export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Ёрдам",
  personalArea: "Шахсий кабинет",
  myApplications: "Менинг аризаларим",
  servicesAndFacilities: "Хизмат ва сервислар",
  logout: "Чиқиш",
  favorites: "Танланганлар",
  theChoice: "Танлов",
  notifications: "Билдиришномалар",
  logIn: "Кириш",
  quickAccessThroughSocialNetworks: "Ижтимоий тармоқлар орқали тез кириш",
  enterYourEmail: "E-mail манзилингизни киритинг",
  password: "Пароль",
  enterPassword: "Паролни киритинг",
  rememberMe: "Мени ёдда сақланг",
  forgotYourPassword: "Паролни эсдан чиқардингизми?",
  noAccount: "Аккаунт йўқми?",
  registration: "Рўйхатдан ўтиш",
  countryOfCitizenship: "Фуқаролик мамлакати",
  chooseYourCountryOfCitizenship: "Фуқаролик мамлакатини танланг",
  iHaveReadAndAgree: "Мен танишдим ва",
  _toTheProcessingOfMyPersonalData:
    "менинг шахсий маълумотларим қайта ишланишига розилик бераман",
  iAgreeTo: "Мен cookie файлларидан",
  _theUseOfCookies: "фойдаланилишига розилик бераман",
  signUp: "Рўйхатдан ўтиш",
  haveAnAccount: "Аккаунт борми?",
  mainPage: "Бош саҳифа",
  frequentlyAskedQuestionsAnswers: "Кўп бериладиган саволларга жавоблар",
  goTo: "Ўтиш",
  startTesting: "Синовни бошлаш",
  buildCareerVector: "Карьера векторини қуриш",
  forum: "Форум",
  textForumBanner:
    "Форумимизда сиз учун муҳим бўлган барча мавзуларни муҳокама қилинг: Россиянинг шаҳарларида яшаш қандай, Россия университетларида ўқиш қандай ўтади ва ҳок.",
  feedback: "Тескари алоқа",
  technicalSupportFeedbackForm:
    "Техник қўллаб-қувватлаш хизмати билан тескари алоқа шакли",
  allCities: "Барча шаҳарлар",
  citiesOfRussia: "Россия шаҳарлари",
  educationInRussia: "Россияда таълим олиш",
  educationalOrganizations: "Университетлар",
  educationInRussiaYourKeyToSuccess:
    "Россияда таълим олиш - муваффақиятингиз калити",
  applyForStudyInRussia: "Россияда таълим олиш учун ариза беринг",
  apply: "Ариза бериш",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Касбга йўналтириш ва ариза бериш имкониятга эга бўлиш учун киринг ёки рўйхатдан ўтинг",
  aboutOrganization: "Ташкилот ҳақида",
  eventsAndOlympiads: "Тадбир ва олимпиадалар",
  educationalPrograms: "Дастурлар",
  contacts: "Контактлар",
  inFavorites: "Танланганларда",
  toFavorites: "Танланганларга",
  fromChoices: "Танланган",
  choices: "Танлаш",
  generalInformation: "Умумий маълумот",
  internationalRatings: "Халқаро рейтинглар",
  nationalRatings: "Миллий рейтинглар",
  ratings: "Рейтинглар",
  year: "Йил",
  ratingName: "Рейтинг номи",
  aPlace: "Жой",
  screeningTests: "Саралаш синовлари",
  choiceOfProfession: "Касбни танлаш",
  lifeInRussia: "Россиядаги ҳаёт",
  russianLanguage: "Рус тили",
  livingConditions: "Яшаш шароитлари",
  cultureAndLeisure: "Маданият ва ҳордиқ",
  safety: "Хавфсизлик",
  benefitsForInternationalStudents: "Чет эллик талабалар учун имтиёзлар",
  workForInternationalStudents: "Чет эллик талабалар учун иш",
  studentVisaAndRegistration: "Таълим визаси ва рўйхатдан ўтиш",
  healthInsurance: "Тиббий суғурта ",
  howToEnter: "Қандай ўқишга кириш мумкин",
  educationLevels: "Таълим даражаси",
  admissionProcess: "Ўқишга кириш жараёни",
  olympics: "Олимпиадалар",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Россияда {year} йилда таълим олишга чет эллик фуқароларни танлаб олиш учун расмий сайт",
  whyRussia: "Нега айнан Россияни танлашади",
  "10reasonsToStudyInRussia": "Россияда таълим олишнинг 10 сабаби",
  reviewsOfForeignStudents: "Чет эллик талабаларнинг фикр-мулоҳазалари",
  onlineMonitoring: "Онлайн-мониторинг",
  mainPrograms: "Асосий дастурлар",
  additionalPrograms: "Қўшимча дастурлар",
  chooseCounty: "Округни танланг",
  county: "Округ",
  chooseACity: "Шаҳарни танланг",
  city: "Шаҳар",
  chooseYourLevelOfEducation: "Таълим даражасини танланг",
  chooseYourAreaOfScientificKnowledge: "Ilmiy bilimlar sohasini tanlang",
  chooseYourScientificSpecialtyProfile: "Ilmiy mutaxassislik profilini tanlang",
  areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Таълим даражаси",
  chooseAGroupOfTrainingAreas: "Тайёрлаш йўналишлари гуруҳини танланг",
  trainingDirectionGroup: "Тайёрлаш йўналишлари гуруҳи",
  chooseATrainingDirection: "Тайёрлаш йўналишини танланг",
  trainingDirection: "Тайёрлаш йўналиши",
  chooseRatings: "Рейтингларни танланг",
  thePresenceOfThePreparatoryFaculty: "Тайёрлов факультетининг мавжудлиги",
  show: "Кўрсатиш",
  _university: "университет",
  _universities: "университетлар",
  __universities: "университетлар",
  clearAllFilters: "Барча фильтрларни қайта ўрнатиш",
  chooseSemester: "Семестрни танланг",
  semester: "Семестр",
  enterTheNameOfTheEducationalOrganization: "Университет номини киритинг",
  _educationalOrganization: "университет",
  _educationalOrganizations: "университетлар",
  __educationalOrganizations: "университетлар",
  chooseYourAreaOfEducation: "Таълим соҳасини танланг",
  areaOfEducation: "Таълим соҳаси",
  chooseYourLanguageOfInstruction: "Таълим тилини танланг",
  languageOfInstruction: "Таълим тили",
  _program: "дастур",
  _programs: "дастурлар",
  __programs: "дастурлар",
  formOfTraining: "Таълим шакли",
  level: "Даражаси",
  typeOfTraining: "Таълим тури",
  free: "Бепул",
  paid: "Пуллик",
  tuitionFees: "Пуллик таълимнинг нархи",
  established: "Асос солинган сана",
  numberOfStudents: "Талабалар сони",
  _students: "талабалар",
  numberOfQuotaPlaces: "Квотали ўринлар сони",
  _for: "Кимга",
  numberOfInternationalStudents: "Чет эллик талабалар",
  _educationalPrograms: "таълим дастурлари",
  moreDetails: "Батафсил",
  sTEP: "ҚАДАМ",
  afterTrainingForThisProgramYouCanBecome:
    "Ушбу дастур бўйича таълим олгандан сўнг сиз эгаллайдиган касб",
  chooseProgram: "Дастурни танлаш",
  chooseUniversity: "Университетни танлаш",
  print: "Чоп этиш",
  save: "Сақлаш",
  programs: "Дастурлар",
  selectedProgram: "Танланган дастур",
  noProgramSelected: "Дастур танланмаган",
  selectedEducationalOrganizations: "Танланган университетлар",
  educationalOrganizationNotSelected: "Университет танланмаган",
  representation: "Ваколатхона",
  programDescription: "Дастурнинг тавсифи",
  programPageOnTheUniversityWebsite: "Университет сайтидаги дастур саҳифаси",
  programCurator: "Дастур раҳбари",
  disciplines: "Фанлар",
  theProcessOfEnteringARussianEducationalOrganization:
    "Россия университетларига кириш жараёни",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Дастур ва 6 тагача университетни танланг",
  takeTheCompetitiveSelectionInYourCountry:
    "Ўз мамлакатингизда саралаш танловидан ўтинг",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Аризани онлайн тўлдиринг ва ҳужжатлар тўпламини илова қилинг",
  getAnInvitationToStudAnStudyVisa:
    "Таълим олиш учун таклифнома ва таълим визасини олинг",
  preparatoryFaculties: "Тайёрлов факультетлари",
  summerAndWinterSchools: "Қишки ва ёзги мактаблар",
  russianLanguageCourses: "Рус тили курслари",
  tutorsTheRussianLanguage: "Рус тили репетиторлари",
  certificationInRussian: "Рус тили бўйича сертификатлаш",
  news: "Янгиликлар",
  allNews: "Барча янгиликлар",
  reviewsOfStudentsAndGraduates: "Талаба ва битирувчиларнинг фикрлари",
  areYouSureYouWantToWithdrawTheApplication:
    "Ростдан ҳам аризангизни қайтариб олмоқчимисиз?",
  withdrawApplication: "Аризани қайтариб олиш",
  jan: "Январь",
  feb: "Февраль",
  mar: "Март",
  apr: "Апрель",
  may: "Май",
  jun: "Июнь",
  jul: "Июль",
  aug: "Август",
  sep: "Сентябрь",
  act: "Октябрь",
  nov: "Ноябрь",
  dec: "Декабрь",
  cityFeatures: "Шаҳарнинг хусусиятлари",
  enterTheNameOfTheCity: "Шаҳар номини киритинг",
  organizations: "Ташкилотлар",
  livingWage: "Яшаш минимуми",
  "one-roomApartmentForRent": "1 хоналик квартирани ижарага олиш",
  _month: "ой",
  averageTemperature: "Ўртача ҳарорат",
  _inWinter: "қишда",
  _inSummer: "ёзда",
  averageCostOfDormitories: "Ётоқхоналарнинг ўртача нархи",
  photos: "Фотосуратлар",
  interestingPlaces: "Қизиқарли жойлар",
  readCompletely: "Тўлиқ ўқиш",
  _review: "фикр-мулоҳаза",
  _reviews: "фикр-мулоҳаза",
  __reviews: "фикр-мулоҳаза",
  university: "Университет",
  universities: "Университетлар",
  country: "Мамлакат",
  selectACountry: "Мамлакатни танланг",
  reviewOfAForeignStudent: "Чет эллик талабанинг фикри",
  careerGuidance: "Касбга йўналтириш",
  determineYourAreaOfEducationAndProfession:
    "Ўзингиз учун мос келадиган таълим соҳалари ва касбларни танланг",
  myCareer: "Менинг хизмат  фаолиятим",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Қандай қилиб ўқишни маданий ҳордиқ ва янги танишувлар билан мужассамлаштириш мумкин. Интенсив дастурлар хусусиятлари ва уларнинг нархи",
  toApply: "Қўллаш",
  chooseYear: "Йилни танланг",
  _in: "бу шаҳарда",
  universitiesInThisCity: "Бу шаҳардаги университетлар",
  programsInThisCity: "Бу шаҳардаги дастурлар",
  all: "Барчаси",
  _inYourCountry: "сизнинг мамлакатингизда",
  _common: "умумий",
  allReviews: "Барча фикр-мулоҳазалар",
  calendar: "Тақвим",
  activity: "Тадбирлар",
  addToPersonalCalendar: "Шахсий тақвимга қўшиш",
  eventAdded: "Воқеа қўшилган",
  eventSuccessfullyAddedToYourCalendar: "Воқеа сизнинг тақвимингизга қўшилди!",
  population: "Аҳоли",
  onTheMap: "Харитада",
  generalEducation: "Умумий таълим",
  professionalEducation: "Касбий таълим",
  preschoolEducation: "Мактабгача таълим",
  kindergarten: "Болалар боғчаси",
  nursery: "Ясли",
  earlyDevelopmentCenters: "Эрта ривожланиш маркази",
  primaryAndSecondaryEducation: "Бошланғич, асосий ва ўрта таълим",
  schools: "Мактаблар",
  lyceums: "Лицейлар",
  gymnasium: "Гимназиялар",
  secondaryVocationalEducation: "Ўрта касбий таълим",
  colleges: "Коллежлар",
  technicalSchools: "Техникумлар",
  secondarySchool: "Ўқув юртлари",
  higherEducation: "Олий таълим",
  baccalaureate: "Бакалавриат",
  specialistDegree: "Мутахассис",
  magistracy: "Магистратура",
  postgraduateStudies: "Аспирантура",
  preparatoryFaculty: "Тайёрлов факультети",
  summerSchools: "Ёзги мактаблар",
  additionalGeneralAndPreVocationalPrograms:
    "Қўшимча, умумий ривожлантириш  ва касбдан олдинги дастурлар",
  continuingEducationAndRetrainingPrograms:
    "Малака ошириш ва касбий қайта тайёрлаш дастурлари",
  whatAreTheLevelsOfRussianEducation:
    "Россия таълими қандай даражалардан ташкил топган?",
  enterYourName: "Исмингизни киритинг",
  name: "Исм",
  selectTheSubjectOfTheMessage: "Хабар мавзусини киритинг",
  messageSubject: "Хабар мавзуси",
  chooseAMessage: "Хабарни танланг",
  message: "Хабар",
  submit: "Юбориш",
  frequentlyAskedQuestions: "Кўп бериладиган саволлар",
  didNotFindTheAnswerToYourQuestion: "Саволингизга жавоб топа олмадингизми?",
  contactSupportAt: "Манзил бўйича қўллаб-қувватлаш хизматига мурожаат қилинг",
  searchInTheSection: "Бўлимда қидириш",
  sort: "Саралаш",
  searchingResults: "Қидирув натижалари",
  _answers: "жавоблар",
  _views: "кўришлар",
  byCreationDate: "Яратилиш санасига кўра",
  byTheNumberOfResponses: "Жавоблар сонига кўра",
  byViews: "Кўришлар сонига кўра",
  toAnswer: "Жавоб бериш",
  toQuote: "Иқтибос келтириш",
  complain: "Шикоят қилиш",
  addNewMessage: "Янги хабар қўшиш",
  selectComplaintType: "Шикоят турини танланг",
  typeOfComplaint: "Шикоят тури",
  enterComplaintText: "Шикоят матнини киритинг",
  complaintText: "Шикоят матни",
  complaintSentSuccessfully: "Шикоят юборилди",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Сизнинг шикоятингиз юборилди, биз тез орада сизнинг шикоятингизни кўриб чиқамиз",
  close: "Ёпиш",
  myMessages: "Менинг хабарларим",
  myTopicsAndPosts: "Менинг мавзу ва хабарларим",
  searchInMyPosts: "Менинг хабарларимдан қидириш",
  _posts: "хабарлар",
  feedbackOnEducationalOrganizations: "Университет ҳақидаги фикр-мулоҳазалар",
  posted: "Нашр қилингин",
  goToMessage: "Хабарга ўтиш",
  _themes: "мавзу",
  themeTitle: "Мавзу номи",
  enterAThemeName: "Мавзунинг номини киритинг",
  cancel: "Бекор қилиш",
  banUser: "Фойдаланувчи блоклансинми?",
  areYouSure: "Шунга аминмисиз?",
  toBan: "Блоклаш",
  _logIn: "тизимга кириш",
  _or: "ёки",
  _signUp: "рўйхатдан ўтиш",
  eventCalendar: "Тадбирлар тақвими",
  trainingApplicationsFiled: "Таълим олиш учун ариза берилган",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Абитуриентлар танлайдиган энг машҳур университетлар",
  _of: "Қуйидагидан",
  _whenApplying: "ариза беришда",
  tOP10EducationalOrganizations: "ТОП-10 та университет",
  collapse: "Ёпиш",
  showAll: "Барчасини кўрсатиш",
  conditionsForForeigners: "Чет элликлар учун шароитлар",
  supportForInternationalStudents: "Чет эллик талабаларни қўллаб-қувватлаш",
  lifeOfForeignStudents: "Чет эллик талабалар ҳаёти",
  leisureAndSportingEvents: "Дам олиш ва спорт тадбирлари",
  dormitories: "Ётоқхоналар",
  dormitoryCost: "Ётоқхона нархи",
  theNumberOfPeopleInTheRoom: "Хонадаги одамлар сони",
  from: "Бошланғич нархи",
  _rubles: "рубль",
  _to: "Қанчагача",
  _perMonth: "ойига",
  "2-3People": "2-3 кишидан",
  theInternationalCooperation: "Халқаро ҳамкорлик",
  photoAndVideo: "Фото ва видео",
  eventTitle: "Тадбир номи",
  location: "Ўтказилиш жойи",
  date: "Сана",
  enterYourRealMail: "Ҳақиқий почтангизни кўрсатинг",
  requiredField: "Майдон тўлдирилиши керак",
  minimumLengthCharacters: "Минимал узунлиги: {length} та белги",
  maximumLengthCharacters: "Максимал узунлиги: {length} та белги",
  theFieldMustBeANumber: "Майдон рақам бўлиши керак",
  onlyLettersAreAllowedInTheField: "Майдонда фақат ҳарфлар бўлиши керак",
  checkKeyboardLayout: "Клавиатуранинг жойлашувини текширинг",
  checkTheNumberYouEnteredIsCorrect:
    "Сиз киритган рақамнинг тўғрилигини текширинг",
  olympiadName: "Олимпиада номи",
  description: "Тавсифи",
  contactInformation: "Боғланиш учун маълумот",
  majorForeignPartnerUniversities: "Чет элдаги асосий ҳамкор-университетлар",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Россия ва бутун дунё бўйлаб рус тилини масофадан ва жойида ўрганиш имкониятлари",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Сиз репетитормисиз? Профилингизни сайтда эълон қилиш учун сўровнома беринг",
  submitApplication: "Сўровнома бериш",
  chooseATeachingExperience: "Ўқитиш стажини танланг",
  teachingExperience: "Ўқитиш стажи",
  chooseLanguages: "Тилларни танланг",
  knowledgeOfLanguages: "Тилларни билиш",
  workExperience: "Иш стажи",
  trainingLevel: "Тайёргарлик даражаси",
  durationOfOneLesson: "Битта машғулотнинг давомийлиги",
  possibleTrainingTime: "Эҳтимолий таълим вақти",
  theCosOfOneLesson: "Битта машғулот нархи",
  writeAMessage: "Хабар ёзиш",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Сиз сайтда рўйхатдан ёки тасдиқдан ўтганингиздан сўнг репетиторга ёзишингиз мумкин",
  educationDocuments: "Маълумоти ҳақидаги ҳужжатлар",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Сизда бошқа дастур танлаб бўлинган, агар бу дастурни танласангиз, танланган дастур ва ташкилотлар йўқолади",
  limitIsExceeded: "Чекловдан ошиб кетди",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Сизда 6 тагача университет танлаш имкони бор",
  _fieldsOfEducation: "таълим соҳаси",
  _trainingAreas: "тайёрлаш йўналишлари",
  _cities: "шаҳар",
  _languagesOfInstruction: "таълим тиллари",
  _typesOfAdditionalPrograms: "қўшимча дастурлар турлари",
  _semesters: "семестрлар",
  chooseDurationOfStudy: "Таълим давомийлигини танланг",
  _durationOfStudy: "таълимнинг давомийлиги",
  enterTheNameOfTheEducationalProgram: "Дастур номини киритинг",
  instruction: "Йўриқнома",
  profile: "Сўровнома",
  back: "Орқага",
  next: "Кейинги",
  testReport: "Синов натижалари бўйича ҳисобот",
  testDate: "Синов ўтказиш санаси",
  duration: "Давомийлиги",
  memberName: "Қатнашчининг номи",
  age: "Ёши",
  gender: "Жинси",
  currentLevelOfEducation: "Жорий таълим даражаси",
  areasOfInterestHobbies: "Қизиқишлар доираси, ҳобби",
  sportsAchievements: "Спорт ютуқлари",
  testScaleResults: "Тест шкалалари бўйича натижалар",
  scaleDescription: "Шкалалар бўйича натижалар тавсифи",
  recommendedEducationAreas: "Тавсия этиладиган таълим соҳалари",
  enterYourAge: "Ёшингизни киритинг",
  enterYourGender: "Жинсингизни киритинг",
  chooseYourCurrentLevelOfEducation: "Жорий маълумот даражангизни киритинг",
  chooseYourAreasOfInterestHobbies:
    "Қизиқишларингиз доираси, хоббиларингизни танланг",
  chooseYourSportingAchievements: "Спорт ютуқларингизни танланг",
  legalizationOfDocuments: "Ҳужжатларни легаллаштириш",
  consularLegalization: "Консуллик томонидан легаллаштириш",
  edit: "Таҳрирлаш",
  mobilePhoneNumber: "Мобил телефон рақами",
  iWantToReceiveNotificationsByEmail:
    "Электрон почтага хабарлар олишни хоҳлайман",
  authorizationCheck: "Авторизацияни текшириш",
  createANewApplication: "Янги ариза яратиш",
  selectionStage: "саралаш босқичи",
  underRevision: "Охиригача ишлов берилмоқда",
  fillingOutAnApplication: "Аризани тўлдириш",
  verificationAndRevision: "Текшириш ва қайта ишлаш",
  dossierFormation: "Шахсий ҳужжатларни шакллантириш",
  fillingInTheQuestionnaire: "Сўровномани тўлдириш",
  attachingDocuments: "Ҳужжатларни илова қилиш",
  sendingApplicationForVerification: "Аризани текширишга юбориш",
  personalData: "Шахсий маълумотлар",
  surnameInLatinLettersAccordingToPassport:
    "Фамилия, лотин ҳарфлари билан (паспортга мос тарзда)",
  nameInLatinLettersAccordingToPassport:
    "Исм (исмлар), лотин ҳарфлари билан (паспортга мос тарзда)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Отасининг исми (агар бўлса), кириллчада, рус транскрипциясида",
  placeOfBirthAccordingToPassport: "Туғилган жой (паспортга мос тарзда)",
  dateOfBirth: "Туғилган санаси",
  compatriotStatus: "Ватандошлик мақоми",
  citizenshipPlaceOfPermanentResidence: "Фуқаролик (доимий яшаш жойи)",
  online: "Онлайн",
  profile2: "Профиль",
  goToTheApplicationForm: "Аризани тўлдиришга ўтиш",
  areYouHere: "Сиз бу ердасиз",
  russianTextDes1:
    "Тайёрлов факультетлари нега керак, уларда нима ўрганилади, ундаги таълим қанча давом этади ва нархи қанча",
  russianTextDes4:
    "Рус тилидан индивидуал машғулотлар учун ўқитувчини қандай ва қаердан топса бўлади",
  russianTextDes5:
    "Россия университетига кириш учун рус тилини билиш даражасини қандай ва нега тасдиқлаш керак. Сертификатга синовдан ўтиш тўғрисидаги ҳаммасини билиб олинг.",
  typeOfProgram: "Дастур тури",
  chooseTheTypeOfAdditionalProgram: "Қўшимча дастур турини танланг",
  durationOfStudy: "Таълимнинг давомийлиги",
  common: "Умумий",
  inYourCountry: "Сизнинг мамлакатингизда",
  courseName: "Курснинг номи",
  courseDuration: "Курснинг давомийлиги",
  classDuration: "Машғулотнинг давомийлиги",
  courseCost: "Курснинг нархи",
  courseReviews: "Курс ҳақидаги фикр-мулоҳазалар",
  male: "Эркак",
  female: "Аёл",
  feedbackLink: "Фикр-мулоҳазаларга ҳавола",
  russianLanguageCoursesInYourCountry: "Мамлакатингиздаги рус тили курслари",
  evaluation: "Баҳо",
  giveFeedback: "Фикр-мулоҳаза қолдириш",
  newFeedback: "Янги фикр",
  _hour: "соат",
  _hours: "соат",
  __hours: "соат",
  _minute: "дақиқа",
  _minutes: "дақиқа",
  __minutes: "дақиқа",
  create: "Яратиш",
  feedbackSuccess: "Фикр-мулоҳаза юборилди ва модерацияни кутмоқда",
  _y: "й",
  _from: "from",
  error: "Хато",
  direction: "Йўналиш",
  _tutor: "репетитор",
  _tutors: "репетиторлар",
  __tutors: "репетиторлар",
  filters: "Фильтрлар",
  sendMessage: "Хабар юбориш",
  sendMessageSuccess: "Хабар юборилди",
  _year: "йил",
  _years: "йил",
  __years: "йил",
  _back: "орқага",
  _next: "кейин",
  showing: "Кўрсатиш",
  textRussianLanguageCourses:
    "Менюдан яшаш мамлакатингизни танланг ва мумкин бўлган тил курслари ва мактабларни кўринг. Уйда рус тилининг бошланғич даражасини билиб олиш кўчиб ўтганда мослашишни анча енгиллаштиради.",
  tOP10Programs: "ТОП-10 та дастур",
  tOP10City: "Россиянинг ТОП -10 та шаҳри",
  createANewTopic: "Янги мавзуни яратиш",
  topics: "Мавзу",
  posts: "Хабар",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "10 Мбайтгача .pdf, .jpg, .png форматидаги файллар",
  noFileSelected: "Файл танланмаган",
  attachFile: "Файл бириктириш",
  eMail: "E-mail",
  fullTime: "Кундузги",
  toFind: "Топиш",
  searchInTopic: "Мавзуда қидириш",
  lessThan1Month: "1 ойдан кам",
  "1To3Months": "1 ойдан 3 ойгача",
  "3To6Months": "3 ойдан 6 ойгача",
  "6To12Months": "6 ойдан 12 ойгача",
  moreThan1Year: "1 йилдан кўп",
  theDateOfThe: "Ўтказилиш санаси",
  toAdminPanel: "Маъмур панелига ўтиш",
  support: "Техник қўллаб-қувватлаш",
  faq: "FAQ",
  userGuide: "Фойдаланувчи қўлланмаси",
  textUserGuide: "Портал билан ишлаш бўйича йўриқнома ва ўқишга ариза бериш",
  textRegisterGuide: "Хорижликлар учун Россияда таълим олиш сайтида рўйхатдан ўтиш",
  textTrainingGuide: "Хорижликлар учун Россияда таълим олиш сайтида ўқишга ариза бериш",
  textApplication: "Сўровномани тўлдириш",
  instructionForWork: "Ариза билан ишлаш бўйича йўриқнома",
  appeals: "Аппеляциялар",
  handling: "Мурожаатлар",
  __students: "талаба",
  _student: "талаба",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Тайёрлаш йўналишингиз гуруҳини аниқланг ва келажакдаги касбингизнинг мумкин бўлган вариантларини билиб олинг",
  delTheme: "Мавзуни ўчириш",
  competitionForTheFirstPlace: "Россия Федерацияси квоталари доирасидаги 1 та ўринга танлов (киши)",
  ruble: "рубл",
  __rubles: "рубл",
  dateOfEvent: "Ўтказиш санаси",
  durationUnit: "ой",
  numberPhone: "Телефон рақами",
  offline: "Офлайн",
  to_begin: "бошланишига",
  theDateOfThe: "Бошланиш санаси",
  chat: "Чат-бот",
  yourMessageChat: "Хабарингиз",
  searchChat: "Диалог бўйича қидириш",
  firstMsgChat: "Хабар матнини киритинг",
  categoryBtnChat_1: "Ариза бериш бўйича савол",
  categoryBtnChat_2: "Ўқиш бўйича савол",
  categoryBtnChat_3: "Танловли саралаш бўйича савол",
  categoryBtnChat_4: "Таклиф ёки танқидий фикр",
  categoryBtnChat_5: "Университет фаолияти бўйича фикр-мулоҳаза",
  categoryBtnChat_6: "Хизматлар сифати бўйича шикоят",
  categoryBtnChat_7: "Техник қўллаб-қувватлаш хизматига мурожаат",
  notQuestionResolvedChat: "Саволимга жавоб олмадим",
  questionResolvedChat: "Саволимга жавоб олдим",
  countrySelectTrueChat: "Мамлакат тўғри кўрсатилган",
  salutationChat: "Ассалом-у алайкум.",
  categoryAppealChat: "Маълумотлар олиш учун тугмани босган ҳолда саволингиз тегишли бўлган тоифани танланг. Тоифани танлагандан кейин саволингизни хабарга киритинг ва Юбориш тугмасини босинг.'",
  langAppealChat: "Мурожаатингизни юбормоқчи бўлган тилни танланг. Рус ва инглиз тилида юбориладиган мурожаатлар энг тез кўриб чиқилади.",
  youCountryChatPath1: "Мамлакатингиз",
  youCountryChatPath2: "Агар йўқ бўлса, жавоб хабарида мамлакат номини кўрсатинг.",
  youCountryChat: "Мамлакатингиз {country} ми? Агар йўқ бўлса, жавоб хабари билан мамлакат номини кўрсатинг.",
  notFoundCountryChat: "Сиз кўрсатган номли мамлакат топилмади. Киритилган маълумотларнинг тўғрилигини текширинг ва хабарни қайта юборинг.",
  additionalInfoChat: "Жавоб хабарида мурожаатингиз бўйича қўшимча маълумотларни кўрсатинг.",
  yourEmailChat: "Электрон почта манзилингизни кўрсатинг. Мурожаатингиз бўйича жавоб электрон хат билан юборилади.",
  notValidEmailChat: "Сиз юборган электрон почта манзили aaa@bbb.ccc форматига мос келмайди. Киритилган маълумотларнинг тўғрилигини текширинг ва хабарни қайта юборинг.",
  acceptRequestChat: "Мурожаатингиз оператор ишига юборилди. Кўриб чиқишнинг ўртача муддати 7 календар кунига тенг.",
  notFoundEmployeeChat: "Сиз кўрсатган мамлакат Россотрудничество дастури бўйича ўқиш мумкин бўлган мамлакатлар рўйхатига кирмайди. Мурожаатингизни кўриб чиқишга юбориб бўлмайди. Бошқа мамлакатни кўрсатинг. Ўқиш дастурларининг тўлиқ рўйхати билан <a href=/education-in-russia/programs#>{host}/education-in-russia/programs# саҳифасида танишиб чиқиш мумкин.</a>' ",
  langBtnChat_1: "Инглиз",
  langBtnChat_2: "Рус",
  langBtnChat_3: "Бошқа",
  addAppBtn: "Ариза бериш",
  listAppBtn: "Аризалар рўйхати",
  privacyPolicy: "Махфийлик сиёсати",
  ___programs: "дастурга",
  ___educationalPrograms: "таълим дастурлари",
  __educationalPrograms: "таълим дастури",
  editTopic: "Мавзуни таҳрирлаш",
  editMessage: "Хабарни таҳрирлаш",
  deleteMessage: "Хабарни ўчириш",
  blockingUser: "Фойдаланувчини блоклаш",
  blockEndDate: "Блоклашни тугаш санаси",
  blockEndTime: "Блоклашни тугаш вақти",
  blockForever: "Бутунлай блоклаш",
  block: "Блоклаш",
  unlock: "Блокдан чиқариш",
  edited: "Таҳрирланди",
  founder: "Таъсисчи",
  abbreviation: "Аббревиатура",
  studentsByCountry: "Талабалар сони мамлакатлар кесимида",
  studentsByCountryCount: "Ўқиётган талабалар сони",
  contactsInternationalDepartment: "Халқаро бўлим контакт маълумотлари",
  site: "Сайт",
  address: "Манзил",
  format: "Формат",
  typeEvent: "Тадбир тури",
  quotaLearning: "Квота билан ўқиш",
  kcp: "ҚНР (қабулнинг назорат рақамлари)",
  countSeatsKCP: "ҚНР ўринлар сони",
  countSeatsCostTraining: "Пуллик ўқишда ўринлар сони",
  distanceLearning: "Масофавий таълим",
  notificationAboutDistanceLearning: "Таълим дастурларини электрон ўқитиш ва масофавий таълим технологияларидан фойдаланиб амалга ошириш мумкин. Батафсил маълумотларни ОТМ дан аниқлаштиринг.",
  additionalTests: "Қўшимча саралаш синовларини ўтказиш саналари",
  pageNotFound: "Саҳифа топилмади",
  goToMainPage: "Бош саҳифага ўтиш",
  faqInformation: "Аризани текшириш ҳолати, керакли ҳужжатлар рўйхати, саралаш синовларини ўтказиш саналари билан боғлиқ бўлган саволлар ҳамда ўқишга кириш тартиби бўйича бошқа саволлар билан мамлакатингизда саралаш ўтказиш ваколатига эга бўлган Россотрудничество ваколатхоналари ёки Россия Федерацияси элчихоналарига мурожаат қилишни тавсия қиламиз. Россотрудничество ходимлари билан боғланиш учун контакт маълумотларини шахсий кабинетингиздан топасиз.",
  allEvents: "Барча тадбирлар",
  eventsAndNews: "Тадбирлар ва янгиликлар",
  titleNews: "Янгилик номи",
  numberOfBudgetPlaces: "Бюджет ўринлар сони",
  russianGovernmentQuota: "byudjet (qabul qilishning nazorat raqamlari)",
  legalAddress: "Юридик манзили",
  actualAddress: "Ҳақиқий манзил",
  goToTheRegister: "Қўшимча дастурлар рейестрига ўтиш",
  searchForSuitablePrograms:"Ҷустуҷӯи барномаҳои мувофиқ",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Абитуриентлар танлайдиган энг оммабоп таълим дастурлари",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Абитуриентлар танлайдиган энг оммабоп Россия шаҳарлари",
  ofTheCountry: "мамлакатдан",
  passed: "Ўтказилди",
  question: "Савол",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Portal sinov operatsiyasi rejimida ishlamoqta!",
  banner_2: "Hurmatli foydalanuvchilar hozirgi vaqtda Google porchta manzillaridan  foydalanish jarayonida texnik noqulayliklar yuzaga kelishi mumkin. Portal bilan ishlashda boshqa pochta servislaridan foydalanishingizni suraymiz.",
  banner_3: "Qabul kаmpaniyasi 2022 yil 24 dekabrdan ishlashini ma'lum qilamiz.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
