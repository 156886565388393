<template>
    <div :class="formRowClass">
        <validation-provider :name="storePath" :rules="rules" v-slot="{ errors }">
            <label :for="model" class="form-control-label" v-if="label" v-html="label" />
        
            <multiselect 
                :options="options" 
                :placeholder="valueName || placeholder" 
                v-model="valuePreSet"
                :allow-empty="allowEmpty" 
                :label="customLabel" 
                ref="multiselect" 
                :multiple="isMultiple" 
                :track-by="customValue"
                :searchable="searchable"
                @search-change="searchChange"
                :internalSearch="true"
                @open="onOpen"
                @close="onClose"
                :loading="isLoading"
                >
                <template slot="singleLabel" slot-scope="prop">
                    <span class="option__title">{{prop.option[customLabel] | kitCut(70) }}</span> 
                </template>
                    <li class="load" slot="afterList"  v-show="hasNextPage" ref="load">Loading...</li>
            </multiselect>
            <div class="hint-error" v-if="error">
                {{ error }}
            </div>
            <div class="hint-error" v-if="errors.length">
                {{ errors[0] }}
            </div>
            <p class="hint" v-if="hint">
                {{ hint }}
            </p>
        </validation-provider> 
    </div>
</template>

<script>

import _ from 'lodash';
import {mapGetters} from 'vuex';
import {helpers} from '@/_services';

export default {
    name: 'AsyncSelectPaginate',

    data: () => ({
        defaultCount: 10,
        valuePreSet: null,
        interval: null,
        valueName: '',
        observer: null,
        page: 1,
        search: '',
        total: 0,
        isLoading: false,
        options: [],
    }),

    props: {
        code: {
            type: Boolean, default() {
                return false;
            },
        },
        label: String,
        allowEmpty: {
            type: Boolean, default() {
                return false;
            },
        },

        customLabel: {
            type: String, default() {
                return 'name';
            },
        },

        customValue: {
            type: String, default() {
                return 'value';
            },
        },
        model: String,
        storePath: String,
        placeholder: String,
        value: [String, Object],
        callbackOnChange: Function,
        isMultiple: {type: Boolean, default() { return false; }},
        searchable: {type: Boolean, default() { return true; }},
        rules: {type: String, default: () => ''},
        formRowClass: {type: String, default: () => 'form-row'},
        error: String,
        hint: String,
        keyShowAll: String,
        loadOptions: Function,
        filters: Object,
    },

    mounted() {
        /**
         * You could do this directly in data(), but since these docs
         * are server side rendered, IntersectionObserver doesn't exist
         * in that environment, so we need to do it in mounted() instead.
         */
        this.observer = new IntersectionObserver(this.infiniteScroll)

        // получение первых 10 опций
        this.loadOptions().then(res => {
            this.total = res.total
            this.options = this.code ? helpers.optionsCode(res.data) : helpers.options(res.data)
        })

        //стандартные функции мультиселекта для записи выбранного селекта в стор
        if (this.storePath && !_.get(this.$store.state.form.forms, this.storePath)) {
            this.$store.commit('form/initFormField', {model: this.storePath, value: _.get(this.forms, this.storePath) || this.value || null});
        }

        if (this.valuePreSet !== this.value && this.value) {
            this.valuePreSet = this.value;
            this.setOptions();
        }
    },

    
    methods: {

        //получаем текст из инпута для поискового запроса
        searchChange(value) {
           return this.search = value
        },

        //запускаем слежку за скроллом
        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick()
                this.observer.observe(this.$refs.load)
            }
        },

        //отключаем слежку за скроллом
        onClose() {
            this.observer.disconnect()
        },

        // когда доскролили, добаляем страничку
        async infiniteScroll([{isIntersecting, target}]) {
            if (this.hasNextPage && isIntersecting) {
                const ul = target.offsetParent
                const scrollTop = target.offsetParent.scrollTop
                this.page += 1
                await this.$nextTick()
                ul.scrollTop = this.page === 2? 0 : scrollTop
            }
        },


        // получает значение при выборе опции
        getValueName() {
            let value = _.get(this.forms, this.storePath), name;

            if (!value) {
                return false;
            }

            if (_.isArray(value)) {
                name = _.map(value, v => (_.find(this.options, option => option.value == v) || {}).name || false).join(', ')
            } else {
                name = (_.find(this.options, option => option.value == value) || {}).name || false;
            }
            return name;
        },

        //получаем опции
        setOptions() {
            this.loadOptions({...this.filters, page: this.page, q: this.search}).then(res => {   
                if (res.data && (res.data.length > 0) && res.total > this.options.length) {
                    this.total = res.total
                    const newOptions = this.code ? helpers.optionsCode(res.data) : helpers.options(res.data)
                    this.options = [ ...this.options,...newOptions]
                } else {
                    this.options =[]
                    this.total = 0
                    this.isLoading = false
                }
            }).finally(() => this.isLoading = false)

            let
                value = this.value, valueFind;

            if (_.get(this.forms, this.storePath)) {
                value = _.get(this.forms, this.storePath);
            }

            if (value) {
                if (_.isArray(value)) {
                    valueFind = [];
                    _.each(value, val => {
                        valueFind.push(_.find(this.options, option => (option.value || '') == val));
                    })
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            forms: 'form/forms',
        }),

        hasNextPage() {
            return (this.total > this.defaultCount && (this.options && this.options.length < this.total))
        },
    },

    watch: {

        // запись выбранного значения в стор 
        valuePreSet(nextState) {
            if (!nextState) {
                this.$store.commit('form/updateField', {model: this.storePath, value: null});
                this.valueName = this.getValueName();
                return;
            }

            if (this.isMultiple) {
                this.$store.commit('form/updateField', {
                    model: this.storePath, value: _.map(nextState, value => value[this.customValue] || value),
                });

                if (this.callbackOnChange) {
                    this.callbackOnChange();
                }
            } else {
                if (_.isObject(this.valuePreSet)) {
                    this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet[this.customValue]});

                    if (this.callbackOnChange) {
                        this.callbackOnChange();
                    }
                }
            }

            this.valueName = this.getValueName();
        },

        forms: {
            handler(val) {
                if (!_.get(val, this.storePath)) {
                    this.valuePreSet = null;
                }
            },
            deep: true,
        },
        page(nextState) {
            if (nextState === this.page && this.page !== 0) {
                this.isLoading = true;
                this.setOptions(); 
            } else {
                this.options = [];  
                this.page =1
            }
        },
        search(nextState, prevState) {
            if (nextState !== prevState) {
                this.options = [];
                this.page = 0;
                this.isLoading = true;
                this.search = nextState;  
            }  
        },
        filters() {
            this.options = [];  
            this.page = 0;
            this.isLoading = true;
            this.valuePreSet = this.getValueName();
        },
      
    },
}
</script>
<style scoped>
.load {
    background-color: #F4F5F9;
    text-align: center;
    color: #bbbbbb;
}
</style>
