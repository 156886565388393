<template>
  <button
    :type="type"
    :class="elementClass"
    v-bind:id="name"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    :placeholder="placeholder"
    @click="callback"
    :disabled="isDisabled"
  ><span v-html="text" /></button>
</template>

<script>
  export default {
    name: 'Button',

    props: {
      callback: {
        type: Function, default: () => () => {
        },
      },

      type: {type: String, default: 'button'},
      text: String,
      name: String,
      value: String,
      error: String,
      hint: String,
      elementClass: String,
      placeholder: String,
      isDisabled: Boolean,
    },
  };
</script>
