<template>
  <div class="dropzone">
    <span class="dropzone-btn">{{ textButton || textButtonDef }}</span>

    <input
      :accept="accept"
      :multiple="multiple"
      type="file"
      @change="set($event)"
      ref="inputFile"
    />

    <div class="dropzone-file selected">
      <span class="dropzone-file-text" v-if="!getField().length">{{ textEmptyField || textEmptyFieldDef }}</span>

      <span
        class="dropzone-file-name"
        v-for="(file, key) in getField()"
        v-bind:key="key"
      >
        {{ file.name }}
        <span
          class="dropzone-file-remove"
          @click="() => removeFile(file)"
        ></span>
      </span>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import _ from 'lodash';

  export default {
    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    name: 'form-input-file',

    props: {
      autocomplete: String,
      disabled: {type: Boolean, default: () => false},
      multiple: {type: [String, Boolean], default: () => ''},
      accept: {type: String, default: () => '.jpg,.png,.pdf'},
      label: String,
      value: String,
      elementClass: String,
      placeholder: String,
      storePath: String,
      timeout: {type: Number, default: () => 500},
      elementId: String,
      textEmptyField: String,
      textButton: String,
    },

    data() {
      return {
        interval: null,
        valuePreSet: null,
        textEmptyFieldDef: '',
        textButtonDef: '',
      };
    },

    watch: {
      value() {
        if (this.value && this.valuePreSet !== this.value) {
          this.valuePreSet = this.value;

          this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
        }
      },

//      forms: {
//        handler(val) {
//          console.log(this.$refs.inputFile.files[0])
//          console.log(this.valuePreSet)
//          console.log('dddd', this.storePath)
//
//          if (_.get(val, this.storePath) && _.get(val, this.storePath) !== this.valuePreSet ) {
//            this.$refs.inputFile.value = this.valuePreSet;
//
//
//            this.$store.commit('form/updateField', {model: this.storePath, value: _.get(this.$refs, 'inputFile.files.0')});
//          }
//
//          if (!_.get(val, this.storePath)) {
//            this.valuePreSet = null;
//          }
//        },
//
//        deep: true,
//      },
    },

    mounted() {
      if (this.storePath) {
        this.$store.commit('form/initFormField', {model: this.storePath, value: this.value || null});
      }

      if (this.valuePreSet !== this.value) {
        this.valuePreSet = this.value || _.get(this.forms, this.storePath);
      }

      this.textEmptyFieldDef = this.$t('noFileSelected')
      this.textButtonDef = this.$t('attachFile')
    },

    methods: {
      getField() {
        return _.get(this.forms, this.storePath, []) || [];
      },

      change(value) {
        this.$store.commit('form/updateField', {model: this.storePath, value: value});
      },


      removeFile(removedFile) {
        this.$store.commit('form/updateField', {
          model: this.storePath,
          value: _.filter(this.getField(), file => file.name !== removedFile.name),
        });
      },

      set() {
        clearTimeout(this.interval);
        this.interval = setTimeout(() => this.change(_.get(this.$refs, 'inputFile.files')), this.timeout);
      },
    },
  }
</script>
