import Vue from 'vue';

export default {
  namespaced: true,

  mutations: {
    success(state, {message, timeHidden = 10000}) {
      Vue.$toast.open({message: message, type: 'success', duration: timeHidden});
    },

    error(state, {message, timeHidden = 10000}) {
      Vue.$toast.open({message: message, type: 'error', duration: timeHidden});
    },

  },
};
