<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <header-component />
    <div class="university__page-container">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url(${require('@/assets/images/bg-rus.png')});`"></div>
        <div class="container">
          <ul class="breadcrumbs">
            <li class="breadcrumbs-item">
              <a href="#">Главная страница</a>
            </li>
            <li class="breadcrumbs-item">
              <a href="#">Почему Россия</a>
            </li>
            <li class="breadcrumbs-item">
              10 причин пройти обучение в России
            </li>
          </ul>

          <h1 class="title-1">
            Интеллектуальные системы обработки информации и управления
          </h1>
        </div>
      </div>
      <div class="container">
        <div class="universities__info">
          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">01</span>
                Фундаментальный характер университетского образования
              </h2>
              <p>
                В России можно получить глубокие, фундаментальные знания по всем направлениям, но больше всего страна известна своими
                сильными научными школами в области физико-математических и естественных наук. Россия – признанный мировой лидер в
                подготовке математиков, физиков, химиков, геологов, инженеров, программистов, врачей и специалистов других
                естественнонаучных областей. Это подтверждается позициями российских вузов в международных рейтингах.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">02</span>
                Большой выбор университетов <br>
                и образовательных программ
              </h2>
              <p class="mb10">
                Иностранных абитуриентов в России ждут:
              </p>
              <ul class="list-disc list-sub">
                <li>766 университетов в 82 регионах страны – от Калининграда до Владивостока.</li>
                <li>205 направлений подготовки – от математических и естественных наук до актерского искусства.</li>
                <li>657 специальностей по программам бакалавриата, магистратуры, специалитета, аспирантуры (ординатуры, адъюнктуры),
                  ассистентуры-стажировки.
                </li>
              </ul>
              <p>
                Российские вузы также предлагают довузовские подготовительные программы (подготовка в бакалавриат, специалитет и
                магистратуру), краткие программы (летний университет, семестр в России, летние школы), программы изучения русского языка как
                иностранного, программы профессиональной переподготовки и повышения квалификации.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">03</span>
                Оптимальное соотношение цены и качества образования
              </h2>
              <p>
                Обучение на контрактной основе в российских вузах гораздо дешевле, чем в университетах США, Канады и Великобритании, а по
                уровню подготовки ведущие вузы России составляют достойную конкуренцию западным образовательным организациям.
              </p>
              <p>
                В 2019 году стоимость обучения в российских вузах на очных программах бакалавриата начиналась от 83 тысяч рублей в год (1277
                долларов) в зависимости от специальности (согласно нормативу, установленному Министерством науки и высшего образования
                Российской Федерации). Максимальный же показатель стоимости в самых престижных столичных вузах страны доходил до 586 000
                рублей в год (9000 долларов) по данным рейтингового агентства «Эксперт».
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">04</span>
                Возможность обучения
                на бюджетной основе
              </h2>
              <p>
                Россия – одна из немногих стран, предоставляющих иностранцам возможность бесплатного обучения. Ежегодно российское
                правительство выделяет несколько тысяч бюджетных мест в российских вузах для иностранных граждан (в 2019 году было выделено
                15 000 мест). Кроме того, некоторые категории иностранцев, в том числе соотечественники, могут поступать на бюджетные места
                вне квот наравне с россиянами. Еще одна возможность – принять участие в университетских олимпиадах, победителям и призерам
                которых предоставляются льготы при поступлении в ведущие российские вузы.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <img alt="" src="@/assets/images/reason-img.png">
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">05</span>
                Возможность изучения русского языка
              </h2>
              <p>
                На русском языке говорят около 260 миллионов жителей планеты, постоянно изучают русский около 10,5 миллионов иностранцев.
                Российские вузы предлагают разные программы по изучению русского языка – курсы, летние школы, обучение в дистанционном
                режиме. Для иностранцев в рамках подготовительного отделения предусмотрен годичный курс обучения русскому языку, для
                студентов, поступивших по квотам (госстипендиям), он бесплатен.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">06</span>
                Возможность получить двойные дипломы
              </h2>
              <p>
                В 2003 году Россия подписала Болонскую декларацию, приняв на себя ряд обязательств в рамках создания общего образовательного
                пространства стран-участниц. Дипломы российских вузов признают в большинстве государств мира. Облегчают эту процедуру
                межправительственные соглашения России более чем с 60 странами, в том числе с Финляндией, Испанией, Кубой, Индией,
                Вьетнамом, Китаем, Венесуэлой, Намибией, в июне 2015 года такой документ был подписан с Францией, а в октябре и ноябре 2016
                года – с Кипром и Мальтой, в мае 2019 года – с Узбекистаном. Кроме того, многие российские вузы реализуют совместные
                программы с зарубежными университетами, выдавая выпускникам двойные дипломы. То есть, учась в России, можно получить и
                российский диплом, и, к примеру, диплом европейского вуза.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">07</span>
                Удобные и оснащенные кампусы и общежития
              </h2>
              <p>
                Российские вузы по степени оснащенности учебных комплексов давно не уступают многим западным университетам. Высокий уровень
                технической оснащенности лабораторий, научных центров и учебных корпусов – это реалии сегодняшней студенческой жизни. У
                большинства российских вузов есть собственные общежития, стоимость проживания в которых достаточно низкая.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">08</span>
                Россия – страна с великой культурой
              </h2>
              <p>
                Культура – это то, с чем чаще всего ассоциируют Россию иностранцы, и чем гордится большинство россиян. Россия дала миру
                великих писателей, поэтов, философов, композиторов, художников и музыкантов – Лев Толстой, Антон Чехов, Федор Достоевский,
                Николай Рерих, Сергей Рахманинов, Борис Пастернак, Петр Чайковский, Сергей Эйзенштейн, Мстислав Ростропович... Литература,
                балет, классическая музыка, архитектура, живопись, театр, кинематограф – это то, чем Россия завоевывает сердца людей по
                всему миру. Российские города – центры культурной жизни, где постоянно проходят выставки, фестивали, концерты, показы,
                перформансы и другие культурные и развлекательные мероприятия международного и национального уровней.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <img alt="" src="@/assets/images/reason-img2.png">
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">09</span>
                Возможность заниматься спортом
              </h2>
              <p>
                Россияне – спортивная нация, давшая миру великих спортсменов: Ирина Роднина, Вячеслав Третьяк, Мария Шарапова, Евгений
                Плющенко, Александр Овечкин, Елена Исинбаева, Александр Поветкин… Спортом в России занимаются более 60% жителей и 71%
                студентов. Спортивные объекты – 70 000 спортивных залов, 140 000 спортплощадок, 4 800 бассейнов – доступны всем желающим.
                Российские университеты, как правило, имеют собственную спортивную инфраструктуру и предлагают своим учащимся отличные
                возможности для занятий спортом. При вузах создаются спортивные команды, побеждающие на престижных соревнованиях, в том
                числе Универсиадах. Страна не раз становилась хозяйкой спортивных турниров мирового уровня – в 2014 году состоялись зимние
                Олимпийские игры в Сочи, гонки Формулы-1, в 2018 году прошел Чемпионат мира по футболу, в 2019 – Всемирная зимняя
                универсиада. В 2020 году состоятся четыре матча Чемпионата Европы по футболу, Чемпионат мира по хоккею с мячом, Всемирная
                шахматная олимпиада. В 2023 году Екатеринбург примет Всемирную летнюю универсиаду.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2 title-counter">
                <span class="title-counter-item">10</span>
                Путешествия <br>
                круглый год
              </h2>
              <p>
                Большой театр, Третьяковская галерея, Пушкинский музей, Эрмитаж, Исаакиевский собор – известные на весь мир российские
                культурные достопримечательности, ежегодно привлекающие миллионы туристов. Однако это лишь малая часть того, где стоит
                побывать. В список всемирного наследия ЮНЕСКО включены 30 объектов российской культуры. Помимо Кремля с Красной площадью,
                туда вошли памятники Новгорода, Суздаля, Владимира, Троице-Сергиева Лавра, озеро Байкал, вулканы Камчатки, Золотые горы
                Алтая, Новодевичий монастырь, Куршская коса и другие. Многие вузы организуют экскурсионные поездки для студентов. Выбор
                практически неограничен – от Золотого кольца, охватывающего восемь древнерусских городов, до путешествия по легендарному
                маршруту Транссиба.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <footer-component />
  </div>
</template>


<script>
  import HeaderComponent from '@/components/site/blocks/Header.vue';
  import FooterComponent from '@/components/site/blocks/Footer.vue';

  export default {
    name: 'Reasons',
    components: {
      HeaderComponent,
      FooterComponent,
    },
    computed: {},
    data() {
    },
  };
</script>
