<template>
  <div>
    <transition name="fade">
      <Form :form-callback="createMessage" v-if="!shownButtonNewMessage">
        <template slot="form">
          <div class="forum__topics-form def-container">
            <Input
              :label="$t('themeTitle')"
              element-class="txt"
              :placeholder="$t('enterAThemeName')"
              store-path="forum.message.theme"
              type="text"
              rules="required|max:100|min:1"
              v-if="type === 'theme'"
              :value="editorData"
            />

            <div
              class="form-row"
              :class="{'quote-active': type === 'quote', 'response-active': type === 'response'}"
              v-if="type !== 'theme'"
            >
              <label>
                {{ $t('message') }}
              </label>
              <div class="ck-editor">
                <ckeditor 
                  :editor="editor" 
                  v-model="editorData" 
                  :config="editorConfig" 
                  rules="required|max:1000|min:1"
                  class="lol"
                >
                  <div slot="row" v-if="type === 'quote'">
                    <div class="quote">
                      <h5 class="title-5">{{ data && data.user.username }}</h5>
                      <p>{{ quote }}</p>
                    </div>
                  </div>
                  <div slot="row" v-if="type === 'response'">
                    <div class="response">
                      <a href="javascript:void(0)">@{{ data && data.user.username }}</a>
                    </div>
                  </div>
              </ckeditor>
              </div>
            </div>

            <div class="feedback-form-row">
              <div class="dropzone">
                <InputFile
                  v-if="type !== 'theme'"
                  store-path="forum.message.file"
                  :text-empty-field="$t('filesOfTheFormatPdfJpgPngUpTo10MB')"
                />
              </div>

              <div class="form-row__btn">
                <a
                  href="javascript:void(0)"
                  class="btn btn-outline"
                  @click="closeForm"
                >{{ $t('cancel') }}</a>

                <Button 
                  type="submit" 
                  class="btn" 
                  :text="edit ? $t('edit') : $t('create')" 
                />
              </div>
            </div>
          </div>
        </template>
      </Form>
    </transition>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import Input from '@/components/generic/form/field/Input';
  import Form from '@/components/generic/form/Form';
  import Button from '@/components/generic/form/Button';
  import InputFile from '@/components/generic/form/field/InputFile';
  import {helpers} from '@/_services/helpers.service';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

  export default {
    components: {
      Input,
      Form,
      Button,
      InputFile,
    },

    data: () => ({
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: ['heading','bold','italic','link','bulletedList','numberedList'],
      },
      quote: '',
    }),

    computed: {
      ...mapGetters({
        form: 'form/forms',
        shownButtonNewMessage: 'help/getShownButtonNewMessage',
      }),
    },

    methods: {
      ...mapActions({
        setForumPost: 'help/setForumPost',
        setForumDiscussion: 'help/setForumDiscussion',
        updateForumDiscussion: 'help/updateForumDiscussion',
        updateForumPostById: 'help/updateForumPostById',
      }),

      closeForm() {
        this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage);

        setTimeout(function() {
          this.$store.commit('form/updateField', {model: 'forum.message.file', value: null})
          this.$store.commit('form/updateField', {model: 'forum.message.text', value: null})
          this.$store.commit('form/updateField', {model: 'forum.message.theme', value: null})
        }.bind(this))
      },

      async getFilesData(files) {
        return Promise.all(files.map(item => helpers.convertFileToBase64(item)));
      },

      async createMessage() {
        if (this.type !== 'theme') {
          let body;
          let files = this.form.forum.message.file
            ? [ ...this.form.forum.message.file ] : [];

          if (files.length) {
            const baseFiles = await this.getFilesData(files.slice(0));
            files = files.map((file, index) => {
              let f = {};
              f.name = file.name;
              f.type = file.type;
              f.data = baseFiles[index];
              return f;
            });
          }

          if (this.type === 'quote') {
            body = `<div class="quote">
            <h5 class="title-5">${ this.data && this.data.user.username }</h5>
            <p>${this.quote}</p>
          </div> ${this.editorData}`;
          }

          if (this.type === 'response') {
            body = `<div class="response">
            <a href="javascript:void(0)">@${ this.data && this.data.user.username }</a>
          </div> ${this.editorData}`;
          }
          if (this.edit) {
            const dataPost = {
              discussion_id: this.parenId.id,
              body: body || this.editorData,
              files,
            }
            this.updateForumPostById({
              id: this.edit,
              data: dataPost,
            }).then(() => {
              if (typeof this.sendCallback ==='function') {
                this.sendCallback();
              }

              this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage);
            })
          } else {
            this.setForumPost({
              discussion_id: this.parenId.id,
              body: body || this.editorData,
              files,
            }).then(() => {
              if (typeof this.sendCallback ==='function') {
                this.sendCallback();
              }

              this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage);
            })
          }
        } else {
          if (this.edit) {
            const dataDiscution = {
              title: this.form.forum.message.theme,
              category_id: this.parenId.id,
              can_post: true,
            }
            this.updateForumDiscussion({
              id: this.edit,
              data: dataDiscution,
            }).then(() => {
              if (typeof this.sendCallback ==='function') {
                this.sendCallback();
              }

              this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage);
            })
          } else {
            this.setForumDiscussion({
              title: this.form.forum.message.theme,
              category_id: this.parenId.id,
              can_post: true,
            }).then(res => {
              if (typeof this.sendCallback ==='function') {
                this.sendCallback(res);
              }

              this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage)
            })
          }
        }

        setTimeout(function() {
          this.$store.commit('form/updateField', {model: 'forum.message.file', value: null})
          this.$store.commit('form/updateField', {model: 'forum.message.text', value: null})
          this.$store.commit('form/updateField', {model: 'forum.message.theme', value: null})
        }.bind(this))
      },

      getHTMLOfSelection() {
        var range;
        if (document.selection && document.selection.createRange) {
          range = document.selection.createRange();
          return range.htmlText;
        }
        else if (window.getSelection) {
          var selection = window.getSelection();
          if (selection.rangeCount > 0) {
            range = selection.getRangeAt(0);
            var clonedSelection = range.cloneContents();
            var div = document.createElement('div');
            div.appendChild(clonedSelection);
            return div.innerHTML;
          }
          else {
            return '';
          }
        }
        else {
          return '';
        }
      },

      parseMessage(text) {
        const sourseText = document.createElement('div');
        sourseText.innerHTML = text;
        if (sourseText.firstElementChild) {
          this.editorData = sourseText.lastElementChild.outerText
        } else {
          this.editorData = sourseText.outerText
        }
      },

      updateForm() {
        if (this.type === 'quote') {
          this.quote = this.getHTMLOfSelection() ? this.getHTMLOfSelection() : this.data.body;
          if (this.getHTMLOfSelection()) {
            this.quote = this.getHTMLOfSelection();
          } else {
            let str = /<p[^>]*>([\s\S]*?)<\/p>/gim.exec(this.data.body);
            this.quote = str && str.length ? str[str.length - 1] : this.data.body
          }
        }
        if (this.edit) this.editorData = this.data.body
        else this.editorData = ''
        if (this.type === 'quote' || this.type === 'response') {
          this.edit && this.parseMessage(this.data.body)
        }
      },
    },

    props: {
      data: Object,
      type: String,
      parenId: Object,
      sendCallback: Function,
      edit: Number,
      value: String,
    },

    watch: {
      type() {
        this.updateForm();
      },

      shownButtonNewMessage() {
        this.updateForm();
      },
    },
  }
</script>
<style>
  .ck-editor i {
    font-family: auto;
  }

  .ck-editor ol li {
    list-style: decimal;
  }

  .ck-editor ul li {
    list-style: inside;
  }

  .ck-editor ul,ol {
    padding-left: 20px !important;
  }
</style>

