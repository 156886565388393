<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <!--  eslint-disable   -->
  <div class="home-page">
    <div class="base-container">
      <div
        class="container def-container"
        v-if="step === 'questionnaire'"
      >
        <div class="row">
          <div class="col-4">
            <h1 class="title-2 mb16">
              {{ $t('profile') }}
            </h1>

            <CareerTestsForm />
          </div>
        </div>
      </div>

      <div
        class="container def-container def-txt"
        v-if="step === 'manual'"
      >
        <div class="row">
          <div class="col-4">
            <h1 class="title-2 mb16">
              {{ $t('instruction') }}
            </h1>

            <p>
              {{ testData | lang('description') }}
            </p>

            <div class="form-row__btn">
              <a
                class="btn"
                href="javascript:void(0)"
                @click="() => setStep('questionnaire')"
              >{{ $t('back') }}</a>

              <a
                class="btn"
                href="javascript:void(0)"
                @click="() => setStep(0)"
              >{{ $t('next') }}</a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container def-container"
        v-if="step >= 0 && testData.questions"
      >
        <div class="test__wrapper">
          <div
            class="test__wrapper-content"
            style="width: 100%"
            v-for="(question, key) in testData.questions"
            v-bind:key="key"
            v-show="key === step"
          >
            <h1 class="title-2 light">
              {{ $t('question') }} {{ step + 1 }}/{{ testData.questions.length }}
            </h1>
            <p class="title-4">
              {{ question | lang('text') }}
            </p>

            <form class="test__form" v-if="question">
              <InputRadio
                :element-id="`radio-${key}-${k}`"
                :name="`radio[${key}][]`"
                type="radio"
                :store-path="`careerTests.answers.${key}`"
                :value="answer.id.toString()"
                v-for="(answer, k) in question.answers"
                v-bind:key="k"
              >
                <template slot="body">
                  <span>{{ answer | lang('text') }}</span>
                </template>
              </InputRadio>

              <div class="form-row__btn">
                <a
                  class="btn"
                  href="javascript:void(0)"
                  @click="() => setStep(step > 0 ? -1 : 'manual')"
                >{{ $t('back') }}</a>

                <Button
                  class="btn"
                  href="javascript:void(0)"
                  :callback="() => setStep(testData.questions.length - 1 === step ? 'fin' : +1)"
                  :is-disabled="isDisabled(step)"
                  :text="$t('next')"
                />
              </div>
            </form>
          </div>

          <div class="test__wrapper-steps">
            <div class="timer" hidden>00:01:32</div>

            <ol class="test__steps" ref="questionsList">
              <li
                class="test__steps-item"
                :class="{current: step === key}"
                v-for="(question, key) in testData.questions"
                v-bind:key="key"
              >
                <span :id="`${key}answer`" :ref="`${key}answer`">{{ $t('areYouHere') }}</span>
              </li>
            </ol>

            <div class="test__range">
              {{ $t('passed') }}: {{ percentTest }}%
              <span class="test__range-line">
                <span :style="`width: ${percentTest}%;`"></span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <CareerTestsReport :data="testReport" :data-request="dataRequest" />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {mapGetters, mapActions} from 'vuex';
  import CareerTestsForm from '@/components/site/pages/educationInRussia/career/generic/CareerTestsForm'
  import InputRadio from '@/components/generic/form/field/InputRadio';
  import CareerTestsReport from '@/components/site/pages/educationInRussia/career/generic/CareerTestsReport';
  import Button from '@/components/generic/form/Button';

  export default {
    name: 'career-tests',

    components: {
      Button,
      CareerTestsForm,
      InputRadio,
      CareerTestsReport,
    },

    computed: {
      ...mapGetters({
        forms: 'form/forms',
        params: 'educationInRussia/getParams',
      }),

      step: self => self.$store.state.educationInRussia.params.careerTests.step,
    },

    methods: {
      ...mapActions({
        getTestingById: 'educationInRussia/getTestingById',
        setTestingCheckTestResult: 'educationInRussia/setTestingCheckTestResult',
      }),

      setStep(step) {
        this.$store.commit(
          'educationInRussia/updateParam',
          {
            model: 'careerTests.step',
            value: _.isNumber(step) ? step + (_.isNumber(this.step) ? this.step : 0) : step,
          },
        )

        if (step === 'fin') {
          _.each(this.testData.questions, (questions, key) => {
            this.dataRequest.data.push({
              question_id: questions.id,
              answer_id: this.forms.careerTests.answers[key],
            })
          });

          this.setTestingCheckTestResult(this.dataRequest).then(res => {
            this.testReport = res.data;
          });
        }
      },

      setPercentTest() {
        if (_.isNumber(this.step)) {
          this.percentTest = Math.round((100 / ((this.testData.questions || []).length)) * (this.step + 1));
        }
      },

      isDisabled(step) {
        return !(_.get(this.forms, `careerTests.answers.${step}`) || _.isString(step));
      },
    },

    data() {
      return {
        activeItem: 'item',
        percentTest: 0,
        currentTestKey: 0,
        testMap: [],
        testData: {},
        answers: {},
        testReport: {},
        dataRequest: {
          test_id: 1,
          data: [],
        },
      };
    },

  mounted() {
      this.$store.commit('preloader/isLoading', true)
      this.getTestingById(1).then(res => {
        this.testData = res.data

        _.each(this.testData.questions, (questions, key) => {
          _.assign(this.answers, {[key]: {}});
        });

        this.setPercentTest();
      }).finally(() => this.$store.commit('preloader/isLoading', false))
    },

    watch: {
      step(step) {
        this.setPercentTest();

        if (this.$refs.questionsList && this.$refs[`${step}answer`]) {
          if (this.$refs[`${step}answer`][0]) {
            this.$refs.questionsList.scroll(0, (step * 42) - 42 * 9)

//            this.$refs.questionsList.scroll(0, (step * 42) - 189)
          }
        }
      },
    },
  };
</script>
