<template>
  <div class='EventCard event-card-info'>
    <div class='row'>
      <div class="col-6">
        <div class='event-card-type'>{{ (event.type || {}).name }}</div>
        <div class='event-card-title'>
          <router-link :to='`/event/${event.id}`'>
            {{ event.name }}
          </router-link>
        </div>
        <div class='event-card_date-time'>
          <img :src="timeIcon"/> 
          {{ dateEvent(event.start_date, event.end_date) }} 
          • 
          {{ timeEvent(event.start_time, event.end_time) }}
        </div>
        <div class='event-card_location-link'>
          <span>
            <img :src="locationIcon" />
            <a 
              class='light'
              v-if="event.format.code === 'ONLINE'"
              :href="url_link(event.url)"
            > 
              {{ event.url ? `${$t('online')}, ${event.url}` : event.location }}
            </a>
            <span class='light' v-else>{{ ` ${event.location}` }}</span>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import timeIcon from '@/assets/images/time-icon.png';
import locationIcon from '@/assets/images/ic_location.svg';

export default {
  name: 'event-card-home',

  props: {
    event: Object,
  },

  data: () => ({
    timeIcon: timeIcon,
    locationIcon: locationIcon,
  }),

  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
  },
}
</script>
