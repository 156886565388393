export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Trợ giúp",
  personalArea: "Tài khoản cá nhân",
  myApplications: "Đơn đăng ký của tôi",
  servicesAndFacilities: "Dịch vụ",
  logout: "Thoát",
  favorites: "Đã chọn",
  theChoice: "Lựa chọn",
  notifications: "Thông báo",
  logIn: "Đăng nhập",
  quickAccessThroughSocialNetworks: "Đăng nhập nhanh qua mạng xã hội",
  enterYourEmail: "Hãy nhập e-mail của bạn",
  password: "Mật khẩu",
  enterPassword: "Hãy nhập mật khẩu",
  rememberMe: "Ghi nhớ tôi",
  forgotYourPassword: "Quên mật khẩu?",
  noAccount: "Không có tài khoản?",
  registration: "Đăng ký",
  countryOfCitizenship: "Quốc tịch",
  chooseYourCountryOfCitizenship: "Chọn quốc tịch",
  iHaveReadAndAgree: "Tôi đã đọc và",
  _toTheProcessingOfMyPersonalData:
    "đồng ý với việc xử lý dữ liệu cá nhân của mình",
  iAgreeTo: "Tôi đồng ý việc",
  _theUseOfCookies: "sử dụng các file cookie",
  signUp: "Đăng ký",
  haveAnAccount: "Có tài khoản?",
  mainPage: "Trang chủ",
  frequentlyAskedQuestionsAnswers: "Câu trả lời cho các câu hỏi thường gặp",
  goTo: "Chuyển ",
  startTesting: "Bắt đầu làm bài kiểm tra",
  buildCareerVector: "Xây dựng vector nghề nghiệp",
  forum: "Diễn đàn",
  textForumBanner:
    "Hãy thảo luận trên diễn đàn chung về tất cả các chủ đề quan trọng đối với bạn: cuộc sống tại các thành phố ở Nga như thế nào, việc học tập tại các trường đại học ở Nga như thế nào, v.v.",
  feedback: "Phản hồi",
  technicalSupportFeedbackForm: "Biểu mẫu phản hồi có hỗ trợ kỹ thuật",
  allCities: "Tất cả các thành phố",
  citiesOfRussia: "Các thành phố ở Nga",
  educationInRussia: "Học tập tại Nga",
  educationalOrganizations: "Các trường đại học",
  educationInRussiaYourKeyToSuccess:
    "Giáo dục ở Nga - chìa khóa đến thành công của bạn",
  applyForStudyInRussia: "Hãy nộp đơn đăng ký học tại Nga",
  apply: "Nộp đơn đăng ký",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Hãy đăng nhập hoặc đăng ký để có quyền truy cập vào định hướng nghề nghiệp và nộp đơn đăng ký",
  aboutOrganization: "Về tổ chức",
  eventsAndOlympiads: "Sự kiện và các cuộc thi olympic",
  educationalPrograms: "Các chương trình",
  contacts: "Liên hệ",
  inFavorites: "Trong mục yêu thích",
  toFavorites: "Chuyển vào yêu thích",
  fromChoices: "Đã chọn",
  choices: "Chọn",
  generalInformation: "Thông tin chung",
  internationalRatings: "Xếp hạng quốc tế",
  nationalRatings: "Xếp hạng quốc gia",
  ratings: "Xếp hạng",
  year: "Năm",
  ratingName: "Tên xếp hạng",
  aPlace: "Vị trí",
  screeningTests: "Sát hạch tuyển chọn",
  choiceOfProfession: "Lựa chọn nghề nghiệp",
  lifeInRussia: "Cuộc sống ở Nga",
  russianLanguage: "Tiếng Nga",
  livingConditions: "Điều kiện sống",
  cultureAndLeisure: "Văn hóa và giải trí",
  safety: "An toàn",
  benefitsForInternationalStudents:
    "Những ưu đãi dành cho sinh viên nước ngoài",
  workForInternationalStudents: "Việc làm dành cho sinh viên nước ngoài",
  studentVisaAndRegistration: "Thị thực du học và đăng ký",
  healthInsurance: "Bảo hiểm y tế",
  howToEnter: "Nhập học như thế nào",
  educationLevels: "Các cấp độ giáo dục",
  admissionProcess: "Quy trình nhập học",
  olympics: "Olympic",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Trang web chính thức về tuyển sinh công dân nước ngoài đến học tập tại Nga, năm {year}",
  whyRussia: "Tại sao là nước Nga",
  "10reasonsToStudyInRussia": "10 lý do học tập tại Nga",
  reviewsOfForeignStudents: "Nhận xét của sinh viên nước ngoài",
  onlineMonitoring: "Giám sát trực tuyến",
  mainPrograms: "Các chương trình cơ bản",
  additionalPrograms: "Các chương trình bổ sung",
  chooseCounty: "Hãy lựa chọn khu vực",
  county: "Vùng",
  chooseACity: "Hãy lựa chọn thành phố",
  city: "Thành phố",
  chooseYourLevelOfEducation: "Hãy chọn trình độ giáo dục",
  chooseYourAreaOfScientificKnowledge: "Chọn lĩnh vực kiến ​​thức khoa học",
  chooseYourScientificSpecialtyProfile: "Chọn một hồ sơ của một chuyên ngành khoa học",
  areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Trình độ giáo dục",
  chooseAGroupOfTrainingAreas: "Hãy lựa chọn nhóm ngành đào tạo",
  trainingDirectionGroup: "Nhóm các ngành đào tạo",
  chooseATrainingDirection: "Hãy chọn ngành đào tạo",
  trainingDirection: "Ngành đào tạo",
  chooseRatings: "Hãy lựa chọn xếp hạng",
  thePresenceOfThePreparatoryFaculty: "Có khoa dự bị",
  show: "Hiển thị",
  _university: "trường đại học",
  _universities: "trường đại học ",
  __universities: "của các trường đại học",
  clearAllFilters: "bỏ tất cả các bộ lọc",
  chooseSemester: "Hãy lựa chọn học kỳ",
  semester: "Học kỳ",
  enterTheNameOfTheEducationalOrganization: "Hãy nhập tên trường đại học",
  _educationalOrganization: "trường đại học",
  _educationalOrganizations: "trường đại học ",
  __educationalOrganizations: "của các trường đại học",
  chooseYourAreaOfEducation: "Hãy lựa chọn lĩnh vực giáo dục",
  areaOfEducation: "Lĩnh vực giáo dục",
  chooseYourLanguageOfInstruction: "Hãy lựa chọn ngôn ngữ đào tạo",
  languageOfInstruction: "Ngôn ngữ đào tạo",
  _program: "chương trình",
  _programs: "các chương trình",
  __programs: "các chương trình",
  formOfTraining: "Hình thức đào tạo",
  level: "Cấp độ",
  typeOfTraining: "Loại hình đào tạo",
  free: "Miễn phí",
  paid: "Có phí",
  tuitionFees: "Mức giá đào tạo có phí",
  established: "Ngày thành lập",
  numberOfStudents: "Số lượng sinh viên",
  _students: "sinh viên",
  numberOfQuotaPlaces: "Số lượng chỗ theo hạn ngạch",
  _for: "dành cho",
  numberOfInternationalStudents: "Sinh viên nước ngoài",
  _educationalPrograms: "các chương trình giáo dục",
  moreDetails: "Cụ thể",
  sTEP: "BƯỚC",
  afterTrainingForThisProgramYouCanBecome:
    "Sau khi học xong chương trình này, bạn có thể trở thành",
  chooseProgram: "Lựa chọn chương trình",
  chooseUniversity: "Lựa chọn trường đại học",
  print: "In",
  save: "Lưu",
  programs: "Các chương trình",
  selectedProgram: "chương trình đã được lựa chọn",
  noProgramSelected: "Chương trình chưa được lựa chọn",
  selectedEducationalOrganizations: "Các trường đại học được chọn",
  educationalOrganizationNotSelected: "Trường đại học chưa chọn",
  representation: "Văn phòng đại diện",
  programDescription: "Mô tả chương trình",
  programPageOnTheUniversityWebsite:
    "Trang chương trình trên website của trường đại học",
  programCurator: "Người phụ trách chương trình",
  disciplines: "Các môn học",
  theProcessOfEnteringARussianEducationalOrganization:
    "Quy trình nhập học vào trường đại học Nga",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Hãy lựa chọn chương trình và lựa chọn tối đa 6 trường đại học",
  takeTheCompetitiveSelectionInYourCountry:
    "Tham dự kỳ thi tuyển chọn tại quốc gia của bạn",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Hãy điền vào đơn đăng ký trực tuyến và đính kèm hồ sơ tài liệu",
  getAnInvitationToStudAnStudyVisa: "Hãy nhận thư mời học và thị thực du học",
  preparatoryFaculties: "Các khoa dự bị",
  summerAndWinterSchools: "Các khóa học mùa hè và mùa đông",
  russianLanguageCourses: "Các khóa học tiếng Nga",
  tutorsTheRussianLanguage: "Gia sư tiếng Nga",
  certificationInRussian: "Chứng chỉ tiếng Nga",
  news: "Tin mới",
  allNews: "Tất cả tin tức mới",
  reviewsOfStudentsAndGraduates:
    "Nhận xét của sinh viên và sinh viên đã tốt nghiệp",
  areYouSureYouWantToWithdrawTheApplication:
    "Bạn có chắc chắn muốn rút đơn đăng ký của mình không?",
  withdrawApplication: "Rút đơn đăng ký",
  jan: "Tháng Một",
  feb: "Tháng Hai",
  mar: "Tháng Ba",
  apr: "Tháng Tư",
  may: "Tháng Năm",
  jun: "Tháng Sáu",
  jul: "Tháng Bảy",
  aug: "Tháng Tám",
  sep: "Tháng Chín",
  act: "Tháng Mười",
  nov: "Tháng Mười Một",
  dec: "Tháng Mười Hai",
  cityFeatures: "Đặc điểm của thành phố",
  enterTheNameOfTheCity: "Hãy nhập tên thành phố",
  organizations: "Tổ chức",
  livingWage: "Mức chi phí đủ sống",
  "one-roomApartmentForRent": "Thuê căn hộ 1 phòng",
  _month: "chỗ",
  averageTemperature: "Nhiệt độ trung bình",
  _inWinter: "mùa đông",
  _inSummer: "mùa hè",
  averageCostOfDormitories: "Mức giá trung bình của ký túc xá ",
  photos: "Ảnh",
  interestingPlaces: "Những địa điểm thú vị",
  readCompletely: "Đọc toàn bộ",
  _review: "nhận xét",
  _reviews: "nhận xét",
  __reviews: "nhận xét",
  university: "Trường đại học",
  universities: "Các trường đại học",
  country: "Quốc gia",
  selectACountry: "Hãy chọn quốc gia",
  reviewOfAForeignStudent: "Nhận xét của sinh viên nước ngoài",
  careerGuidance: "Hướng nghiệp",
  determineYourAreaOfEducationAndProfession:
    "Hãy xác định lĩnh vực giáo dục và nghề nghiệp phù hợp với bạn",
  myCareer: "Sự nghiệp của tôi",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Làm thế nào để kết hợp việc học tập với giải trí văn hóa và các mối quan hệ mới. Đặc điểm của các chương trình chuyên sâu và mức phí của các chương trình này",
  toApply: "Áp dụng",
  chooseYear: "Hãy chọn năm",
  _in: "vào",
  universitiesInThisCity: "Các trường đại học trong thành phố này",
  programsInThisCity: "Các chương trình trong thành phố này",
  all: "Tất cả",
  _inYourCountry: "ở đất nước của bạn",
  _common: "Chung",
  allReviews: "Tất cả các đánh giá",
  calendar: "Lịch",
  activity: "Các sự kiện",
  addToPersonalCalendar: "Thêm vào lịch cá nhân",
  eventAdded: "Sự kiện đã được thêm vào",
  eventSuccessfullyAddedToYourCalendar:
    "Sự kiện đã được thêm thành công vào lịch của bạn!",
  population: "Dân cư",
  onTheMap: "Trên bản đồ",
  generalEducation: "Giáo dục phổ thông",
  professionalEducation: "Giáo dục chuyên nghiệp",
  preschoolEducation: "Giáo dục mầm non",
  kindergarten: "Mẫu giáo",
  nursery: "Nhà trẻ",
  earlyDevelopmentCenters: "Trung tâm phát triển sớm",
  primaryAndSecondaryEducation:
    "Giáo dục tiểu học, trung học, trung học phổ thông",
  schools: "Trường học",
  lyceums: "Trường trung học cơ sở",
  gymnasium: "Trường trung học ",
  secondaryVocationalEducation: "Giáo dục nghề nghiệp trung học",
  colleges: "Trường Cao đẳng",
  technicalSchools: "Trường kỹ thuật",
  secondarySchool: "Trường học chuyên ngành",
  higherEducation: "Giáo dục đại học",
  baccalaureate: "Hệ cử nhân",
  specialistDegree: "Hệ chuyên gia",
  magistracy: "Hệ thạc sỹ",
  postgraduateStudies: "Nghiên cứu sinh",
  preparatoryFaculty: "Khoa dự bị",
  summerSchools: "Khóa học mùa hè",
  additionalGeneralAndPreVocationalPrograms:
    "Các chương trình phát triển chung và tiền chuyên nghiệp bổ sung",
  continuingEducationAndRetrainingPrograms:
    "Các chương trình nâng cao nghiệp vụ và đào tạo lại chuyên ngành",
  whatAreTheLevelsOfRussianEducation: "Nền giáo dục tại Nga có các cấp độ nào?",
  enterYourName: "Hãy nhập tên của bạn",
  name: "Tên",
  selectTheSubjectOfTheMessage: "Hãy chọn chủ đề tin nhắn",
  messageSubject: "Chủ đề tin nhắn",
  chooseAMessage: "Hãy chọn tin nhắn",
  message: "Tin nhắn",
  submit: "Gửi",
  frequentlyAskedQuestions: "Các câu hỏi thường gặp",
  didNotFindTheAnswerToYourQuestion:
    "Không tìm thấy câu trả lời cho câu hỏi của bạn",
  contactSupportAt: "Hãy liên hệ đến bộ phận hỗ trợ theo địa chỉ",
  searchInTheSection: "Tìm kiếm trong mục",
  sort: "Sắp xếp",
  searchingResults: "Kết quả tìm kiếm",
  _answers: "các câu trả lời",
  _views: "lượt xem",
  byCreationDate: "Theo ngày tạo",
  byTheNumberOfResponses: "Theo số lượng câu trả lời",
  byViews: "Theo số lượng lượt xem",
  toAnswer: "Trả lời",
  toQuote: "Trích dẫn",
  complain: "Khiếu nại",
  addNewMessage: "Thêm tin nhắn mới",
  selectComplaintType: "Hãy chọn loại khiếu nại",
  typeOfComplaint: "Loại khiếu nại",
  enterComplaintText: "Hãy nhập văn bản khiếu nại",
  complaintText: "Văn bản khiếu nại",
  complaintSentSuccessfully: "Khiếu nại đã được gửi thành công",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Khiếu nại của bạn đã được gửi thành công, chúng tôi sẽ xem xét khiếu nại của bạn trong thời gian gần nhất",
  close: "Đóng",
  myMessages: "Tin nhắn của tôi",
  myTopicsAndPosts: "Các chủ đề và tin nhắn của tôi",
  searchInMyPosts: "Tìm kiếm trong tin nhắn của tôi",
  _posts: "tin nhắn",
  feedbackOnEducationalOrganizations: "Đánh giá về các trường đại học",
  posted: "Đã đăng",
  goToMessage: "Chuyển đến tin nhắn",
  _themes: "chủ đề",
  themeTitle: "Tên chủ đề",
  enterAThemeName: "Hãy nhập tên chủ đề",
  cancel: "Hủy",
  banUser: "Cấm người dùng?",
  areYouSure: "Bạn có chắc chắn?",
  toBan: "Cấm ",
  _logIn: "xác minh quyền truy cập",
  _or: "hoặc",
  _signUp: "đăng ký",
  eventCalendar: "Lịch sự kiện",
  trainingApplicationsFiled: "Đã nộp đơn đăng ký học",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Các trường đại học phổ biến nhất được lựa chọn bởi các ứng viên",
  _of: "từ",
  _whenApplying: "trong khi nộp đơn đăng ký",
  tOP10EducationalOrganizations: "TOP-1O Trường Đại học",
  collapse: "Cuộn",
  showAll: "Hiển thị tất cả",
  conditionsForForeigners: "Các điều kiện dành cho người nước ngoài",
  supportForInternationalStudents: "Hỗ trợ sinh viên nước ngoài",
  lifeOfForeignStudents: "Cuộc sống hàng ngày của sinh viên nước ngoài",
  leisureAndSportingEvents: "Các hoạt động giải trí và thể thao",
  dormitories: "Ký túc xá",
  dormitoryCost: "Mức phí ký túc xá",
  theNumberOfPeopleInTheRoom: "Số người trong phòng",
  from: "Từ",
  _rubles: "rúp",
  _to: "đến",
  _perMonth: "một tháng",
  "2-3People": "2-3 người",
  theInternationalCooperation: "Hợp tác quốc tế",
  photoAndVideo: "Ảnh và video",
  eventTitle: "Tên sự kiện",
  location: "Địa điểm tổ chức",
  date: "Ngày",
  enterYourRealMail: "Hãy điền địa chỉ hòm thư thực tế của bạn",
  requiredField: "Mục bắt buộc phải điền",
  minimumLengthCharacters: "Độ dài tối thiểu {length} ký tự",
  maximumLengthCharacters: "Độ dài tối đa {length} ký tự",
  theFieldMustBeANumber: "Trường phải điền số",
  onlyLettersAreAllowedInTheField:
    "Trong trường chỉ có thể sử dụng các chữ cái",
  checkKeyboardLayout: "Hãy kiểm tra bố cục bàn phím của bạn",
  checkTheNumberYouEnteredIsCorrect:
    "Hãy kiểm tra xem số bạn đã nhập có chính xác không",
  olympiadName: "Tên cuộc thi Olympic",
  description: "Mô tả",
  contactInformation: "Thông tin liên hệ",
  majorForeignPartnerUniversities:
    "Các trường đại học đối tác chính ở nước ngoài",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Cơ hội học tiếng Nga chính quy và từ xa ở Nga trên khắp thế giới",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Bạn là một gia sư? Hãy gửi mẫu đơn để đăng hồ sơ của bạn trên trang web",
  submitApplication: "Nộp mẫu đơn",
  chooseATeachingExperience: "Hãy lựa chọn thâm niên giảng dạy của bạn",
  teachingExperience: "Thâm niên giảng dạy",
  chooseLanguages: "Hãy lựa chọn ngôn ngữ",
  knowledgeOfLanguages: "Thông thạo các ngôn ngữ",
  workExperience: "Thâm niên làm việc",
  trainingLevel: "Trình độ đào tạo",
  durationOfOneLesson: "Thời lượng của một buổi học",
  possibleTrainingTime: "Thời gian có thể học",
  theCosOfOneLesson: "Giá của một buổi học",
  writeAMessage: "Viết tin nhắn",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Bạn có thể viết thư cho gia sư sau khi đăng ký hoặc xác minh quyền đăng nhập trang web",
  educationDocuments: "Tài liệu về giáo dục",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Bạn đã chọn một chương trình khác, nếu chọn chương trình này, chương trình và tổ chức đã chọn sẽ bị mất.",
  limitIsExceeded: "Đã vượt quá giới hạn",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Bạn có thể chọn không quá 6 trường đại học.",
  _fieldsOfEducation: "lĩnh vực giáo dục",
  _trainingAreas: "ngành đào tạo",
  _cities: "các thành phố",
  _languagesOfInstruction: "ngôn ngữ giảng dạy",
  _typesOfAdditionalPrograms: "các loại chương trình bổ sung",
  _semesters: "học kỳ",
  chooseDurationOfStudy: "Hãy chọn thời gian đào tạo",
  _durationOfStudy: "thời gian đào tạo",
  enterTheNameOfTheEducationalProgram: "Hãy nhập tên chương trình",
  instruction: "Hướng dẫn",
  profile: "Mẫu đơn",
  back: "Quay lại",
  next: "Tiếp theo",
  testReport: "Báo cáo kết quả kiểm tra",
  testDate: "Ngày kiểm tra",
  duration: "Thời lượng",
  memberName: "Tên người tham gia",
  age: "Tuổi",
  gender: "Giới tính",
  currentLevelOfEducation: "Trình độ giáo dục hiện tại",
  areasOfInterestHobbies: "Lĩnh vực quan tâm, sở thích",
  sportsAchievements: "Các thành tích thể thao",
  testScaleResults: "Kết quả theo thang điểm kiểm tra",
  scaleDescription: "Mô tả kết quả theo thang điểm",
  recommendedEducationAreas: "Các lĩnh vực giáo dục được đề xuất",
  enterYourAge: "Hãy nhập tuổi của bạn",
  enterYourGender: "Hãy nhập giới tính của bạn",
  chooseYourCurrentLevelOfEducation:
    "Hãy chọn trình độ học vấn hiện tại của bạn",
  chooseYourAreasOfInterestHobbies: "Hãy chọn lĩnh vực bạn quan tâm, sở thích",
  chooseYourSportingAchievements: "Hãy chọn thành tích thể thao của bạn",
  legalizationOfDocuments: "Hợp pháp hóa tài liệu",
  consularLegalization: "Hợp pháp hóa lãnh sự",
  edit: "Chỉnh sửa",
  mobilePhoneNumber: "Số điện thoại di động",
  iWantToReceiveNotificationsByEmail: "Tôi muốn nhận thông báo qua e-mail",
  authorizationCheck: "Kiểm tra quyền đăng nhập",
  createANewApplication: "Tạo đơn đăng ký mới",
  selectionStage: "giai đoạn tuyển sinh ",
  underRevision: "Đang hoàn tất",
  fillingOutAnApplication: "Điền đơn đăng ký ",
  verificationAndRevision: "Kiểm tra và hoàn thiện",
  dossierFormation: "Lập bộ hồ sơ",
  fillingInTheQuestionnaire: "Điền mẫu đơn",
  attachingDocuments: "Đính kèm các tài liệu",
  sendingApplicationForVerification: "Gửi đơn đăng ký để kiểm tra",
  personalData: "Dữ liệu cá nhân",
  surnameInLatinLettersAccordingToPassport:
    "Họ bằng chữ cái Latinh (theo hộ chiếu)",
  nameInLatinLettersAccordingToPassport:
    "Tên (các) bằng chữ cái Latinh (theo hộ chiếu)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Phụ đệm (nếu có) bằng chữ kirin trong phiên âm tiếng Nga",
  placeOfBirthAccordingToPassport: "Nơi sinh (theo hộ chiếu)",
  dateOfBirth: "Ngày sinh",
  compatriotStatus: "Tình trạng đồng hương",
  citizenshipPlaceOfPermanentResidence: "Quốc tịch (nơi cư trú thường xuyên)",
  online: "Online",
  profile2: "Hồ sơ",
  goToTheApplicationForm: "Chuyển đến điền đơn đăng ký",
  areYouHere: "Bạn ở đây",
  russianTextDes1:
    "Tại sao chúng ta cần các khoa dự bị, tại đó chúng ta học những gì, thời gian đào tạo kéo dài bao nhiêu và học phí bao nhiêu?",
  russianTextDes4: "Tìm giáo viên dạy tiếng Nga kèm riêng ở đâu và như thế nào",
  russianTextDes5:
    "Làm thế nào và vì sao cần xác nhận trình độ tiếng Nga của bạn để nhập học vào một trường đại học ở Nga. Hãy tìm hiểu về việc tham gia kiểm tra để nhận chứng chỉ",
  typeOfProgram: "Loại chương trình",
  chooseTheTypeOfAdditionalProgram: "Hãy chọn loại chương trình bổ sung",
  durationOfStudy: "Thời gian đào tạo",
  common: "Chung",
  inYourCountry: "Ở đất nước của bạn",
  courseName: "Tên khóa học",
  courseDuration: "Thời gian của khóa học",
  classDuration: "Thời lượng của giờ học",
  courseCost: "Mức phí của khóa học",
  courseReviews: "Đánh giá về khóa học",
  male: "Nam",
  female: "Nữ",
  feedbackLink: "Đường link đến các bài đánh giá",
  russianLanguageCoursesInYourCountry:
    "Các khóa học tiếng Nga ở quốc gia của bạn",
  evaluation: "Đánh giá",
  giveFeedback: "Để lại nhận xét",
  newFeedback: "Nhận xét mới",
  _hour: "giờ",
  _hours: "giờ",
  __hours: "giờ",
  _minute: "phút",
  _minutes: "phút",
  __minutes: "phút",
  create: "Tạo",
  feedbackSuccess: "Đánh giá đã được gửi thành công và đang chờ kiểm duyệt",
  _y: "năm",
  _from: "from",
  error: "Lỗi",
  direction: "Ngành học",
  _tutor: "gia sư",
  _tutors: "gia sư",
  __tutors: "gia sư",
  filters: "Các bộ lọc",
  sendMessage: "Gửi tin nhắn",
  sendMessageSuccess: "Tin nhắn đã được gửi thành công",
  _year: "năm",
  _years: "năm",
  __years: "năm",
  _back: "quay lại",
  _next: "tiếp tục",
  showing: "Hiển thị",
  textRussianLanguageCourses:
    "Chọn quốc gia cư trú của bạn từ menu và xem các khóa học và trường học ngôn ngữ đang có. Có trình độ tiếng Nga cơ bản từ trong nước sẽ tạo điều kiện thuận lợi rất nhiều cho việc thích nghi khi đến Nga.",
  tOP10Programs: "TOP-10 Chương trình",
  tOP10City: "TOP-10 Thành phố ở Nga",
  createANewTopic: "Tạo chủ đề mới",
  topics: "Chủ đề",
  posts: "Tin nhắn",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "Các file có định dạng .pdf, .jpg, .png lên đến 10 MB",
  noFileSelected: "Chưa chọn file",
  attachFile: "Đính kèm file",
  eMail: "E-mail",
  fullTime: "Chính quy",
  toFind: "Tìm kiếm",
  searchInTopic: "Tìm kiếm trong chủ đề",
  lessThan1Month: "Dưới 1 tháng",
  "1To3Months": "từ 1 đến 3 tháng",
  "3To6Months": "từ 3 đến 6 tháng",
  "6To12Months": "từ 6 đến 12 tháng",
  moreThan1Year: "Trên 1 năm",
  theDateOfThe: "Ngày thực hiện",
  toAdminPanel: "Chuyển đến bảng điều khiển",
  support: "Hỗ trợ kỹ thuật",
  faq: "Câu hỏi thường gặp",
  userGuide: "Hướng dẫn dành cho người dùng",
  textUserGuide: "Hướng dẫn làm việc với cổng thông tin và nộp đơn đăng ký đào tạo",
  textRegisterGuide: "Đăng ký trên trang web Giáo dục ở Nga dành cho người nước ngoài'",
  textTrainingGuide: "Đơn đăng ký học tậptrên trang web Giáo dục ở Nga dành cho người nước ngoài'",
  textApplication: "Điền mẫu đơn",
  instructionForWork: "Hướng dẫn làm việc với đơn đăng ký",
  appeals: "Kháng nghị",
  handling: "Kiến nghị",
  __students: "sinh viên",
  _student: "sinh viên",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Hãy xác định nhóm ngành đào tạo của bạn và tìm ra các phương án có thể có cho nghề nghiệp tương lai của bạn",
  delTheme: "Xóa chủ đề",
  competitionForTheFirstPlace: "Thi tuyển chọn giành vị trí thứ 1 trong khuôn khổ hạn ngạch của Liên bang Nga (người)",
  ruble: "rúp",
  __rubles: "rúp",
  dateOfEvent: "Ngày thực hiện",
  durationUnit: "tháng",
  numberPhone: "Số điện thoại",
  offline: "Gián tuyến",
  to_begin: "vào lúc bắt đầu",
  theDateOfThe: "Ngày bắt đầu",
  chat: "Chatbot",
  yourMessageChat: "Tin nhắn của bạn",
  searchChat: "Tìm kiếm theo hội thoại",
  firstMsgChat: "Hãy nhập tin nhắn của bạn",
  categoryBtnChat_1: "Câu hỏi về việc nộp đơn đăng ký",
  categoryBtnChat_2: "Câu hỏi về việc học tập",
  categoryBtnChat_3: "Câu hỏi về việc thi tuyển chọn",
  categoryBtnChat_4: "Đề xuất hoặc phê bình",
  categoryBtnChat_5: "Phản hồi về hoạt động của trường đại học",
  categoryBtnChat_6: "Khiếu nại về chất lượng dịch vụ",
  categoryBtnChat_7: "Liên hệ với bộ phận hỗ trợ kỹ thuật",
  notQuestionResolvedChat: "Câu hỏi của tôi chưa được giải quyết",
  questionResolvedChat: "Câu hỏi của tôi đã được giải quyết",
  countrySelectTrueChat: "Đã nêu đúng quốc gia",
  salutationChat: "Xin chào.",
  categoryAppealChat: "Để nhận thông tin, hãy chọn danh mục liên quan đến câu hỏi của bạn bằng cách nhấp vào nút. Sau khi chọn danh mục, hãy nhập câu hỏi của bạn vào tin nhắn và chọn nút Gửi. '",
  langAppealChat: "Hãy chọn ngôn ngữ mà bạn muốn sử dụng để gửi kiến nghị.Các kiến nghị gửi bằng tiếng Nga và tiếng Anh được xử lý nhanh chóng nhất.",
  youCountryChatPath1: "Quốc gia của bạn",
  youCountryChatPath2: "Nếu không, vui lòng nêu tên quốc gia trong tin nhắn trả lời.",
  youCountryChat: "Quốc gia của bạn {country}? Nếu không, vui lòng nêu tên quốc gia trong tin nhắn trả lời.",
  notFoundCountryChat: "Không tìm thấy quốc gia có tên như bạn đã nêu. Bạn hãy kiểm tra tính chính xác của dữ liệu đã nhập và gửi lại tin nhắn.",
  additionalInfoChat: "Vui lòng nêu trong tin nhắn phản hồi thông tin bổ sung về kiến nghị của bạn.",
  yourEmailChat: "Xin vui lòng nêu địa chỉ hòm thư điện tử của bạn. Phản hồi về kiến nghị của bạn sẽ được gửi qua e-mail.",
  notValidEmailChat: "Địa chỉ email bạn đã gửi không khớp với định dạng aaa@bbb.ccc. Bạn hãy kiểm tra tính chính xác của dữ liệu đã nhập và gửi lại tin nhắn.",
  acceptRequestChat: "Kiến nghị của bạn đã được gửi đến quản trị viên để xử lý. Thời gian xử lý trung bình là 7 ngày lịch.",
  notFoundEmployeeChat: "Quốc gia bạn đã nêu không có trong danh sách các quốc gia có đào tạo theo chương trình của Rossotrudnichestvo. Kiến nghị của bạn không thể được chuyển đi để xem xét. Vui lòng chỉ định một quốc gia khác. Danh sách đầy đủ các chương trình đào tạo bạn có thể xem trên trang <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
  langBtnChat_1: "Tiếng Anh",
  langBtnChat_2: "Tiếng Việt",
  langBtnChat_3: "Khác",
  addAppBtn: "Nộp đơn đăng ký",
  listAppBtn: "Danh sách đơn đăng ký",
  privacyPolicy: "Chính sách bảo mật",
  ___programs: "chương trình",
  ___educationalPrograms: "các chương trình giáo dục",
  __educationalPrograms: "chương trình giáo dục",
  editTopic: "Chỉnh sửa chủ đề",
  editMessage: "Chỉnh sửa tin nhắn",
  deleteMessage: "Xóa tin nhắn",
  blockingUser: "Chặn người dùng",
  blockEndDate: "Ngày kết thúc chặn",
  blockEndTime: "Hết kết thúc chặn",
  blockForever: "Chặn vĩnh viễn",
  block: "Chặn",
  unlock: "Bỏ chặn",
  edited: "Đã chỉnh sửa",
  founder: "Người sáng lập",
  abbreviation: "Viết tắt",
  studentsByCountry: "Số lượng sinh viên theo quốc gia",
  studentsByCountryCount: "Số lượng sinh viên đang học tập",
  contactsInternationalDepartment: "Thông tin liên hệ của phòng quốc tế",
  site: "Trang web",
  address: "Địa chỉ",
  format: "Định dạng",
  typeEvent: "Loại sự kiện",
  quotaLearning: "Đào tạo theo hạn ngạch",
  kcp: "Số kiểm tra tiếp nhận",
  countSeatsKCP: "Số lượng suất học (Số kiểm tra tiếp nhận)",
  countSeatsCostTraining: "Số lượng suất học trả phí",
  distanceLearning: "Học tập từ xa",
  notificationAboutDistanceLearning: "Việc triển khai chương trình giáo dục có thể thực hiện được với việc sử dụng công nghệ học tập điện tử và giáo dục từ xa. Hỏi rõ thông tin chi tiết tại trường ĐH.",
  additionalTests: "Các ngày tiến hành các bài kiểm tra tuyển chọn bổ sung",
  pageNotFound: "Không tìm thấy trang",
  goToMainPage: "Chuyển đến trang chủ",
  faqInformation: "Tất cả các câu hỏi liên quan đến tình trạng xác minh đơn đăng ký, danh sách các tài liệu cần thiết, thời gian của các bài kiểm tra tuyển chọn, cũng như các câu hỏi khác liên quan đến quá trình nhập học, chúng tôi khuyên bạn nên liên hệ với các văn phòng đại diện của Rossotrudnichestvo hoặc Đại sứ quán của Liên bang Nga được giao quyền thực hiện việc tuyển chọn tại quốc gia của bạn. Bạn có thể tìm thấy địa chỉ liên hệ để liên lạc với nhân viên của Rossotrudnichestvo trong tài khoản cá nhân của mình.",
  allEvents: "Tất cả các sự kiện",
  eventsAndNews: "Sự kiện và tin tức",
  titleNews: "Tên tin tức",
  numberOfBudgetPlaces: "Số suất học bằng ngân sách",
  russianGovernmentQuota: "Ngân sách (số liệu cuối cùng)",
  legalAddress: "Địa chỉ pháp lý",
  actualAddress: "Địa chỉ thực tế",
  goToTheRegister: "Chuyển đến sổ đăng ký các chương trình bổ sung",
  searchForSuitablePrograms:"Tìm kiếm các chương trình phù hợp",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Các chương trình giáo dục phổ biến nhất được lựa chọn bởi các ứng viên",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Các thành phố phổ biến nhất ở Nga được lựa chọn bởi các ứng viên",
  ofTheCountry: "từ đất nước",
  passed: "Đã vượt qua",
  question: "Câu hỏi",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Cổng thông tin đang ở chế độ Vận hành thử nghiệm!",
  banner_2: "Trong thời gian này, người dùng kính mến có thể gặp sự cố kỹ thuật khi sử dụng địa chỉ gửi thư của Google. Xin vui lòng sử dụng các dịch vụ thư khác khi làm việc với cổng thông tin.",
  banner_3: "Đợt tuyển sinh bắt đầu từ ngày 24/12/2022",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
