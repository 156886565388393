<template>
    <div :class="['SliderDetail', {
            'displaynone': displayBlock
        }]">
        <div class="variant-detail">
            <div class="close-black" @click="close">
                <img alt="" src="@/assets/images/close-black.png">
            </div>
            <swiper :options="swiperOption" class="swiper slider-photo variant-detail-item">
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>

                  <swiper-slide
                    v-for="(media, key) in citys"
                    v-bind:key="key"
                  >
                        <img :alt="media.description" :src="media.path" />
                  </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<style>
    .SliderDetail{
        background-size: cover;
        display: flex;
        position: fixed;
        top: 0;
        z-index: 10;
        left: 0;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.5);
        display:none;
    }
    .SliderDetail.displaynone{
        display:flex;
    }
    .SliderDetail .variant-detail{
        height: 100%;
        max-height:600px;
        max-width:900px;
        width:100%;
        position: relative;
    }
    .SliderDetail .slider-photo.variant-detail-item{
        height: 100%;
        width:100%;
    }
    .SliderDetail .slider-photo .swiper-wrapper .swiper-slide {
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
    }
    .SliderDetail .slider-photo img{
        width: 100%;
        height: 100%;
    }
    .SliderDetail .close-black{
        width: 40px;
        height: 40px;
        background: #fff;
        -webkit-box-shadow: 0 0 4px rgba(138, 138, 138, 0.5);
        box-shadow: 0 0 4px rgba(138, 138, 138, 0.5);
        border-radius: 50%;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 11;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
</style>
<script>
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  export default {
    name: 'slider',
    props:{
        citys: {type: Array, required: true, default: () => []},
        index: {type: Number, required: true, default:1},
        display: Boolean,
    },
    components: {
      Swiper,
      SwiperSlide,
    },

    data() {
      return {
        displayBlock:this.display,
        swiperOption: {
          initialSlide: this.index,
          slidesPerView: 1,
          spaceBetween: 32,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 22,
            },
            992: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
          },
        },

        city: {},
      };
    },
    methods: {
        close() {
            this.displayBlock = false;
        },
    },
  }
</script>
