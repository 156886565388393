export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "کمک",
  personalArea: "صفحه شخصی",
  myApplications: "درخواست های من",
  servicesAndFacilities: " خدمات و سرویس ها",
  logout: "خروج",
  favorites: "علاقه مندی ها",
  theChoice: "انتخاب",
  notifications: "اعلان ها",
  logIn: "ورود",
  quickAccessThroughSocialNetworks: "ورود سریع از طریق شبکه های اجتماعی",
  enterYourEmail: "ایمیل خود را وارد نمائید",
  password: "رمزعبور",
  enterPassword: "گذرواژه خود را وارد نمائید",
  rememberMe: "مرا به خاطر بسپار",
  forgotYourPassword: "گذرواژه را فراموش کرده اید؟",
  noAccount: "حسابی ندارید؟",
  registration: "ثبت نام",
  countryOfCitizenship: "کشور تابعیت",
  chooseYourCountryOfCitizenship: "کشور تابعیت خود را انتخاب نمائید",
  iHaveReadAndAgree: "با این قوانین آشنا شده و اجازه میدهم",
  _toTheProcessingOfMyPersonalData: "موافق پردازش اطلاعات شخصی خود هستم",
  iAgreeTo: "رضایت خود را اعلام می دارم",
  _theUseOfCookies: "کاربرد فایلهای cookie",
  signUp: "ثبت نام",
  haveAnAccount: "حساب دارید؟",
  mainPage: "صفحه اصلی",
  frequentlyAskedQuestionsAnswers: "پاسخ به پرسشهای متداول",
  goTo: "رد کردن",
  startTesting: "آغاز کردن آزمون",
  buildCareerVector: "ساختن بردار شغلی",
  forum: "انجمن",
  textForumBanner:
    "در انجمن عمومی درباره تمام موضوعاتی که برای شما مهم هستند، بحث کنید: زندگی در شهرهای روسیه چگونه است ، تحصیل در دانشگاه های روسیه و غیره",
  feedback: "تماس با شما",
  technicalSupportFeedbackForm: "فرم تماس با شما از طرف پشتیبانی فنی",
  allCities: "همه شهرها",
  citiesOfRussia: "شهرهای روسیه",
  educationInRussia: "تحصیل در روسیه",
  educationalOrganizations: "دانشگاه ها",
  educationInRussiaYourKeyToSuccess: "تحصیل در روسیه؛ کلید موفقیت شما",
  applyForStudyInRussia: "ارائه درخواست برای تحصیل در روسیه",
  apply: "ارائه درخواست",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "برای دسترسی به راهنمایی شغلی و ارسال درخواست وارد شوید یا ثبت نام کنید",
  aboutOrganization: "درباره سازمان",
  eventsAndOlympiads: "رویدادها و المپیادها",
  educationalPrograms: "برنامه ها",
  contacts: "تماسها",
  inFavorites: "مورد علاقه",
  toFavorites: "منتخب",
  fromChoices: "انتخاب شده",
  choices: "انتخاب کردن",
  generalInformation: "اطلاعات عمومی",
  internationalRatings: "رتبه بندی بین المللی",
  nationalRatings: "رتبه بندی ملی ",
  ratings: "رتبه بندی ها",
  year: "سال",
  ratingName: "عنوان رتبه بندی",
  aPlace: "رتبه",
  screeningTests: "آزمون های مقدماتی",
  choiceOfProfession: "انتخاب شغل و حرفه",
  lifeInRussia: "زندگی در روسیه",
  russianLanguage: "زبان روسی",
  livingConditions: "شرایط زندگی",
  cultureAndLeisure: "فرهنگ و اوقات فراغت",
  safety: "ایمنی",
  benefitsForInternationalStudents: "امتیازات برای دانشجویان خارجی",
  workForInternationalStudents: "کار برای دانشجویان خارجی",
  studentVisaAndRegistration: "ویزای تحصیلی و ثبت نام",
  healthInsurance: "بیمه درمانی",
  howToEnter: "نحوه پذیرش",
  educationLevels: "مقاطع تحصیلی",
  admissionProcess: "مراحل پذیرش",
  olympics: "المپیادها",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
  "{year} وب سایت رسمی برای گزینش اتباع خارجی جهت تحصیل در روسیه ",
  whyRussia: "چرا روسیه",
  "10reasonsToStudyInRussia": "10 دلیل برای تحصیل در روسیه",
  reviewsOfForeignStudents: "نظرات دانشجویان خارجی",
  onlineMonitoring: "نظارت آنلاین",
  mainPrograms: "برنامه های اصلی",
  additionalPrograms: "برنامه های تکمیلی",
  chooseCounty: "منطقه را انتخاب کنید",
  county: "منطقه",
  chooseACity: "شهر را انتخاب کنید",
  city: "شهر",
  chooseYourLevelOfEducation: "مقطع تحصیلی را انتخاب کنید",
  chooseYourAreaOfScientificKnowledge: "حوزه ای از دانش علمی را انتخاب کنید",
  chooseYourScientificSpecialtyProfile: "پروفایل یک تخصص علمی را انتخاب کنید",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "مقطع تحصیلی",
  chooseAGroupOfTrainingAreas: "یک گروه از زمینه های آموزش را انتخاب کنید",
  trainingDirectionGroup: "گروه رشته های آموزش",
  chooseATrainingDirection: "رشته آموزشی را انتخاب کنید",
  trainingDirection: "رشته های آموزشی",
  chooseRatings: "رتبه بندی را انتخاب کنید",
  thePresenceOfThePreparatoryFaculty: "وجود دانشکده تحصیلات پیش دانشگاهی",
  show: "نمایش",
  _university: "دانشگاه",
  _universities: "دانشگاه",
  __universities: "دانشگاهها",
  clearAllFilters: "پاک کردن تمام فیلترها",
  chooseSemester: "نیمسال تحصیلی را انتخاب کنید",
  semester: "نیمسال تحصیلی",
  enterTheNameOfTheEducationalOrganization: "نام دانشگاه را وارد کنید",
  _educationalOrganization: "دانشگاه",
  _educationalOrganizations: "دانشگاه",
  __educationalOrganizations: "دانشگاهها",
  chooseYourAreaOfEducation: "زمینه تحصیلی خود را انتخاب کنید",
  areaOfEducation: "حوزه آموزش",
  chooseYourLanguageOfInstruction: "زبان تحصیل را انتخاب کنید",
  languageOfInstruction: "زبان تحصیل",
  _program: "برنامه",
  _programs: "برنامه ها",
  __programs: "برنامه",
  formOfTraining: "نوع تحصیل",
  level: "مقطع",
  typeOfTraining: "نوع آموزش",
  free: "رایگان",
  paid: "غیر رایگان",
  tuitionFees: "هزینه تحصیل غیر رایگان",
  established: "تاریخ تأسیس",
  numberOfStudents: "تعداد دانشجویان",
  _students: "دانشجویان",
  numberOfQuotaPlaces: "تعداد سهمیه",
  _for: "در",
  numberOfInternationalStudents: "دانشجویان خارجی",
  _educationalPrograms: "برنامه های تحصیلی",
  moreDetails: "جزئیات بیشتر",
  sTEP: "گام",
  afterTrainingForThisProgramYouCanBecome:
    "پس از تحصیل در این دوره شما موفق به دریافت",
  chooseProgram: "انتخاب برنامه",
  chooseUniversity: "انتخاب دانشگاه",
  print: "چاپ کردن",
  save: "ذخیره کردن",
  programs: "برنامه ها",
  selectedProgram: "برنامه منتخب",
  noProgramSelected: "برنامه انتخاب نشده",
  selectedEducationalOrganizations: "دانشگاههای منتخب",
  educationalOrganizationNotSelected: "دانشگاه انتخاب نشده",
  representation: "نمایندگی",
  programDescription: "شرح برنامه",
  programPageOnTheUniversityWebsite: "صفحه برنامه ها در سایت دانشگاه",
  programCurator: "سرپرست برنامه",
  disciplines: "اصول",
  theProcessOfEnteringARussianEducationalOrganization:
    "روند پذیرش دانشگاه روسی",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "یک برنامه و حداکثر شش دانشگاه انتخاب کنید",
  takeTheCompetitiveSelectionInYourCountry: "کنکور کشور خود را بگذرانید",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "برنامه را بصورت آنلاین پر کنید و مدارک را ضمیمه کنید",
  getAnInvitationToStudAnStudyVisa: "دعوتنامه و ویزای تحصیلی دریافت کنید",
  preparatoryFaculties: "دانشکده های تحصیلات پیش دانشگاهی",
  summerAndWinterSchools: "مدارس تابستانی و زمستانی",
  russianLanguageCourses: "دوره های زبان روسی",
  tutorsTheRussianLanguage: "مدرسان زبان روسی",
  certificationInRussian: "گواهی نامه ها به زبان روسی",
  news: "اخبار",
  allNews: "تمام اخبار",
  reviewsOfStudentsAndGraduates: "نظرات دانشجویان و فارغ التحصیلان",
  areYouSureYouWantToWithdrawTheApplication:
    "آیا مطمئن هستید که می خواهید درخواست خود را پس بگیرید؟",
  withdrawApplication: "بازپس گیری درخواست",
  jan: "ژانویه",
  feb: "فوریه",
  mar: "مارس",
  apr: "آوریل",
  may: "می",
  jun: "ژوئن",
  jul: "ژوئیه",
  aug: "اوت",
  sep: "سپتامبر",
  act: "اکتبر",
  nov: "نوامبر",
  dec: "دسامبر",
  cityFeatures: "ویژگی های شهر",
  enterTheNameOfTheCity: "نام شهر را وارد کنید",
  organizations: "سازمان",
  livingWage: "حداقل هزینه زندگی",
  "one-roomApartmentForRent": "اجاره آپارتمان یک خوابه",
  _month: "ماه",
  averageTemperature: "میانگین دما",
  _inWinter: "زمستان",
  _inSummer: "تابستان",
  averageCostOfDormitories: "میانگین هزینه خوابگاه",
  photos: "عکس",
  interestingPlaces: "مکانهای جالب",
  readCompletely: "خواندن کامل",
  _review: "نظرات",
  _reviews: "نظرات",
  __reviews: "نظرات",
  university: "دانشگاه",
  universities: "دانشگاه ها",
  country: "کشور",
  selectACountry: "کشور را انتخاب کنید",
  reviewOfAForeignStudent: "نظرات دانشجویان خارجی",
  careerGuidance: "راهنمای شغلی",
  determineYourAreaOfEducationAndProfession:
    "رشته تحصیلی و شغلی مناسب خود را تعیین کنید",
  myCareer: "حرفه من",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "چگونه می توان مطالعه را با تفریحات فرهنگی و آشنایی جدید ترکیب کرد؟ ویژگی های برنامه های فشرده و هزینه آنها",
  toApply: "اعمال کردن",
  chooseYear: "سال را انتخاب کنید",
  _in: "در",
  universitiesInThisCity: "دانشگاه های این شهر",
  programsInThisCity: "برنامه ها در این شهر",
  all: "همه",
  _inYourCountry: "در کشور شما",
  _common: "عمومی",
  allReviews: "همه نظرات",
  calendar: "تقویم",
  activity: "رویدادها",
  addToPersonalCalendar: "افزودن به صفحه شخصی",
  eventAdded: "رویداد اضافه شده",
  eventSuccessfullyAddedToYourCalendar:
    "رویدادها با موفقیت به تقویم شما اضافه شد!",
  population: "جمعیت",
  onTheMap: "در نقشه",
  generalEducation: "تحصیلات عمومی",
  professionalEducation: "تحصیلات حرفه ای",
  preschoolEducation: "تحصیلات پیش دبستانی",
  kindergarten: "کودکستان",
  nursery: "شیرخوارگاه",
  earlyDevelopmentCenters: "مراکز توسعه اولیه",
  primaryAndSecondaryEducation: "آموزش ابتدایی ، مقدماتی و متوسطه",
  schools: "مدارس",
  lyceums: "موسسات آموزش متوسطه",
  gymnasium: "مدارس تیزهوشان",
  secondaryVocationalEducation: "تحصیلات حرفه ای متوسطه",
  colleges: "کالج ها",
  technicalSchools: "دانشکده های فنی",
  secondarySchool: "هنرستان",
  higherEducation: "تحصیلات عالی",
  baccalaureate: "کارشناسی",
  specialistDegree: "کارشناسی ارشد پیوسته",
  magistracy: "کارشناسی ارشد ناپیوسته",
  postgraduateStudies: "دکتری",
  preparatoryFaculty: "دانشکده های تحصیلات پیش دانشگاهی",
  summerSchools: "مدرسه تابستانی",
  additionalGeneralAndPreVocationalPrograms:
    "برنامه های تکمیلی توسعه عمومی و پیش حرفه ای ",
  continuingEducationAndRetrainingPrograms:
    "برنامه های آموزش پیشرفته و بازآموزی حرفه ای",
  whatAreTheLevelsOfRussianEducation:
    "آموزش در روسیه از چه مقاطعی تشکیل می شود؟",
  enterYourName: "نام خود را وارد کنید",
  name: "نام",
  selectTheSubjectOfTheMessage: "موضوع پیام را وارد کنید",
  messageSubject: "موضوع پیام",
  chooseAMessage: "پیام را انتخاب کنید",
  message: "پیام",
  submit: "ارسال",
  frequentlyAskedQuestions: "پرسشهای متداول",
  didNotFindTheAnswerToYourQuestion: "پاسخ سوال شما را پیدا نکردیم",
  contactSupportAt: "با پشتیبانی به این آدرس تماس بگیرید",
  searchInTheSection: "جست و جو در بخش",
  sort: "مرتب سازی",
  searchingResults: "نتایج جست و جو",
  _answers: "پاسخ ها",
  _views: "دیدگاهها",
  byCreationDate: "طبق تاریخ ایجاد",
  byTheNumberOfResponses: "طبق تعداد پاسخها",
  byViews: "طبق تعداد دیدگاهها",
  toAnswer: "پاسخ دادن",
  toQuote: "نقل قول کردن",
  complain: "شکایت کردن",
  addNewMessage: " اضافه کردن پیام جدید",
  selectComplaintType: "نوع شکایت را انتخاب کنید",
  typeOfComplaint: "نوع شکایت",
  enterComplaintText: "متن شکایت را وارد کنید",
  complaintText: "متن شکایت",
  complaintSentSuccessfully: "شکایت با موفقیت ارسال شد",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "شکایت شما با موفقیت ارسال شد، ما در کمترین زمان شکایت شما را بررسی می کنیم",
  close: "بستن",
  myMessages: "پیامهای من",
  myTopicsAndPosts: "موضوعات و پیامهای من",
  searchInMyPosts: "جست و جو در پیامهای من",
  _posts: "پیام",
  feedbackOnEducationalOrganizations: "نظرات در مورد دانشگاهها",
  posted: "منتشر شده",
  goToMessage: "به بخش پیام بروید",
  _themes: "موضوع",
  themeTitle: "عنوان موضوع",
  enterAThemeName: "عنوان موضوع را وارد کنید",
  cancel: "لغو",
  banUser: "کاربر ممنوع شده است؟",
  areYouSure: "مطمئن هستید؟",
  toBan: "ممنوع کردن",
  _logIn: "ورود به سیستم",
  _or: "یا",
  _signUp: "ثبت نام کردن",
  eventCalendar: "تقویم رویدادها",
  trainingApplicationsFiled: "برنامه های آموزشی ارسال شده",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "معروفترین دانشگاه ها که توسط متقاضیان انتخاب شده است",
  _of: "از",
  _whenApplying: "هنگام انتقال درخواست",
  tOP10EducationalOrganizations: "ده دانشگاه برتر",
  collapse: "منحل کردن",
  showAll: "نمایش همه",
  conditionsForForeigners: "شرایط برای خارجیان",
  supportForInternationalStudents: "پشتیبانی از دانشجویان خارجی",
  lifeOfForeignStudents: "زندگی روزمره دانشجویان خارجی",
  leisureAndSportingEvents: "اوقات فراغت و رویدادهای ورزشی",
  dormitories: "خوابگاهها",
  dormitoryCost: "هزینه خوابگاهها",
  theNumberOfPeopleInTheRoom: "تعداد افراد در هر اتاق",
  from: "از",
  _rubles: "روبل",
  _to: "تا",
  _perMonth: "در ماه",
  "2-3People": "دو تا سه نفر",
  theInternationalCooperation: "همکاری بین المللی",
  photoAndVideo: "عکس و ویدئو",
  eventTitle: "نام رویداد",
  location: "محل برگزاری",
  date: "تاریخ",
  enterYourRealMail: "ایمیل واقعی خود را وارد نمائید",
  requiredField: "بخش اجباری برای پر کردن",
  minimumLengthCharacters: "حداقل طول{length} کاراکتر",
  maximumLengthCharacters: "حداکثر طول {length} کاراکتر",
  theFieldMustBeANumber: "این بخش باید عدد باشد",
  onlyLettersAreAllowedInTheField: "در این قسمت فقط حروف معتبر است",
  checkKeyboardLayout: "چیدمان صفحه کلید را بررسی کنید",
  checkTheNumberYouEnteredIsCorrect: "صحت عددی که وارد کرده اید را بررسی کنید",
  olympiadName: "نام المپیاد",
  description: "شرح",
  contactInformation: "اطلاعات تماس",
  majorForeignPartnerUniversities: "دانشگاههای خارجی اصلی-پارتنرها",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "امکان یادگیری حضوری و غیرحضوری زبان روسی در روسیه از سراسر جهان",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "آیا شما معلم خصوصی هستید؟ برای انتشار مشخصات خود در سایت، یک درخواست ارسال کنید",
  submitApplication: "ارسال فرم",
  chooseATeachingExperience: "سابقه تدریس را انتخاب کنید",
  teachingExperience: "سابقه تدریس",
  chooseLanguages: "زبانها را انتخاب نمائید",
  knowledgeOfLanguages: "دانش زبانی",
  workExperience: "سابقه کار",
  trainingLevel: "سطح آمادگی",
  durationOfOneLesson: "مدت زمان تدریس",
  possibleTrainingTime: "زمان احتمالی آموزش ",
  theCosOfOneLesson: "هزینه یک جلسه",
  writeAMessage: "نوشتن پیام",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "پس از ثبت نام یا ورود به سایت می توانید برای معلم خصوصی نامه بنویسید",
  educationDocuments: "مدارک تحصیلی",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "شما قبلاً برنامه دیگری را انتخاب کرده اید، اگر این برنامه را انتخاب کنید، برنامه و سازمانهای انتخاب شده از بین می روند.",
  limitIsExceeded: "بیش از حد مجاز",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "شما فقط می توانید 6 دانشگاه را انتخاب کنید.",
  _fieldsOfEducation: "حوزه آموزش",
  _trainingAreas: "زمینه های آموزشی",
  _cities: "شهرها",
  _languagesOfInstruction: "زبانهای تحصیل",
  _typesOfAdditionalPrograms: "انواع برنامه های تکمیلی",
  _semesters: "نیمسال تحصیلی",
  chooseDurationOfStudy: "مدت زمان تحصیل را انتخاب نمائید",
  _durationOfStudy: "مدت زمان تحصیل",
  enterTheNameOfTheEducationalProgram: "نام برنامه را وارد کنید",
  instruction: "دستورالعمل ها",
  profile: "فرم درخواست",
  back: "قبلی",
  next: "بعدی",
  testReport: "نمره ارزشیابی آزمون",
  testDate: "تاریخ آزمون",
  duration: "مدت زمان",
  memberName: "نام شرکت کننده",
  age: "سن",
  gender: "جنسیت",
  currentLevelOfEducation: "سطح تحصیلات کنونی",
  areasOfInterestHobbies: "زمینه های مورد علاقه، سرگرمی ها",
  sportsAchievements: "دستاوردهای ورزشی",
  testScaleResults: "نتایج در مقیاس آزمون",
  scaleDescription: "شرح نتایج بر اساس جدول",
  recommendedEducationAreas: "حوزه های پیشنهادی آموزش",
  enterYourAge: "سن خود را وارد نمائید.",
  enterYourGender: "جنسیت خود را وارد نمائید.",
  chooseYourCurrentLevelOfEducation: "سطح تحصیلات کنونی خود را انتخاب کنید.",
  chooseYourAreasOfInterestHobbies:
    "زمینه های علاقه مندی و سرگرمی های خود را انتخاب کنید.",
  chooseYourSportingAchievements: "دستاوردهای ورزشی خود را انتخاب نمائید.",
  legalizationOfDocuments: "تایید محضری مدارک",
  consularLegalization: "تایید رسمی مدارک توسط کنسولگری",
  edit: "ویرایش کردن",
  mobilePhoneNumber: "شماره تلفن همراه",
  iWantToReceiveNotificationsByEmail:
    "من می خواهم اعلان ها را در ایمیل دریافت کنم",
  authorizationCheck: "بررسی ورود به سیستم",
  createANewApplication: "ایجاد درخواست جدید",
  selectionStage: "مرحله انتخاب",
  underRevision: "در حال تکمیل",
  fillingOutAnApplication: "پر کردن فرم ",
  verificationAndRevision: "بررسی و تکمیل",
  dossierFormation: "تشکیل پرونده",
  fillingInTheQuestionnaire: "پر کردن فرم",
  attachingDocuments: "پیوست مدارک",
  sendingApplicationForVerification: "ارسال درخواست جهت بررسی",
  personalData: "اطلاعات شخصی",
  surnameInLatinLettersAccordingToPassport:
    "نام خانوادگی، با حروف لاتین (مطابق با گذرنامه)",
  nameInLatinLettersAccordingToPassport:
    "نام، با حروف لاتین (مطابق با گذرنامه)",
  middleNameIAnyCyrillicInRussianTranscription:
    "نام پدر (چنانچه در فامیلی می آید)، به خط سیریلیک با آوانویسی روسی ",
  placeOfBirthAccordingToPassport: "محل تولد (مطابق با گذرنامه)",
  dateOfBirth: "تاریخ تولد",
  compatriotStatus: "وضعیت شهروندی",
  citizenshipPlaceOfPermanentResidence: "تابعیت (محل دائمی زندگی)",
  online: "آنلاین",
  profile2: "پرونده شخصی",
  goToTheApplicationForm: "به بخش پر کردن فرم بروید",
  areYouHere: "شما اینجا هستید",
  russianTextDes1:
    "چرا به دانشکده های تحصیلات پیش دانشگاهی نیاز است، چه چیزی را در آنجا آموزش می دهند، چه مدت آموزش طول می کشد و چقدر هزینه دارد؟",
  russianTextDes4:
    "برای تحصیل خصوصی زبان روسی از کجا و چگونه می توان معلم پیدا کرد؟",
  russianTextDes5:
    "چگونه و چرا باید سطح زبان روسی خود را برای پذیرش در دانشگاه روسیه تقویت کرد؟ درباره شرکت در آزمون گواهینامه همه چیز را بدانید.",
  typeOfProgram: "نوع برنامه",
  chooseTheTypeOfAdditionalProgram: "نوع برنامه تکمیلی را انتخاب کنید.",
  durationOfStudy: "مدت زمان تحصیل",
  common: "عمومی",
  inYourCountry: "در کشور شما",
  courseName: "عنوان دوره",
  courseDuration: "مدت زمان دوره",
  classDuration: "مدت زمان جلسه آموزشی",
  courseCost: "هزینه دوره",
  courseReviews: "نظرات درباره دوره",
  male: "مرد",
  female: "زن",
  feedbackLink: "لینک نظرات",
  russianLanguageCoursesInYourCountry: "دوره های زبان روسی در کشور شما",
  evaluation: "ارزیابی",
  giveFeedback: "نظر دادن",
  newFeedback: "نظر جدید",
  _hour: "ساعت",
  _hours: "ساعت",
  __hours: "ساعات",
  _minute: "دقیقه",
  _minutes: "دقیقه",
  __minutes: "دقیقه",
  create: "ایجاد",
  feedbackSuccess: "نظر با موفقیت ارسال شد و در انتظار تعدیل است.",
  _y: "سال",
  _from: "from",
  error: "اشتباه",
  direction: "رشته",
  _tutor: "معلم خصوصی",
  _tutors: "معلم خصوصی",
  __tutors: "معلم های خصوصی",
  filters: "فیلترها",
  sendMessage: "ارسال پیام",
  sendMessageSuccess: "پیام با موفقیت ارسال شد",
  _year: "سال",
  _years: "سال",
  __years: "سال",
  _back: "قبلی",
  _next: "بعدی",
  showing: "نمایش",
  textRussianLanguageCourses:
    "کشور محل اقامت خود را از فهرست انتخاب کرده و دوره ها و مدارس زبان موجود را مشاهده کنید. داشتن دانش مقدماتی زبان روسی  سازگاری شما هنگام مهاجرت را تا حد زیادی تسهیل می کند.",
  tOP10Programs: "ده برنامه برتر",
  tOP10City: "ده شهر برتر روسیه",
  createANewTopic: "ساختن موضوع جدید",
  topics: "موضوعات",
  posts: "پیام",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "فرمت فایل ها: .pdf, .jpg, .png تا ده مگابایت",
  noFileSelected: "فایل انتخاب نشد",
  attachFile: "پیوست کردن فایل",
  eMail: "E-mail",
  fullTime: "حضوری",
  toFind: "یافت شده",
  searchInTopic: "جست و جو موضوع",
  lessThan1Month: "کمتر از یک ماه",
  "1To3Months": "از یک تا سه ماه",
  "3To6Months": "از سه تا شش ماه",
  "6To12Months": "از شش تا دوازده ماه",
  moreThan1Year: "بیش از یک سال",
  theDateOfThe: "تاریخ برگزاری",
  toAdminPanel: "به پنل مدیریت بروید",
  support: "پشتیبانی فنی",
  faq: "FAQ",
  userGuide: "راهنمای کاربر",
  textUserGuide: "دستورالعمل کار با پورتال و ارائه درخواست برای تحصیل",
  textRegisterGuide: "ثبت نام در وب سایت تحصیل در روسیه برای خارجیان",
  textTrainingGuide: "درخواست تحصیلی در وب سایت تحصیل در روسیه برای خارجیان",
  textApplication: "پر کردن فرم",
  instructionForWork: "دستورالعمل کار با فرم درخواست",
  appeals: "درخواست تجدید نظر",
  handling: "تجدید نظر",
  __students: "دانشجو(دختر)",
  _student: "دانشجو(پسر)",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "گروه آموزشی خود را مشخص کرده واز گزینه های احتمالی برای شغل آینده خود مطلع شوید",
  delTheme: "حذف موضوع",
  competitionForTheFirstPlace: "رقابت برای کسب مقام اول در سهمیه های مشخص شده توسط فدراسیون روسیه",
  ruble: "روبل",
  __rubles: "روبل",
  dateOfEvent: "تاریخ برگزاری",
  durationUnit: "ماه",
  numberPhone: "شماره تلفن",
  offline: "آفلاین",
  to_begin: "در آغاز",
  theDateOfThe: "تاریخ شروع",
  chat: "ربات چت",
  yourMessageChat: "پیام های شما",
  searchChat: "جست و جو در گفت و گوها",
  firstMsgChat: "متن پیام را وارد نمائید",
  categoryBtnChat_1: "سؤال در مورد فرم درخواست",
  categoryBtnChat_2: "سؤال در مورد آموزش",
  categoryBtnChat_3: "سؤال در مورد آزمون گزینشی",
  categoryBtnChat_4: "پیشنهاد یا شکایت",
  categoryBtnChat_5: "بازخورد فعالیتهای دانشگاه",
  categoryBtnChat_6: "شکایت از کیفیت خدمات",
  categoryBtnChat_7: "تماس با پشتیبانی فنی",
  notQuestionResolvedChat: "مشکل من حل نشد",
  questionResolvedChat: "مشکل من حل شد",
  countrySelectTrueChat: "کشور به درستی نشان داده شده",
  salutationChat: "سلام",
  categoryAppealChat: "برای کسب اطلاعات، دسته بندی مربوط به سوال خود را با کلیک روی دکمه انتخاب کنید. پس از انتخاب دسته بندی، سؤال خود را در پیام وارد کرده و دکمه ارسال را انتخاب کنید.",
  langAppealChat: "زبانی را که می خواهید درخواست ها را با آن ارسال کنید انتخاب کنید. درخواست های ارسال شده به زبانهای روسی و انگلیسی در سریعترین زمان رسیدگی می شوند.",
  youCountryChatPath1: "کشور شما",
  youCountryChatPath2: "اگر کشورتان در فهرست نیست، لطفاً نام کشور را در پیام پاسخ وارد کنید.",
  youCountryChat: "کشور شما{country}؟ اگر کشورتان در فهرست نیست، لطفاً نام کشور را در پیام پاسخ وارد کنید.",
  notFoundCountryChat: "کشوری با نامی که مشخص کرده اید یافت نشد. صحت داده های وارد شده را بررسی کرده و پیام را دوباره ارسال کنید.",
  additionalInfoChat: "لطفاً در پیام پاسخ، اطلاعات تکمیلی درخواست خود را ذکر کنید.",
  yourEmailChat: "لطفا آدرس ایمیل خود را وارد کنید. پاسخ به درخواست شما از طریق ایمیل ارسال می شود.",
  notValidEmailChat: "آدرس ایمیلی که ارسال کرده اید با قالب aaa@bbb.ccc مطابقت ندارد. صحت داده های وارد شده را بررسی کرده و پیام را دوباره ارسال کنید.",
  acceptRequestChat: "درخواست تجدیدنظر شما به اپراتور ارسال شد. متوسط ​​زمان پردازش 7 روز تقویمی است.",
  notFoundEmployeeChat: "کشوری که مشخص کرده اید جزء لیست کشورهای واجد شرایط برای آموزش تحت برنامه Rossotrudnichestvo نیست. درخواست تجدیدنظر شما نمیتواند برای بررسی ارسال گردد لطفاً کشور دیگری را مشخص کنید. می توانید لیست کاملی از برنامه های آموزشی را در صفحه <a href=/education-in-russia/programs#>{host} /education-in-russia /p rograms#</a> بیابید. ",
  langBtnChat_1: "انگلیسی",
  langBtnChat_2: "روسی",
  langBtnChat_3: "سایر",
  addAppBtn: "ارائه درخواست",
  listAppBtn: "فهرست درخواستها",
  privacyPolicy: "سیاست محرمانه بودن اطلاعات",
  ___programs: "برنامه",
  ___educationalPrograms: "برنامه های آموزشی",
  __educationalPrograms: "برنامه آموزشی",
  editTopic: "ویرایش موضوع",
  editMessage: "ویرایش پیام",
  deleteMessage: "حذف پیام",
  blockingUser: "مسدود کردن کاربر",
  blockEndDate: "تاریخ پایان مسدودی",
  blockEndTime: "زمان پایان مسدودی",
  blockForever: "مسدودکردن برای همیشه",
  block: "مسدود کردن",
  unlock: "خارج کردن از مسدودی",
  edited: "ویرایش شده",
  founder: "مؤسس",
  abbreviation: "اختصاری",
  studentsByCountry: "تعداد دانشجویان بر اساس کشور",
  studentsByCountryCount: "تعداد دانشجویان در حال تحصیل",
  contactsInternationalDepartment: "تماس با بخش بین المللی",
  site: "سایت",
  address: "آدرس",
  format: "فرمت",
  typeEvent: "نوع فعالیت",
  quotaLearning: "تحصیل تحت سمیه بندی",
  kcp: "بررسی تعداد افراد جهت پذیرش",
  countSeatsKCP: "بررسی تعداد افراد جهت پذیرش",
  countSeatsCostTraining: "تعداد صندلی برای تحصیلات غیر رایگان",
  distanceLearning: "آموزش از راه دور",
  notificationAboutDistanceLearning: "اجرای برنامه آموزشی با استفاده از فناوری های آموزش الکترونیکی و آموزش از راه دور امکان پذیر است. برای اطلاع از جزئیات با دانشگاه تماس بگیرید.",
  additionalTests: "تاریخ های برگزاری آزمون های تکمیلی واجدین شرایط",
  pageNotFound: "صفحه یافت نشد",
  goToMainPage: "به صفحه اصلی بروید",
  faqInformation: "برای کلیه سؤالات مربوط به وضعیت تأیید درخواست، فهرست مدارک مورد نیاز، زمان آزمون گزینشی و همچنین سایر سؤالات مربوط به روند پذیرش، توصیه میشود با دفاتر نمایندگی مجاز Rossotrudnichestvo یا سفارت فدراسیون روسیه در کشورتان تماس بگیرید. اطلاعات تماس با کارکنان Rossotrudnichestvo را می توانید در حساب شخصی خود پیدا کنید.",
  allEvents: "همه رويدادها",
  eventsAndNews: "رویدادها و اخبار",
  titleNews: "عناوین اخبار",
  numberOfBudgetPlaces: "تعداد ظرفیت تحصیل رایگان",
  russianGovernmentQuota: "سهمیه دولت روسیه",
  legalAddress: "آدرس حقوقی",
  actualAddress: "آدرس حقیقی",
  goToTheRegister: "رفتن به ثبت برنامه های تکمیلی",
  searchForSuitablePrograms: "جستجوی برنامه های مناسب",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "محبوب ترین برنامه های آموزشی که متقاضیان انتخاب می کنند.",
  theMostPopularCitiesOfRussiaChosenByApplicants: "محبوب ترین شهرهای روسیه که توسط متقاضیان انتخاب شده است",
  ofTheCountry: "از کشور",
  passed: "تکمیل شد",
  question: "پرسش",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "پورتال در حالت عملیات آزمایشی است!",
  banner_2: "کاربران گرامی، در حال حاضر ممکن است مشکلات فنی در استفاده از آدرس های پستی گوگل وجود داشته باشد.  لطفاً هنگام کار با پورتال از سایر خدمات پستی استفاده کنید.",
  banner_3: " شروع کمپین پذیرش از ۲۳ دسامبر ۲۰۲۲ (۳ دی ۱۴۰۱)",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
