<template>
  <div :class="formRowClass">
    <validation-provider :name="storePath" :rules="rules" v-slot="{errors}">
      <label :for="model" class="form-control-label" v-if="label" v-html="label" />

      <multiselect
        :options="options"
        :placeholder="valueName || placeholder"
        v-model="valuePreSet"
        :allow-empty="allowEmpty"
        :label="customLabel"
        ref="multiselect"
        :multiple="isMultiple"
        :track-by="customValue"
        :searchable="searchable"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__title">{{ props.option[customLabel] | kitCut(70) }}</span>
        </template>
      </multiselect>

      <div class="hint-error" v-if="error">
        {{ error }}
      </div>
      <div class="hint-error" v-if="errors.length">
        {{ errors[0] }}
      </div>
      <p class="hint" v-if="hint">
        {{ hint }}
      </p>
    </validation-provider>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';

  export default {
    name: 'multi-select',

    data() {
      return {
        valuePreSet: null,
        interval: null,
        valueName: '',
      };
    },

    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    props: {
      label: String,
      allowEmpty: {
        type: Boolean, default() {
          return false;
        },
      },

      customLabel: {
        type: String, default() {
          return 'name';
        },
      },

      customValue: {
        type: String, default() {
          return 'value';
        },
      },

      model: String,
      storePath: String,
      placeholder: String,
      options: Array,
      value: [String, Object],
      callbackOnChange: Function,
      isMultiple: {type: Boolean, default() { return false; }},
      searchable: {type: Boolean, default() { return true; }},
      rules: {type: String, default: () => ''},
      formRowClass: {type: String, default: () => 'form-row'},
      error: String,
      hint: String,
      keyShowAll: String,
    },

    mounted() {
      if (this.storePath && !_.get(this.$store.state.form.forms, this.storePath)) {
        this.$store.commit('form/initFormField', {model: this.storePath, value: _.get(this.forms, this.storePath) || this.value || null});
      }

      if (this.valuePreSet !== this.value && this.value) {
        this.valuePreSet = this.value;
        this.setOptions();
      }
    },

    methods: {
      getValueName() {
        let value = _.get(this.forms, this.storePath), name;

        if (!value) {
          return false;
        }

        if (_.isArray(value)) {
          name =  _.map(value, v => (_.find(this.options, option => option.value == v) || {}).name || false).join(', ')
        } else {
          name = (_.find(this.options, option => option.value == value) || {}).name || false;
        }
        return name;
      },

      setOptions() {
        // if (this.keyShowAll && !_.find(this.options, {value: null}) && this.options.length) {
        //   _.assignIn(this.options, [{name: `${this.$t('all')} ${this.$t(this.keyShowAll)}`, value: null}])
        // }

        let
          value = this.value, valueFind;

        if (_.get(this.forms, this.storePath)) {
          value = _.get(this.forms, this.storePath);
        }

        if (value) {
          if (_.isArray(value)) {
            valueFind = [];
            _.each(value, val => {
              valueFind.push(_.find(this.options, option => (option.value || '') == val));
            })
          } else {
            valueFind = _.find(this.options, option => (option.value || '').toString() === (value || '').toString());
          }
        }

        if (valueFind || value) {
          this.valuePreSet = valueFind;
        }
      },
    },

    watch: {
      valuePreSet(nextState) {
        if(!nextState) {
          this.$store.commit('form/updateField', {model: this.storePath, value: null});
          this.valueName = this.getValueName();

          return ;
        }

        if (this.isMultiple) {
          this.$store.commit('form/updateField', {
            model: this.storePath, value: _.map(nextState, value => value[this.customValue] || value),
          });

          if (this.callbackOnChange) {
            this.callbackOnChange();
          }
        } else {
          if (_.isObject(this.valuePreSet)) {
            this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet[this.customValue]});

            if (this.callbackOnChange) {
              this.callbackOnChange();
            }
          }
        }

        this.valueName = this.getValueName();
      },

      forms: {
        handler(val) {
          if (!_.get(val, this.storePath)) {
            this.valuePreSet = null;
          }
        },

        deep: true,
      },

      options() {
        this.setOptions();
      },
    },
  }
</script>
