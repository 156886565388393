export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Yardım",
  personalArea: "Kullanıcı hesabı",
  myApplications: "Başvurularım",
  servicesAndFacilities: "Hizmet ve servisler",
  logout: "Çıkış",
  favorites: "Sık kullanılanlar",
  theChoice: "Seç",
  notifications: "Bildirimler",
  logIn: "Giriş",
  quickAccessThroughSocialNetworks: "Sosyal ağlar aracılığı ile hızlı giriş",
  enterYourEmail: "E-posta adresinizi girin",
  password: "Parola",
  enterPassword: "Parolanızı girin",
  rememberMe: "Beni hatırla",
  forgotYourPassword: "Parolayı unuttum",
  noAccount: "Kullanıcı hesabım yok",
  registration: "Kayıt ol",
  countryOfCitizenship: "Vatandaşlık",
  chooseYourCountryOfCitizenship: "Vatandaşı olduğunuz ülkeyi seçin",
  iHaveReadAndAgree: "Okudum ve",
  _toTheProcessingOfMyPersonalData:
    "kişisel bilgilerimin işlenmesini teyit ediyorum",
  iAgreeTo: "Çerez dosyalarının",
  _theUseOfCookies: "kullanılmasını kabul ediyorum",
  signUp: "Kayıt ol",
  haveAnAccount: "Hesabım var",
  mainPage: "Ana sayfa",
  frequentlyAskedQuestionsAnswers: "Sık Sorulan Sorular",
  goTo: "Git",
  startTesting: "Sınavı başlat",
  buildCareerVector: "Kariyer vektörü oluştur",
  forum: "Forum",
  textForumBanner:
    "Sizin için önemli olan tüm konuları forumda tartışabilirsiniz: Rusya'nın şehirlerinde yaşam, Rusya üniversitelerinde eğitim v.b.",
  feedback: "Geri bildirim",
  technicalSupportFeedbackForm: "Teknik destek ile geri bildirim formu",
  allCities: "Tüm şehirler",
  citiesOfRussia: "Rusya şehirleri",
  educationInRussia: "Rusya'da eğitim",
  educationalOrganizations: "Üniversiteler",
  educationInRussiaYourKeyToSuccess: "Rusya'da eğitim başarınızın anahtarıdır",
  applyForStudyInRussia: "Rusya'da eğitim için başvuru yapın",
  apply: "Başvuru yap",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Mesleki oryantasyon ve başvuru göndermek için giriş yapın veya kayıt olun",
  aboutOrganization: "Kurumumuz hakkında",
  eventsAndOlympiads: "Etkinlikler ve olimpiyatlar",
  educationalPrograms: "Programlar",
  contacts: "İletişim",
  inFavorites: "Sık kullanılanlarda",
  toFavorites: "Sık kullanılanlara ekle",
  fromChoices: "Seçilen",
  choices: "Seç",
  generalInformation: "Genel bilgi",
  internationalRatings: "Uluslararası reytingler",
  nationalRatings: "Ulusal reytingler",
  ratings: "Reytingler",
  year: "Yıl",
  ratingName: "Reyting adı",
  aPlace: "Yer",
  screeningTests: "Yerleştirme sınavları",
  choiceOfProfession: "Meslek seçimi",
  lifeInRussia: "Rusya'da yaşam",
  russianLanguage: "Rusça",
  livingConditions: "Yaşam koşulları",
  cultureAndLeisure: "Kültür ve eğlence",
  safety: "Güvenlik",
  benefitsForInternationalStudents: "Yabancı öğrenciler için avantajlar",
  workForInternationalStudents: "Yabancı öğrenciler için iş imkanları",
  studentVisaAndRegistration: "Öğrenim vizesi ve kayıt",
  healthInsurance: "Sağlık sigortası",
  howToEnter: "Nasıl kayıt yapılır",
  educationLevels: "Eğitim düzeyleri",
  admissionProcess: "Kayıt süreci",
  olympics: "Olimpiyatlar",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Rusya'da eğitim amaçlı yabancı öğrencilerin seçimi için resmi site, {year}.",
  whyRussia: "Neden Rusya?",
  "10reasonsToStudyInRussia": "Rusya'da eğitim almak için 10 sebep",
  reviewsOfForeignStudents: "Yabancı öğrencilerin yorumları",
  onlineMonitoring: "Çevrimiçi gözlem",
  mainPrograms: "Ana programlar",
  additionalPrograms: "Ek programlar",
  chooseCounty: "Bölge seçin",
  county: "Bölge",
  chooseACity: "Şehir seçin",
  city: "Şehir",
  chooseYourLevelOfEducation: "Eğitim düzeyini seçin",
  chooseYourAreaOfScientificKnowledge: "Bir bilimsel bilgi alanı seçin",
  chooseYourScientificSpecialtyProfile: "Bilimsel bir uzmanlık profili seçin",
  areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Eğitim düzeyi",
  chooseAGroupOfTrainingAreas: "Eğitim dalı grubunu seçin",
  trainingDirectionGroup: "Eğitim dalı grubu",
  chooseATrainingDirection: "Eğitim dalını seçin",
  trainingDirection: "Eğitim dalı",
  chooseRatings: "Reyting seçin",
  thePresenceOfThePreparatoryFaculty: "Hazırlık fakültesinin varlığı",
  show: "Göster",
  _university: "üniversite",
  _universities: "üniversite",
  __universities: "üniversite",
  clearAllFilters: "Tüm filtreleri sıfırla",
  chooseSemester: "Dönemi seçin",
  semester: "Dönem",
  enterTheNameOfTheEducationalOrganization: "Üniversite adını girin",
  _educationalOrganization: "üniversite",
  _educationalOrganizations: "üniversite",
  __educationalOrganizations: "üniversite",
  chooseYourAreaOfEducation: "Eğitim alanı seçin",
  areaOfEducation: "Eğitim alanı",
  chooseYourLanguageOfInstruction: "Eğitim dili seçin",
  languageOfInstruction: "Eğitim dili",
  _program: "program",
  _programs: "programlar",
  __programs: "programlar",
  formOfTraining: "Öğrenim şekli",
  level: "Düzey",
  typeOfTraining: "Eğitim türü",
  free: "Ücretsiz",
  paid: "Ücretli",
  tuitionFees: "Ücretli eğitimin fiyatı",
  established: "Kuruluş tarihi",
  numberOfStudents: "Öğrenci sayısı",
  _students: "öğrenci",
  numberOfQuotaPlaces: "Kontenjan sayısı",
  _for: "на",
  numberOfInternationalStudents: "Yabancı öğrenci",
  _educationalPrograms: "eğitim programı",
  moreDetails: "Daha fazla bilgi",
  sTEP: "ADIM",
  afterTrainingForThisProgramYouCanBecome:
    "Bu programda eğitimi tamamladıktan sonra elde edebileceğiniz meslekler",
  chooseProgram: "Programı seç",
  chooseUniversity: "Üniversite seç",
  print: "Yazdır",
  save: "Kaydet",
  programs: "Programlar",
  selectedProgram: "Seçilen program",
  noProgramSelected: "Program seçilmemiş",
  selectedEducationalOrganizations: "Seçilen üniversiteler",
  educationalOrganizationNotSelected: "Üniversite seçilmemiş",
  representation: "Temsilcilik",
  programDescription: "Program tanımı",
  programPageOnTheUniversityWebsite: "Üniversitenin sitesinde program sayfası",
  programCurator: "Program sorumlusu",
  disciplines: "Dersler",
  theProcessOfEnteringARussianEducationalOrganization:
    "Rusya üniversitesine kayıt süreci",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Bir program ve 6'ya kadar üniversite seçin",
  takeTheCompetitiveSelectionInYourCountry:
    "Ülkenizde yerleştirme sınavını geçin",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Online başvurunuzu doldurun ve belgelerinizi ekleyin",
  getAnInvitationToStudAnStudyVisa: "Eğitim davetiyenizi ve vizenizi alın",
  preparatoryFaculties: "Hazırlık fakülteleri",
  summerAndWinterSchools: "Yaz ve kış okulları",
  russianLanguageCourses: "Rusça kursları",
  tutorsTheRussianLanguage: "Rusça öğretmenleri",
  certificationInRussian: "Rusça sertifikaları",
  news: "Haberler",
  allNews: "Tüm haberler",
  reviewsOfStudentsAndGraduates: "Öğrenci ve mezunların yorumları",
  areYouSureYouWantToWithdrawTheApplication:
    "Başvurunuzu gerçekten geri almak istiyor musunuz?",
  withdrawApplication: "Başvuruyu geri al",
  jan: "Ocak",
  feb: "Şubat",
  mar: "Mart",
  apr: "Nisan",
  may: "Mayıs",
  jun: "Haziran",
  jul: "Temmuz",
  aug: "Ağustos",
  sep: "Eylül",
  act: "Ekim",
  nov: "Kasım",
  dec: "Aralık",
  cityFeatures: "Şehrin özellikleri",
  enterTheNameOfTheCity: "Şehir adını girin",
  organizations: "Kuruluşlar",
  livingWage: "Geçim gideri",
  "one-roomApartmentForRent": "1 odalı dairenin kirası",
  _month: "ay",
  averageTemperature: "Ortalama sıcaklık",
  _inWinter: "kışın",
  _inSummer: "yazın",
  averageCostOfDormitories: "Ortalama yurt ücreti",
  photos: "Resimler",
  interestingPlaces: "Enteresan yerler",
  readCompletely: "Tamamını oku",
  _review: "yorum",
  _reviews: "yorum",
  __reviews: "yorumlar",
  university: "Üniversite",
  universities: "Üniversiteler",
  country: "Ülke",
  selectACountry: "Ülke seçin",
  reviewOfAForeignStudent: "Yabancı öğrencinin yorumu",
  careerGuidance: "Mesleki oryantasyon",
  determineYourAreaOfEducationAndProfession:
    "Sizin için uygun eğitim alanı ve mesleğin tanımlanması",
  myCareer: "Kariyerim",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Eğitiminizi kültürsel dinlenme ve yeni tanışıklıklar ile bir arada yürütme. Yoğun programların özellikleri ve ücreti",
  toApply: "Uygula",
  chooseYear: "Yılı seçin",
  _in: "bu şehirde",
  universitiesInThisCity: "Bu şehirdeki üniversiteler",
  programsInThisCity: "Bu şehirdeki programlar",
  all: "Tümü",
  _inYourCountry: "ülkenizde",
  _common: "genel",
  allReviews: "Tüm yorumlar",
  calendar: "Takvim",
  activity: "Etkinlikler",
  addToPersonalCalendar: "Kişisel takvime ekle",
  eventAdded: "Etkinlik eklenmiştir",
  eventSuccessfullyAddedToYourCalendar:
    "Etkinlik takviminize başarılı bir şekilde eklenmiştir!",
  population: "Nüfus",
  onTheMap: "Haritada",
  generalEducation: "Genel eğitim",
  professionalEducation: "Mesleki eğitim",
  preschoolEducation: "Okul öncesi eğitim",
  kindergarten: "Kreş",
  nursery: "Yuva",
  earlyDevelopmentCenters: "Erken gelişim merkezleri",
  primaryAndSecondaryEducation: "Ana, genel ve orta eğitim",
  schools: "Okullar",
  lyceums: "Liseler",
  gymnasium: "Orta okullar",
  secondaryVocationalEducation: "Orta mesleki eğitim",
  colleges: "Kolejler",
  technicalSchools: "Teknik okullar",
  secondarySchool: "İhtisas okulları",
  higherEducation: "Yüksek öğrenim",
  baccalaureate: "Lisans",
  specialistDegree: "Uzmanlık",
  magistracy: "Master",
  postgraduateStudies: "Yüksek lisans",
  preparatoryFaculty: "Hazırlık fakültesi",
  summerSchools: "Yaz okulları",
  additionalGeneralAndPreVocationalPrograms:
    "Ek genel gelişim ve meslek öncesi programları",
  continuingEducationAndRetrainingPrograms:
    "Meslek ilerleme ve mesleki yeniden eğitim programları",
  whatAreTheLevelsOfRussianEducation:
    "Rusya'da eğitim hangi kademelerden oluşur?",
  enterYourName: "Adınızı girin",
  name: "Ad",
  selectTheSubjectOfTheMessage: "Mesaj konusunu seçin",
  messageSubject: "Mesaj konusu",
  chooseAMessage: "Mesajınızı seçin",
  message: "Mesaj",
  submit: "Gönder",
  frequentlyAskedQuestions: "Sık Sorulan Sorular",
  didNotFindTheAnswerToYourQuestion: "Sorunuza cevap bulamadınız mı?",
  contactSupportAt: "Aşağıdaki adresten destek servisi ile iletişime geçin",
  searchInTheSection: "Bölüm içinde arama",
  sort: "Sırala",
  searchingResults: "Arama sonuçları",
  _answers: "yanıt",
  _views: "görüntüleme",
  byCreationDate: "Oluşturma tarihine göre",
  byTheNumberOfResponses: "Yanıt sayısına göre",
  byViews: "Görüntüleme sayısına göre",
  toAnswer: "Yanıtla",
  toQuote: "Alıntı yap",
  complain: "Şikayet et",
  addNewMessage: "Yeni mesaj ekle",
  selectComplaintType: "Şikayet türünü seçin",
  typeOfComplaint: "Şikayet türü",
  enterComplaintText: "Şikayet metni girin",
  complaintText: "Şikayet metni",
  complaintSentSuccessfully: "Şikayet başarılı bir şekilde gönderildi",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Şikayetiniz başarılı bir şekilde gönderildi, şikayetinizi en kısa sürede inceleyeceğiz",
  close: "Kapat",
  myMessages: "Mesajlarım",
  myTopicsAndPosts: "Konularım ve mesajlarım",
  searchInMyPosts: "Mesajlarımda ara",
  _posts: "mesaj",
  feedbackOnEducationalOrganizations: "Üniversite hakkında yorumlar",
  posted: "Yayınlandı",
  goToMessage: "İletiye geç",
  _themes: "konu",
  themeTitle: "Konu adı",
  enterAThemeName: "Konu adını girin",
  cancel: "İptal",
  banUser: "Kullanıcıyı bloke et",
  areYouSure: "Emin misiniz?",
  toBan: "Bloke et",
  _logIn: "oturum aç",
  _or: "veya",
  _signUp: "kayıt ol",
  eventCalendar: "Etkinlik takvimi",
  trainingApplicationsFiled: "Gönderilen eğitim başvuruları",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Adayların seçtiği en popüler üniversiteler",
  _of: "из",
  _whenApplying: "başvuru esnasında",
  tOP10EducationalOrganizations: "İLK 10 üniversite",
  collapse: "Küçült",
  showAll: "Tümünü göster",
  conditionsForForeigners: "Yabancılar için koşullar",
  supportForInternationalStudents: "Yabancı öğrencilere destek",
  lifeOfForeignStudents: "Yabancı öğrencilerin günlük yaşamı",
  leisureAndSportingEvents: "Eğlence ve spor etkinlikleri",
  dormitories: "Yurtlar",
  dormitoryCost: "Yurt ücreti",
  theNumberOfPeopleInTheRoom: "Odadaki kişi sayısı",
  from: "Alt sınır",
  _rubles: "ruble",
  _to: "üst sınır",
  _perMonth: "aylık",
  "2-3People": "2-3 kişi",
  theInternationalCooperation: "Uluslararası işbirliği",
  photoAndVideo: "Foto ve video",
  eventTitle: "Etkinlik adı",
  location: "Etkinlik yeri",
  date: "Tarih",
  enterYourRealMail: "Gerçek e-postanızı girin",
  requiredField: "Alanın doldurulması zorunludur",
  minimumLengthCharacters: "Asgari uzunluk {length} karakterdir",
  maximumLengthCharacters: "Azami uzunluk {length} karakterdir",
  theFieldMustBeANumber: "Alan bir sayı olmalıdır",
  onlyLettersAreAllowedInTheField: "Alanda sadece harf kullanılabilir",
  checkKeyboardLayout: "Klavye dilinizi kontrol edin",
  checkTheNumberYouEnteredIsCorrect:
    "Girilen numaranın doğruluğunu kontrol edin",
  olympiadName: "Olimpiyat adı",
  description: "Açıklama",
  contactInformation: "İletişim bilgileri",
  majorForeignPartnerUniversities: "Ana yurt dışı ortak üniversiteler",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Dünyanın her yerinde ve Rusya'da şahsen veya uzaktan Rusça öğrenme fırsatları",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Öğretmen misiniz? Profilinizin sitede yayımlanması için anketinizi gönderin",
  submitApplication: "Anket gönder",
  chooseATeachingExperience: "Öğretim deneyiminizi seçin",
  teachingExperience: "Öğretim deneyimi",
  chooseLanguages: "Dilleri seçin",
  knowledgeOfLanguages: "Dil bilgisi",
  workExperience: "Çalışma geçmişi",
  trainingLevel: "Hazırlık düzeyi",
  durationOfOneLesson: "Bir dersin süresi",
  possibleTrainingTime: "Olası öğretim süresi",
  theCosOfOneLesson: "Bir dersin ücreti",
  writeAMessage: "Mesaj gönder",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Siteye giriş yaptıktan veya kayıt olduktan sonra öğretmene yazabileceksiniz",
  educationDocuments: "Eğitim belgeleri",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Başka bir programı seçtiniz; bu programı seçerseniz daha önce seçilen program ve kuruluşlar silinecektir.",
  limitIsExceeded: "Limit aşılmıştır",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "En fazla 6 üniversite seçebilirsiniz",
  _fieldsOfEducation: "eğitim alanları",
  _trainingAreas: "eğitim dalları",
  _cities: "şehirler",
  _languagesOfInstruction: "eğitim dilleri",
  _typesOfAdditionalPrograms: "ek program türleri",
  _semesters: "dönemler",
  chooseDurationOfStudy: "Eğitim süresini seçin",
  _durationOfStudy: "eğitim süreleri",
  enterTheNameOfTheEducationalProgram: "Program adını girin",
  instruction: "Talimatlar",
  profile: "Anket",
  back: "Geri",
  next: "İleri",
  testReport: "Sınav sonuçları raporu",
  testDate: "Sınav tarihi",
  duration: "Süre",
  memberName: "Katılımcı adı",
  age: "Yaş",
  gender: "Cinsiyet",
  currentLevelOfEducation: "Mevcut eğitim düzeyi",
  areasOfInterestHobbies: "İlgi alanları, hobiler",
  sportsAchievements: "Sportif başarılar",
  testScaleResults: "Test ölçeklerine göre sonuçlar",
  scaleDescription: "Sonuçların ölçeklere göre açıklanması",
  recommendedEducationAreas: "Tavsiye edilen eğitim alanları",
  enterYourAge: "Yaşınızı girin",
  enterYourGender: "Cinsiyetinizi girin",
  chooseYourCurrentLevelOfEducation: "Mevcut eğitim düzeyinizi seçin",
  chooseYourAreasOfInterestHobbies: "İlgi alanlarınızı ve hobilerinizi seçin",
  chooseYourSportingAchievements: "Spor başarılarınızı seçin",
  legalizationOfDocuments: "Belgelerin yasallaştırılması",
  consularLegalization: "Konsolosluk tarafından yasallaştırma",
  edit: "Düzenle",
  mobilePhoneNumber: "Cep telefonu numarası",
  iWantToReceiveNotificationsByEmail:
    "E-posta adresime bildirimleri almak istiyorum",
  authorizationCheck: "Kullanıcı doğrulama",
  createANewApplication: "Yeni başvuru oluştur",
  selectionStage: "seçim aşaması",
  underRevision: "Revizyonda",
  fillingOutAnApplication: "Başvurunun tamamlanması",
  verificationAndRevision: "Kontrol ve revizyon",
  dossierFormation: "Dosyanın oluşturulması",
  fillingInTheQuestionnaire: "Anket doldurma",
  attachingDocuments: "Belgelerin eklenmesi",
  sendingApplicationForVerification: "Başvurunun kontrole gönderilmesi",
  personalData: "Kişisel bilgiler",
  surnameInLatinLettersAccordingToPassport:
    "Soyad, latin harfleri ile (pasaporta göre)",
  nameInLatinLettersAccordingToPassport:
    "Ad (adlar), latin harfleri ile (pasaporta göre)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Baba adı (varsa), kiril harfleri ile",
  placeOfBirthAccordingToPassport: "Doğum tarihi (pasaporta göre)",
  dateOfBirth: "Doğum tarihi",
  compatriotStatus: "Yurttaşlık statüsü",
  citizenshipPlaceOfPermanentResidence: "Uyruk (daimi ikametgah yeri)",
  online: "Online (Çevrimiçi)",
  profile2: "Kullanıcı profili",
  goToTheApplicationForm: "Başvuruyu doldurmaya başla",
  areYouHere: "Buradasınız",
  russianTextDes1:
    "Hazırlık fakülteleri ne için gerekli, neler öğretilir, süreleri ve ücretleri nelerdir",
  russianTextDes4: "Özel Rusça dersleri için öğretmen nerede bulabilirim",
  russianTextDes5:
    "Rusya üniversitesine kayıt yapabilmek için Rusça dil bilgisi nasıl ve ne için teyit edilir. Sertifika testi hakkında her şeyi öğrenin",
  typeOfProgram: "Program türü",
  chooseTheTypeOfAdditionalProgram: "Ek program türünü seçin",
  durationOfStudy: "Eğitim süresi",
  common: "Genel",
  inYourCountry: "Ülkenizde",
  courseName: "Kurs adı",
  courseDuration: "Kurs uzunluğu",
  classDuration: "Ders uzunluğu",
  courseCost: "Kurs ücreti",
  courseReviews: "Kurs hakkında yorumlar",
  male: "Bay",
  female: "Bayan",
  feedbackLink: "Yorumlara bağlantı",
  russianLanguageCoursesInYourCountry: "Ülkenizdeki Rusça kurslar",
  evaluation: "Not",
  giveFeedback: "Yorum yaz",
  newFeedback: "Yeni yorum",
  _hour: "saat",
  _hours: "saat",
  __hours: "saat",
  _minute: "dakika",
  _minutes: "dakika",
  __minutes: "dakika",
  create: "Oluştur",
  feedbackSuccess: "Yorum başarıyla gönderildi ve kontrolü yapılmaktadır",
  _y: "yıl",
  _from: "from",
  error: "Hata",
  direction: "Dal",
  _tutor: "öğretmen",
  _tutors: "öğretmen",
  __tutors: "öğretmen",
  filters: "Filtreler",
  sendMessage: "Mesaj gönder",
  sendMessageSuccess: "Mesaj başarıyla gönderildi",
  _year: "yıl",
  _years: "yıl",
  __years: "yıl",
  _back: "geri",
  _next: "ileri",
  showing: "Göster",
  textRussianLanguageCourses:
    "Menüden ikamet ettiğiniz ülkeyi seçin ve mevcut kurs ve okulları görüntüleyin. Evinizde temel Rusça bilgileri elde etmeniz Rusya'ya gelişinizde alışmanızı kolaylaştıracaktır.",
  tOP10Programs: "İLK 10 program",
  tOP10City: "Rusyanın İLK 10 şehri",
  createANewTopic: "Yeni konu oluştur",
  topics: "Konular",
  posts: "Mesajlar",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    ".pdf, .jpg, .png biçimde 10 Mbayt'a kadar dosyalar",
  noFileSelected: "Dosya seçilmemiş",
  attachFile: "Dosya ekle",
  eMail: "E-mail",
  fullTime: "Tam zamanlı",
  toFind: "Bul",
  searchInTopic: "Konu içinde ara",
  lessThan1Month: "1 aydan az",
  "1To3Months": "1 ile 3 ay arası",
  "3To6Months": "3 ile 6 ay arası",
  "6To12Months": "6 ile 12 ay arası",
  moreThan1Year: "1 yıldan fazla",
  theDateOfThe: "Tarih",
  toAdminPanel: "Yönetici paneline geç",
  support: "Teknik destek",
  faq: "SSS",
  userGuide: "Kullanıcı kılavuzu",
  textUserGuide: "Portalda çalışma talimatları ve eğitim başvurusunun yapılması",
  textRegisterGuide: "Yabancılar için Rusya'da Eğitim web sitesinde kayıt işlemleri'",
  textTrainingGuide: "Yabancılar için Rusya'da Eğitim web sitesinde eğitim başvurusu",
  textApplication: "Anket doldurma",
  instructionForWork: "Başvuru işlemlerine ait talimatlar",
  appeals: "Şikâyetler",
  handling: "Öğrencinin",
  __students: "mesajları",
  _student: "öğrenci",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Eğitim alanlarınızın grubunu tanımlayın ve gelecekteki mesleğiniz için olası seçenekleri öğrenin",
  delTheme: "Konuyu sil",
  competitionForTheFirstPlace: "Rusya Federasyonu kotası dahilinde birincilik sınavı (kişi)",
  ruble: "ruble",
  __rubles: "ruble",
  dateOfEvent: "Yapılma tarihi:",
  durationUnit: "ay",
  numberPhone: "Telefon numarası",
  offline: "Çevrimdışı",
  to_begin: "başlangıca dön",
  theDateOfThe: "Başlangıç tarihi",
  chat: "Sohbet botu",
  yourMessageChat: "Mesajınız",
  searchChat: "Mesajlar kutusuna göre arama",
  firstMsgChat: "Mesajı yazınız",
  categoryBtnChat_1: "Başvuru işlemi ile ilgili soru",
  categoryBtnChat_2: "Eğitim ile ilgili soru",
  categoryBtnChat_3: "Sınav sonuçlarına göre seçme ile ilgili soru",
  categoryBtnChat_4: "Teklif veya tenkit",
  categoryBtnChat_5: "Üniversite faaliyetleri konusunda geri bildirim",
  categoryBtnChat_6: "Hizmet kalitesi konusunda şikayet",
  categoryBtnChat_7: "Teknik desteğe gönderilen mesaj",
  notQuestionResolvedChat: "Sorunum çözülmedi",
  questionResolvedChat: "Sorunum çözüldü",
  countrySelectTrueChat: "Ülke yanlış belirtildi",
  salutationChat: "Merhaba",
  categoryAppealChat: 'Bilgi almak için sorunuzun ait olduğu kategoriyi seçip tuşa basınız. Kategoriyi seçtikten sonra mesaj alanında sorunuzu yazın ve "Gönder" tuşuna basınız.',
  langAppealChat: "Mesajı yazmak istediğiniz dili seçiniz. Rusça ve İngilizce yazılan mesajlar daha hızlı işleme alınır.",
  youCountryChatPath1: "Ülkeniz",
  youCountryChatPath2: "Ülke adı listede yoksa cevap mesajında belirtin.",
  youCountryChat: "Ülkeniz{country}? Ülke adı listede yer almıyorsa cevap mesajında belirtin.",
  notFoundCountryChat: "Belirttiğiniz ülke adı bulunamadı. Yazılan bilgilerin doğruluğunu kontrol edip mesajı tekrar gönderiniz.",
  additionalInfoChat: "Cevap mesajında lütfen mesajınız ile ilgili ilave bilgileri yazınız.",
  yourEmailChat: "Lütfen e-mail adresinizi belirtiniz. Mesajınızın cevabı e-mail ile gönderilecektir.",
  notValidEmailChat: "Bildirdiğiniz e-mail adresi aaa@bbb.ccc formatına uymuyor. Yazılan bilgilerin doğruluğunu kontrol edip mesajı tekrar gönderiniz.",
  acceptRequestChat: "Mesajınız işlenmek üzere operatöre gönderildi. Ortalama işleme alım süresi 7 takvim günüdür.",
  notFoundEmployeeChat: "Belirttiğiniz ülke, Rossotrudniçestvo programı kapsamında eğitimin verildiği ülkeler listesinde yer almıyor. Mesajınız işleme alınamıyor. Lütfen diğer ülkeyi belirtiniz. Eğitim programlarının tam listesini <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>'sayfasında görebilirsiniz. ",
  langBtnChat_1: "İngilizce",
  langBtnChat_2: "Rusça",
  langBtnChat_3: "Diğer",
  addAppBtn: "Başvuru yap",
  listAppBtn: "Başvurular listesi",
  privacyPolicy: "Gizlilik politikası",
  ___programs: "program",
  ___educationalPrograms: "eğitim programları",
  __educationalPrograms: "eğitim programı",
  editTopic: "Konuyu düzenle",
  editMessage: "Mesajı düzenle",
  deleteMessage: "Mesajı sil",
  blockingUser: "Kullanıcının engellenmesi",
  blockEndDate: "Engellemenin bitiş tarihi",
  blockEndTime: "Engellemenin bitiş saati",
  blockForever: "Süresiz engelle",
  block: "Engelle",
  unlock: "Engeli kaldır",
  edited: "Düzenlendi",
  founder: "Kurucu",
  abbreviation: "Kısaltma",
  studentsByCountry: "Ülkelere göre öğrenci sayısı",
  studentsByCountryCount: "Eğitim gören öğrenci sayısı",
  contactsInternationalDepartment: "Uluslararası bölümün iletişim bilgileri",
  site: "Web sitesi",
  address: "Adres",
  format: "Format",
  typeEvent: "Etkinlik tipi",
  quotaLearning: "Kota eğitimi",
  kcp: "Eğitim programını kazanma notu",
  countSeatsKCP: "Eğitim programını kazanma notuna göre yer sayısı",
  countSeatsCostTraining: "Ücretli eğitim programında yer sayısı",
  distanceLearning: "Uzaktan eğitim",
  notificationAboutDistanceLearning: "Eğitim programının uygulanması, elektronik öğrenim ve uzaktan eğitim teknolojilerinin kullanımı ile mümkündür. Detaylı bilgileri üniversiteden alabilirsiniz.",
  additionalTests: "İlave seçme sınavlarının tarihleri",
  pageNotFound: "Sayfa bulunamadı",
  goToMainPage: "Ana sayfaya git",
  faqInformation: "Başvuru doğrulamasının durumu, gerekli belgelerin listesi, seçme sınavlarının zamanlaması ve kabul süreciyle ilgili diğer konularda bilgiler almak için ülkenizde seçim yapmaya yetkili olan Rossotrudniçestvo temsilcilikleri veya Rusya Federasyonu Büyükelçiliği ile iletişime geçmenizi öneririz. Rossotrudniçestvo çalışanları ile iletişim kurmak için irtibat bilgileri kişisel hesabınızda bulunabilir.",
  allEvents: "Tüm etkinlikler",
  eventsAndNews: "Etkinlikler ve haberler",
  titleNews: "Haber başlığı",
  numberOfBudgetPlaces: "Burslu yerler sayısı",
  russianGovernmentQuota: "Bütçe (kabul kontrol rakamları)",
  legalAddress: "Hukuki adres",
  actualAddress: "Fiili adres",
  goToTheRegister: "Ek programların siciline geçmek",
  searchForSuitablePrograms:"Uygun Programları Arayın",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Başvuranların seçtiği en popüler eğitim programları",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Rusya'nın içinde başvuranların seçtiği en popüler",
  ofTheCountry: "şehirler",
  passed: "Tamamlandı",
  question: "Soru",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Portal deneme rejiminde çalışmaktadır!",
  banner_2: "Saygıdeğer kullanıcılar, An itibariye Google e-postalarının kullanımından  kaynaklanabilecek birtakım teknik aksaklıklar meydana gelebilmektedir. Bu sebeple, portal üzerinden yürüteceğiniz işlemlerde farklı e-posta servislerinin sağladığı adresleri kullanmanızı rica ederiz.",
  banner_3: "Evrak kabul ve başvuru süreci 24 Aralık 2022 itibariyle başlayacaktır.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
