import _ from 'lodash';
import {api} from '@/_services';
import Vue from 'vue';
import Vuex from 'vuex';
import VueCookie from 'vue-cookie';
import translationsRu from '../locales/ru';
import translationsEn from '../locales/en';
import translationsAe from '../locales/ae';
import translationsCn from '../locales/cn';
import translationsDe from '../locales/de';
import translationsEs from '../locales/es';
import translationsFr from '../locales/fr';
import translationsIn from '../locales/in';
import translationsMs from '../locales/ms';
import translationsMn from '../locales/mn';
import translationsPer from '../locales/per';
import translationsPt from '../locales/pt';
import translationsTr from '../locales/tr';
import translationsUz from '../locales/uz';
import translationsVn from '../locales/vn';
import translationsIm from '../locales/im';
import iconFlagRu from '@/assets/images/flags/img-flag-ru.png';
import iconFlagEn from '@/assets/images/flags/img-flag-en.png';
import iconFlagAe from '@/assets/images/flags/img-flag-ae.png';
import iconFlagCn from '@/assets/images/flags/img-flag-cn.png';
import iconFlagDe from '@/assets/images/flags/img-flag-de.png';
import iconFlagEs from '@/assets/images/flags/img-flag-es.png';
import iconFlagFr from '@/assets/images/flags/img-flag-fr.png';
import iconFlagIn from '@/assets/images/flags/img-flag-in.png';
import iconFlagMs from '@/assets/images/flags/img-flag-ms.png';
import iconFlagMn from '@/assets/images/flags/img-flag-mn.png';
import iconFlagPer from '@/assets/images/flags/img-flag-per.png';
import iconFlagPt from '@/assets/images/flags/img-flag-pt.png';
import iconFlagTr from '@/assets/images/flags/img-flag-tr.png';
import iconFlagUz from '@/assets/images/flags/img-flag-uz.png';
import iconFlagVn from '@/assets/images/flags/img-flag-vn.png';

import vuexI18n from 'vuex-i18n';
import users from './modules/users';
import news from './modules/news';
import form from './modules/form';
import educationInRussia from './modules/educationInRussia';
import lifeInRussia from './modules/lifeInRussia';
import whyRussia from './modules/whyRussia';
import help from './modules/help';
import alert from './modules/alert';
import modal from './modules/modal';
import preloader from './modules/preloader'
import events from './modules/events';
import chatBot from './modules/chatBot';
import {extend} from 'vee-validate';
import {required, email, min, max, numeric} from 'vee-validate/dist/rules'

const SM = 576; //576px from base.scss
const MD = 768; //768px from base.scss
const LG = 992; //992px from base.scss
const XL = 1200; //1200px from base.scss

Vue.use(Vuex);
Vue.use(VueCookie);

/**
* * индонезйский язык (indomelayu) это просто название, фактически используется малайский (ms)
*/ 
let initialState = {
  apiDomain: process.env.NODE_ENV !== 'production' ? '' : process.env.VUE_APP_API,
  accessToken: localStorage.access || '',
  curLanguage: 'ru',
  languages: [
      {key: 'russian', short: 'ru', icon: iconFlagRu},
      {key: 'english', short: 'en', icon: iconFlagEn},
      {key: 'arab', short: 'ae', icon: iconFlagAe},
      {key: 'chinese', short: 'cn', icon: iconFlagCn},
      {key: 'spanish', short: 'es', icon: iconFlagEs},
      {key: 'french', short: 'fr', icon: iconFlagFr},
      {key: 'portuguese', short: 'pt', icon: iconFlagPt},
      {key: 'indian', short: 'in', icon: iconFlagIn},
      {key: 'vietnamese', short: 'vn', icon: iconFlagVn},
      {key: 'indomelayu', short: 'ms', icon: iconFlagMs}, 
      {key: 'turkish', short: 'tr', icon: iconFlagTr},
      {key: 'persian', short: 'per', icon: iconFlagPer},
      {key: 'uzbek', short: 'uz', icon: iconFlagUz},
      {key: 'german', short: 'de', icon: iconFlagDe},
      {key: 'mongolian', short: 'mn', icon: iconFlagMn},
  ],

  params: {
    pagination: {},
    countries: [],
  },

  menu: [],
  device: {
    sm: false, 
    md: false, 
    lg: false, 
    xl: false, 
  },
  permissions: null,
  currency:null,
};

const setAdditionalParaMLang = lang => {
  if (document.getElementsByTagName('html')[0]) {
    if (lang === 'ae' || lang === 'per') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
      document.body.classList.add('rtl-style')
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir')
      document.body.classList.remove('rtl-style')
    }
  }
};

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: initialState,

  modules: {
    news,
    users,
    form,
    educationInRussia,
    lifeInRussia,
    whyRussia,
    help,
    alert,
    modal,
    events,
    chatBot,
    preloader,
  },

  mutations: {
    setLanguage(state, locale) {
      Vue.i18n.set(locale.short);
      localStorage.lang = JSON.stringify({
        notation: locale.short,
        name: Vue.i18n.translate(locale.key),
        icon: locale.icon,
      })

      state.curLanguage = locale;
      setAdditionalParaMLang(state.curLanguage)
      window.location.reload();
    },

    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }

      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params);
    },

    setMenu(state, payload) {
      state.menu = payload;
    },

    checkDevice(state) {
      const width = window.outerWidth - 0.02;
      const device = {
        sm: false, 
        md: false, 
        lg: false, 
        xl: false, 
      }
      if (width < SM) device.sm = true
      if (width < MD) device.md = true
      if (width < LG) device.lg = true
      if (width < XL) device.xl = true
      state.device = device
    },

    setPermissions: (state, val) => {
      return state.permissions = {...state.permissions, ...val}
    },
  },

  getters: {
    getAllLanguages(state) {
      return state.languages;
    },

    getFilterAllLanguages(state, getters) {
      return state.languages.filter(el => el.short !== getters.getLocale);
    },

    getLocale(state) {
      return state.curLanguage;
    },

    getMenu(state) {
      return state.menu;
    },

    getPagination: state => {
      return state.params.pagination;
    },

    isDevelop: () => {
      return process.env.NODE_ENV !== 'production';
    },
    
    getPermissionsAdmin: state => (state.permissions || {}).data || null,

    getSizeDevice: state => state.device,
    
    getDataCountries: state => state.params.countries,

    getDataCurrency: state => state.currency,
  },

  actions: {
    getDistricts(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/districts',
        params,
      });
    },

    getCountries(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/countries',
        params,
      });
    },

    getAllUniversities(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/all-universities',
        params,
      });
    },

    getLevelEducation(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/level-education',
        params,
      });
    },

    getEducationFilter(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/education-filter',
        params,
      });
    },

    getAreaEducation(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/area-education',
        params,
      });
    },

    getGroupDirections(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/group-directions',
        params,
      });
    },

    getEducationalPrograms(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/educational-programs',
        params,
      });
    },

    getSemesters(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/semesters',
        params,
      });
    },

    getScientificAreas(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/programs/scientific-areas',
        params,
      });
    },

    getScientificSpecialties(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/programs/scientific-specialties',
        params,
      });
    },

    getLanguagesInstruction(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/languages-instruction',
        params,
      });
    },

    getRatingsFilter(s, params = {}) {
      return api.$getMethod(s, {
        url: '/ps/api/ratings',
        params,
      });
    },

    getPage(s, slug) {
      return api.$getMethod(s, {
        url: `/ps/api/page/${slug}`,
      });
    },

    getPageBreadcrumb(s, id) {
      return api.$getMethod(s, {
        url: `/ps/api/page/breadcrumb/${id}`,
      });
    },

    getUser(s) {
      return api.$getMethod(s, {
        url: '/zhps/api/profile',
        mutationType: 'users/setUser',
      });
    },

    getRussianLanguageCourses(s, params) {
      return api.$getMethod(s, {
        url: '/ps/api/courses',
        params,
      });
    },

    getMenu(s, params) {
      return api.$getMethod(s, {
        url: '/ps/api/menu',
        params,
      });
    },

    getPermissionsAdminAction(s, params = {}) {
      return api.$getMethod(s, {
        url: '/zhps/api/roles/admin-functions',
        params,
        mutationType: 'setPermissions',
      });
    }, 
    
    getTypeOfEducations(s) {
      return api.$getMethod(s, {
        url: '/ps/api/application/type-financing',
      });
    },

    getCurrency(s) {
      return api.$getMethod(s, {
        url:  process.env.NODE_ENV !== 'production' ? 'https://dev.education-in-russia.com/zhps/api/course-dollar-euro' : '/zhps/api/course-dollar-euro',
      });
    },
  },
});

const lang = localStorage.lang && JSON.parse(localStorage.lang).notation;

initialState.curLanguage = lang || 'ru';
setAdditionalParaMLang(initialState.curLanguage);

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('ru', translationsRu);
Vue.i18n.add('en', translationsEn);
Vue.i18n.add('ae', translationsAe);
Vue.i18n.add('cn', translationsCn);
Vue.i18n.add('de', translationsDe);
Vue.i18n.add('es', translationsEs);
Vue.i18n.add('fr', translationsFr);
Vue.i18n.add('in', translationsIn);
Vue.i18n.add('ms', translationsMs);
Vue.i18n.add('mn', translationsMn);
Vue.i18n.add('per', translationsPer);
Vue.i18n.add('pt', translationsPt);
Vue.i18n.add('tr', translationsTr);
Vue.i18n.add('uz', translationsUz);
Vue.i18n.add('vn', translationsVn);
Vue.i18n.add('im', translationsIm);

Vue.i18n.set(localStorage.lang && JSON.parse(localStorage.lang).notation || 'ru');

extend('email', {
  ...email,
  message: Vue.i18n.translate('enterYourRealMail'),
})

extend('required', {
  ...required,
  message: Vue.i18n.translate('requiredField'),
})

extend('min', {
  ...min,
  message: Vue.i18n.translate('minimumLengthCharacters'),
})

extend('max', {
  ...max,
  message: Vue.i18n.translate('maximumLengthCharacters'),
})

extend('numeric', {
  ...numeric,
  message: Vue.i18n.translate('theFieldMustBeANumber'),
})

extend('notnull', {
    validate(value) {
        let one = value.substring(0, 1)
        if(one==0) {
          return false;
        }
        else {
          return true
        }
    },

    message: Vue.i18n.translate('theFieldMustBeANumber'),
})
extend('onlyText', {
  validate(value) {
    return !/[0-9]/.test(value)
  },
  message: Vue.i18n.translate('onlyLettersAreAllowedInTheField'),
})

extend('latin', {
  validate(value) {
    return !/[а-яА-Я]/.test(value)
  },

  message: Vue.i18n.translate('checkKeyboardLayout'),
});

extend('phone', {
  validate(value) {
    return /^[+][0-9]{11,13}$/.test(value);
  },

  message: Vue.i18n.translate('checkTheNumberYouEnteredIsCorrect'),
});

export default store;
