export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "مساعدة",
  personalArea: "الحساب الشخصي",
  myApplications: "طلباتي",
  servicesAndFacilities: "الخدمات",
  logout: "خروج",
  favorites: "المفضلة",
  theChoice: "الاختيارات",
  notifications: "الإشعارات",
  logIn: "دخول",
  quickAccessThroughSocialNetworks:
    "تسجيل دخول سريع عبر شبكات التواصل الاجتماعي",
  enterYourEmail: "أدخل عنوان بريدك الإلكتروني",
  password: "كلمة المرور",
  enterPassword: "أدخل كلمة المرور الخاصة بك",
  rememberMe: "تذكرني",
  forgotYourPassword: "هل نسيت كلمة المرور؟",
  noAccount: "ليس لديك حساب؟",
  registration: "تسجيل",
  countryOfCitizenship: "بلد الجنسية",
  chooseYourCountryOfCitizenship: "حدد بلد الجنسية",
  iHaveReadAndAgree: "لقد قرأت وأوافق",
  _toTheProcessingOfMyPersonalData: "على معالجة بياناتي الشخصية",
  iAgreeTo: "أوافق على",
  _theUseOfCookies: 'استخدام ملفات تعريف الارتباط "cookie"',
  signUp: "إنشاء حساب",
  haveAnAccount: "هل لديك حساب؟",
  mainPage: "الصفحة الرئيسية",
  frequentlyAskedQuestionsAnswers: "إجابات على الأسئلة الأكثر طرحا",
  goTo: "انتقال",
  startTesting: "بدء الاختبار",
  buildCareerVector: "إنشاء ناقل وظيفي",
  forum: "المنتدى",
  textForumBanner:
    "ناقش في المنتدى العام جميع الموضوعات التي تهمك: كيف تسير الحياة في المدن الروسية، كيف تتم العملية التعليمية في الجامعات الروسية وغير ذلك.",
  feedback: "التواصل العكسي",
  technicalSupportFeedbackForm: "نموذج التواصل العكسي مع الدعم الفني",
  allCities: "جميع المدن",
  citiesOfRussia: "مدن روسيا",
  educationInRussia: "الدراسة في روسيا",
  educationalOrganizations: "الجامعات",
  educationInRussiaYourKeyToSuccess: "التعليم في روسيا هو مفتاحك للنجاح",
  applyForStudyInRussia: "قدم طلبًا للدراسة في روسيا",
  apply: "تقديم طلب",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "سجّل الدخول أو أنشئ حسابًا للحصول على توجيه مهني وتقديم طلب",
  aboutOrganization: "حول المؤسسة",
  eventsAndOlympiads: "الفعاليات والأولمبيادات",
  educationalPrograms: "البرامج",
  contacts: "جهات الاتصال",
  inFavorites: "في المفضلة",
  toFavorites: "إضافة إلى المفضلة",
  fromChoices: "تم الاختيار",
  choices: "اختيار",
  generalInformation: "معلومات عامة",
  internationalRatings: "التصنيفات الدولية",
  nationalRatings: "التصنيفات الوطنية",
  ratings: "التصنيفات",
  year: "السنة",
  ratingName: "اسم التصنيف",
  aPlace: "المرتبة",
  screeningTests: "اختبارات الاختيار",
  choiceOfProfession: "اختيار المهنة",
  lifeInRussia: "الحياة في روسيا",
  russianLanguage: "اللغة الروسية",
  livingConditions: "الظروف المعيشية",
  cultureAndLeisure: "الثقافة والترفيه",
  safety: "الأمان",
  benefitsForInternationalStudents: "مزايا للطلاب الأجانب",
  workForInternationalStudents: "العمل للطلاب الأجانب",
  studentVisaAndRegistration: "التأشيرة الدراسية والتسجيل",
  healthInsurance: "التأمين الصحي",
  howToEnter: "كيفية التقديم",
  educationLevels: "مستويات التعليم",
  admissionProcess: "عملية التقدم للالتحاق بالجامعة",
  olympics: "الأولمبيادات الطلابية",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "{year} الموقع الرسمي لاختيار المواطنين الأجانب للدراسة في روسيا، العام",
  whyRussia: "لماذا روسيا؟!",
  "10reasonsToStudyInRussia": "10 أسباب للدراسة في روسيا",
  reviewsOfForeignStudents: "تقييمات الطلاب الأجانب",
  onlineMonitoring: "الرصد المباشر",
  mainPrograms: "البرامج الرئيسية",
  additionalPrograms: "البرامج الإضافية",
  chooseCounty: "اختر المنطقة",
  county: "المنطقة",
  chooseACity: "اختر المدينة",
  city: "المدينة",
  chooseYourLevelOfEducation: "اختر مستوى التعليم",
  chooseYourAreaOfScientificKnowledge: "اختر مجال المعرفة العلمية",
  chooseYourScientificSpecialtyProfile: "اختر ملف تعريف للتخصص العلمي",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "مستوى التعليم",
  chooseAGroupOfTrainingAreas: "اختر مجموعة مجالات الدراسة",
  trainingDirectionGroup: "مجموعة مجالات الدراسة",
  chooseATrainingDirection: "اختر مجال الدراسة",
  trainingDirection: "مجال الدراسة",
  chooseRatings: "اختر التصنيف",
  thePresenceOfThePreparatoryFaculty: "توفر كلية تحضيرية",
  show: "عرض",
  _university: "جامعة",
  _universities: "جامعة",
  __universities: "جامعة",
  clearAllFilters: "إعادة تعيين جميع الفلاتر",
  chooseSemester: "اختر الفصل الدراسي",
  semester: "فصل دراسي",
  enterTheNameOfTheEducationalOrganization: "أدخل اسم الجامعة",
  _educationalOrganization: "جامعة",
  _educationalOrganizations: "جامعة",
  __educationalOrganizations: "جامعة",
  chooseYourAreaOfEducation: "اختر مجال التعليم",
  areaOfEducation: "مجال التعليم",
  chooseYourLanguageOfInstruction: "اختر لغة التدريس",
  languageOfInstruction: "لغة التدريس",
  _program: "البرنامج",
  _programs: "البرامج",
  __programs: "برنامج",
  formOfTraining: "نظام الدراسة",
  level: "المستوى",
  typeOfTraining: "نوع الدراسة",
  free: "مجانية",
  paid: "مدفوعة الأجر",
  tuitionFees: "تكلفة الدراسة مدفوعة الأجر",
  established: "تاريخ التأسيس",
  numberOfStudents: "عدد الطلاب",
  _students: "طالب",
  numberOfQuotaPlaces: "عدد المقاعد بموجب نظام الحصص",
  _for: "بالنسبة",
  numberOfInternationalStudents: "للطلاب الأجانب",
  _educationalPrograms: "للبرامج التعليمية",
  moreDetails: "مزيد من التفاصيل",
  sTEP: "الخطوة",
  afterTrainingForThisProgramYouCanBecome:
    "بعد التخرج من هذا البرنامج، يمكنك أن تصبح",
  chooseProgram: "اختيار البرنامج",
  chooseUniversity: "اختيار الجامعة",
  print: "طباعة",
  save: "حفظ",
  programs: "البرامج",
  selectedProgram: "البرنامج المختار",
  noProgramSelected: "لم يتم اختيار البرنامج",
  selectedEducationalOrganizations: "الجامعات المختارة",
  educationalOrganizationNotSelected: "لم يتم اختيار الجامعة",
  representation: "الممثلية",
  programDescription: "وصف البرنامج",
  programPageOnTheUniversityWebsite: "صفحة البرنامج على موقع الجامعة",
  programCurator: "القيم على البرنامج",
  disciplines: "المواد الدراسية",
  theProcessOfEnteringARussianEducationalOrganization:
    "عملية الالتحاق بجامعة روسية",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "اختر برنامجًا وما لا يزيد عن 6 جامعات",
  takeTheCompetitiveSelectionInYourCountry: "اجتز الاختيار التنافسي في بلدك",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "املأ الطلب عبر الإنترنت وأرفق حزمة الوثائق",
  getAnInvitationToStudAnStudyVisa: "احصل على دعوة للدراسة وتأشيرة الدراسة",
  preparatoryFaculties: "الكليات التحضيرية",
  summerAndWinterSchools: "المدارس الصيفية والشتوية",
  russianLanguageCourses: "دورات اللغة الروسية",
  tutorsTheRussianLanguage: "معلمو اللغة الروسية",
  certificationInRussian: "شهادة اللغة الروسية",
  news: "الأخبار",
  allNews: "جميع الأخبار",
  reviewsOfStudentsAndGraduates: "تقييمات الطلاب والخريجين",
  areYouSureYouWantToWithdrawTheApplication: "هل أنت متأكد أنك تريد سحب الطلب؟",
  withdrawApplication: "سحب الطلب",
  jan: "يناير/ كانون الثاني",
  feb: "فبراير/ شباط",
  mar: "مارس/ آذار",
  apr: "أبريل/ نيسان",
  may: "مايو/ أيار",
  jun: "يونيو/ حزيران",
  jul: "يوليو/ تموز",
  aug: "أغسطس/ آب",
  sep: "سبتمبر/ أيلول",
  act: "أكتوبر/ تشرين الأول",
  nov: "نوفمبر/ تشرين الثاني",
  dec: "ديسمبر/ كانون الأول",
  cityFeatures: "ميزات المدينة",
  enterTheNameOfTheCity: "أدخل اسم المدينة",
  organizations: "المؤسسة",
  livingWage: "الحد الأدنى لمستوى المعيشة",
  "one-roomApartmentForRent": "تكلفة استئجار شقة من غرفة واحدة",
  _month: "شهريًا",
  averageTemperature: "متوسط درجة الحرارة",
  _inWinter: "في الشتاء",
  _inSummer: "في الصيف",
  averageCostOfDormitories: "متوسط ​​تكلفة السكن الجامعي",
  photos: "الصور",
  interestingPlaces: "المعالم السياحية",
  readCompletely: "قراءة المزيد",
  _review: "تقييم",
  _reviews: "تقييم",
  __reviews: "تقييم",
  university: "الجامعة",
  universities: "الجامعات",
  country: "الدولة",
  selectACountry: "اختر الدولة",
  reviewOfAForeignStudent: "تقييم الطالب الأجنبي",
  careerGuidance: "التوجيه المهني",
  determineYourAreaOfEducationAndProfession:
    "حدد مجال التعليم والمهنة المناسبين لك",
  myCareer: "مهنتي",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "كيفية الجمع بين الدراسة والترفيه الثقافي والمعارف الجديدة. مميزات البرامج المكثفة وتكلفتها",
  toApply: "تطبيق",
  chooseYear: "اختر السنة",
  _in: "في",
  universitiesInThisCity: "الجامعات في هذه المدينة",
  programsInThisCity: "البرامج التعليمية في هذه المدينة",
  all: "الكل",
  _inYourCountry: "في بلدك",
  _common: "المشتركة",
  allReviews: "جميع التقييمات",
  calendar: "التقويم",
  activity: "فعاليات",
  addToPersonalCalendar: "إضافة إلى التقويم الشخصي",
  eventAdded: "تمت إضافة الحدث",
  eventSuccessfullyAddedToYourCalendar:
    "تمت إضافة الحدث بنجاح إلى التقويم الخاص بك!",
  population: "عدد السكان",
  onTheMap: "على الخريطة",
  generalEducation: "التعليم العام",
  professionalEducation: "التعليم المهني",
  preschoolEducation: "تعليم ما قبل المدرسة",
  kindergarten: "روضة أطفال",
  nursery: "حضانة أطفال",
  earlyDevelopmentCenters: "مراكز التنمية المبكرة",
  primaryAndSecondaryEducation: "تعليم ابتدائي، أساسي، ثانوي",
  schools: "المدارس",
  lyceums: "المدارس الثانوية التخصصية",
  gymnasium: "مدارس الغمنازيوم المتقدمة",
  secondaryVocationalEducation: "التعليم المهني المتوسط",
  colleges: "كليات",
  technicalSchools: "مدارس فنية",
  secondarySchool: "معهد مهني",
  higherEducation: "التعليم العالي",
  baccalaureate: "درجة البكالوريوس",
  specialistDegree: "درجة تخصص",
  magistracy: "ماجستير",
  postgraduateStudies: "الدراسات العليا",
  preparatoryFaculty: "الكلية التحضيرية",
  summerSchools: "المدارس الصيفية",
  additionalGeneralAndPreVocationalPrograms:
    "برامج تطوير عامة إضافية وبرامج ما قبل التخصص",
  continuingEducationAndRetrainingPrograms:
    "برامج تحسين المستوى التأهيلي وإعادة التدريب المهني",
  whatAreTheLevelsOfRussianEducation: "ما هي مستويات التعليم الروسي؟",
  enterYourName: "أدخل أسمك",
  name: "الإسم",
  selectTheSubjectOfTheMessage: "اختر موضوع الرسالة",
  messageSubject: "موضوع الرسالة",
  chooseAMessage: "اختر الرسالة",
  message: "الرسالة",
  submit: "إرسال",
  frequentlyAskedQuestions: "الأسئلة الأكثر طرحاً",
  didNotFindTheAnswerToYourQuestion: "لم تجد إجابة عن سؤالك",
  contactSupportAt: "اتصل بفريق الدعم على العنوان",
  searchInTheSection: "البحث في قسم",
  sort: "فرز",
  searchingResults: "نتائج البحث",
  _answers: "رد",
  _views: "مشاهدة",
  byCreationDate: "حسب تاريخ الإنشاء",
  byTheNumberOfResponses: "حسب عدد الردود",
  byViews: "حسب عدد المشاهدات",
  toAnswer: "رد",
  toQuote: "اقتباس",
  complain: "تقديم شكوى",
  addNewMessage: "أضف رسالة جديدة",
  selectComplaintType: "اختر نوع الشكوى",
  typeOfComplaint: "نوع الشكوى",
  enterComplaintText: "أدخل نص الشكوى",
  complaintText: "نص الشكوى",
  complaintSentSuccessfully: "تم إرسال الشكوى بنجاح",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "تم إرسال شكواك بنجاح، وسننظر في شكواك في أقرب وقت ممكن",
  close: "إغلاق",
  myMessages: "رسائلي",
  myTopicsAndPosts: "مواضيعي ورسائلي",
  searchInMyPosts: "البحث في رسائلي",
  _posts: "الرسائل",
  feedbackOnEducationalOrganizations: "تقييمات الجامعات",
  posted: "تم النشر",
  goToMessage: "الانتقال إلى الرسالة",
  _themes: "المواضيع",
  themeTitle: "اسم الموضوع",
  enterAThemeName: "أدخل اسم الموضوع",
  cancel: "إلغاء",
  banUser: "حظر مستخدم؟",
  areYouSure: "هل أنت متأكد؟",
  toBan: "حظر",
  _logIn: "تسجيل الدخول",
  _or: "أو",
  _signUp: "إنشاء حساب",
  eventCalendar: "تقويم الأحداث",
  trainingApplicationsFiled: "الطلبات المقدمة الدراسة",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    " أشهر الجامعات التي اختارها الطلاب المتقدمون",
  _of: "من",
  _whenApplying: "عند تقديم الطلب",
  tOP10EducationalOrganizations: "أفضل 10 جامعات",
  collapse: "تحجيم",
  showAll: "عرض الكل",
  conditionsForForeigners: "الشروط الخاصة بالأجانب",
  supportForInternationalStudents: "دعم الطلاب الأجانب",
  lifeOfForeignStudents: "الحياة اليومية للطلاب الأجانب",
  leisureAndSportingEvents: "الأنشطة الترفيهية والرياضية",
  dormitories: "المساكن الجامعية",
  dormitoryCost: "تكلفة المساكن الجامعية",
  theNumberOfPeopleInTheRoom: "عدد الأشخاص في الغرفة",
  from: "من",
  _rubles: "روبل",
  _to: "حتى",
  _perMonth: "في الشهر",
  "2-3People": "2-3 أشخاص",
  theInternationalCooperation: "التعاون الدولي",
  photoAndVideo: "الصور والفيديو",
  eventTitle: "اسم الحدث",
  location: "مكان إقامة الحدث",
  date: "التاريخ",
  enterYourRealMail: "أدخل البريد الإلكتروني الحقيقي الخاص بك",
  requiredField: "حقل مطلوب ملؤه",
  minimumLengthCharacters: "الحد الأدنى {length} رمز",
  maximumLengthCharacters: "الحد الأقصى {length} رمز",
  theFieldMustBeANumber: "تكتب الأرقام فقط في هذا الحقل",
  onlyLettersAreAllowedInTheField: "تكتب الحروف فقط في هذا الحقل",
  checkKeyboardLayout: "تأكد من توزيع الرموز على لوحة المفاتيح",
  checkTheNumberYouEnteredIsCorrect: "تأكد من صحة الرقم الذي أدخلته",
  olympiadName: "اسم الأولمبياد",
  description: "الوصف",
  contactInformation: "معلومات الاتصال",
  majorForeignPartnerUniversities: "الجامعات الأجنبية الشريكة الرئيسية",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "إمكانية دراسة اللغة الروسية وفق نظام الدوام الكامل أو عن بعد في روسيا من جميع بقاع العالم",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "هل انت مدرس؟ قم بإرسال نموذج طلب لنشر ملفك الشخصي على الموقع",
  submitApplication: "تقديم الطلب",
  chooseATeachingExperience: "حدد سنوات الخبرة في التدريس",
  teachingExperience: "سنوات الخبرة في التدريس",
  chooseLanguages: "حدد اللغات",
  knowledgeOfLanguages: "معرفة اللغات",
  workExperience: "سنوات الخبرة",
  trainingLevel: "المستوى الدراسي",
  durationOfOneLesson: "مدة الدرس الواحد",
  possibleTrainingTime: "وقت التدريس الممكن",
  theCosOfOneLesson: "تكلفة الدرس الواحد",
  writeAMessage: "كتابة رسالة",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "يمكنك الكتابة إلى المعلم بعد إنشاء الحساب أو تسجيل الدخول إلى الموقع",
  educationDocuments: "وثائق التعليم",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "لقد قمت بالفعل باختيار برنامج آخر، إذا قمت باختيار هذا البرنامج، فسيتم فقد البرنامج والمؤسسات المختارة.",
  limitIsExceeded: "تم تجاوز الحد المسموح به",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "لديك إمكانية اختيار ما لا يزيد عن 6 جامعات.",
  _fieldsOfEducation: "مجالات التعليم",
  _trainingAreas: "تخصصات الدراسة",
  _cities: "المدن",
  _languagesOfInstruction: "لغات التدريس",
  _typesOfAdditionalPrograms: "أنواع البرامج التعليمية الإضافية",
  _semesters: "الفصول الدراسية",
  chooseDurationOfStudy: "اختر مدة الدراسة",
  _durationOfStudy: "مدة الدراسة",
  enterTheNameOfTheEducationalProgram: "أدخل اسم البرنامج التعليمي",
  instruction: "الإرشادات",
  profile: "نموذج الطلب",
  back: "السابق",
  next: "التالي",
  testReport: "تقرير نتائج الاختبار",
  testDate: "تاريخ الاختبار",
  duration: "المدة",
  memberName: "اسم المشارك",
  age: "العمر",
  gender: "الجنس",
  currentLevelOfEducation: "المستوى الحالي للتعليم",
  areasOfInterestHobbies: "مجالات الاهتمام والهوايات",
  sportsAchievements: "الإنجازات الرياضية",
  testScaleResults: "النتائج وفقًا لمقاييس الاختبار",
  scaleDescription: "وصف النتائج وفقًا للمقاييس",
  recommendedEducationAreas: "مجالات التعليم الموصى بها",
  enterYourAge: "أدخل عمرك",
  enterYourGender: "أدخل جنسك",
  chooseYourCurrentLevelOfEducation: "اختر مستوى التعليم الحالي الخاص بك",
  chooseYourAreasOfInterestHobbies: "اختر مجالات اهتمامك وهواياتك",
  chooseYourSportingAchievements: "اختر إنجازاتك الرياضية",
  legalizationOfDocuments: "تصديق الوثائق",
  consularLegalization: "تصديق قنصلي",
  edit: "تعديل",
  mobilePhoneNumber: "رقم الهاتف المحمول",
  iWantToReceiveNotificationsByEmail: "أريد تلقي إشعارات عبر البريد الإلكتروني",
  authorizationCheck: "التحقق من تسجيل الدخول",
  createANewApplication: "إنشاء طلب جديد",
  selectionStage: "مرحلة الاختيار",
  underRevision: "للتعديل",
  fillingOutAnApplication: "ملء الطلب",
  verificationAndRevision: "التدقيق والتعديل",
  dossierFormation: "تشكيل الملف",
  fillingInTheQuestionnaire: "تعبئة نموذج الطلب",
  attachingDocuments: "إرفاق الوثائق",
  sendingApplicationForVerification: "إرسال الطلب للتدقيق",
  personalData: "البيانات الشخصية",
  surnameInLatinLettersAccordingToPassport:
    "اسم العائلة، بأحرف لاتينية (وفقًا لجواز السفر)",
  nameInLatinLettersAccordingToPassport:
    "الاسم (الأسماء)، بأحرف لاتينية (وفقًا لجواز السفر)",
  middleNameIAnyCyrillicInRussianTranscription:
    "الاسم الأوسط (إن وجد)، بأحرف كيريلية في النسخ الروسي",
  placeOfBirthAccordingToPassport: "مكان الولادة (وفقًا لجواز السفر)",
  dateOfBirth: "تاريخ الولادة",
  compatriotStatus: "وضع المواطن الروسي المقيم في الخارج",
  citizenshipPlaceOfPermanentResidence: "الجنسية (مكان الإقامة الدائمة)",
  online: "عبر الانترنت",
  profile2: "الملف الشخصي",
  goToTheApplicationForm: "الانتقال لملء الطلب",
  areYouHere: "أنت هنا",
  russianTextDes1:
    "لماذا تعتبر الكليات التحضيرية ضرورية، وما هي المواد التي تُدرس فيها، وكم تبلغ مدة وتكلفة الدراسة فيها",
  russianTextDes4: "أين وكيف يمكن العثور على مدرس دروس خصوصية باللغة الروسية",
  russianTextDes5:
    "كيف ولماذا ينبغي تأكيد المستوى الخاص بإجادة اللغة الروسية عند الالتحاق بجامعة روسية. اعرف كل شيء عن اجتياز اختبار الشهادة",
  typeOfProgram: "نوع البرنامج التعليمي",
  chooseTheTypeOfAdditionalProgram: "اختر نوع البرنامج التعليمي الإضافي",
  durationOfStudy: "مدة الدراسة",
  common: "المشتركة",
  inYourCountry: "في بلدك",
  courseName: "اسم الدورة التعليمية",
  courseDuration: "مدة الدورة التعليمية",
  classDuration: "مدة الدروس",
  courseCost: "تكلفة الدورة التعليمية",
  courseReviews: "التقييمات الخاصة بالدورة التعليمية",
  male: "ذكر",
  female: "أنثى",
  feedbackLink: "رابط التقييمات",
  russianLanguageCoursesInYourCountry: "دورات اللغة الروسية في بلدك",
  evaluation: "التقييم",
  giveFeedback: "كتابة تقييم",
  newFeedback: "تقييم جديد",
  _hour: "ساعة",
  _hours: "ساعة",
  __hours: "ساعة",
  _minute: "الدقيقة",
  _minutes: "دقيقة",
  __minutes: "دقيقة",
  create: "إنشاء",
  feedbackSuccess: "تم إرسال التقييم بنجاح وستتم مراجعته بالقريب العاجل",
  _y: "سنة",
  _from: "c",
  error: "خطأ",
  direction: "التخصص",
  _tutor: "مدرس",
  _tutors: "مدرس",
  __tutors: "مدرس",
  filters: "الفلاتر",
  sendMessage: "ارسال رسالة",
  sendMessageSuccess: "تم ارسال الرسالة بنجاح",
  _year: "سنة",
  _years: "سنة",
  __years: "سنة",
  _back: "السابق",
  _next: "التالي",
  showing: "عرض",
  textRussianLanguageCourses:
    "حدد بلد إقامتك من القائمة وتعرّف على دورات تعليم اللغة والمدارس المتاحة. إن الحصول على مستوى أساسي من اللغة الروسية في بلدك سيسهل بشكل كبير من عملية التكيف عند السفر.",
  tOP10Programs: "أفضل 10 برامج تعليمية",
  tOP10City: "أفضل 10 مدن روسية",
  createANewTopic: "إنشاء موضوع جديد",
  topics: "المواضيع",
  posts: "الرسائل",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "الملفات بتنسيق .pdf و.jpg و.png بحجم لا يزيد عن 10 ميغابايت",
  noFileSelected: "لم يتم اختيار اي ملف",
  attachFile: "إرفاق ملف",
  eMail: "البريد الإلكتروني",
  fullTime: "دوام كامل",
  toFind: "بحث",
  searchInTopic: "بحث في الموضوع",
  lessThan1Month: "أقل من شهر واحد",
  "1To3Months": "من 1 إلى 3 أشهر",
  "3To6Months": "من 3 إلى 6 أشهر",
  "6To12Months": "من 6 إلى 12 شهرًا",
  moreThan1Year: "أكثر من سنة واحدة",
  theDateOfThe: "تاريخ التنفيذ",
  toAdminPanel: "الإنتقال إلى لوحة الإدارة",
  support: "الدعم الفني",
  faq: "الأسئلة المتكررة",
  userGuide: "دليل المستخدم",
  textUserGuide: "إرشادات استخدام البوابة وتقديم طلب الدراسة",
  textRegisterGuide: "التسجيل على موقع الدراسة في روسيا للطلاب الأجانب",
  textTrainingGuide: "طلب الدراسة على موقع الدراسة في روسيا للطلاب الأجانب",
  textApplication: "تعبئة نموذج الطلب",
  instructionForWork: "إرشادات تعبئة الطلب",
  appeals: "طلبات الاعتراض",
  handling: "الالتماسات",
  __students: "طالب",
  _student: "طالب",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "حدد مجموعة تخصصات الدراسة التي ترغب بها وتعرّف على الخيارات الممكنة لمهنتك المستقبلية",
  delTheme: "حذف الموضوع",
  competitionForTheFirstPlace: "المنافسة على المركز الأول في إطار الحصص الدراسية التي تقدمها روسيا الاتحادية (شخص)",
  ruble: "روبل",
  __rubles: "روبل",
  dateOfEvent: "تاريخ الحدث",
  durationUnit: "الشهر",
  numberPhone: "رقم الهاتف",
  offline: "حضوريًا",
  to_begin: "إلى البداية",
  theDateOfThe: "تاريخ البدء",
  chat: "دردشة آلية",
  yourMessageChat: "رسالتك",
  searchChat: "بحث في الحوار",
  firstMsgChat: "أدخل نص الرسالة",
  categoryBtnChat_1: "سؤال حول تقديم الطلب",
  categoryBtnChat_2: "سؤال حول الدراسة",
  categoryBtnChat_3: "سؤال حول الاختبارات التأهيلية التنافسية",
  categoryBtnChat_4: "اقتراح أو ملاحظة",
  categoryBtnChat_5: "ملاحظات حول أنشطة الجامعة",
  categoryBtnChat_6: "شكوى على جودة الخدمات",
  categoryBtnChat_7: "الاتصال بالدعم الفني",
  notQuestionResolvedChat: "لم يتم حل مشكلتي",
  questionResolvedChat: "مشكلتي حُلت",
  countrySelectTrueChat: "البلد محدد بشكل صحيح",
  salutationChat: "مرحبًا.",
  categoryAppealChat: '"للحصول على معلومات، حدد الفئة التي ينتمي إليها سؤالك بالنقر فوق الزر. بعد تحديد الفئة، أدخل سؤالك في الرسالة وانقر فوق الزر "إرسال.',
  langAppealChat: "اختر اللغة التي تريد إرسال الطلبات بها. تعالج الطلبات المرسلة باللغتين الروسية والإنجليزية لسرعة أكبر من غيرها.",
  youCountryChatPath1: "بلدك",
  youCountryChatPath2: "إذا لم يكن اسم بلدك موجودًا ضمن القائمة، فيرجى تضمين اسم البلد في رسالة الرد.",
  youCountryChat: "بلدك {country}؟ إذا لم يكن اسم بلدك موجودًا ضمن القائمة، فيرجى تضمين اسم البلد برسالة رد.",
  notFoundCountryChat: "تعذّر العثور على بلد بالاسم الذي حددته. تأكد من صحة البيانات المدخلة وأعد إرسال الرسالة.",
  additionalInfoChat: "يرجى تحديد معلومات إضافية ذات صلة بطلبك في رسالة الرد.",
  yourEmailChat: "يرجى إدخال عنوان البريد الإلكتروني الخاص بك. سيرسل الرد على طلبك برسالة عبر البريد الإلكتروني.",
  notValidEmailChat: "عنوان البريد الإلكتروني الذي أرسلته لا يطابق التنسيق aaa@bbb.ccc. تأكد من صحة البيانات المدخلة وأعد إرسال الرسالة.",
  acceptRequestChat: "تم إرسال طلبك إلى المشغل لمعالجته. متوسط ​​مدة النظر في الطلب هو 7 أيام تقويمية.",
  notFoundEmployeeChat: 'البلد الذي حددته غير مدرج في قائمة البلدان التي يحق لمواطنيها الدراسة في إطار برنامج مؤسسة "روس سوترودنيتشيستفو". يتعذّر ارسال طلبك للنظر فيه. يرجى تحديد بلد آخر. للاطلاع على القائمة الكاملة لبرامج الدراسة، يرجى زيارة صفحة <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>',
  langBtnChat_1: "الإنجليزية",
  langBtnChat_2: "الروسية",
  langBtnChat_3: "غير ذلك",
  addAppBtn: "تقديم طلب",
  listAppBtn: "قائمة الطلبات",
  privacyPolicy: "سياسة الخصوصية",
  ___programs: "البرنامج",
  ___educationalPrograms: "البرامج التعليمية",
  __educationalPrograms: "برنامج تعليمي",
  editTopic: "تعديل الموضوع",
  editMessage: "تعديل الرسالة",
  deleteMessage: "حذف الرسالة",
  blockingUser: "حظر المستخدم",
  blockEndDate: "تاريخ انتهاء الحظر",
  blockEndTime: "وقت انتهاء الحظر",
  blockForever: "حظر للأبد",
  block: "حظر",
  unlock: "رفع الحظر",
  edited: "مُعدل",
  founder: "المؤسس",
  abbreviation: "الاختصار",
  studentsByCountry: "عدد الطلاب حسب الدولة",
  studentsByCountryCount: "عدد الطلاب الدارسون",
  contactsInternationalDepartment: "جهات اتصال القسم الدولي",
  site: "الموقع الإلكتروني",
  address: "العنوان",
  format: "التنسيق",
  typeEvent: "نوع الحدث",
  quotaLearning: "دراسة بموجب نظام المنح",
  kcp: "عدد المقاعد الدراسية في الجامعة",
  countSeatsKCP: "عدد المقاعد الدراسية",
  countSeatsCostTraining: "عدد المقاعد الدراسية مدفوعة الأجر",
  distanceLearning: "الدراسة عن بعد",
  notificationAboutDistanceLearning: "التسجيل في البرنامج التعليمي ممكن باستخدام تقنيات التعلم الإلكتروني والدراسة عن بعد. يرجى التواصل مع الجامعة للحصول على مزيد من التفاصيل.",
  additionalTests: "مواعيد الاختبارات التأهيلية الإضافية",
  pageNotFound: "الصفحة المطلوبة غير موجودة",
  goToMainPage: "الذهاب إلى الصفحة الرئيسية",
  faqInformation: 'للحصول على مزيد من التفاصيل بخصوص الأسئلة المتعلقة بحالة النظر في طلب الدراسة وقائمة المستندات المطلوبة ومواعيد الاختبارات التأهيلية بالإضافة إلى الأسئلة الأخرى المتعلقة بعملية القبول، يرجى الاتصال بالمكاتب التمثيلية لمؤسسة "روس سوترودنيتشيستفو" أو سفارات روسيا الاتحادية وهم المخولين الحصريين بإجراء الاختبارات التنافسية التأهيلية في بلدك. يمكنك العثور على جهات الاتصال للتواصل مع موظفي "روس سوترودنيتشيستفو" في حسابك الشخصي.',
  allEvents: "جميع الأحداث",
  eventsAndNews: "الأحداث والأخبار",
  titleNews: "عنوان الخبر",
  numberOfBudgetPlaces: "عدد المقاعد الدراسية الممولة من قبل الدولة",
  russianGovernmentQuota: "الحصص الدراسية التي تمنحها حكومة روسيا الاتحادية",
  legalAddress: "العنوان الرسمي",
  actualAddress: "العنوان الفعلي",
  goToTheRegister: "الانتقال إلى قائمة البرامج الإضافية",
  searchForSuitablePrograms: "ابحث عن البرامج المناسبة",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "أكثر البرامج التعليمية رواجًا بين الطلبة المتقدمين",
  theMostPopularCitiesOfRussiaChosenByApplicants: "المدن الروسية الأكثر شهرةً والتي اختارها الطلبة المتقدمين",
  ofTheCountry: "من دولة",
  passed: "منجز",
  question: "سؤال",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "تعمل البوابة بنظام التشغيل التجريبي!",
  banner_2: "من \"غوغل\". عند العمل في البوابة يرجى استخدام خدمات البريد الالكتروني الأخرى. حضرة المستخدمين! قد تحدث صعوبات تقنية حالياً عند استخدام عناوين البريد الالكتروني",
  banner_3: "تبدأ حملة التقديم من 24 كانون الأول/ ديسمبر 2022.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
