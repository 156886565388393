<template>
  <div @mouseover="() => showPasswordFn(false)" :class="`input-container-provider ${elementClassParent}`">
    <validation-provider :name="storePath" :rules="rules" v-slot="{errors}">
      <div class="form-row">
        <div class="input-container">
          <label :for="elementId || storePath" :class="`form-control-label ${ labelClass || '' }`" v-if="label" v-html="label" />
          <input
            :autocomplete="autocomplete"
            :class="elementClass"
            :disabled="disabled"
            :id="elementId || storePath"
            :name="storePath"
            :placeholder="placeholder"
            :type="type ? type : 'text'"
            @change="set"
            @keyup="set"
            v-on:keyup.13="enterCallback"
            @autocomplete="set"
            ref="input"
            v-model="valuePreSet"
            @focus="el => autocomplete !== 'on' && el.target.setAttribute('autocomplete', 'nope')"
          >

          <div class="hint-error" v-if="error">
            {{ error }}
          </div>
          <div class="hint-error" v-if="errors.length">
            {{ errors[0] }}
          </div>
          <p class="hint" v-if="hint">
            {{ hint }}
          </p>

          <div class="show-password" v-if="type === 'password' && showPassword">
            <a
              @mousedown="() => showPasswordFn(true)"
              href="javascript:void(0)"
            ><img src="@/assets/images/pass-icon.png" alt=""></a>
          </div>
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';
  import {extend} from 'vee-validate';

  export default {
    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    name: 'Input',

    props: {
      autocomplete: String,
      disabled: {type: Boolean, default: () => false},
      label: String,
      model: String,
      value: String,
      error: String,
      hint: String,
      elementClass: String,
      elementClassParent: String,
      type: {type: String, default: () => 'text'},
      placeholder: String,
      storePath: String,
      rules: {type: String, default: () => ''},
      timeout: {type: Number, default: () => 500},
      showPassword: {type: Boolean, default: () => false},
      errorMessage: String,
      isSugg: Boolean,
      elementId: String,
      labelClass: String,
      keyupEnterCallback: Function,
    },

    data() {
      return {
        interval: null,
        valuePreSet: null,
        enterCallback: null,
      };
    },

    watch: {
      valuePreSet(valueNext, valueOld) {
        if (valueNext !== valueOld) {
          this.$store.commit('form/updateField', {model: this.storePath, value: valueNext});
        }
      },

      forms: {
        handler(val) {
          if (_.get(val, this.storePath) && _.get(val, this.storePath) !== this.$refs.input.value) {
            this.valuePreSet = _.get(val, this.storePath);
            this.$refs.input.value = this.valuePreSet;
            this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
          }

          if (!_.get(val, this.storePath)) {
            this.valuePreSet = null;
          }
        },

        deep: true,
      },
    },

    mounted() {
      if (this.storePath) {
        this.$store.commit('form/initFormField', {model: this.storePath, value: this.value || null});
      }

      if (this.valuePreSet !== this.value && this.value) {
        this.valuePreSet = this.value;
      }

      const
        forms = this.forms;
      const errorMessage = this.errorMessage;

      extend('identity', {
        validate(value, param) {
          return value === _.get(forms, param[0]);
        },

        message: errorMessage,
      });

      this.enterCallback = this.keyupEnterCallback ?this.keyupEnterCallback : () => {};
    },

    methods: {
      showPasswordFn(flag) {
        this.showPassword = flag;
      },

      change(el) {
        this.$store.commit('form/updateField', {model: this.storePath, value: el.target.value});
      },

      set(el) {
        if (this.rules.indexOf('numeric') !== -1) {
          this.valuePreSet = this.valuePreSet.replace(/[^\d]/g, '');
        }

        clearTimeout(this.interval);
        this.interval = setTimeout(() => this.change(el), this.timeout);
      },
    },
  };
</script>

<style lang="scss">
  .input-container-provider {
    width: 100%;

    .input-container {
      position: relative;

      .show-password {
        position: absolute;
        right: 12px;
        top: 14px;

        a {
          text-decoration: none;
        }
      }
    }
  }
</style>
