<template>
  <div>
    <div class='event-list-item-title-block'>
      <div class='event-list-item-event-name'>
        <router-link :to='`/event/${data.id}`'>
          {{ data.name }}
        </router-link>
      </div>
      <div>
        <button
          class='event-list-item-button'
          disabled
        >{{ data.type.name }}
        </button>
      </div>
    </div>
    <div class='event-list-item-body'>
      <div class='event-card-table'>
        <div class='event-card-table-row'>
          <p class='event-card-table-row-title'>{{ $t('organizers') }}</p>
          <p class='event-card-table-row-value truncate-line'>{{ data.organizers }}</p>
        </div>
        <div class='event-card-table-row'>
          <p class='event-card-table-row-title'>{{ $t('dateOfEvent') }}</p>
          <p class='event-card-table-row-value'>{{ date }}</p>
        </div>
        <div class='event-card-table-row'>
          <p class='event-card-table-row-title'>{{ $t('timeOfEvent') }}</p>
          <p class='event-card-table-row-value'>{{ data.format.code === 'ONLINE' ? `${time} (GMT ${timeZone})` : time }}</p>
        </div>
      </div>
      <div class='event-card-table'>
        <div class='event-card-table-row'>
          <p class='event-card-table-row-title'>{{ $t('formatOfEvent') }}</p>
          <p class='event-card-table-row-value'>{{ data.format.name }}</p>
        </div>
        <div class='event-card-table-row'>
          <p class='event-card-table-row-title'>
            {{ data.format.code === 'ONLINE' && data.url ? $t('linkOnEvent') : $t('location') }}
          </p>
          <a 
            class="link event-card-table-row-value truncate-lines2" 
            :href="$options.filters.validatorWebSite(data.url)"
            v-if="data.format.code === 'ONLINE' && data.url"  
          >{{ data.url }}</a>
          <p class="truncate-lines2 event-card-table-row-value" v-else>{{ data.location }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import {getTimeZone} from '../../../../../../utils/getTimeZone.ts'

export default {
  name: 'event-list-item',
  data: () => ({
    title: '',
    location: '',
    time: '',
    date: '',
    timeZone: '',
  }),

  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
  },

  methods: {
    setTime() {
      this.time = moment(`${this.data.start_date} ${this.data.start_time}`)
          .format(' _f_ HH:mm').replace('_f_', this.$t('_from')).replace('_p_', this.$t('_y')) +
        moment(`${this.data.start_date} ${this.data.end_time}`).format(` ${this.$t('_to')} HH:mm`)

      if (this.data.format.code === 'ONLINE') {
        this.timeZone = getTimeZone()
      }
    },

    setDate() {
      this.date = moment(this.data.start_date).format('DD MMMM') ===
      moment(this.data.end_date).format('DD MMMM')
        ? moment(this.data.end_date).format('DD MMMM')
        : moment(this.data.start_date).format('DD MMMM') ===
        moment(this.data.end_date).format('DD MMMM')
          ? moment(this.data.start_date).format('DD MMMM')
          : `${moment(this.data.start_date).format('DD MMMM')} — ${moment(
            this.data.end_date,
          ).format('DD MMMM')}`
    },

    setTitleLength(title) {
      if (title.length > 45) {
        return title.slice(0, 45) + '...'
      } else {
        return title
      }
    },
  },

  mounted() {
    this.setTime()
    this.setDate()
  },

  props: {
    data: Object,
    isEventInsert: Boolean,
  },

  watch: {},
}
</script>
