<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div>
    <div class="container">
      <div class="profile-container">
        <div class="profile-aside">
          <ProfileMenuLeft/>
          <ProfileRepresentation/>
        </div>

        <div class="profile-content">
          <div class="profile-title">
            <h1 class="title-1">{{ $t('theChoice') }}</h1>
          </div>

          <div class="def-container mb32">
            <div class="container">
              <span class="light">{{ $t('selectedProgram') }}</span>

              <h4 class="title-4" v-if="!program.id && isLoad">
                {{ $t('noProgramSelected') }}
              </h4>

              <div class="favorites" v-if="program.id">
                <div class="favorites__item">
                  <h2 class="title-2">
                    <span>
                      <router-link to="/education-in-russia/programs">
                        {{ program | lang('name_without_code') }}
                      </router-link>
                    </span>

                    <Button
                        element-class="btn-remove"
                        :callback="() => removeChoice('program')"
                    />
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div class="def-container mb32">
            <div class="container">
              <span class="light">{{ $t('selectedEducationalOrganizations') }}</span>

              <h4 class="title-4" v-if="!universities.length && isLoad">
                {{ $t('educationalOrganizationNotSelected') }}
              </h4>

              <div class="favorites">
                <div
                    v-for="(university, key) in universities"
                    v-bind:key="key"
                    class="favorites__item"
                >
                  <h2 class="title-2">
                    <span>
                      <router-link :to="`/education-in-russia/universities/${university.slug}`">
                        {{ university | lang('name') }}
                      </router-link>
                    </span>

                    <Button
                        element-class="btn-remove"
                        :callback="() => removeChoice('university', university)"
                    />
                  </h2>

                  <table class="table var2">
                    <tbody>
                    <tr>
                      <td>
                        <router-link :to="`/life-in-russia/cities/${university.address.city.slug}`">
                          <span class="link">
                            <img alt="" src="@/assets/images/location-icon.png" />
                            {{ university.address.city | lang }}
                          </span>
                        </router-link>
                      </td>

                      <td>
                        <a class="link"
                           :href="(!!university.website.startsWith('http://') || !!university.website.startsWith('https://'))
                           ? university.website
                           : '//' + university.website"
                           target="_blank">
                          <span>
                            <img alt="" src="@/assets/images/site-icon.png">
                            {{ university.website }}
                          </span>
                        </a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <hr/>
                </div>
              </div>
            </div>
          </div>
          <a href="/settings/profile/applications">
            <Button
                element-class="btn"
                :text="$t('goToTheApplicationForm')"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ProfileMenuLeft from '@/components/site/pages/account/generic/ProfileMenuLeft';
import ProfileRepresentation from '@/components/site/pages/account/generic/ProfileRepresentation';
import {mapActions} from 'vuex';
import Button from '@/components/generic/form/Button';

export default {
  name: 'account-choices',

  components: {
    Button,
    ProfileMenuLeft,
    ProfileRepresentation,
  },

  data() {
    return {
      choices: [],
      program: {},
      universities: [],
      isLoad: false,
    }
  },

  methods: {
    ...mapActions({
      getMyChoice: 'users/getMyChoice',
      unsetMyChoiceDelete: 'users/unsetMyChoiceDelete',
    }),

    getChoice() {
      this.$store.commit('preloader/isLoading', true)
      this.getMyChoice().then(res => {
        this.isLoad = true;
        this.choices = res.data.length ? res.data[0] : {};
        this.program = (this.choices || {}).program || {};
        this.universities = [];

        this.$store.commit('form/updateField', {
          model: 'filter.programs.main.educationalProgram',
          value: this.program.id,
        });
        this.$store.commit('form/updateField', {
          model: 'filter.programs.additional.educationalProgram',
          value: this.program.id,
        });

        _.map(this.choices.universities, university => {
          if (!_.find(this.universities, {id: university.university.id})) {
            this.universities.push(university.university);
          }
        });
      }).finally(() => this.$store.commit('preloader/isLoading', false))
    },

    removeChoice(type, params = {}) {
      this.$store.commit('preloader/isLoading', true)
      this.unsetMyChoiceDelete({
        program_id: type === 'program' ? this.program.id : null,
        university_id: type === 'university' ? params.id : null,
      }).then(() => {
        this.getChoice();
      }).finally(() => this.$store.commit('preloader/isLoading', false))
    },
  },

  mounted() {
    this.getChoice();
  },
}
</script>
