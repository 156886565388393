<template>
  <Form :form-callback="onSubmit" ref="feedbackForm">
    <div slot="form" class="feedback">
      <h1 class="title-1">{{ title }}</h1>

      <Input
          type="text"
          element-class="txt"
          :placeholder=" $t('enterYourName')"
          store-path="feedback.name"
          :label="`${$t('name')} <span class='red'>*</span>`"
          rules="required|onlyText"
      />

      <Input
          type="email"
          element-class="txt"
          :placeholder="$t('enterYourEmail')"
          store-path="feedback.email"
          :label="`${$t('eMail')} <span class='red'>*</span>`"
          rules="required|email|latin"
      />

      <MultiSelect
          :options="feedbackThemes"
          :placeholder="$t('messageSubject')"
          store-path="feedback.themeMessage"
          :label="`${$t('messageSubject')} <span class='red'>*</span>`"
          rules="required"
      />
      <Textarea
          element-class="txt-area"
          :placeholder="$t('chooseAMessage')"
          store-path="feedback.message"
          :label="`${$t('message')} <span class='red'>*</span>`"
          rules="required"
      />
      <Captcha :answer="getCaptcha" v-show="showCaptcha"/>
      <div class="feedback-form-row">
        <InputFile
            store-path="feedback.file"
            :multiple="false"
        />

        <Button
            type="submit"
            class="btn"
            :text="$t('submit')"
        />
      </div>
    </div>
  </Form>
</template>

<script>
import _ from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import {helpers} from '@/_services';
import Form from '@/components/generic/form/Form';
import Button from '@/components/generic/form/Button';
import Textarea from '@/components/generic/form/field/Textarea';
import MultiSelect from '@/components/generic/form/field/MultiSelect';
import Input from '@/components/generic/form/field/Input';
import InputFile from '@/components/generic/form/field/InputFile';
import Captcha from '@/components/generic/Captcha';

export default {
  name: 'feedback-form',

  components: {
    Button,
    Form,
    Textarea,
    MultiSelect,
    Input,
    InputFile,
    Captcha,
  },

  computed: {
    ...mapGetters({
      forms: 'form/forms',
    }),
  },

  methods: {
    ...mapActions({
      getFeedbackThemes: 'help/getFeedbackThemes',
      setFeedback: 'help/setFeedback',
    }),

    getCaptcha() {
      this.captchaStatus = true
      this.showCaptcha = false
    },

    async onSubmit(reset) {
      if (!this.captchaStatus) {
        this.showCaptcha = true
        return
      }

      let formData = new FormData();

      formData.append('name', this.forms.feedback.name);
      formData.append('email', this.forms.feedback.email);
      formData.append('feedback_theme', this.forms.feedback.themeMessage);
      formData.append('message', this.forms.feedback.message);
      formData.append('file', _.get(this.forms, 'feedback.file.0', null));

      helpers.convertFormToJson(formData).then(res => {
        this.setFeedback(res).then(() => {
          this.$store.commit('form/updateField', {model: 'feedback', value: {}});
          this.$store.commit('alert/success', {message: 'Сообщение успешно отправлено'});

          if (reset) {
            reset();
          }
        });
      })
      this.captchaStatus = false;
    },
  },

  props: {
    title: String,
  },

  data() {
    return {
      value: null,
      feedbackThemes: [],
      captchaStatus: false,
      showCaptcha: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getFeedbackThemes().then(res => {
      this.feedbackThemes = helpers.options(res.data);
    });
  },
};
</script>
