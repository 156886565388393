<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <!--  eslint linebreak-style: ["error", "windows"]-->
  <div>
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('help'), url: '/help'},
              { title: $t('forum'), url: '/help/forum' },
              { title: $t('myTopicsAndPosts') },
            ]"
        />

        <h1 class="title-1 mb32">{{ $t('forum') }}</h1>
        <h2 class="title-2 mb32">{{ $t('myMessages') }}</h2>

        <form action="" class="search-row mb32">
          <Input
            element-class="txt"
            :placeholder="$t('searchInMyPosts')"
            store-path="filter.forum.myMessages.search"
            type="text"
            :keyup-enter-callback="getData"
          />

          <Button
            type="button"
            element-class="btn btn-search"
            :callback="getData"
          ></Button>
        </form>

        <div class="forum__message">
          <div
            class="forum__message-item"
            v-for="(post, key) in postsLost.data"
            v-bind:key="key"
          >
            <figure class="forum__message-item-user">
              <h5 class="title-5">{{ post.user.username }}</h5>

              <figcaption class="forum__message-item-user-info">
                <div class="forum__message-item-user-avatar">
                  <img v-if="post.user.avatar" :src="post.user.avatar" :alt="post.user.username" />
                  <img v-if="!post.user.avatar" src="@/assets/images/icon/account_circle-24px.svg" :alt="post.user.username" />
                </div>

                <div class="country">
                  <span class="country-flag">
                    <img src="@/assets/images/flags/img-flag-in.png" alt="" />
                  </span>

                  Индия
                </div>
                <span class="light">{{ post.user.post_count }} {{ $t('_posts') }}</span>
              </figcaption>
            </figure>
            <div class="forum__message-item-content">
              <h5 class="title-5 light">{{ $t('feedbackOnEducationalOrganizations') }}</h5>
              <h5 class="title-5 mb32">Самарский национальный исследовательский университет имени академика С.П. Королёва</h5>
              <div class="title-sub light">{{ $t('posted') }} {{ post.time_ago }}</div>

              <p v-html="post.body" />

              <div class="forum__message-item-content-navigation">
                <a
                  href="javascript:void(0)"
                  @click="() => goToMessage(post)"
                >{{ $t('goToMessage') }}</a>
              </div>
            </div>
          </div>
        </div>

        <Pagination
          :count="postsLost.last_page"
          :callback-on-change="getData"
          pagination-model="pagination.programs.additional"
          :last-data-count="postsLost.data.length"
          :show-list-showing="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Vue from 'vue';
  import Pagination from '@/components/generic/pagination/Pagination';
  import Input from '@/components/generic/form/field/Input';
  import Button from '@/components/generic/form/Button';

  export default {
    name: 'help-forum-my-message',

    components: {
      Breadcrumbs,
      Pagination,
      Input,
      Button,
    },

    computed: {
      ...mapGetters({
        forms: 'form/forms',
        user: 'users/getUser',
      }),
    },

    data: () => ({
      lang: Vue.filter('lang'),
      postsLost: {
        last_page: 0,
        data: [],
      },
    }),

    methods: {
      ...mapActions({
        getForumPost: 'help/getForumPost',
        getForumGoToMessage: 'help/getForumGoToMessage',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        const token = localStorage.getItem('userToken')
        this.getForumPost({
          user: this.user.id,
          q: _.get(this.forms, 'filter.forum.myMessages.search', null),
        }).then(res => {
          if (res.status === 401 && !token) {
            this.$router.push('/account/login').catch(() => {});
            return ;
          }

          if (res.data !== 401 && token) {
            this.postsLost = res;
          } else {
            this.$router.push('/help/forum').catch(() => {});
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      goToMessage(message) {
        this.getForumGoToMessage({
          postId: message.id,
          discussionId: message.discussion_id,
        }).then(res => {
          if (res.data.page && res.data.slug) {
            this.$router.push(`/help/forum/themes/messages/${res.data.slug}?page=${res.data.page}`);
          }
        })
      },
    },

    mounted() {
      this.getData();
    },
  };
</script>
