<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">

    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
            { title: $t('mainPage'), url: '/' },
            { title: $t('educationInRussia') },
            { title: $t('educationalPrograms') },
          ]"
        />

        <h1 class="title-1 mb32">{{ $t('educationalPrograms') }}</h1>
        <ProgramsSearch />
      </div>
    </div>
  </div>
</template>

<script>
import ProgramsSearch from '@/components/site/pages/educationInRussia/programs/generic/ProgramsSearch'
import Breadcrumbs from '@/components/generic/Breadcrumbs'

export default {
  name: 'Programs',
  components: {
    Breadcrumbs,
    ProgramsSearch,
  },

  computed: {},

    data() { },
    
    methods: {},
  };

</script>
