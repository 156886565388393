<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div>
    <div class="container">
      <div class="profile-container">
        <div class="profile-aside">
          <ProfileMenuLeft />
          <ProfileRepresentation />
        </div>

        <div class="profile-content">
          <div class="profile-title">
            <h1 class="title-1">{{ $t('favorites') }}</h1>
          </div>

          <div class="nav-tabs-wrap">
            <ul class="nav-tabs mb20">
              <li class="nav-item" @click="activeTab = 'programs'">
                <router-link
                        :class="{ active: activeTab === 'programs' }"
                        class="nav-link"
                        :to="{ query: { tab: 'programs' }}"
                >
                  {{ $t('programs') }}
                </router-link>
              </li>

              <li class="nav-item" @click="activeTab = 'universities'">
                <router-link
                        :class="{ active: activeTab === 'universities' }"
                        class="nav-link"
                        :to="{ query: { tab: 'universities' }}"
                >
                  {{ $t('educationalOrganizations') }}
                </router-link>
              </li>
            </ul>
          </div>

          <div class="tab-content" id="myTabContent">
            <div :class="{ 'active show': activeTab === 'programs' }" class="tab-pane" id="programs">
              <ProgramsSearchItem
                v-for="(program, key) in programs"
                v-bind:key="key"
                :data="program"
                :is-header-program="false"
                css-variant="variant-3"
                class="def-container mb32"
              />
            </div>

            <div :class="{ 'active show': activeTab === 'universities' }" class="tab-pane" id="universities">
              <UniversitySearchItem
                v-for="(university, key) in universities"
                v-bind:key="key"
                :data="university"
                :is-header-university="false"
                css-variant="variant-3"
                class="def-container mb32 favorite-card organization"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {mapActions} from 'vuex';
  import ProfileMenuLeft from '@/components/site/pages/account/generic/ProfileMenuLeft';
  import ProfileRepresentation from '@/components/site/pages/account/generic/ProfileRepresentation';
  import ProgramsSearchItem from '@/components/site/pages/educationInRussia/programs/generic/ProgramsSearchItem';
  import UniversitySearchItem from '@/components/site/pages/educationInRussia/universities/generic/UniversitySearchItem';

  export default {
    name: 'account-favorites-choices',

    methods: {
      ...mapActions({
        getFavoritesPrograms: 'users/getFavoritesPrograms',
        getFavoritesUniversities: 'users/getFavoritesUniversities',
      }),
    },

    data() {
      return {
        activeTab: 'programs',
        favorites: [],
        programs: [],
        universities: [],
      }
    },

    components: {
      ProfileMenuLeft,
      ProfileRepresentation,
      ProgramsSearchItem,
      UniversitySearchItem,
    },

    mounted() {
      this.getFavoritesPrograms().then(res => {
        this.programs = res.data.map(el => (el.program));
      });
      this.getFavoritesUniversities().then(res => {
        this.universities = res.data.map(el => (el.university));
      });
    },
  }
</script>
