<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <ul class="breadcrumbs">
          <Breadcrumbs :items="[{title: $t('mainPage'), url: '/' }, {title: $t('choiceOfProfession') }]" />
        </ul>

        <h1 class="title-1 mb32">{{ $t('choiceOfProfession') }}</h1>

        <div class="card__vertical">
          <figure class="card">
            <div class="card-bg">
              <img alt="" src="@/assets/images/choice-prof-bg.png">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('careerGuidance') }}</h3>
              <p>{{ $t('determineYourAreaOfEducationAndProfession') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/choice-of-profession/career-tests">{{ $t('startTesting') }}</router-link>
            </div>
          </figure>

          <figure class="card">
            <div class="card-bg">
              <img alt="" src="@/assets/images/choice-prof-bg2.png">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('myCareer') }}</h3>
              <p>{{ $t('defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/choice-of-profession/my-career">{{ $t('buildCareerVector') }}</router-link>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
    @media (max-width: 980px) {
        .card-btn{
            align-self: auto;
            width: 100%;
        }
        .card-btn a{
            width: 100%;
        }
        .card{
            padding: 20px 20px 40px;
        }
        .card-info{
            min-height: 300px;
        }
    }
</style>
<script>
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'choice-of-profession',

    components: {
      Breadcrumbs,
    },
  computed: {},
  created() {
    this.$store.commit('preloader/isLoading', true)
    setTimeout(() => this.$store.commit('preloader/isLoading', false), 1500)
  },
  };
</script>
