<template>
  <div class="col-6">
    <div class="title-4">{{ $t('russianLanguageCoursesInYourCountry') }}</div>
    <div class="title-5">{{ $t('textRussianLanguageCourses')}}</div>

    <div class="courses-table">
      <form class="program-content">
        <MultiSelect
          :options="countries"
          :placeholder="$t('selectACountry')"
          store-path="russian.country"
          :label="$t('country')"
          allow-empty
        />
      </form>
      <div class="table-wrap">
        <table class="table">
          <thead>
          <tr>
            <th>{{ $t('courseName') }}</th>
            <th>{{ $t('trainingLevel') }}</th>
            <th>{{ $t('courseDuration') }}</th>
            <th>{{ $t('classDuration') }}</th>
            <th>{{ $t('courseCost') }}</th>
            <th>{{ $t('courseReviews') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(course, key) in courses.data"
            :key="key"
          >
            <td><a class="link" target="_blank" :href="course.link_to_course || '##'">{{ course | lang('name') }}</a></td>
            <td>{{ course.required_lvl_rus_lang | lang('name') }}</td>
            <td>{{ course | lang('duration_course') }}</td>
            <td>{{ course | lang('duration_lesson') }}</td>
            <td>{{ course | lang('course_cost') }}</td>
            <td><a class="link" target="_blank" :href="course.link_to_reviews || '##'">{{ $t('feedbackLink') }}</a></td>
          </tr>
          <Pagination
          :count="courses.last_page"
          :callback-on-change="russianLanguageCourses"
          :last-data-count="courses.total"
          pagination-model="pagination.courses"
          :show-list-showing="false"
        />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import {helpers} from '@/_services';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';
  import Pagination from '@/components/generic/pagination/Pagination';

  export default {
    name: 'russian-language-courses',

    components: {
      MultiSelect,
      Pagination,
    },

    computed: {
      ...mapGetters({
        forms: 'form/forms',
        pagination: 'getPagination',
      }),
    },

    data: () => ({
      courses: [],
      showAll: false,
      countries: [],
      countriesData: [],
    }),

    methods: {
      ...mapActions({
        getCountries: 'educationInRussia/getOnlineMonitoringCountries',
        getRussianLanguageCourses: 'getRussianLanguageCourses',
      }),

      russianLanguageCourses() {
        this.getRussianLanguageCourses({
          countryId: this.forms.russian.country,
          rusLangCourses: 1,
          type: this.type,
          perPage: (this.pagination.courses|| {}).length || 15,
          page: (this.pagination.courses|| {}).page || null,
        }).then(res => {
          this.courses = res.data;
        })
      },
    },

  mounted() {
      this.$store.commit('preloader/isLoading', true)
      this.getCountries().then(res => {
        this.countriesData = res.data;
        this.countries = helpers.options(res.data, 'name', 'id');
      }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    props: {
      type: Number,
    },

    watch: {
      'forms.russian.country'() {
        this.russianLanguageCourses();
      },
    },
  }
</script>
