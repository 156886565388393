<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="university__page-container">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url(${require('@/assets/images/bg-russian-language-teacher.jpg')});`"></div>
        <div class="container">
          <Breadcrumbs
            :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('educationInRussia') },
              {title: $t('russianLanguage'), url: '/education-in-russia/russian'},
              {title: $t('tutorsTheRussianLanguage') }
            ]"
          />

          <h1 class="title-1">
            {{ $t('tutorsTheRussianLanguage') }}
          </h1>
        </div>
      </div>

      <div class="container mb64">

        <div class="container def-container program-container">
          <div class="program-container-popup" v-show="isShowFilter || !isResponsiveSm">
            <div class="program-container-popup-header">
              <h3 class="title-3">{{ $t('filters') }}</h3>
              <div
                  class="program-container-popup-header-close"
                  @click="isShowFilter = !isShowFilter"
              >
                <img src="@/assets/images/close-black.png" alt="" />
              </div>
            </div>
            <div class="tab-content">
              <form class="program-content">
                <!--              <div class="form-row">-->
                <!--                <label>Стоимость за 1 час занятия</label>-->
                <!--                <multiselect :options="options" placeholder="Выберите стоимость" v-model="value"></multiselect>-->
                <!--              </div>-->

                <MultiSelect
                        :options="tutorsExperiences"
                        :placeholder="$t('chooseATeachingExperience')"
                        store-path="filter.tutors.experience"
                        :label="$t('teachingExperience') "
                        value=""
                />

                <MultiSelect
                        :options="tutorsLanguages"
                        :placeholder="$t('chooseLanguages') "
                        store-path="filter.tutors.language"
                        :label="$t('knowledgeOfLanguages') "
                        is-multiple
                        allow-empty
                        value=""
                />
              </form>

              <div class="program-content-btn">
                <a
                        class="btn"
                        href="javascript:void(0)"
                        @click="() => getData('additional')"
                >{{ $t('show') }} {{ totalTutors | declOfNum([this.$t('_tutor'), this.$t('_tutors'), this.$t('__tutors')]) }}</a>

                <a
                        class="btn btn-outline"
                        href="javascript:void(0)"
                        @click="resetFilter"
                >{{ $t('clearAllFilters') }}</a>
              </div>
            </div>
          </div>
          <a
                  href="#"
                  class="btn btn-outline d-none sm-show"
                  @click="isShowFilter = !isShowFilter"
          >Фильтры</a>
        </div>

        <!--   search title    -->
        <h3 class="title-3 light mb16">
          {{ getTutorsMainList.total | declOfNum([this.$t('_tutor'), this.$t('_tutors'), this.$t('__tutors')]) }}
        </h3>

        <div
          class="teacher__card"
          v-for="(tutors, key) in getTutorsMainList.data"
          v-bind:key="key"
        >
          <TutorsItem :tutors="tutors" :update-data="getData" />
        </div>

        <Pagination
          :count="getTutorsMainList.last_page"
          :callback-on-change="getData"
          pagination-model="pagination.tutors"
          :last-data-count="getTutorsMainList.data.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {helpers} from '@/_services';
  import {mapGetters, mapActions} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Pagination from '@/components/generic/pagination/Pagination.vue';
  import fancyBox from 'vue-fancybox';
  import TutorsItem from '@/components/site/pages/educationInRussia/russian/tutors/generic/TutorsItem';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';

  export default {
    name: 'Teacher',
    components: {
      Breadcrumbs,
      Pagination,
      TutorsItem,
      MultiSelect,
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        getTutorsMainList: 'educationInRussia/getTutorsMainList',
        programsAdditionalList: 'educationInRussia/getProgramsAdditionalList',
        forms: 'form/forms',
      }),
    },

    data() {
      return {
        isShowFilter: '',
        value: null,
        options: ['item1', 'item2', 'item3', 'item4', 'item5'],
        imageList: [
          {width: 900, height: 675, url: 'http://ocm0knkb1.bkt.clouddn.com/1-1.jpg'},
          {width: 601, height: 1024, url: 'http://ocm0knkb1.bkt.clouddn.com/1-2.jpg'},
          {width: 1024, height: 700, url: 'http://ocm0knkb1.bkt.clouddn.com/1-3.jpg'},
        ],

        totalTutors: 0,
        tutorsExperiences: [],
        tutorsLanguages: [],
      };
    },

    methods: {
      ...mapActions({
        getTutors: 'educationInRussia/getTutors',
        getTutorsExperiences: 'educationInRussia/getTutorsExperiences',
        getTutorsLanguages: 'educationInRussia/getTutorsLanguages',
        setTutorsLanguages: 'educationInRussia/setTutorsLanguages',
      }),

      open(e) {
        fancyBox(e.target, this.imageList);
      },

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getTutors({
          perPage: _.get(this.pagination, 'tutors.length', 10),
          page: _.get(this.pagination, 'tutors.page', null),
          experience: this.forms.filter.tutors.experience,
          language: this.forms.filter.tutors.language,
        }).then(res => {
          if (res.status === 'success') {
            this.totalTutors = res.data.total;
            this.$store.commit('educationInRussia/updateParam', {model: 'tutors', value: res.data});
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false))
      },

      resetFilter() {
        this.$store.commit('form/updateField', {model: 'filter.tutors', value: {}});
        this.$store.commit('updateParam', {model: 'pagination.tutors.page', value: 1});
        this.$store.commit('updateParam', {model: 'pagination.tutors.length', value: 10});
      },
    },

    mounted() {
      this.getData();

      this.getTutorsExperiences().then(res => {
        this.tutorsExperiences = _.map(res.data, experience => ({value: experience, name: experience}));
      })

      this.getTutorsLanguages().then(res => {
        this.tutorsLanguages = helpers.options(res.data);
      })
    },

    watch: {
      forms() {
        this.getTutors({
          experience: this.forms.filter.tutors.experience,
          language: this.forms.filter.tutors.language,
          count: 1,
        }).then(res => {
          this.totalTutors = res.data.count;
        });
      },
    },
  };
</script>
