<template>
  <div :class="{row: !hideGrid}">
    <div
      :class="{'col-2': !hideGrid}"
      v-for="(citysights, key) in data"
      v-bind:key="key"
    >
      <a :href="`${citysights.link}`">
        <figure class="info__card news">
          <div class="info__card-img" style="height: 180px">
            <img :src="citysights.image" alt="" />
          </div>
          <figcaption class="info__card-content">
            <h2 class="sight-title">
              {{citysights.title}}
            </h2>
            <h5 class="sight-desc">
              {{ (citysights.description) }}
            </h5>
          </figcaption>
        </figure>
      </a>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'items-sights',
    data: () => ({
      moment: moment,
    }),

    props: {
      data: Array,
      hideGrid: Boolean,
    },
  }
</script>
