<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[{title: $t('mainPage'), url: '/' }, {title: $t('whyRussia') }, {title: $t('reviewsOfForeignStudents') }]"
        />

        <h1 class="title-1 mb32">{{ $t('reviewsOfForeignStudents') }}</h1>

        <div class="container def-container program-container mb64">
          <div class="program-container-popup" v-show="isShowFilter || !isResponsiveSm">
            <div class="program-container-popup-header">
              <h3 class="title-3">{{ $t('filters') }}</h3>
              <div
                class="program-container-popup-header-close"
                @click="isShowFilter = !isShowFilter"
              >
                <img src="@/assets/images/close-black.png" alt="" />
              </div>
            </div>
            <form class="program-content">
              <MultiSelect
                :options="universities"
                :placeholder="$t('chooseUniversity')"
                store-path="filter.reviews.university"
                :label="$t('university')"
                allow-empty
              />

              <MultiSelect
                :options="countries"
                :placeholder="$t('selectACountry')"
                store-path="filter.reviews.country"
                :label="$t('country')"
                allow-empty
              />
            </form>

            <div class="program-content-btn">
              <a
                class="btn"
                href="javascript:void(0)"
                @click="getData"
              >{{ $t('show') }} {{ totalReviews | declOfNum([$t('_review'), $t('_reviews'), $t('__reviews')]) }}</a>

              <a
                class="btn btn-outline"
                href="javascript:void(0)"
                @click="resetFilter"
              >{{ $t('clearAllFilters') }}</a>
            </div>
          </div>

          <a
            href="#"
            class="btn btn-outline d-none sm-show"
            @click="isShowFilter = !isShowFilter"
          >{{ $t('filters') }}</a>
        </div>

        <h3 class="title-3 light mb16">{{ reviewsList.total | declOfNum([$t('_review'), $t('_reviews'), $t('__reviews')]) }}</h3>
        <ItemsReviews :data="reviewsList.data" />

        <Pagination
          :count="reviewsList.last_page"
          :callback-on-change="getData"
          :last-data-count="reviewsList.data.length"
          pagination-model="pagination.reviews"
          :show-list-showing="false"
        />
      </div>
    </div>
  </div>
</template>


<script>
  import {helpers} from '@/_services';
  import {mapActions, mapGetters} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Pagination from '@/components/generic/pagination/Pagination';
  import ItemsReviews from '@/components/site/pages/whyRussia/generic/ItemsReviews';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';

  export default {
    name: 'why-russia-reviews',

    components: {
      Breadcrumbs,
      ItemsReviews,
      Pagination,
      MultiSelect,
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        reviewsList: 'whyRussia/getReviewsList',
        locale: 'getLocale',
        forms: 'form/forms',
       
      }),
    },

    data() {
      return {
        value: null,
        totalReviews: 0,
        countries: [],
        universities: [],
        isShowFilter: '',
      };
    },

    methods: {
      ...mapActions({
        getReviews: 'whyRussia/getReviews',
        getReviewsCountByFilter: 'whyRussia/getReviewsCountByFilter',
        getCountries: 'getCountries',
        getAllUniversities: 'getAllUniversities',
      }),

      resetFilter() {
        this.$store.commit('form/updateField', {model: 'filter.reviews', value: {}});
        this.$store.commit('updateParam', {model: 'pagination.reviews.page', value: 1});
        this.$store.commit('updateParam', {model: 'pagination.reviews.length', value: 10});
      },

      getData() {
        this.$store.commit('preloader/isLoading', true)

        this.getReviews({
          countryId: this.forms.filter.reviews.country,
          universityId: this.forms.filter.reviews.university,
          perPage: (this.pagination.reviews|| {}).length || 10,
          page: (this.pagination.reviews|| {}).page || null,
        }).then(res => {
          this.$store.commit('whyRussia/updateParam', {model: 'reviews', value: res.data});
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      this.getData();

      this.getCountries()
        .then(res => {
          this.countries = helpers.options(res);
        });

      this.getAllUniversities()
        .then(res => {
          this.universities = helpers.options(res.data);
        });
    },

    watch: {
      forms() {
        this.getReviewsCountByFilter({
          countryId: this.forms.filter.reviews.country,
          universityId: this.forms.filter.reviews.university,
        }).then(res => {
          this.totalReviews = res.data;
        });
      },
    },
  };
</script>
