<template >
  <div :key="data.id" class="card__universities" :class="{ 'mb32 variant-2': isHeaderUniversity }">
    <div class="card__universities-content">
      <figure class="favorite-card-top">
        <div class="favorite-card-logo">
          <a href="#">
            <img alt="" :src="data.logo" />
          </a>
        </div>
        <figcaption class="favorite-card-btn">
          <a
            class="favorite"
            :class="{ active: data.favorite }"
            href="javascript:void(0)"
            @click="() => addFavorite(data)"
          >{{ data.favorite ? $t('inFavorites') : $t('toFavorites') }}</a>

          <a
            class="btn choose"
            :class="{ active: data.favorite }"
            href="javascript:void(0)"
            @click="() => addChoices(data)"
          >{{ data.choice ? $t('fromChoices') : $t('choices') }}</a>
        </figcaption>
      </figure>

      <h3 class="title-3" v-if="!isHeaderUniversity">
        <router-link :to="`/education-in-russia/universities/${data.slug}`">
          {{ data | lang }}
        </router-link>
      </h3>

      <ul class="card__universities-links md-hide">
        <li v-if="data.address">
          <router-link
          v-if="data.address.city && data.address.city.slug"
            class="link"
            :to="`/life-in-russia/cities/${data.address.city.slug}`"
          >
            <span>
              <img alt="" src="@/assets/images/location-icon.png" />
              {{ data.address.city | lang }}{{ countryUnversity && `, ${countryUnversity.name}`}}
            </span>
          </router-link>
        </li>
        <li>
          <a class="link" :href="prepareUrl(data.website)" target="_blank">
            <span>
              <img alt="" src="@/assets/images/site-icon.png">
              {{ data.website }}
            </span>
          </a>
        </li>
      </ul>

      <figure class="card__universities-info">
        <div class="card__universities-info-img" v-if="!isHeaderUniversity">
          <img alt="" :src="data.cover ? `${data.cover}` : defaultImg">
          <router-link
            class="link"
            :to="`/education-in-russia/universities/${data.slug}?tab=educationProgram`"
          >
            {{ data.programs_count | number }} {{ $t('_educationalPrograms') }}
          </router-link>
          <ul class="card__universities-links">
            <li v-if="data.address">
              <router-link
              v-if="data.address.city && data.address.city.slug"
                class="link"
                :to="`/life-in-russia/cities/${data.address.city.slug}`"
              >
                <span>
                  <img alt="" src="@/assets/images/location-icon.png" />
                  {{ data.address.city | lang }}
                </span>
              </router-link>
            </li>
            <li>
              <a class="link" :href="data.website" target="_blank">
                <span>
                  <img alt="" src="@/assets/images/site-icon.png">
                  {{ data.website }}
                </span>
              </a>
            </li>
          </ul>
        </div>

        <figcaption class="card__universities-info-content">
          <ul class="card__universities-info-list">
            <li>
              <span class="light">
                {{ $t('established') }}
              </span>
              <span class="dark">
                {{ convertDateFoundation }}
              </span>
            </li>
            <li>
              <span class="light">
                {{ $t('numberOfStudents') }}
              </span>
              <span class="dark">
                {{ data.number_of_students | handlerEmpty }} 
              </span>
            </li>
            <li>
              <span class="light">
                {{ $t('numberOfBudgetPlaces') }}
              </span>
              <span class="dark">
                {{ data.quotas | handlerEmpty }}
              </span>
            </li>
            <li>
              <span class="light">
                {{ $t('numberOfInternationalStudents') }}
              </span>
              <span class="dark" v-if="data.number_of_foreign_students">
                {{ data.number_of_foreign_students | handlerEmpty }} 
              </span>
              <span class="dark" v-else>
                {{ data.number_of_foreign_students | handlerEmpty  }}
              </span>
            </li>
          </ul>
          <router-link
            class="link"
            :to="`/education-in-russia/universities/${data.slug}?tab=educationProgram`"
          >
            {{ data.programs_count | declOfNum([$t('__educationalPrograms'), $t('___educationalPrograms'), $t('_educationalPrograms')]) }}
          </router-link>
        </figcaption>
      </figure>
    </div>

    <div class="card__universities-control">
      <div class="card__universities-control-logo">
        <a href="#">
          <img alt="" :src="data.logo" />
        </a>
      </div>

      <div>
        <a
          class="favorite"
          :class="{ active: data.favorite }"
          href="javascript:void(0)"
          @click="() => addFavorite(data)"
        >{{ data.favorite ? $t('inFavorites') : $t('toFavorites') }}</a>

        <a
          class="btn choose"
          :class="{ active: data.favorite }"
          href="javascript:void(0)"
          @click="() => addChoices(data)"
        >{{ data.choice ? $t('fromChoices') : $t('choices') }}</a>
      </div>
    </div>

    <Modal
      :unique-id="`${modalErrorUniqueId}_${data.id}`"
      ref="modal"
    >
      <div slot="body">
        <h2 class="title-2">{{ $t('error') }}</h2>

        <p>{{ textError }}.</p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => targetModal(`${modalErrorUniqueId}_${data.id}`)"
        >{{ $t('close') }}</a>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Modal from '@/components/generic/modal/Modal';
  import uniCard from '@/assets/images/uni-card.png';
  import moment from 'moment';

  export default {
    name: 'university-search-item',

    components: {
      Modal,
    },

    props: {
      data: Object,
      isHeaderUniversity: {type: Boolean, default: false},
      options: Object,
    },

    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
        getDataCountries: 'getDataCountries',
      }),
      convertDateFoundation() {
        return this.data.date_of_foundation 
          ? moment(this.data.date_of_foundation, 'YYYY-MM-DD').format('DD.MM.YYYY')
          : '—'
      },
      countryUnversity() {
        return this.getDataCountries.find(({id}) => Number(id) === Number(this.data.address.country))
      },
    },

    data() {
      return {
        modalErrorUniqueId: 'modal-choice-error',
        textError: '',
        defaultImg: uniCard,
      }
    },

    methods: {
      ...mapActions({
        setFavoritesAdd: 'users/setFavoritesAdd',
        unsetFavoritesDelete: 'users/unsetFavoritesDelete',
        setMyChoiceAdd: 'users/setMyChoiceAdd',
        unsetMyChoiceDelete: 'users/unsetMyChoiceDelete',
        getCountries: 'getCountries',
      }),

      addFavorite(university) {
        this.$store.commit('preloader/isLoading', true)
        if (university.favorite) {
          this.unsetFavoritesDelete({
            university_id: university.id,
          }).then(() => {
            university.favorite = false;
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        } else {
          this.setFavoritesAdd({
            university_id: university.id,
          }).then(res => {
            if (res.status === 'success') {
              university.favorite = true;
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        }
      },

      addChoices(university) {
        this.$store.commit('preloader/isLoading', true)
        if (university.choice) {
          this.unsetMyChoiceDelete({
            university_id: university.id,
          }).then(() => {
            university.choice = false;
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        } else {
          this.setMyChoiceAdd({
            university_id: university.id,
          }).then(res => {
            if (res.status === 422) {
              this.targetModal(`${this.modalErrorUniqueId}_${university.id}`);
              this.textError = res.data.errors.program_to_university[0];
            } else {
              if (res.status === 'success' && res.data !== false) {
                university.choice = true;
              } else {
                this.$router.push(`/education-in-russia/universities/${university.slug}?tab=educationProgram`);
                this.$scrollTo('#educationProgram', {duration: 500})
              }
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        }
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },

      prepareUrl(website) {
        return website && website.indexOf('//') > 0 ? website.replace('https:', '').replace('http:', '') : `//${website}`;
      },
    },
  }
</script>
