import _ from 'lodash';
import axios from 'axios';
import storeRoot from '@/store';
import rootRouter from '@/router';
import {Base64} from 'js-base64';

const apiUrl = process.env.NODE_ENV !== 'production' ? 'https://dev.education-in-russia.com' : process.env.VUE_APP_API;

export const api = {
  $getMethod,
  $authMethod,
  $deleteMethod,
  $freshMethod,
  $postMethod,
  $putMethod,
  $patchMethod,
};

function _checkError(error) {
  const token = localStorage.getItem('userToken')
  if (error.toString().indexOf('401') !== -1 && !token) {
    storeRoot.commit('users/logout');

    return rootRouter.push('/account/login').catch(() => {})
  }

  if (error) {
    return error.response;
  }
}

function $getMethod(store, {url, mutationType, params, headers = {}}) {
  return axios
    .get(apiUrl + url, {
      headers: {
        ...headers,
        'AU-TOKEN': `Bearer ${_.get(storeRoot.state, 'users.userToken')}`,
        Authorization: `Basic ${Base64.encode('education-in-russia:H97R9I5172TnqNuVxJA9')}`,
        'X-Requested-With': 'XMLHttpRequest',
        'X-localization': store.state.curLanguage,
      },

      params,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response.data;
    })
    .catch(_checkError);
}

function $freshMethod(url) {
  return axios
    .get(apiUrl + url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error) {
        return error.response;
      }
    });
}

function $authMethod(store, {url, data, mutationType}) {
  return axios
    .post(apiUrl + url, data)
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $postMethod(store, {url, data, mutationType}) {
  let headers = {
    'AU-TOKEN': `Bearer ${_.get(storeRoot.state, 'users.userToken')}`,
    Authorization: `Basic ${Base64.encode('education-in-russia:H97R9I5172TnqNuVxJA9')}`,
    'X-Requested-With': 'XMLHttpRequest',
    'X-localization': store.state.curLanguage,
  };

  if (data.toString().indexOf('FormData') !== -1) {
    _.assign(headers, {'Content-Type': 'multipart/form-data'})
  }

  return axios({
    method: 'post',
    url: apiUrl + url,
    data,
    headers: headers,
  })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response.data;
    })
    .catch(_checkError);
}

function $putMethod(store, {url, params, data, mutationType}) {
  const _params = params || '';
  const _data = data || '';
  return axios
    .put(apiUrl + url, _data, {
      headers: {
        'AU-TOKEN': `Bearer ${_.get(storeRoot.state, 'users.userToken')}`,
        Authorization: `Basic ${Base64.encode('education-in-russia:H97R9I5172TnqNuVxJA9')}`,
      },
      params: _params,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $deleteMethod(store, {url, mutationType, data}) {
  return axios
    .delete(apiUrl + url, {
      headers: {
        'AU-TOKEN': `Bearer ${_.get(storeRoot.state, 'users.userToken')}`,
        Authorization: `Basic ${Base64.encode('education-in-russia:H97R9I5172TnqNuVxJA9')}`,
      },

      data,
    })
    .then(response => {
      if (mutationType) {
        store.commit(mutationType, response.data);
      }

      return response;
    })
    .catch(_checkError);
}

function $patchMethod(store, {url, params, data}) {
  const _params = params || '';
  const _data = data || '';
  return axios
    .patch(apiUrl + url, _data, {
      headers: {
        'AU-TOKEN': `Bearer ${_.get(storeRoot.state, 'users.userToken')}`,
        Authorization: `Basic ${Base64.encode('education-in-russia:H97R9I5172TnqNuVxJA9')}`,
      },
      _params,
    })
    .then(response => {
      return response;
    })
    .catch(_checkError);
}
