<template>
  <div class="captcha-wrapper">
    <nocaptcha
        class="captcha"
        :appkey="'FFFF00000000016AB730'"
        :scene="'test'"
        @callback="answer"
        :lang="$t('captcha')"
    ></nocaptcha>
  </div>
</template>
<script>

import nocaptcha from 'vue-nocaptcha/lib/nocaptcha.js'
export default {
  props: {
    answer: Function,
  },
  components: {
    nocaptcha,
  },
}
</script>
<style>
.captcha-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 60px;
}

.captcha {
  width: 100%;
}

.captcha  span {
  height: 34px !important;
}
</style>
