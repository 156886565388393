<template>
  <badger-accordion class="accordion-content">
    <badger-accordion-item
      v-for="(value, key) in items"
      v-bind:key="key"
    >
      <div slot="header">{{ value.name }}</div>

      <div slot="content">
        <div class="accordion-table-wrap">
          <div
            v-for="(additional, k) in value.additional || []"
            v-bind:key="`${k}add`"
          >
            <RussianLanguageCourses v-if="additional === 'russianLanguageCourses'" :type=0 />
            <RussianLanguageCourses v-if="additional === 'russianLanguageCoursesFullTime'" :type=1 />
          </div>

          <div class="col-4" v-html="value.html" />
        </div>
      </div>
    </badger-accordion-item>
  </badger-accordion>
</template>

<script>
  import RussianLanguageCourses from '@/components/site/pages/generic/RussianLanguageCourses';

  export default {
    name: 'accordion',

    components: {
      RussianLanguageCourses,
    },

    props: {
      items: Array,
    },

    data() {
      return {

      }
    },
  };
</script>
