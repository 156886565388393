<template>
  <div
    :class="[
      'preloader',
      {
        preloaderIsLoading: isLoading,
      },
    ]"
  >
    <img :src="require(`@/assets/images/preloader.svg`)" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    isLoading: Boolean,
  },
  watch: {
    isLoading(value) {
      const bodyEl = document.querySelector('body')
      if (value) {
        bodyEl.style.overflow = 'hidden'
      } else {
        bodyEl.style.overflow = 'auto'
      }
    },
  },
};

</script>
<style>
body{
  overflow: hidden;
}

.preloader {
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.7);
  position: fixed;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.preloader img {
  height: 100px;
}

.preloader.preloaderIsLoading {
  display: flex;
}
</style>
