<template>
  <div></div>
  <!-- DONE: removed banner -->
  <!-- TODO: fetch data to show this banner -->
</template>

<script>
export default {
  name: 'account-profile-representation',
};
</script>
