<template>
  <div class="container" id="print-result">
    <div class="def-container" v-if="data && step === 'fin'">
      <div class="title-close mb32">
        <h2 class="title-2">{{ $t('testReport') }}</h2>
        <div 
          class="close-btn"
          @click="closeReport"  
        >
          <img alt="" src="@/assets/images/close-icon.png">
        </div>
      </div>

      <hr class="mb10">

      <div class="row mb20">
        <div class="col-1">
          <span class="title-sub light">{{ $t('testDate') }}</span>
          <p class="title-sub">{{ getDataNow() }}</p>
        </div>
        <div class="col-1" v-show="false">
          <span class="title-sub light">
            {{ $t('duration') }}
          </span>
          <p class="title-sub">
            00:40:27
          </p>
        </div>
      </div>

      <hr class="mb32">

      <div class="carrer-report-info" v-if="forms.careerTests">
        <div class="carrer-report-info-row">
          <span class="light">{{ $t('memberName') }}</span>
          <span>{{ forms.careerTests.name }}</span>
        </div>

        <div class="carrer-report-info-row">
          <span class="light">{{ $t('age') }}</span>
          <span>{{ forms.careerTests.age }}</span>
        </div>

        <div class="carrer-report-info-row">
          <span class="light">{{ $t('gender') }}</span>
          <span>{{ genders[forms.careerTests.gender] }}</span>
        </div>

        <div class="carrer-report-info-row">
          <span class="light">{{ $t('currentLevelOfEducation') }}</span>
          <span>{{ getName(levelsEducation, forms.careerTests.levelEducation) }}</span>
        </div>

        <div class="carrer-report-info-row">
          <span class="light">{{ $t('areasOfInterestHobbies') }}</span>
          <span>{{ getName(areasInterestHobbies, forms.careerTests.areasInterestHobbies) }}</span>
        </div>

        <div class="carrer-report-info-row">
          <span class="light">{{ $t('sportsAchievements') }}</span>
          <span>{{ getName(sports, forms.careerTests.sportsAchivments) }}</span>
        </div>
      </div>

      <h2 class="title-2 mb16">{{ $t('testScaleResults') }}</h2>

      <div class="row">
        <div class="col-5 graph-wrap">
          <div class="graph">
            <div class="graph-content">
              <div
                class="graph-content-item"
                v-for="(char, key) in data.characteristics"
                v-bind:key="key"
              >
                <div
                  class="graph-labels-item"
                >{{ char.char | lang('name') }}</div>
                <div
                  class="graph-rows-item"
                >
                  <div class="graph-rows-item-progress" :style="`width: ${char.score * 100 / 30.8}%`">
                    <span>{{ char.low_level }}{{ char.score }}</span>
                  </div>
                </div>
              </div>
              <div 
                class="graph-line" 
                v-for="(char, key) in data.characteristics" 
                v-bind:key="key" 
                :id="`param${key}`"
              >
                <div
                  class="graph-line-item"
                  :class="{active: nthChild === num}"
                  v-for="(num, key) in getRange()"
                  :id="`${key}-answer`"
                  v-bind:key="key"
                ><span>{{ num }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="title-2 mb16">{{ $t('scaleDescription') }}</h2>

      <div
        v-for="(char, key) in data.characteristics"
        v-bind:key="key"
        class="carrer-report-text"
      >
        <h4 class="title-4" v-if="char.text">{{ char.char | lang('name') }}</h4>
        <p class="mb32" v-if="char.text" v-html="char.text" />
      </div>

      <h2 class="title-2 mb16">{{ $t('recommendedEducationAreas') }}</h2>

      <div class="row">
        <div class="col-5">
          <div class="graph">
            <div class="graph-content">
              <div class="graph-content-item"
                   v-for="(pro, key) in data.profile"
                   v-bind:key="key"
              >
                <div
                    class="graph-labels-item"
                >{{ pro.profile | lang('name') }}</div>
                <div
                    class="graph-rows-item"
                >
                  <div class="graph-rows-item-progress" :style="`width: ${pro.score * 100 / 30.8}%`">
                    <span>{{ pro.low_level }}{{ pro.score }}</span>
                  </div>
              </div>
              </div>
              <div
                class="graph-line" 
                v-for="(pro, key) in data.profile" 
                v-bind:key="key" 
                :id="`param${key}`"
              >
                <div
                  class="graph-line-item"
                  :class="{active: nthChild === num}"
                  v-for="(num, key) in getRange()"
                  v-bind:key="key"
                ><span>{{ num }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(pro, key) in data.profile"
        v-bind:key="`${key}-pro`"
        class="carrer-report-text"
      >
        <h4 class="title-4" v-if="pro.text">{{ pro.profile | lang('name') }}</h4>
        <p class="mb32" v-if="pro.text" v-html="pro.text" />
      </div>

      <div class="form-row__btn">
        <a class="btn" href="javascript:void(0)" @click="printDiv">{{ $t('print') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import {helpers} from '@/_services/helpers.service';
  import moment from 'moment';

  export default {
    name: 'career-tests-report',

    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),

    step: self => self.$store.state.educationInRussia.params.careerTests.step,
    },

    methods: {
      ...mapActions({
        getSports: 'educationInRussia/getSports',
        getTestingDownload: 'educationInRussia/getTestingDownload',
        getLevelEducation: 'getLevelEducation',
        getHobby: 'educationInRussia/getHobby',
      }),

      getName(object, value) {
        if (_.isArray(value)) {
          let strAr = [];

          _.each(value, v => {
            strAr.push(v ? (_.find(object, {value: v}) || {}).name : '')
          })

          return strAr.join(', ');
        }

        return value ? (_.find(object, {value: value}) || {}).name : ''
      },

      getDataNow() {
        return moment().format('DD.MM.YY')
      },

      printDiv() {
        window.print();
      },

      getRange(min = 0, max = 30) {
        return _.range(min, max + 1);
      },

      closeReport() {
        this.$store.commit('educationInRussia/updateParam', {model: 'careerTests.step', value: 'questionnaire'})
      },

      testingDownload() {
        this.getTestingDownload({...this.dataRequest, form: this.forms.careerTests}).then(res => {
          window.open(res['online-tests'], '_blank');
        })
      },
    },

    data() {
      return {
        names: [],
        values: [[ 6, 7, 8, 5 ]],
        genders: {},
        sports: [],
        levelsEducation: [],
        areasInterestHobbies: [],
        nthChild: 0,
      };
    },

    props: {
      data: {},
      dataRequest: Object,
    },

    mounted() {
      this.names = [this.$t('evaluation')];
      this.genders = {m: this.$t('male'), w: this.$t('female')};

      this.getSports().then(res => {
        this.sports = helpers.options(res.data)
      });

      this.getLevelEducation().then(res => {
        this.levelsEducation = helpers.options(res)
      });

      this.getHobby().then(res => {
        this.areasInterestHobbies = helpers.options(res.data)
      })

      let sr = 0;

      _.each(this.data.characteristics, characteristic => {
        sr += characteristic.percent || 0;
      })

      this.nthChild = Math.round(sr / Object.keys(this.data.characteristics || {}).length);

      if (!this.nthChild) {
        this.$store.commit('educationInRussia/updateParam', {model: 'careerTests.step', value: 'questionnaire'})
      }
    },
  };
</script>
