<template>
  <div class='container' id='container-calendar'>
    <div class='login__wrap'>
      <div ref='calendar' id='calendar'></div>
      <div v-if='isCurrentEventShown' class='event-popover'
           :style='`top: ${currentEvent.pageY}px; left: ${currentEvent.pageX}px;`'>
        <EventItem :data='currentEvent.event' />
      </div>
      <div v-if='isCurrentEventShown' class='event-popover-bg' @click='isCurrentEventShown = false' />
    </div>
  </div>
</template>

<style lang='scss'>
.container, .card__vertical {
  position: unset;
}

.calendar-padding {
  padding-top: 0 !important;
}
</style>

<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import {Calendar} from '@fullcalendar/core'
import moment from 'moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'
import Vue from 'vue'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import '@fullcalendar/list/main.css'
import EventItem from '@/components/site/pages/events/generic/EventItem'

export default {
  name: 'events-calendar',

  components: {
    EventItem,
  },

  data: () => ({
    currentEvent: {
      pageX: 300,
      pageY: 300,
    },
    isCurrentEventShown: false,
    eventsCalendar: [],
    calendar: {},
    componentKey: 0,
  }),

  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
  },

  methods: {
    ...mapActions({
      getEventsCalendar: 'events/getEventsCalendar',
    }),
  },

  mounted() {
    window.scrollTo(0, 0)

    let calendarEl = document.getElementById('calendar')

    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin],
      locales: [ruLocale],
      fixedWeekCount: false,
      header: {
        left: 'prev,title,next',
        center: '',
        right: '',
      },
      views: {
        dayGridMonth: {
          titleFormat: 'MMMM, YYYY',
        },
      },
      eventTimeFormat: 'hh:mm',

      //idk how fix it on mobile
      contentHeight: 900,

      eventClick: ev => {
        ev.el.classList.add('fc-event-active')
        this.currentEvent.pageX = ev.jsEvent.pageX - 150
        this.currentEvent.pageY = ev.jsEvent.pageY + 15
        this.currentEvent.title = ev.event.title
        this.currentEvent.time = ev.event.extendedProps.time
        this.currentEvent.location = ev.event.extendedProps.location
        this.currentEvent.event = ev.event.extendedProps.event
        this.isCurrentEventShown = true
      },
    })

    this.calendar.render()
    this.calendar.setOption('locale', this.locale)

    this.getEventsCalendar().then(res => {
      this.eventsCalendar = res.data

      _.map(this.eventsCalendar, event => {
        this.calendar.addEvent({
          title: Vue.filter('lang')(event, 'name'),
          start: moment(`${event.start_date} ${event.start_time}`).format('YYYY-MM-DD hh:mm'),
          end: moment(`${event.start_date} ${event.end_time}`).format('YYYY-MM-DD'),

          extendedProps: {
            event,
          },

          description: event.description,
        })
      })
    })
  },

  watch: {
    isCurrentEventShown() {
      if (!this.isCurrentEventShown) {
        _.map(document.getElementsByClassName('fc-event-active'), fcEvent => fcEvent.classList.remove('fc-event-active'))
      }
    },

    locale() {
      this.calendar.setOption('locale', this.locale)
    },
  },
}
</script>
