import _ from 'lodash';
import store from '@/store';
import axios from 'axios';

export const helpers = {
  options(options, name = 'name', value = 'id') {
    return _.map(options, option => {
      let opt = _.find(option.translations, {'locale': store.getters.getLocale}, {}) || {};

      if (_.isEmpty(opt) && option.translations) {
        opt = option.translations[0];
      }

      if (!option.translations) {
        opt = option;
      }

      return {name: opt[name] , value: option[value]}
    })
  },
  optionsCode(options, name = 'name', value = 'id', code = 'code') {
    return _.map(options, option => {
      let opt = _.find(option.translations, {'locale': store.getters.getLocale}, {}) || {};

      if (_.isEmpty(opt) && option.translations) {
        opt = option.translations[0];
      }

      if (!option.translations) {
        opt = option;
      }

      return {name: option[code]? `${option[code]} ${opt[name]}` : opt[name] , value: option[value]}
    })
  },
  optionsObjectKeys(obj) {
    let arr = []
    for (const [key, value] of Object.entries(obj)) {
      arr.push({value:key, name:value})
    }
    return arr
  },
  
  convertFileToBase64(file) {
    if (file instanceof Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    }
    console.error(`Cannot convert ${ typeof file} to Base64`)
  },

  getFileMeta(file) {
    if (file !== undefined) {
      return {
        name: file.name,
        type: file.type,
        size: file.size,
      }
    }
  },

  useDownload(url, name) {
    axios({
      url: url.split('.com')[1],
      method: 'GET',
      responseType: 'blob', 
    }).then(response => {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a')
      fileLink.href = fileUrl

      fileLink.setAttribute('download', name)
      document.body.appendChild(fileLink)

      fileLink.click()
    })
  },

  convertFormToJson(formData) {
    if (formData instanceof Object) {
      return new Promise(resolve => {
        const object = {};
        const promises = []
        formData.forEach((value, key) => {
          if (value instanceof Blob) {
            promises.push({
              key,
              meta: this.getFileMeta(value),
              promise: this.convertFileToBase64(value),
            })
          } else {
            object[key] = value
          }
        });


        if (promises.length > 0) {
          const proxyPromises = promises.map(el => el.promise)
          Promise.all(proxyPromises).then(values => {
            values.forEach((el, index) => {
              object[promises[index].key] = {
                ...promises[index].meta,
                data: el,
              }
            })
            resolve(object)
          })
        } else {
          resolve(object)
        }
      })
    }
    console.error(`Cannot convert ${ typeof formData} to JSON`)
  },
}
