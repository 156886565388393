<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="login__page-container">
      <div class="container">
        <div class="login__wrap">
          <div slot="form" class="login">
            <div style="height: 80px; width: 80px">
              <Preloader :isLoading="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Preloader from '@/components/generic/pagination/Preloader';

export default {
  name: 'Logout',
  components: {
    Preloader,
  },

  computed: {
    ...mapGetters({
      forms: 'form/forms',
      user: 'users/getUser',
      getLocale: 'getLocale',
    }),
  },

  methods: {
    logout() {
      this.$store.commit('users/logout');
    },
  },

  mounted() {
    this.logout();
  },
};
</script>
