export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "सहायता",
  personalArea: "पर्सनल अकाउंट",
  myApplications: "मेरे आवेदन",
  servicesAndFacilities: "सेवाएं और सर्विसेज",
  logout: "साइन आउट करें",
  favorites: "फेवरेट",
  theChoice: "चुनें",
  notifications: "नोटिफिकेशन्स",
  logIn: "लॉगिन करें",
  quickAccessThroughSocialNetworks: "सोशल साइट्स के माध्यम से तुरंत लॉगिन",
  enterYourEmail: "अपना e-mail दर्ज करें",
  password: "पासवर्ड",
  enterPassword: "पासवर्ड दर्ज करें",
  rememberMe: "मुझे याद रखें",
  forgotYourPassword: "पासवर्ड भूल गए हैं?",
  noAccount: "अकाउंट नहीं है?",
  registration: "साइन अप",
  countryOfCitizenship: "नागरिकता का देश",
  chooseYourCountryOfCitizenship: "नागरिकता का देश सेलेक्ट करें",
  iHaveReadAndAgree: "मैंने पढ़कर समझ लिया है और",
  _toTheProcessingOfMyPersonalData:
    "अपने पर्सनल डाटा की प्रोसेसिंग के लिए सहमति देता हूँ",
  iAgreeTo: "मैं cookie फाइलों के",
  _theUseOfCookies: "प्रयोग के लिए सहमति देता हूँ",
  signUp: "साइन अप करें",
  haveAnAccount: "अकाउंट पहले से है?",
  mainPage: "होम पेज",
  frequentlyAskedQuestionsAnswers: "अक्सर पूछे जाने वाले प्रश्नों के उत्तर",
  goTo: "जाएं",
  startTesting: "टेस्टिंग शुरू करें",
  buildCareerVector: "कैरियर वेक्टर बनाएँ",
  forum: "फोरम",
  textForumBanner:
    "जेनरल फोरम पर उन सभी विषयों पर चर्चा करें जो आपके लिए महत्वपूर्ण हैं: रूस के शहरों में रहना-सहना कैसा है, रूस के विश्वविद्यालयों में पढ़ाई कैसे होती है, आदि।",
  feedback: "हमसे संपर्क करें",
  technicalSupportFeedbackForm: "टेक्निकल सपोर्ट सेवा से संपर्क करने का फॉर्म",
  allCities: "सभी शहर",
  citiesOfRussia: "रूस के शहर",
  educationInRussia: "रूस में पढ़ाई",
  educationalOrganizations: "विश्वविद्यालय",
  educationInRussiaYourKeyToSuccess: "रूस में शिक्षा आपकी सफलता की कुंजी है",
  applyForStudyInRussia: "रूस में पढ़ने के लिए आवेदन करें",
  apply: "आवेदन करें",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "करियर मार्गदर्शन और आवेदन करने के लिए एक्सेस प्राप्त करने के लिए लॉगिन या साइन अप करें",
  aboutOrganization: "संस्था के बारे में",
  eventsAndOlympiads: "इवेंट्स और ओलिंपियाड्स",
  educationalPrograms: "पाठ्यक्रम",
  contacts: "कॉन्टेक्ट्स",
  inFavorites: "फेवरिट्स की सूची में",
  toFavorites: "फेवरेट की सूची में",
  fromChoices: "सेलेक्ट किया गया है",
  choices: "सेलेक्ट करें",
  generalInformation: "सामान्य जानकारी",
  internationalRatings: "अंतर्राष्ट्रीय रेटिंग्स",
  nationalRatings: "राष्ट्रीय रेटिंग",
  ratings: "रेटिंग्स",
  year: "वर्ष",
  ratingName: "रेटिंग का नाम",
  aPlace: "स्थान",
  screeningTests: "क्वालिफाइंग टेस्ट्स",
  choiceOfProfession: "प्रोफेशन चुनें",
  lifeInRussia: "रूस में रहना-सहना",
  russianLanguage: "रूसी भाषा",
  livingConditions: "रहने की शर्तें",
  cultureAndLeisure: "संस्कृति और खाली समय",
  safety: "सुरक्षा",
  benefitsForInternationalStudents: "विदेशी छात्रों के लिए छूट",
  workForInternationalStudents: "विदेशी छात्रों के लिए काम करने के प्रावधान",
  studentVisaAndRegistration: "स्टूडेंट वीज़ा और पंजीकरण",
  healthInsurance: "स्वास्थ्य बीमा",
  howToEnter: "प्रवेश कैसे लें",
  educationLevels: "शिक्षा के स्तर",
  admissionProcess: "प्रवेश लेने की प्रक्रिया",
  olympics: "ओलिंपियाड्स",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "सन् {year} में रूस में पढ़ने के लिए विदेशी नागरिकों के चयन के लिए आधिकारिक वेबसाइट।",
  whyRussia: "रूस ही क्यों",
  "10reasonsToStudyInRussia": "रूस में पढ़ने के लिए 10 कारण",
  reviewsOfForeignStudents: "विदेशी छात्रों के फ़ीडबैक",
  onlineMonitoring: "ऑनलाइन-मॉनिटरिंग",
  mainPrograms: "मुख्य पाठ्यक्रम",
  additionalPrograms: "सप्लीमेंट्री पाठ्यक्रम",
  chooseCounty: "डिस्ट्रिक्ट चुनें",
  county: "क्षेत्र",
  chooseACity: "शहर सेलेक्ट करें",
  city: "शहर",
  chooseYourLevelOfEducation: "शिक्षा का स्तर सेलेक्ट करें",
  chooseYourAreaOfScientificKnowledge: "वैज्ञानिक ज्ञान का एक क्षेत्र चुनें",
  chooseYourScientificSpecialtyProfile: "एक वैज्ञानिक विशेषता का प्रोफ़ाइल चुनें",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "शिक्षा का स्तर",
  chooseAGroupOfTrainingAreas: "मुख्य विषयों का समूह सेलेक्ट करें",
  trainingDirectionGroup: "मुख्य विषयों का समूह",
  chooseATrainingDirection: "मुख्य विषय सेलेक्ट करें",
  trainingDirection: "मुख्य विषय",
  chooseRatings: "रेटिंग्स सेलेक्ट करें",
  thePresenceOfThePreparatoryFaculty: "प्री-यूनिवर्सिटी फैकल्टी का होना",
  show: "दिखाएं",
  _university: "विश्वविद्यालय",
  _universities: "विश्वविद्यालय",
  __universities: "विश्वविद्यालयों",
  clearAllFilters: "सभी फ़िल्टर रिसेट करें",
  chooseSemester: "सेमेस्टर सेलेक्ट करें",
  semester: "सेमेस्टर",
  enterTheNameOfTheEducationalOrganization: "विश्वविद्यालय का नाम दर्ज करें",
  _educationalOrganization: "विश्वविद्यालय",
  _educationalOrganizations: "विश्वविद्यालय",
  __educationalOrganizations: "विश्वविद्यालयों",
  chooseYourAreaOfEducation: "अध्ययन का क्षेत्र सेलेक्ट करें",
  areaOfEducation: "अध्ययन का क्षेत्र",
  chooseYourLanguageOfInstruction: "अध्ययन की भाषा सेलेक्ट करें",
  languageOfInstruction: "अध्ययन की भाषा",
  _program: "पाठ्यक्रम",
  _programs: "पाठ्यक्रम",
  __programs: "पाठ्यक्रमों",
  formOfTraining: "अध्ययन का फॉर्मेट",
  level: "स्तर",
  typeOfTraining: "शिक्षा का प्रकार",
  free: "निःशुल्क",
  paid: "सशुल्क",
  tuitionFees: "सशुल्क शिक्षा की कीमत",
  established: "संस्थापन की तिथि",
  numberOfStudents: "छात्रों की संख्या",
  _students: "छात्रों",
  numberOfQuotaPlaces: "कोटा सीटों की संख्या",
  _for: "पर",
  numberOfInternationalStudents: "विदेशी छात्रों",
  _educationalPrograms: "पाठ्यक्रमों",
  moreDetails: "अधिक जानकारी",
  sTEP: "चरण",
  afterTrainingForThisProgramYouCanBecome:
    "यह पाठ्यक्रम पूरा करने के बाद, आप निम्नलिखित प्रोफेशंस अपना सकते हैं",
  chooseProgram: "पाठ्यक्रम सेलेक्ट करें",
  chooseUniversity: "विश्वविद्यालय सेलेक्ट करें",
  print: "प्रिंट-आउट लें",
  save: "सेव करें",
  programs: "पाठ्यक्रम",
  selectedProgram: "सेलेक्ट किया गया पाठ्यक्रम",
  noProgramSelected: "कोई पाठ्यक्रम सेलेक्ट नहीं किया गया",
  selectedEducationalOrganizations: "सेलेक्ट किए गए विश्वविद्यालय",
  educationalOrganizationNotSelected: "कोई विश्वविद्यालय सेलेक्ट नहीं किया गया",
  representation: "प्रतिनिधि कार्यालय",
  programDescription: "पाठ्यक्रम का विवरण",
  programPageOnTheUniversityWebsite:
    "विश्वविद्यालय की वेबसाइट पर पाठ्यक्रम का पेज",
  programCurator: "पाठ्यक्रम का कुरेटर",
  disciplines: "विषय",
  theProcessOfEnteringARussianEducationalOrganization:
    "रूसी विश्वविद्यालय में प्रवेश की प्रक्रिया",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "पाठ्यक्रम और अधिकतम 6 विश्वविद्यालय सेलेक्ट करें",
  takeTheCompetitiveSelectionInYourCountry:
    "अपने देश में क्वालीफाइंग टेस्ट पास करें",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "ऑनलाइन आवेदन भरें और डाक्यूमेंट्स का पैकेज संलग्न करें",
  getAnInvitationToStudAnStudyVisa:
    "पढ़ने के लिए निमंत्रण और स्टूडेंट वीज़ा प्राप्त करें",
  preparatoryFaculties: "प्री-यूनिवर्सिटी फैकल्टी",
  summerAndWinterSchools: "समर और विंटर स्कूल",
  russianLanguageCourses: "रूसी भाषा कोर्सेज",
  tutorsTheRussianLanguage: "रूसी भाषा ट्यूटर्स",
  certificationInRussian: "रूसी भाषा ज्ञान सर्टिफिकेशन",
  news: "न्यूज़",
  allNews: "सभी न्यूज़",
  reviewsOfStudentsAndGraduates: "स्टूडेंट्स और ग्रेजुएट्स के फ़ीडबैक",
  areYouSureYouWantToWithdrawTheApplication:
    "क्या आप वास्तव में आवेदन वापस लेना चाहते हैं?",
  withdrawApplication: "आवेदन वापस लें",
  jan: "जनवरी",
  feb: "फ़रवरी",
  mar: "मार्च",
  apr: "अप्रैल",
  may: "मई",
  jun: "जून",
  jul: "जुलाई",
  aug: "अगस्त",
  sep: "सितंबर",
  act: "अक्तूबर",
  nov: "नवंबर",
  dec: "दिसंबर",
  cityFeatures: "शहर की विशेषताएं",
  enterTheNameOfTheCity: "शहर का नाम दर्ज करें",
  organizations: "संस्थाएं",
  livingWage: "रहने-सहने के लिए आवश्यक न्यूनतम धनराशि",
  "one-roomApartmentForRent": "1 कमरे वाले अपार्टमेंट का किराया",
  _month: "प्रतिमाह",
  averageTemperature: "औसत तापमान",
  _inWinter: "सर्दियों में",
  _inSummer: "गर्मियों में",
  averageCostOfDormitories: "छात्रावासों में रहने का औसत खर्चा",
  photos: "फोटो",
  interestingPlaces: "दर्शनीय स्थल",
  readCompletely: "पूरा पढ़ें",
  _review: "फ़ीडबैक",
  _reviews: "फ़ीडबैक",
  __reviews: "फ़ीडबैक",
  university: "विश्वविद्यालय",
  universities: "विश्वविद्यालय",
  country: "देश",
  selectACountry: "देश सेलेक्ट करें",
  reviewOfAForeignStudent: "विदेशी छात्र का फ़ीडबैक",
  careerGuidance: "प्रोफेशनल ओरिएंटेशन",
  determineYourAreaOfEducationAndProfession:
    "आपके लिए उचित शिक्षा और व्यवसाय के क्षेत्र निर्धारित",
  myCareer: "मेरा करियर",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "पढ़ाई को कल्चरल रिक्रिएशन और नए दोस्त बनाने की क्रियाकलापों के साथ कैसे एडजेस्ट करें। इंटेंसिव कोर्सेज की विशेषताएं और फीस",
  toApply: "अप्लाई करें",
  chooseYear: "वर्ष चुनें",
  _in: "में",
  universitiesInThisCity: "इस शहर में विश्वविद्यालय",
  programsInThisCity: "इस शहर में पाठ्यक्रम",
  all: "सभी",
  _inYourCountry: "आपके देश में",
  _common: "सामान्य",
  allReviews: "सभी फ़ीडबैक",
  calendar: "कैलेंडर",
  activity: "इवेंट्स",
  addToPersonalCalendar: "पर्सनल कैलेंडर में जोड़ें",
  eventAdded: "इवेंट जोड़ दिया गया है",
  eventSuccessfullyAddedToYourCalendar:
    "इवेंट आपके कैलेंडर में सफलतापूर्वक जोड़ दिया गया है",
  population: "आबादी",
  onTheMap: "मानचित्र पर",
  generalEducation: "जनरल एजुकेशन",
  professionalEducation: "प्रोफेशनल एजुकेशन",
  preschoolEducation: "प्री-स्कूल एजुकेशन",
  kindergarten: "डे-केयर सेंटर",
  nursery: "इन्फेंट डे-केयर सेंटर",
  earlyDevelopmentCenters: "इन्फेंट डेवलपमेंट सेंटर",
  primaryAndSecondaryEducation: "प्राथमिक, बेसिक और माध्यमिक शिक्षा",
  schools: "स्कूल",
  lyceums: "लाइसियम",
  gymnasium: "जिम्नेज़ियम",
  secondaryVocationalEducation: "मीडियम प्रोफेशनल एजुकेशन",
  colleges: "कॉलेज",
  technicalSchools: "टेक्निकल कॉलेज",
  secondarySchool: "टेक्निकल स्कूल",
  higherEducation: "उच्च शिक्षा",
  baccalaureate: "बैचलर डिग्री",
  specialistDegree: "स्पेशलिस्ट डिग्री",
  magistracy: "मास्टर डिग्री",
  postgraduateStudies: "रिसर्च डिग्री",
  preparatoryFaculty: "प्री-यूनिवर्सिटी फैकल्टी",
  summerSchools: "समर स्कूल",
  additionalGeneralAndPreVocationalPrograms:
    "सप्लीमेंट्री जनरल डेवलपिंग और प्री-प्रोफेशनल पाठ्यक्रम",
  continuingEducationAndRetrainingPrograms:
    "क्वालिफिकेशन बढ़ाने वाले और प्रोफेशनल रीट्रेनिंग वाले पाठ्यक्रम",
  whatAreTheLevelsOfRussianEducation:
    "रूस के एजुकेशन स्ट्रक्चर के कौन-कौन-से लेवल होते हैं?",
  enterYourName: "अपना नाम दर्ज करें",
  name: "नाम",
  selectTheSubjectOfTheMessage: "संदेश का विषय सेलेक्ट करें",
  messageSubject: "संदेश का विषय",
  chooseAMessage: "संदेश सेलेक्ट करें",
  message: "संदेश",
  submit: "भेजें",
  frequentlyAskedQuestions: "अक्सर पूछे जाने वाले प्रश्न",
  didNotFindTheAnswerToYourQuestion: "हमें आपके प्रश्न का कोई उत्तर नहीं मिला",
  contactSupportAt: "इस एड्रेस पर सपोर्ट सेवा से संपर्क करें",
  searchInTheSection: "अनुभाग में खोज",
  sort: "छंटाई करें",
  searchingResults: "खोज के परिणाम",
  _answers: "जवाबों",
  _views: "कितनी बार देखा गया",
  byCreationDate: "क्रिएट करने की तिथि के अनुसार",
  byTheNumberOfResponses: "जवाबों की संख्या के अनुसार",
  byViews: "कितनी बार देखा गया उसके अनुसार",
  toAnswer: "जवाब दें",
  toQuote: "कोट करें",
  complain: "शिकायत करें",
  addNewMessage: "नया संदेश जोड़ें",
  selectComplaintType: "शिकायत का प्रकार चुनें",
  typeOfComplaint: "शिकायत का प्रकार",
  enterComplaintText: "शिकायत का टेक्स्ट दर्ज करें",
  complaintText: "शिकायत का टेक्स्ट",
  complaintSentSuccessfully: "शिकायत सफलतापूर्वक भेजी गई है",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "आपकी शिकायत सफलतापूर्वक भेजी जा चुकी है, हम शीघ्र ही उसकी समीक्षा करेंगे",
  close: "बंद करें",
  myMessages: "मेरे संदेश",
  myTopicsAndPosts: "मेरे टॉपिक्स और संदेश",
  searchInMyPosts: "मेरे संदेशों में खोज",
  _posts: "संदेशों",
  feedbackOnEducationalOrganizations: "विश्वविद्यालयों के बारे में फ़ीडबैक",
  posted: "पब्लिश किया जा चुका है ",
  goToMessage: "संदेश पर जाएं",
  _themes: "टॉपिक",
  themeTitle: "टॉपिक का नाम",
  enterAThemeName: "टॉपिक का नाम दर्ज करें",
  cancel: "रद्द करें",
  banUser: "यूज़र को बैन करें?",
  areYouSure: "वास्तव में करें?",
  toBan: "बैन करें",
  _logIn: "साइन इन करें",
  _or: "या",
  _signUp: "साइन अप करें",
  eventCalendar: "इवेंट कैलेंडर",
  trainingApplicationsFiled: "पढ़ाई के लिए आवेदनों की संख्या",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "अभ्यर्थियों द्वारा चुने जाने वाले सबसे लोकप्रिय विश्वविद्यालय",
  _of: "से",
  _whenApplying: "आवेदन करते समय",
  tOP10EducationalOrganizations: "टॉप-10 विश्वविद्यालय",
  collapse: "कोलैप्स करें",
  showAll: "सब दिखाएं",
  conditionsForForeigners: "विदेशियों के लिए सुविधाएं",
  supportForInternationalStudents: "विदेशी छात्रों का सपोर्ट",
  lifeOfForeignStudents: "विदेशी छात्रों का रहन-सहन",
  leisureAndSportingEvents: "रिक्रिएशन और स्पोर्ट इवेंट्स",
  dormitories: "छात्रावास",
  dormitoryCost: "छात्रावासों में रहने का खर्चा",
  theNumberOfPeopleInTheRoom: "कमरे में कितने लोग रहते हैं",
  from: "से",
  _rubles: "रूबल",
  _to: "तक",
  _perMonth: "प्रतिमाह",
  "2-3People": "2-3 व्यक्ति",
  theInternationalCooperation: "अंतर्राष्ट्रीय कोऑपरेशन",
  photoAndVideo: "फोटो और वीडियो",
  eventTitle: "इवेंट का नाम",
  location: "आयोजन का समय",
  date: "दिनांक",
  enterYourRealMail: "अपना वास्तविक ईमेल दर्ज करें",
  requiredField: "फ़ील्ड भरना आवश्यक है",
  minimumLengthCharacters: "वर्णों की न्यूनतम लंबाई{length}",
  maximumLengthCharacters: "वर्णों की अधिकतम लंबाई{length}",
  theFieldMustBeANumber: "फ़ील्ड संख्या में होना चाहिए",
  onlyLettersAreAllowedInTheField: "फ़ील्ड में केवल अक्षर हो सकते हैं",
  checkKeyboardLayout: "कीबोर्ड का लेआउट जांचें",
  checkTheNumberYouEnteredIsCorrect: "आपने जो नंबर दर्ज किया है, उसे जांचें",
  olympiadName: "ओलंपियाड का नाम",
  description: "विवरण",
  contactInformation: "कॉन्टैक्ट इनफार्मेशन",
  majorForeignPartnerUniversities: "प्रमुख विदेशी पार्टनर-विश्वविद्यालय",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "रूस और पूरी दुनिया में रूसी भाषा फुल-टाइम और डिस्टेंस फॉर्मेट में सीखने की संभावनाएं",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "आप ट्यूटर हैं? वेबसाइट पर अपना प्रोफाइल पब्लिश करने के लिए आवेदन करें",
  submitApplication: "आवेदन करें",
  chooseATeachingExperience: "अध्यापन का अनुभव सेलेक्ट करें",
  teachingExperience: "अध्यापन का अनुभव",
  chooseLanguages: "भाषाएं सेलेक्ट करें",
  knowledgeOfLanguages: "भाषाओं का ज्ञान",
  workExperience: "काम का अनुभव",
  trainingLevel: "कराई जाने वाली तैयारी का स्तर",
  durationOfOneLesson: "एक लेक्चर की समयावधि",
  possibleTrainingTime: "पढ़ाने का संभावित समय",
  theCosOfOneLesson: "एक लेक्चर की फीस",
  writeAMessage: "संदेश लिखें",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "आप ट्यूटर को वेबसाइट पर साइन अप या साइन इन के बाद लिख सकेंगे",
  educationDocuments: "शैक्षिक प्रमाण पत्र",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "आप दूसरा पाठ्यक्रम सेलेक्ट कर चुके हैं, अगर आप यह पाठ्यक्रम सेलेक्ट करते हैं तो पहले सेलेक्ट किया गया पाठ्यक्रम और शैक्षिक संस्थाएं डिलीट हो जाएंगी",
  limitIsExceeded: "लिमिट ओवर हो गई है",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "आप अधिक से अधिक 6 विश्वविद्यालय सेलेक्ट कर सकते हैं।",
  _fieldsOfEducation: "शिक्षा के क्षेत्र",
  _trainingAreas: "मुख्य विषय",
  _cities: "शहर",
  _languagesOfInstruction: "अध्ययन की भाषाएं",
  _typesOfAdditionalPrograms: "सप्लीमेंट्री पाठ्यक्रमों के प्रकार",
  _semesters: "सेमेस्टर",
  chooseDurationOfStudy: "अध्ययन की अवधि सेलेक्ट करें",
  _durationOfStudy: "पाठ्यक्रम की अवधि",
  enterTheNameOfTheEducationalProgram: "पाठ्यक्रम का नाम दर्ज करें",
  instruction: "अनुदेश",
  profile: "प्रश्नावली",
  back: "पीछे",
  next: "आगे",
  testReport: "टेस्टिंग परिणामों पर रिपोर्ट",
  testDate: "टेस्टिंग की तारीख़",
  duration: "समयांतराल",
  memberName: "भाग लेने वाले का नाम",
  age: "आयु",
  gender: "लिंग",
  currentLevelOfEducation: "शिक्षा का वर्तमान स्तर",
  areasOfInterestHobbies: "रुचि, हॉबी के क्षेत्र",
  sportsAchievements: "खेल उपलब्धियां",
  testScaleResults: "टेस्ट स्केल के अनुसार परिणाम",
  scaleDescription: "टेस्ट स्केल के अनुसार परिणामों का विवरण",
  recommendedEducationAreas: "शिक्षा के रेकमेंडेड क्षेत्र",
  enterYourAge: "अपनी आयु दर्ज करें",
  enterYourGender: "अपना लिंग दर्ज करें",
  chooseYourCurrentLevelOfEducation: "अपनी शिक्षा का वर्तमान स्तर सेलेक्ट करें",
  chooseYourAreasOfInterestHobbies: "अपनी रूचि के क्षेत्र और हॉबी सेलेक्ट करें",
  chooseYourSportingAchievements: "अपनी खेल उपलब्धियां सेलेक्ट करें",
  legalizationOfDocuments: "डाक्यूमेंट्स का लीगलाइज़ेशन",
  consularLegalization: "कोंसलर लीगलाइज़ेशन",
  edit: "एडिट करें",
  mobilePhoneNumber: "मोबाइल फ़ोन नंबर",
  iWantToReceiveNotificationsByEmail:
    "मैं e-mail द्वारा नोटिफ़िकेशन्स प्राप्त करना चाहता हूँ",
  authorizationCheck: "साइन इन की जांच",
  createANewApplication: "नया आवेदन डालें",
  selectionStage: "सेलेक्शन का चरण",
  underRevision: "कम्पलीशन पर",
  fillingOutAnApplication: "आवेदन भरने की प्रक्रिया",
  verificationAndRevision: "जांच और कम्पलीशन",
  dossierFormation: "डोसियर निर्माण",
  fillingInTheQuestionnaire: "प्रश्नावली भरें",
  attachingDocuments: "डाक्यूमेंट्स अपलोड करें",
  sendingApplicationForVerification: "आवेदन सत्यापन के लिए भेजें",
  personalData: "पर्सनल डाटा",
  surnameInLatinLettersAccordingToPassport:
    "उपनाम, लैटिन अक्षरों में (पासपोर्ट के अनुसार)",
  nameInLatinLettersAccordingToPassport:
    "नाम (एक या एकाधिक), लैटिन अक्षरों में (पासपोर्ट के अनुसार)",
  middleNameIAnyCyrillicInRussianTranscription:
    "पेट्रोनिमिक (अगर हो तो), सिरिलिक लिपि में, रूसी ट्रांस्क्रिप्शन में",
  placeOfBirthAccordingToPassport: "जन्म स्थान (पासपोर्ट के अनुसार)",
  dateOfBirth: "जन्म तिथि",
  compatriotStatus: "नेशनल स्टेटस",
  citizenshipPlaceOfPermanentResidence: "नागरिकता (स्थायी निवास स्थान)",
  online: "ऑनलाइन",
  profile2: "प्रोफ़ाइल",
  goToTheApplicationForm: "आवेदन भरने पर जाएं",
  areYouHere: "आप यहाँ हैं",
  russianTextDes1:
    "प्री-यूनिवर्सिटी फैकल्टी किस लिए होती हैं, उनमें क्या पढ़ाया जाता हैं, उनमें कितने समय तक पढ़ते हैं और उनमें पढ़ने का शुल्क कितना होता है",
  russianTextDes4:
    "रूसी भाषा व्यक्तिगत रूप से पढ़ने के लिए शिक्षक कहाँ और कैसे ढूंढें",
  russianTextDes5:
    "रूसी विश्वविद्यालय में प्रवेश लेने के लिए रूसी भाषा ज्ञान के स्तर की पुष्टि कैसे और किस लिए करें। सर्टिफिकेट प्राप्त करने के लिए टेस्ट में भाग लेने के बारे में सब कुछ जानें",
  typeOfProgram: "पाठ्यक्रम का प्रकार",
  chooseTheTypeOfAdditionalProgram: "सप्लीमेंट्री पाठ्यक्रम का प्रकार चुनें",
  durationOfStudy: "अध्ययन की अवधि",
  common: "सामान्य",
  inYourCountry: "आपके देश में",
  courseName: "कोर्स का नाम",
  courseDuration: "कोर्स की अवधि",
  classDuration: "लेक्चर की अवधि",
  courseCost: "कोर्स का शुल्क",
  courseReviews: "कोर्स के बारे में फ़ीडबैक",
  male: "पुल्लिंग",
  female: "स्त्रीलिंग",
  feedbackLink: "फ़ीडबैक का लिंक",
  russianLanguageCoursesInYourCountry: "आपके देश में रूसी भाषा के कोर्सेज",
  evaluation: "मूल्यांकन",
  giveFeedback: "फ़ीडबैक लिखें",
  newFeedback: "नया फ़ीडबैक",
  _hour: "घंटा",
  _hours: "घंटे",
  __hours: "घंटे",
  _minute: "मिनट",
  _minutes: "मिनट",
  __minutes: "मिनट",
  create: "क्रिएट करें",
  feedbackSuccess:
    "फ़ीडबैक सफलतापूर्वक भेजी जा चुकी है और मोडरेशन की प्रतीक्षा में है",
  _y: "सन्",
  _from: "from",
  error: "एरर",
  direction: "मुख्य विषय",
  _tutor: "ट्यूटर",
  _tutors: "ट्यूटर",
  __tutors: "ट्यूटरों",
  filters: "फिल्टर्स",
  sendMessage: "संदेश भेजें",
  sendMessageSuccess: "संदेश सफलतापूर्वक भेजा जा चुका है",
  _year: "वर्ष",
  _years: "वर्ष",
  __years: "वर्ष",
  _back: "पीछे",
  _next: "आगे जाएं",
  showing: "दिखाएं",
  textRussianLanguageCourses:
    "मेन्यू में अपने निवास का देश सेलेक्ट करें और उपलब्ध रूसी भाषा कोर्सेज और स्कूल देखें। अपने देश में ही रूसी भाषा की बेसिक नॉलिज प्राप्त करने से आप रूस में जल्दी और आसानी से एडजेस्ट हो जाएंगे।",
  tOP10Programs: "टॉप-10 पाठ्यक्रम",
  tOP10City: "टॉप-10 रूस के शहर",
  createANewTopic: "नया टॉपिक क्रिएट करें",
  topics: "टॉपिक",
  posts: "संदेश",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    ".pdf, .jpg, .png फॉर्मेट की फाइलें, 10 Mb तक",
  noFileSelected: "कोई फाइल सेलेक्ट नहीं की गई",
  attachFile: "फाइल संलग्न करें",
  eMail: "E-mail",
  fullTime: "फुल-टाइम",
  toFind: "खोजें",
  searchInTopic: "टॉपिक में खोजें",
  lessThan1Month: "1 महीने से कम",
  "1To3Months": "1 से 3 महीने तक",
  "3To6Months": "3 से 6 महीने तक",
  "6To12Months": "6 से 12 महीने तक",
  moreThan1Year: "1 साल से ज्यादा",
  theDateOfThe: "कंडक्ट कराने की तिथि",
  toAdminPanel: "एडमिन पैनल में जाएं",
  support: "तकनीकी सहायता",
  faq: "FAQ",
  userGuide: "उपयोगकर्ता पुस्तिका",
  textUserGuide: "पोर्टल उपयोग करने की गाइड और एडमिशन के लिए आवेदन करने के दिशा-निर्देश",
  textRegisterGuide: "विदेशियों के लिए रूस में शिक्षा वेबसाइट पर साइन अप करें",
  textTrainingGuide: "विदेशियों के लिए रूस में शिक्षा वेबसाइट पर एडमिशन के लिए आवेदन करें",
  textApplication: "प्रश्नावली भरें",
  instructionForWork: "आवेदन करने से संबंधित दिशा-निर्देश",
  appeals: "अपील",
  handling: "एप्लीकेशंस",
  __students: "छात्र",
  _student: "छात्र",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "अपने मुख्य विषयों का समूह तय करें और अपने भविष्य के प्रोफेशन के संभावित विकल्पों का पता लगाएं",
  delTheme: "टॉपिक डिलीट करें",
  competitionForTheFirstPlace: "रशियन फेडरेशन के कोटे के अंतर्गत 1 सीट (व्यक्ति)के लिए प्रतियोगिता परीक्षा",
  ruble: "रूबल",
  __rubles: "रूबल",
  dateOfEvent: "संचालन की तिथि",
  durationUnit: "महीना",
  numberPhone: "फ़ोन नंबर",
  offline: "ऑफलाइन",
  to_begin: "शुरुआत में जाएं",
  theDateOfThe: "आरंभ करने की तिथि",
  chat: "चैट बॉट",
  yourMessageChat: "आपका मैसेज",
  searchChat: "संवाद के अनुसार खोज",
  firstMsgChat: "मैसेज की विषय वस्तु दर्ज करें",
  categoryBtnChat_1: "आवेदन करने से सम्बंधित प्रश्न",
  categoryBtnChat_2: "शिक्षा से सम्बंधित प्रश्न",
  categoryBtnChat_3: "प्रतियोगिता परीक्षा से सम्बंधित प्रश्न",
  categoryBtnChat_4: "सुझाव या टिप्पणी",
  categoryBtnChat_5: "विश्वविद्यालय की गतिविधियों के विषय में फीडबैक",
  categoryBtnChat_6: "सेवाओं की गुणवत्ता के बारे में शिकायत",
  categoryBtnChat_7: "तकनीकी सहायता हेतु संपर्क",
  notQuestionResolvedChat: "मेरी समस्या हल नहीं हुई",
  questionResolvedChat: "मेरी समस्या हल हो गयी है",
  countrySelectTrueChat: "देश सही दर्ज किया गया है",
  salutationChat: "नमस्कार।",
  categoryAppealChat: 'जानकारी प्राप्त करने के लिए उस केटेगरी को दबाएं जिससे आपका प्रश्न संबंधित है।. कैटेगरी चुनने के बाद मैसेज में अपना प्रश्न दर्ज करें और "सबमिट" बटन दबाएं।',
  langAppealChat: "आप जो आवेदन भेजना चाहते हैं, उनकी भाषा चुनें।. ध्यान दें, जो आवेदन रूसी और अंग्रेजी में भाषा में होते हैं, उनकी प्रोसेसिंग जल्दी की जाती है।",
  youCountryChatPath1: "आपका देश",
  youCountryChatPath2: "अगर नहीं, तो कृपया रिप्लाई मैसेज में देश का नाम दर्ज करें।",
  youCountryChat: "आपका देश {country}है? अगर नहीं, तो कृपया रिप्लाई मैसेज में देश का नाम दर्ज करें।",
  notFoundCountryChat: "आपके द्वारा दर्ज नाम का कोई देश नहीं मिला। कृपया दर्ज किया गया डाटा जाँच लें और मैसेज दोबारा प्रेषित करें।",
  additionalInfoChat: "कृपया रिप्लाई मैसेज में आपके आवेदन से सम्बंधित अतिरिक्त जानकारी उपलब्ध कराएं।",
  yourEmailChat: "कृपया अपना ईमेल एड्रेस दर्ज करें। आपके आवेदन से सम्बंधित जवाब ईमेल द्वारा भेजा जाएगा।",
  notValidEmailChat: "आपने जो ईमेल अड्रेस दर्ज किया है, वह aaa@bbb.ccc प्रारूप के अनुसार नहीं है। कृपया दर्ज किया गया डाटा जाँच लें और मैसेज दोबारा प्रेषित करें।",
  acceptRequestChat: "आपकी एप्लीकेशन ऑपरेटर को प्रोसेसिंग के लिए भेज दी गई है। एप्लीकेशन प्रोसेसिंग की औसत अवधि 7 कैलेंडर दिनों की होती है।",
  notFoundEmployeeChat: "आपने जो देश दर्ज किया है, वह उन देशों की सूची में नहीं है, जिनके लिए रोस्सोत्रूद्निचेस्त्वो के कार्यक्रम के तहत शिक्षा का अवसर उपलब्ध है। आपका आवेदन समीक्षा के लिए फॉरवर्ड नहीं किया जा सकता। कृपया कोई दूसरा देश दर्ज करें। पाठ्यक्रमों की पूरी सूची देखने के लिए कृपया <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a> पेज पर जाएं ",
  langBtnChat_1: "अंग्रेज़ी",
  langBtnChat_2: "रूसी",
  langBtnChat_3: "अन्य",
  addAppBtn: "आवेदन करें",
  listAppBtn: "आवेदनों की सूची",
  privacyPolicy: "गोपनीयता नीति",
  ___programs: "पाठ्यक्रम",
  ___educationalPrograms: "शैक्षिक पाठ्यक्रम",
  __educationalPrograms: "शैक्षिक पाठ्यक्रम",
  editTopic: "टॉपिक एडिट करें",
  editMessage: "मैसेज एडिट करें",
  deleteMessage: "मैसेज डिलीट करें",
  blockingUser: "यूज़र को ब्लॉक करें",
  blockEndDate: "ब्लॉक होने की अवधि की समाप्ति तिथि",
  blockEndTime: "ब्लॉक होने की अवधि की समाप्ति का समय",
  blockForever: "हमेशा के लिए ब्लॉक करें",
  block: "ब्लॉक करें",
  unlock: "अनब्लॉक करें",
  edited: "एडिट किया जा चुका है",
  founder: "संस्थापक",
  abbreviation: "संक्षिप्त रूप",
  studentsByCountry: "देशों के सेक्शन में छात्रों की संख्या",
  studentsByCountryCount: "पढ़ने वाले छात्रों की संख्या",
  contactsInternationalDepartment: "अंतर्राष्ट्रीय विभाग की कांटेक्ट डिटेल्स",
  site: "साइट",
  address: "एड्रेस",
  format: "फॉर्मेट",
  typeEvent: "कार्यक्रम का प्रकार",
  quotaLearning: "कोटा के अंतर्गत एडमिशन",
  kcp: "एडमिशन कोटा",
  countSeatsKCP: "एडमिशन कोटा के अंतर्गत सीटों की संख्या",
  countSeatsCostTraining: "पेड एजुकेशन के लिए सीटों की संख्या",
  distanceLearning: "दूरस्थ शिक्षा",
  notificationAboutDistanceLearning: "पाठ्यक्रम के अंतर्गत पढ़ाई ई-लर्निंग और दूरस्थ शिक्षा प्रौद्योगिकियों के माध्यम से कराई जा सकती है। अधिक जानकारी के लिए विश्वविद्यालय से संपर्क करें।",
  additionalTests: "अतिरिक्त क्वालीफाइंग टेस्ट्स की तिथियां",
  pageNotFound: "पेज नहीं मिला",
  goToMainPage: "होम पेज पर जाएं",
  faqInformation: "आवेदन सत्यापन की स्थिति, आवश्यक दस्तावेजों की सूची, क्वालीफाइंग टेस्ट्स के आयोजन का समय, और साथ ही प्रवेश प्रक्रिया से सम्बंधित सभी प्रश्नों को लेकर अपने देश में रोस्सोत्रूद्निचेस्त्वो के प्रतिनिधि कार्यालय अथवा रशियन फेडरेशन के दूतावास से संपर्क करें, क्योंकि उन्हें आपके देश में चयन प्रक्रिया कराने का अधिकार प्राप्त है। रोस्सोत्रूद्निचेस्त्वो के कर्मचारियों की कांटेक्ट डिटेल्स अपने पर्सनल अकाउंट में देखें।",
  allEvents: "सभी कार्यक्रम",
  eventsAndNews: "कार्यक्रम और समाचार",
  titleNews: "समाचार का नाम",
  numberOfBudgetPlaces: "स्टेट-फंडेड सीटों की संख्या",
  russianGovernmentQuota: "रशियन फेडरेशन सरकार का कोटा",
  legalAddress: "लीगल पता",
  actualAddress: "वास्तविक पता",
  goToTheRegister: "अतिरिक्त पाठ्यक्रमों की सूची में जाएं",
  searchForSuitablePrograms: "उपयुक्त कार्यक्रमों की खोज करें",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "अभ्यर्थियों द्वारा चुने जाने वाले सबसे लोकप्रिय पाठ्यक्रम",
  theMostPopularCitiesOfRussiaChosenByApplicants: "अभ्यर्थियों द्वारा चुने जाने वाले रूस के सबसे लोकप्रिय शहर",
  ofTheCountry: "देश से",
  passed: "पूरा किया गया है",
  question: "प्रश्न",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "पोर्टल प्रायोगिक संचालन के मोड में काम करता है!",
  banner_2: "प्रिय उपयोगकर्ताओं, इस समय, Google मेल का उपयोग करते समय तकनीकी कठिनाइयाँ हो सकती हैं। पोर्टल के साथ काम करते समय कृपया अन्य मेल सेवाओं का उपयोग करें।.",
  banner_3: "छात्रवृत्ति प्रवेश अभियान की शुरुआत 24 दिसंबर 2022 से.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
