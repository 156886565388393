<template>
  <div class="universities__info">
    <div class="row">
      <div class="col-6">
        <h2 class="title-2">
          {{ $t('olympics') }}
        </h2>

        <table class="table var2 news-table-full" data-row-select=odd>
          <thead>
          <tr>
            <th>{{ $t('olympiadName') }}</th>
            <th>{{ $t('description') }}</th>
            <th>{{ $t('dateOfEvent') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(olympiad, key) in olympiads"
            v-bind:key="key"
          >
            <td><a class="link" :href="olympiad.website">{{ olympiad | lang('name') }}</a></td>
            <td>{{ olympiad | lang('description') }}</td>
            <td>{{ olympiad.start_date | date }} - {{ olympiad.end_date | date }}</td>
          </tr>
          </tbody>
        </table>
        <div class="news-tables">
          <div
            class="news-table"
            v-for="(olympiad, key) in olympiads"
            v-bind:key="key"
          >
              <div class="news-table-title">{{ $t('olympiadName') }}</div>
              <div class="news-table-content"><a class="link" :href="olympiad.website">{{ olympiad | lang('name') }}</a></div>
              <div class="news-table-title">{{ $t('description') }}</div>
              <div class="news-table-content">{{ olympiad | lang('description') }}</div>
              <div class="news-table-title">{{ $t('dateOfEvent') }}</div>
              <div class="news-table-content">{{ olympiad.start_date | date }} - {{ olympiad.end_date | date }}</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default ({
    name: 'olympiads-university',
    props: {
      olympiads: Array,
    },
  })
</script>
