<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">

    <div class="university__page-container">

      <div class="main__info-title">
        <div
          class="main__info-title__img"
          :style="`background-image: url(${require('@/assets/images/bg-cities.jpg')});`"
        ></div>

        <div class="container">
          <Breadcrumbs
            :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('lifeInRussia') },
              { title: $t('citiesOfRussia') },
            ]"
          />

          <h1 class="title-1">{{ $t('citiesOfRussia') }}</h1>
        </div>
      </div>
      <div class="container mb64">
        <div class="row row-32">
          <div class="col-3">
            <h5 class="title-content">
              {{ $t('onTheMap') }}
            </h5>

            <div class="map-small">
              <Openstreetmap :height="150" :markers="markers" />
            </div>
          </div>

          <div class="col-3">
            <h5 class="title-content">{{ $t('cityFeatures') }}</h5>

            <form class="tags" @click="updateButton">
              <InputRadio
                :element-id="`checkbox${tag.id}`"
                :store-path="`filter.cities.cloudTag`"
                type="radio"
                :value="tag.id.toString()"
                v-for="(tag, key) in citiesCloudTags"
                v-bind:key="key"
                label-class="tags__item"
                allow-reset
              >
                <template slot="body"
                  ><span>{{ tag | lang('name') }}</span></template
                >
              </InputRadio>
            </form>
          </div>
        </div>

        <div class="search-row">
          <Input
            element-class="txt"
            :placeholder="$t('enterTheNameOfTheCity')"
            store-path="filter.cities.search"
            type="text"
            :value="((forms.filter || {}).cities || {}).search || ''"
          />

          <Button
            type="button"
            element-class="btn btn-search"
            :callback="getData"
          ></Button>
        </div>

        <div 
          class="blocks__dooble" 
        >
        <template  v-if="hideExcept === null || hideExcept === city.id">
          <CitySearchItem
            :data="city"
            v-for="(city, key) in citesList.data"
              v-bind:key="key"
          />
          </template>
        </div>

        <Pagination
          v-if="!hideExcept"
          :count="citesList.last_page"
          :callback-on-change="getData"
          pagination-model="pagination.cites"
          :last-data-count="citesList.data.length"
          :show-list-variants="[10, 20, 50, 70, 100]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '@/components/generic/pagination/Pagination.vue'
import Breadcrumbs from '@/components/generic/Breadcrumbs'
import CitySearchItem from '@/components/site/pages/lifeInRussia/cities/generic/CitySearchItem'
import Input from '@/components/generic/form/field/Input'
import InputRadio from '@/components/generic/form/field/InputRadio'
import Button from '@/components/generic/form/Button'
import Openstreetmap from '@/components/site/pages/lifeInRussia/cities/generic/Openstreetmap'

export default {
  name: 'life-in-russia-cities',

  components: {
    Pagination,
    Breadcrumbs,
    CitySearchItem,
    InputRadio,
    Input,
    Button,
    Openstreetmap,
  },

  data() {
    return {
      citiesCloudTags: [],
      markers: [],
      hideExcept: null,
      preloader: false,
    }
  },

  computed: {
    ...mapGetters({
      pagination: 'getPagination',
      citesList: 'lifeInRussia/getCitesList',
      forms: 'form/forms',
    }),

    formCloudTags: store =>
      store.$store.getters['form/getField']('filter.cities.cloudTags'),
  },

  methods: {
    ...mapActions({
      getCities: 'lifeInRussia/getCities',
      getCitiesCloudTags: 'lifeInRussia/getCitiesCloudTags',
    }),


    getData() {
      this.$store.commit('preloader/isLoading', true)
      this.getCities({
        name: this.forms.filter.cities.search,
        cloudTags: this.forms.filter.cities.cloudTag ? [this.forms.filter.cities.cloudTag] : null,
        perPage: _.get(this.pagination, 'cites.length', 10),
        page: _.get(this.pagination, 'cites.page', null),
      })
        .then(res => {
          this.$store.commit('lifeInRussia/updateParam', {model: 'cites', value: res});
          this.markers = [];

          _.map(this.citesList.data, city => {
            this.markers.push({
              city: city,
              id: city.id,

              markerCallback: id => {
                this.hideExcept = (id === this.hideExcept && this.hideExcept !== null) ? null : id
              },

              coordinate: [city.lat, city.lng],
            })
          })
        })
        .finally(() => this.$store.commit('preloader/isLoading', false))
    },

    updateButton() {
      this.$store.commit('updateParam', {model: 'pagination.cites.page', value: 1});
      this.$store.commit('updateParam', {model: 'pagination.cites.length', value: 10});
      this.$forceUpdate();
    },
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getData()

    this.getCitiesCloudTags().then(res => {
      this.citiesCloudTags = res
    })
  },

  watch: {
    forms() {
      this.getData()
    },
  },
}
</script>
