export default {
    namespaced: true,
    
    state: {
        preloader:  true,
    },

    getters: {
        preloader: state => {
            return state.preloader;
        },
        
    },

     mutations: {
        isLoading(state, payload) {
            return state.preloader = payload
        },
    },
};
