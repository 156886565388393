import Vue from 'vue';
import VueRouter from 'vue-router';
import Reasons from '@/views/why-rus/Reasons.vue';
import Certification from '@/views/education-in-rus/Сertification.vue';

// New
import Home from '@/components/site/pages/Home';
import Logout from '@/components/site/pages/Logout.vue';
import Dynamic from '@/components/site/pages/Dynamic';
import Login from '@/components/site/pages/account/Login';
import Help from '@/components/site/pages/help/Help';
import Faq from '@/components/site/pages/help/Faq';
import UserGuide from '@/components/site/pages/help/UserGuide';
import Feedback from '@/components/site/pages/help/Feedback';
import Cities from '@/components/site/pages/lifeInRussia/cities/Cities';
import City from '@/components/site/pages/lifeInRussia/cities/City.vue';
import CareerTests from '@/components/site/pages/educationInRussia/career/CareerTests';
import Universities from '@/components/site/pages/educationInRussia/universities/Universities';
import University from '@/components/site/pages/educationInRussia/universities/University';
import Programs from '@/components/site/pages/educationInRussia/programs/Programs';
import Program from '@/components/site/pages/educationInRussia/programs/Program';
import Russian from '@/components/site/pages/educationInRussia/russian/Russian';
import Tutors from '@/components/site/pages/educationInRussia/russian/tutors/Tutors';
import Monitoring from '@/components/site/pages/educationInRussia/Monitoring';
import ChoiceOfProfession from '@/components/site/pages/educationInRussia/ChoiceOfProfession';
import MyCareer from '@/components/site/pages/educationInRussia/MyCareer';
import Reviews from '@/components/site/pages/whyRussia/Reviews.vue';
import StudentReview from '@/components/site/pages/whyRussia/StudentReview.vue';
import LegalizationDocuments from '@/components/site/pages/account/LegDoc.vue';
import Favorites from '@/components/site/pages/account/Favorites';
import Choices from '@/components/site/pages/account/Choices';
import News from '@/components/site/pages/news/News';
import NewsSingle from '@/components/site/pages/news/NewsSingle';
import Event from '@/components/site/pages/events/Event';
import CalendarOfEvents from '@/components/site/pages/events/CalendarOfEvents';
import Forum from '@/components/site/pages/help/forum/Forum';
import ForumThemes from '@/components/site/pages/help/forum/ForumThemes';
import ForumThemeMessages from '@/components/site/pages/help/forum/ForumThemeMessages';
import ForumMyMessage from '@/components/site/pages/help/forum/ForumMyMessage';
import store from '@/store';
import NotFound from '@/views/not-found/NotFound';
import Events from '@/components/site/pages/events/Events';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  const token = localStorage.getItem('userToken')
  if (typeof next === 'function') {
    if (store.state.users.isAuthorized && !((store.state.users || {}).user || {}).id) {
       return store.dispatch('getUser').then(res => {
        if (res.status === 401 && !token) {
          store.commit('users/logout', true);
        }

         next();
      }).catch(() => {
         store.commit('users/logout', true);

         if (to.meta.requiresAuth) {
           next('/account/login');
         } else {
           next();
         }
       });
    }

    if (to.meta.requiresAuth && !((store.state.users || {}).user || {}).id) {
      next('/account/login');
    }

    next();
  }

  if (typeof next === 'function') {
    next();
  }
};

const routes = [
  {
    path     : '/',
    name     : 'Home',
    component: Home,
    beforeEnter: ifNotAuthenticated,
  },
  
  {
    path     : '/logout',
    name     : 'Logout',
    component: Logout,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/help',
    name     : 'Help',
    component: Help,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/help/faq',
    name     : 'help-faq',
    component: Faq,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path     : '/help/user-guide',
    name     : 'user-guide',
    component: UserGuide,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path     : '/help/feedback',
    name     : 'help-feedback',
    component: Feedback,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/life-in-russia/cities',
    name     : 'life-in-russia-cities',
    component: Cities,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/life-in-russia/cities/:name',
    name     : 'life-in-russia-cities-city',
    component: City,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/universities',
    name     : 'education-in-russia-universities',
    component: Universities,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/universities/:name',
    name     : 'education-in-russia-universities-university',
    component: University,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/programs',
    name     : 'education-in-russia-universities-programs',
    component: Programs,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/russian',
    name     : 'education-in-russia-russian',
    component: Russian,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/russian/tutors',
    name     : 'education-in-russia-russian-tutors',
    component: Tutors,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/programs/:idProgram',
    name     : 'education-in-russia-programs-program',
    component: Program,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/monitoring',
    name     : 'education-in-russia-monitoring',
    component: Monitoring,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/choice-of-profession',
    name     : 'education-in-russia-choice-of-profession',
    component: ChoiceOfProfession,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/choice-of-profession/career-tests',
    name     : 'education-in-russia-choice-of-profession-my-career-tests',
    component: CareerTests,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/education-in-russia/choice-of-profession/my-career',
    name     : 'education-in-russia-choice-of-profession-my-career',
    component: MyCareer,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/why-russia/reviews',
    name     : 'why-russia-reviews',
    component: Reviews,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account/login',
    name     : 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account/registration',
    name     : 'Login',
    component: Login,
    redirect: '/account/login',
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account/legalization-documents',
    name     : 'LegalizationDocuments',
    component: LegalizationDocuments,
    meta: {requiresAuth: true},
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account/favorites',
    name     : 'Favorites',
    component: Favorites,
    meta: {requiresAuth: true},
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account/choices',
    name     : 'Choices',
    component: Choices,
    meta: {requiresAuth: true},
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/account',
    beforeEnter: () => {
      window.location.href = '/settings/profile';
    },
  },
  
  {
    path     : '/events',
    name     : 'events',
    component: Events,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/event/:id',
    name     : 'events-event',
    component: Event,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/calendar-of-events',
    name     : 'CalendarOfEvents',
    component: CalendarOfEvents,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/news',
    name     : 'news',
    component: News,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/news/:slug',
    name     : 'news-single',
    component: NewsSingle,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path     : '/why-russia/reviews/:id',
    name     : 'why-russia-student-review',
    component: StudentReview,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/help/forum',
    name: 'help-forum',
    component: Forum,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/help/forum/themes/:slug',
    name: 'help-forum-themes',
    component: ForumThemes,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/help/forum/themes/messages/:slug',
    name: 'help-forum-theme-messages',
    component: ForumThemeMessages,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/help/forum/my-messages',
    name: 'help-forum-themes-my-message',
    component: ForumMyMessage,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '*',
    name: 'not-found-page',
    component: NotFound,
  },

  // этот роут всегда должен быть в самом низу
  {
    component: Dynamic,
    name     : 'dynamic',
    path     : '/:category/:categorySub/:slug',
    props    : true,
    beforeEnter: ifNotAuthenticated,
  },

  {
    component: Dynamic,
    name     : 'dynamic',
    path     : '/:category/:slug',
    props    : true,
    beforeEnter: ifNotAuthenticated,
  },

  // Views
  {
    path     : '/certification',
    name     : 'Certification',
    component: Certification,
  },

  {
    path     : '/reasons',
    name     : 'Reasons',
    component: Reasons,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
