export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Ajuda",
  personalArea: "Área de cliente",
  myApplications: "Meus pedidos",
  servicesAndFacilities: "Serviços e apoio",
  logout: "Sair",
  favorites: "Favoritos",
  theChoice: "Seleção",
  notifications: "Notificações",
  logIn: "Fazer login",
  quickAccessThroughSocialNetworks: "Login rápido via redes sociais",
  enterYourEmail: "Digite o seu endereço de e-mail",
  password: "Senha",
  enterPassword: "Digite a sua senha",
  rememberMe: "Lembrar-se de mim",
  forgotYourPassword: "Esqueceu a sua senha?",
  noAccount: "Não tem conta?",
  registration: "Inscrição",
  countryOfCitizenship: "Nacionalidade",
  chooseYourCountryOfCitizenship: "Selecione a nacionalidade",
  iHaveReadAndAgree: "Eu li e dou o meu",
  _toTheProcessingOfMyPersonalData:
    "consentimento para processamento dos meus dados pessoais",
  iAgreeTo: "Eu autorizo",
  _theUseOfCookies: "o uso de cookies",
  signUp: "Inscrever-se",
  haveAnAccount: "Já tem uma conta?",
  mainPage: "Página inicial",
  frequentlyAskedQuestionsAnswers: "Respostas a perguntas frequentes",
  goTo: "Ir para",
  startTesting: "Iniciar testes",
  buildCareerVector: "Construir vetor-plano de carreira",
  forum: "Fórum",
  textForumBanner:
    "Discuta no fórum comum todos os tópicos que são importantes para si: como é a vida nas cidades da Rússia, como é o ensino nas universidades russas, etc.",
  feedback: "Feedback",
  technicalSupportFeedbackForm: "Formulário de feedback para suporte técnico",
  allCities: "Todas as cidades",
  citiesOfRussia: "Cidades da Rússia",
  educationInRussia: "Estudar na Rússia",
  educationalOrganizations: "Universidades",
  educationInRussiaYourKeyToSuccess:
    "Ensino na Rússia é a sua chave para o sucesso",
  applyForStudyInRussia: "Apresente um pedido para estudar na Rússia",
  apply: "Solicitar",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Faça o login ou inscreva-se para aceder a orientação profissional e à apresentação do pedido",
  aboutOrganization: "Sobre a organização",
  eventsAndOlympiads: "Eventos e olimpíadas",
  educationalPrograms: "Programas",
  contacts: "Contactos",
  inFavorites: "Em favoritos",
  toFavorites: "Para favoritos",
  fromChoices: "Selecionado",
  choices: "Selecionar",
  generalInformation: "Informações gerais",
  internationalRatings: "Avaliações internacionais",
  nationalRatings: "Avaliações nacionais",
  ratings: "Avaliações",
  year: "Ano",
  ratingName: "Designação da avaliação",
  aPlace: "Localização",
  screeningTests: "Testes de seleção",
  choiceOfProfession: "Escolha de uma profissão",
  lifeInRussia: "Vida na Rússia",
  russianLanguage: "Língua russa",
  livingConditions: "Condições de vida",
  cultureAndLeisure: "Cultura e lazer",
  safety: "Segurança",
  benefitsForInternationalStudents: "Benefícios para estudantes internacionais",
  workForInternationalStudents: "Empregos para estudantes internacionais",
  studentVisaAndRegistration: "Visto de estudo e registo",
  healthInsurance: "Seguro de saúde",
  howToEnter: "Como ingressar",
  educationLevels: "Níveis de ensino",
  admissionProcess: "Processo de ingresso",
  olympics: "Olimpíadas",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Site oficial da seleção de candidatos estrangeiros para estudar na Rússia no ano de {year}",
  whyRussia: "Por que a Rússia",
  "10reasonsToStudyInRussia": "10 razões para estudar na Rússia",
  reviewsOfForeignStudents: "Feedback dos estudantes internacionais",
  onlineMonitoring: "Monitorização on-line",
  mainPrograms: "Programas principais",
  additionalPrograms: "Programas adicionais",
  chooseCounty: "Escolha o distrito",
  county: "Distrito",
  chooseACity: "Selecione a cidade",
  city: "Cidade",
  chooseYourLevelOfEducation: "Selecione o nível de ensino",
  chooseYourAreaOfScientificKnowledge: "Escolha uma área do conhecimento científico",
  chooseYourScientificSpecialtyProfile: "Escolha um perfil de uma especialidade científica",
  areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Nível de ensino",
  chooseAGroupOfTrainingAreas: "Selecione um grupo de especialidades",
  trainingDirectionGroup: "Grupo de especialidades",
  chooseATrainingDirection: "Escolha a sua especialidade",
  trainingDirection: "Especialidade",
  chooseRatings: "Selecione avaliações",
  thePresenceOfThePreparatoryFaculty: "Existência de curso preparatório",
  show: "Mostrar",
  _university: "universidade",
  _universities: "universidades",
  __universities: "universidades",
  clearAllFilters: "Reiniciar todos os filtros",
  chooseSemester: "Selecione o semestre",
  semester: "Semestre",
  enterTheNameOfTheEducationalOrganization: "Digite o nome da universidade",
  _educationalOrganization: "universidade",
  _educationalOrganizations: "universidades",
  __educationalOrganizations: "universidades",
  chooseYourAreaOfEducation: "Selecione a área de formação",
  areaOfEducation: "Área de formação",
  chooseYourLanguageOfInstruction: "Selecione o idioma de ensino",
  languageOfInstruction: "Idioma de ensino",
  _program: "programa",
  _programs: "programas",
  __programs: "programas",
  formOfTraining: "Modalidade de ensino",
  level: "Nível",
  typeOfTraining: "Tipo de ensino",
  free: "Grátis",
  paid: "Pago",
  tuitionFees: "Mensalidade",
  established: "Data de fundação",
  numberOfStudents: "Número de alunos",
  _students: "alunos",
  numberOfQuotaPlaces: "Número de vagas da quota",
  _for: "para",
  numberOfInternationalStudents: "Estudantes estrangeiros",
  _educationalPrograms: "de programas de ensino",
  moreDetails: "Saber mais",
  sTEP: "PASSO",
  afterTrainingForThisProgramYouCanBecome:
    "Após essa formação académica, você poderá ser",
  chooseProgram: "Selecionar currículo",
  chooseUniversity: "Selecionar universidade",
  print: "Imprimir",
  save: "Salvar",
  programs: "Programas",
  selectedProgram: "Currículo selecionado",
  noProgramSelected: "Currículo não selecionado",
  selectedEducationalOrganizations: "Universidades selecionadas",
  educationalOrganizationNotSelected: "Universidade não selecionada",
  representation: "Representação",
  programDescription: "Descrição da matriz curricular",
  programPageOnTheUniversityWebsite:
    "Página da matriz curricular no site da universidade",
  programCurator: "Curador do programa",
  disciplines: "Disciplinas",
  theProcessOfEnteringARussianEducationalOrganization:
    "Processo de ingresso numa universidade russa",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Selecione a matriz curricular e até 6 universidades",
  takeTheCompetitiveSelectionInYourCountry:
    "Participe no concurso de seleção no seu país",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Preencha o formulário de solicitação on-line e anexe o conjunto de documentos",
  getAnInvitationToStudAnStudyVisa:
    "Receba um convite para estudar e um visto de estudante",
  preparatoryFaculties: "Departamentos preparatórios",
  summerAndWinterSchools: "Escolas de verão e de inverno",
  russianLanguageCourses: "Cursos de língua russa",
  tutorsTheRussianLanguage: "Explicadores de língua russa",
  certificationInRussian: "Certificação em língua russa",
  news: "Notícias",
  allNews: "Todas as notícias",
  reviewsOfStudentsAndGraduates: "Feedback de alunos e finalistas",
  areYouSureYouWantToWithdrawTheApplication:
    "Quer realmente cancelar a sua solicitação?",
  withdrawApplication: "Retirar o pedido",
  jan: "Janeiro",
  feb: "Fevereiro",
  mar: "Março",
  apr: "Abril",
  may: "Maio",
  jun: "Junho",
  jul: "Julho",
  aug: "Agosto",
  sep: "Setembro",
  act: "Outubro",
  nov: "Novembro",
  dec: "Dezembro",
  cityFeatures: "Características da cidade",
  enterTheNameOfTheCity: "Digite o nome da cidade",
  organizations: "Organizações",
  livingWage: "Nível de subsistência",
  "one-roomApartmentForRent": "Aluguer de apartamento com 1 quarto",
  _month: "meses",
  averageTemperature: "Temperatura média",
  _inWinter: "no inverno",
  _inSummer: "no verão",
  averageCostOfDormitories: "Custo médio da diária em residências académicas",
  photos: "Fotos",
  interestingPlaces: "Lugares interessantes",
  readCompletely: "Ler tudo",
  _review: "feedback",
  _reviews: "feedbacks",
  __reviews: "feedbacks",
  university: "Universidade",
  universities: "Universidades",
  country: "País",
  selectACountry: "Selecione o país",
  reviewOfAForeignStudent: "Feedback de estudante internacional",
  careerGuidance: "Orientação profissional",
  determineYourAreaOfEducationAndProfession:
    "Determine as áreas de ensino e profissões adequadas para si",
  myCareer: "A minha carreira",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Como combinar os estudos com lazer cultural e novos conhecidos. Características e custos dos programas intensivos",
  toApply: "Aplicar",
  chooseYear: "Selecione o ano",
  _in: "em",
  universitiesInThisCity: "Universidades nesta cidade",
  programsInThisCity: "Programas nesta cidade",
  all: "Todos",
  _inYourCountry: "no seu país",
  _common: "gerais",
  allReviews: "Todos os feedbacks",
  calendar: "Calendário",
  activity: "Atividades",
  addToPersonalCalendar: "Adicionar ao calendário pessoal",
  eventAdded: "Evento adicionado",
  eventSuccessfullyAddedToYourCalendar:
    "O evento foi adicionado com sucesso ao seu calendário!",
  population: "População",
  onTheMap: "No mapa",
  generalEducation: "Formação geral",
  professionalEducation: "Formação profissional",
  preschoolEducation: "Educação pré-escolar",
  kindergarten: "Jardim de infância",
  nursery: "Creche",
  earlyDevelopmentCenters: "Centros de desenvolvimento inicial",
  primaryAndSecondaryEducation: "Ensino primário, básico e secundário",
  schools: "Escolas",
  lyceums: "Liceus",
  gymnasium: "Colégios secundários",
  secondaryVocationalEducation: "Formação profissional secundária",
  colleges: "Colégios",
  technicalSchools: "Escolas técnicas",
  secondarySchool: "Escolas de formação profissional",
  higherEducation: "Ensino superior",
  baccalaureate: "Bacharelato",
  specialistDegree: "Licenciatura",
  magistracy: "Mestrado",
  postgraduateStudies: "Pós-graduação",
  preparatoryFaculty: "Curso preparatório",
  summerSchools: "Escolas de verão",
  additionalGeneralAndPreVocationalPrograms:
    "Programas de desenvolvimento geral e pré-profissionais adicionais",
  continuingEducationAndRetrainingPrograms:
    "Programas de desenvolvimento e requalificação profissional",
  whatAreTheLevelsOfRussianEducation: "Que níveis inclui o ensino russo?",
  enterYourName: "Digite o seu nome",
  name: "Nome",
  selectTheSubjectOfTheMessage: "Selecione o assunto da mensagem",
  messageSubject: "Assunto da mensagem",
  chooseAMessage: "Selecione a mensagem",
  message: "Mensagem",
  submit: "Enviar",
  frequentlyAskedQuestions: "Perguntas mais frequentes",
  didNotFindTheAnswerToYourQuestion:
    "Não encontrou resposta para a sua pergunta",
  contactSupportAt: "Entre em contacto com o serviço de apoio em",
  searchInTheSection: "Buscar em",
  sort: "Ordenar",
  searchingResults: "Resultados da busca",
  _answers: "respostas",
  _views: "visualizações",
  byCreationDate: "Pela data de criação",
  byTheNumberOfResponses: "Pelo número de respostas",
  byViews: "Pelo número de visualizações",
  toAnswer: "Responder",
  toQuote: "Citar",
  complain: "Reclamar",
  addNewMessage: "Adicionar outra mensagem",
  selectComplaintType: "Selecione o tipo de reclamação",
  typeOfComplaint: "Tipo de reclamação",
  enterComplaintText: "Digite o texto da reclamação",
  complaintText: "Texto da reclamação",
  complaintSentSuccessfully: "Reclamação enviada com sucesso",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "A sua reclamação foi enviada com sucesso, analisaremos a sua reclamação o mais rápido possível",
  close: "Fechar",
  myMessages: "As minhas mensagens",
  myTopicsAndPosts: "Os meus assuntos e mensagens",
  searchInMyPosts: "Pesquisar em minhas mensagens",
  _posts: "mensagens",
  feedbackOnEducationalOrganizations: "Feedbacks sobre universidades",
  posted: "Publicado",
  goToMessage: "Saltar para mensagem",
  _themes: "assuntos",
  themeTitle: "Nome do assunto",
  enterAThemeName: "Digite o assunto",
  cancel: "Cancelar",
  banUser: "Banir o utilizador?",
  areYouSure: "Tem a certeza?",
  toBan: "Banir",
  _logIn: "login",
  _or: "ou",
  _signUp: "inscrever-se",
  eventCalendar: "Calendário de eventos",
  trainingApplicationsFiled: "Pedidos de ensino apresentados",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Universidades mais escolhidas pelos candidatos",
  _of: "de",
  _whenApplying: "na entrega do pedido",
  tOP10EducationalOrganizations: "Top 10 das Universidades",
  collapse: "Minimizar",
  showAll: "Mostrar tudo",
  conditionsForForeigners: "Condições para estrangeiros",
  supportForInternationalStudents: "Apoio a estudantes internacionais",
  lifeOfForeignStudents: "O dia a dia dos estudantes internacionais",
  leisureAndSportingEvents: "Lazer e atividades desportivas",
  dormitories: "Residências",
  dormitoryCost: "Custo da residência",
  theNumberOfPeopleInTheRoom: "Número de pessoas por quarto",
  from: "De",
  _rubles: "rublos",
  _to: "até",
  _perMonth: "por mês",
  "2-3People": "Duas ou três pessoas por quarto",
  theInternationalCooperation: "Cooperação internacional",
  photoAndVideo: "Fotos e vídeos",
  eventTitle: "Nome do evento",
  location: "Localidade",
  date: "Data",
  enterYourRealMail: "Digite o seu endereço de e-mail atual",
  requiredField: "Campo de preenchimento obrigatório",
  minimumLengthCharacters: "Comprimento mínimo {length} caracteres",
  maximumLengthCharacters: "Comprimento máximo {length} caracteres",
  theFieldMustBeANumber: "O campo deve ser um número",
  onlyLettersAreAllowedInTheField: "O campo apenas admite letras",
  checkKeyboardLayout: "Verifique o layout do teclado",
  checkTheNumberYouEnteredIsCorrect:
    "Verifique se o número que você digitou está correto",
  olympiadName: "Nome da olimpíada",
  description: "Descrição",
  contactInformation: "Informações de contacto",
  majorForeignPartnerUniversities:
    "Principais universidades parceiras estrangeiras",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Oportunidades para aprendizagem da língua russa presencial e à distância na Rússia para pessoas em todo o mundo",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "É um explicador? Envie um pedido para publicação do seu perfil no site",
  submitApplication: "Enviar formulário",
  chooseATeachingExperience: "Selecione a experiência de ensino",
  teachingExperience: "Experiência de ensino",
  chooseLanguages: "Selecione os idiomas",
  knowledgeOfLanguages: "Conhecimento de idiomas",
  workExperience: "Tempo de trabalho",
  trainingLevel: "Nível de formação",
  durationOfOneLesson: "Duração de cada aula",
  possibleTrainingTime: "Prazo de ensino possível",
  theCosOfOneLesson: "Preço por aula",
  writeAMessage: "Escrever mensagem",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Poderá escrever a um explicador depois de se registar ou entrar no site",
  educationDocuments: "Documentos sobre habilitações académicas",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Já tem outra matriz curricular selecionada, se selecionar esta matriz, a matriz e as organizações selecionadas serão eliminadas.",
  limitIsExceeded: "Limite excedido",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Tem um máximo de 6 universidades para escolher.",
  _fieldsOfEducation: "áreas de ensino",
  _trainingAreas: "especialidades",
  _cities: "cidades",
  _languagesOfInstruction: "idiomas de ensino",
  _typesOfAdditionalPrograms: "tipos de matrizes curriculares adicionais",
  _semesters: "semestres",
  chooseDurationOfStudy: "Selecione a duração do ensino",
  _durationOfStudy: "da duração do ensino",
  enterTheNameOfTheEducationalProgram: "Digite o nome da matriz curricular",
  instruction: "Manual de instruções",
  profile: "Questionário",
  back: "Voltar",
  next: "Continuar",
  testReport: "Relatório dos resultados dos testes",
  testDate: "Data dos testes",
  duration: "Duração",
  memberName: "Nome do participante",
  age: "Idade",
  gender: "Sexo",
  currentLevelOfEducation: "Nível atual de ensino",
  areasOfInterestHobbies: "Áreas de interesse, passatempos",
  sportsAchievements: "Realizações desportivas",
  testScaleResults: "Resultados nas escalas de testes",
  scaleDescription: "Descrição dos resultados por escalas",
  recommendedEducationAreas: "Áreas de ensino recomendadas",
  enterYourAge: "Digite a sua idade",
  enterYourGender: "Digite o seu sexo",
  chooseYourCurrentLevelOfEducation: "Selecione o seu nível de ensino atual",
  chooseYourAreasOfInterestHobbies:
    "Selecione as suas áreas de interesse e passatempos",
  chooseYourSportingAchievements: "Selecione as suas conquistas desportivas",
  legalizationOfDocuments: "Legalização de documentos",
  consularLegalization: "Legalização consular",
  edit: "Editar",
  mobilePhoneNumber: "Número de telemóvel",
  iWantToReceiveNotificationsByEmail: "Quero receber notificações por e-mail",
  authorizationCheck: "Verificação da autorização",
  createANewApplication: "Crie um novo pedido",
  selectionStage: "fase de seleção",
  underRevision: "Em ajuste",
  fillingOutAnApplication: "Preenchimento do pedido",
  verificationAndRevision: "Verificação e ajuste",
  dossierFormation: "Compilação de dossiê",
  fillingInTheQuestionnaire: "Preenchimento do questionário",
  attachingDocuments: "Anexação de documentos",
  sendingApplicationForVerification: "Envio do pedido para verificação",
  personalData: "Dados pessoais",
  surnameInLatinLettersAccordingToPassport:
    "Apelido, em letras latinas (conforme o passaporte)",
  nameInLatinLettersAccordingToPassport:
    "Nome(s), em letras latinas (conforme o passaporte)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Patronímico (se houver), em cirílico na transcrição russa",
  placeOfBirthAccordingToPassport: "Naturalidade (conforme o passaporte)",
  dateOfBirth: "Data de nascimento",
  compatriotStatus: "Estatuto de compatriota",
  citizenshipPlaceOfPermanentResidence: "Nacionalidade (domicílio permanente)",
  online: "On-line",
  profile2: "Perfil",
  goToTheApplicationForm: "Proceder para o preenchimento do pedido",
  areYouHere: "Você está aqui",
  russianTextDes1:
    "Para que servem os cursos preparatórios, que disciplinas são aí ensinadas, qual é a sua duração e mensalidade",
  russianTextDes4:
    "Onde e como encontrar um professor para aulas individuais de língua russa",
  russianTextDes5:
    "Como e para quê comprovar o seu nível de língua russa para admissão numa universidade russa. Saiba tudo sobre o teste para obter o certificado",
  typeOfProgram: "Tipo de matriz curricular",
  chooseTheTypeOfAdditionalProgram:
    "Selecione o tipo de de matriz curricular adicional",
  durationOfStudy: "Duração do curso",
  common: "Gerais",
  inYourCountry: "No seu país",
  courseName: "Nome do curso",
  courseDuration: "Duração do curso",
  classDuration: "Duração da aula",
  courseCost: "Preço do curso",
  courseReviews: "Feedbacks do curso",
  male: "Masculino",
  female: "Feminino",
  feedbackLink: "Link para feedbacks",
  russianLanguageCoursesInYourCountry: "Cursos de língua russa no seu país",
  evaluation: "Avaliação",
  giveFeedback: "Deixe o seu feedback",
  newFeedback: "Novo feedback",
  _hour: "hora",
  _hours: "horas",
  __hours: "horas",
  _minute: "minuto",
  _minutes: "minutos",
  __minutes: "minutos",
  create: "Criar",
  feedbackSuccess: "O feedback foi enviado com sucesso e aguarda a moderação",
  _y: "ano",
  _from: "from",
  error: "Erro",
  direction: "Área",
  _tutor: "explicador",
  _tutors: "explicadores",
  __tutors: "explicadores",
  filters: "Filtros",
  sendMessage: "Enviar mensagem",
  sendMessageSuccess: "Mensagem foi enviada com sucesso",
  _year: "ano",
  _years: "anos",
  __years: "anos",
  _back: "voltar",
  _next: "seguinte",
  showing: "Mostrar",
  textRussianLanguageCourses:
    "Selecione o seu país de residência no menu e veja os cursos e escolas de línguas disponíveis. A obtenção de um nível básico de russo em casa tornará muito mais fácil a adaptação durante a sua mudança.",
  tOP10Programs: "Top 10 de Programas",
  tOP10City: "Top 10 de Cidades da Rússia",
  createANewTopic: "Criar um novo tópico",
  topics: "Tópicos",
  posts: "Mensagens",
  filesOfTheFormatPdfJpgPngUpTo10MB: "Arquivos .pdf, .jpg, .png até 10 MB",
  noFileSelected: "Arquivo não selecionado",
  attachFile: "Anexar arquivo",
  eMail: "E-mail",
  fullTime: "Presencial",
  toFind: "Encontrar",
  searchInTopic: "Pesquisar no tópico",
  lessThan1Month: "Menos de 1 mês",
  "1To3Months": "De 1 a 3 meses",
  "3To6Months": "De 3 a 6 meses",
  "6To12Months": "De 6 a 12 meses",
  moreThan1Year: "Mais de 1 ano",
  theDateOfThe: "Data de realização",
  toAdminPanel: "Ir para o painel de administração",
  support: "Apoio técnico",
faq: "FAQ",
  userGuide: "Manual do utilizador",
  textUserGuide: "Instruções para utilização do portal e apresentação de candidatura a um curso",
  textRegisterGuide: 'Inscrição no sítio "Ensino na Rússia para estrangeiros"',
  textTrainingGuide: 'Candidatura para formação no sítio "Ensino na Rússia para estrangeiros"',
  textApplication: "Preenchimento do questionário",
  instructionForWork: "Instruções para preparação da candidatura",
  appeals: "Recurso para rematrícula",
  handling: "Requerimentos",
  __students: "do estudante",
  _student: "estudante",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Defina a sua série de especialidades de formação e descubra as possíveis opções para a sua futura profissão",
  delTheme: "Eliminar o tópico",
  competitionForTheFirstPlace: "Concurso de acesso para uma vaga no âmbito da quota da Federação da Rússia (número de pessoas)",
  ruble: "rublo",
  __rubles: "rublos",
  dateOfEvent: "Data da realização",
  durationUnit: "mês",
  numberPhone: "Telefone de contacto",
  offline: "Offline",
  to_begin: "início",
  theDateOfThe: "Data do início",
  chat: "Chatbot",
  yourMessageChat: "Sua mensagem",
  searchChat: "Pesquisa por diálogo",
  firstMsgChat: "Introduza o texto da mensagem",
  categoryBtnChat_1: "Pergunta sobre a candidatura",
  categoryBtnChat_2: "Pergunta sobre o ensino",
  categoryBtnChat_3: "Pergunta sobre o concurso de acesso",
  categoryBtnChat_4: "Proposta ou comentário",
  categoryBtnChat_5: "Feedback sobre as actividades universitárias",
  categoryBtnChat_6: "Queixa sobre a qualidade dos serviços",
  categoryBtnChat_7: "Contactar o apoio técnico",
  notQuestionResolvedChat: "O meu problema não foi resolvido",
  questionResolvedChat: "O meu problema foi resolvido",
  countrySelectTrueChat: "O país está indicado correctamente",
  salutationChat: "Olá.",
  categoryAppealChat: 'Para obter informação, selecione a categoria correspondente ao seu problema clicando no botão. Depois de selecionar a categoria, introduza a sua pergunta na mensagem e selecione o botão "Enviar".',
  langAppealChat: "Selecione o idioma em que pretende redigir os requerimentos. Os requerimentos redigidos em russo e inglês são processados mais rapidamente.",
  youCountryChatPath1: "Seu país",
  youCountryChatPath2: "Se não houver, indique o país na mensagem de resposta.",
  youCountryChat: "Seu país {country}? Se não houver, indique o país em mensagem de resposta.",
  notFoundCountryChat: "Não foi encontrado nenhum país com o nome especificado. Verifique a veracidade dos dados introduzidos e reenvie a mensagem.",
  additionalInfoChat: "Por favor, forneça informações adicionais ao seu requerimento em mensagem de resposta.",
  yourEmailChat: "Por favor, introduza o seu endereço de correio eletrónico. A resposta ao seu requerimento será enviada por correio eletrónico.",
  notValidEmailChat: "O endereço de correio eletrónico que enviou não corresponde ao formato aaa@bbb.ccc. Verifique a veracidade dos dados introduzidos e reenvie a mensagem.",
  acceptRequestChat: "O seu requerimento foi encaminhado para processamento por um operador. O prazo médio de consideração é de 7 dias de calendário.",
  notFoundEmployeeChat: "O país indicado não consta da lista de países em que o ensino segundo o programa da agência Rossotrudnichestvo está disponível. O seu requerimento não pode ser submetido para consideração. Por favor especifique outro país. Pode conferir a lista completa de programas de ensino na página <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
  langBtnChat_1: "Inglês",
  langBtnChat_2: "Russo",
  langBtnChat_3: "Outro",
  addAppBtn: "Apresentar candidatura",
  listAppBtn: "Lista de candidaturas",
  privacyPolicy: "Política de privacidade",
  ___programs: "do programa",
  ___educationalPrograms: "programas de ensino",
  __educationalPrograms: "programa de ensino",
  editTopic: "Editar tópico",
  editMessage: "Editar mensagem",
  deleteMessage: "Eliminar mensagem",
  blockingUser: "Bloqueio do utilizador",
  blockEndDate: "Data de fim do bloqueio",
  blockEndTime: "Hora de fim do bloqueio",
  blockForever: "Bloqueio permanente",
  block: "Bloquear",
  unlock: "Desbloquear",
  edited: "Editado",
  founder: "Fundador",
  abbreviation: "Sigla",
  studentsByCountry: "Número de alunos por país",
  studentsByCountryCount: "Número de alunos matriculados",
  contactsInternationalDepartment: "Contactos do Serviço de Relações Internacionais (SRI)",
  site: "Sítio",
  address: "Endereço",
  format: "Formato",
  typeEvent: "Tipo de evento",
  quotaLearning: "Ensino pelo sistema de quotas",
  kcp: "Quotas de admissão",
  countSeatsKCP: "Número de vagas por quotas de admissão",
  countSeatsCostTraining: "Número de vagas em cursos pagos",
  distanceLearning: "Ensino à distância",
  notificationAboutDistanceLearning: "O programa de ensino pode ser implementado utilizando tecnologias de e-learning e de ensino à distância. Confira os detalhes na instituição de ensino superior.",
  additionalTests: "Datas de realização das provas de seleção adicionais",
  pageNotFound: "Página não encontrada",
  goToMainPage: "Ir para a página inicial",
  faqInformation: "Para resolver todos as problemas relacionados com o status de verificação da candidatura, a lista de documentos necessários, a duração das provas de seleção e outros assuntos relativos ao processo de admissão, recomendamos que contacte a representação da Rossotrudnichestvo ou as embaixadas da Federação da Rússia autorizadas a realizar a seleção no seu país. Os contactos para comunicar com os funcionários da Rossotrudnichestvo estão na sua conta pessoal.",
  allEvents: "Todas as atividades",
  eventsAndNews: "Eventos e notícias",
  titleNews: "Título da notícia",
  numberOfBudgetPlaces: "Número de vagas subsidiadas",
  russianGovernmentQuota: "Quota do governo da Federação da Rússia",
  legalAddress: "Sede social",
  actualAddress: "Endereço real",
  goToTheRegister: "Ir para o registo de programas adicionais",
  searchForSuitablePrograms: "Procurar programas adequados",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Programas dos cursos mais populares escolhidos pelos candidatos",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Cidades da Rússia mais populares para os candidatos",
  ofTheCountry: "do país",
  passed: "Abrangidas",
  question: "Pergunta",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "O Portal encontra-se no modo de operação experimental.",
  banner_2: "Estimados usuários, atualmente podem apresentar-se problemas técnicos devido ao uso dos correios electrónicos associados ao Google. Para poder trabalhar com o Portal aconselha-se a utilizar correios electrónicos dos demais serviços.",
  banner_3: "A Campanha de selecção de candidatos para estudar na Rússia começa no dia 24 de Dezembro.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
