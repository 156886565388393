import {api} from '@/_services';
import _ from 'lodash';

export default {
  namespaced: true,

  actions: {
    getFaq(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/faq',
        params,
      });
    },

    getFeedbackThemes(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/feedback/themes',
        params,
      });
    },

    setFeedback(store, data) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$postMethod(store, {
        url: '/ps/api/feedback',
        data,
      });
    },

    // forum
    getForumCategories(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/forum/category',
        params,
      });
    },

    setForumCategory(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/forum/category',
        data,
      });
    },

    getForumCategory(store, slug) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/category/${slug}`,
      });
    },

    updateForumCategory(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/category/${id}`,
        data,
      });
    },

    deleteForumCategory(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/category/${id}`,
        data,
      });
    },

    getForumComplains(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/forum/complain',
        params,
      });
    },

    setForumComplains(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/forum/complain',
        data,
      });
    },

    getForumComplainList(store, params = {}) {
      if (this.state.users.isAuthorized) {
        store.state.curLanguage = this.getters.getLocale;
        
      return api.$getMethod(store, {
        url: '/ps/api/forum/complain/list',
        params,
      });
     }
    },

    getForumComplainById(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/complain/${id}`,
      });
    },

    updateForumComplainById(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$putMethod(store, {
        url: `/ps/api/forum/complain/${id}`,
        data,
      });
    },

    deleteForumComplainById(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$deleteMethod(store, {
        url: `/ps/api/forum/complain/${id}`,
      });
    },

    setForumComplainBan(store, params) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: `/ps/api/forum/complain/ban/${params.id}?date_finish_blocked=${params.dateBlocking}${params.always ? '&always=true': ''}`,
        data: {},
      });
    },

    setForumComplainUnBan(store, {userId, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: `/ps/api/forum/complain/unban/${userId}`,
        data,
      });
    },

    getForumDiscussion(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/forum/discussion',
        params,
      });
    },

    setForumDiscussion(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/forum/discussion',
        data,
      });
    },

    getForumDiscussionSlug(store, slug) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/discussion/${slug}`,
      });
    },

    updateForumDiscussion(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$putMethod(store, {
        url: `/ps/api/forum/discussion/${id}`,
        data,
      });
    },

    deleteForumDiscussion(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$deleteMethod(store, {
        url: `/ps/api/forum/discussion/${id}`,
      });
    },

    getForumPost(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/forum/post',
        params,
      });
    },

    setForumPost(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/forum/post',
        data,
      });
    },

    getForumPostSearchById(store, {id, params}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/post/search/${id}`,
        params,
      });
    },

    getForumPostById(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/post/${id}`,
      });
    },

    updateForumPostById(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$putMethod(store, {
        url: `/ps/api/forum/post/${id}`,
        data,
      });
    },

    deleteForumPostById(store, {id, data}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$deleteMethod(store, {
        url: `/ps/api/forum/post/${id}`,
        data,
      });
    },

    getForumGoToMessage(store, {postId, discussionId}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/forum/post/go-to-message/${postId}/${discussionId}`,
        params: {},
      });
    },
  },

  getters: {
    getParams: state => {
      return state.params;
    },

    getShownButtonNewMessage: state => {
      return state.shownButtonNewMessage;
    },

    updateBannedList: state => {
      return state.updateBannedList;
    },
  },

  mutations: {
    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }

      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params);
    },

    setShownButtonNewMessage(state, payload) {
      state.shownButtonNewMessage = payload;
    },
    
    setUpdateBannedList(state, payload) {
      state.updateBannedList = payload;
    },
  },

  state: {
    params: {
    },

    shownButtonNewMessage: true,
    updateBannedList: false,
  },
};
