import _ from 'lodash';
import Vue from 'vue';
import {mapGetters} from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';
import AxiosPlugin from 'vue-axios-cors';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueScrollTo from 'vue-scrollto'
import VueGraph from 'vue-graph';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@/components/site/pages/events/generic/events';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('Multiselect', Multiselect)

Vue.use(VueToast);
Vue.use(VueAwesomeSwiper);

Vue.use(VueGraph);

Vue.use( CKEditor );

Vue.use(VueScrollTo, {
  container : 'body',
  duration  : 300,
  easing    : 'ease',
  offset    : -120,
  force     : true,
  cancelable: true,
  onStart   : false,
  onDone    : false,
  onCancel  : false,
  x         : false,
  y         : true,
});

Vue.mixin({
  data: () => ({
    isResponsive: false,
    isResponsiveSm: false,
  }),
  computed: {
    ...mapGetters({user: 'users/getUser'}),
  },

  methods: {
    setResize() {
      this.isResponsive = window.innerWidth <= 992;
      this.isResponsiveSm = window.innerWidth <= 576;
    },
    roleSatisfying(...args) {
      if (!!args === null) return true;    
      return args.includes(this.user.org_id)
    },
  },

  mounted() {
    window.addEventListener('resize', this.setResize);
    this.setResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.setResize);
  },
})

Vue.directive('addTagRecursive', {
  inserted: function(el, binding) {
    el.querySelectorAll('*').forEach(function(node) {
      node.setAttribute(binding.name, binding.value)
    })
  },
});

Vue.filter('declOfNum', function(number, titles) {
  let cases = [2, 0, 1, 1, 1, 2];
  return number + ' ' + titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
});

Vue.config.productionTip = false;

Vue.filter('lang', (obj, key = 'name') => {
  let r = (_.find((obj || {}).translations || {}, {'locale': store.getters.getLocale}, {}) || {})[key] || '';
  return r ? r || '' : (obj.translations && obj.translations[0] ? (obj.translations[0][key] || '') : (obj[key] || ''))
});

Vue.filter('handlerEmpty', val => !val ? '—' : val)

Vue.filter('handlerBoolean', (val, valTrue, valFalse) => val ? valTrue : valFalse);

Vue.filter('date', date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY'));

Vue.filter('time', time => moment(time, 'hh:mm:ss').format('hh:mm'));

Vue.filter('validatorWebSite', site => typeof site === 'string' && site.startsWith('http') ? site : `//${site}`);

Vue.filter('kitCut', (text, limit = 120) => {
  text = text.toString().trim();
  if( text.length <= limit) return text;

  text = text.slice(0, limit);

  return text.toString().trim() + '...';
});

Vue.filter('number', function(number, sep = ' ', post = '') {
  const
    price = Number.prototype
      .toFixed
      .call(parseFloat(number) || 0, (_.toNumber(number) ^ 0) === _.toNumber(number) ? 0 : 2);

  return price.replace(/(\D)/g, sep).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + post
});
Vue.filter('float_2', function(number, sep = '.', post = '') {
  const
    price = Number.prototype
      .toFixed
      .call(parseFloat(number) || 0, (_.toNumber(number) ^ 0) === _.toNumber(number) ? 0 : 2);

  return price.replace(/(\D)/g, sep).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + post
});

Vue.use(AxiosPlugin);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
