<template>
  <input
    :autocomplete="autocomplete"
    :class="elementClass"
    :disabled="disabled"
    :id="elementId || storePath"
    :name="storePath"
    :placeholder="placeholder"
    :type="showPassword ? 'text' : type"
    @change="set"
    @keyup="set"
    ref="input"
    v-model="valuePreSet"
    @focus="el => autocomplete !== 'on' && el.target.setAttribute('autocomplete', 'nope')"
  >
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    name: 'input-simple',

    props: {
      autocomplete: String,
      disabled: {type: Boolean, default: () => false},
      label: String,
      value: String,
      elementClass: String,
      type: {type: String, default: () => 'text'},
      placeholder: String,
      storePath: String,
      timeout: {type: Number, default: () => 500},
      elementId: String,
    },

    data() {
      return {
        showPassword: false,
        interval: null,
        valuePreSet: null,
      };
    },

    watch: {
      value() {
        if (this.value && this.valuePreSet !== this.value) {
          this.valuePreSet = this.value;

          this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
        }
      },

      forms: {
        handler(val) {
          if (_.get(val, this.storePath) && _.get(val, this.storePath) !== this.valuePreSet ) {
            this.$refs.input.value = this.valuePreSet;

            this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
          }

          if (!_.get(val, this.storePath)) {
            this.valuePreSet = null;
          }
        },

        deep: true,
      },
    },

    mounted() {
      if (this.storePath) {
        this.$store.commit('form/initFormField', {model: this.storePath, value: this.value || null});
      }

      if (this.valuePreSet !== this.value) {
        this.valuePreSet = this.value || _.get(this.forms, this.storePath);
      }
    },

    methods: {
      change(el) {
        this.$store.commit('form/updateField', {model: this.storePath, value: el.target.value});
      },

      set(el) {
         clearTimeout(this.interval);
        this.interval = setTimeout(() => this.change(el), this.timeout);
      },
    },
  };
</script>
