<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page University" v-if="university.slug">
    <div class="university__page-container">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url(${university.cover ? university.cover : defaultImg});`"></div>
        <div class="container">
          <Breadcrumbs
            :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('educationInRussia') },
              { title: $t('educationalOrganizations'), url: '/education-in-russia/universities' },
              { title: university.name }
            ]"
          />

          <h1 class="title-1">
            {{ university.name }}
          </h1>
        </div>
      </div>

      <div class="container university-item">
        <UniversitySearchItem
          :data="university"
          :is-header-university="true"
        />
      </div>

      <div class="container">
        <div class="nav-tabs-wrap-univers">
          <ul class="nav-tabs mb16">
            <li class="nav-item" @click="activeTab = 'aboutOrganization'">
              <router-link
                :class="{ active: activeTab === 'aboutOrganization' }"
                class="nav-link"
                :to="{ query: { tab: 'aboutOrganization' }}"
              >
                {{ $t('aboutOrganization') }}
              </router-link>
            </li>

            <li class="nav-item" @click="activeTab = 'eventsAndNews'">
              <router-link
                :class="{ active: activeTab === 'eventsAndNews' }"
                class="nav-link"
                :to="{ query: { tab: 'eventsAndNews' }}"
              >
                {{ $t('eventsAndNews') }}
              </router-link>
            </li>

            <li class="nav-item" @click="activeTab = 'olympiads'">
              <router-link
                :class="{ active: activeTab === 'olympiads' }"
                class="nav-link"
                :to="{ query: { tab: 'olympiads' }}"
              >
                {{ $t('olympics') }}
              </router-link>
            </li>

            <li class="nav-item" @click="activeTab = 'educationProgram'">
              <router-link
                :class="{ active: activeTab === 'educationProgram' }"
                class="nav-link"
                :to="{ query: { tab: 'educationProgram' }}"
              >
                {{ $t('educationalPrograms') }}
              </router-link>
            </li>

            <li class="nav-item" @click="activeTab = 'contacts'">
              <router-link
                :class="{ active: activeTab === 'contacts' }"
                class="nav-link"
                :to="{ query: { tab: 'contacts' }}"
              >
                {{ $t('contacts') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="myTabContent">
        <div 
          :class="{ 'active show': activeTab === 'aboutOrganization' }"
          class="tab-pane"
          id="aboutOrganization"
          v-if="university.description"
        >
          <InformationUniversity
            :university="university"
            :set-active-sub-tab="setActiveSubTab"
            :set-show-all-rating="setShowAllRating"
            :active-sub-tab="activeSubTab"
            :show-all-rating="showAllRating"
            :get-ratings="getRatings"
          />
        </div>

        <div 
          :class="{ 'active show': activeTab === 'eventsAndNews' }" 
          class="tab-pane" 
          id="eventsAndNews"
        >
          <EventsNewsUniversity 
            :data-events="university.events"
            :data-news="university.news"
          />
        </div>
        
        <div 
          :class="{ 'active show': activeTab === 'olympiads' }" 
          class="tab-pane mb64" 
          id="olympiads"
        >
          <div class="container">
            <OlympiadsUniversity
              :olympiads="university.olympiads"
            />
          </div>
        </div>

        <div 
          :class="{ 'active show': activeTab === 'educationProgram' }" 
          class="tab-pane mb64" 
          id="educationProgram"
        >
          <div class="container">
            <ProgramsUniversity
              :university-slug="university.slug"
              :is-university-programs="true"
              :university-id="university.id"
            />
          </div>
        </div>

        <div 
          :class="{ 'active show': activeTab === 'contacts' }" 
          class="tab-pane" id="contacts"
          v-if="activeTab === 'contacts'"
        >
          <ContactsUniversity 
            :university="university"
          />
          <div class="map" >
            <Openstreetmap
              :height="570"
              :markers="marker"
              is-grey
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import UniversitySearchItem from '@/components/site/pages/educationInRussia/universities/generic/UniversitySearchItem';
  import Openstreetmap from '@/components/site/pages/lifeInRussia/cities/generic/Openstreetmap';
  import uniCard2x from '@/assets/images/bg-uni2x.jpg';
  import InformationUniversity from '@/components/site/pages/educationInRussia/universities/tabs/InformationUniversity';
  import EventsNewsUniversity from '@/components/site/pages/educationInRussia/universities/tabs/EventsNewsUniversity';
  import OlympiadsUniversity from '@/components/site/pages/educationInRussia/universities/tabs/OlympiadsUniversity';
  import ProgramsUniversity from '@/components/site/pages/educationInRussia/universities/tabs/ProgramsUniversity';
  import ContactsUniversity from '@/components/site/pages/educationInRussia/universities/tabs/ContactsUniversity';
  import moment from 'moment';

  export default {
    name: 'university',

    components: {
      Breadcrumbs,
      Openstreetmap,

      UniversitySearchItem,
      InformationUniversity,
      EventsNewsUniversity,
      OlympiadsUniversity,
      ProgramsUniversity,
      ContactsUniversity,
    },

    computed: {
      ...mapGetters({
        locale: 'getLocale',
        forms: 'form/forms',
      }),
      selectedCountry() {
        return (this.forms.university || {}).country
      },
    },

    data() {
      return {
        university: {},
        activeTab: 'aboutOrganization',
        activeSubTab: 'item-sub3',
        value: null,
        tabs: ['aboutOrganization', 'eventsAndNews', 'olympiads', 'educationProgram', 'contacts'],
        marker: [],
        showAllRating: false,
        defaultImg: uniCard2x,
      };
    },

    methods: {
      ...mapActions({
        getUniversity: 'educationInRussia/getUniversity',
        getCountries: 'getCountries',
      }), 

      convertDate(date) {
        return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      },

      setActiveSubTab(val) {
        this.activeSubTab = val;
      },
      setShowAllRating(val) {
        this.showAllRating = val;
      },
      
      getData() {
        this.getUniversity(this.$route.params.name)
          .then(res => {
            if (res.status === 'success') {
              this.university = res.data;

              _.each(this.university.translations, (t, k) => {
                if (t.content) {
                  this.university.translations[k].content = t.content.replace('oembed', 'iframe');
                  this.university.translations[k].content = t.content.replace('url', 'src');
                  this.university.translations[k].content = t.content.replace('watch?v=', 'embed/');
                  this.university.translations[k].content = t.content.replace('oembed', 'iframe');
                }
              })

              if (!this.getRatings(false).length) {
                this.activeSubTab = 'item-sub4';
              }

              if (!this.university.description) {
                this.activeTab = 'eventsAndNews';
              }
            }
            this.$nextTick(() => {
              if (this.university.address) this.marker = [{coordinate: [this.university.address.lat, this.university.address.lng]}]

              const activeTabElement = document.getElementById(this.activeTab);
              if (activeTabElement) {
                activeTabElement.scrollIntoView();
              }
            })
          });
      },

      getRatings(national) {
        return _.filter(this.university.ratings, rating => rating.rating.is_national === national);
      },
    },

    mounted() {
      window.scrollTo(0, 0);

      if (this.$route.query.tab && _.indexOf(this.tabs, this.$route.query.tab) !== -1) {
        this.activeTab = this.$route.query.tab;
      }

      this.getCountries().then(res => {
        this.$store.commit('updateParam', {model: 'countries', value: res})
      })

      this.getData();
    },

    watch: {
      $route(nextRoute, prevRoute) {
        if (nextRoute.query.tab !== prevRoute.query.tab && _.indexOf(this.tabs, this.$route.query.tab) !== -1) {
          this.activeTab = this.$route.query.tab;
        }
      },
    },
  };
</script>
