<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[{title: $t('mainPage'), url: '/' }, {title: $t('educationInRussia') }, {title: $t('onlineMonitoring') }]"
        />

        <h1 class="title-1 mb32">{{ $t('onlineMonitoring') }}</h1>

        <div class="container def-container program-container mb64">
          <form class="program-content">
            <MultiSelect
              :options="onlineMonitoringCountries"
              :placeholder="$t('selectACountry')"
              store-path="filter.monitoring.country"
              :label="$t('country')"
            />

            <MultiSelect
              :options="onlineMonitoringYears"
              :placeholder="$t('chooseYear')"
              store-path="filter.monitoring.year"
              :label="$t('year')"
            />
          </form>

          <div class="program-content-btn">
            <a
              class="btn"
              href="javascript:void(0)"
              @click="getData"
            >{{ $t('toApply') }}</a>

            <a
              class="btn btn-outline"
              href="javascript:void(0)"
              @click="resetFilter"
            >{{ $t('clearAllFilters') }}</a>
          </div>
        </div>

        <div class="row mb32">
          <div class="col-3" :hidden="true">
            <div class="def-container statistics-container">
              <h2 class="title-2">
                {{ $t('numberOfQuotaPlaces') }}
              </h2>
              <h3 class="title-big light">
                {{ getParams.monitoring.quota | number }}
              </h3>
            </div>
          </div>

          <div class="col-6">
            <div class="def-container statistics-container">
              <h2 class="title-2">{{ $t('competitionForTheFirstPlace') }}</h2>
              <h3 class="title-big light">{{ getParams.monitoring.competition }}</h3>
            </div>
          </div>
        </div>

        <div class="container def-container mb32" v-if="getCityName()">
          <h3 class="title-3">{{ $t('tOP10EducationalOrganizations') }}</h3>

          <p class="title-sub light">
            {{ $t('theMostPopularEducationalOrganizationsChosenByApplicants') }} <br>
            {{ $t('ofTheCountry') }} {{ getCityName() }} {{ $t('_whenApplying') }}
          </p>

          <ol class="monitoring__list logo">
            <li class="monitoring__list-item"
              v-for="(university, key) in getParams.monitoring.universities"
              v-bind:key="key"
              >
              <figure class="monitoring__list-logo">
                <figcaption>{{ university | lang('name') }}</figcaption>
                <div>
                  <img alt="" :src="university.logo">
                </div>
              </figure>
            </li>
          </ol>
        </div>

        <div class="container def-container mb32" v-if="getCityName()">
          <h3 class="title-3">
            {{ $t('tOP10Programs') }}
          </h3>
          <p class="title-sub light">
            {{ $t('theMostPopularEducationalProgramsChosenByApplicants') }} <br>
            {{ $t('ofTheCountry') }} {{ getCityName() }} {{ $t('_whenApplying') }}
          </p>

          <div class="tab-content">
            <div :class="{ 'active show': activeItem === 'item' }" class="tab-pane" id="item">
              <ol class="monitoring__list">
                <li
                  class="monitoring__list-item"
                  v-for="(program, key) in getParams.monitoring.allProgramme"
                  :key="key"
                >
                  <div>
                    <p class="light">{{ (program.name.split(' ') || [])[0] || '' }}</p>
                    {{ program | lang('name_without_code') }}
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="container def-container" v-if="getCityName()">
          <h3 class="title-3">
            {{ $t('tOP10City') }}
          </h3>
          <p class="title-sub light">
            {{ $t('theMostPopularCitiesOfRussiaChosenByApplicants') }} <br>
            {{ $t('ofTheCountry') }} {{ getCityName() }} {{ $t('_whenApplying') }}
          </p>

          <ol class="monitoring__list">
            <li
              class="monitoring__list-item"
              v-for="(citity, key) in getParams.monitoring.topCitites"
              v-bind:key="key"
            >
              {{ citity | lang('name') }}
            </li>
          </ol>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters, mapActions} from 'vuex';
  import {helpers} from '@/_services';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';
  import moment from 'moment';

  export default {
    name: 'Programs',

    components: {
      Breadcrumbs,
      MultiSelect,
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        getParams: 'educationInRussia/getParams',
        forms: 'form/forms',
      }),
    },

    data() {
      return {
        activeItem: 'item',
        value: null,
        onlineMonitoringYears: [],
        onlineMonitoringYearsData: [],
        onlineMonitoringCountries: [],
        onlineMonitoringCountriesData: [],
        currentCountry: null,
      };
    },

    methods: {
      ...mapActions({
        getOnlineMonitoring: 'educationInRussia/getOnlineMonitoring',
        getOnlineMonitoringYears: 'educationInRussia/getOnlineMonitoringYears',
        getOnlineMonitoringCountries: 'educationInRussia/getOnlineMonitoringCountries',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)

        this.getOnlineMonitoring(_.assign(
          {academicYearId: this.forms.filter.monitoring.year},
          this.forms.filter.monitoring.country && {countryId: this.forms.filter.monitoring.country})
        ).then(res => {
          if (res.status === 'success') {
            this.preloader = false
            this.$store.commit('educationInRussia/updateParam', {model: 'monitoring', value: res.data});
            this.currentCountry = _.get(this.forms.filter, 'monitoring.country');
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      resetFilter() {
        this.$store.commit('form/updateField', {model: 'filter.monitoring.country', value: ''});
        this.setDefaultYear();
        this.getData();
      },

      getCityName() {
        return (_.find(this.onlineMonitoringCountries, {value: this.currentCountry}) || {}).name;
      },

      setDefaultYear() {
        this.$store.commit(
          'form/updateField',
          {model: 'filter.monitoring.year', value: _.find(this.onlineMonitoringYears, {name: moment().format('YYYY')}, {}).value},
        );

        this.onlineMonitoringYears = _.clone(this.onlineMonitoringYears);
      },
    },

    mounted() {
      this.getOnlineMonitoringYears().then(res => {
        this.onlineMonitoringYearsData = res.data;
        this.onlineMonitoringYears = helpers.options(res.data, 'name', 'id');
        this.setDefaultYear();
      });

      this.getOnlineMonitoringCountries().then(res => {
        this.onlineMonitoringCountriesData = res.data;
        this.onlineMonitoringCountries = helpers.options(res.data, 'name', 'id');
      });

      this.getData();
    },
  };
</script>
