import _ from 'lodash';
import {api} from '@/_services';

const dateNow = new Date().getTime();
const isUserAuth = localStorage.getItem('userToken')

const getCurrentTime = dateString => {
  const date = new Date(dateString);
  const currentTime = 
    `${(date.getHours()<10 ? '0' : '') + date.getHours()}:${(date.getMinutes()<10 ? '0' : '') + date.getMinutes()}`;
  return currentTime;
}
const getMessagesFromLocalStorage = () => {
  let msgs = localStorage.getItem('messages');
  return msgs ? JSON.parse(msgs) : [];
}
const initialState = {
  params: {
    userId: null,
    countUnread: null,
    langs: [],
    groupMessages: [],
    notifications: {
      buttons: {
        noProblem: [{
          id: 1,
          name: 'questionResolvedChat',
          category: 'noProblem',
        }],
        isProblem: [{
          id: 1,
          name: 'notQuestionResolvedChat',
          category: 'isProblem',
        }],
        isCountry: [{
          id: 1,
          name: 'countrySelectTrueChat',
          category: 'isCountry',
        }],
      },
      salutation: [{
        id: 1,
        text: 'salutationChat',
        time: getCurrentTime(dateNow),
      }],
      firstMessage: [{
        id: 2,
        text: 'firstMsgChat',
        time: getCurrentTime(dateNow),
      }],
      groupMess: [{
        id: 3,
        text: 'categoryAppealChat',
        time: getCurrentTime(dateNow),
      }],
      lang: [{
        id: 4,
        text: 'langAppealChat',
        time: getCurrentTime(dateNow),
      }],
      additionalData: [{
        id: 5,
        text: 'additionalInfoChat',
      }],
      country: [
        {text: 'youCountryChatPath1'},
        {text: 'youCountryChatPath2'},
      ],
      notFoundCountry: [{
        text: 'notFoundCountryChat',
      }],
      email: [{
        text: 'yourEmailChat',
      }],
      notValidEmail: [{
        text: 'notValidEmailChat',
      }],
      acceptRequest: [{
        text: 'acceptRequestChat',
      }],
      notFoundEmployee: [{
        text: 'В указанной вами стране нет представительства Россотрудничества. Пожалуйста, укажите другую страну.',
      }],
    },
    messages: getMessagesFromLocalStorage(),
  },
};

export default {
  namespaced: true,

  actions: {
    getLangsAction(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: '/chat/api/language',
        params,
      });
    },
    getGroupMessagesAction(store) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: '/chat/api/group-messages-noreg/btn-category',
      });
    },
    sendMessageAction(store, data) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$postMethod(store, {
        url: `/chat/api/message${!isUserAuth ? '/noreg' : '' }`,
        data,
      });
    },
    closeAppealAction(store, data) {
      return api.$putMethod(store, {
        url: '/chat/api/action-user/close-group',
        data,
      })
    },
    getCountUnread(store) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: '/chat/api/message/count-unread',
      })
    },
    questionNotResolvedAction(store, params = {}) {
      return api.$getMethod(store, {
        url: `/chat/api/users-group-message/my-question-not-resolved/?id_messages_user=${params.idMsgs}&id_group=${params.idGroup}`,
      });
    },
    getCurrentTimeAction(store, date = new Date()) {
      store.state.curLanguage = this.getters.getLocale;
      return getCurrentTime(date);
    },
    findMessageAction(store, params = {}) {
      return api.$getMethod(store, {
        url: `/chat/api/message/noreg?search=${params.text}&id_user=${params.id_user}`,
      })
    },
    downloadFileAction(store, params = {}) {
      return api.$getMethod(store, {
        url: `/chat/api/file?id_user=${params.id_user}&path_hash=${params.path_hash}`,
      })
    },
    detectCountryAction(store, params) {
      return api.$getMethod(store, {
        url: `/chat/api/geolocation/country${params ? `?country=${params}` : ''}`,
      })
    },
  },
  mutations: {
    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }
      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params)
    },
  },

  getters: {
    getLangs: state => state.params.langs,
    getGroupMessages: state => state.params.groupMessages,
    getMessages: state => state.params.messages,
    getNotifications: state => state.params.notifications,
    getUserId: state => state.params.userId,
    getCountUnread: state => state.params.countUnread,
  },

  state: {
    ...initialState,
  },
}
