<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <!--  eslint linebreak-style: ["error", "windows"]-->
  <div>
    <div class="base-container" v-if="themesList">
      <div class="container">
        <Breadcrumbs
          :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('help'), url: '/help'},
              { title: $t('forum'), url: '/help/forum' },
              { title: lang(themesListCategory, 'name') },
            ]"
        />

        <h1 class="title-1 mb32">{{ themesListCategory | lang('name') }}</h1>
        <AlertIsNotAuth />
        <AlertFailTheme v-if="isFailedTheme" text="В названии темы не может быть менее 5 символов"/>

        <div class="additional_info-container" v-if="!themesListCategory.can_discuss && themesListCategory.additional_info">
          <div class="row">
            <div class="col-4" v-html="filterLang(themesListCategory, 'additional_info')"/>
          </div>
        </div>

        <div class="search-row mb32" v-if="themesListCategory.can_discuss">
          <Input
            element-class="txt"
            :placeholder="$t('searchInTheSection')"
            store-path="filter.forum.themes.search"
            type="text"
            :keyup-enter-callback="() => getData(searchType)"
          />

          <Button
            type="button"
            element-class="btn btn-search"
            :callback="() => getData(searchType)"
          ></Button>
        </div>

        <div class="form-row forum-select" v-if="!isSearch">
          <multiselect
            class="simple-select"
            v-model="sorting"
            :options="options"
            :searchable="false"
            :placeholder="$t('sort')"
            track-by="value"
            label="name"
          ></multiselect>
        </div>

        <div v-if="isSearch">
          <div class="row">
            <div class="col-2">
              <h3 class="title-3 forum__title-result">{{ $t('searchingResults') }}</h3>
            </div>

            <div class="col-2">
              <ul class="nav-tabs-sub" style="margin: 0">
                <li class="nav-item-sub">
                  <a
                    href="javascript:void(0)"
                    class="nav-link-sub"
                    @click.prevent="() => getData('themes')"
                    :class="{ 'active show': searchType === 'themes' }"
                  >{{ $t('topics') }}</a>
                </li>

                <li class="nav-item-sub">
                  <a
                    href="javascript:void(0)"
                    class="nav-link-sub"
                    @click.prevent="() => getData('posts')"
                    :class="{ 'active show': searchType === 'posts' }"
                  >{{ $t('posts') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="forum__topics"  :class="{searchMessage: searchType === 'posts' && isSearch}">
          <div
            class="forum__topics-item"
            v-for="(theme, key) in themesList.data"
            v-bind:key="key"
          >
            <ForumPost :data="theme" v-if="searchType === 'posts' && isSearch" :is-search="isSearch" />

            <template v-if="searchType === 'themes' || !isSearch">
              <div class="forum__topics-title-wrap">
                <router-link
                  :to="`/help/forum/themes/messages/${theme.slug}`"
                  class="forum__topics-item-title"
                >{{ theme.title }}</router-link>

                <div class="forum__topics-item-info">
                  <span>{{ $t('_answers') }}: {{ theme.answers }}</span>
                  <span class="light">{{ $t('_views') }}: {{ theme.views }}</span>
                </div>
              </div>
              <figure class="forum__item-user" v-if="theme.user">
                <div class="forum__item-user-avatar">
                  <img v-if="theme.user.avatar" :src="theme.user.avatar" :alt="theme.user.username" />
                  <img v-if="!theme.user.avatar" src="@/assets/images/icon/account_circle-24px.svg" :alt="theme.user.username" />
                </div>

                <figcaption class="forum__item-user-name">
                  <span>
                    {{ theme.user.username }}
                  </span>
                  <span class="light">{{ theme.time_ago }}</span>
                </figcaption>
              </figure>
              <Modal
                :unique-id="`${theme.id}`"
                @click.stop="dialog = true"
                ref="modal"
              >
                <div slot="body">
                  <h2 class="title-2">{{ $t('areYouSure') }}?</h2>
                  <p>
                    {{ $t('areYouSure') }}?
                  </p>

                  <a
                    href="javascript:void(0)"
                    class="btn-danger"
                    @click="() => deleteModal(theme.id)"
                  >{{ $t('delTheme') }}</a>
                </div>
              </Modal>

            </template>
            <div v-if="(user && (user.org_id && user.org_id === '02') || (user.roles && user.roles[0] === 'moderator')) && themesListCategory.can_discuss" class="blue-btn">
              <a
                href="javascript:void(0)"
                @click="() => editTopic(theme)"
              >{{ $t('editTopic') }}</a>
            </div>
            <div v-if="(user && (user.org_id && user.org_id === '02') || (user.roles && user.roles[0] === 'moderator'))" class="red">
              <a
                href="javascript:void(0)"
                class="forum__banned"
                @click="() => {targetModal(theme.id) }"
              >{{ $t('delTheme') }}</a>
            </div>
          </div>
        </div>

        <div class="pagination text-right" v-if="user.id && !isSearch">
          <Pagination
            :count="themesList.last_page"
            :callback-on-change="() => getData(searchType)"
            pagination-model="pagination.programs.additional"
            :last-data-count="themesList.data.length"
            :show-list-showing="false"
          />

          <a
            href="javascript:void(0)"
            class="btn btn-big"
            @click="createTopic"
            v-if="shownButtonNewMessage && themesListCategory.can_discuss"
          >{{ $t('createANewTopic') }}</a>
        </div>

        <NewMessage
          v-if="user.id && themesListCategory.can_discuss"
          :data="currentMessageData"
          type="theme"
          :paren-id="{id: themesListCategory.id}"
          :send-callback="(resCallback) => getData(searchType,resCallback)"
          :edit="editTheme.id"
          :value="editTheme.title"
        />
      </div>
    </div>

    
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import Vue from 'vue';
  import AlertIsNotAuth from '@/components/site/pages/help/forum/generic/AlertIsNotAuth';
  import AlertFailTheme from '@/components/site/pages/help/forum/generic/AlertFailTheme';
  import Pagination from '@/components/generic/pagination/Pagination';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Input from '@/components/generic/form/field/Input';
  import Button from '@/components/generic/form/Button';
  import NewMessage from '@/components/site/pages/help/forum/generic/NewMessage';
  import ForumPost from '@/components/site/pages/help/forum/generic/ForumPost';
  import Modal from '@/components/generic/modal/Modal';
  export default {
    name: 'ForumThemes',

    components: {
      AlertIsNotAuth,
      AlertFailTheme,
      Pagination,
      Breadcrumbs,
      Input,
      Button,
      NewMessage,
      ForumPost,
      Modal,
      },


    computed: {
      ...mapGetters({
        shownButtonNewMessage: 'help/getShownButtonNewMessage',
        isModalShown: 'modal/isModalShown',
        forms: 'form/forms',
        user: 'users/getUser',
      }),

      search: self => _.get(self.$store.state.form.forms, 'filter.forum.themes.search', null),
    },

    data: () => ({
      filterLang: Vue.filter('lang'),
      sorting: '',
      searchType: 'posts',
      modalDeleteTheme: '',
      options: [],
      editTheme: {}, 
      themesList: {
        last_page: 0,
        data: [],
      },

      themesListCategory: {},
      lang: Vue.filter('lang'),
      currentMessageData: null,
      isSearch: false,
      isFailedTheme: false,
    }),

    methods: {
      ...mapActions({
        getForumDiscussion: 'help/getForumDiscussion',
        deleteAction: 'help/deleteForumDiscussion',
      }),

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },

      editTopic(theme) {
        this.editTheme = theme;
      },
      
      createTopic() {
        this.editTheme = {};
        this.isFailedTheme = false
        this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage)
      },

      deleteModal(id) {
        this.deleteAction(id).then(() => {
          this.dialog = false
          window.location.reload()
        });
       },


      getData(searchType, resCallback) {
        this.getForumDiscussion({
          category: this.$route.params.slug,
          q: this.search,
          searchType: this.search && searchType,
          sort: (this.sorting || {}).value || 'number_of_responses',
        }).then(res => {
          this.isSearch = !this.search ? this.search : !!this.search;

          if (searchType) {
            this.searchType = searchType;
          }

          if(resCallback && resCallback.status === 422) {
            this.isFailedTheme = true
          }

          if (res.status !== 500) {
            this.themesList = res;

            if (res.category) {
              this.themesListCategory = res.category;
            }
          }
        })
      },
    },

    mounted() {
      this.getData(null);
      this.$store.commit('form/updateField', {model: 'filter.forum.themes.search', value: ''})
      this.isSearch = false;
     
      this.options = [
        {name: this.$t('byCreationDate'), value: 'creation_date'},
        {name: this.$t('byTheNumberOfResponses'), value: 'number_of_responses'},
        {name: this.$t('byViews'), value: 'number_of_views'},
      ];
    },

    watch: {
      editTheme() {
        this.$store.commit('help/setShownButtonNewMessage', false);
      },
      sorting() {
        this.getData(this.searchType);
      },

      'forms.filter.forum.themes.search'(nextVal, prevVal) {
        if (nextVal !== prevVal && !nextVal) {
          this.getData(null);
        }
      },
    },
  };
</script>
