<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="login__page-container">
      <div class="container">
        <div class="login__wrap">
          <Form :form-callback="loginOld" v-if="isDevelop">
            <div slot="form" class="login">
              <p>{{ $t('quickAccessThroughSocialNetworks') }}</p>

              <ul class="social">
                <li><a href="#"><img src="@/assets/images/vk.png" alt="vk"></a></li>
                <li><a href="#"><img src="@/assets/images/fb.png" alt="fb"></a></li>
                <li><a href="#"><img src="@/assets/images/twit.png" alt="twit"></a></li>
                <li><a href="#"><img src="@/assets/images/googl.png" alt="googl"></a></li>
                <li><a href="#"><img src="@/assets/images/massage.png" alt="massage"></a></li>
              </ul>

              <Input
                element-class="txt"
                type="email"
                label="E-mail"
                :placeholder="$t('enterYourEmail')"
                rules="required|email"
                store-path="login.email"
              />

              <Input
                element-class="txt"
                type="password"
                :label="$t('password')"
                :placeholder="$t('enterPassword')"
                rules="required|min:6|max:30|latin"
                store-path="login.password"
              />

              <div class="form-control">
                <div class="checkbox">
                  <label for="rememberMe">
                    <InputSimple
                      store-path="login.rememberMe"
                      element-id="rememberMe"
                      type="checkbox"
                    />

                    <span>{{ $t('rememberMe') }}</span>
                  </label>
                </div>

                <router-link to="/account/forgot-Password" class="link">{{ $t('forgotYourPassword') }}</router-link>
              </div>

              <Button
                :text="$t('logIn')"
                element-class="btn"
                type="submit"
              />
            </div>
          </Form>

          <Form :form-callback="login">
            <div slot="form" class="login">
              <template v-if="this.$route.query.code">
                <h1 class="title-1">{{ $t('authorizationCheck') }}</h1>
              </template>

              <template v-if="!this.$route.query.code">
                <h1 class="title-1">{{ $t('logIn') }}</h1>

                <a
                  class="btn"
                  :href="`${avanpost}/oauth2/authorize?lang=${getLocale}&client_id=educationinrussia&redirect_uri=${link}/account/login&scope=openid%20profile%20email%20groups%20additional_info&response_type=code`"
                >
                  {{ $t('logIn') }}
                </a>


                <div class="registr">
                  {{ $t('noAccount') }}
                  <a
                    :href="`${avanpost}/user/self/register?lang=${getLocale}`"
                    class="link"
                  >{{ $t('registration') }}</a>
                </div>
              </template>

            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Form from '@/components/generic/form/Form';

  import Button from '@/components/generic/form/Button';
  import Input from '@/components/generic/form/field/Input';
  import InputSimple from '@/components/generic/form/field/InputSimple';

  export default {
    name: 'Login',

    components: {
      Button,
      Form,
      Input,
      InputSimple,
    },

    data: () => ({
      avanpost: process.env.VUE_APP_LINK_AVANPOST,
      domain: window.location.host,
      link: window.location.origin,
    }),
    computed: {
      ...mapGetters({
        isDevelop: 'isDevelop',
        isAuth: 'users/isAuth',
        forms: 'form/forms',
        getLocale: 'getLocale',
      }),
    },

    methods: {
      login() {
        let
          email = this.forms.login.email,
          password = this.forms.login.password;


        this.$store.commit(
          'users/login',
          {email, password, callback: this.callback},
        );
      },

      loginOld() {
        let
          email = this.forms.login.email,
          password = this.forms.login.password;


        this.$store.commit(
          'users/loginOld',
          {email, password, redirectTo: '/settings', callback: this.callback},
        );
      },
    },

    mounted() {
      if (this.isAuth) {
        this.$router.push('/settings');
      } else {
        if (this.$route.query.code) {
          this.$store.commit(
            'users/login',
            {code: this.$route.query.code, callback: this.callback},
          );
        }
      }
    },
  };
</script>
