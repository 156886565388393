import _ from 'lodash';

export default {
    namespaced: true,

    getters: {
        getField: state => model => {
            return _.get(state.forms, model, null);
        },

        forms: state => {
            return state.forms;
        },
    },

    mutations: {
        initFormField(state, payload) {
            if (!_.has(state.forms, payload.model)) {
                _.set(state.forms, payload.model, payload.value || null);
                state.forms = _.clone(state.forms);
            }
        },

        updateField(state, payload) {
            if (!_.has(state.forms, payload.model)) {
                _.set(state.forms, payload.model, payload.value || null);
                state.forms = _.clone(state.forms);
            }

            _.set(state.forms, payload.model, payload.value);
            state.forms = _.clone(state.forms);
        },
    },

    state: {
        forms: {

        },
    },
};
