//import axios from 'axios';

//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

//axios.interceptors.request.use(config => {
//  config.headers['X-Requested-With'] = 'XMLHttpRequest';
//  config.headers.common['Access-Control-Allow-Headers'] = 'DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,' +
//    'Content-Type,Range, X-Token-Auth, Authorization, X-localization';
//
//  return config;
//}, error => {
//  return Promise.reject(error);
//});

export * from './api.service';
export * from './axios.service';
export * from './helpers.service';
export * from './translater';

