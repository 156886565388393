<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[{title: $t('mainPage'), url: '/' }, {title: $t('educationInRussia') }, {title: $t('educationalOrganizations') }]"
        />

        <h1 class="title-1 mb32">{{ $t('educationalOrganizations') }}</h1>
        <div class="container def-container program-container">
          
          <div class="program-container-popup" v-show="isShowFilter || !isResponsiveSm">
            <div class="program-container-popup-header">
              <h3 class="title-3">{{ $t('filters') }}</h3>
              <div
                class="program-container-popup-header-close"
                @click="isShowFilter = !isShowFilter"
              >
                <img src="@/assets/images/close-black.png" alt="" />
              </div>
            </div>
            <div class="nav-tabs-sub-wrap">
              <ul class="nav-tabs-sub">
                <li
                  class="nav-item-sub"
                  @click="typeFilter = 'main'"
                >
                  <router-link
                    :class="{ active: typeFilter === 'main' }"
                    @click.prevent="typeFilter = 'main'"
                    class="nav-link-sub"
                    :to="{ query: { typeFilter: 'main' }}"
                  >{{ $t('mainPrograms') }}</router-link>
                </li>

                <li
                  class="nav-item-sub"
                  @click="typeFilter = 'additional'"
                >
                  <router-link
                    :class="{ active: typeFilter === 'additional' }"
                    class="nav-link-sub"
                    :to="{ query: { typeFilter: 'additional' }}"
                  >{{ $t('additionalPrograms') }}</router-link>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div v-show="typeFilter === 'main'" class="">
                <form class="program-content">
                  <MultiSelect
                    :options="districts"
                    :placeholder="$t('chooseCounty')"
                    store-path="filter.universities.main.district"
                    :label="$t('county')"
                    value=""
                    :callback-on-change="onChangeDistrict"
                  />

                  <MultiSelect
                    :options="universitiesGetCities"
                    :placeholder="$t('chooseACity')"
                    store-path="filter.universities.main.city"
                    :label="$t('city')"
                    value=""
                  />

                  <MultiSelect
                    :options="levelEducation"
                    :placeholder="$t('chooseYourLevelOfEducation')"
                    store-path="filter.universities.main.levelEducation"
                    :label="$t('theLevelOfEducation')"
                    value=""
                    :callback-on-change="() => {
                      onChangeSelect('levelEducation');
                      updateFilters('levelEducation')
                    }"
                    allow-empty
                  />
                    
                  <!-- область научных знаний -->
                  <MultiSelect :options="scientificAreas" :placeholder="$t('chooseYourAreaOfScientificKnowledge')"
                    store-path="filter.universities.main.scientificAreas" 
                    :label="$t('areaOfScientificKnowledge')" 
                    v-if="isLevelEducation"
                    :callback-on-change="() => {
                      onChangeSelect('scientificAreas');
                      updateFilters('scientificAreas')
                    }" 
                    allow-empty 
                  />
                  <!-- профиль научной специальности -->
                  <AsyncSelectPaginate 
                    :code="true"
                    :placeholder="$t('chooseYourScientificSpecialtyProfile')" 
                    :searchable="true"
                    store-path="filter.universities.main.scientificSpecialties" 
                    :label="$t('scientificSpecialtyProfile')"
                    v-if="isLevelEducation" 
                    :callback-on-change="() => {
                    onChangeSelect('scientificSpecialties');
                    updateFilters('scientificSpecialties')
                    }" 
                    :load-options="getScientificSpecialties" 
                    :filters="filtersScientificSpecialties" 
                    allow-empty 
                  />

                  <MultiSelect
                    :options="groupDirections"
                    :placeholder="$t('chooseAGroupOfTrainingAreas')"
                    store-path="filter.universities.main.groupDirection"
                    :label="$t('trainingDirectionGroup')"
                    value=""
                    v-if="!isLevelEducation"
                    :callback-on-change="() => updateFilters('groupDirection')"
                  />

                  <MultiSelect
                    :options="educationalPrograms"
                    :placeholder="$t('chooseATrainingDirection')"
                    store-path="filter.universities.main.educationalProgram"
                    :label="$t('trainingDirection')"
                    value=""
                    v-if="!isLevelEducation"
                  />

                  <MultiSelect
                    :options="ratingsFilter"
                    class="multiselect-select"
                    :placeholder="$t('chooseRatings')"
                    store-path="filter.universities.main.ratings"
                    :label="$t('ratings')"
                    :is-multiple="true"
                    :allow-empty="true"
                    value=""
                  />

                  <div class="checkbox">
                    <label for="preparatoryFaculty">
                      <InputSimple
                        store-path="filter.universities.preparatoryFaculty"
                        element-id="preparatoryFaculty"
                        type="checkbox"
                      />

                      <span>{{ $t('thePresenceOfThePreparatoryFaculty') }}</span>
                    </label>
                  </div>
                </form>

                <div class="program-content-btn">
                  <a
                    class="btn"
                    href="javascript:void(0)"
                    @click="() => { getData('main', true, true), this.isShowFilter = false }"
                  >
                    {{ $t('show') }}
                    {{ totalMainUniversities | declOfNum([$t('_university'), $t('_universities'), $t('__universities')]) }}
                  </a>

                  <a
                    class="btn btn-outline"
                    href="javascript:void(0)"
                    @click="resetFilter"
                  >{{ $t('clearAllFilters') }}</a>
                </div>
              </div>

              <div v-show="typeFilter === 'additional'">
                <form class="program-content">
                  <!--
                    <MultiSelect
                      :options="groupDirectionsAdditional"
                      :placeholder="$t('chooseAGroupOfTrainingAreas')"
                      store-path="filter.universities.additional.groupDirection"
                      :label="$t('trainingDirectionGroup')"
                      value=""
                      :callback-on-change="() => updateFilters('groupDirection')"
                    />
                  -->
                  <MultiSelect
                    :options="educationalProgramsAdditional"
                    :placeholder="$t('chooseATrainingDirection')"
                    store-path="filter.universities.additional.educationalProgram"
                    :label="$t('trainingDirection')"
                    value=""
                  />

                  <MultiSelect
                    :options="semesters"
                    :placeholder="$t('chooseSemester')"
                    store-path="filter.universities.additional.semester"
                    :label="$t('semester')"
                    value=""
                  />

                  <MultiSelect
                    :options="universitiesGetCitiesAdditional"
                    :placeholder="$t('chooseACity')"
                    store-path="filter.universities.additional.city"
                    :label="$t('city')"
                    value=""
                  />
                </form>

                <div class="program-content-btn">
                  <a
                    class="btn"
                    href="javascript:void(0)"
                    @click="() => getData('additional', true, true)"
                  >
                    {{ $t('show') }}
                    {{ totalAdditionalUniversities | declOfNum([$t('_university'), $t('_universities'), $t('__universities')]) }}
                  </a>

                  <a
                    class="btn btn-outline"
                    href="javascript:void(0)"
                    @click="resetFilter"
                  >{{ $t('clearAllFilters') }}</a>
                </div>
              </div>
            </div>
          </div>

          <a
            href="#"
            class="btn btn-outline d-none sm-show"
            @click="isShowFilter = !isShowFilter"
          >Фильтры</a>
        </div>

        <div class="search-row">
          <Input
            element-class="txt"
            :placeholder="$t('enterTheNameOfTheEducationalOrganization')"
            store-path="filter.universities.search"
            :value="((forms.filter || {}).universities || {}).search || ''"
            type="text"
            :keyup-enter-callback="() => getData(typeFilter, true, true)"
          />

          <Button
            type="button"
            element-class="btn btn-search"
            :callback="() => getData(typeFilter, true, true)"
          ></Button>
        </div>

        <!--   search title    -->
        <h3 class="title-3 light mb16">
          {{ (typeFilter === 'main' ? universitiesMainList : universitiesAdditionalList).total|
          declOfNum([$t('_educationalOrganization'), $t('_educationalOrganizations'), $t('__educationalOrganizations')]) }}
        </h3>

        <div class="card__vertical" v-if="typeFilter === 'main'">
          <UniversitySearchItem
            :data="university"
            v-for="university in universitiesMainList.data"
            :key="university.id"
          />

          <Pagination
            :count="universitiesMainList.last_page"
            :callback-on-change="() => getData('main', true)"
            pagination-model="pagination.universities.main"
            :last-data-count="universitiesMainList.data.length"
          />
        </div>

        <div class="card__vertical" v-if="typeFilter === 'additional'">
          <UniversitySearchItem
            :data="university"
            v-for="university in universitiesAdditionalList.data"
           :key="university.id"
          />

          <Pagination
            :count="universitiesAdditionalList.last_page"
            :callback-on-change="() => getData('additional', true)"
            pagination-model="pagination.universities.additional"
            :last-data-count="universitiesAdditionalList.data.length"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {helpers} from '@/_services';
  import {mapGetters, mapActions} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import UniversitySearchItem from '@/components/site/pages/educationInRussia/universities/generic/UniversitySearchItem';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';
  import Input from '@/components/generic/form/field/Input';
  import InputSimple from '@/components/generic/form/field/InputSimple';
  import Button from '@/components/generic/form/Button';
  import Pagination from '@/components/generic/pagination/Pagination';
  import AsyncSelectPaginate from '@/components/generic/asyncSelectPaginate/AsyncSelectPaginate.vue';

  export default {
    name: 'Universities',
    components: {
      Breadcrumbs,
      UniversitySearchItem,
      MultiSelect,
      Input,
      InputSimple,
      Button,
      Pagination,
      AsyncSelectPaginate,
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        universitiesMainList: 'educationInRussia/getUniversitiesMainList',
        universitiesAdditionalList: 'educationInRussia/getUniversitiesAdditionalList',
        locale: 'getLocale',
        forms: 'form/forms',
      }),
    },

    data() {
      return {
        typeFilter: 'main',
        districts: [],
        semesters: [],
        levelEducation: [],
        universitiesGetCities: [],
        universitiesGetCitiesAdditional: [],
        groupDirections: [],
        groupDirectionsAdditional: [],
        educationalPrograms: [],
        educationalProgramsAdditional: [],
        totalMainUniversities: 0,
        totalAdditionalUniversities: 0,
        ratingsFilter: [],
        isShowFilter: '',
        allFilters: {},
        preloader: true,
        isLevelEducation: false,
        postgraduateNumber: 1,
        scientificAreas: [],
        scientificSpecialties: [],
        filtersScientificSpecialties: {},
      };
    },

    methods: {
      ...mapActions({
        getLevelEducation: 'getLevelEducation',
        getSemesters: 'getSemesters',
        getDistricts: 'getDistricts',
        getUniversities: 'educationInRussia/getUniversities',
        getUniversitiesGetCities: 'educationInRussia/getUniversitiesGetCities',
        getGroupDirections: 'getGroupDirections',
        getEducationFilter: 'getEducationFilter',
        getEducationalPrograms: 'getEducationalPrograms',
        getUniversitiesCountByFilter: 'educationInRussia/getUniversitiesCountByFilter',
        getRatingsFilter: 'getRatingsFilter',
        getScientificAreas: 'getScientificAreas',
        getScientificSpecialties: 'getScientificSpecialties',
      }),

      getFilter() {
        let currentFilter;

        if (this.typeFilter === 'main') {
          currentFilter = this.forms.filter.universities.main;
        } else {
          currentFilter = this.forms.filter.universities.additional;
        }

        return currentFilter;
      },

      getFiltersScientificSpecialties(areasId) {
        this.filtersScientificSpecialties = {scientific_areas_id: areasId}
      },

      resetFilter() {
        this.$store.commit('form/updateField', {model: 'filter.universities.search', value: ''});
        this.$store.commit('updateParam', {model: 'pagination.universities.main.page', value: 1});
        this.$store.commit('updateParam', {model: 'pagination.universities.additional.page', value: 1});
        this.$store.commit('updateParam', {model: 'pagination.universities.main.length', value: 10});
        this.$store.commit('updateParam', {model: 'pagination.universities.additional.length', value: 10});

        if (this.typeFilter === 'main') {
          this.$store.commit('form/updateField', {model: 'filter.universities.main', value: {}});
          this.getCites();
        } else {
          this.$store.commit('form/updateField', {model: 'filter.universities.additional', value: {}});
        }

        this.getFilterData();
        this.getData(this.typeFilter, true, true);
        this.filtersScientificSpecialties = {}
      },

      getData(typeFilter, updateFilter, resetPagination) {
        this.$store.commit('preloader/isLoading', true)
        let currentFilter = this.getFilter();

        if (updateFilter || _.isEmpty(this.allFilters)) {
          this.allFilters = {
            district: currentFilter && currentFilter.district,
            city: currentFilter && currentFilter.city,
            directionId: currentFilter && currentFilter.groupDirection,
            programId: currentFilter && currentFilter.educationalProgram,
            sciAreaId: this.isLevelEducation && currentFilter ? currentFilter.scientificAreas : null,
            sciSpecialtyId: this.isLevelEducation && currentFilter ? currentFilter.scientificSpecialties : null,
            levelId: currentFilter.levelEducation,
            ratings: currentFilter.ratings,
            q: this.forms.filter.universities.search,
            additionalProgs: typeFilter === 'main' ? null : 1,
            hideAdditionalProgs: typeFilter === 'main' ? 1 : null,
            semester: currentFilter.semester,
          }
        }

        if (!resetPagination) {
          this.allFilters.perPage = typeFilter === 'additional'
            ? _.get(this.pagination, 'universities.additional.length', 10)
            : _.get(this.pagination, 'universities.main.length', 10);

          this.allFilters.page = typeFilter === 'additional'
            ? _.get(this.pagination, 'universities.additional.page', null)
            : _.get(this.pagination, 'universities.main.page', null);
        } else {
          this.allFilters.page = 1;

          if (typeFilter === 'additional') {
            this.$store.commit('updateParam', {model: 'pagination.universities.additional.page', value: 1});
            this.$store.commit('updateParam', {model: 'pagination.universities.additional.length', value: 10});
          } else {
            this.$store.commit('updateParam', {model: 'pagination.universities.main.page', value: 1});
            this.$store.commit('updateParam', {model: 'pagination.universities.main.length', value: 10});
          }
        }

        this.getUniversities(this.allFilters)
          .then(res => {
            if (res.status === 'success') {
              this.preloader = false;
              if (typeFilter === 'main') {
                this.$store.commit('educationInRussia/updateParam', {model: 'universities.main', value: res.data});
              } else {
                this.$store.commit('educationInRussia/updateParam', {model: 'universities.additional', value: res.data});
              }
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      onChangeDistrict() {
        this.$store.commit('form/updateField', {model: 'filter.universities.main.city', value: null});
        this.getCites();
      },

      getCites() {
        this.$store.commit('preloader/isLoading', true)
        this.getUniversitiesGetCities({
          district: this.forms.filter.universities.main.district,
        }).then(res => {
          this.universitiesGetCities = helpers.options(res);

          if (!this.universitiesGetCitiesAdditional.length) {
            this.universitiesGetCitiesAdditional = helpers.options(res);
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      updateFilters(filter) {
        this.$store.commit('preloader/isLoading', true)
        let currentFilter = this.getFilter();
        let _groupDirection = _.clone(currentFilter.groupDirection);

        if (filter === 'levelEducation') {
          if (this.typeFilter === 'main') {
            this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: null});
          } else {
            this.$store.commit('form/updateField', {model: 'filter.universities.additional.groupDirection', value: null});
          }
        }

        if (filter === 'scientificAreas') {
          this.getFiltersScientificSpecialties(currentFilter.scientificAreas)
        }

        this.getEducationFilter({
          hideAdditionalProgs: this.typeFilter === 'main' ? 1 : null,
          showOnlyAdditionalProgs: this.typeFilter === 'main' ? null : 1,
          levelId: currentFilter.levelEducation,
          directionId: currentFilter.groupDirection,
          sciAreaId: this.isLevelEducation ? currentFilter.scientificAreas : null,
          sciSpecialtyId: this.isLevelEducation ? currentFilter.scientificSpecialties : null,
          programId:  filter === 'levelEducation' || filter === 'groupDirection' ? null : currentFilter.educationalProgram,
        }).then(res => {
          if (filter === 'levelEducation') {
            this.groupDirections = _.map(res.data.education_direction, value => ({value: value.id, name: value.name}));

            if (!_.find(this.groupDirections, {value: _groupDirection})) {
              this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: null});
            } else {
              this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: _groupDirection});
            }
          }

          if (filter === 'groupDirection' || filter === 'levelEducation') {
            if (this.typeFilter === 'main') {
              this.educationalPrograms = _.map(res.data.education_program, value => ({value: value.id, name: value.name}));

              if (!_.find(this.educationalPrograms, {value: currentFilter.educationalProgram})) {
                this.$store.commit('form/updateField', {model: 'filter.universities.main.educationalProgram', value: null});
              }
            } else {
              this.educationalProgramsAdditional = _.map(res.data.education_program, value => ({value: value.id, name: value.name}));

              if (!_.find(this.educationalProgramsAdditional, {value: currentFilter.educationalProgram})) {
                this.$store.commit('form/updateField', {model: 'filter.universities.additional.educationalProgram', value: null});
              }
            }
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      onChangeSelect(filter) {
        if(filter !== 'levelEducation')
          return;

        let {levelEducation} = this.getFilter('main', 'program');
        const selectedOption = this.levelEducation.filter(level => level.value === levelEducation);
        const selectedOptionValue = selectedOption[0].value;

        this.isLevelEducation = Number(selectedOptionValue) === this.postgraduateNumber
            ? true
            : false;
      },

      getFilterData() {
        this.$store.commit('preloader/isLoading', true)
        this.getEducationalPrograms({
          hideAdditionalProgs: 1,
        }).then(res => {
          this.educationalPrograms = helpers.options(res);
        }).finally(() => this.$store.commit('preloader/isLoading', false));

        this.getEducationalPrograms({
          showOnlyAdditionalProgs: 1,
        }).then(res => {
          this.educationalProgramsAdditional = helpers.options(res);
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      getCountUniversities(typeFilter) {
         this.$store.commit('preloader/isLoading', true)
        let currentFilter = this.getFilter();

        this.getUniversitiesCountByFilter({
          district: currentFilter.district,
          city: currentFilter.city,
          directionId: currentFilter.groupDirection,
          programId: currentFilter.educationalProgram,
          levelId: currentFilter.levelEducation,
          ratings: currentFilter.ratings,
          semester: currentFilter.semester,
          sciAreaId: this.isLevelEducation && currentFilter ? currentFilter.scientificAreas : null,
          sciSpecialtyId: this.isLevelEducation && currentFilter ? currentFilter.scientificSpecialties : null,
          additionalProgs: typeFilter === 'main' ? null : 1,
        }).then(res => {
          if (typeFilter === 'main') {
            this.totalMainUniversities = res.data;
          } else {
            this.totalAdditionalUniversities = res.data;
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      window.scroll(0, 0)
      this.getCountUniversities('additional');
      this.getCountUniversities('main');

      if (this.$route.query.typeFilter === 'additional') {
        this.typeFilter = 'additional'
      }

      if (this.$route.query.typeFilter === 'main') {
        this.typeFilter = 'main'
      }

      this.getDistricts().then(res => {
        this.districts = helpers.options(res);
      });

      this.getSemesters().then(res => {
        this.semesters = helpers.options(res);
      });

      this.getLevelEducation().then(res => {
        this.levelEducation = helpers.options(res);
      });

      this.getCites();

      this.getGroupDirections().then(res => {
        this.groupDirections = helpers.options(res);
        this.groupDirectionsAdditional = helpers.options(res);
      });

      this.getRatingsFilter().then(res => {
        this.ratingsFilter = _.map(res, option => ({name: option.name, value: option.id}));
      });

      this.getScientificAreas().then(res => {
        this.scientificAreas = helpers.options(res.data);
      });


      this.getData('additional', true, true);
      this.getData('main', true, true);
      this.getFilterData();
    },

  watch: {
      'forms.filter.universities.main.typeOfEducation'() {
         this.getCountUniversities(this.typeFilter);
      },
      'forms.filter.universities.additional.typeOfEducation'() {
         this.getCountUniversities(this.typeFilter);
      },
      '$route.query.typeFilter'() {
        if (this.typeFilter === 'main') {
          if (!this.universitiesMainList.data.length) {
            this.getData('main');
          }
        } else {
          if (!this.universitiesAdditionalList.data.length) {
            this.getData('additional');
            this.getCountUniversities('additional');
          }
        }
      },

      'forms.filter.universities.search'(valNext) {
        if (valNext === '') {
          this.getData(this.typeFilter, true, true);
        }
      },

    },
  };
</script>
