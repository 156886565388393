export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Tolonglah",
  support: "Sokongan teknikal",
  personalArea: "Kawasan Peribadi",
  myApplications: "Aplikasi saya",
  servicesAndFacilities: "Perkhidmatan dan Kemudahan",
  logout: "Log keluar",
  favorites: "Kegemaran",
  theChoice: "Pilihan",
  notifications: "Pemberitahuan",
  logIn: "Untuk masuk",
  quickAccessThroughSocialNetworks: "Log masuk pantas melalui rangkaian sosial",
  enterYourEmail: "Masukkan emel anda",
  password: "Kata Laluan",
  enterPassword: "masukkan kata laluan",
  rememberMe: "Untuk mengingati saya",
  forgotYourPassword: "Lupa kata laluan anda?",
  noAccount: "Tiada akaun?",
  registration: "pendaftaran",
  countryOfCitizenship: "Negara Kewarganegaraan",
  chooseYourCountryOfCitizenship: "Pilih negara yang mempunyai kerakyatan",
  iHaveReadAndAgree: "Saya telah membaca dan memberi",
  _toTheProcessingOfMyPersonalData:
    "persetujuan untuk memproses data peribadi saya",
  iAgreeTo: "Saya bersetuju untuk",
  _theUseOfCookies: "penggunaan kuki",
  signUp: "Mendaftar",
  haveAnAccount: "Ada akaun?",
  mainPage: "Muka surat utama",
  frequentlyAskedQuestionsAnswers: "Jawapan Soalan Lazim",
  goTo: "Pergi ke",
  forum: "Forum",
  textForumBanner:
    "Bincangkan di forum umum semua topik yang penting bagi anda: bagaimana anda tinggal di bandar-bandar Rusia, bagaimana anda belajar di universiti-universiti di Rusia, dll.",
  feedback: "Maklum balas",
  technicalSupportFeedbackForm: "Borang Maklum Balas Sokongan Teknikal",
  allCities: "semua bandar",
  citiesOfRussia: "Bandar-bandar di Rusia",
  educationInRussia: "Pendidikan di Rusia",
  educationalOrganizations: "Organisasi pendidikan",
  educationInRussiaYourKeyToSuccess:
    "Pendidikan di Rusia adalah kunci kejayaan anda",
  applyForStudyInRussia: "Memohon untuk belajar di Rusia",
  apply: "Memohon",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Log masuk atau daftar untuk mengakses bimbingan kerjaya dan memohon",
  aboutOrganization: "Mengenai Organisasi",
  eventsAndOlympiads: "Acara dan Sukan Olimpik",
  educationalPrograms: "Program pendidikan",
  contacts: "Kenalan",
  inFavorites: "Di kegemaran",
  toFavorites: "Ke kegemaran",
  fromChoices: "Terpilih",
  choices: "Pilih",
  generalInformation: "maklumat am",
  internationalRatings: "Peringkat antarabangsa",
  nationalRatings: "Peringkat Kebangsaan",
  ratings: "Peringkat",
  year: "Tahun",
  ratingName: "Nama Penarafan",
  aPlace: "Sebuah tempat",
  screeningTests: "Ujian Saringan",
  choiceOfProfession: "Pilihan profesion",
  lifeInRussia: "Kehidupan di Rusia",
  russianLanguage: "Bahasa Rusia",
  livingConditions: "Keadaan hidup",
  cultureAndLeisure: "Budaya dan riadah",
  safety: "Keselamatan",
  benefitsForInternationalStudents: "Faedah untuk pelajar antarabangsa",
  workForInternationalStudents: "Bekerja untuk pelajar antarabangsa",
  studentVisaAndRegistration: "Visa dan pendaftaran pelajar",
  healthInsurance: "Insuran kesihatan",
  howToEnter: "Bagaimana untuk teruskan",
  educationLevels: "Tahap pendidikan",
  admissionProcess: "Proses kemasukan",
  olympics: "Sukan Olimpik",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Laman rasmi untuk pemilihan warga asing untuk belajar di Rusia, {year}",
  whyRussia: "Mengapa Rusia",
  "10reasonsToStudyInRussia": "10 sebab untuk belajar di Rusia",
  reviewsOfForeignStudents: "Ulasan pelajar asing",
  onlineMonitoring: "Pemantauan dalam talian",
  mainPrograms: "Program utama",
  additionalPrograms: "Program tambahan",
  chooseCounty: "Pilih daerah",
  county: "Daerah",
  chooseACity: "Pilih bandar",
  city: "Bandar",
  chooseYourLevelOfEducation: "Pilih tahap pendidikan anda",
  chooseYourAreaOfScientificKnowledge: "Pilih bidang pengetahuan saintifik",
  chooseYourScientificSpecialtyProfile: "Pilih profil kepakaran saintifik",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Tahap pendidikan",
  chooseAGroupOfTrainingAreas: "Pilih sekumpulan kawasan latihan",
  trainingDirectionGroup: "Kumpulan Arah Latihan",
  chooseATrainingDirection: "Pilih arah latihan",
  trainingDirection: "Arah latihan",
  chooseRatings: "Pilih penilaian",
  thePresenceOfThePreparatoryFaculty: "Kehadiran fakulti persediaan",
  show: "Tunjukkan",
  _universities: "universiti",
  clearAllFilters: "Kosongkan semua penapis",
  chooseSemester: "Pilih semester",
  semester: "Semester",
  enterTheNameOfTheEducationalOrganization:
    "Masukkan nama organisasi pendidikan",
  _educationalOrganizations: "organisasi pendidikan",
  chooseYourAreaOfEducation: "Pilih bidang pendidikan anda",
  areaOfEducation: "Bidang pendidikan",
  chooseYourLanguageOfInstruction: "Pilih bahasa pengantar anda",
  languageOfInstruction: "Bahasa pengantar",
  _programs: "program",
  formOfTraining: "Bentuk kajian",
  level: "Tahap",
  _years: "tahun ini",
  typeOfTraining: "Jenis latihan",
  free: "Percuma",
  paid: "Dibayar",
  tuitionFees: "Yuran tuisyen",
  established: "Ditubuhkan",
  numberOfStudents: "Bilangan pelajar",
  _students: "pelajar",
  numberOfQuotaPlaces: "Bilangan tempat kuota",
  _for: "pada",
  numberOfInternationalStudents: "Bilangan pelajar antarabangsa",
  _educationalPrograms: "program pendidikan",
  moreDetails: "Maklumat lanjut",
  sTEP: "LANGKAH",
  afterTrainingForThisProgramYouCanBecome:
    "Selepas latihan untuk program ini, anda boleh menjadi",
  chooseProgram: "Pilih program",
  chooseUniversity: "Pilih universiti",
  print: "Cetak",
  save: "Jimat",
  programs: "Program",
  selectedProgram: "Program terpilih",
  noProgramSelected: "Tiada program yang dipilih",
  selectedEducationalOrganizations: "Organisasi Pendidikan Terpilih",
  educationalOrganizationNotSelected: "Organisasi pendidikan tidak dipilih",
  representation: "Perwakilan",
  programDescription: "Penerangan program",
  programPageOnTheUniversityWebsite: "Halaman program di laman web universiti",
  programCurator: "Kurator program",
  disciplines: "Disiplin",
  theProcessOfEnteringARussianEducationalOrganization:
    "Proses memasuki organisasi pendidikan Rusia",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Pilih program dan sehingga 6 organisasi pendidikan",
  takeTheCompetitiveSelectionInYourCountry:
    "Ikuti pilihan kompetitif di negara anda",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Isi permohonan secara dalam talian dan lampirkan pakej dokumen",
  getAnInvitationToStudAnStudyVisa:
    "Dapatkan jemputan untuk belajar dan belajar visa",
  preparatoryFaculties: "Fakulti persediaan",
  summerAndWinterSchools: "Sekolah musim panas dan musim sejuk",
  russianLanguageCourses: "Kursus bahasa Rusia",
  tutorsTheRussianLanguage: "Mengajar bahasa Rusia",
  certificationInRussian: "Pensijilan dalam bahasa Rusia",
  news: "berita",
  allNews: "Semua berita",
  reviewsOfStudentsAndGraduates: "Ulasan pelajar dan graduan",
  areYouSureYouWantToWithdrawTheApplication:
    "Adakah anda pasti mahu menarik balik permohonan itu?",
  withdrawApplication: "Batalkan permohonan",
  jan: "Januari",
  feb: "Februari",
  mar: "Mac",
  apr: "April",
  may: "Mungkin",
  jun: "Jun",
  jul: "Julai",
  aug: "Ogos",
  sep: "September",
  act: "Oktober",
  nov: "November",
  dec: "Disember",
  cityFeatures: "Ciri-ciri bandar",
  enterTheNameOfTheCity: "Masukkan nama bandar",
  organizations: "Organisasi",
  livingWage: "Duit sara hidup",
  "one-roomApartmentForRent": "Pangsapuri 1 bilik untuk disewa",
  _month: "bulan",
  averageTemperature: "suhu purata",
  _inWinter: "pada musim sejuk",
  _inSummer: "pada musim panas",
  averageCostOfDormitories: "Kos purata asrama",
  photos: "Gambar",
  interestingPlaces: "Tempat menarik",
  readCompletely: "Baca sepenuhnya",
  _reviews: "ulasan",
  university: "Universiti",
  country: "Negara",
  selectACountry: "pilih negara",
  reviewOfAForeignStudent: "Ulasan pelajar asing",
  careerGuidance: "Bimbingan kerjaya",
  determineYourAreaOfEducationAndProfession:
    "Tentukan bidang pendidikan dan profesion anda",
  myCareer: "Kerjaya saya",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Tentukan kumpulan bidang latihan anda dan cari pilihan yang mungkin untuk profesion masa depan anda",
  toApply: "Untuk memohon",
  chooseYear: "Pilih tahun",
  _university: "universiti",
  __universities: "universiti",
  _educationalOrganization: "organisasi pendidikan",
  __educationalOrganizations: "organisasi pendidikan",
  _program: "program",
  __programs: "program",
  __reviews: "ulasan",
  universities: "Universiti",
  _in: "dalam",
  universitiesInThisCity: "Universiti di bandar ini",
  programsInThisCity: "Program di bandar ini",
  all: "Semua",
  _inYourCountry: "di negara awak",
  _common: "biasa",
  allReviews: "Semua ulasan",
  calendar: "Kalendar",
  activity: "Aktiviti",
  addToPersonalCalendar: "Tambahkan ke kalendar peribadi",
  eventAdded: "Acara ditambah",
  eventSuccessfullyAddedToYourCalendar:
    "Acara berjaya ditambahkan ke kalendar anda!",
  population: "Penduduk",
  onTheMap: "Di peta",
  generalEducation: "Pendidikan umum",
  professionalEducation: "Pendidikan profesional",
  preschoolEducation: "Pendidikan prasekolah",
  kindergarten: "Tadika",
  nursery: "Taska",
  earlyDevelopmentCenters: "Pusat pembangunan awal",
  primaryAndSecondaryEducation: "Pendidikan rendah dan menengah",
  schools: "Sekolah",
  lyceums: "Lyceum",
  gymnasium: "Gimnasium",
  secondaryVocationalEducation: "Pendidikan vokasional menengah",
  colleges: "Kolej",
  technicalSchools: "Sekolah teknik",
  secondarySchool: "Sekolah Menengah",
  higherEducation: "Higher education",
  baccalaureate: "Baccalaureate",
  specialistDegree: "Ijazah pakar",
  magistracy: "Majistret",
  postgraduateStudies: "pengajian pascasiswazah",
  preparatoryFaculty: "Fakulti Persediaan",
  summerSchools: "Sekolah musim panas",
  additionalGeneralAndPreVocationalPrograms:
    "Program am dan pra-vokasional tambahan",
  continuingEducationAndRetrainingPrograms:
    "Melanjutkan program pendidikan dan latihan semula",
  whatAreTheLevelsOfRussianEducation: "Apakah tahap pendidikan Rusia?",
  enterYourName: "Masukkan nama anda",
  name: "Nama",
  selectTheSubjectOfTheMessage: "Pilih subjek mesej",
  messageSubject: "Subjek mesej",
  chooseAMessage: "Pilih mesej",
  message: "Mesej",
  submit: "Hantar",
  frequentlyAskedQuestions: "Soalan Lazim",
  didNotFindTheAnswerToYourQuestion: "Tidak menemui jawapan untuk soalan anda?",
  contactSupportAt: "Hubungi sokongan di",
  searchInTheSection: "Cari di bahagian",
  sort: "Susun",
  searchingResults: "hasil carian",
  _answers: "jawapan",
  _views: "pandangan",
  byCreationDate: "Mengikut tarikh penciptaan",
  byTheNumberOfResponses: "Dengan bilangan tindak balas",
  byViews: "Oleh pandangan",
  toAnswer: "Untuk menjawab",
  toQuote: "Untuk memetik",
  complain: "Mengeluh",
  addNewMessage: "Tambahkan mesej baru",
  selectComplaintType: "Pilih jenis aduan",
  typeOfComplaint: "Jenis aduan",
  enterComplaintText: "Masukkan teks aduan",
  complaintText: "Teks aduan",
  complaintSentSuccessfully: "Aduan berjaya dihantar",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Aduan anda berjaya dihantar, kami akan menyemak aduan anda sebentar lagi!",
  close: "Tutup",
  myMessages: "Mesej Saya",
  myTopicsAndPosts: "Topik dan catatan saya",
  searchInMyPosts: "Cari di catatan saya",
  _posts: "jawatan",
  feedbackOnEducationalOrganizations:
    "Maklum balas mengenai organisasi pendidikan",
  posted: "Dihantar",
  goToMessage: "Pergi ke mesej",
  _themes: "tema",
  themeTitle: "Tajuk tema",
  enterAThemeName: "Masukkan nama topik",
  cancel: "Batal",
  banUser: "Larang pengguna?",
  areYouSure: "Adakah anda pasti?",
  toBan: "Untuk melarang",
  _logIn: "log masuk",
  _or: "atau",
  _signUp: "daftar",
  eventCalendar: "Kalendar Acara",
  trainingApplicationsFiled: "Permohonan latihan difailkan",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Organisasi pendidikan paling popular yang dipilih oleh pemohon",
  _of: "semasa memohon",
  _whenApplying: "semasa memohon",
  tOP10EducationalOrganizations: "TOP 10 Organisasi Pendidikan",
  collapse: "Runtuhkan",
  showAll: "Tunjukkan semua",
  conditionsForForeigners: "Syarat untuk warga asing",
  supportForInternationalStudents: "Sokongan untuk pelajar antarabangsa",
  lifeOfForeignStudents: "Kehidupan pelajar asing",
  leisureAndSportingEvents: "Acara riadah dan sukan",
  dormitories: "Asrama",
  dormitoryCost: "Kos asrama",
  theNumberOfPeopleInTheRoom: "Jumlah orang di dalam bilik",
  from: "Dari",
  _rubles: "rubel",
  _to: "ke",
  _perMonth: "sebulan",
  "2-3People": "2-3 orang",
  theInternationalCooperation: "Kerjasama antarabangsa",
  photoAndVideo: "Foto dan video",
  eventTitle: "Tajuk acara",
  location: "Lokasi",
  date: "Tarikh",
  enterYourRealMail: "Masukkan surat sebenar anda",
  requiredField: "Medan wajib",
  minimumLengthCharacters: "Panjang minimum {length} aksara",
  maximumLengthCharacters: "Panjang maksimum {length} aksara",
  theFieldMustBeANumber: "Medan mestilah nombor",
  onlyLettersAreAllowedInTheField: "Hanya surat yang dibenarkan di lapangan",
  checkKeyboardLayout: "Periksa susun atur papan kekunci",
  checkTheNumberYouEnteredIsCorrect: "Periksa nombor yang anda masukkan betul",
  olympiadName: "Nama Olimpik",
  description: "Penerangan",
  contactInformation: "Maklumat perhubungan",
  majorForeignPartnerUniversities: "Universiti rakan kongsi asing utama",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Ketahui mengenai bentuk, program dan yuran pengajian bahasa Rusia",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Adakah anda seorang tutor? Kirimkan profil untuk menerbitkan profil anda di laman web ini",
  submitApplication: "Hantar Permohonan",
  chooseATeachingExperience: "Pilih pengalaman mengajar",
  teachingExperience: "Pengalaman mengajar",
  chooseLanguages: "Pilih bahasa",
  knowledgeOfLanguages: "Pengetahuan bahasa",
  workExperience: "Pengalaman kerja",
  trainingLevel: "Tahap latihan",
  durationOfOneLesson: "Tempoh satu pelajaran",
  possibleTrainingTime: "Masa latihan yang mungkin",
  theCosOfOneLesson: "Kos satu pelajaran",
  writeAMessage: "Tulis mesej",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Anda boleh menulis surat kepada tutor selepas pendaftaran atau kebenaran di laman web ini",
  educationDocuments: "Dokumen Pendidikan",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Anda telah memilih program lain, jika anda memilih program ini, program dan organisasi yang dipilih akan hilang.",
  limitIsExceeded: "Had melebihi",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Anda berpeluang memilih tidak lebih dari 6 organisasi pendidikan.",
  _fieldsOfEducation: "bidang pendidikan",
  _trainingAreas: "kawasan latihan",
  _cities: "bandar-bandar",
  _languagesOfInstruction: "bahasa pengantar",
  _typesOfAdditionalPrograms: "jenis program tambahan",
  _semesters: "semester",
  chooseDurationOfStudy: "Pilih tempoh pengajian",
  _durationOfStudy: "tempoh pengajian",
  enterTheNameOfTheEducationalProgram: "Masukkan nama program pendidikan",
  instruction: "Arahan",
  profile: "Profil",
  back: "Belakang",
  next: "Seterusnya",
  testReport: "Laporan ujian",
  testDate: "Tarikh Ujian",
  duration: "Tempoh",
  memberName: "Nama ahli",
  age: "Umur",
  gender: "Jantina",
  currentLevelOfEducation: "Tahap pendidikan semasa",
  areasOfInterestHobbies: "Kawasan Menarik, Hobi",
  sportsAchievements: "Pencapaian sukan",
  testScaleResults: "Keputusan Skala Ujian",
  scaleDescription: "Huraian Skala",
  recommendedEducationAreas: "Kawasan Pendidikan yang Disyorkan",
  enterYourAge: "Masukkan usia anda",
  enterYourGender: "Masukkan jantina anda",
  chooseYourCurrentLevelOfEducation: "Pilih tahap pendidikan anda sekarang",
  chooseYourAreasOfInterestHobbies: "Pilih bidang minat, hobi anda",
  chooseYourSportingAchievements: "Pilih pencapaian sukan anda",
  legalizationOfDocuments: "Pengesahan dokumen",
  consularLegalization: "Pengesahan konsular",
  edit: "Edit",
  mobilePhoneNumber: "Nombor Telefon Bimbit",
  iWantToReceiveNotificationsByEmail:
    "Saya mahu menerima pemberitahuan melalui e-mel",
  authorizationCheck: "Pemeriksaan Kebenaran",
  createANewApplication: "Buat aplikasi baru",
  selectionStage: "peringkat pemilihan",
  underRevision: "Di bawah audit",
  fillingOutAnApplication: "Mengisi permohonan",
  verificationAndRevision: "Pengesahan dan semakan",
  dossierFormation: "Fail latihan",
  fillingInTheQuestionnaire: "Mengisi soal selidik",
  attachingDocuments: "Melampirkan Dokumen",
  sendingApplicationForVerification: "Menghantar permohonan untuk pengesahan",
  personalData: "Data peribadi",
  surnameInLatinLettersAccordingToPassport:
    "Nama keluarga, dalam huruf Latin (mengikut pasport)",
  nameInLatinLettersAccordingToPassport:
    "Nama, dalam huruf Latin (mengikut pasport)",
  placeOfBirthAccordingToPassport: "Tempat lahir (mengikut pasport)",
  dateOfBirth: "Tarikh lahir",
  compatriotStatus: "Status kompatriot",
  citizenshipPlaceOfPermanentResidence:
    "Kewarganegaraan (tempat kediaman tetap)",
  online: "Dalam talian",
  profile2: "Profil",
  goToTheApplicationForm: "Pergi ke borang permohonan",
  areYouHere: "Adakah awak di sini",
  russianTextDes1:
    "Bagaimana persediaan untuk belajar di universiti Rusia, mata pelajaran apa yang dipelajari di fakulti persediaan dan berapa lama masa yang diperlukan",
  russianTextDes4:
    "Ketahui mengenai bentuk kursus, program dan yuran pengajian bahasa Rusia",
  russianTextDes5:
    "Bagaimana persediaan untuk belajar di universiti Rusia, mata pelajaran apa yang dipelajari di fakulti persediaan dan berapa lama masa yang diperlukan",
  typeOfProgram: "Jenis program",
  chooseTheTypeOfAdditionalProgram: "Pilih jenis program tambahan",
  durationOfStudy: "Tempoh pengajian",
  common: "Biasa",
  inYourCountry: "Di negara awak",
  courseName: "Nama kursus",
  courseDuration: "Tempoh kursus",
  classDuration: "Tempoh kelas",
  courseCost: "Kos kursus",
  courseReviews: "Ulasan Kursus",
  male: "Lelaki",
  female: "Perempuan",
  feedbackLink: "Pautan maklum balas",
  russianLanguageCoursesInYourCountry: "Kursus bahasa Rusia di negara anda",
  evaluation: "Penilaian",
  giveFeedback: "Beri maklum balas",
  newFeedback: "Maklum balas baru",
  _hour: "Jam",
  _hours: "Jam",
  __hours: "Jam",
  _minute: "minit",
  _minutes: "minit",
  __minutes: "minit",
  create: "Buat",
  feedbackSuccess:
    "Maklum balas berjaya dihantar dan sedang menunggu penyederhanaan",
  _y: "dan",
  _from: "dari",
  error: "Ralat",
  direction: "Arah",
  _tutor: "tutor",
  _tutors: "tutor",
  __tutors: "tutor",
  sendMessage: "Menghantar mesej",
  sendMessageSuccess: "Mesej berjaya dihantar",
  _year: "tahun",
  __years: "tahun",
  to_begin: "any am-piandohana",
  _next: "semakin jauh",
  showing: "Tunjuk",
  textRussianLanguageCourses:
    "Pilih negara tempat tinggal anda dari menu dan lihat kursus bahasa dan sekolah yang tersedia. Memperoleh tahap asas bahasa Rusia di rumah akan memudahkan penyesuaian ketika bergerak.",
  filters: "Penapis",
  middleNameIAnyCyrillicInRussianTranscription:
    "Patronymic (jika ada), Cyrillic dalam transkripsi Rusia",
  tOP10Programs: "Program TOP-10",
  tOP10City: "TOP-10 Bandar Rusia",
  createANewTopic: "Buat topik baru",
  topik: "Topik",
  posting: "Posts",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "Fail format .pdf, .jpg, .png hingga 10 MB",
  noFileSelected: "Tidak ada fail yang dipilih",
  attachFile: "Lampirkan fail",
  eMail: "E-mel",
  fullTime: "Sepenuh masa",
  toFind: "Untuk Mencari",
  chat: "Bot sembang",
  yourMessageChat: "Mesej anda",
  searchChat: "Cari dialog?",
  firstMsgChat: "Masukkan mesej anda di sini",
  categoryBtnChat_1: "Soalan tentang penyerahan permohonan",
  categoryBtnChat_2: "Soalan tentang pendidikan",
  categoryBtnChat_3: "Soalan tentang kemasukan kompetitif",
  categoryBtnChat_4: "Cadangan atau komen",
  categoryBtnChat_5: "Maklum balas tentang aktiviti universiti",
  categoryBtnChat_6: "Aduan tentang kualiti perkhidmatan",
  categoryBtnChat_7: "Permintaan sokongan teknikal",
  notQuestionResolvedChat: "Isu saya belum diselesaikan",
  questionResolvedChat: "Isu saya telah diselesaikan",
  countrySelectTrueChat: "Negara saya dinyatakan dengan betul",
  salutationChat: "Helo,",
  categoryAppealChat:
    'Untuk mendapatkan maklumat yang diperlukan, klik butang yang sesuai dengan kategori pertanyaan anda, kemudian taipkan soalan anda dalam kotak mesej dan klik "Serah".',
  langAppealChat:
    "Sila pilih bahasa yang ingin anda gunakan untuk menyerahkan permintaan anda. Permintaan dalam bahasa Rusia dan Inggeris diproses dengan lebih cepat.",
  youCountryChatPath1: "Negara awak",
  youCountryChatPath2:
    "Sekiranya tidak, sila berikan nama balasan nama negara.",
  notFoundCountryChat:
    "Negara yang anda nyatakan tidak ditemui. Sila semak input anda dan serahkan semula mesej.",
  additionalInfoChat:
    "Sila berikan maklumat tambahan berkenaan permintaan anda dalam mesej balasan.",
  yourEmailChat:
    "Sila nyatakan alamat e-mel untuk menerima balasan kepada permintaan anda.",
  notValidEmailChat:
    "Alamat e-mel yang anda nyatakan tidak sepadan dengan format aaa@bbb.ccc. Sila semak input anda dan serahkan semula mesej.",
  acceptRequestChat:
    "Permintaan anda telah diserahkan kepada operator. Purata masa penyelesaian ialah 7 hari kalendar.",
  langBtnChat_1: "Bahasa Inggeris",
  langBtnChat_2: "Orang Rusia",
  langBtnChat_3: "Yang lain",
  listAppBtn: "Senarai aplikasi",
  addAppBtn: "Mengemukakan sebuah permohonan",
  startTesting: "Mulakan ujian",
  buildCareerVector: "Membina vektor kerjaya",
  allEvents:"Semua acara",
  support: "Sokongan Teknikal",
  faq: "Soalan Lazim",
  userGuide: "Panduan Pengguna",
  textUserGuide: "Cara mengendalikan laman web dan membuat permohonan untuk belajar",
  textRegisterGuide: "Pendaftaran Pengajian di Rusia",
  textTrainingGuide: "Memohon pengajian menerusi Pengajian di Rusia",
  textApplication: "Melengkapkan borang",
  instructionForWork: "Arahan proses permohonan",
  appeals: "Rayuan",
  handling: "Pertanyaan",
  __students: "daripada pelajar",
  _student: "pelajar",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Tentukan pilihan bidang pengajian anda dan dapatkan maklumat tentang pilihan kerjaya anda",
  delTheme: "Padam subjek",
  competitionForTheFirstPlace: "Persaingan bagi setiap penempatan dalam kuota Persekutuan Rusia (bil. orang)",
  ruble: "rubel",
  __rubles: "rubel",
  dateOfEvent: "Tarikh acara",
  durationUnit: "bln",
  numberPhone: "No. Telefon ",
  offline: "Luar talian",
  to_begin: "kembali ke atas",
  theDateOfThe: "Tarikh mula",
  chat: "Bot sembang",
  yourMessageChat: "Mesej anda",
  searchChat: "Cari dialog",
  firstMsgChat: "Masukkan teks mesej",
  categoryBtnChat_1: "Soalan mengenai permohonan",
  categoryBtnChat_2: "Soalan mengenai kajian",
  categoryBtnChat_3: "Pertanyaan mengenai penyaringan kompetitif",
  categoryBtnChat_4: "Komen atau cadangan",
  categoryBtnChat_5: "Maklum balas perkhidmatan universiti",
  categoryBtnChat_6: "Aduan perkhidmatan",
  categoryBtnChat_7: "Hubungi sokongan teknikal",
  notQuestionResolvedChat: "Isu saya tidak diselesaikan",
  questionResolvedChat: "Isu saya telah diselesaikan",
  countrySelectTrueChat: "Negara adalah betul",
  salutationChat: "Hai!",
  categoryAppealChat: "Untuk menerima maklumat, pilih kategori soalan anda dan klik padanya. Kemudian masukkan soalan anda di dalam petak mesej dan tekan Hantar.",
  langAppealChat: "Pilih bahasa pertanyaan anda. Pertanyaan dalam bahasa Rusia atau Inggeris lebih segera diproses.",
  youCountryChatPath1: "Negara anda",
  youCountryChatPath2: "Jika tidak disenaraikan, nyatakan negara dalam mesej balasan.",
  youCountryChat: "Negara anda {country}? Jika tidak disenaraikan, nyatakan negara dalam mesej balasan.",
  notFoundCountryChat: "Tiada negara yang berkenaan. Pastikan maklumat anda adalah betul dan hantar semula.",
  additionalInfoChat: "Dalam balasan anda, sila berikan maklumat tambahan untuk pertanyaan anda.",
  yourEmailChat: "Sila sertakan alamat e-mel anda. Pertanyaan anda akan dijawab melalui e-mel.",
  notValidEmailChat: "Alamat e-mel yang anda berikan bukan dalam format aaa@bbb.ccc. Pastikan maklumat anda adalah betul dan hantar semula.",
  acceptRequestChat: "Pertanyaan anda telah dihantar kepada pengendali. Purata tempoh pemprosesan ialah 7 hari kalendar. ",
  notFoundEmployeeChat: "Negara yang anda namakan tidak termasuk dalam senarai negara yang layak untuk belajar di bawah program Rossotrudnichestvo. Pertanyaan anda tidak dapat diproses. Sila nyatakan negara lain. Untuk senarai lengkap program pengajian, sila rujuk <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>'",
  langBtnChat_1: "Bahasa Inggeris",
  langBtnChat_2: "Bahasa Rusia",
  langBtnChat_3: "Lain-lain",
  addAppBtn: "Mohon",
  listAppBtn: "Senarai permohonan",
  privacyPolicy: "Dasar Kerahsiaan",
  ___programs: "program",
  ___educationalPrograms: "program pengajian",
  __educationalPrograms: "program pengajian",
  editTopic: "Edit subjek",
  editMessage: "Edit mesej",
  deleteMessage: "Padam mesej",
  blockingUser: "Sekat pengguna",
  blockEndDate: "Menyekat tarikh akhir",
  blockEndTime: "Menyekat masa akhir",
  blockForever: "Sekat secara kekal",
  block: "Sekat",
  unlock: "Nyahsekat",
  edited: "Diedit",
  founder: "Pengasas",
  abbreviation: "Singkatan",
  studentsByCountry: "Bilangan pelajar mengikut negara asal",
  studentsByCountryCount: "Bilangan pelajar yang sedang belajar",
  contactsInternationalDepartment: "Maklumat hubungan Pejabat Antarabangsa",
  site: "Laman web",
  address: "Alamat",
  format: "Format",
  typeEvent: "Jenis acara",
  quotaLearning: "Pengajian kuota",
  kcp: "Kuota Kemasukan (AQ)",
  countSeatsKCP: "Bil. penempatan AQ",
  countSeatsCostTraining: "Bil. penempatan dengan tuisyen penuh",
  distanceLearning: "Pengajian jarak jauh",
  notificationAboutDistanceLearning: "Program ini boleh diikuti melalui pengajian jarak jauh dengan penggunaan teknologi pembelajaran jarak jauh. Hubungi kolej untuk maklumat lanjut.",
  additionalTests: "Tarikh ujian saringan tambahan",
  pageNotFound: "Halaman tidak dijumpai",
  goToMainPage: "Pergi ke laman utama",
  faqInformation: "Untuk hasil terbaik, semua pertanyaan yang berkaitan dengan status pengesahan permohonan, senarai dokumen kemasukan yang diperlukan, tarikh ujian saringan atau sebarang pertanyaan pendaftaran lain hendaklah dirujuk ke pejabat Rossotrudnichestvo atau Kedutaan Rusia yang diberi kuasa untuk menjalankan pemeriksaan di negara anda. Maklumat hubungan Rossotrudnichestvo terdapat dalam akaun anda.",
  allEvents: "Semua acara",
  eventsAndNews: "Acara dan Berita",
  titleNews: "Tajuk berita",
  numberOfBudgetPlaces: "Bil. penempatan bersubsidi",
  russianGovernmentQuota: "Kuota Kerajaan Rusia",
  legalAddress: "Alamat berdaftar",
  actualAddress: "Lokasi fizikal",
  goToTheRegister: "Pergi ke senarai program tambahan",
  searchForSuitablePrograms: "Cari aplikasi yang sesuai",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Program pengajian yang sering dipilih oleh calon pelajar",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Bandar raya Rusia yang sering dipilih oleh calon pelajar",
  ofTheCountry: "dari negara",
  passed: "Lengkap",
  question: "Pertanyaan",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Laman web ini berada dalam mod operasi percubaan.",
  banner_2: "Kepada para pengguna yang dihormati, buat masa sekarang mungkin terdapat beberapa masalah teknikal semasa mengguna alamat mel Google. Sila gunakan perkhidmatan mel lain semasa mengakses laman web ini.",
  banner_3: "Kempen kemasukkan akan bermula daripada 24 Disember 2022.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
