<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="university__page-container" v-if="page.slug">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url('${page.banner_image}');`"></div>

        <div class="container">
          <Breadcrumbs :items="breadcrumbs"/>

          <h1 class="title-1">{{ page | lang('name') }}</h1>
        </div>
      </div>

      <div class="container">
        <div class="universities__info">
          <div
              class="row"
              v-for="(value, key) in getContent()"
              v-bind:key="key"
          >
            <div class="col-4" v-if="value.type === 'html' || value.type === ''" v-html="value.html"/>
            <div class="col-2 goto-button-block"
                 v-if="page.name === $t('summerAndWinterSchools') && value.type === 'html'">
              <router-link class="btn goto-button" to="/education-in-russia/programs?typeFilter=additional">
                {{ $t('searchForSuitablePrograms') }}
              </router-link>
            </div>
            <div class="col-4" v-if="value.type === 'htmlLight'">
              <div class="important-info" v-html="value.html"/>
            </div>

            <div class="col-6" v-if="value.type === 'accordion'">
              <Accordion :items="value.items"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.goto-button-block {
  display: flex;
  justify-content: center;
}

.goto-button {
  padding: 10px !important;
  max-width: 200px;
  max-height: 64px;
  line-height: 20px !important;
}
</style>
<script>
import Accordion from '@/components/site/pages/generic/Accordion';
import Breadcrumbs from '@/components/generic/Breadcrumbs';
import {mapActions, mapGetters} from 'vuex';
import Vue from 'vue';

export default {
  name: 'dynamic-pages',

  components: {
    Accordion,
    Breadcrumbs,
  },

  computed: {
     ...mapGetters({
      user: 'users/getUser',
    }),
  },

  data() {
    return {
      filterLang: Vue.filter('lang'),
      page: {},

      breadcrumbs: [],
      accessIsLimited: ['faq-rzu'],
      RZU: '01',
      ROSS:'02',
    };
  },

  methods: {
    ...mapActions({
      getPage: 'getPage',
      getPageBreadcrumb: 'getPageBreadcrumb',
    }),

    getUserInfo() {
        if (this.user && (this.user.org_id === this.RZU || this.user.org_id === this.ROSS)) {
          this.getData()
        } else {
          this.$router.push({name: '404', path: '/404'})
          this.$router.go()
        }
    },

    getData() {
      this.$store.commit('preloader/isLoading', true)
      this.getPage(this.$route.params.slug)
          .then(res => {
            if (res.status === 404) {
              this.$router.push({name: '404', path: '/404'})
              this.$router.go()
            }
            if (res.status === 'success') {
              this.page = res.data;

              this.getPageBreadcrumb(this.page.id).then(r => {
                this.breadcrumbs = [
                  {
                    url: '/',
                    title: this.$t('mainPage'),
                  },
                ];

                if (r.data[0] && r.data[0].third) {
                  r.data[0].third.title = r.data[0].third.name;
                  this.breadcrumbs.push(r.data[0].third)
                }

                if (r.data[0] && r.data[0].second) {
                  r.data[0].second.title = r.data[0].second.name;
                  this.breadcrumbs.push(r.data[0].second)
                }

                if (r.data[0] && r.data[0].first) {
                  r.data[0].first.title = r.data[0].first.name;
                  this.breadcrumbs.push(r.data[0].first)
                }

                if (r.data[0] && r.data[0].children) {
                  r.data[0].children.title = r.data[0].children.name;
                  this.breadcrumbs.push(r.data[0].children)
                }
              })
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    getContent() {
      return this.page.content
          ? JSON.parse(
              this.filterLang(this.page, 'content')
                  .split('oembed')
                  .join('iframe')
                  .split('url')
                  .join('src')
                  .split('youtu.be')
                  .join('youtube.com/embed')
                  .split('watch?v=')
                  .join('embed/')
                  .replace('&', '')) : [];
    },
  },

  mounted() {
    if (this.accessIsLimited.includes(this.$route.params.slug)) {
      this.getUserInfo()
    } else {
      this.getData();
    }
  },

  watch: {
    $route() {
      this.getData();
    },
  },
};
</script>

