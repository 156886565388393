export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Hilfe ",
  personalArea: "Bewerberkonto",
  myApplications: "Meine Anträge",
  servicesAndFacilities: "Dienstleistungen und Services",
  logout: "Abmelden",
  favorites: "Favoriten",
  theChoice: "Auswahl",
  notifications: "Benachrichtigungen",
  logIn: "Anmelden",
  quickAccessThroughSocialNetworks:
    "Schnelle Anmeldung über die sozialen Netzwerke",
  enterYourEmail: "Geben Sie Ihre E-Mail-Adresse ein",
  password: "Passwort",
  enterPassword: "Geben Sie Ihr Passwort ein",
  rememberMe: "Anmeldung merken",
  forgotYourPassword: "Passwort vergessen?",
  noAccount: "Kein Konto?",
  registration: "Anmeldung",
  countryOfCitizenship: "Land der Staatsangehörigkeit",
  chooseYourCountryOfCitizenship: "Wählen Sie das Land der Staatsangehörigkeit",
  iHaveReadAndAgree: "Ich habe Kenntnis genommen und erteile ",
  _toTheProcessingOfMyPersonalData:
    "Zustimmung zur Bearbeitung meiner persönlichen Daten",
  iAgreeTo: "Ich gebe meine Zustimmung zur",
  _theUseOfCookies: "Verwendung von Cookie-Dateien",
  signUp: "Registrieren",
  haveAnAccount: "Sie haben bereits ein Konto?",
  mainPage: "Hauptseite",
  frequentlyAskedQuestionsAnswers: "Oft gestellte Fragen",
  goTo: "Wechseln",
  startTesting: "Mit dem Test beginnen",
  buildCareerVector: "Karriere-Vektor",
  forum: "Forum",
  textForumBanner:
    "Besprechen Sie auf dem gemeinsamen Forum alle Themen, die für Sie wichtig sind: Leben in russischen Städten, Studium an russischen Universitäten usw.",
  feedback: "Feedback",
  technicalSupportFeedbackForm: "Feedback-Form mit technischer Unterstützung",
  allCities: "Alle Städte",
  citiesOfRussia: "Städte Russlands",
  educationInRussia: "Studium in Russland",
  educationalOrganizations: "Universitäten",
  educationInRussiaYourKeyToSuccess:
    "Bildung in Russland — Ihr Schlüssel zum Erfolg",
  applyForStudyInRussia:
    "Stellen Sie einen Antrag auf Zulassung zum Studium in Russland",
  apply: "Antrag stellen",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Loggen Sie ein oder melden Sie sich an, um den Zugang zur Berufsorientierung und Antragstellung zu erhalten",
  aboutOrganization: "Über die Organisation",
  eventsAndOlympiads: "Veranstaltungen und Olympiaden ",
  educationalPrograms: "Programme",
  contacts: "Kontakte",
  inFavorites: "In den Favoriten",
  toFavorites: "In die Favoriten",
  fromChoices: "Ausgewählt",
  choices: "Auswählen",
  generalInformation: "Allgemeine Informationen",
  internationalRatings: "Internationale Ratings",
  nationalRatings: "Nationale Ratings",
  ratings: "Ratings",
  year: "Jahr",
  ratingName: "Bezeichnung des Ratings",
  aPlace: "Ort",
  screeningTests: "Auswahlprüfung",
  choiceOfProfession: "Berufswahl",
  lifeInRussia: "Leben in Russland",
  russianLanguage: "Russische Sprache",
  livingConditions: "Lebensbedingungen",
  cultureAndLeisure: "Kultur und Freizeit",
  safety: "Sicherheit",
  benefitsForInternationalStudents:
    "Vergünstigungen für ausländische Studenten",
  workForInternationalStudents: "Arbeit für ausländische Studenten",
  studentVisaAndRegistration: "Studienvisum und Anmeldung",
  healthInsurance: "Krankenversicherung",
  howToEnter: "Wie bewerbe ich mich?",
  educationLevels: "Bildungsstufen",
  admissionProcess: "Zulassungs- und Immatrikulationsverfahren",
  olympics: "Olympiaden",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Offizielle Webseite für die Auswahl ausländischer Bürger für das Studium in Russland, {year}",
  whyRussia: "Warum in Russland studieren",
  "10reasonsToStudyInRussia": "10 Gründe dafür, um in Russland zu studieren",
  reviewsOfForeignStudents: "Feedback ausländischer Studenten",
  onlineMonitoring: "Online-Monitoring",
  mainPrograms: "Grundprogramme",
  additionalPrograms: "Zusätzliche Programme",
  chooseCounty: "Wählen Sie den Bezirk aus",
  county: "Bezirk",
  chooseACity: "Wählen Sie die Stadt aus",
  city: "Städte",
  chooseYourLevelOfEducation: "Wählen Sie die Bildungsstufe aus",
  chooseYourAreaOfScientificKnowledge: "Wählen Sie einen wissenschaftlichen Wissensbereich",
  chooseYourScientificSpecialtyProfile: "Wählen Sie ein Profil einer wissenschaftlichen Spezialität",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Bildungsstand",
  chooseAGroupOfTrainingAreas: "Wählen Sie die Fachrichtungsgruppe aus",
  trainingDirectionGroup: "Fachrichtungsgruppe",
  chooseATrainingDirection: "Wählen Sie die Fachrichtung",
  trainingDirection: "Fachrichtung",
  chooseRatings: "Wählen Sie die Ratings aus",
  thePresenceOfThePreparatoryFaculty:
    "Vorhandensein einer Vorbereitungsfakultät",
  show: "Zeigen",
  _university: "Universität",
  _universities: "der Universität",
  __universities: "der Universitäten",
  clearAllFilters: "Alle Filter zurücksetzen",
  chooseSemester: "Wählen Sie das Semester aus",
  semester: "Semester",
  enterTheNameOfTheEducationalOrganization: "Geben Sie das Semester ein",
  _educationalOrganization: "Universität",
  _educationalOrganizations: "der Universität",
  __educationalOrganizations: "der Universitäten",
  chooseYourAreaOfEducation: "Wählen Sie das Bildungsgebiet aus",
  areaOfEducation: "Bildungsgebiet",
  chooseYourLanguageOfInstruction: "Wählen Sie die Unterrichtssprache aus",
  languageOfInstruction: "Unterrichtssprache",
  _program: "Programm",
  _programs: "Programme",
  __programs: "der Programme",
  formOfTraining: "Form der Ausbildung",
  level: "Niveau",
  typeOfTraining: "Art der Ausbildung",
  free: "Kostenlos",
  paid: "Kostenpflichtig",
  tuitionFees: "Ausbildungskosten",
  established: "Gründungsdatum",
  numberOfStudents: "Anzahl der Studenten",
  _students: "Studenten",
  numberOfQuotaPlaces: "Anzahl der Quotenstudienplätze",
  _for: "an",
  numberOfInternationalStudents: "Ausländische Studenten",
  _educationalPrograms: "der Bildungsprogramme",
  moreDetails: "Detailliert",
  sTEP: "SCHRITT",
  afterTrainingForThisProgramYouCanBecome:
    "Nach Abschluss dieses Programms können Sie werden",
  chooseProgram: "Programm auswählen",
  chooseUniversity: "Universität auswählen",
  print: "Ausdrucken",
  save: "Speichern",
  programs: "Programme",
  selectedProgram: "Ausgewähltes Programm",
  noProgramSelected: "Programm nicht ausgewählt",
  selectedEducationalOrganizations: "Ausgewählte Universitäten",
  educationalOrganizationNotSelected: "Universität nicht ausgewählt",
  representation: "Vertretung",
  programDescription: "Beschreibung des Programms",
  programPageOnTheUniversityWebsite:
    "Seite des Programms auf der Webseite der Universität",
  programCurator: "Betreuer des Programms",
  disciplines: "Fächer",
  theProcessOfEnteringARussianEducationalOrganization:
    "Verfahren der Zulassung und Immatrikulation an der russischen Universität",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Wählen Sie ein Programm und bis zu 6 Universitäten aus",
  takeTheCompetitiveSelectionInYourCountry:
    "Durchlaufen Sie die Auswahl in Ihrem Land",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Füllen Sie das Online-Bewerbungsformular aus und legen Sie das Dokumentenpaket bei",
  getAnInvitationToStudAnStudyVisa:
    "Erhalten Sie eine Einladung zum Studium und ein Studienvisum",
  preparatoryFaculties: "Vorbereitungsfakultäten",
  summerAndWinterSchools: "Sommer- und Winterschulen",
  russianLanguageCourses: "Russische Sprachkurse",
  tutorsTheRussianLanguage: "Nachhilfelehrer für Russisch",
  certificationInRussian: "Zertifizierung in Russisch",
  news: "Nachrichten",
  allNews: "Alle Nachrichten",
  reviewsOfStudentsAndGraduates: "Feedback der Studenten und Absolventen",
  areYouSureYouWantToWithdrawTheApplication:
    "Möchten Sie den Antrag wirklich zurückziehen?",
  withdrawApplication: "Antrag zurückziehen",
  jan: "Januar",
  feb: "Februar",
  mar: "März",
  apr: "April",
  may: "Mai",
  jun: "Juni",
  jul: "Juli",
  aug: "August",
  sep: "September",
  act: "Oktober",
  nov: "November",
  dec: "Dezember",
  cityFeatures: "Besonderheiten der Stadt",
  enterTheNameOfTheCity: "Geben Sie den Namen der Stadt ein",
  organizations: "Organisationen",
  livingWage: "Lebenshaltungskosten",
  "one-roomApartmentForRent": "Miete einer 1-Zimmer-Wohnung",
  _month: "im Monat",
  averageTemperature: "Durchschnittstemperatur",
  _inWinter: "im Winter",
  _inSummer: "im Sommer",
  averageCostOfDormitories:
    "Durchschnittlicher Mietpreis in Studentenwohnheimen",
  photos: "Fotos",
  interestingPlaces: "Interessante Orte",
  readCompletely: "Vollständig lesen",
  _review: "Feedback",
  _reviews: "des Feedbacks",
  __reviews: "Feedbacks",
  university: "Universität",
  universities: "Universitäten",
  country: "Land",
  selectACountry: "Wählen Sie das Land aus",
  reviewOfAForeignStudent: "Feedback des ausländischen Studenten",
  careerGuidance: "Berufsorientierung",
  determineYourAreaOfEducationAndProfession:
    "Bestimmen Sie die für Sie passenden Bildungsgebiete und Berufe",
  myCareer: "Meine Karriere",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Verbinden Sie das Studium mit der Freizeit und machen Sie neue Bekanntschaften. Besonderheiten intensiver Programme und ihr Preis.",
  toApply: "Anwenden",
  chooseYear: "Wählen Sie das Jahr aus",
  _in: "in",
  universitiesInThisCity: "Universitäten in dieser Stadt",
  programsInThisCity: "Programme in dieser Stadt",
  all: "Alle",
  _inYourCountry: "in Ihrem Land",
  _common: "allgemeine",
  allReviews: "Alle Feedbacks",
  calendar: "Kalender",
  activity: "Veranstaltungen",
  addToPersonalCalendar: "Im persönlichen Kalender hinzufügen",
  eventAdded: "Ereignis hinzugefügt",
  eventSuccessfullyAddedToYourCalendar:
    "Ereignis erfolgreich in Ihrem Kalender hinzugefügt",
  population: "Bevölkerung",
  onTheMap: "Auf der Karte",
  generalEducation: "Allgemeine Bildung",
  professionalEducation: "Berufliche Bildung",
  preschoolEducation: "Vorschule",
  kindergarten: "Kindergarten",
  nursery: "Krippe",
  earlyDevelopmentCenters: "Zentren für frühere Entwicklung",
  primaryAndSecondaryEducation:
    "Elementar-, Grundschul- und Mittelschulbildung",
  schools: "Schulen",
  lyceums: "Lyzeen",
  gymnasium: "Gymnasien",
  secondaryVocationalEducation: "Mittlere Berufsbildung",
  colleges: "Colleges",
  technicalSchools: "Fachoberschulen",
  secondarySchool: "Fachschulen",
  higherEducation: "Hochschulbildung",
  baccalaureate: "Bachelorstudium",
  specialistDegree: "Spezialistenstudium",
  magistracy: "Masterstudium",
  postgraduateStudies: "Aspirantur",
  preparatoryFaculty: "Vorbereitungsfakultät",
  summerSchools: "Sommerschule",
  additionalGeneralAndPreVocationalPrograms:
    "Zusätzliche allgemeinbildende und vorberufliche Programme",
  continuingEducationAndRetrainingPrograms:
    "Programme für Fortbildung und berufliche Weiterbildung",
  whatAreTheLevelsOfRussianEducation:
    "Welche Stufen und Abschlüsse hat das russische Bildungssystem?",
  enterYourName: "Geben Sie Ihren Namen ein",
  name: "Name",
  selectTheSubjectOfTheMessage: "Wählen Sie das Thema der Nachricht aus",
  messageSubject: "Thema der Nachricht",
  chooseAMessage: "Wählen Sie die Nachricht aus",
  message: "Nachricht",
  submit: "Senden",
  frequentlyAskedQuestions: "Häufig gestellte Fragen (FAQ)",
  didNotFindTheAnswerToYourQuestion: "Keine Antwort auf Ihre Frage gefunden",
  contactSupportAt: "Wenden Sie sich an den Support unter der Adresse",
  searchInTheSection: "Suche in der Rubrik",
  sort: "Sortieren",
  searchingResults: "Suchergebnisse",
  _answers: "Antworten",
  _views: "Aufrufe",
  byCreationDate: "Nach dem Erstellungsdatum",
  byTheNumberOfResponses: "Nach der Anzahl der Antworten",
  byViews: "Nach der Anzahl der Aufrufe",
  toAnswer: "Antworten",
  toQuote: "Zitieren",
  complain: "Sich beschweren",
  addNewMessage: "Neue Antwort hinzufügen",
  selectComplaintType: "Wählen Sie den Typ der Beschwerde aus",
  typeOfComplaint: "Typ der Beschwerde ",
  enterComplaintText: "Geben Sie den Text der Beschwerde ein",
  complaintText: "Text der Beschwerde ",
  complaintSentSuccessfully: "Beschwerde erfolgreich gesendet",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Ihre Beschwerde wurde erfolgreich gesendet. Wir überprüfen Ihre Beschwerde so schnell wie möglich.",
  close: "Schließen",
  myMessages: "Meine Nachrichten",
  myTopicsAndPosts: "Meine Themen und Nachrichten",
  searchInMyPosts: "Suche in meinen Nachrichten",
  _posts: "Nachrichten",
  feedbackOnEducationalOrganizations: "Feedback über die Universitäten",
  posted: "Veröffentlicht",
  goToMessage: "Zur Nachricht wechseln",
  _themes: "Themen",
  themeTitle: "Bezeichnung des Themas",
  enterAThemeName: "Geben Sie die Bezeichnung des Themas ein",
  cancel: "Absagen",
  banUser: "Soll der Benutzer gesperrt werden?",
  areYouSure: "Sind Sie sicher?",
  toBan: "Sperren",
  _logIn: "Sich autorisieren",
  _or: "oder",
  _signUp: "sich anmelden",
  eventCalendar: "Kalender der Veranstaltungen",
  trainingApplicationsFiled:
    "Anzahl der gestellten Anträge zur Zulassung zum Studium",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Beliebteste Universitäten, die von Studienbewerber gewählt werden",
  _of: "von den",
  _whenApplying: "bei der Antragstellung",
  tOP10EducationalOrganizations: "Die zehn besten Universitäten",
  collapse: "Minimieren",
  showAll: "Alle zeigen",
  conditionsForForeigners: "Bedingungen für Ausländer",
  supportForInternationalStudents: "Unterstützung ausländischer Studenten",
  lifeOfForeignStudents: "Alltag ausländischer Studenten",
  leisureAndSportingEvents: "Freizeit- und Sportveranstaltungen",
  dormitories: "Wohnheime",
  dormitoryCost: "Wohnheimmietkosten",
  theNumberOfPeopleInTheRoom: "Anzahl der Personen in einem Zimmer",
  from: "Von",
  _rubles: "Rubel",
  _to: "bis",
  _perMonth: "pro Monat",
  "2-3People": "2 bis 3 Personen",
  theInternationalCooperation: "Internationale Zusammenarbeit",
  photoAndVideo: "Fotos und Videos",
  eventTitle: "Bezeichnung der Veranstaltung",
  location: "Ort der Durchführung",
  date: "Datum",
  enterYourRealMail: "Geben Sie Ihre tatsächliche E-Mail-Adresse an",
  requiredField: "Das Ausfüllen des Feldes ist obligatorisch",
  minimumLengthCharacters: "Minimale Zeichenanzahl {length}",
  maximumLengthCharacters: "Maximale Zeichenanzahl {length} ",
  theFieldMustBeANumber: "Das Feld muss eine Zahl sein.",
  onlyLettersAreAllowedInTheField: "Im Feld sind nur Buchstaben zulässig",
  checkKeyboardLayout: "Überprüfen Sie die Tastaturbelegung",
  checkTheNumberYouEnteredIsCorrect:
    "Überprüfen Sie die Richtigkeit der von Ihnen eingegebenen Nummer",
  olympiadName: "Bezeichnung der Olympiade",
  description: "Beschreibung",
  contactInformation: "Kontaktinformationen",
  majorForeignPartnerUniversities: "Wichtigste Partneruniversitäten im Ausland",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Möglichkeiten für den Direkt- und Fernunterricht der russischen Sprache in Russland und in der ganzen Welt",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Sind Sie Nachhilfelehrer? Reichen Sie einen Fragebogen für die Veröffentlichung Ihres Profils auf der Seite ein",
  submitApplication: "Fragebogen einreichen",
  chooseATeachingExperience: "Wählen Sie die Berufspraxis als Lehrer aus",
  teachingExperience: "Berufspraxis als Lehrer ",
  chooseLanguages: "Wählen Sie die Sprachen aus",
  knowledgeOfLanguages: "Sprachkenntnisse",
  workExperience: "Berufspraxis",
  trainingLevel: "Ausbildungsstand",
  durationOfOneLesson: "Dauer einer Unterrichtsstunde",
  possibleTrainingTime: "Mögliche Unterrichtszeit",
  theCosOfOneLesson: "Preis für eine Unterrichtsstunde",
  writeAMessage: "Nachricht schreiben",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Sie können dem Nachhilfelehrer nach der Anmeldung oder Autorisierung auf der Webseite schreiben ",
  educationDocuments: "Bildungsurkunden",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Sie haben schon ein anderes Programm ausgewählt. Wenn Sie dieses Programm auswählen, gehen das ausgewählten Programm und Organisationen verloren.",
  limitIsExceeded: "Limit überschritten",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Sie können höchstens 6 Universitäten auswählen.",
  _fieldsOfEducation: "Bildungsgebiete",
  _trainingAreas: "Fachrichtungen",
  _cities: "Städte",
  _languagesOfInstruction: "Unterrichtssprachen",
  _typesOfAdditionalPrograms: "Arten der zusätzlichen Programme",
  _semesters: "Semester",
  chooseDurationOfStudy: "Wählen Sie die Studiendauer aus",
  _durationOfStudy: " Studiendauer ",
  enterTheNameOfTheEducationalProgram:
    "Geben Sie die Bezeichnung des Programms ein",
  instruction: "Anweisung",
  profile: "Fragebogen",
  back: "Zurück",
  next: "Weiter",
  testReport: "Bericht über die Testergebnisse",
  testDate: "Datum des Tests",
  duration: "Dauer",
  memberName: "Name des Teilnehmers",
  age: "Alter",
  gender: "Geschlecht",
  currentLevelOfEducation: "Aktueller Bildungsstand",
  areasOfInterestHobbies: "Interessenbereiche, Hobbys",
  sportsAchievements: "Sportleistungen",
  testScaleResults: "Ergebnisse nach Testskalen",
  scaleDescription: "Beschreibung der Ergebnisse nach Testskalen",
  recommendedEducationAreas: "Empfohlene Bildungsgebiete",
  enterYourAge: "Geben Sie Ihr Alter ein",
  enterYourGender: "Geben Sie Ihr Geschlecht ein",
  chooseYourCurrentLevelOfEducation: "Wählen Sie Ihren aktuellen Bildungsstand",
  chooseYourAreasOfInterestHobbies:
    "Wählen Sie Ihre Interessenbereiche, Hobbys",
  chooseYourSportingAchievements: "Wählen Sie Ihre Sportleistungen",
  legalizationOfDocuments: "Legalisation der Dokumente",
  consularLegalization: "Konsularische Legalisation",
  edit: "Bearbeiten",
  mobilePhoneNumber: "Handynummer",
  iWantToReceiveNotificationsByEmail:
    "Ich möchte Benachrichtigungen an die E-Mail-Adresse erhalten",
  authorizationCheck: "Überprüfung der Autorisierung",
  createANewApplication: "Neuen Antrag erstellen",
  selectionStage: "Auswahletappe",
  underRevision: "In Überarbeitung",
  fillingOutAnApplication: "Ausfüllen des Antrags",
  verificationAndRevision: "Überprüfung und Überarbeitung",
  dossierFormation: "Erstellung eines Dossiers",
  fillingInTheQuestionnaire: "Ausfüllen des Fragebogens",
  attachingDocuments: "Anhängen der Unterlagen",
  sendingApplicationForVerification: "Absenden des Antrags zur Überprüfung",
  personalData: "Persönliche Daten",
  surnameInLatinLettersAccordingToPassport:
    "Nachname, mit lateinischen Buchstaben (wie im Pass)",
  nameInLatinLettersAccordingToPassport:
    "Vorname (Vornamen), mit lateinischen Buchstaben (in Übereinstimmung mit dem Pass)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Vatersname (falls vorhanden), in kyrillischer Schrift in russischer Transkription",
  placeOfBirthAccordingToPassport: "Geburtsort (wie im Pass)",
  dateOfBirth: "Geburtsdatum",
  compatriotStatus: "Landsmann-Status",
  citizenshipPlaceOfPermanentResidence:
    "Staatsangehörigkeit (ständiger Wohnsitz)",
  online: "Online",
  profile2: "Profil",
  goToTheApplicationForm: "Zum Ausfüllen des Antrags übergehen",
  areYouHere: "Sie sind hier",
  russianTextDes1:
    "Wozu gibt es die Vorbereitungsfakultäten, was wird dort studiert, wie lange es dauert und was kostet das Studium?",
  russianTextDes4:
    "Wo und wie finde ich einen Lehrer für den Einzelunterricht der russischen Sprache?",
  russianTextDes5:
    "Wie und wozu muss man den Stand der Kenntnisse der russischen Sprache nachweisen, um zum Studium an einer russischen Universität zugelassen zu werden. Erfahren Sie alles über die Tests zum Erhalt eines Zertifikats",
  typeOfProgram: "Art des Programms",
  chooseTheTypeOfAdditionalProgram:
    "Wählen Sie die Art des zusätzlichen Programms",
  durationOfStudy: " Studiendauer",
  common: "Allgemeine",
  inYourCountry: "In ihrem land",
  courseName: "Bezeichnung des Kurses",
  courseDuration: "Dauer des Kurses",
  classDuration: "Dauer einer Unterrichtsstunde",
  courseCost: "Kursgebühr",
  courseReviews: "Feedback über den Kurs",
  male: "Männlich",
  female: "Weiblich",
  feedbackLink: "Link zum Feedback",
  russianLanguageCoursesInYourCountry: "Russische Sprachkurse in Ihrem Land",
  evaluation: "Bewertung",
  giveFeedback: "Feedback hinterlassen",
  newFeedback: "Neues Feedback",
  _hour: "Stunde",
  _hours: "Stunden",
  __hours: "Stunden",
  _minute: "Minute",
  _minutes: "Minuten",
  __minutes: "Minuten",
  create: "Erstellen",
  feedbackSuccess:
    "Das Feedback wurde erfolgreich gesendet und wartet auf die Moderation",
  _y: "Jahr",
  _from: "from",
  error: "Fehler",
  direction: "Richtung",
  _tutor: "Nachhilfelehrer",
  _tutors: "Nachhilfelehrer",
  __tutors: "Nachhilfelehrer",
  filters: "Filter",
  sendMessage: "Nachricht senden",
  sendMessageSuccess: "Nachricht erfolgreich gesendet",
  _year: "Jahr",
  _years: "Jahre",
  __years: "Jahre",
  _back: "zurück",
  _next: "weiter",
  showing: "Anzeigen",
  textRussianLanguageCourses:
    "Wählen Sie im Menü Ihr Wohnsitzland und sehen Sie sich die verfügbaren Sprachkurse und -schulen an. Der Erwerb der Basiskenntnisse der russischen Sprache zu Hause wird die Eingewöhnung in das neue Umfeld wesentlich erleichtern.",
  tOP10Programs: "Die zehn besten Programme",
  tOP10City: "Die zehn besten Städte Russlands",
  createANewTopic: "Neues Thema erstellen",
  topics: "Themen",
  posts: "Nachrichten",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "Dateien in Formaten .pdf, .jpg, .png bis zu 10 MByte",
  noFileSelected: "Datei nicht ausgewählt",
  attachFile: "Datei anhängen",
  eMail: "E-mail",
  fullTime: "Direktstudium",
  toFind: "Finden",
  searchInTopic: "Suche im Thema",
  lessThan1Month: "Weniger als 1 Monat",
  "1To3Months": "1 bis 3 Monate",
  "3To6Months": "3 bis 6 Monaten",
  "6To12Months": "6 bis 12 Monaten",
  moreThan1Year: "Mehr als 1 Jahr",
  theDateOfThe: "Datum der Durchführung",
  toAdminPanel: "Zum Administrationsbereich wechseln",
  support: "Technischer Support",
  faq: "FAQ",
  userGuide: "Benutzerhandbuch",
  textUserGuide: "Anleitung für das Webportal und die Studienbewerbung",
  textRegisterGuide: "Registrierung auf der Webseite Bildung in Russland für Ausländer'",
  textTrainingGuide: "Studienbewerbung auf der Webseite Bildung in Russland für Ausländer'",
  textApplication: "Ausfüllen des Bewerbungsbogens",
  instructionForWork: "Anleitung für das Erstellen der Bewerbung",
  appeals: "Einsprüche",
  handling: "Anfragen",
  __students: "des Studenten",
  _student: "Student",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Legen Sie Ihre Ausbildungsrichtung fest und erfahren Sie, welche Möglichkeiten Sie in Ihrem künftigen Beruf haben",
  delTheme: "Thema entfernen",
  competitionForTheFirstPlace: "Wettbewerb um den 1. Platz im Rahmen der Quote der Russischen Föderation (Personen)",
  ruble: "Rubel",
  __rubles: "Rubel",
  dateOfEvent: "Datum der Durchführung",
  durationUnit: "Monat",
  numberPhone: "Telefonnummer",
  offline: "Offline",
  to_begin: "zum Anfang",
  theDateOfThe: "Startdatum",
  chat: "Chatbot",
  yourMessageChat: "Ihre Nachricht",
  searchChat: "Dialogsuche",
  firstMsgChat: "Geben Sie den Text der Nachricht ein",
  categoryBtnChat_1: "Frage zur Einreichung des Bewerbungsantrags",
  categoryBtnChat_2: "Frage zum Studium",
  categoryBtnChat_3: "Frage zum Auswahlverfahren",
  categoryBtnChat_4: "Vorschlag oder Anmerkung",
  categoryBtnChat_5: "Feedback zur Tätigkeiten der Universität",
  categoryBtnChat_6: "Beschwerde über die Servicequalität",
  categoryBtnChat_7: "Anfrage an den technischen Support",
  notQuestionResolvedChat: "Meine Frage wurde nicht gelöst",
  questionResolvedChat: "Meine Frage wurde gelöst",
  countrySelectTrueChat: "Das Land wurde richtig eingegeben",
  salutationChat: "Guten Tag.",
  categoryAppealChat: "Um Informationen zu erhalten, wählen Sie die Kategorie aus, zu der Ihre Frage gehört, indem Sie auf die Schaltfläche klicken. Nach der Auswahl der Kategorie geben Sie Ihre Frage in der Nachricht ein und wählen die Schaltfläche Senden aus.'",
  langAppealChat: "Wählen Sie die Sprache aus, in der Sie die Anfrage stellen möchten. Anfragen auf Russisch und Englisch werden schneller bearbeitet.",
  youCountryChatPath1: "Ihr Land",
  youCountryChatPath2: "Wenn nicht, geben Sie den Namen des Landes in der Antwortnachricht ein.",
  youCountryChat: "Ihr Land {country}? Wenn nicht, geben Sie den Namen des Landes in der Antwortnachricht ein.",
  notFoundCountryChat: "Es wurde kein Land mit dem von Ihnen angegebenen Namen gefunden. Bitte überprüfen Sie die Richtigkeit der eingegebenen Daten und senden Sie die Nachricht erneut.",
  additionalInfoChat: "Bitte geben Sie in der Antwortnachricht zusätzliche Informationen zu Ihrer Anfrage an.",
  yourEmailChat: "Bitte geben Sie ihre E-Mail-Adresse ein. Die Antwort auf Ihre Anfrage wird per E-Mail an Sie gesendet.",
  notValidEmailChat: "Die von Ihnen gesendete E-Mail-Adresse entspricht nicht dem Format aaa@bbb.ccc. Bitte überprüfen Sie die Richtigkeit der eingegebenen Daten und senden Sie die Nachricht erneut.",
  acceptRequestChat: "Ihre Anfrage wurde dem Mitarbeiter übermittelt. Die durchschnittliche Bearbeitungszeit beträgt 7 Kalendertage.",
  notFoundEmployeeChat: "Das von Ihnen eingegebene Land ist nicht in der Liste der möglichen Studienländer im Rahmen des Programms von Rossotrudnitschestwo. Ihre Anfrage konnte nicht zur Bearbeitung gesendet werden. Bitte geben Sie ein anderes Land ein. Das vollständige Verzeichnis der Studienprogramme finden Sie auf der Webseite <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
  langBtnChat_1: "Englisch",
  langBtnChat_2: "Russisch",
  langBtnChat_3: "Sonstiges",
  addAppBtn: "Antrag stellen",
  listAppBtn: "Liste der Anträge",
  privacyPolicy: "Datenschutzbestimmungen",
  ___programs: "Programm",
  ___educationalPrograms: "Studienprogramme",
  __educationalPrograms: "Studienprogramm",
  editTopic: "Thema bearbeiten",
  editMessage: "Nachricht bearbeiten",
  deleteMessage: "Nachricht entfernen",
  blockingUser: "Benutzer gesperrt",
  blockEndDate: "Sperrfrist endet am",
  blockEndTime: "Sperrfrist endet um",
  blockForever: "Dauerhaft sperren",
  block: "Sperren",
  unlock: "Freischalten",
  edited: "Bearbeitet",
  founder: "Gründer",
  abbreviation: "Abkürzung",
  studentsByCountry: "Anzahl der Studenten nach Ländern",
  studentsByCountryCount: "Anzahl der Studierenden",
  contactsInternationalDepartment: "Kontakt zur internationalen Abteilung",
  site: "Webseite",
  address: "Adresse",
  format: "Format",
  typeEvent: "Art der Veranstaltung",
  quotaLearning: "Quotenstudium",
  kcp: "NC",
  countSeatsKCP: "Anzahl der NC-Studienplätze",
  countSeatsCostTraining: "Anzahl der kostenpflichtigen Studienplätze",
  distanceLearning: "Fernstudium",
  notificationAboutDistanceLearning: "Das Bildungsprogramm kann mithilfe von E-Learning- und im Fernunterricht-Technologien durchgeführt werden. Erkundigen Sie sich bei der Hochschule nach weiteren Details.",
  additionalTests: "Termine für die Durchführung der zusätzlichen Auswahlprüfungen",
  pageNotFound: "Seite nicht gefunden",
  goToMainPage: "Zur Hauptseite",
  faqInformation: "Wenden Sie sich bei allen Fragen zum Bearbeitungsstatus des Bewerbungsantrags, zum Verzeichnis der vorzulegenden Unterlagen, zu den Terminen für die Durchführung der Auswahlprüfungen bzw. bei sonstigen Fragen zum Immatrikulationsprozess an die Vertretungen von Rossotrudnitschestwo oder an die Botschaften der Russischen Föderation, die für das Auswahlverfahren in Ihrem Land zuständig sind. Die Kontaktinformationen der Mitarbeiter von Rossotrudnitschestwo finden Sie in Ihrem Bewerberkonto.",
  allEvents: "Alle Veranstaltungen",
  eventsAndNews: "Veranstaltungen und Nachrichten",
  titleNews: "Name der Nachricht",
  numberOfBudgetPlaces: "Anzahl der staatlich geförderten Studienplätze",
  russianGovernmentQuota: "Quote der Regierung der Russischen Föderation",
  legalAddress: "Offizielle Anschrift",
  actualAddress: "Adresse",
  goToTheRegister: "Zum Verzeichnis zusätzlichen Programme wechseln",
  searchForSuitablePrograms: "Suchen Sie nach geeigneten Programmen",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Die beliebtesten und von den Studienbewerbern am häufigsten gewählten Studienprogramme",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Die beliebtesten und von den Studienbewerbern am häufigsten gewählten Städte Russlands",
  ofTheCountry: "aus dem Land",
  passed: "Abgeschlossen",
  question: "Frage",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Das Portal läuft im Testmodus!",
  banner_2: "Liebe Bewerber, im Moment kann es zu technischen Störungen kommen, wenn man eine Google-E-Mail-Adresse hat. Bitte verwenden Sie andere E-Mail-Dienste, wenn Sie den Portal nutzen.",
  banner_3: "Der Begin  n der Bewerbungskampagne ist an 24. Dezember 2022.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
