export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: "Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Тусламж",
  personalArea: "Хувийн өрөө",
  myApplications: "Миний өргөдлүүд",
  servicesAndFacilities: "Үйлчилгээ",
  logout: "Гарах",
  favorites: "Сонгосон",
  theChoice: "Сонголт",
  notifications: "Мэдэгдэл",
  logIn: "Нэвтрэх",
  quickAccessThroughSocialNetworks:
    "Нийтийн сүлжээгээр дамжуулан хурдан нэвтрэх",
  enterYourEmail: "Та өөрийн имэйлээ оруулна уу",
  password: "Нууц үг",
  enterPassword: "Нууц үгээ оруулна уу",
  rememberMe: "Намайг санах",
  forgotYourPassword: "Нууц үгээ мартсан уу?",
  noAccount: "Танд бүртгэл байхгүй юу?",
  registration: "Бүртгэл",
  countryOfCitizenship: "Иргэншил",
  chooseYourCountryOfCitizenship: "Иргэншлийн улсаа сонгоно уу",
  iHaveReadAndAgree: "Би танилцаад, өгч байна",
  _toTheProcessingOfMyPersonalData:
    "миний хувийн мэдээллийг боловсруулах зөвшөөрөл",
  iAgreeTo: "Би зөвшөөрч байна",
  _theUseOfCookies: "cookie файлыг ашиглахыг",
  signUp: "Бүртгүүлэх",
  haveAnAccount: "Бүртгэл байгаа юу?",
  mainPage: "Нүүр хуудас",
  frequentlyAskedQuestionsAnswers: "Байнга асуудаг асуултуудын хариулт",
  goTo: "Шилжих",
  startTesting: "Туршилтыг эхлүүлэх",
  buildCareerVector: "Ажил мэргэжлийн замнал бүтээх",
  forum: "Хэлэлцүүлэг",
  textForumBanner:
    "Оросын хотуудад амьдрал ямар байх, Оросын их дээд сургуулиудад хэрхэн суралцах гэх мэт бүх чухал сэдвүүдийг ерөнхий хэлэлцүүлэг дээр ярилцана уу.",
  feedback: "Эргээд холбогдох",
  technicalSupportFeedbackForm: "Техникийн дэмжлэгтэй эргэж холбогдох хэлбэр",
  allCities: "Бүх хотууд",
  citiesOfRussia: "Оросын хотууд",
  educationInRussia: "Орост суралцах",
  educationalOrganizations: "Их сургуулиуд",
  educationInRussiaYourKeyToSuccess: "Оросын боловсрол - амжилтад хүрэх зам ",
  applyForStudyInRussia: "Орост суралцах өргөдөл гаргах",
  apply: "Өргөдөл гаргах",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Ажил мэргэжлийн чиг баримжаа олох, өргөдөл гаргах эрх авахын тулд нэвтрэх эсвэл бүртгүүлэх",
  aboutOrganization: "Байгууллагын тухай",
  eventsAndOlympiads: "Арга хэмжээнүүд ба олимпиадууд",
  educationalPrograms: "Хөтөлбөрүүд",
  contacts: "Холбоо барих",
  inFavorites: "Сонгосон хэсэгт",
  toFavorites: "Сонгосон хэсэг рүү",
  fromChoices: "Сонгосон",
  choices: "Сонгох",
  generalInformation: "Ерөнхий мэдээлэл",
  internationalRatings: "Олон улсын чансаа",
  nationalRatings: "Үндэсний чансаа",
  ratings: "Чансаа",
  year: "Он",
  ratingName: "Чансааны нэр",
  aPlace: "Байр",
  screeningTests: "Сонгон шалгаруулалт",
  choiceOfProfession: "Мэргэжил сонгох",
  lifeInRussia: "Орост амьдрах",
  russianLanguage: "Орос хэл",
  livingConditions: "Амьдралын нөхцөл",
  cultureAndLeisure: "Соёл амралт",
  safety: "Аюулгүй байдал",
  benefitsForInternationalStudents: "Гадаадын оюутнуудын хөнгөлөлт",
  workForInternationalStudents: "Гадаадын оюутнуудын ажил эрхлэлт",
  studentVisaAndRegistration: "Сургалтын виз ба бүртгэл",
  healthInsurance: "Эрүүл мэндийн даатгал",
  howToEnter: "Хэрхэн элсэх вэ",
  educationLevels: "Боловсролын түвшин",
  admissionProcess: "Элсэлтийн явц",
  olympics: "Олимпиад",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Орост суралцах гадаадын иргэдийг сонгон шалгаруулах албан ёсны вебсайт, {year} он",
  whyRussia: "Яагаад Орос улс",
  "10reasonsToStudyInRussia": "Орост суралцах 10 шалтгаан",
  reviewsOfForeignStudents: "Гадаад оюутнуудын сэтгэгдлүүд",
  onlineMonitoring: "Онлайн-хяналт",
  mainPrograms: "Үндсэн хөтөлбөрүүд",
  additionalPrograms: "Нэмэлт хөтөлбөрүүд",
  chooseCounty: "Та муж сонгоно уу",
  county: "Муж",
  chooseACity: "Хот сонгоно уу",
  city: "Хот",
  chooseYourLevelOfEducation: "Боловсролын түвшинг сонгох",
  chooseYourAreaOfScientificKnowledge: "Шинжлэх ухааны мэдлэгийн чиглэлийг сонго",
  chooseYourScientificSpecialtyProfile: "Шинжлэх ухааны мэргэжлийн профайлыг сонгоно уу",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Боловсролын түвшин",
  chooseAGroupOfTrainingAreas: "Бэлтгэлийн чиглэлийн бүлгийг сонгоно уу",
  trainingDirectionGroup: "Бэлтгэлийн чиглэлийн бүлэг",
  chooseATrainingDirection: "Бэлтгэлийн чиглэлийг сонгоно уу",
  trainingDirection: "Бэлтгэлийн чиглэл",
  chooseRatings: "Чансаа сонгоно уу",
  thePresenceOfThePreparatoryFaculty: "Бэлтгэл тэнхим байгаа эсэх",
  show: "Харуулах",
  _university: "их сургууль",
  _universities: "их сургуулийн",
  __universities: "их сургуулиудын",
  clearAllFilters: "Бүх шүүлтүүрийг хаях",
  chooseSemester: "Семестр сонгоно уу",
  semester: "Семестр",
  enterTheNameOfTheEducationalOrganization: "Их сургуулийн нэрийг оруулна уу",
  _educationalOrganization: "их сургууль",
  _educationalOrganizations: "их сургуулийн",
  __educationalOrganizations: "их сургуулиудын",
  chooseYourAreaOfEducation: "Боловсролын салбарыг сонгоно уу",
  areaOfEducation: "Боловсролын салбар",
  chooseYourLanguageOfInstruction: "Суралцах хэл сонгоно уу",
  languageOfInstruction: "Суралцах хэл",
  _program: "хөтөлбөр",
  _programs: "хөтөлбөрүүд",
  __programs: "хөтөлбөр",
  formOfTraining: "Суралцах хэлбэр",
  level: "Түвшин",
  typeOfTraining: "Суралцах хэлбэр",
  free: "Төлбөргүй",
  paid: "Төлбөртэй",
  tuitionFees: "Төлбөртэй сургалтын зардал",
  established: "Үүсгэн байгуулагдсан он",
  numberOfStudents: "Оюутны тоо",
  _students: "оюутан",
  numberOfQuotaPlaces: "Квотын тоо",
  _for: "на",
  numberOfInternationalStudents: "Гадаадын оюутан",
  _educationalPrograms: "боловсролын хөтөлбөр",
  moreDetails: "Дэлгэрэнгүй",
  sTEP: "АЛХАМ",
  afterTrainingForThisProgramYouCanBecome:
    "Энэ хөтөлбөрийг дуусгасны дараа та ... болно",
  chooseProgram: "Хөтөлбөр сонгох",
  chooseUniversity: "Их сургууль сонгох",
  print: "Хэвлэх",
  save: "Хадгалах",
  programs: "Хөтөлбөрүүд",
  selectedProgram: "Сонгосон хөтөлбөр",
  noProgramSelected: "Хөтөлбөр сонгогдоогүй",
  selectedEducationalOrganizations: "Сонгосон их сургуулиуд",
  educationalOrganizationNotSelected: "Их сургууль сонгогдоогүй",
  representation: "Төлөөлөгчийн газар",
  programDescription: "Хөтөлбөрийн тухай",
  programPageOnTheUniversityWebsite:
    "Их сургуулийн сайт дахь хөтөлбөрийн хуудас",
  programCurator: "Хөтөлбөрийн зохицуулагч",
  disciplines: "Хичээлүүд",
  theProcessOfEnteringARussianEducationalOrganization:
    "Оросын их сургуульд элсэх үйл явц",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Хөтөлбөр болон 6 хүртэл их сургууль сонгоно уу",
  takeTheCompetitiveSelectionInYourCountry:
    "Өөрийн улсад уралдаант шалгаруулалтад оролцоно уу",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Онлайн өргөдлийг бөглөж, баримт бичгийн багцыг хавсаргана уу",
  getAnInvitationToStudAnStudyVisa:
    "Суралцах урилга болон сургалтын виз хүлээн авна уу",
  preparatoryFaculties: "Бэлтгэл тэнхимүүд",
  summerAndWinterSchools: "Зуны болон өвлийн сургуулиуд",
  russianLanguageCourses: "Орос хэлний курс",
  tutorsTheRussianLanguage: "Орос хэлний давтлагын багш нар",
  certificationInRussian: "Орос хэлний мэдлэгийн шалгалт",
  news: "Мэдээ",
  allNews: "Бүх мэдээ",
  reviewsOfStudentsAndGraduates: "Оюутан, төгсөгчдийн сэтгэгдлүүд",
  areYouSureYouWantToWithdrawTheApplication: "Та өргөдлөө буцаамаар байна уу?",
  withdrawApplication: "Өргөдлөө буцаах",
  jan: "1-р сар",
  feb: "2-р сар",
  mar: "3-р сар",
  apr: "4-р сар",
  may: "5-р сар",
  jun: "6-р сар",
  jul: "7-р сар",
  aug: "8-р сар",
  sep: "9-р сар",
  act: "10-р сар",
  nov: "11-р сар",
  dec: "12-р сар",
  cityFeatures: "Хотын онцлог шинж чанарууд",
  enterTheNameOfTheCity: "Хотын нэр оруулна уу",
  organizations: "Байгууллагууд",
  livingWage: "Амьжиргааны доод зардал",
  "one-roomApartmentForRent": "1 өрөө байрны түрээс",
  _month: "сар",
  averageTemperature: "Дундаж температур",
  _inWinter: "өвөлдөө",
  _inSummer: "зундаа",
  averageCostOfDormitories: "Нийтийн байрны дундаж үнэ",
  photos: "Зураг",
  interestingPlaces: "Сонирхолтой газрууд",
  readCompletely: "Бүтнээр унших",
  _review: "сэтгэгдэл",
  _reviews: "сэтгэгдэлд",
  __reviews: "сэтгэгдлүүд",
  university: "Их сургууль",
  universities: "Их сургуулиуд",
  country: "Улс",
  selectACountry: "Улс сонгоно уу",
  reviewOfAForeignStudent: "Гадаад оюутны сэтгэгдэл",
  careerGuidance: "Мэргэжлийн чиг хандлага",
  determineYourAreaOfEducationAndProfession:
    "Өөрт тохирох боловсрол, мэргэжлийн салбараа тодорхойл",
  myCareer: "Миний карьер",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Сургалтаа соёлтой амралт, шинэ танилтай хэрхэн хослуулах вэ. Эрчимжүүлсэн хөтөлбөрүүдийн онцлог шинж чанарууд ба тэдгээрийн өртөг",
  toApply: "Хэрэглэх",
  chooseYear: "Он сонгох",
  _in: "энэ хотод",
  universitiesInThisCity: "Энэ хотын их сургуулиуд",
  programsInThisCity: "Энэ хотын хөтөлбөрүүд",
  all: "Бүх",
  _inYourCountry: "танай улсад",
  _common: "нийтийн",
  allReviews: "Бүх сэтгэгдлүүд",
  calendar: "Хуанли",
  activity: "Арга хэмжээнүүд",
  addToPersonalCalendar: "Хувийн хуанлид нэмэх",
  eventAdded: "Үйл явдал нэмсэн",
  eventSuccessfullyAddedToYourCalendar:
    "Үйл явдлыг таны хуанлид амжилттай нэмсэн!",
  population: "Хүн ам",
  onTheMap: "Газрын зураг дээр",
  generalEducation: "Ерөнхий боловсрол",
  professionalEducation: "Мэргэжлийн боловсрол",
  preschoolEducation: "Сургуулийн өмнөх боловсрол",
  kindergarten: "Цэцэрлэг",
  nursery: "Ясли",
  earlyDevelopmentCenters: "Хүүхэд хөгжлийн төвүүд ",
  primaryAndSecondaryEducation: "Анхан, ерөнхий болон дунд боловсрол",
  schools: "Сургуулиуд",
  lyceums: "Лицей",
  gymnasium: "Гимнази",
  secondaryVocationalEducation: "Мэргэжлийн дунд боловсрол",
  colleges: "Коллежууд",
  technicalSchools: "Техникумууд",
  secondarySchool: "Сургуулиуд",
  higherEducation: "Дээд боловсрол",
  baccalaureate: "Бакалавр",
  specialistDegree: "Мэргэжлийн дээд боловсрол",
  magistracy: "Магистр",
  postgraduateStudies: "Аспирантур",
  preparatoryFaculty: "Бэлтгэлийн факультет",
  summerSchools: "Зуны сургууль",
  additionalGeneralAndPreVocationalPrograms:
    "Ерөнхий хөгжлийн болон мэргэжлийн өмнөх нэмэлт хөтөлбөрүүд",
  continuingEducationAndRetrainingPrograms:
    "Мэргэжил дээшлүүлэх, мэргэжлийн давтан сургалтын хөтөлбөрүүд",
  whatAreTheLevelsOfRussianEducation: "Оросын боловсрол хэдэн түвшинтэй вэ?",
  enterYourName: "Та өөрийн нэрийг оруулна уу",
  name: "Нэр",
  selectTheSubjectOfTheMessage: "Зурвасын сэдвийг оруулна уу",
  messageSubject: "Зурвасын сэдэв",
  chooseAMessage: "Зурвас оруулна уу",
  message: "Зурвас",
  submit: "Илгээх",
  frequentlyAskedQuestions: "Байнга асуудаг асуултууд",
  didNotFindTheAnswerToYourQuestion: "Таны асуултад хариу олдсонгүй",
  contactSupportAt: "Туслалцаа авахад холбогдох хаяг",
  searchInTheSection: "Хэсэгт хайх",
  sort: "Ангилах",
  searchingResults: "Хайлтын үр дүн",
  _answers: "хариулт",
  _views: "үзсэн тоо",
  byCreationDate: "Үүсгэсэн огноогоор",
  byTheNumberOfResponses: "Хариултын тоогоор",
  byViews: "Үзсэн тоогоор",
  toAnswer: "Хариулах",
  toQuote: "Иш татах",
  complain: "Гомдол мэдүүлэх",
  addNewMessage: "Шинэ зурвас нэмэх",
  selectComplaintType: "Гомдлын төрөл сонгоно уу",
  typeOfComplaint: "Гомдлын төрөл",
  enterComplaintText: "Гомдлын текстийг оруулна уу",
  complaintText: "Гомдлын текст",
  complaintSentSuccessfully: "Гомдол амжилттай илгээсэн",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Таны гомдлыг амжилттай илгээсэн тул бид таны гомдлыг аль болох богино хугацаанд авч үзэх болно",
  close: "Хаах",
  myMessages: "Миний зурвасууд",
  myTopicsAndPosts: "Миний сэдэв ба зурвасууд",
  searchInMyPosts: "Миний зурвасд хайх",
  _posts: "зурвас",
  feedbackOnEducationalOrganizations: "Их сургуулийн тухай сэтгэгдлүүд",
  posted: "Нийтэлсэн",
  goToMessage: "Зурвас руу шилжих",
  _themes: "сэдэв",
  themeTitle: "Сэдвийн нэр",
  enterAThemeName: "Сэдвийн нэрийг оруулна уу",
  cancel: "Цуцлагдсан",
  banUser: "Хэрэглэгчийг хориглох уу?",
  areYouSure: "Та итгэлтэй байна уу?",
  toBan: "Хориглох",
  _logIn: "таниулах",
  _or: "эсвэл",
  _signUp: "бүртгүүлэх",
  eventCalendar: "Арга хэмжээний хуанли",
  trainingApplicationsFiled: "Сургалтанд хамрагдахаар өргөдөл гаргасан",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Элсэгчдийн сонгосон хамгийн алдартай их дээд сургуулиуд",
  _of: "из",
  _whenApplying: "өргөдөл гаргахад",
  tOP10EducationalOrganizations: "ТОП-10 Их сургууль",
  collapse: "Хаах",
  showAll: "Бүгдийг үзүүлэх",
  conditionsForForeigners: "Гадаадын иргэдэд зориулсан нөхцлүүд ",
  supportForInternationalStudents: "Гадаадын оюутнуудыг дэмжих",
  lifeOfForeignStudents: "Гадаадын оюутнуудын ахуй",
  leisureAndSportingEvents: "Амралт, спортын үйл ажиллагаа",
  dormitories: "Дотуур байр",
  dormitoryCost: "Дотуур байрны үнэ өртөг",
  theNumberOfPeopleInTheRoom: "Нэг өрөөнд байх хүний тоо",
  from: "ямар",
  _rubles: "рубль-ээс",
  _to: "хүртэл",
  _perMonth: "сард",
  "2-3People": "2-3 хүнтэй",
  theInternationalCooperation: "Олон услын хамтын ажиллагаа",
  photoAndVideo: "Зураг ба видео",
  eventTitle: "Арга хэмжээний нэр",
  location: "Байршил",
  date: "Огноо",
  enterYourRealMail: "Та бодит хаягаа оруулна уу",
  requiredField: "Талбарыг заавал бөглөсөн байна",
  minimumLengthCharacters: "Хамгийн богинодоо {length} тэмдэгт байна",
  maximumLengthCharacters: "Хамгийн уртдаа {length} тэмдэгт байна",
  theFieldMustBeANumber: "Талбар нь тоо байх ёстой",
  onlyLettersAreAllowedInTheField: "Талбарт зөвхөн үсэгнүүд байх ёстой",
  checkKeyboardLayout: "Гарныхаа байрлалыг шалгана уу",
  checkTheNumberYouEnteredIsCorrect:
    "Таны оруулсан дугаар зөв эсэхийг шалгана уу",
  olympiadName: "Олимпиадын нэр",
  description: "Тодорхойлолт",
  contactInformation: "Холбоо барих мэдээлэл",
  majorForeignPartnerUniversities: "Гадаадын түнш томоохон сургуулиуд",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Орост дэлхийн өнцөг булан бүрт орос хэлний бүтэн цагийн болон зайны сургалтын боломжууд",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Та давтлагын багш уу? Өөрийн профайлыг сайт дээр нийтлэхийн тулд анкетаа явуулна уу",
  submitApplication: "Анкет явуулах",
  chooseATeachingExperience: "Багшлах туршлагаа сонгоно уу",
  teachingExperience: "Багшлах туршлага",
  chooseLanguages: "Хэл сонгоно уу",
  knowledgeOfLanguages: "Хэлний мэдлэг",
  workExperience: "Ажилласан жил",
  trainingLevel: "Бэлтгэлийн түвшин",
  durationOfOneLesson: "Нэг хичээлийн үргэлжлэх хугацаа",
  possibleTrainingTime: "Сургалтын боломжит хугацаа",
  theCosOfOneLesson: "Нэг хичээлийн төлбөр",
  writeAMessage: "Зурвас илгээх",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Та сайт дээр бүртгүүлсний дараа эсвэл зөвшөөрөл авсны дараа багшид захиа бичих боломжтой",
  educationDocuments: "Боловсролыг нотлох баримт бичиг",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Та өөр хөтөлбөр сонгосон байна, хэрэв та энэ хөтөлбөрийг сонговол урьд нь сонгосон хөтөлбөр, байгууллагууд устах болно.",
  limitIsExceeded: "Хязгаараас хэтэрсэн байна",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Танд 6-аас бага их сургууль сонгох боломж байна.",
  _fieldsOfEducation: "боловсролын салбар",
  _trainingAreas: "бэлтгэлийн чиглэл",
  _cities: "хотууд",
  _languagesOfInstruction: "суралцах хэл",
  _typesOfAdditionalPrograms: "нэмэлт хөтөлбөрийн төрлүүд",
  _semesters: "семестр",
  chooseDurationOfStudy: "Суралцах хугацааг сонгоно уу",
  _durationOfStudy: "суралцах хугацаа",
  enterTheNameOfTheEducationalProgram: "Хөтөлбөрийн нэрийг оруулна уу",
  instruction: "Заавар",
  profile: "Анкет",
  back: "Буцах",
  next: "Цааш нь",
  testReport: "Шалгалтын үр дүнгийн тайлан",
  testDate: "Шалгалт болох өдөр",
  duration: "Үргэлжлэх хугацаа",
  memberName: "Оролцогчийн нэр",
  age: "Нас",
  gender: "Хүйс",
  currentLevelOfEducation: "Боловсролын одоогийн түвшин",
  areasOfInterestHobbies: "Сонирхол, хобби",
  sportsAchievements: "Спортын амжилт",
  testScaleResults: "Шалгалтын онооны үр дүн",
  scaleDescription: "Үр дүнг оноогоор тайлбарлах",
  recommendedEducationAreas: "Санал болгож буй боловсролын салбарууд",
  enterYourAge: "Та насаа оруулна уу",
  enterYourGender: "Та хүйсээ оруулна уу",
  chooseYourCurrentLevelOfEducation:
    "Та одоогийн боловсролын түвшингээ оруулна уу",
  chooseYourAreasOfInterestHobbies: "Та өөрийн сонирхол, хоббиг оруулна уу ",
  chooseYourSportingAchievements: "Та спортын амжилтаа оруулна уу",
  legalizationOfDocuments: "Баримт бичгийг хууль ёсны болгох",
  consularLegalization: "Консулын газраар хууль ёсны болгох",
  edit: "Засварлах",
  mobilePhoneNumber: "Гар утасны дугаар",
  iWantToReceiveNotificationsByEmail:
    "Би мэдэгдлийг цахимаар шуудангаар авах хүсэлтэй байна",
  authorizationCheck: "Баталгаажуулалтын шалгалт",
  createANewApplication: "Шинэ өргөдөл гаргах",
  selectionStage: "сонгон шалгаруулалтын үе шат",
  underRevision: "Боловсруулж байгаа",
  fillingOutAnApplication: "Өргөдлийг бөглөх",
  verificationAndRevision: "Шалгалт ба боловсруулалт",
  dossierFormation: "Хавтаст баримт бүрдүүлэх",
  fillingInTheQuestionnaire: "Анкетыг бөглөх",
  attachingDocuments: "Баримт бичгүүдийг хавсаргах",
  sendingApplicationForVerification: "Өргөдлийг шалгуулахаар илгээх",
  personalData: "Хувийн мэдээлэл",
  surnameInLatinLettersAccordingToPassport:
    "Овог, латин үсгээр (паспортын дагуу)",
  nameInLatinLettersAccordingToPassport:
    "Нэр (нэрс), латин үсгээр (паспортын дагуу)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Эцгийн нэр (хэрэв байгаа бол), кирилл үсгээр орос хувилбараар",
  placeOfBirthAccordingToPassport: "Төрсөн газар (паспортын дагуу)",
  dateOfBirth: "Төрсөн он, сар, өдөр",
  compatriotStatus: "Нутаг нэгтний статус",
  citizenshipPlaceOfPermanentResidence: "Иргэншил (байнгын оршин суудаг газар)",
  online: "Онлайн",
  profile2: "Профайл",
  goToTheApplicationForm: "Өргөдлийг бөглөх хэсэгт шилжих",
  areYouHere: "Та энд байна",
  russianTextDes1:
    "Бэлтгэлийн тэнхимүүд яагаад хэрэгтэй вэ, тэнд юу сурдаг вэ, сургалт хэр удаан үргэлжилдэг, ямар өртөгтэй вэ?",
  russianTextDes4:
    "Орос хэл дээр ганцаарчилсан хичээл заах багшийг хаанаас, яаж олох вэ",
  russianTextDes5:
    "Оросын их дээд сургуульд элсэхэд орос хэлний түвшинг хэрхэн, яагаад баталгаажуулах вэ. Гэрчилгээний шалгалт өгөх талаар бүгдийг мэдэж аваарай",
  typeOfProgram: "Хөтөлбөрийн төрөл",
  chooseTheTypeOfAdditionalProgram: "Нэмэлт хөтөлбөрийн төрлийг сонгоно уу",
  durationOfStudy: "Суралцах хугацаа",
  common: "Нийтийн",
  inYourCountry: "Танай улсад",
  courseName: "Курсын нэр",
  courseDuration: "Курсын үргэлжлэх хугацаа",
  classDuration: "Хичээлийн үргэлжлэх хугацаа",
  courseCost: "Курсын үнэ",
  courseReviews: "Курсын талаар сэтгэгдэл",
  male: "Эрэгтэй",
  female: "Эмэгтэй",
  feedbackLink: "Сэтгэгдэл харах холбоос",
  russianLanguageCoursesInYourCountry: "Танай улсад байгаа орос хэлний курсүүд",
  evaluation: "Дүн",
  giveFeedback: "Сэтгэгдэл үлдээх",
  newFeedback: "Шинэ сэтгэгдэл",
  _hour: "цаг",
  _hours: "цаг",
  __hours: "цаг",
  _minute: "минут",
  _minutes: "минут",
  __minutes: "минут",
  create: "Үүсгэх",
  feedbackSuccess:
    "Сэтгэгдэл амжилттай илгээгдсэн бөгөөд боловсруулалтыг хүлээж байна",
  _y: "он",
  _from: "from",
  error: "Алдаа",
  direction: "Чиглэл",
  _tutor: "багш",
  _tutors: "багш",
  __tutors: "багш нар",
  filters: "Шүүлтүүр",
  sendMessage: "Зурвас илгээх",
  sendMessageSuccess: "Зурвас амжилттай илгээгдсэн",
  _year: "он",
  _years: "оны",
  __years: "жил",
  _back: "буцах",
  _next: "цааш нь",
  showing: "Харуулах",
  textRussianLanguageCourses:
    "Цэснээс оршин суугаа улсаа сонгоод, бэлэн байгаа хэлний дамжаа, сургуулиудыг үзээрэй. Гэртээ орос хэлний анхан шатны мэдлэг олж авах нь цаашид дасан зохицоход ихээхэн дөхөм болно.",
  tOP10Programs: "ТОП-10 Хөтөлбөр",
  tOP10City: "ОХУ-ын ТОП-10 хотууд ",
  createANewTopic: "Шинэ сэдэв үүсгэх",
  topics: "Сэдвүүд",
  posts: "Зурвасууд",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "10 Мбайт хүртэл .pdf, .jpg, .png формат бүхий файлууд",
  noFileSelected: "Файл сонгогдоогүй",
  attachFile: "Файл хавсаргах",
  eMail: "E-mail",
  fullTime: "Бүтэн цагийн сургалт",
  toFind: "Хайх",
  searchInTopic: "Сэдэвт хайх",
  lessThan1Month: "1 сараас бага",
  "1To3Months": "1-ээс 3-н сар хүртэл",
  "3To6Months": "3-аас 6 сар хүртэл",
  "6To12Months": "6-аас 12 сар хүртэл",
  moreThan1Year: "1-ээс дээш жил",
  theDateOfThe: "Огноо",
  toAdminPanel: "Админ самбар руу очих",
  support: "Техникийн туслалцаа",
  faq: "FAQ",
  userGuide: "Хэрэглэгчийн гарын авлага",
  textUserGuide: "Порталтай ажиллах, сургалтанд хамрагдах заавар",
  textRegisterGuide: "Гадаад улсын иргэдэд зориулсан Орос улсын боловсролын сайтад бүртгүүлэх",
  textTrainingGuide: "Гадаад улсын иргэдэд зориулсан Орос улсын боловсролын сайтад суралцах өргөдөл гаргах",
  textApplication: "Анкетыг бөглөх",
  instructionForWork: "Өргөдлийг хэрхэн бөглөх тухай заавар",
  appeals: "Давж заалдах",
  handling: "Хандалт",
  __students: "оюутны",
  _student: "оюутан",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Бэлтгэл курсын чиглэлээ тодорхойлж, ирээдүйн мэргэжлээ сонгох боломжтой хувилбаруудыг олж мэдээрэй",
  delTheme: "Сэдвийг устгах",
  competitionForTheFirstPlace: "Оросын Холбооны Улсын сургалтын квотын хүрээнд 1 орон тооны төлөөх өрсөлдөөн (хүн.)",
  ruble: "рубль",
  __rubles: "рубль",
  dateOfEvent: "Явагдах огноо",
  durationUnit: "сар",
  numberPhone: "Утасны дугаар",
  offline: "Офлайн",
  to_begin: "эхэнд",
  theDateOfThe: "Эхлэх огноо",
  chat: "Чат-бот",
  yourMessageChat: "Таны зурвас",
  searchChat: "Ярианы дагуу хайх",
  firstMsgChat: "Зурвасын бичвэрийг оруулна уу",
  categoryBtnChat_1: "Өргөдөл гаргахад асуух асуулт",
  categoryBtnChat_2: "Сургалтын талаарх асуулт",
  categoryBtnChat_3: "Уралдаант шалгалтын талаарх асуулт",
  categoryBtnChat_4: "Санал эсвэл тайлбар",
  categoryBtnChat_5: "Их сургуулийн үйл ажиллагааны талаархи санал хүсэлт",
  categoryBtnChat_6: "Үйлчилгээний чанарын талаархи гомдол",
  categoryBtnChat_7: "Техникийн туслалцаанд хандах",
  notQuestionResolvedChat: "Миний асуудлыг шийдээгүй",
  questionResolvedChat: "Миний асуудлыг шийдсэн",
  countrySelectTrueChat: "Улсын нэр зөв байна",
  salutationChat: "Сайн байна уу.",
  categoryAppealChat: "Мэдээлэл авахын тулд товчлуур дээр дарж асуултынхаа ангиллыг сонгоно уу. Ангилал сонгосны дараа асуултаа зурваст оруулаад Илгээх товчийг дарна уу. '",
  langAppealChat: "Хүсэлт илгээхийг хүсч буй хэлээ сонгоно уу. Орос, англи хэл дээр илгээсэн өргөдлийг хамгийн хурдан шуурхай шийдвэрлэдэг.",
  youCountryChatPath1: "Танай улс",
  youCountryChatPath2: "Хэрэв байхгүй ​​бол хариуны зурваст тухайн улсын нэрийг оруулна уу.",
  youCountryChat: "Танай улс{country}? Байхгүй бол тухайн улсын нэрийг хариу зурваст оруулна уу.",
  notFoundCountryChat: "Таны заасан нэртэй улс олдсонгүй. Оруулсан өгөгдлийн зөв эсэхийг шалгаж, зурвасыг дахин илгээнэ үү.",
  additionalInfoChat: "Өөрийн хүсэлтийн талаархи нэмэлт мэдээллийг хариу зурваст оруулна уу.",
  yourEmailChat: "Та өөрийн имэйл хаягаа оруулна уу. Таны хүсэлтийн хариуг имэйлээр илгээх болно.",
  notValidEmailChat: "Таны илгээсэн имэйл хаяг aaa@bbb.ccc форматтай тохирохгүй байна. Оруулсан өгөгдлийн зөв эсэхийг шалгаж, зурвасыг дахин илгээнэ үү.",
  acceptRequestChat: "Таны хүсэлтийг оператор руу илгээсэн болно. Боловсруулалтын дундаж хугацаа хуанлийн 7 хоног байна.",
  notFoundEmployeeChat: "Таны заасан улс Россотрудничество хөтөлбөрийн дагуу сургалт явуулах боломжтой орнуудын жагсаалтад ороогүй байна. Таны хүсэлтийг хянуулахаар шилжүүлэх боломжгүй. Өөр улсыг зааж өгнө үү. Та сургалтын хөтөлбөрүүдийн бүрэн жагсаалтыг <a href=/education-in-russia/programs#> {host} хуудаснаас олж болно /education-in russia/p programs#</a> ",
  langBtnChat_1: "Англи",
  langBtnChat_2: "Орос",
  langBtnChat_3: "Бусад",
  addAppBtn: "Өргөдөл гаргах",
  listAppBtn: "Өргөдлийн жагсаалт",
  privacyPolicy: "Нууцлалын бодлого",
  ___programs: "хөтөлбөр",
  ___educationalPrograms: "боловсролын хөтөлбөрүүд",
  __educationalPrograms: "боловсролын хөтөлбөр",
  editTopic: "Сэдвийг засах",
  editMessage: "Зурвасыг засах",
  deleteMessage: "Зурвасыг устгах",
  blockingUser: "Хэрэглэгчийг блоклох",
  blockEndDate: "Блоклох хугацаа дуусах огноо",
  blockEndTime: "Блоклох хугацаа дуусах цаг",
  blockForever: "Бүрмөсөн блоклох",
  block: "Блоклох",
  unlock: "Блокоос гаргах",
  edited: "Засварласан",
  founder: "Үүсгэн байгуулагч",
  abbreviation: "Товчлол",
  studentsByCountry: "Улс орноор оюутнуудын тоо",
  studentsByCountryCount: "Сурч буй оюутны тоо",
  contactsInternationalDepartment: "Олон улсын хэлтэстэй холбоо барих",
  site: "Вебсайт",
  address: "Хаяг",
  format: "Формат",
  typeEvent: "Арга хэмжээний төрөл",
  quotaLearning: "Квотын сургалт",
  kcp: "Элсэлтийн оноо",
  countSeatsKCP: "Элсэлтийн онооны дагуу суралцах орон тоо",
  countSeatsCostTraining: "Төлбөртэй сургалтын орон тоо",
  distanceLearning: "Зайны сургалт",
  notificationAboutDistanceLearning: "Боловсролын хөтөлбөрийг цахим сургалтын болон зайн сургалтын технологийг ашиглан хэрэгжүүлэх боломжтой. Дэлгэрэнгүй мэдээллийг их сургуулиас лавлана уу.",
  additionalTests: "Нэмэлт сонгон шалгаруулалтыг явуулах огноо",
  pageNotFound: "Хуудас олдсонгүй",
  goToMainPage: "Нүүр хуудас руу шилжих",
  faqInformation: "Өргөдлийн төлөвийг шалгах, шаардлагатай бичиг баримтын жагсаалт, сонгон шалгаруулалтын хугацаа, элсэлтийн явцтай холбоотой бусад бүх асуултыг Россотрудничествогийн төлөөлөгчийн газар эсвэл сонгон шалгаруулалтыг танай улсад явуулах эрхтэй ОХУ Элчин сайдын яамтай холбоо барихыг зөвлөж байна. Россотрудничествогийн ажилтнуудтай холбоо барих хаягийг өөрийн хувийн хуудаснаас олж болно.",
  allEvents: "Бүх үйл явдлууд",
  eventsAndNews: "Үйл явдал ба мэдээ",
  titleNews: "Мэдээний гарчиг",
  numberOfBudgetPlaces: "Төсвийн орон тоо",
  russianGovernmentQuota: "Төcөв (Элсэлтийн хяналтын тоо)",
  legalAddress: "Албан ёсны хаяг",
  actualAddress: "Бодит хаяг",
  goToTheRegister: "Нэмэлт хөтөлбөрүүдийн бүртгэл рүү очно уу",
  searchForSuitablePrograms: "Тохиромжтой програмуудыг хайх",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Өргөдөл гаргагчдын сонгодог хамгийн эрэлт хэрэгцээтэй боловсролын хөтөлбөрүүд",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Өргөдөл гаргагчдын сонгодог Орос улсын хамгийн түгээмэл хотууд",
  ofTheCountry: "улсаас",
  passed: "Өнгөрсөн",
  question: "Асуулт",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "Эрхэм хэрэглэгчдээ! ",
  banner_2: "Манай сайт туршилтын горимоор ажиллаж байгааг анхаарна уу. Gmail цахим шуудан ашиглан бүртгүүлэх явцад техникийн саатал гарч болзошгүй тул та бүхэн өөр цахим шуудангийн хаягаар бүртгүүлнэ үү.",
  banner_3: "Элсэлтийн кампанит ажил 2022 оны 12 сарын 24-ны өдрөөс эхэлнэ.",
  typeOfEducation: "Вид образования",
   chooseTheTypeOfEducation: "Выберите вид образования",
};
