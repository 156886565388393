<template>
  <div class="not-found">
    <div class="not-found__top">
      <h1 class="not-found__status">404</h1>
      <h1 class="not-found__title">{{ $t('error') }}</h1>
    </div>
    <div class="not-found__bottom">
      <p class="not-found__text">{{ $t('pageNotFound') }}</p>
      <a href="/" class="not-found__link">{{ $t('goToMainPage') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',

  created() {
    this.$store.commit('preloader/isLoading', true)
    setTimeout(() => this.$store.commit('preloader/isLoading', false), 1500)
  }
}
</script>
