export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "帮助",
  personalArea: "个人中心",
  myApplications: "我的申请",
  servicesAndFacilities: "服务中心",
  logout: "退出",
  favorites: "收藏夹",
  theChoice: "选择",
  notifications: "通知",
  logIn: "登录",
  quickAccessThroughSocialNetworks: "通过社交账号快捷登录",
  enterYourEmail: "请输入您的电子邮箱地址",
  password: "密码",
  enterPassword: "请输入密码",
  rememberMe: "保存我的信息",
  forgotYourPassword: "忘记密码?",
  noAccount: "还没有帐号？",
  registration: "注册",
  countryOfCitizenship: "国籍",
  chooseYourCountryOfCitizenship: "请选择国籍",
  iHaveReadAndAgree: "我已阅读并同意",
  _toTheProcessingOfMyPersonalData: "个人信息处理条款",
  iAgreeTo: "同意",
  _theUseOfCookies: "使用Cookie",
  signUp: "注册",
  haveAnAccount: "已有帐号？",
  mainPage: "主页",
  frequentlyAskedQuestionsAnswers: "常见问题解答",
  goTo: "跳转",
  startTesting: "启动测试",
  buildCareerVector: "设计职业规划",
  forum: "论坛",
  textForumBanner:
    "您可以在论坛上讨论对您重要的问题，例如俄罗斯城市生活、俄罗斯大学教育等等。",
  feedback: "反馈",
  technicalSupportFeedbackForm: "反馈框",
  allCities: "所有城市",
  citiesOfRussia: "俄罗斯城市",
  educationInRussia: "留俄学习",
  educationalOrganizations: "大学",
  educationInRussiaYourKeyToSuccess: "俄罗斯教育是您成功的关键",
  applyForStudyInRussia: "申请留俄学习",
  apply: "申请",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "登录或注册以申请入学并享受就业指导服务",
  aboutOrganization: "大学介绍",
  eventsAndOlympiads: "活动与学生竞赛",
  educationalPrograms: "专业培养计划",
  contacts: "联系信息",
  inFavorites: "已在收藏夹中",
  toFavorites: "添加到收藏夹",
  fromChoices: "已选",
  choices: "选定",
  generalInformation: "基本信息",
  internationalRatings: "国际排名表",
  nationalRatings: "国家排名表",
  ratings: "排名表",
  year: "年",
  ratingName: "排名表名称",
  aPlace: "位次",
  screeningTests: "选拔测验",
  choiceOfProfession: "职业选择",
  lifeInRussia: "留俄生活",
  russianLanguage: "俄语",
  livingConditions: "生活条件",
  cultureAndLeisure: "文化休闲",
  safety: "留学安全",
  benefitsForInternationalStudents: "留学生优惠",
  workForInternationalStudents: "留学生兼职",
  studentVisaAndRegistration: "留学签证和落地签",
  healthInsurance: "医疗保险",
  howToEnter: "如何入学",
  educationLevels: "教育层次",
  admissionProcess: "入学流程",
  olympics: "学生竞赛",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "供外国公民报读俄罗斯大学的官方网站，{year}年",
  whyRussia: "留俄学习理由",
  "10reasonsToStudyInRussia": "留俄学习的十大原因",
  reviewsOfForeignStudents: "留学生评价",
  onlineMonitoring: "在线跟踪",
  mainPrograms: "主要培养计划",
  additionalPrograms: "补充性培养计划",
  chooseCounty: "请选择管区",
  county: "管区",
  chooseACity: "请选择城市",
  city: "城市",
  chooseYourLevelOfEducation: "请选择最高学历",
  chooseYourAreaOfScientificKnowledge: "选择一个科学知识领域",
  chooseYourScientificSpecialtyProfile: "选择科学专业的简介",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "最高学历",
  chooseAGroupOfTrainingAreas: "请选择学科方向类别",
  trainingDirectionGroup: "学科方向类别",
  chooseATrainingDirection: "请选择学科方向",
  trainingDirection: "学科方向",
  chooseRatings: "请选择排名表",
  thePresenceOfThePreparatoryFaculty: "设有预科班",
  show: "显示",
  _university: "所大学",
  _universities: "所大学",
  __universities: "所大学",
  clearAllFilters: "清除所有筛选条件",
  chooseSemester: "请选择学期",
  semester: "学期",
  enterTheNameOfTheEducationalOrganization: "请输入大学名称",
  _educationalOrganization: "所大学",
  _educationalOrganizations: "所大学",
  __educationalOrganizations: "所大学",
  chooseYourAreaOfEducation: "请选择学习领域",
  areaOfEducation: "学习领域",
  chooseYourLanguageOfInstruction: "请选择授课语言",
  languageOfInstruction: "授课语言",
  _program: "个专业培养计划",
  _programs: "个专业培养计划",
  __programs: "个专业培养计划",
  formOfTraining: "教学形式",
  level: "学位",
  typeOfTraining: "公费/自费",
  free: "公费",
  paid: "自费",
  tuitionFees: "学费",
  established: "成立日期",
  numberOfStudents: "学生人数",
  _students: "人",
  numberOfQuotaPlaces: "公费学生名额",
  _for: "/",
  numberOfInternationalStudents: "留学生",
  _educationalPrograms: "个专业培养计划",
  moreDetails: "详细信息",
  sTEP: "步骤",
  afterTrainingForThisProgramYouCanBecome:
    "学习这一专业后，您可以在哪些领域就业：",
  chooseProgram: "选择专业培养计划",
  chooseUniversity: "选择大学",
  print: "打印",
  save: "保存",
  programs: "专业培养计划",
  selectedProgram: "已选培养计划",
  noProgramSelected: "培养计划未被选定",
  selectedEducationalOrganizations: "已选大学",
  educationalOrganizationNotSelected: "大学未被选定",
  representation: "代表处",
  programDescription: "培养计划简介",
  programPageOnTheUniversityWebsite: "大学官网上的培养计划页面",
  programCurator: "培养计划协调人",
  disciplines: "课程",
  theProcessOfEnteringARussianEducationalOrganization: "俄罗斯大学入学流程",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "请选择一个专业培养计划和最多六所大学",
  takeTheCompetitiveSelectionInYourCountry: "在您的国家通过竞争性选拔",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "在网站上填写报读申请并附上申请材料",
  getAnInvitationToStudAnStudyVisa: "收到邀请函并办理留学签证",
  preparatoryFaculties: "留学生预科系",
  summerAndWinterSchools: "暑期/冬季学校",
  russianLanguageCourses: "俄语培训班",
  tutorsTheRussianLanguage: "俄语补习老师",
  certificationInRussian: "对外俄语等级考试",
  news: "新闻",
  allNews: "所有新闻",
  reviewsOfStudentsAndGraduates: "留学生和毕业生评价",
  areYouSureYouWantToWithdrawTheApplication: "您是否确定要撤退申请？",
  withdrawApplication: "撤回申请",
  jan: "一月",
  feb: "二月",
  mar: "三月",
  apr: "四月",
  may: "五月",
  jun: "六月",
  jul: "七月",
  aug: "八月",
  sep: "九月",
  act: "十月",
  nov: "十一月",
  dec: "十二月",
  cityFeatures: "城市特色",
  enterTheNameOfTheCity: "请输入城市名称",
  organizations: "组织",
  livingWage: "最低生活费",
  "one-roomApartmentForRent": "一室公寓租金",
  _month: "月",
  averageTemperature: "平均温度",
  _inWinter: "冬季",
  _inSummer: "夏季",
  averageCostOfDormitories: "平均宿舍住宿费",
  photos: "照片",
  interestingPlaces: "名胜古迹",
  readCompletely: "阅读更多",
  _review: "条评价",
  _reviews: "条评价",
  __reviews: "条评价",
  university: "大学",
  universities: "大学",
  country: "国家",
  selectACountry: "请选择国家",
  reviewOfAForeignStudent: "留学生评价",
  careerGuidance: "就业指导",
  determineYourAreaOfEducationAndProfession: "确定适合您的学习领域和职业",
  myCareer: "我的事业计规划",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "如何将学习、文化娱乐和社会交际相结合？强化课程的特色和价格",
  toApply: "应用",
  chooseYear: "请选择年份",
  _in: "在",
  universitiesInThisCity: "这座城市的大学",
  programsInThisCity: "这座城市的专业培养计划",
  all: "全部",
  _inYourCountry: "在您本国",
  _common: "普通的",
  allReviews: "所有评价",
  calendar: "日历",
  activity: "活动",
  addToPersonalCalendar: "添加到个人日历",
  eventAdded: "活动已添加到日历",
  eventSuccessfullyAddedToYourCalendar: "该活动已成功添加到日历中！",
  population: "人口",
  onTheMap: "在地图上",
  generalEducation: "普通教育",
  professionalEducation: "专业教育",
  preschoolEducation: "学前教育",
  kindergarten: "幼儿园",
  nursery: "托儿所",
  earlyDevelopmentCenters: "幼儿教育中心",
  primaryAndSecondaryEducation: "初等、基础和中等教育",
  schools: "学校",
  lyceums: "中学（偏理科）",
  gymnasium: "中学（偏文科）",
  secondaryVocationalEducation: "中等专业教育",
  colleges: "中等专业学校",
  technicalSchools: "中等技术学校",
  secondarySchool: "实用职业学校",
  higherEducation: "高等教育",
  baccalaureate: "本科培养计划",
  specialistDegree: "专家培养计划",
  magistracy: "硕士研究生培养计划",
  postgraduateStudies: "副博士研究生培养计划",
  preparatoryFaculty: "预科系",
  summerSchools: "暑期学校",
  additionalGeneralAndPreVocationalPrograms:
    "补充性普通教育培养计划和初等专业培养计划",
  continuingEducationAndRetrainingPrograms: "进修和职业再培训计划",
  whatAreTheLevelsOfRussianEducation: "俄罗斯有哪些教育层次？",
  enterYourName: "请输入名字",
  name: "名字",
  selectTheSubjectOfTheMessage: "请选择评论主题",
  messageSubject: "评论主题",
  chooseAMessage: "请选择评论",
  message: "评论",
  submit: "发送",
  frequentlyAskedQuestions: "常见问题解答",
  didNotFindTheAnswerToYourQuestion: "找不到问题答案？",
  contactSupportAt: "联系客服团队：",
  searchInTheSection: "在栏目中搜索",
  sort: "排序",
  searchingResults: "搜索结果",
  _answers: "人回复",
  _views: "人浏览",
  byCreationDate: "按发帖日期",
  byTheNumberOfResponses: "按回复人数",
  byViews: "按浏览人数",
  toAnswer: "回复",
  toQuote: "引用",
  complain: "举报",
  addNewMessage: "添加评论",
  selectComplaintType: "请选择举报类型",
  typeOfComplaint: "举报类型",
  enterComplaintText: "请输入举报内容",
  complaintText: "举报内容",
  complaintSentSuccessfully: "举报已收到",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "您的举报已收到，我们会尽快核实处理。",
  close: "关闭",
  myMessages: "我的评论",
  myTopicsAndPosts: "我的帖子和评论",
  searchInMyPosts: "在我的评论中搜索",
  _posts: "条评论",
  feedbackOnEducationalOrganizations: "大学评价",
  posted: "已发布",
  goToMessage: "转至评论",
  _themes: "条帖子",
  themeTitle: "帖子标题",
  enterAThemeName: "请输入帖子标题",
  cancel: "取消",
  banUser: "是否拉黑此用户？",
  areYouSure: "你确定吗？",
  toBan: "拉黑",
  _logIn: "登录",
  _or: "或",
  _signUp: "注册",
  eventCalendar: "活动日历",
  trainingApplicationsFiled: "已提交的申请数量",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    " 申请者选择最多的热门大学",
  _of: "从",
  _whenApplying: "申请时",
  tOP10EducationalOrganizations: "排名前十的大学",
  collapse: "向上滚动",
  showAll: "显示全部",
  conditionsForForeigners: "留学生入学条件",
  supportForInternationalStudents: "留学生支持",
  lifeOfForeignStudents: "留学生生活",
  leisureAndSportingEvents: "文娱体育活动",
  dormitories: "宿舍",
  dormitoryCost: "住宿费",
  theNumberOfPeopleInTheRoom: "房间人数",
  from: "从",
  _rubles: "卢布",
  _to: "到",
  _perMonth: "/每月",
  "2-3People": "2至3人",
  theInternationalCooperation: "国际合作",
  photoAndVideo: "照片和视频",
  eventTitle: "活动名称",
  location: "举办地点",
  date: "日期",
  enterYourRealMail: "请输入有效电子邮箱地址",
  requiredField: "为必填项",
  minimumLengthCharacters: "最小长度为{length}个字符",
  maximumLengthCharacters: "最大长度为{length}个字符",
  theFieldMustBeANumber: "字段必须为数字",
  onlyLettersAreAllowedInTheField: "只能输入字母",
  checkKeyboardLayout: "请检查键盘布局设置",
  checkTheNumberYouEnteredIsCorrect: "请检查您输入的编号是否正确",
  olympiadName: "学生竞赛名称",
  description: "说明",
  contactInformation: "联系信息",
  majorForeignPartnerUniversities: "主要国外合作大学",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "在世界各地远程或面授学习俄语的机会",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "你是否为补习老师？不妨申请在本网站上发布简历资料",
  submitApplication: "提交申请",
  chooseATeachingExperience: "请选择教龄",
  teachingExperience: "教龄",
  chooseLanguages: "请选择语言",
  knowledgeOfLanguages: "语言能力",
  workExperience: "工龄",
  trainingLevel: "俄语水平",
  durationOfOneLesson: "一节课的时长",
  possibleTrainingTime: "可能的教学长度",
  theCosOfOneLesson: "一节课的价格",
  writeAMessage: "添加评论",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "登录或注册之后即可向补习老师发送信息。",
  educationDocuments: "学历证书",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "您已经选择了另一个培养计划，如果确认选择这个培养计划，您所选的培养计划和大学内容将丢失。",
  limitIsExceeded: "已超出限制",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "最多可以选择六所大学。",
  _fieldsOfEducation: "学习领域",
  _trainingAreas: "学科方向",
  _cities: "城市",
  _languagesOfInstruction: "授课语言",
  _typesOfAdditionalPrograms: "补充性培养计划类别",
  _semesters: "学期",
  chooseDurationOfStudy: "请选择课程长度",
  _durationOfStudy: "课程长度",
  enterTheNameOfTheEducationalProgram: "请输入培养计划的名称",
  instruction: "填写提示",
  profile: "个人信息表",
  back: "返回",
  next: "下一步",
  testReport: "测试成绩报告",
  testDate: "测试日期",
  duration: "持续时间",
  memberName: "参与者姓名",
  age: "年龄",
  gender: "性别",
  currentLevelOfEducation: "当前学历",
  areasOfInterestHobbies: "兴趣爱好",
  sportsAchievements: "体育成就",
  testScaleResults: "测试部分的成绩",
  scaleDescription: "测试部分成绩说明",
  recommendedEducationAreas: "推荐的学习领域",
  enterYourAge: "请输入您的年龄",
  enterYourGender: "请输入您的性别",
  chooseYourCurrentLevelOfEducation: "请选择您的当前学历",
  chooseYourAreasOfInterestHobbies: "请选择您的兴趣爱好",
  chooseYourSportingAchievements: "请选择您的体育成就",
  legalizationOfDocuments: "文件认证",
  consularLegalization: "领事认证",
  edit: "编辑",
  mobilePhoneNumber: "手机号码",
  iWantToReceiveNotificationsByEmail: "我想通过电子邮件接收通知",
  authorizationCheck: "登录检查",
  createANewApplication: "创建新申请",
  selectionStage: "选拔阶段",
  underRevision: "在修改中",
  fillingOutAnApplication: "填写报读申请",
  verificationAndRevision: "检查更正",
  dossierFormation: "形成档案",
  fillingInTheQuestionnaire: "填写表格",
  attachingDocuments: "添加附件",
  sendingApplicationForVerification: "将申请提交审核",
  personalData: "个人信息",
  surnameInLatinLettersAccordingToPassport: "姓氏（按护照用拉丁字母拼写）",
  nameInLatinLettersAccordingToPassport: "名字（按护照用拉丁字母拼写）",
  middleNameIAnyCyrillicInRussianTranscription:
    "父称（如有，请用俄文字母拼写）",
  placeOfBirthAccordingToPassport: "出生地点（按护照填写）",
  dateOfBirth: "出生日期",
  compatriotStatus: "俄罗斯同胞身份",
  citizenshipPlaceOfPermanentResidence: "国籍 （永久居留国）",
  online: "在线",
  profile2: "个人资料",
  goToTheApplicationForm: "转至填写报读申请",
  areYouHere: "您在这里",
  russianTextDes1:
    "为何要上预科班？预科班有哪些学习内容？预科课程持续时间多长？费用多少？",
  russianTextDes4: "在哪里寻找教授一对一俄语课程的补习老师？如何寻找?",
  russianTextDes5:
    "为何入读俄罗斯大学需要获得俄语等级证书？如何获得？了解有关俄语等级考试的所有信息。",
  typeOfProgram: "培养计划类别",
  chooseTheTypeOfAdditionalProgram: "请选择补充性培养计划类别",
  durationOfStudy: "长度",
  common: "普通的",
  inYourCountry: "在您本国",
  courseName: "课程名称",
  courseDuration: "课程长度",
  classDuration: "一节课的市场",
  courseCost: "课程价格",
  courseReviews: "课程评价",
  male: "男",
  female: "女",
  feedbackLink: "学生评价链接",
  russianLanguageCoursesInYourCountry: "在您的国家报名俄语培训班",
  evaluation: "评价",
  giveFeedback: "留下评价",
  newFeedback: "新的评价",
  _hour: "小时",
  _hours: "小时",
  __hours: "小时",
  _minute: "分",
  _minutes: "分",
  __minutes: "分",
  create: "创建",
  feedbackSuccess: "评价已成功提交，正在等待审核",
  _y: "年",
  _from: "c",
  error: "错误",
  direction: "学科方向",
  _tutor: "位补习老师",
  _tutors: "位补习老师",
  __tutors: "位补习老师",
  filters: "筛选器",
  sendMessage: "发送评论",
  sendMessageSuccess: "评论已发送",
  _year: "年",
  _years: "年",
  __years: "年",
  _back: "返回",
  _next: "下一步",
  showing: "显示",
  textRussianLanguageCourses:
    "在菜单中选择您所居住的国家，并查看有效的俄语课程和俄语学校。在自己国家掌握基本俄语能力可以帮助您适应俄罗斯生活。",
  tOP10Programs: "十大培养计划",
  tOP10City: "十大俄罗斯城市",
  createANewTopic: "发帖子",
  topics: "帖子",
  posts: "评论",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "可支持文件格式为.pdf、 .jpg、 .png，且文件大小不超过10MB",
  noFileSelected: "未选择文件",
  attachFile: "添加附件",
  eMail: "电子邮箱地址",
  fullTime: "全日制",
  toFind: "搜索",
  searchInTopic: "搜索帖子",
  lessThan1Month: "1个月以下",
  "1To3Months": "1～3个月",
  "3To6Months": "3～6个月",
  "6To12Months": "6～12个月",
  moreThan1Year: "一年以上",
  theDateOfThe: "测试日期",
  toAdminPanel: "转至后台管理面板",
  support: "技术支持",
  faq: "常见问题",
  userGuide: "用户指南",
  textUserGuide: "门户网站使用说明和报读申请提交须知",
  textRegisterGuide: "在“外国人在俄教育”网站上办理注册手续",
  textTrainingGuide: "在“外国人在俄教育”网站上提交报读申请",
  textApplication: "填写个人信息表",
  instructionForWork: "申请表填写说明",
  appeals: "申诉",
  handling: "学生",
  __students: "请求",
  _student: "学生",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "确定心仪的一组学科方向并获得关于未来就业前景的更多信息",
  delTheme: "删除主题",
  competitionForTheFirstPlace: "以俄罗斯联邦一个公费名额计算的报考生比例（人）",
  ruble: "卢布",
  __rubles: "卢布",
  dateOfEvent: "举行日期",
  durationUnit: "月份",
  numberPhone: "电话号码",
  offline: "离线",
  to_begin: "返回首页",
  theDateOfThe: "开始日期",
  chat: "聊天机器人",
  yourMessageChat: "您的消息",
  searchChat: "在对话中搜索",
  firstMsgChat: "输入消息内容",
  categoryBtnChat_1: "涉及申请提交流程的问题",
  categoryBtnChat_2: "涉及教育服务的问题",
  categoryBtnChat_3: "涉及选拔考试的问题",
  categoryBtnChat_4: "建议和意见",
  categoryBtnChat_5: "大学活动反馈",
  categoryBtnChat_6: "服务质量投诉",
  categoryBtnChat_7: "技术支持请求",
  notQuestionResolvedChat: "我的问题尚未解决",
  questionResolvedChat: "我的问题已解决了",
  countrySelectTrueChat: "国家输入无误",
  salutationChat: "您好！",
  categoryAppealChat: "以获取所需信息，请选择您的问题类型并点击相应的按钮。选择问题类型后，请在消息框中输入您的问题并点击“发送”按钮。",
  langAppealChat: "请选择消息语种。俄文和英文消息的处理速度更高。",
  youCountryChatPath1: "您的国家",
  youCountryChatPath2: "若否，请在消息中回复国家名称。",
  youCountryChat: "您的国家是否为{country}？若否，请在消息中回复国家名称。",
  notFoundCountryChat: "系统无法找到您输入的国家。请检查所输入信息的正确性并重新发送消息。",
  additionalInfoChat: "请在消息中回复与您请求相关的附加信息。",
  yourEmailChat: "请注明您的电子邮箱。 我们将通过电子邮件的方式对您请求进行回复。",
  notValidEmailChat: "您输入的电子邮箱与aaa@bbb.ccc格式不符。 请检查所输入信息的正确性并重新发送消息。",
  acceptRequestChat: "您的请求已发送至操作员进行处理。 平均审理时间为7个自然日。",
  notFoundEmployeeChat: "您注明的国家未被列入可以享受俄罗斯国合署培养计划的国家名单。 您的请求无法被提交进行审理。 请注明其他国家。 您可以在<a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>页面上查看完整的培养计划清单。 ",
  langBtnChat_1: "英文",
  langBtnChat_2: "俄文",
  langBtnChat_3: "其他",
  addAppBtn: "提交申请",
  listAppBtn: "申请清单",
  privacyPolicy: "隐私政策",
  ___programs: "培养计划",
  ___educationalPrograms: "培养计划",
  __educationalPrograms: "培养计划",
  editTopic: "编辑主题",
  editMessage: "编辑消息",
  deleteMessage: "删除消息",
  blockingUser: "屏蔽用户",
  blockEndDate: "屏蔽结束日期",
  blockEndTime: "屏蔽结束时间",
  blockForever: "永远屏蔽",
  block: "启动屏蔽",
  unlock: "解除屏蔽",
  edited: "已编辑",
  founder: "创办人",
  abbreviation: "缩写",
  studentsByCountry: "按国家分类的学生人数",
  studentsByCountryCount: "在读学生人数",
  contactsInternationalDepartment: "国际合作处联系方式",
  site: "网址",
  address: "地址",
  format: "格式",
  typeEvent: "活动类型",
  quotaLearning: "按配额公费教育",
  kcp: "招生控制数",
  countSeatsKCP: "招生控制数规定的名额",
  countSeatsCostTraining: "自费名额",
  distanceLearning: "远程教育",
  notificationAboutDistanceLearning: "培养计划可以在使用电子教学和远程教育技术的条件下落实。 请与大学联系了解详情。",
  additionalTests: "附加选拔考试日期",
  pageNotFound: "找不到网页",
  goToMainPage: "回到主页",
  faqInformation: "涉及申请审理状态、所需文件清单、选拔考试期限以及申报流程的问题，我们建议您与俄罗斯国合署办事处或获权在贵国进行选拔的俄罗斯联邦外交代表机构联系。 您可以在个人中心查看俄罗斯国合署工作人员的联系方式。",
  allEvents: "所有活动",
  eventsAndNews: "活动和新闻",
  titleNews: "新闻名称",
  numberOfBudgetPlaces: "公费名额",
  russianGovernmentQuota: "国家预算(入学管制数字)",
  legalAddress: "法定地址",
  actualAddress: "实际地址",
  goToTheRegister: "转至附加计划清单",
  searchForSuitablePrograms: "搜索合適的程序",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "最受应招生欢迎的培养计划",
  theMostPopularCitiesOfRussiaChosenByApplicants: "最受应招生欢迎的俄罗斯城市",
  ofTheCountry: "来自（国家）",
  passed: "已通过",
  question: "问题",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "平台目前处于试运行模式",
  banner_2: "尊敬的平台使用者，目前使用google邮箱地址时可能会存在技术问题，请您改用其他电子邮件地址进行注册。",
  banner_3: "招生工作将于2022年12月24日开始。",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
