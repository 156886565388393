<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page" v-if="city.id">
    <div class="university__page-container city-page">

      <div class="main__info-title">
        <div class="main__info-title__img cover" :style="{
        background: `url(${city.cover_image ? city.cover_image : '/img/bg-cities.875f7bf0.jpg'})`
        }">
        </div>
        <div class="container">
          <Breadcrumbs
            :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('lifeInRussia') },
              { title: $t('citiesOfRussia'), url: '/life-in-russia/cities' },
              { title: getName() }
            ]"
          />

          <h1 class="title-1">{{ city | lang('name') }}</h1>
        </div>
      </div>

      <div class="container">
        <div class="universities__info city-page-main mb32">
          <table class="table-list table-city">
            <tbody>
            <tr>
              <td>
                <span class="light">
                  {{ $t('established') }}
                </span>
                <span class="dark">
                  {{ city.date_of_foundation }} {{ $t('year').toLowerCase() }}
                </span>
              </td>
              <td>
                <span class="light">
                  {{ $t('universities') }}
                </span>
                <span class="dark">
                  {{ city.universitiesCount }}
                </span>
              </td>
              <td>
                <span class="light item-title-max-width">
                  {{ $t('livingWage') }}
                </span>
                <span class="dark">
                  {{ city.cost_of_living }}/{{ $t('_month') }}
                </span>
              </td>
              <td>
                <div class="table-list-item-block">
                  <span class="light item-title-max-width">
                    {{ $t('one-roomApartmentForRent') }}
                  </span>
                  <span class="dark item-value-max-width">
                    {{ city.rent_1_apt }}/{{ $t('_month') }}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="table-list-item-block">
                  <span class="light item-title-max-width">
                    {{ $t('population') }}
                  </span>
                  <span class="dark item-value-max-width">
                    {{ city.population }}
                  </span>
                </div>
              </td>
              <td>
                <div class="table-list-item-block">
                  <span class="light item-title-max-width">
                    {{ $t('averageTemperature') }}
                  </span>
                    <div>

                      <span class="dark item-value-max-width">
                      {{ $t('_inWinter') }}: {{ city.average_temp_winter }}°C, {{ $t('_inSummer') }}: {{ city.average_temp_summer }}°C
                      </span>

                    </div>
                </div>
              </td>
              <td>
                <div class="table-list-item-block">
                  <span class="light item-title-max-width">{{ $t('averageCostOfDormitories') }}</span>
                  <span class="dark item-value-max-width" v-if="city.min_hostel_pay">{{ city.min_hostel_pay | number }} ₽/{{ $t('_month')
                    }}</span>
                  <span class="dark" v-if="!city.min_hostel_pay">—</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table-city_mobile">
            <tbody>
            <tr>
              <td>
                <span class="light">
                  {{ $t('established') }}
                </span>
                <span class="dark">
                  {{ city.date_of_foundation }} {{ $t('year').toLowerCase() }}
                </span>
              </td>
              <td>
                <span class="light">
                  {{ $t('population') }}
                </span>
                <span class="dark">
                  {{ city.population }}
                </span>
              </td>
              <td>
                <span class="light">
                  {{ $t('universities') }}
                </span>
                <span class="dark">
                  {{ city.universitiesCount }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span class="light">
                  {{ $t('averageTemperature') }}
                </span>
                <span class="dark">
                  {{ $t('_inWinter') }}: {{ city.average_temp_winter }}°C, {{ $t('_inSummer') }}: {{ city.average_temp_summer }}°C
                </span>
              </td>
              <td>
                <span class="light">
                  {{ $t('livingWage') }}
                </span>
                <span class="dark">
                  {{ city.cost_of_living }}/{{ $t('_month') }}
                </span>
              </td>
              <td>
                <span class="light">{{ $t('averageCostOfDormitories') }}</span>
                <span class="dark" v-if="city.min_hostel_pay">{{ city.min_hostel_pay | number }} ₽/{{ $t('_month') }}</span>
                <span class="dark" v-if="!city.min_hostel_pay">—</span>
              </td>
              <td>
                <span class="light">
                  {{ $t('one-roomApartmentForRent') }}
                </span>
                <span class="dark">
                  {{ city.rent_1_apt }}/{{ $t('_month') }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="form-row__btn">
            <a
              class="btn"
              href="javascript:void(0)"
              @click="setUniversity"
            >{{ $t('universitiesInThisCity') }}</a>
            <a
              class="btn"
              href="javascript:void(0)"
              @click="setProgram"
            >{{ $t('programsInThisCity') }}</a>
          </div>
        </div>
      </div>
      <div class="container mb32">
        <div class="universities__info">
          <div class="row">
            <div class="col-4" v-html="city.description" />
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <h2 class="title-2 mb32">{{ $t('photos') }}</h2>
            <swiper :options="swiperOption" class="swiper slider-photo variant-2">
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>

              <swiper-slide
                v-for="(media, key) in city.medias"
                v-bind:key="key"
              >
                <img :alt="media.description" :src="media.path" @click="setItemSlider(key)"/>
              </swiper-slide>
            </swiper>
            <br>
            <SliderDetail v-if="isVisible" :citys="city.medias" :index="int" :display="displaySliderDetail" />
          </div>
        </div>
      </div>

      <div class="container">
        <h2 class="title-2 mb32">{{ $t('interestingPlaces') }}</h2>
      </div>

      <div class="map__wrapper">
        <Openstreetmap
          :height="600"
          :markers="city.citysights"
          is-grey
        />
      </div>
    </div>
    <div class="mb64 d-none md-show">
          <swiper class="swiper slider-default news-slider">

            <swiper-slide
                v-for="(obj, key) in city.citysights"
                v-bind:key="key"
            >
              <ItemSights :hide-grid="true" :data="[obj]" />
            </swiper-slide>
          </swiper>
        </div>
  </div>
</template>

<style>
    .main__info-title__img.cover{
        background-size: cover;
    }
    .slider-photo.variant-2 .swiper-slide img{
        cursor: pointer;
    }

    @media (max-width: 768px) {
      .table-list-item-block{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    @media (max-width: 410px) {
      .item-title-max-width{
        max-width: 160px;
      }

      .item-value-max-width{
        max-width: 120px;
      }
    }

</style>

<script>
  import _ from 'lodash';
  import Vue from 'vue';
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import SliderDetail from '@/components/generic/SliderDetail';
  import {mapActions} from 'vuex';
  import Openstreetmap from '@/components/site/pages/lifeInRussia/cities/generic/Openstreetmap';
  import ItemSights from '@/components/site/pages/citysights/ItemSights';

  export default {
    name: 'life-in-russia-city',

    components: {
      Breadcrumbs,
      Swiper,
      SwiperSlide,
      SliderDetail,
      Openstreetmap,
      ItemSights,
    },

    computed: {},

    data() {
      return {
        displaySliderDetail:false,
        int:2,
        isVisible: true,
                swiperOption: {
          initialSlide: 0,
          slidesPerView: 2,
          spaceBetween: 32,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 22,
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
          },
        },

        city: {},
      };
    },

    methods: {
      ...mapActions({
        getCity: 'lifeInRussia/getCity',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getCity({slug: this.$route.params.name})
          .then(res => {
            if (res.status === 'success') {
              this.city = res.data;

              setTimeout(function() {
                this.city.citysights = _.map(this.city.citysights, sights => ({...sights, coordinate: [sights.lat, sights.lng]}))
              }.bind(this), 300)
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      getName() {
        return Vue.filter('lang')(this.city, 'name');
      },

      setUniversity() {
        this.$store.commit('form/updateField', {model: 'filter.universities.search', value: ''});
        this.$store.commit('form/updateField', {model: 'filter.universities.main', value: {}});
        this.$store.commit('form/updateField', {model: 'filter.universities.main.city', value: this.city.id});
        this.$router.push('/education-in-russia/universities');
      },

      setProgram() {
        this.$store.commit('form/updateField', {model: 'filter.programs.search', value: ''});
        this.$store.commit('form/updateField', {model: 'filter.programs.main', value: {}});
        this.$store.commit('form/updateField', {model: 'filter.programs.main.city', value: this.city.id});
        this.$router.push('/education-in-russia/programs');
      },

      setItemSlider(int) {
        this.isVisible = false
        this.$nextTick(() => {
            this.int = int;
            this.displaySliderDetail = true;
            this.isVisible = true;
        })
      },
    },

    mounted() {
      this.getData();
    },
  };
</script>
