<template>
  <figure class="info__card">
    <div class="info__card-img">
      <img alt="" :src="data.cover_image_thumbnail" v-if="data.cover_image_thumbnail" />
      <img alt="" src="@/assets/images/card-2.png" v-if="!data.cover_image_thumbnail" />
    </div>
    <figcaption class="info__card-content">
      <h3 class="title-3">
        <router-link :to="`/life-in-russia/cities/${data.slug}`">{{ data | lang('name') }}</router-link>
      </h3>
      <ul class="list-city">
        <li>
          <span class="light">
            {{ $t('established') }}
          </span>
          <span class="dark">
            {{ data.date_of_foundation }} {{ $t('year').toLowerCase() }}
          </span>
        </li>
        <li>
          <span class="light">
            {{ $t('population') }}
          </span>
          <span class="dark">
            {{ data.population }}
          </span>
        </li>
        <li>
          <span class="light">
            {{ $t('universities') }}
          </span>
          <span class="dark">
            {{ data.universities_count }}
          </span>
        </li>
      </ul>
      <ul class="list-city-info">
        <li>
          <span class="light">
            {{ $t('averageTemperature') }}
          </span>
          <span class="dark">
            {{ $t('_inWinter') }}: {{ data.average_temp_winter }}°C, {{ $t('_inSummer') }}: {{ data.average_temp_summer }}°C
          </span>
        </li>
        <li>
          <span class="light">
            {{ $t('livingWage') }}
          </span>
          <span class="dark">
            {{ data.cost_of_living }}/{{ $t('_month') }}
          </span>
        </li>
        <li>
          <span class="light">
            {{ $t('averageCostOfDormitories') }}
          </span>
          <span class="dark" v-if="data.min_hostel_pay">
            {{ data.min_hostel_pay | number }} ₽/{{ $t('_month') }}
          </span>

          <span class="dark" v-if="!data.min_hostel_pay">—</span>
        </li>
        <li>
          <span class="light">
            {{ $t('one-roomApartmentForRent') }}
          </span>
          <span class="dark">
            {{ data.rent_1_apt }}/{{ $t('_month') }}
          </span>
        </li>
      </ul>
    </figcaption>
  </figure>
</template>

<script>
  export default {
    name: 'city-search-item',

    props: {
      data: Object,
    },
  }
</script>
