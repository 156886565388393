<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <ul class="breadcrumbs">
          <Breadcrumbs :items="[{title: $t('mainPage'), url: '/' }, {title: $t('help') }]" />
        </ul>

        <h1 class="title-1 mb32">{{ $t('help') }}</h1>

        <div class="card__vertical">
          <figure v-for="card in helpSection" class="card" :key="card.title">
            <div class="card-bg" :style="`background-image: url(${require(`@/assets/images/${card.bgImg}`)});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t(card.title) }}</h3>
              <p>{{ $t(card.description) }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" :to="card.link">{{ $t(card.btn) }}</router-link>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'Home',
    components: {
      Breadcrumbs,
    },
    data: () => ({
      helpSection:[
        {
          title: 'feedback',
          description: 'technicalSupportFeedbackForm', 
          btn: 'goTo',
          bgImg: 'info-card-bg3.png',
          link: '/help/feedback',
        },
        {
          title: 'userGuide',
          description: 'textUserGuide',
          btn: 'goTo',
          bgImg: 'info-card-bg4.jpg',
          link: '/help/user-guide',
        },
        {
          title: 'faq',
          description: 'frequentlyAskedQuestionsAnswers', 
          btn: 'goTo',
          bgImg: 'info-card-bg.png',
          link: '/help/faq',
        },
        {
          title: 'forum',
          description: 'textForumBanner', 
          btn: 'goTo',
          bgImg: 'info-card-bg2.png',
          link: '/help/forum',
        },
      ],
    }),
  computed: {},
  created() {
    this.$store.commit('preloader/isLoading', true)
    setTimeout(() => this.$store.commit('preloader/isLoading', false), 1500)
  },
  };
</script>
