export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Помощь",
  personalArea: "Личный кабинет",
  myApplications: "Мои заявки",
  servicesAndFacilities: "Услуги и сервисы",
  logout: "Выйти",
  favorites: "Избранное",
  theChoice: "Выбор",
  notifications: "Уведомления",
  logIn: "Войти",
  quickAccessThroughSocialNetworks: "Быстрый вход через социальные сети",
  enterYourEmail: "Введите ваш e-mail",
  password: "Пароль",
  enterPassword: "Введите пароль",
  rememberMe: "Запомнить меня",
  forgotYourPassword: "Забыли пароль?",
  noAccount: "Нет аккаунта?",
  registration: "Регистрация",
  countryOfCitizenship: "Страна гражданства",
  chooseYourCountryOfCitizenship: "Выберите страну гражданства",
  iHaveReadAndAgree: "Я ознакомился и даю",
  _toTheProcessingOfMyPersonalData:
    "согласие на обработку моих персональных данных",
  iAgreeTo: "Я выражаю согласие на",
  _theUseOfCookies: "использование файлов cookie",
  signUp: "Зарегистрироваться",
  haveAnAccount: "Есть аккаунт?",
  mainPage: "Главная страница",
  frequentlyAskedQuestionsAnswers: "Ответы часто задаваемые вопросы",
  goTo: "Подробнее",
  startTesting: "Начать тестирование",
  buildCareerVector: "Построить карьерный вектор",

  forum: "Форум",
  textForumBanner:
    "Обсудите на общем форуме все темы, которые вам важны: как живется в городах России, как проходит обучение в российских университетах и т.д.",
  feedback: "Обратная связь",
  technicalSupportFeedbackForm: "Форма обратной связи с технической поддержкой",
  allCities: "Все города",
  citiesOfRussia: "Города России",
  educationInRussia: "Обучение в России",
  educationalOrganizations: "Университеты",
  educationInRussiaYourKeyToSuccess: "Образование в России — ваш ключ к успеху",
  applyForStudyInRussia: "Подайте заявку на обучение в России",
  apply: "Подать заявку",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Войдите или зарегистрируйтесь, чтобы получить доступ к профориентации и подаче заявки",
  aboutOrganization: "Об организации",
  eventsAndOlympiads: "Мероприятия и олимпиады",
  educationalPrograms: "Программы",
  contacts: "Контакты",
  inFavorites: "В избранном",
  toFavorites: "В избранное",
  fromChoices: "Выбрано",
  choices: "Выбрать",
  generalInformation: "Общая информация",
  internationalRatings: "Международные рейтинги",
  nationalRatings: "Национальные рейтинги",
  ratings: "Рейтинги",
  year: "Год",
  ratingName: "Название рейтинга",
  aPlace: "Место",
  screeningTests: "Отборочные испытания",
  choiceOfProfession: "Выбор профессии",
  lifeInRussia: "Жизнь в России",
  russianLanguage: "Русский язык",
  livingConditions: "Условия жизни",
  cultureAndLeisure: "Культура и досуг",
  safety: "Безопасность",
  benefitsForInternationalStudents: "Льготы для иностранных студентов",
  workForInternationalStudents: "Работа для иностранных студентов",
  studentVisaAndRegistration: "Учебная виза и регистрация",
  healthInsurance: "Медицинское страхование",
  howToEnter: "Как поступить",
  educationLevels: "Уровни образования",
  admissionProcess: "Процесс поступления",
  olympics: "Олимпиады",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Официальный сайт для отбора иностранных граждан на обучение в России, {year} г.",
  whyRussia: "Почему Россия",
  "10reasonsToStudyInRussia": "10 причин обучаться в России",
  reviewsOfForeignStudents: "Отзывы иностранных студентов",
  onlineMonitoring: "Онлайн-мониторинг",
  mainPrograms: "Основные программы",
  additionalPrograms: "Дополнительные программы",
  chooseCounty: "Выберите округ",
  county: "Округ",
  chooseACity: "Выберите город",
  city: "Город",
  chooseYourLevelOfEducation: "Выберите уровень образования",
  theLevelOfEducation: "Уровень образования",
  chooseAGroupOfTrainingAreas: "Выберите группу направлений подготовки",
  trainingDirectionGroup: "Группа направлений подготовки",
  chooseATrainingDirection: "Выберите направление подготовки",
  trainingDirection: "Направление подготовки",
  chooseRatings: "Выберите рейтинги",
  thePresenceOfThePreparatoryFaculty: "Наличие подготовительного факультета",
  show: "Показать",
  _university: "университет",
  _universities: "университета",
  __universities: "университетов",
  clearAllFilters: "Сбросить все фильтры",
  chooseSemester: "Выберите семестр",
  semester: "Семестр",
  enterTheNameOfTheEducationalOrganization: "Введите название университета",
  _educationalOrganization: "университет",
  _educationalOrganizations: "университета",
  __educationalOrganizations: "университетов",
  chooseYourAreaOfEducation: "Выберите область образования",
  chooseYourAreaOfScientificKnowledge: "Выберите область научных знаний",
  chooseYourScientificSpecialtyProfile: "Выберите профиль научной специальности",
  scientificSpecialtyProfile: "Профиль научной специальности",
  areaOfEducation: "Область образования",
  areaOfScientificKnowledge: "Область научных знаний",
  chooseYourLanguageOfInstruction: "Выберите язык обучения",
  languageOfInstruction: "Язык обучения",
  _program: "программа",
  _programs: "программы",
  __programs: "программ",
  formOfTraining: "Форма обучения",
  level: "Уровень",
  typeOfTraining: "Вид обучения",
  free: "Бесплатное",
  paid: "Платное",
  tuitionFees: "Стоимость платного обучения",
  established: "Дата основания",
  numberOfStudents: "Количество студентов",
  _students: "студентов",
  numberOfQuotaPlaces: "Количество квотных мест",
  _for: "на",
  numberOfInternationalStudents: "Иностранных студентов",
  _educationalPrograms: "образовательных программ",
  moreDetails: "Подробнее",
  sTEP: "ШАГ",
  afterTrainingForThisProgramYouCanBecome:
    "После обучения по данной программе вы можете стать",
  chooseProgram: "Выбрать программу",
  chooseUniversity: "Выбрать университет",
  print: "Распечатать",
  save: "Сохранить",
  programs: "Программы",
  selectedProgram: "Выбранная программа",
  noProgramSelected: "Программа не выбрана",
  selectedEducationalOrganizations: "Выбранные университеты",
  educationalOrganizationNotSelected: "Университет не выбран",
  representation: "Представительство",
  programDescription: "Описание программы",
  programPageOnTheUniversityWebsite: "Страница программы на сайте университета",
  programCurator: "Куратор программы",
  disciplines: "Дисциплины",
  theProcessOfEnteringARussianEducationalOrganization:
    "Процесс поступления в российский университет",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Выберите программу и до 6 университетов",
  takeTheCompetitiveSelectionInYourCountry:
    "Пройдите конкурсный отбор в вашей стране",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Заполните заявку онлайн и приложите пакет документов",
  getAnInvitationToStudAnStudyVisa:
    "Получите приглашение на обучение и учебную визу",
  preparatoryFaculties: "Подготовительные факультеты",
  summerAndWinterSchools: "Летние и зимние школы",
  russianLanguageCourses: "Курсы русского языка",
  tutorsTheRussianLanguage: "Репетиторы русского языка",
  certificationInRussian: "Сертификация по русскому языку",
  news: "Новости",
  allNews: "Все новости",
  reviewsOfStudentsAndGraduates: "Отзывы студентов и выпускников",
  areYouSureYouWantToWithdrawTheApplication:
    "Вы действительно хотите отозвать заявку?",
  withdrawApplication: "Отозвать заявку",
  jan: "Январь",
  feb: "Февраль",
  mar: "Март",
  apr: "Апрель",
  may: "Май",
  jun: "Июнь",
  jul: "Июль",
  aug: "Август",
  sep: "Сентябрь",
  act: "Октябрь",
  nov: "Ноябрь",
  dec: "Декабрь",
  cityFeatures: "Особенности города",
  enterTheNameOfTheCity: "Введите название города",
  organizations: "Организации",
  livingWage: "Прожиточный минимум",
  "one-roomApartmentForRent": "Аренда 1-комнатной квартиры",
  _month: "мес",
  averageTemperature: "Средняя температура",
  _inWinter: "зимой",
  _inSummer: "летом",
  averageCostOfDormitories: "Средняя стоимость общежитий",
  photos: "Фотографии",
  interestingPlaces: "Интересные места",
  readCompletely: "Читать полностью",
  _review: "отзыв",
  _reviews: "отзыва",
  __reviews: "отзывы",
  university: "Университет",
  universities: "Университеты",
  country: "Страна",
  selectACountry: "Выберите страну",
  reviewOfAForeignStudent: "Отзыв иностранного студента",
  careerGuidance: "Профориентирование",
  determineYourAreaOfEducationAndProfession:
    "Определите подходящие для вас области образования и профессии",
  myCareer: "Моя карьера",
  toApply: "Применить",
  chooseYear: "Выберите год",
  _in: "в",
  universitiesInThisCity: "Университеты в этом городе",
  programsInThisCity: "Программы в этом городе",
  all: "Все",
  _inYourCountry: "в вашей стране",
  _common: "общие",
  allReviews: "Все отзывы",
  calendar: "Календарь",
  activity: "Мероприятия",
  addToPersonalCalendar: "Добавить в личный календарь",
  eventAdded: "Событие добавлено",
  eventSuccessfullyAddedToYourCalendar:
    "Событие успешно добавлено в ваш календарь!",
  population: "Население",
  onTheMap: "На карте",
  generalEducation: "Общее образование",
  professionalEducation: "Профессиональное образование",
  preschoolEducation: "Дошкольное обучение",
  kindergarten: "Детский сад",
  nursery: "Ясли",
  earlyDevelopmentCenters: "Центры раннего развития",
  primaryAndSecondaryEducation: "Начальное, основное и среднее образование",
  schools: "Школы",
  lyceums: "Лицеи",
  gymnasium: "Гимназии",
  secondaryVocationalEducation: "Среднее профессиональное образование",
  colleges: "Колледжи",
  technicalSchools: "Техникумы",
  secondarySchool: "Училища",
  higherEducation: "Высшее образование",
  baccalaureate: "Бакалавриат",
  specialistDegree: "Специалитет",
  magistracy: "Магистратура",
  postgraduateStudies: "Аспирантура",
  preparatoryFaculty: "Подготовительный факультет",
  summerSchools: "Летние школы",
  additionalGeneralAndPreVocationalPrograms:
    "Дополнительные общеразвивающие и предпрофессиональные программы",
  continuingEducationAndRetrainingPrograms:
    "Программы повышения квалификации и профессиональной переподготовки",
  whatAreTheLevelsOfRussianEducation:
    "Из каких уровней состоит российское образование?",
  enterYourName: "Введите ваше имя",
  name: "Имя",
  selectTheSubjectOfTheMessage: "Выберите тему сообщения",
  messageSubject: "Тема сообщения",
  chooseAMessage: "Выберите сообщение",
  message: "Сообщение",
  submit: "Отправить",
  frequentlyAskedQuestions: "Часто задаваемые вопросы",
  didNotFindTheAnswerToYourQuestion: "Не нашли ответ на ваш вопрос",
  contactSupportAt: "Обратитесь в службу поддержки по адресу",
  searchInTheSection: "Поиск в разделе",
  sort: "Сортировать",
  searchingResults: "Результаты поиска",
  _answers: "ответов",
  _views: "просмотров",
  byCreationDate: "По дате создания",
  byTheNumberOfResponses: "По количеству ответов",
  byViews: "По количеству просмотров",
  toAnswer: "Ответить",
  toQuote: "Цитировать",
  complain: "Пожаловаться",
  addNewMessage: "Добавить новое сообщение",
  selectComplaintType: "Выберите тип жалобы",
  typeOfComplaint: "Тип жалобы",
  enterComplaintText: "Введите текст жалобы",
  complaintText: "Текст жалобы",
  complaintSentSuccessfully: "Жалоба успешно отправлена",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Ваша жалоба успешно отправлена, мы рассмотрим вашу жалобу в ближайшее время",
  close: "Закрыть",
  myMessages: "Мои сообщения",
  myTopicsAndPosts: "Мои темы и сообщения",
  searchInMyPosts: "Поиск в моих сообщениях",
  _posts: "сообщений",
  feedbackOnEducationalOrganizations: "Отзывы об университетах",
  posted: "Опубликовано",
  goToMessage: "Перейти к сообщению",
  _themes: "тем",
  themeTitle: "Название темы",
  enterAThemeName: "Введите название темы",
  cancel: "Отменить",
  banUser: "Заблокировать пользователя?",
  areYouSure: "Вы уверены",
  toBan: "Заблокировать",
  _logIn: "авторизоваться",
  _or: "или",
  _signUp: "зарегистироваться",
  eventCalendar: "Календарь мероприятий",
  trainingApplicationsFiled: "Подано заявок на обучение",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    " Самые популярные университеты, которые выбирают абитуриенты",
  _of: "из",
  _whenApplying: "при подаче заявки",
  tOP10EducationalOrganizations: "ТОП-10 Университетов",
  collapse: "Свернуть",
  showAll: "Показать все",
  conditionsForForeigners: "Условия для иностранцев",
  supportForInternationalStudents: "Поддержка иностранных студентов",
  lifeOfForeignStudents: "Быт иностранных студентов",
  leisureAndSportingEvents: "Досуговые и спортивные мероприятия",
  dormitories: "Общежития",
  dormitoryCost: "Стоимость общежития",
  theNumberOfPeopleInTheRoom: "Количество человек в комнате",
  from: "От",
  _rubles: "рублей",
  _to: "до",
  _perMonth: "в месяц",
  "2-3People": "По 2-3 человека",
  theInternationalCooperation: "Международное сотрудничество",
  photoAndVideo: "Фото и видео",
  eventTitle: "Название мероприятия",
  location: "Место проведения",
  date: "Дата",
  enterYourRealMail: "Укажите Вашу реальную почту",
  requiredField: "Поле обязательно для заполнения",
  minimumLengthCharacters: "Минимальная длина {length} символов",
  maximumLengthCharacters: "Максимальная длина {length} символов",
  theFieldMustBeANumber: "Поле должно быть числом",
  onlyLettersAreAllowedInTheField: "В поле допустимы только буквы",
  checkKeyboardLayout: "Проверьте раскладку клавиатуры",
  checkTheNumberYouEnteredIsCorrect:
    "Проверьте правильность введённого вами номера",
  olympiadName: "Название олимпиады",
  description: "Описание",
  contactInformation: "Контактная информация",
  majorForeignPartnerUniversities: "Основные зарубежные университеты-партнеры",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Возможности очного и дистанционного изучения русского языка в России по всему миру",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Вы репетитор? Подайте анкету для публикации вашего профиля на сайте",
  submitApplication: "Подать анкету",
  chooseATeachingExperience: "Выберите преподавательский стаж",
  teachingExperience: "Преподавательский стаж",
  chooseLanguages: "Выберите языки",
  knowledgeOfLanguages: "Знание языков",
  workExperience: "Стаж работы",
  trainingLevel: "Уровень подготовки",
  durationOfOneLesson: "Длительность одного занятия",
  possibleTrainingTime: "Возможное время обучения",
  theCosOfOneLesson: "Стоимость одного занятия",
  writeAMessage: "Написать сообщение",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Вы сможете написать репетитору после регистрации или авторизации на сайте",
  educationDocuments: "Документы об образовании",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "У Вас уже выбрана другая программа, если выбрать эту программу, то выбранная программа и организации будут утеряны.",
  limitIsExceeded: "Превышен лимит",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "У Вас есть возможность выбрать не более 6-ти университетов.",
  _fieldsOfEducation: "области образования",
  _trainingAreas: "направления подготовки",
  _cities: "города",
  _languagesOfInstruction: "языки обучения",
  _typesOfAdditionalPrograms: "виды дополнительных программы",
  _semesters: "семестры",
  chooseDurationOfStudy: "Выберите продолжительность обучения",
  _durationOfStudy: "продолжительности обучения",
  enterTheNameOfTheEducationalProgram: "Введите название программы",
  instruction: "Инструкция",
  profile: "Анкета",
  back: "Назад",
  next: "Далее",
  testReport: "Отчет по результатам тестирования",
  testDate: "Дата тестирования",
  duration: "Продолжительность",
  memberName: "Имя участника",
  age: "Возраст",
  gender: "Пол",
  currentLevelOfEducation: "Текущий уровень образования",
  areasOfInterestHobbies: "Сферы интересов, хобби",
  sportsAchievements: "Спортивные достижения",
  testScaleResults: "Результаты по шкалам теста",
  scaleDescription: "Описание результатов по шкалам",
  recommendedEducationAreas: "Рекомендуемые области образования",
  enterYourAge: "Введите ваш возраст",
  enterYourGender: "Введите ваш пол",
  chooseYourCurrentLevelOfEducation: "Выберите ваш текущий уровень образования",
  chooseYourAreasOfInterestHobbies: "Выберите ваши сферы интересов, хобби",
  chooseYourSportingAchievements: "Выберите ваши спортивные достижения",
  legalizationOfDocuments: "Легализация документов",
  consularLegalization: "Консульская легализация",
  edit: "Редактировать",
  mobilePhoneNumber: "Номер мобильного телефона",
  iWantToReceiveNotificationsByEmail: "Я хочу получать уведомления на e-mail",
  authorizationCheck: "Проверка авторизации",
  createANewApplication: "Создать новую заявку",
  selectionStage: "этап отбора",
  underRevision: "На доработке",
  fillingOutAnApplication: "Заполнение заявки",
  verificationAndRevision: "Проверка и доработка",
  dossierFormation: "Формирование досье",
  fillingInTheQuestionnaire: "Заполнение анкеты",
  attachingDocuments: "Прикрепление документов",
  sendingApplicationForVerification: "Отправка заявки на проверку",
  personalData: "Личные данные",
  surnameInLatinLettersAccordingToPassport:
    "Фамилия, латинскими буквами (в соответствии с паспортом)",
  nameInLatinLettersAccordingToPassport:
    "Имя (имена), латинскими буквами (в соответствии с паспортом)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Отчество (если имеется), кириллицей в русской транскрипции",
  placeOfBirthAccordingToPassport:
    "Место рождения (в соответствии с паспортом)",
  dateOfBirth: "Дата рождения",
  compatriotStatus: "Статус соотечественника",
  citizenshipPlaceOfPermanentResidence:
    "Гражданство (место постоянного проживания)",
  online: "Онлайн",
  profile2: "Профиль",
  goToTheApplicationForm: "Перейти к заполнению заявки",
  areYouHere: "Вы здесь",
  russianTextDes1:
    "Зачем нужны подготовительные факультеты, что на них изучают, сколько длится и стоит обучение",
  russianTextDes4:
    "Где и как найти преподавателя для индивидуальных занятий по русскому языку",
  russianTextDes5:
    "Как и зачем подтверждать свой уровень русского языка для поступления в российский университет. Узнайте все о прохождении теста на сертификат",
  typeOfProgram: "Вид программы",
  chooseTheTypeOfAdditionalProgram: "Выберите вид дополнительной программы",
  durationOfStudy: "Продолжительность обучения",
  common: "Общие",
  inYourCountry: "В вашей стране",
  courseName: "Название курса",
  courseDuration: "Длительность курса",
  classDuration: "Длительность занятия",
  courseCost: "Стоимость курса",
  courseReviews: "Отзывы о курсе",
  male: "Мужской",
  female: "Женский",
  feedbackLink: "Ссылка на отзывы",
  russianLanguageCoursesInYourCountry: "Курсы русского языка в вашей стране",
  evaluation: "Оценка",
  giveFeedback: "Оставить отзыв",
  newFeedback: "Новый отзыв",
  _hour: "час",
  _hours: "часа",
  __hours: "часов",
  _minute: "минута",
  _minutes: "минуты",
  __minutes: "минут",
  create: "Создать",
  feedbackSuccess: "Отзыв успешно отправлен и ожидает модерации",
  _y: "г",
  _from: "c",
  error: "Ошибка",
  direction: "Направление",
  _tutor: "репетитор",
  _tutors: "репетитора",
  __tutors: "репетиторов",
  filters: "Фильтры",
  sendMessage: "Отправить сообщение",
  sendMessageSuccess: "Сообщение успешно отправлено",
  _year: "год",
  _years: "года",
  __years: "лет",
  _next: "дальше",
  showing: "Показывать",
  textRussianLanguageCourses:
    "Выберите в меню свою страну проживания и посмотрите доступные языковые курсы и школы. Получение базового уровня русского языка дома заметно облегчит адаптацию при переезде.",
  tOP10Programs: "ТОП-10 Программ",
  tOP10City: "ТОП-10 Городов России",
  createANewTopic: "Создать новую тему",
  topics: "Темы",
  posts: "Сообщения",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "Файлы формата .pdf, .jpg, .png до 10 Мбайт",
  noFileSelected: "Файл не выбран",
  attachFile: "Прикрепить файл",
  eMail: "E-mail",
  fullTime: "Очная",
  toFind: "Найти",
  searchInTopic: "Поиск в теме",
  lessThan1Month: "Менее 1 месяца",
  "1To3Months": "От 1 до 3 месяцев",
  "3To6Months": "От 3 до 6 месяцев",
  "6To12Months": "От 6 до 12 месяцев",
  moreThan1Year: "Более 1 года",
  toAdminPanel: "Перейти в админ панель",
  support: "Техническая поддержка",
  faq: "FAQ",
  userGuide: "Руководство пользователя",
  textUserGuide: "Инструкция по работе с порталом и подача заявки на обучение",
  textRegisterGuide:
    'Регистрация на сайте "Образование в России для иностранцев"',
  textTrainingGuide:
    'Заявка на обучение на сайте "Образование в России для иностранцев"',
  textApplication: "Заполнение анкеты",
  instructionForWork: "Инструкция  по работе с заявкой",
  appeals: "Аппеляции",
  handling: "Обращения",
  __students: "студентa",
  _student: "студент",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Определите вашу группу направлений подготовки и узнайте возможные варианты будущей профессии",
  delTheme: "Удалить тему",
  competitionForTheFirstPlace:
    "Конкурс на 1 место в пределах квоты Российской Федерации (чел.)",
  ruble: "рубль",
  __rubles: "рубля",
  dateOfEvent: "Дата проведения",
  durationUnit: "мес",
  numberPhone: "Номер телефона",
  offline: "Офлайн",
  to_begin: "в начало",
  theDateOfThe: "Дата начала",
  chat: "Чат-бот",
  yourMessageChat: "Ваше сообщение",
  searchChat: "Поиск по диалогу",
  firstMsgChat: "Введите текст сообщения",
  categoryBtnChat_1: "Вопрос по подаче заявки",
  categoryBtnChat_2: "Вопрос по обучению",
  categoryBtnChat_3: "Вопрос по конкурсному отбору",
  categoryBtnChat_4: "Предложение или замечание",
  categoryBtnChat_5: "Обратная связь по деятельности университета",
  categoryBtnChat_6: "Жалоба на качество услуг",
  categoryBtnChat_7: "Обращение в техподдержку",
  notQuestionResolvedChat: "Мой вопрос не решен",
  questionResolvedChat: "Мой вопрос решен",
  countrySelectTrueChat: "Страна указана верно",
  salutationChat: "Здравствуйте.",
  categoryAppealChat:
    'Для получения информации выберите категорию, к которой относится ваш вопрос, нажав на кнопку. После выбора категории, введите ваш вопрос в сообщении и выберите кнопку "Отправить".',
  langAppealChat:
    "Выберите язык, на котором вы хотите направить обращения. Обращения, направляемые на русском и английском языке обрабатываются наиболее оперативно.",
  youCountryChatPath1: "Ваша страна",
  youCountryChatPath2:
    "Если нет, укажите название страны в ответном сообщении.",
  youCountryChat:
    "Ваша страна {country}? Если нет, укажите название страны ответным сообщением.",
  notFoundCountryChat:
    "Страна с указанным вами названием не найдена. Проверьте корректность введенных данных и повторите отправку сообщения.",
  additionalInfoChat:
    "Пожалуйста, укажите в ответном сообщении дополнительную информацию по вашему обращению.",
  yourEmailChat:
    "Пожалуйста, укажите ваш адрес электронной почты. Ответ по вашему обращению будет направлен электронным письмом.",
  notValidEmailChat:
    "Направленный вами адрес электронной почты не соответствует формату aaa@bbb.ccc. Проверьте корректность введенных данных и повторите отправку сообщения.",
  acceptRequestChat:
    "Ваше обращение направлено в работу оператору. Средний срок рассмотрения составляет 7 календарный дней.",
  notFoundEmployeeChat:
    'Указанная вами страна не входит в список стран, в которых доступно обучение по программе Россотрудничества. Ваше обращение не может быть передано на рассмотрение. Пожалуйста, укажите другую страну. С полным списком программ обучения вы можете ознакомиться на странице <a href="/education-in-russia/programs#">{host}/education-in-russia/programs#.</a>',
  langBtnChat_1: "Английский",
  langBtnChat_2: "Русский",
  langBtnChat_3: "Другой",
  addAppBtn: "Подать заявку",
  listAppBtn: "Список заявок",
  privacyPolicy: "Политика конфиденциальности",
  ___programs: "программу",
  ___educationalPrograms: "образовательные программы",
  __educationalPrograms: "образовательная программа",
  editTopic: "Редактировать тему",
  editMessage: "Редактировать сообщение",
  deleteMessage: "Удалить сообщение",
  blockingUser: "Блокировка пользователя",
  blockEndDate: "Дата окончания блокировки",
  blockEndTime: "Время окончания блокировки",
  blockForever: "Заблокировать навсегда",
  block: "Заблокировать",
  unlock: "Разблокировать",
  edited: "Отредактировано",
  founder: "Учредитель",
  abbreviation: "Аббревиатура",
  studentsByCountry: "Количество студентов в разрезе стран",
  studentsByCountryCount: "Количество обучающихся студентов",
  contactsInternationalDepartment: "Контакты международного отдела",
  site: "Сайт",
  address: "Адрес",
  format: "Формат",
  typeEvent: "Тип мероприятия",
  quotaLearning: "Квотное обучение",
  kcp: "КЦП",
  countSeatsKCP: "Количество мест КЦП",
  countSeatsCostTraining: "Количество мест платного обучения",
  distanceLearning: "Дистанционное обучение",
  notificationAboutDistanceLearning:
    "Реализация образовательной программы возможна с применением электронного обучения и дистанционных образовательных технологий. Уточняйте подробности в вузе.",
  additionalTests: "Даты проведения дополнительных отборочных испытаний",
  pageNotFound: "Страница не найдена",
  goToMainPage: "Перейти на главную страницу",
  faqInformation:
    "По всем вопросам, связанным со статусом проверки заявки, перечнем необходимых документов, срокам проведения отборочных испытаний, а также по прочим вопросам по процессу поступления рекомендуем обращаться в представительства Россотрудничества или посольства Российской Федерации, полномочные проводить отбор в Вашей стране. Контакты для связи с сотрудниками Россотрудничества можно найти в своем личном кабинете.",
  allEvents: "Все мероприятия",
  eventsAndNews: "Мероприятия и новости",
  titleNews: "Название новости",
  numberOfBudgetPlaces: "Количество бюджетных мест",
  russianGovernmentQuota: "Бюджет (контрольные цифры приема)",
  legalAddress: "Юридический адрес",
  actualAddress: "Фактический адрес",
  goToTheRegister: "Поиск подходящих программ",
  searchForSuitablePrograms:"Поиск подходящих программ",
  captcha: "ru_RU",
  theMostPopularEducationalProgramsChosenByApplicants:
    " Самые популярные образовательные программы, которые выбирают абитуриенты",
  theMostPopularCitiesOfRussiaChosenByApplicants:
    " Самые популярные города России, которые выбирают абитуриенты",
  ofTheCountry: "из страны",
  aboutTheProject: 'О суперсервисе',
  aroundTheCountry: "По стране",
  byTheList: "Списком",
  inTheCalendar: "В календаре",
  organizers: "Организаторы",
  timeOfEvent: "Время проведения",
  formatOfEvent: "Формат проведения",
  linkOnEvent: "Ссылка на мероприятие",
  question:"Вопрос",
  passed:"Пройдено",
  instructionForProctoring: "Инструкция по прохождению тестирования с прокторингом",
  banner_1: "Портал работает в режиме Опытной эксплуатации!",
  banner_2: "Уважаемые пользователи, в настоящее время могут возникнуть технические сложности при использовании почтовых адресов Google. Просьба при работе с порталом использовать другие почтовые сервисы.",
  banner_3: "Начало приемной кампании с 24 декабря 2022 г.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
