<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <router-link to="/" class="logo">
          <!--          <img src="@/assets/images/logo.png" alt="" class="lg-hide">-->
          <!--          <img src="@/assets/images/responsive-logo.png" alt="" class="lg-show d-none">-->
        </router-link>
        <div class="header__inner-control">
          <router-link to="/pages/o-proekte" class="header__help">{{ $t('aboutTheProject') }}</router-link>
          <router-link to="/help/feedback" class="header__help">{{ $t('support') }}</router-link>
          <router-link to="/help" class="header__help">{{ $t('help') }}</router-link>

          <div class="header__lang-switcher" v-add-tag-recursive="'isDropdownLang'">
            <!-- header lang switcher -->
            <div class="lang-switcher" @click="isShownLang = !isShownLang">
              <div
                  class="lang-switcher__label" :class="{show: isShownLang}"
              >
                <div class="lang-switcher__label-icon">
                  <img :src="require(`@/assets/images/flags/img-flag-${getLocale}.png`)" alt=""/>
                </div>
                <div class="lang-switcher__label-val">
                  {{ $t(getLangName()) }}
                </div>
              </div>

              <ul class="lang-switcher__dd" v-show="isShownLang">
                <li
                    v-for="(value, key) in getFilterAllLanguages"
                    v-bind:key="key"
                >
                  <a href="#" @click="$store.commit('setLanguage', value)">
                    <img :src="value.icon" alt=""/>
                    {{ $t(value.key) }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="header__nav" v-add-tag-recursive="'isDropdownMenuTop'">
      <div class="container" style="position: static">
        <div class="header__inner">
          <router-link to="/" class="header__image">
            <img src="@/assets/images/bottom-logo.svg" alt="">
          </router-link>
          <!-- header navigation -->

          <ul class="nav">
            <li
                class="nav__item"
                @click="() => showMenu(m.id)"
                :class="{active: showDropdownMenuTop === m.id}"
                v-for="(m, k) in menu"
                v-bind:key="k"
                v-add-tag-recursive="'isDropdownMenuTop'"
            >
              <router-link :to="m.url">{{ m | lang('name') }}</router-link>

              <div class="nav__sub-wrapper" v-show="showDropdownMenuTop === m.id">
                <ul class="nav__sub">
                  <li
                      class="nav__sub-item"
                      v-for="(child, k1) in m.children"
                      v-bind:key="k1"
                      v-add-tag-recursive="'isDropdownMenuTop'"
                  >
                    <router-link :to="child.url">
                      {{ child | lang('name') }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <HeaderUser class="lg-hide"/>
          <div class="responsive-menu" :class="{open: isShownResponsive}">
            <div class="responsive-menu-btn" @click="isShownResponsive = !isShownResponsive">
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </div>

            <div class="responsive-menu-item">
              <HeaderUser/>
              <ul class="nav">
                <li
                    class="nav__item"
                    @click="() => showMenu(m.id)"
                    :class="{active: showDropdownMenuTop === m.id}"
                    v-for="(m, k) in menu"
                    v-bind:key="k"
                    v-add-tag-recursive="'isDropdownMenuTop'"
                >

                  <router-link :to="m.url">{{ m | lang('name') }}</router-link>

                  <div class="nav__sub-wrapper" v-show="showDropdownMenuTop === m.id">
                    <ul class="nav__sub">
                      <li
                          class="nav__sub-item"
                          v-for="(child, k1) in m.children"
                          v-bind:key="k1"
                          v-add-tag-recursive="'isDropdownMenuTop'"
                          @click="isShownResponsive = false"
                      >
                        <router-link :to="child.url">
                          {{ child | lang('name') }}
                        </router-link>

                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav__item" @click="isShownResponsive = false">
                  <router-link to="/pages/o-proekte" class="header__help">{{ $t('aboutTheProject') }}</router-link>
                </li>
                <li class="nav__item" @click="isShownResponsive = false">
                  <router-link to="/help/feedback" class="header__help">{{ $t('support') }}</router-link>
                </li>
                <li class="nav__item" @click="isShownResponsive = false">
                  <router-link to="/help" class="header__help">{{ $t('help') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-translucent" v-if="showDropdownMenuTop" :style="`height: ${heightMenuTranslucent}px`"/>
    <hr>
    <div class="inline-banner">
      <div class="inline-banner__container container">
        <img src="@/assets/images/icon/Attention-filled.svg" alt="">
        <span class="inline-banner__text">
          {{ $t('banner_1') }}
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import _ from 'lodash';
import HeaderUser from '@/components/site/blocks/generic/HeaderUser';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    HeaderUser,
  },

  computed: {
    ...mapGetters({
      allLanguages: 'getAllLanguages',
      getLocale: 'getLocale',
      getFilterAllLanguages: 'getFilterAllLanguages',
      menu: 'getMenu',
      isAuth: 'users/isAuth',
    }),
  },

  data: () => ({
    isShownLang: false,
    isDropdownMenuTop: false,
    showDropdownMenuTop: '',
    heightMenuTranslucent: 0,
    isShownResponsive: false,
  }),

  methods: {
    ...mapActions({
      getMenu: 'getMenu',
      getPermissionsAdminAction: 'getPermissionsAdminAction',
    }),

    getLangName() {
      return (_.find(this.allLanguages, {short: this.getLocale}) || {}).key || 'russian';
    },

    closeDropdownLang(ev) {
      if (this.isShownLang) {
        this.isShownLang = ev.target.getAttribute('add-tag-recursive') === 'isDropdownLang';
      }
    },

    closeDropdownMenuTop(ev) {
      if (this.isDropdownMenuTop) {
        this.isDropdownMenuTop = ev.target.getAttribute('add-tag-recursive') === 'isDropdownMenuTop';

        if (!(ev.target.getAttribute('add-tag-recursive') === 'isDropdownMenuTop')) {
          this.showDropdownMenuTop = '';
        }
      }
    },

    showMenu(name) {
      if (name !== this.showDropdownMenuTop) {
        this.showDropdownMenuTop = name;
        this.isDropdownMenuTop = true;
      } else {
        this.showDropdownMenuTop = '';
        this.isDropdownMenuTop = false;
      }
    },

    setHeightMenuTranslucent() {
      if (document.body.offsetWidth > 768) {
        document.body.classList.remove('ov-hide');
      }

      if (document.body.offsetWidth < 768 && this.isShownResponsive) {
        document.body.classList.add('ov-hide');
      }

      this.heightMenuTranslucent = document.body.offsetHeight - (document.querySelector('header') || {}).offsetHeight || 0;
    },
  },

  name: 'header-blocks',
  props: {},

  destroyed() {
    window.removeEventListener('resize', this.setHeightMenuTranslucent);
  },

  mounted() {
    this.getMenu().then(res => {
      this.$store.commit('setMenu', res.data);
    })
  },

  watch: {
    menu() {
      setTimeout(function() {
        window.addEventListener('resize', this.setHeightMenuTranslucent);
      }.bind(this))
    },

    isShownLang(val) {
      if (val) {
        document.body.addEventListener('click', this.closeDropdownLang)
      } else {
        document.body.removeEventListener('click', this.closeDropdownLang)
      }
    },

    isDropdownMenuTop(val) {
      this.setHeightMenuTranslucent();

      if (val) {
        document.body.addEventListener('click', this.closeDropdownMenuTop)
      } else {
        document.body.removeEventListener('click', this.closeDropdownMenuTop)
      }
    },

    isShownResponsive(val) {
      if (val) {
        document.body.classList.add('ov-hide');
      } else {
        document.body.classList.remove('ov-hide');
      }
    },
  },
};
</script>
