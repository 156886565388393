<template>
  <div class="help" data-help="help">
    <p>{{text}}</p>
  </div>
</template>

<script>

export default {
  name: 'help-forum-generic-alert-failed-theme',

  props: {
    text: String,
  },
}
</script>
