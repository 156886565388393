import {api} from '@/_services';
import _ from 'lodash';

export default {
  namespaced: true,

  actions: {

    // Cities
    getCities(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/cities/',
        params,
      });
    },

    getCity(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: `/ps/api/cities/${params.slug}`,
      });
    },

    getCitiesCloudTags(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/cities/cloudtags',
        params,
      });
    },
  },

  getters: {
    getParams: state => {
      return state.params;
    },

    getCitesList: state => {
      return state.params.cites;
    },
  },

  mutations: {
    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }

      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params);
    },
  },

  state: {
    params: {
      cites: {
        total: 0,
        data: [],
      },
    },
  },
};
