<template>
  <div class="input-date">
    <Input
      :autocomplete="autocomplete"
      :disabled="disabled"
      :label="label"
      :model="model"
      :value="value"
      :error="error"
      :hint="hint"
      :element-class="elementClass"
      :element-class-parent="elementClassParent"
      :type="type"
      :placeholder="placeholder"
      :store-path="storePath"
      :rules="rules"
      :timeout="timeout"
      :show-password="showPassword"
      :error-message="errorMessage"
      :is-sugg="isSugg"
      :element-id="elementId"
      :label-class="labelClass"
      :keyup-enter-callback="keyupEnterCallback"
    />
    <img 
      class="calendar-ico"
      :src="calendarIco"
    >
  </div>
</template>

<script>
  import Input from '@/components/generic/form/field/Input';
  import calendarIco from '@/assets/images/icon/ic-calendar-input.png';

  export default {
    name: 'InputDate',

    components: {
      Input,
    },

    props: {
      autocomplete: String,
      disabled: {type: Boolean, default: () => false},
      label: String,
      model: String,
      value: String,
      error: String,
      hint: String,
      elementClass: String,
      elementClassParent: String,
      type: {type: String, default: () => 'text'},
      placeholder: String,
      storePath: String,
      rules: {type: String, default: () => ''},
      timeout: {type: Number, default: () => 500},
      showPassword: {type: Boolean, default: () => false},
      errorMessage: String,
      isSugg: Boolean,
      elementId: String,
      labelClass: String,
      keyupEnterCallback: Function,
    },

    data() {
      return {
        date: null,
        calendarIco: calendarIco,
        isCalendar: false,
      }
    },
  }
</script>

<style lang ="scss">
  .input-date {
    position: relative;
  }
  .calendar-ico {
    position: absolute;
    right: 4%;
    top: 56%; 
    pointer-events: none;
  }
</style>
