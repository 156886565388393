<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <header-component />
    <div class="university__page-container">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url(${require('@/assets/images/bg-certification.png')});`"></div>
        <div class="container">
          <ul class="breadcrumbs">
            <li class="breadcrumbs-item">
              <a href="#">Главная страница</a>
            </li>
            <li class="breadcrumbs-item">
              <a href="#">Обучение в России</a>
            </li>
            <li class="breadcrumbs-item">
              <a href="#">Русский язык</a>
            </li>
            <li class="breadcrumbs-item">
              Сертификация по русскому языку
            </li>
          </ul>

          <h1 class="title-1">
            Сертификация по русскому языку
          </h1>
        </div>
      </div>
      <div class="container">
        <div class="universities__info">
          <div class="row">
            <div class="col-4">
              <p>
                Чтобы подтвердить уровень владения русским языком, нужно сдать международный экзамен на определение уровня владения русским
                языком ТРКИ (Тест по русскому языку как иностранному).
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2">
                Уровни ТРКИ
              </h2>
              <p class="mb10">
                Уровни владения русским языком как иностранным соответствуют шести ступеням:
              </p>
              <ol class="list-count list-sub">
                <li>А1 (ТЭУ: элементарный уровень) – минимальный уровень знаний русского языка, достаточный для ограниченного числа ситуаций
                  в повседневном общении.
                </li>
                <li>А2 (ТБУ: базовый уровень) – начальный уровень знаний русского языка, достаточный для общения в ограниченном числе
                  бытовых ситуаций. Это минимальный уровень для занятий какой-либо профессиональной деятельностью в ограниченном объеме.
                </li>
                <li>B1 (ТРКИ-I: первый уровень) – средний уровень владения русским языком, который позволяет общаться в бытовой, учебной и
                  профессиональной сферах.
                </li>
                <li>B2 (ТРКИ-II: второй уровень) – достаточно высокий уровень владения русским языком, который позволяет общаться во всех
                  сферах деятельности. Владение языком на данному уровне позволяет работать в инженерно-технической, гуманитарной и
                  естественнонаучной областях. Наличие сертификата этого уровня необходимо для получения диплома бакалавра, магистра или
                  специалиста.
                </li>
                <li>C1 (ТРКИ-III: третий уровень) – с сертификатом этого уровня можно вести профессиональную деятельность на русском языке в
                  качестве филолога, переводчика, редактора, журналиста, дипломата, менеджера, работающего в русскоязычном коллективе.
                </li>
                <li>C2 (ТРКИ-IV: четвертый уровень) – этот уровень сравним с уровнем носителя языка. Он необходим для получения диплома
                  магистра-филолога, который дает право на все виды преподавательской и научно-исследовательской деятельности в области
                  русского языка.
                </li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2">
                Из каких частей состоит тестирование
              </h2>
              <p>
                Тестирование проводится в два дня. В первый день тестируются преимущественно навыки работы с письменной информацией на
                русском языке, а также знание грамматики и лексики. Во второй день тестируется умение воспринимать информацию на русском
                языке на слух, а также участвовать в диалогах, создавать и устно воспроизводить короткие и связные тексты.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 35%;">Первый день</th>
                  <th>Второй день</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Письмо</td>
                  <td>Аудирование</td>
                </tr>
                <tr>
                  <td>Лексика</td>
                  <td>Устное собеседование</td>
                </tr>
                <tr>
                  <td>Грамматика</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Чтение</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <p>
                После успешного прохождения тестирования, ты получишь сертификат государственного образца, подтверждающий владение русским
                языком на соответствующем уровне.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2">
                Сколько действует учебная виза
              </h2>
              <p>
                Первоначально иностранным учащимся виза выдается на три месяца. Для ее продления следует обратиться в Главное управление по
                вопросам миграции МВД РФ. Там выдают многократную визу на один год. В течение этого времени можно находиться в России,
                выезжать и возвращаться обратно. По окончании срока визу снова надо продлить на 1 год и так до конца обучения.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2">
                Как продлить учебную визу
              </h2>
              <p>
                Для продления учебной визы надо за 30-40 дней до окончания срока ее действия обратиться в департамент вуза по работе с
                иностранными гражданами.
              </p>
              <p>
                Вот список документов, которые потребуется в этом случае:
              </p>

              <ol class="list-count list-sub">
                <li>Заполненная анкета.</li>
                <li>Фотография.</li>
                <li>Загранпаспорт.</li>
                <li>Копия договора на обучение или направления Минобрнауки России на обучение.</li>
                <li>Письменное ходатайство вуза в произвольной форме, с указанием причины продления визы.</li>
                <li>Госпошлина (квитанция об оплате).</li>
                <li>Отрывная часть уведомления о прибытии (выдается при постановке на миграционный учет).</li>
                <li>Миграционная карта (оригинал и копия).</li>
              </ol>
              <p>
                Госпошлина за выдачу годовой учебной визы составляет 1 600 рублей (примерно 30$).
              </p>
              <p>
                Каждый раз после продления визы тебе нужно будет заново вставать на миграционный учет. Для этого обращайся в департамент
                своего вуза по работе с иностранными студентами.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h2 class="title-2">
                Регистрация
              </h2>
              <p>
                При въезде на территорию Российской Федерации каждому иностранцу необходимо заполнить миграционную карту и иметь ее при себе
                в течение всего срока нахождения в РФ. По прибытии в город, где будет проходить обучение, нужно в течение 2-х рабочих дней
                сдать документы для постановки на первичный миграционный учет.
              </p>
              <h3 class="title-3">
                Миграционная карта
              </h3>
              <p>
                Миграционная карта служит свидетельством того, что иностранный гражданин пересек российскую границу легально. Без карты
                миграционного учета иностранный гражданин не может легально жить, учиться и работать на территории Российской Федерации.
                Бесплатные бланки миграционной карты выдают в самолете, поезде, а также до прохождения паспортного контроля в аэропорту (на
                вокзале).
                Миграционная карта действует все время вашего нахождения на территории России. При выезде из РФ ее следует сдать в пункте
                пограничного контроля. При возвращении в РФ необходимо заполнить новую миграционную карту.
              </p>

              <h3 class="title-3">
                Миграционный учет
              </h3>

              <p>
                В срок до 7 дней с даты въезда на территорию России иностранцам необходимо встать на миграционный учет. Для этого предоставь
                необходимые документы в свой университет в течение трех дней после приезда. Тебе понадобятся:
              </p>

              <ul class="list-disc list-sub">
                <li>Паспорт (или документ, удостоверяющий личность).</li>
                <li>Виза (если студент из визовой страны).</li>
                <li>Миграционная карта.</li>
              </ul>
              <p>
                Сотрудники вуза выдадут тебе отрывную часть уведомления о прибытии с отметкой Управления по вопросам миграции МВД РФ. Это и
                есть документ о постановке на миграционный учет. Он дает право находиться в стране в течение указанного в нем срока.
                Первоначально это 3 месяца, в дальнейшем 1 год.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h3 class="title-3">
                Как продлить уведомление о прибытии
              </h3>
              <p>
                Для продления срока миграционного учета необходимо за 15-20 дней до его окончания обратиться в подразделение вуза,
                ответственное за работу с иностранными студентами.
              </p>
              <h3 class="title-3">Если потерял миграционные документы</h3>
              <p>
                В случае потери или порчи миграционной карты необходимо в течение трех дней обратиться в Главное управление по вопросам
                миграции МВД РФ. Там при предъявлении документов (паспорта, визы, студенческого билета, отрывной части уведомления о
                прибытии и пр.) будет бесплатно выдан дубликат.
                Важно обратиться в Главное управление по вопросам миграции МВД РФ не позднее трех дней после утери документов.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <footer-component />
  </div>
</template>


<script>
  import HeaderComponent from '@/components/site/blocks/Header.vue';
  import FooterComponent from '@/components/site/blocks/Footer.vue';

  export default {
    name: 'Сertification',
    components: {
      HeaderComponent,
      FooterComponent,
    },
    computed: {},
    data() {
    },
  };
</script>
