<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container" v-if="review.id">

      <div class="container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('whyRussia') },
              {title: $t('reviewsOfForeignStudents'), url: '/why-russia/reviews' },
              {title: $t('reviewOfAForeignStudent') },
            ]"
        />

        <h1 class="title-1 mb32">{{ $t('reviewOfAForeignStudent') }}</h1>

        <div class="def-container">
          <div class="row">
            <div class="col-5">
              <figure class="review">
                <div class="review-avatar" :style="`background-image: url('/static/${review.author_photo_url}');`">
                  <img
                    :alt="review | lang('author')"
                    v-if="!review.author_photo_url" src="@/assets/images/default_user.png"
                  />
                </div>

                <figcaption>
                  <h3 class="title-3">{{ review | lang('author') }}, {{ review.country | lang('name') }}</h3>

                  <router-link
                    class="title-sub link md-hide"
                    :to="`//education-in-russia/universities/${review.university.slug}`"
                  >{{ review.university | lang('name') }}</router-link>
                </figcaption>
              </figure>

              <router-link
                      class="title-sub link d-none md-show mb10"
                      :to="`/education-in-russia/universities/${review.university.slug}`"
              >{{ review.university | lang('name') }}</router-link>

              <span v-html="filterLang(review, 'review')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import {mapActions} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Vue from 'vue';

  export default {
    name: 'why-russia-student-review',

    data() {
      return {
        review: {},
        filterLang: Vue.filter('lang'),
      }
    },

    components: {
      Breadcrumbs,
    },

    methods: {
      ...mapActions({
        getReview: 'whyRussia/getReview',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getReview({id: this.$route.params.id})
          .then(res => {
            if (res.status === 'success') {
              this.review = res.data;
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      this.getData();
    },
  }
</script>
