<template>
  <div :class="{ reviews__wrapper: !isSingle }">
    <div
      :class="{reviews: !isSingle}"
      v-for="(review, key) in data"
      v-bind:key="key"
    >
      <div class="reviews-slide">
        <figure class="reviews-slide-student">
          <div class="reviews-slide-student-icon">
            <div
              class="review-avatar"
              :style="`background-image: url(/static/${review.author_photo_url})`"
              v-if="review.author_photo_url"
            />

            <img
              :alt="review
              | lang('author')"
              v-if="!review.author_photo_url" src="@/assets/images/default_user.png"
            />
          </div>

          <figcaption class="reviews-slide-student-name">
            <h4 class="title-4">{{ review | lang('author') }}, {{ review.country | lang('name') }}</h4>

            <router-link
              class="title-sub link md-hide"
              :to="`/education-in-russia/universities/${review.university.slug}`"
            >{{ review.university | lang('name') }}</router-link>
          </figcaption>
        </figure>
        <div class="reviews-slide-comment">
          <router-link
            class="title-sub link d-none md-show mb10"
            :to="`/education-in-russia/universities/${review.university.slug}`"
          >{{ review.university | lang('name') }}</router-link>

          <p v-html="kitCut(review.review, reviewCut)" />

          <router-link
            class="read-more"
            :to="`/why-russia/reviews/${review.id}`"
          >{{ $t('readCompletely') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'why-russia-items-reviews',

    data: () => ({
      kitCut: Vue.filter('kitCut'),
    }),

    props: {
      data: Array,
      isSingle: Boolean,
      reviewCut: {type: Number, default: function() { return 190; }},
    },

    mounted() {
      window.scrollTo(0, 0);
    },
  }
</script>
