<template>
  <div class="form-row">
    <validation-provider :name="storePath" :rules="rules" v-slot="{errors}">
      <label :for="name" class="form-control-label" v-if="label" v-html="label" />

      <slot name="row" />

      <div class="input-container">
        <textarea
          :class="elementClass"
          :disabled="disabled"
          :id="storePath"
          :name="storePath"
          :placeholder="placeholder"
          :rows="elementRows"
          :type="elementType"
          @change="set"
          @keypress="set"
          ref="textareaInput"
          v-model="valuePreSet"
        />

        <div class="hint" v-if="hint">
          {{ hint }}
        </div>
        <div class="hint-error" v-if="errors.length">
          {{ errors[0] }}
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';

  export default {
    name: 'Textarea',

    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    props: {
      disabled    : {type: Boolean, default: () => false},
      label       : String,
      name        : String,
      value       : String,
      error       : String,
      hint        : String,
      elementClass: String,
      elementType : {type: String, default: () => 'text'},
      elementRows : {type: Number, default: () => 5},
      placeholder : String,
      rules       : {type: String, default: () => ''},
      storePath   : String,
    },
    data() {
      return {
        valuePreSet: null,
      };
    },

    watch: {
      value() {
        if (this.value && this.valuePreSet !== this.value) {
          this.valuePreSet = this.value;

          this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
        }
      },

      forms: {
        handler(val) {
          if (_.get(val, this.storePath) && _.get(val, this.storePath) !== this.$refs.textareaInput.value) {
            this.valuePreSet = _.get(val, this.storePath);
            this.$refs.textareaInput.value = this.valuePreSet;
            this.$store.commit('form/updateField', {model: this.storePath, value: this.valuePreSet});
          }

          if (!_.get(val, this.storePath)) {
            this.valuePreSet = null;
          }
        },

        deep: true,
      },
    },

    mounted() {
      if (this.storePath) {
        this.$store.commit('form/initFormField', {model: this.storePath, value: this.value || null});
      }

      if (this.valuePreSet !== this.value) {
        this.valuePreSet = this.value;
      }
    },

    methods: {
      change(el) {
        this.$store.commit('form/updateField', {model: this.storePath, value: el.target.value});
      },

      set(el) {
        clearTimeout(this.interval);
        this.interval = setTimeout(() => this.change(el), 500);
      },
    },
  };
</script>
