<template>
  <div id='app'>
    <Meta />

    <div class='app-wrapper'>
      <Preloader :isLoading="preloader"/>
      <HeaderSite v-if='!isAdmin' />
      <router-view />
      <FooterSite v-if='!isAdmin' />
      <!-- <ChatBot v-if='!isAdmin' /> -->
    </div>
  </div>
</template>

<script>
import HeaderSite from '@/components/site/blocks/Header'
import FooterSite from '@/components/site/blocks/Footer'
import Preloader from '@/components/generic/pagination/Preloader'

// import ChatBot from '@/components/site/blocks/ChatBot/ChatBot.vue'

import Meta from '@/components/site/blocks/generic/Meta'
import {mapGetters} from 'vuex'
import axios from 'axios'
import storeRoot from '@/store'


export default {
  name: 'App',

  beforeCreate() {
    const token = localStorage.getItem('userToken')
    const refreshToken = async() => {
      const tokenData = token? token.split('.'):[]
      const header = tokenData[1]&& JSON.parse(atob(tokenData[1]))

      const tzOffset = (new Date().getTimezoneOffset() + 180) * 60
      const dt = (new Date().getTime() + tzOffset) / 1000

      if (header && ((header.exp - dt) < (5 * 60))) {
        await axios.post('/ps/api/token/refresh-new', {}, {
          headers: {
            'AU-TOKEN': token,
            'Accept': 'application/json',
          },
        }).then(res => {
          const {access_token} = res.data
          localStorage.setItem('userToken', String(access_token))
        }).catch(e => {
          storeRoot.commit('users/logout')
          console.error(e)
        })
      }
    }

    refreshToken()
    setInterval(refreshToken, 30000)
  },

  components: {
    HeaderSite,
    FooterSite,
    
    // ChatBot,
    Preloader,
    Meta,
  },

  computed: {
    ...mapGetters({
      user: 'users/getUser',
      preloader: 'preloader/preloader',
    }),
    isAdmin() {
      return this.$route.path.indexOf('/admin') !== -1
    },
  },
}
</script>
<style lang='scss' scoped>
</style>

<style lang='scss' src='./assets/styles/main.scss' />
