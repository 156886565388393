import {api} from '@/_services';

export default {
  namespaced: true,

  actions: {
    getEvents(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/events',
        params,
      });
    },

    getEventById(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/events/${id}`,
        params: {},
      });
    },

    getEventsCalendar(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/events/calendar',
        params,
      });
    },

    getTypeList(store) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/zhps/api/reference/open_event/type',
      })
    },

    setEventsParticipate(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/events/participate',
        data,
      });
    },
  },

  getters: {
    getParams: state => {
      return state.params;
    },
    getTypeList: state => {
      return state;
    },
  },

  mutations: {

  },

  state: {
  },
};
