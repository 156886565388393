<template>
  <div>
    <div class="teacher__card-item">
      <div class="teacher__card-item-content">
        <figure class="teacher__card-item-user">
          <div>
            <img
              :alt="tutors | lang('author')"
              :src="tutors.photo_url"
              v-if="tutors.photo_url"
            />

            <img
              :alt="tutors | lang('author')"
              v-if="!tutors.photo_url" src="@/assets/images/default_user.png"
            />
          </div>

          <figcaption>
            <h3 class="title-3">{{ tutors | lang('name') }} {{ tutors | lang('middlename') }}</h3>
            <h5 class="title-5 light">{{ tutors | lang('address') }}</h5>
          </figcaption>
        </figure>
        <div class="d-none lg-show">
          <a
                  class="btn"
                  href="javascript:void(0)"
                  v-if="!isAuth"
                  @click="() => this.$router.push('/account/login')"
          >{{ $t('writeAMessage') }}</a>

          <a
                  class="btn"
                  href="javascript:void(0)"
                  @click="() => targetModal(`${modalMessageUniqueId}${tutors.id}`)"
                  v-if="isAuth"
          >{{ $t('writeAMessage') }}</a>

          <p class="title-sub light" v-if="!isAuth">
            {{ $t('youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite') }}
          </p>

          <p class="t-center" v-if="isAuth">
            <a
                    href="javascript:void(0)"
                    @click="() => targetModal(`${modalUniqueId}${tutors.id}`)"
                    class="link"
            >{{ $t('giveFeedback') }}</a>
          </p>
        </div>
        <table class="table-list">
          <tbody>
          <tr>
            <td>
              <span class="light">{{ $t('workExperience') }}</span>
              <span>{{ tutors.experience_years || 0 | declOfNum([this.$t('_year'), this.$t('_years'), this.$t('__years')]) }}</span>
            </td>
            <td>
              <span class="light">{{ $t('formOfTraining') }}</span>
              <span>{{ tutors | lang('learning_type') }}</span>
            </td>
          </tr>
          </tbody>
        </table>

        <div v-html="modifyUrl(lang(tutors, 'description'))" />

        <div class="teacher__card-item-price">
          <table class="table-list" style="width: 100%">
            <tbody>
            <tr>
              <td>
                <span class="light">{{ $t('trainingLevel') }}</span>
                <span>{{ tutors.level | lang('name') }}</span>
              </td>

              <td>
                <span class="light">{{ $t('durationOfOneLesson') }}</span>
                <span>{{ getTimeFormatted(tutors.lesson_duration) }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span class="light">{{ $t('possibleTrainingTime') }}</span>
                <span>{{ tutors | lang('lesson_time') }}</span>
              </td>
              <td>
                <span class="light">{{ $t('theCosOfOneLesson') }}</span>
                <span>{{ tutors.cost_per_hour | number }} ₽</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="teacher-reviews lg-hide" :class="{open: feedbackOpen}">
          <div class="teacher-reviews-title" @click="feedbackOpen = !feedbackOpen">
            {{ $t('allReviews') }} ({{ tutors.feedbacks.length }})
          </div>
          <div class="teacher-reviews-content">
            <figure
              class="teacher-reviews-content-item"
              v-for="(feedback, k) in tutors.feedbacks"
              v-bind:key="`${k}-feed`"
              v-if="feedback.status"
            >
              <div class="teacher-reviews-content-item-avatar">
                <img src="@/assets/images/icon/account_circle-24px.svg" alt=""  width="68" height="68" />
              </div>

              <figcaption class="teacher-reviews-content-item-content">
                <h4 class="title-4">{{ feedback.user.first_name }} {{ feedback.user.last_name }}</h4>
                <p v-html="feedback.message"/>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="teacher__card-item-nav">
        <a
          class="btn lg-hide"
          href="javascript:void(0)"
          v-if="!isAuth"
          @click="() => this.$router.push('/account/login')"
        >{{ $t('writeAMessage') }}</a>

        <a
          class="btn lg-hide"
          href="javascript:void(0)"
          @click="() => targetModal(`${modalMessageUniqueId}${tutors.id}`)"
          v-if="isAuth"
        >{{ $t('writeAMessage') }}</a>

        <p class="title-sub light lg-hide" v-if="!isAuth">
          {{ $t('youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite') }}
        </p>

        <p class="t-center lg-hide" v-if="isAuth">
          <a
            href="javascript:void(0)"
            @click="() => targetModal(`${modalUniqueId}${tutors.id}`)"
            class="link"
          >{{ $t('giveFeedback') }}</a>
        </p>

        <div class="teacher__card-doc">
          <h5 class="title-5 light" v-if="tutors.medias.length">{{ $t('educationDocuments') }}</h5>

          <swiper class="swiper slider-default news-slider slider-photo" :options="swiperOption">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

            <swiper-slide
              v-for="(media, key) in tutors.medias"
              v-bind:key="key"
            >
              <img :src="media.path" />
            </swiper-slide>
          </swiper>

          <ul class="tag" v-if="tutors.languages.length">
            <li
              class="tag-item"
              v-for="(lang, key) in tutors.languages"
              v-bind:key="key"
            >{{ lang | lang('name') }}</li>
          </ul>

          <div class="teacher-reviews d-none lg-show" :class="{open: feedbackOpen}">
            <div class="teacher-reviews-title" @click="feedbackOpen = !feedbackOpen">
              {{ $t('allReviews') }} ({{ tutors.feedbacks.length }})
            </div>
            <div class="teacher-reviews-content">
              <figure
                      class="teacher-reviews-content-item"
                      v-for="(feedback, k) in tutors.feedbacks"
                      v-bind:key="`${k}-feed`"
                      v-if="feedback.status"
              >
                <div class="teacher-reviews-content-item-avatar">
                  <img src="@/assets/images/icon/account_circle-24px.svg" alt=""  width="68" height="68" />
                </div>

                <figcaption class="teacher-reviews-content-item-content">
                  <h4 class="title-4">{{ feedback.user.first_name }} {{ feedback.user.last_name }}</h4>
                  <p v-html="feedback.message"/>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :unique-id="`${modalUniqueId}${tutors.id}`"
      ref="modal"
    >
      <div slot="body">
        <Form :form-callback="createFeedback">
          <template slot="form">
            <h2 class="title-2">{{ $t('newFeedback') }}</h2>

            <ElTextarea
              element-class="txt-area"
              :cols="30"
              :rows="10"
              rules="required|max:1000|min:1"
              store-path="tutors.feedback.message"
            />

            <Button type="submit" class="btn" :text="$t('submit')" />
          </template>
        </Form>
      </div>
    </Modal>

    <Modal
      :unique-id="`${modalMessageUniqueId}${tutors.id}`"
      ref="modal"
    >
      <div slot="body">
        <Form :form-callback="writeMessage">
          <template slot="form">
            <h2 class="title-2">{{ $t('sendMessage') }}</h2>

            <ElTextarea
              element-class="txt-area"
              :cols="30"
              :rows="10"
              rules="required|max:1000|min:1"
              store-path="tutors.sendMessage.message"
            />

            <Button type="submit" class="btn" :text="$t('submit')" />
          </template>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Modal from '@/components/generic/modal/Modal';
  import {mapActions, mapGetters} from 'vuex';
  import ElTextarea from '@/components/generic/form/field/Textarea';
  import Form from '@/components/generic/form/Form';
  import Button from '@/components/generic/form/Button';
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper';

  export default {
    name: 'education-in-russia-russian-tutors-tutors-item',

    components: {
      Swiper,
      SwiperSlide,
      Modal,
      ElTextarea,
      Form,
      Button,
    },

    data: () => ({
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 32,
      },

      swiperOptionDouble: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 2,
        spaceBetween: 32,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
        },
      },

      swiperOptionTree: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 3,
        spaceBetween: 32,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          750: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        },
      },

      lang: Vue.filter('lang'),
      feedbackOpen: false,
      modalUniqueId: 'modal',
      modalMessageUniqueId: 'message-modal',
      currentTutorsId: null,
    }),

    computed: {
      ...mapGetters({
        isAuth: 'users/isAuth',
        form: 'form/forms',
        isModalShown: 'modal/isModalShown',
      }),
    },

    methods: {
      ...mapActions({
        setTutorsFeedback: 'educationInRussia/setTutorsFeedback',
        setSendMessage: 'educationInRussia/setSendMessage',
      }),

      writeMessage() {
        this.setSendMessage({
          tutor_id: this.tutors.id,
          message: this.form.tutors.sendMessage.message,
        }).then(() => {
          this.$store.commit('alert/success', {message: this.$t('sendMessageSuccess')});
          this.$store.commit('modal/updateShow', {key: `${this.modalMessageUniqueId}${this.tutors.id}`, value: false});
        })
      },

      modifyUrl(content) {
        if (content.includes('<figure class="media">')) 
          {
            let result = content.split('oembed').join('iframe').split('url').join('src').split('youtu.be').join('youtube.com/embed').split('watch?v=').join('embed/').replace(/(&)[^"]*/g, '$1').replace('&', '');
            return result;
          }  
        else 
          return content;
      },

      getTimeFormatted(time) {
        let
          timeHour = Math.floor(time / 60).toString(),
          timeMinute = time - timeHour * 60;

        return `${ Vue.filter('declOfNum')(timeHour, [this.$t('_hour'), this.$t('_hours'), this.$t('__hours')]) }
        ${ timeMinute ? Vue.filter('declOfNum')(timeMinute, [this.$t('_minute'), this.$t('_minutes'), this.$t('__minutes')]) : '' }`
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(`${uniqueId}${this.tutors.id}`)},
        );
      },

      createFeedback() {
        this.setTutorsFeedback({
          tutor_id:  this.tutors.id,
          message:  this.form.tutors.feedback.message,
        }).then(() => {
          if (this.updateData) {
            this.updateData();
            this.$store.commit('alert/success', {message: this.$t('feedbackSuccess')});
            this.$store.commit('modal/updateShow', {key: `${this.modalUniqueId}${this.tutors.id}`, value: false});
          }
        })
      },
    },

    props: {
      tutors: Object,
      updateData: Function,
    },
  }
</script>
