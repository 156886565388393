<template>
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
            :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('activity'), url: '/events' },
            ]"
        />

        <h1 class="title-1 mb20">{{ $t('activity') }}</h1>

        <ul class="nav-tabs">
          <li class="nav-item">
            <a
                class="nav-link"
                @click.prevent="activeEvents = 'all'"
                :class="{ active: activeEvents === 'all' }"
                href="#item"
            >{{ $t('all') }}</a>
          </li>

          <li class="nav-item">
            <a
                class="nav-link"
                @click.prevent="activeEvents = 'common'"
                :class="{ active: activeEvents === 'common' }"
                href="#item2"
            >{{ $t('common') }}</a>
          </li>

          <li class="nav-item">
            <a
                class="nav-link"
                @click.prevent="activeEvents = 'country'"
                :class="{ active: activeEvents === 'country' }"
                href="#item3"
            >{{ $t('aroundTheCountry') }}</a>
          </li>
        </ul>
        <div class="nav-tabs-sub-wrap">
          <ul class="nav-tabs-sub sub-tabs-margin-top">
            <li class="nav-item-sub">
              <a
                  class="nav-link-sub"
                  @click.prevent="activeVariantOfTheEvent = 'byTheList'"
                  :class="{ 'active show': activeVariantOfTheEvent === 'byTheList' }"
                  href="#item-sub1"
              >{{ $t('byTheList') }}</a>
            </li>

            <li class="nav-item-sub">
              <a
                  class="nav-link-sub"
                  @click.prevent="activeVariantOfTheEvent = 'inTheCalendar'"
                  :class="{ 'active show': activeVariantOfTheEvent === 'inTheCalendar' }"
                  href="#item-sub2"
              >{{ $t('inTheCalendar') }}</a>
            </li>
          </ul>
        </div>
        <div
            class="tab-pane"
            :class="{ 'active show': activeVariantOfTheEvent === 'byTheList' }"
            id="item-sub1"
        >
          <div
              v-for="(event, key) in events.data"
              v-bind:key="key"
          >
            <div class="event-wrapper event-list-item-wrapper">
              <EventListItem :data="event" />
            </div>
          </div>
        </div>

        <div
            class="tab-pane"
            :class="{ 'active show': activeVariantOfTheEvent === 'inTheCalendar' }"
            id="item-sub2"
        >
          <EventsCalendar />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .sub-tabs-margin-top {
    margin-top: 32px!important;
  }
</style>

<script>
  import Breadcrumbs from '../../../generic/Breadcrumbs';
  import {mapActions} from 'vuex';
  import EventListItem from './generic/EventListItem';
  import EventsCalendar from './generic/EventsCalendar';
  export default {
    name: 'events',

    components: {
      EventsCalendar,
      Breadcrumbs,
      EventListItem,
    },

    data: () => ({
      activeEvents: 'all',
      activeVariantOfTheEvent: 'byTheList',
      events: [],
    }),

    methods: {
        ...mapActions({
        getEvents: 'events/getEvents',
      }),

      getEventsData() {
        this.$store.commit('preloader/isLoading', true)
        this.getEvents({
          showBy: this.activeEvents,
        }).then(res => {
          this.events = res.data;
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      this.getEventsData()
    },

    watch: {
      activeVariantOfTheEvent() {
      },
      activeEvents() {
        this.getEventsData()
      },
      events() {
      },
    },
  }
</script>
