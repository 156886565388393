export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Help",
  personalArea: "My Account",
  myApplications: "My Applications",
  servicesAndFacilities: "Services",
  logout: "Log out",
  favorites: "Favorites",
  theChoice: "Selected",
  notifications: "Notifications",
  logIn: "Log in",
  quickAccessThroughSocialNetworks: "Social media login",
  enterYourEmail: "Enter your email",
  password: "Password",
  enterPassword: "Enter your password",
  rememberMe: "Remember me",
  forgotYourPassword: "Forgot your password?",
  noAccount: "No account?",
  registration: "Registration",
  countryOfCitizenship: "Country of citizenship",
  chooseYourCountryOfCitizenship: "Select your country of citizenship",
  iHaveReadAndAgree: "I have read the conditions",
  _toTheProcessingOfMyPersonalData:
    "and I consent to the processing of my personal data",
  iAgreeTo: "I allow",
  _theUseOfCookies: "the use of cookie files",
  signUp: "Register",
  haveAnAccount: "Already have an account?",
  mainPage: "Home page",
  frequentlyAskedQuestionsAnswers: "Answers to frequently asked questions",
  goTo: "Go",
  startTesting: "Begin the test",
  buildCareerVector: "Build a career vector",
  forum: "Forum",
  textForumBanner:
    "Use the general forum to discuss topics that are important to you: life in Russian cities, education in Russian universities, etc.",
  feedback: "Contact",
  technicalSupportFeedbackForm: "Contact technical support",
  allCities: "All cities",
  citiesOfRussia: "Russian cities",
  educationInRussia: "Studying in Russia",
  educationalOrganizations: "Universities",
  educationInRussiaYourKeyToSuccess: "Education in Russia: your key to success",
  applyForStudyInRussia: "Apply to study in Russia",
  apply: "Submit an application",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Log in or sign up to access the career guide and submit your application",
  aboutOrganization: "About the organization",
  eventsAndOlympiads: "Events and academic competitions",
  educationalPrograms: "Programs",
  contacts: "Contact",
  inFavorites: "Added to Favorites",
  toFavorites: "Favorite",
  fromChoices: "Selected",
  choices: "Select",
  generalInformation: "General information",
  internationalRatings: "International rankings",
  nationalRatings: "National rankings",
  ratings: "Rankings",
  year: "Year(s)",
  ratingName: "Ranking",
  aPlace: "Location",
  screeningTests: "Screening Tests",
  choiceOfProfession: "Choosing a career path",
  lifeInRussia: "Life in Russia",
  russianLanguage: "Learning Russian",
  livingConditions: "Life in Russia",
  cultureAndLeisure: "Culture and recreation",
  safety: "Safety",
  benefitsForInternationalStudents: "International student benefits",
  workForInternationalStudents: "Working as an international student",
  studentVisaAndRegistration: "Student visa and registration",
  healthInsurance: "Health insurance",
  howToEnter: "How to Enroll",
  educationLevels: "Education levels",
  admissionProcess: "Admission process",
  olympics: "Academic competitions",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Official website for the selection of foreign citizens to study in Russia, {year}",
  whyRussia: "Why Russia?",
  "10reasonsToStudyInRussia": "10 reasons to study in Russia",
  reviewsOfForeignStudents: "Reviews from international students",
  onlineMonitoring: "Online monitoring",
  mainPrograms: "Main programs",
  additionalPrograms: "Additional programs",
  chooseCounty: "Select a district",
  county: "District",
  chooseACity: "Select a city",
  city: "City",
  areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  chooseYourLevelOfEducation: "Select an education level",
  chooseYourAreaOfScientificKnowledge: "Choose an area of scientific knowledge",
  chooseYourScientificSpecialtyProfile: "Choose a profile of a scientific specialty",
  theLevelOfEducation: "Education level",
  chooseAGroupOfTrainingAreas: "Select a general field of study",
  trainingDirectionGroup: "General field of study",
  chooseATrainingDirection: "Select a field of study",
  trainingDirection: "Field of study",
  chooseRatings: "Select a ranking",
  thePresenceOfThePreparatoryFaculty: "Preparatory courses available",
  show: "Show",
  _university: "university",
  _universities: "universities",
  __universities: "universities",
  clearAllFilters: "Clear all filters",
  chooseSemester: "Select a semester",
  semester: "Semester",
  enterTheNameOfTheEducationalOrganization: "Enter the name of the university",
  _educationalOrganization: "university",
  _educationalOrganizations: "universities",
  __educationalOrganizations: "universities",
  chooseYourAreaOfEducation: "Select an area of education",
  areaOfEducation: "Area of education",
  chooseYourLanguageOfInstruction: "Select a language",
  languageOfInstruction: "Language",
  _program: "program",
  _programs: "programs",
  __programs: "programs",
  formOfTraining: "Format",
  level: "Level",
  typeOfTraining: "Program type",
  free: "Free",
  paid: "Paid",
  tuitionFees: "Tuition fee",
  established: "Founding date",
  numberOfStudents: "Number of students",
  _students: "students",
  numberOfQuotaPlaces: "Quota spots available",
  _for: "for",
  numberOfInternationalStudents: "Foreign students",
  _educationalPrograms: "educational program",
  moreDetails: "Learn more",
  sTEP: "STEP",
  afterTrainingForThisProgramYouCanBecome:
    "After graduating from this program, you can become",
  chooseProgram: "Select a program",
  chooseUniversity: "Select a university",
  print: "Print",
  save: "Save",
  programs: "Programs",
  selectedProgram: "Selected program",
  noProgramSelected: "Program not selected",
  selectedEducationalOrganizations: "Selected universities",
  educationalOrganizationNotSelected: "University not selected",
  representation: "Representation",
  programDescription: "Program description",
  programPageOnTheUniversityWebsite: "Program page on the university website",
  programCurator: "Program supervisor",
  disciplines: "Subjects",
  theProcessOfEnteringARussianEducationalOrganization:
    "Applying to a Russian university",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Select a program and up to 6 universities",
  takeTheCompetitiveSelectionInYourCountry:
    "Complete the screening tests in your country",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Fill out your online application and attach the required documents",
  getAnInvitationToStudAnStudyVisa:
    "Receive an invitation to study and a study visa",
  preparatoryFaculties: "Preparatory courses",
  summerAndWinterSchools: "Summer and winter schools",
  russianLanguageCourses: "Russian language courses",
  tutorsTheRussianLanguage: "Russian language tutors",
  certificationInRussian: "Russian language certificate",
  news: "News",
  allNews: "All news",
  reviewsOfStudentsAndGraduates: "Reviews from students and graduates",
  areYouSureYouWantToWithdrawTheApplication:
    "Would you like to withdraw your application?",
  withdrawApplication: "Withdraw application",
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  act: "October",
  nov: "November",
  dec: "December",
  cityFeatures: "City features",
  enterTheNameOfTheCity: "Enter the name of the city",
  organizations: "Organizations",
  livingWage: "Minimum wage",
  "one-roomApartmentForRent": "Average rent for a studio apartment",
  _month: "per month",
  averageTemperature: "Average temperature",
  _inWinter: "winter",
  _inSummer: "summer",
  averageCostOfDormitories: "Cost of student housing",
  photos: "Pictures",
  interestingPlaces: "Interesting places",
  readCompletely: "Read more",
  _review: "review",
  _reviews: "reviews",
  __reviews: "reviews",
  university: "University",
  universities: "Universities",
  country: "Country",
  selectACountry: "Select a country",
  reviewOfAForeignStudent: "Review from a foreign student",
  careerGuidance: "Career guidance",
  determineYourAreaOfEducationAndProfession:
    "Find the education path and profession that are right for you.",
  myCareer: "My Career",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "How to balance your studies with cultural enrichment, recreation, and making new friends. Characteristics and cost of intensive programs.",
  toApply: "Apply",
  chooseYear: "Select a year",
  _in: "in",
  universitiesInThisCity: "Universities in this city",
  programsInThisCity: "Programs in this city",
  all: "All",
  _inYourCountry: "in your country",
  _common: "general",
  allReviews: "All reviews",
  calendar: "Calendar",
  activity: "Events",
  addToPersonalCalendar: "Add to my calendar",
  eventAdded: "Event added",
  eventSuccessfullyAddedToYourCalendar:
    "Event successfully added to your calendar!",
  population: "Population",
  onTheMap: "On the map",
  generalEducation: "General education",
  professionalEducation: "Professional education",
  preschoolEducation: "Pre-school education",
  kindergarten: "Kindergarten",
  nursery: "Nursery",
  earlyDevelopmentCenters: "Early development centers",
  primaryAndSecondaryEducation: "Primary, basic, and secondary education",
  schools: "Schools",
  lyceums: "High Schools",
  gymnasium: "Grammar Schools",
  secondaryVocationalEducation: "Secondary Vocational Education",
  colleges: "Colleges",
  technicalSchools: "Technical schools",
  secondarySchool: "Specialized schools",
  higherEducation: "Higher education",
  baccalaureate: "Undergraduate Degree",
  specialistDegree: "Specialist Degree",
  magistracy: "Master's Degree",
  postgraduateStudies: "Postgraduate Degree",
  preparatoryFaculty: "Preparatory courses",
  summerSchools: "Summer Schools",
  additionalGeneralAndPreVocationalPrograms:
    "Additional general development and pre-professional programs",
  continuingEducationAndRetrainingPrograms:
    "Advanced training and professional retraining programs",
  whatAreTheLevelsOfRussianEducation:
    "What are the levels of the Russian education system?",
  enterYourName: "Enter your name",
  name: "Name",
  selectTheSubjectOfTheMessage: "Enter the subject of your message",
  messageSubject: "Message subject",
  chooseAMessage: "Select the message",
  message: "Message",
  submit: "Send",
  frequentlyAskedQuestions: "FAQ",
  didNotFindTheAnswerToYourQuestion:
    "Didn't find the answer you were looking for",
  contactSupportAt: "You can contact the support service via this address",
  searchInTheSection: "Search this section",
  sort: "Sort by",
  searchingResults: "Search results",
  _answers: "responses",
  _views: "views",
  byCreationDate: "By date of creation",
  byTheNumberOfResponses: "By number of responses",
  byViews: "By number of views",
  toAnswer: "Respond",
  toQuote: "Quote",
  complain: "Report",
  addNewMessage: "Add a new message",
  selectComplaintType: "Select a type of report",
  typeOfComplaint: "Report type",
  enterComplaintText: "Enter your report",
  complaintText: "Report text",
  complaintSentSuccessfully: "Your report has been submitted",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Your report has been submitted and will be reviewed shortly",
  close: "Close",
  myMessages: "My Messages",
  myTopicsAndPosts: "My Topics and Messages",
  searchInMyPosts: "Search my messages",
  _posts: "messages",
  feedbackOnEducationalOrganizations: "University reviews",
  posted: "Published",
  goToMessage: "Jump to message",
  _themes: "topics",
  themeTitle: "Topic name",
  enterAThemeName: "Enter the topic name",
  cancel: "Cancel",
  banUser: "Ban user?",
  areYouSure: "Are you sure?",
  toBan: "Ban",
  _logIn: "Log in",
  _or: "or",
  _signUp: "sign up",
  eventCalendar: "Event calendar",
  trainingApplicationsFiled: "Applications submitted",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    " The most popular universities for applicants",
  _of: "from",
  _whenApplying: "when submitting an application",
  tOP10EducationalOrganizations: "TOP 10 Universities",
  collapse: "Collapse",
  showAll: "Show all",
  conditionsForForeigners: "Conditions for foreigners",
  supportForInternationalStudents: "Support for foreign students",
  lifeOfForeignStudents: "Everyday life for foreign students",
  leisureAndSportingEvents: "Leisure and sports",
  dormitories: "Student housing",
  dormitoryCost: "Cost of student housing",
  theNumberOfPeopleInTheRoom: "Number of people per room",
  from: "From",
  _rubles: "rubles",
  _to: "up to",
  _perMonth: "per month",
  "2-3People": "2–3 people",
  theInternationalCooperation: "International partnership",
  photoAndVideo: "Pictures and videos",
  eventTitle: "Event name",
  location: "Event location",
  date: "Date",
  enterYourRealMail: "Enter your active email address",
  requiredField: "This field must be completed",
  minimumLengthCharacters: "This field must contain at least {length} symbols",
  maximumLengthCharacters:
    "This field must contain no more than {length} symbols",
  theFieldMustBeANumber: "This field must be a number",
  onlyLettersAreAllowedInTheField: "You can only use letters in this field",
  checkKeyboardLayout: "Check your keyboard layout",
  checkTheNumberYouEnteredIsCorrect:
    "Make sure you have entered your number correctly",
  olympiadName: "Name of the academic competition",
  description: "Description",
  contactInformation: "Contact information",
  majorForeignPartnerUniversities:
    "Our main international partner universities",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Opportunities to learn Russian in Russia and around the world, both in-person and remotely.",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Are you a tutor? Submit an application to have your profile published on the website",
  submitApplication: "Apply",
  chooseATeachingExperience: "Select your teaching experience",
  teachingExperience: "Teaching experience",
  chooseLanguages: "Select your languages",
  knowledgeOfLanguages: "Language proficiency",
  workExperience: "Work experience",
  trainingLevel: "Level of training",
  durationOfOneLesson: "Length of one tutoring session",
  possibleTrainingTime: "Availability",
  theCosOfOneLesson: "Cost per tutoring session",
  writeAMessage: "Send a message",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "You can message a tutor by signing up or logging in to the website",
  educationDocuments: "Academic certificates",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "You have already selected another program. If you choose this program, the program and organizations that you have previously selected will be discarded.",
  limitIsExceeded: "Limit exceeded",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "You can choose up to 6 universities",
  _fieldsOfEducation: "areas of education",
  _trainingAreas: "fields of study",
  _cities: "cities",
  _languagesOfInstruction: "languages",
  _typesOfAdditionalPrograms: "types of additional programs",
  _semesters: "semesters",
  chooseDurationOfStudy: "Select the program length",
  _durationOfStudy: "program length",
  enterTheNameOfTheEducationalProgram: "Enter the name of the program",
  instruction: "Instructions",
  profile: "Form",
  back: "Back",
  next: "Next",
  testReport: "Testing results",
  testDate: "Test date",
  duration: "Duration",
  memberName: "Participant name",
  age: "Age",
  gender: "Sex",
  currentLevelOfEducation: "Current education level",
  areasOfInterestHobbies: "Interests and hobbies",
  sportsAchievements: "Athletic achievements",
  testScaleResults: "Scaled results",
  scaleDescription: "Description of scaled results",
  recommendedEducationAreas: "Recommended areas of education",
  enterYourAge: "Enter your age",
  enterYourGender: "Enter your sex",
  chooseYourCurrentLevelOfEducation: "Select your current education level",
  chooseYourAreasOfInterestHobbies: "Select your interests and hobbies",
  chooseYourSportingAchievements: "Select your athletic achievements",
  legalizationOfDocuments: "Document legalization",
  consularLegalization: "Consular legalization",
  edit: "Edit",
  mobilePhoneNumber: "Mobile phone number",
  iWantToReceiveNotificationsByEmail:
    "I want to receive notifications via email",
  authorizationCheck: "Authorizing login",
  createANewApplication: "Create a new application",
  selectionStage: "selection stage",
  underRevision: "Under development",
  fillingOutAnApplication: "Application",
  verificationAndRevision: "Review and revision",
  dossierFormation: "Dossier",
  fillingInTheQuestionnaire: "Form",
  attachingDocuments: "Documents",
  sendingApplicationForVerification: "Submit for Review",
  personalData: "Personal data",
  surnameInLatinLettersAccordingToPassport:
    "Surname in Latin letters (as stated in your passport)",
  nameInLatinLettersAccordingToPassport:
    "Name (names) in Latin letters (as stated in your passport)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Patronymic or middle name (if applicable) transliterated into Russian with Cyrillic letters",
  placeOfBirthAccordingToPassport:
    "Place of birth (as stated in your passport)",
  dateOfBirth: "Date of birth",
  compatriotStatus: "Compatriot status",
  citizenshipPlaceOfPermanentResidence:
    "Citizenship (place of permanent residence)",
  online: "Online",
  profile2: "My Profile",
  goToTheApplicationForm: "Complete your application",
  areYouHere: "You are here",
  russianTextDes1:
    "Why take a preparatory course, what will students learn, length and cost of a course",
  russianTextDes4:
    "Where and how to find a teacher for private Russian lessons",
  russianTextDes5:
    "How and why you should certify your proficiency in the Russian language for  university admission in Russia. Learn all about the Russian language certification test.",
  typeOfProgram: "Program type",
  chooseTheTypeOfAdditionalProgram: "Select a type of additional program",
  durationOfStudy: "Length of educational program",
  common: "General",
  inYourCountry: "In your country",
  courseName: "Course name",
  courseDuration: "Course length",
  classDuration: "Class length",
  courseCost: "Course cost",
  courseReviews: "Course reviews",
  male: "Male",
  female: "Female",
  feedbackLink: "Link to review",
  russianLanguageCoursesInYourCountry:
    "Russian language courses in your country",
  evaluation: "Rating",
  giveFeedback: "Leave a review",
  newFeedback: "New review",
  _hour: "hour",
  _hours: "hours",
  __hours: "hours",
  _minute: "minute",
  _minutes: "minutes",
  __minutes: "minutes",
  create: "Create",
  feedbackSuccess:
    "You have successfully submitted your review. It must now be approved by a moderator",
  _y: "year",
  _from: "from",
  error: "Error",
  direction: "Field",
  _tutor: "tutor",
  _tutors: "tutors",
  __tutors: "tutors",
  filters: "Filters",
  sendMessage: "Send a message",
  sendMessageSuccess: "Message sent successfully",
  _year: "year",
  _years: "years",
  __years: "years",
  _back: "back",
  _next: "next",
  showing: "Show",
  textRussianLanguageCourses:
    "Select your country of residence from the menu and view the available language courses and schools. A basic understanding of Russian will make it easier to adapt when you arrive.",
  tOP10Programs: "TOP 10 Programs",
  tOP10City: "TOP 10 Cities in Russia",
  createANewTopic: "Create a new topic",
  topics: "Topics",
  posts: "Messages",
  filesOfTheFormatPdfJpgPngUpTo10MB: ".pdf, .jpg, and .png files up to 10 Mb",
  noFileSelected: "File not chosen",
  attachFile: "Attach a file",
  eMail: "E-mail",
  fullTime: "In-person",
  toFind: "Search",
  searchInTopic: "Search the topic",
  lessThan1Month: "Less than a month",
  "1To3Months": "1–3 months",
  "3To6Months": "3–6 months",
  "6To12Months": "6–12 months",
  moreThan1Year: "12+ months",
  theDateOfThe: "Event date",
  toAdminPanel: "Go to admin panel",
  aboutTheProject: 'About the project',
  organizers: "Organizers",
  timeOfEvent: "Time of the event",
  formatOfEvent: "Format of the event",
  linkOnEvent: "Link to the event",
  support: "Tech support",
  faq: "FAQ",
  userGuide: "User Guide",
  textUserGuide: "How to use the website and apply for study",
  textRegisterGuide: "Registration on Study in Russia",
  textTrainingGuide: "Applying for study on Study in Russia",
  textApplication: "Completing the form",
  instructionForWork: "Application process instructions",
  appeals: "Appeals",
  handling: "Inquiries",
  __students: "of the student",
  _student: "student",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Make up your mind about your desired fields of study and find out about your career options",
  delTheme: "Delete subject",
  competitionForTheFirstPlace: "Competition per placement within the Russian Federation's quota (no. of persons)",
  ruble: "ruble",
  __rubles: "ruble",
  dateOfEvent: "Event date",
  durationUnit: "mth",
  numberPhone: "Phone No.",
  offline: "Offline",
  to_begin: "back to top",
  theDateOfThe: "Start date",
  chat: "Chat bot",
  yourMessageChat: "Your message",
  searchChat: "Search dialogue",
  firstMsgChat: "Enter message text",
  categoryBtnChat_1: "Question re application",
  categoryBtnChat_2: "Question re study",
  categoryBtnChat_3: "Question re competitive screening",
  categoryBtnChat_4: "Comment or suggestion",
  categoryBtnChat_5: "University services feedback",
  categoryBtnChat_6: "Service complaint",
  categoryBtnChat_7: "Contact tech support",
  notQuestionResolvedChat: "My issue is not resolved",
  questionResolvedChat: "My issue is resolved",
  countrySelectTrueChat: "Country is correct",
  salutationChat: "Hi!",
  categoryAppealChat: "To receive information, select your question category and click on it. Then type in your question in the message box and press Send.",
  langAppealChat: "Select your inquiry language. Inquiries in Russian or English take less time to process.",
  youCountryChatPath1: "Your country",
  youCountryChatPath2: "If not listed, specify country in reply message.",
  youCountryChat: "Your country {country}? If not listed, specify country in reply message.",
  notFoundCountryChat: "No such country found. Make sure your details are correct and resend.",
  additionalInfoChat: "In your reply, please provide supplementary information for your inquiry.",
  yourEmailChat: "Please include your email address. Your inquiry will be answered by email.",
  notValidEmailChat: "The email address you provided is not in aaa@bbb.ccc format. Make sure your details are correct and resend.",
  acceptRequestChat: "Your inquiry was forwarded to operator. The average processing time is 7 calendar days.",
  notFoundEmployeeChat: "The country you named is not on the list of countries eligible for study under Rossotrudnichestvo program. Your inquiry cannot be processed. Please name a different country. For the full list of study programs, please refer to <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
  langBtnChat_1: "English",
  langBtnChat_2: "Russian",
  langBtnChat_3: "Other",
  addAppBtn: "Apply",
  listAppBtn: "List of applications",
  privacyPolicy: "Confidentiality Policy",
  ___programs: "the program",
  ___educationalPrograms: "study programs",
  __educationalPrograms: "study program",
  editTopic: "Edit subject",
  editMessage: "Edit message",
  deleteMessage: "Delete message",
  blockingUser: "Block user",
  blockEndDate: "Blocking end date",
  blockEndTime: "Blocking end time",
  blockForever: "Block permanently",
  block: "Block",
  unlock: "Unblock",
  edited: "Edited",
  founder: "Founder",
  abbreviation: "Abbreviation",
  studentsByCountry: "Number of students by home country",
  studentsByCountryCount: "Number of students currently studying",
  contactsInternationalDepartment: "International Office contact details",
  site: "Website",
  address: "Address",
  format: "Format",
  typeEvent: "Event type",
  quotaLearning: "Quota study",
  kcp: "Admission Quotas (AQ)",
  countSeatsKCP: "No. of AQ placements",
  countSeatsCostTraining: "No. of placements with full tuition",
  distanceLearning: "Distance study",
  notificationAboutDistanceLearning: "The program may be delivered via distance study through the use of distance learning technology. Contact the school for details.",
  additionalTests: "Dates of additional screening tests",
  pageNotFound: "Page not found",
  goToMainPage: "Go to home page",
  faqInformation: "For best results, all questions relating to application verification status, list of required admission documents, screening test dates, or any other enrollment queries should be referred to the Rossotrudnichestvo office or the Russian Embassy authorized to administer screening in your country. You can find Rossotrudnichestvo contact info in your account.",
  allEvents: "All events",
  eventsAndNews: "Events and News",
  titleNews: "News headline",
  numberOfBudgetPlaces: "No. of subsidized placements",
  russianGovernmentQuota: "Budget (state admission limits)",
  legalAddress: "Registered address",
  actualAddress: "Physical location",
  goToTheRegister: "Go to supplementary programs list",
  searchForSuitablePrograms: "Search for suitable programs",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Study programs frequently chosen by prospective students",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Russian cities frequently chosen by prospective students",
  ofTheCountry: "from the country",
  passed: "Completed",
  question: "Question",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "The portal is in trial mode!",
  banner_2: "Dear Users, There may be technical difficulties with using Google mail addresses at this time. Please use other mail services when working with the portal.",
  banner_3: "Admission campaign starts December 24, 2022.",
  typeOfEducation: "Type of Education",
  chooseTheTypeOfEducation: "Select a type of education",
};
