import {api} from '@/_services';
import router from '@/router';

const userToken = localStorage.getItem('userToken');

const roles = {
  USER: '00', // 'Кандидат',
  RZU: '01', // 'РЗУ', 
  ROSS: '02', // 'Россотрудничество',
  EDUORG: '03', // 'Образовательная организация',
  MINOBR: '04', // 'МОН
  PARTNERS: '05', // 'Партнёр',
  REGADMIN: '06', // 'Областная администрация',
  INSURANCEORG: '07', // 'Страховая организация',
}

let initialState = userToken
  ? {
    userToken,
    isAuthorized: true,
  }
  : {
    userToken: null,
    isAuthorized: false,
  };

initialState = {
    ...initialState,
    roles,
    isLoad: false,
    user: {},
    userRole: null,
    permissions: {}, 
  }

const handleResponse = function(response) {
  if (!response.token) {
    if (response.status === 401 && userToken) {
      this.commit('users/logout', false);
    }

    return Promise.reject(response.data);
  }

  return response;
};

export default {
  namespaced: true,

  actions: {
    getFavoritesUniversities(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/profile/favorites/universities',
        params,
      });
    },

    getFavoritesPrograms(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/profile/favorites/programs',
        params,
      });
    },

    setFavoritesAdd(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/profile/favorites/add',
        data,
      });
    },

    unsetFavoritesDelete(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$deleteMethod(store, {
        url: '/ps/api/profile/favorites/delete',
        data,
      });
    },

    getMyChoice(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/profile/my-choice',
        params,
      });
    },

    getMyChoiceCheck(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/profile/my-choice/check',
        params,
      });
    },

    setMyChoiceAdd(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/profile/my-choice/add',
        data,
      });
    },

    setLogout(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/logout',
        data,
      });
    },

    unsetMyChoiceDelete(store, data = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$deleteMethod(store, {
        url: '/ps/api/profile/my-choice/delete',
        data,
      });
    },

    getPermissionsForumAction(store, params = {}) {
      return api.$getMethod(store, {
        url: '/zhps/api/roles/forum-functions',
        params,
        mutationType: 'setPermissions',
        headers: {
          APPCODE: process.env.VUE_APP_APPCODE,
        },
      })
    },
  },

  getters: {
    getUser: state => {
      return state.user || {};
    },

    isAuth: state => {
      return state.isAuthorized;
    },

    getuserRoles: state => {
      return state.userRoles;
    },

    getPermissionsForum: state => {
      const {data} = state.permissions
      return data ? (data.length !== undefined ? {} : data) : {}
    },

    getSetRoles: state => {
      return state.roles;
    },
  },

  mutations: {
    setAuth: (state, isAuthorized) => {
      return state.isAuthorized = isAuthorized;
    },

    login(state, {code, callback}) {
      state.status = {loggingIn: true};
      state.user = JSON.parse(localStorage.getItem('userToken'));

      localStorage.removeItem('userToken');
      state.isLoad = true;

      api.$postMethod({
          state: state,
        },

        {
          url: '/ps/api/login',

          data: {
            code: code,
            message: code,
          },
        },
      )
        .then(
          res => {
            if (res.token) {
              localStorage.setItem('userToken', res.token);
            }

            state.userToken = res.token;
            state.isLoad = false;
            state.firstLogin = true;
            state.isAuthorized = true;

            if (callback) callback();

            this.dispatch('getUser').then(() => {
              const org = state.user && state.user.org_id

              // Redirections after authorizations
              switch (org) {
                case roles.USER: 
                  window.location.replace('/settings/profile/applications/');
                  break;
                case roles.REGADMIN: 
                  window.location.replace('/admin/cities');
                  break;
                default: 
                  window.location.replace('/settings/');
                  break;
              }
            })
          },

          error => {
            state.userToken = null;
            state.user = {};
            this.commit('alert/error', error);
            state.isLoad = false;
            state.firstLogin = true;
          },
        )
    },

    loginOld(state, {email, password, redirectTo, callback}) {
      state.status = {loggingIn: true};
      state.user = JSON.parse(localStorage.getItem('userToken'));

      localStorage.removeItem('userToken');
      state.isLoad = true;

      api.$postMethod({
          state: state,
        },

        {
          url: '/ps/api/old-login',

          data: {
            email: email,
            password: password,
          },
        },
      )

        .then(handleResponse.bind(this))
        .then(
          res => {
            if (res.token) {
              localStorage.setItem('userToken', res.token);
            }

            state.userToken = res.token;
            state.isLoad = false;
            state.firstLogin = true;
            state.isAuthorized = true;

            if (callback) callback();

            this.dispatch('getUser').then(user => {
              if (user.roles[0].name === 'moderator') {
                router.push('/help/forum');
              } else {
                router.push(redirectTo || '/settings');
              }
            })
          },

          error => {
            state.userToken = null;
            state.user = {};
            this.commit('alert/error', error);
            state.isLoad = false;
            state.firstLogin = true;
          },
        )
    },

    setUser(state, payload) {
      state.user = payload;
      state.user.molilePhone = payload.personal_data && payload.personal_data.mobile_phone;
      state.userRoles = (payload || {}).roles || null;
      state.isAuthorized = !!payload;
      this.dispatch('users/getPermissionsForumAction')
      this.dispatch('getPermissionsAdminAction')
    },

    setPermissions(state, payload) {
      state.permissions = payload
    },

    logout(_, reload = true) {
      localStorage.removeItem('userToken');

      this.dispatch('users/setLogout').then(() => {
        if (reload) {
          window.location.replace('/');
        }
      })
    },
  },

  state: {
    ...initialState,
  },
};
