<template>
  <div :class="{[modalClass]: modalClass}" class="modal" ref="modalTemplate" v-if="isModalShown(uniqueId)">
    <div class="modal-content">
      <div :style="{width: `${width}px`}" class="modal-container">
        <div class="modal-header-row" v-if="$slots.header">
          <div class="modal-header">
            <slot name="header" />
          </div>
        </div>

        <div class="modal-body">
          <slot name="body" />
        </div>

        <div class="modal-footer-row" v-if="$slots.footer">
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>

        <a
          @click="targetModal"
          class="close"
          href="javascript:void(0)"
        >
          <img src="@/assets/images/close-black.png" alt="" />
        </a>
      </div>
    </div>

    <div
      @click="targetModal"
      class="full-empty-block"
      v-if="isModalShown(uniqueId)"
    />
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        modals: 'modal/modals',
        isModalShown: 'modal/isModalShown',
      }),
    },

    name: 'Modal',

    props: {
      showModal        : Boolean,
      uniqueId         : String,
      width            : String,
      callbackClose    : Function,
      targetModalBefore: Function,
      modalClass       : String,
    },

    data() {
      return {};
    },

    mounted() {
      this.$store.commit('modal/initModal', this.uniqueId);
    },

    beforeDestroy() {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    },

    methods: {
      targetModal() {
        if (this.targetModalBefore) {
          const r = this.targetModalBefore();

          if (!r) {
            return r;
          }
        }

        this.$store.commit('modal/updateShow', {key: this.uniqueId, value: !this.isModalShown(this.uniqueId)});

        if (this.callbackClose) {
          this.callbackClose();
        }
      },
    },
    watch: {
      uniqueId() {
        this.$store.commit('modal/initModal', this.uniqueId);
      },
    },
  };
</script>
