import {api} from '@/_services';
import _ from 'lodash';

export default {
  namespaced: true,

  actions: {

    // universities
    getUniversitiesCountByFilter(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/universities/count-by-filter',
        params,
      });
    },

    getUniversities(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: '/ps/api/universities',
        params,
      });
    },

    getUniversity(store, slug, params = {}) {
      store.state.curLanguage = this.getters.getLocale;
      return api.$getMethod(store, {
        url: `/ps/api/universities/${slug}`,
        params,
      });
    },

    universityPrograms(store, params = {}) {
      let slug = params.slug;
      store.state.curLanguage = this.getters.getLocale;
      delete (params.slug)

      return api.$getMethod(store, {
        url: `/ps/api/universities/programs/${slug}`,
        params,
      });
    },

    getSemestersDurationAdditional(store) {
      store.state.curLanguage = this.getters.getLocale;
      return [
        {value: '0-0,99', name: 'lessThan1Month'},
        {value: '1-2,99', name: '1To3Months'},
        {value: '3-5,99', name: '3To6Months'},
        {value: '6-11,99', name: '6To12Months'},
        {value: '12+', name: 'moreThan1Year'},
      ]

      // return api.$getMethod(store, {
      //   url: '/ps/api/semesters-duration',
      //   params,
      // });
    },

    getUniversitiesGetCities(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/universities/get-cities',
        params,
      });
    },

    getHobby(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/hobby',
        params,
      });
    },

    // programs
    getPrograms(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/programs',
        params,
      });
    },

    getProgram(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/programs/${params.idProgram}`,
        params,
      });
    },

    getProgramsCountByFilter(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/programs/count-by-filter',
        params,
      });
    },

    // cities
    getCities(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/cities',
        params,
      });
    },

    getCity(store, slug, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/cities/${slug}`,
        params,
      });
    },

    getOnlineMonitoring(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/online-monitoring/',
        params,
      });
    },

    getOnlineMonitoringYears(store, slug, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/online-monitoring/years',
        params,
      });
    },

    getOnlineMonitoringCountries(store, slug, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/online-monitoring/countries',
        params,
      });
    },

    getEducationPrograms(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/my-career/education-programs',
        params,
      });
    },

    getTutors(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/tutors',
        params,
      });
    },

    getTutorsExperiences(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/tutors/experiences',
        params,
      });
    },

    getTutorsLanguages(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/tutors/languages',
        params,
      });
    },

    getMyCareersDownload(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/my-career/download',
        params,
      });
    },

    setTutorsLanguages(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/tutors/languages',
        params,
      });
    },

    getSports(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/sports',
        params,
      });
    },

    getTesting(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/testing',
        params,
      });
    },

    getTestingById(store, id) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/testing/${id}`,
        params: {},
      });
    },

    setTestingCheckTestResult(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/testing/checktestresult',
        data,
      });
    },

    getTestingDownload(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/testing/download',
        data,
      });
    },

    setTutorsFeedback(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/tutors/feedback',
        data,
      });
    },

    setSendMessage(store, data) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$postMethod(store, {
        url: '/ps/api/tutors/send-message',
        data,
      });
    },
  },

  getters: {
    getParams: state => {
      return state.params;
    },

    getUniversitiesMainList: state => {
      return state.params.universities.main;
    },

    getUniversitiesAdditionalList: state => {
      return state.params.universities.additional;
    },

    getProgramsMainList: state => {
      return state.params.programs.main;
    },

    getProgramsAdditionalList: state => {
      return state.params.programs.additional;
    },

    getTutorsMainList: state => {
      return state.params.tutors;
    },
  },

  mutations: {
    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }

      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params);

      // this.$store.commit('preloader/isLoading', {model: 'preloader', value: true})
    },
  },

  state: {
    params: {
      universities: {
        main: {
          total: 0,
          data: [],
        },

        additional: {
          total: 0,
          data: [],
        },
      },

      programs: {
        main: {
          total: 0,
          data: [],
        },

        additional: {
          total: 0,
          data: [],
        },
      },

      monitoring: {
        total: 0,
        data: [],
      },

      tutors: {
        total: 0,
        data: [],
      },

      careerTests: {
        step: 'questionnaire',
      },
    },
  },
};
