<template>
  <div
    :class="{['input-container-provider form-row' + elementClassParent]: elementClassParent}"
    class="form-row"
  >
    <validation-provider :name="storePath" :rules="rules" v-slot="{errors}">
      <label :class="{[labelClass]: labelClass, radio: !labelClass}" :for="elementId || storePath">
        <input
          :autocomplete="autocomplete"
          :class="{[elementClass]: elementClass}"
          :disabled="disabled"
          :id="elementId || storePath"
          :name="storePath"
          :placeholder="placeholder"
          type="radio"
          @click="setClick"
          ref="radio"
          v-model="model"
          :value="value"
          @focus="el => autocomplete !== 'on' && el.target.setAttribute('autocomplete', 'nope')"
          :selected="model"
        />

        <slot name="body" />

        <div class="hint-error" v-if="errors.length">
          {{ errors[0] }}
        </div>
      </label>
    </validation-provider>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        forms: 'form/forms',
      }),
    },

    name: 'input-radio',

    props: {
      autocomplete: String,
      disabled: {type: Boolean, default: () => false},
      label: String,
      value: String,
      elementClass: String,
      placeholder: String,
      storePath: String,
      elementId: String,
      elementClassParent: String,
      rules: {type: String, default: () => ''},
      error: String,
      labelClass: String,
      parenClass: String,
      allowReset: Boolean,
    },

    data() {
      return {
        model: null,
      };
    },

    mounted() {
      if (this.model !== _.get(this.forms, this.storePath)) {
        this.model = _.get(this.forms, this.storePath);
      }
    },

    methods: {
      setClick(el) {
        if (el && el.target.value) {
          if (_.get(this.forms, this.storePath) === this.value) {
            this.$store.commit('form/updateField', {model: this.storePath, value: null});
            this.model = null;
          } else {
            this.$store.commit('form/updateField', {model: this.storePath, value: el.target.value});
          }
        }
      },
    },
  };
</script>
