<template>
  <div class="home-page">
    <div class="base-container">
      <div class="User-guide container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
               { title: $t('help'), url: '/help'},
              {title: $t('userGuide') },
            ]"
        />
        <h1 class="title-1 mb16">{{ $t('userGuide') }}</h1>
        <div class="instruction">
          <router-link :to="PDF" target="_blank">
            <img src="@/assets/images/icon/ic-pdf-file.svg" />
            <p class="instruction-name">{{ $t('instructionForWork') }}</p>
          </router-link>
        </div>

        <div class="instruction">
          <router-link :to="PROCTORING" target="_blank">
            <img src="@/assets/images/icon/ic-pdf-file.svg" />
            <p class="instruction-name">{{ $t('instructionForProctoring') }}</p>
          </router-link>
        </div>

        <h3 
          class="title-3 mb32"
          v-if="this.getLocale === 'ru'"
        >{{ $t('textRegisterGuide') }}</h3>
        <iframe src='https://www.youtube.com/embed/lNSGqt4GLIU'
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          width='95%'
          height='400px'
          class="mb64"
          v-if="this.getLocale === 'ru'"
        />
      <h3 class="title-3 mb16">{{ $t('textApplication') }}</h3>
        <iframe src='https://www.youtube.com/embed/DmIsLonm6CM'
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          width='95%'
          height='400px'
          class="mb64"
          
        />

        <h3 class="title-3 mb16">{{ $t('textTrainingGuide') }}</h3>
        <iframe src='https://www.youtube.com/embed/UHVyuV30wGs'
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          width='95%'
          height='400px'
          class="mb64"
          v-if="this.getLocale === 'ru'"
        />
        <iframe src='https://www.youtube.com/embed/n60f6vwiJtg'
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          width='95%'
          height='400px'
          class="mb64"
          v-if="this.getLocale !== 'ru'"
        />
        <h3 class="title-3 mb32">{{ $t('instructionForProctoring') }}</h3>
        <iframe src='https://www.youtube.com/embed/k6OL2nGAUog'
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          width='95%'
          height='400px'
          class="mb64"
        />
      </div>
    </div>
  </div>
</template>

<script >
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import {mapGetters} from 'vuex';

  export default {
    name: 'user-guide',
    
    computed: {
      ...mapGetters({
        getNotifications: 'chatBot/getNotifications',
        getLocale: 'getLocale', 
        isAuth: 'users/isAuth',
      }),
    },

    components: {
      Breadcrumbs,
    },

    data: () => ({
      PDF: '',
      PROCTORING: '',
    }),
    mounted() {
      this.PDF = require(`@/assets/files/guide-user(${this.getLocale}).pdf`);
      this.PROCTORING = require('@/assets/files/proctoring.pdf');
    },
  }
</script>
