export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Ayuda",
  personalArea: "Cuenta personal",
  myApplications: "Mis solicitudes",
  servicesAndFacilities: "Servicios",
  logout: "Salir",
  favorites: "Favoritos",
  theChoice: "Elección",
  notifications: "Notificaciones",
  logIn: "Iniciar sesión",
  quickAccessThroughSocialNetworks:
    "Inicio de sesión rápido a través de las redes sociales",
  enterYourEmail: "Ingresa tu dirección de correo electrónico",
  password: "Contraseña",
  enterPassword: "Ingresa tu contraseña",
  rememberMe: "Recordarme",
  forgotYourPassword: "¿Has olvidado tu contraseña?",
  noAccount: "¿No tienes una cuenta?",
  registration: "Registrarse",
  countryOfCitizenship: "Ciudadanía",
  chooseYourCountryOfCitizenship: "Selecciona tu país de ciudadanía",
  iHaveReadAndAgree: "He leído y doy",
  _toTheProcessingOfMyPersonalData:
    "mi consentimiento para el procesamiento de mis datos personales",
  iAgreeTo: "Acepto",
  _theUseOfCookies: "el uso de cookies",
  signUp: "Registrarse",
  haveAnAccount: "¿Tienes una cuenta?",
  mainPage: "Página principal",
  frequentlyAskedQuestionsAnswers: "Respuestas a las preguntas más frecuentes",
  goTo: "Ir",
  startTesting: "Comenzar la prueba",
  buildCareerVector: "Construir un vector de carrera profesional",
  forum: "Foro",
  textForumBanner:
    "Discute en el foro general todos los temas que te interesen: cómo es la vida en las ciudades rusas, cómo es la enseñanza en las universidades rusas, etc.",
  feedback: "Retroalimentación",
  technicalSupportFeedbackForm:
    "Forma de retroalimentación con soporte técnico",
  allCities: "Todas las ciudades",
  citiesOfRussia: "Ciudades de Rusia",
  educationInRussia: "Educación en Rusia",
  educationalOrganizations: "Universidades",
  educationInRussiaYourKeyToSuccess:
    "Educación en Rusia: su clave para el éxito",
  applyForStudyInRussia: "Deja tu solicitud para estudiar en Rusia",
  apply: "Dejar solicitud",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Inicia sesión o regístrate para obtener acceso a orientación profesional y para enviar tu solicitud",
  aboutOrganization: "Acerca de la organización",
  eventsAndOlympiads: "Eventos y olimpiadas",
  educationalPrograms: "Programas",
  contacts: "Contactos",
  inFavorites: "En Favoritos",
  toFavorites: "A favoritos",
  fromChoices: "Seleccionado",
  choices: "Seleccionar",
  generalInformation: "Información general",
  internationalRatings: "Calificaciones internacionales",
  nationalRatings: "Calificaciones nacionales",
  ratings: "Calificaciones",
  year: "Año",
  ratingName: "Nombre de la calificación",
  aPlace: "Lugar",
  screeningTests: "Pruebas de selección",
  choiceOfProfession: "Elección de la profesión",
  lifeInRussia: "La vida en Rusia",
  russianLanguage: "Idioma ruso",
  livingConditions: "Condiciones de vida",
  cultureAndLeisure: "Cultura y ocio",
  safety: "Seguridad",
  benefitsForInternationalStudents: "Beneficios para estudiantes extranjeros",
  workForInternationalStudents: "Trabajo para estudiantes extranjeros",
  studentVisaAndRegistration: "Visa de estudiante y registro",
  healthInsurance: "Seguro de salud",
  howToEnter: "¿Cómo ingresar en la universidad?",
  educationLevels: "Niveles de educación",
  admissionProcess: "Proceso de admisión",
  olympics: "Olimpiadas",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Sitio web oficial para la selección de ciudadanos extranjeros para cursar estudios en Rusia, año {year}",
  whyRussia: "¿Por qué Rusia?",
  "10reasonsToStudyInRussia": "10 razones para estudiar en Rusia",
  reviewsOfForeignStudents: "Reseñas de estudiantes extranjeros",
  onlineMonitoring: "Seguimiento online",
  mainPrograms: "Programas básicos",
  additionalPrograms: "Programas complementarios",
  chooseCounty: "Selecciona la región",
  county: "Región",
  chooseACity: "Seleccione la ciudad",
  city: "Ciudad",
  chooseYourLevelOfEducation: "Selecciona el nivel educativo",
  chooseYourAreaOfScientificKnowledge: "Elige un área del conocimiento científico",
  chooseYourScientificSpecialtyProfile: "Elige un perfil de una especialidad científica",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Nivel educativo",
  chooseAGroupOfTrainingAreas: "Selecciona un grupo de áreas de formación",
  trainingDirectionGroup: "Grupo de áreas de formación",
  chooseATrainingDirection: "Seleccione el área de formación",
  trainingDirection: "Área de formación",
  chooseRatings: "Seleccionar calificaciones",
  thePresenceOfThePreparatoryFaculty: "Existencia de facultad preparatoria",
  show: "Mostrar",
  _university: "universidad",
  _universities: "universidades",
  __universities: "universidades",
  clearAllFilters: "Borrar todos los filtros",
  chooseSemester: "Seleccionar semestre",
  semester: "Semestre",
  enterTheNameOfTheEducationalOrganization:
    "Ingrese el nombre de la universidad",
  _educationalOrganization: "universidad",
  _educationalOrganizations: "universidades",
  __educationalOrganizations: "universidades",
  chooseYourAreaOfEducation: "Seleccione un área de educación",
  areaOfEducation: "Área de educación",
  chooseYourLanguageOfInstruction: "Seleccione el idioma de enseñanza",
  languageOfInstruction: "Idioma de enseñanza",
  _program: "programa",
  _programs: "programas",
  __programs: "programas",
  formOfTraining: "Forma de estudios",
  level: "Nivel",
  typeOfTraining: "Tipo de estudios",
  free: "Gratuito",
  paid: "De pago",
  tuitionFees: "Costo de la educación pagada",
  established: "Fecha de fundación",
  numberOfStudents: "Cantidad de estudiantes",
  _students: "estudiantes",
  numberOfQuotaPlaces: "Cantidad de cupos",
  _for: "para",
  numberOfInternationalStudents: "Estudiantes extranjeros",
  _educationalPrograms: "programas educativos",
  moreDetails: "Más información",
  sTEP: "PASO",
  afterTrainingForThisProgramYouCanBecome:
    "Después de completar este programa educativo, puedes convertirte en",
  chooseProgram: "Seleccionar programa",
  chooseUniversity: "Seleccionar universidad",
  print: "Imprimir",
  save: "Guardar",
  programs: "Programas",
  selectedProgram: "Programa seleccionado",
  noProgramSelected: "No has seleccionado el programa",
  selectedEducationalOrganizations: "Universidades seleccionadas",
  educationalOrganizationNotSelected: "No has seleccionado la universidad",
  representation: "Representación",
  programDescription: "Descripción del programa",
  programPageOnTheUniversityWebsite:
    "Página del programa en el sitio web de la universidad",
  programCurator: "Mentor del programa",
  disciplines: "Asignaturas",
  theProcessOfEnteringARussianEducationalOrganization:
    "Proceso de admisión a una universidad rusa",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Elige un programa y hasta 6 universidades",
  takeTheCompetitiveSelectionInYourCountry:
    "Pasa el concurso de selección en tu país",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Rellena la solicitud en línea y adjunta el paquete de documentos",
  getAnInvitationToStudAnStudyVisa:
    "Recibe la invitación para estudiar y la visa de estudios",
  preparatoryFaculties: "Facultades preparatorias",
  summerAndWinterSchools: "Facultades preparatorias",
  russianLanguageCourses: "Cursos de idioma ruso",
  tutorsTheRussianLanguage: "Profesores de idioma ruso",
  certificationInRussian: "Certificación en idioma ruso",
  news: "Noticias",
  allNews: "Todas las noticias",
  reviewsOfStudentsAndGraduates: "Opiniones de estudiantes y graduados",
  areYouSureYouWantToWithdrawTheApplication:
    "¿Seguro que deseas retirar tu solicitud?",
  withdrawApplication: "Retirar la solicitud",
  jan: "Enero",
  feb: "Febrero",
  mar: "Marzo",
  apr: "Abril",
  may: "Mayo",
  jun: "Junio",
  jul: "Julio",
  aug: "Agosto",
  sep: "Septiembre",
  act: "Octubre",
  nov: "Noviembre",
  dec: "Diciembre",
  cityFeatures: "Características de la ciudad",
  enterTheNameOfTheCity: "Ingresa el nombre de la ciudad",
  organizations: "Organización",
  livingWage: "Coste mínimo de vida",
  "one-roomApartmentForRent": "Alquiler de un apartamento de 1 habitación",
  _month: "Mes",
  averageTemperature: "Temperatura media",
  _inWinter: "en invierno",
  _inSummer: "en verano",
  averageCostOfDormitories: "Costo medio de los albergues estudiantiles",
  photos: "Fotografías",
  interestingPlaces: "Lugares de interés",
  readCompletely: "Leer todo",
  _review: "opinión",
  _reviews: "opiniones",
  __reviews: "opiniones",
  university: "Universidad",
  universities: "Universidades",
  country: "País",
  selectACountry: "Selecciona el país",
  reviewOfAForeignStudent: "Opinión de un estudiante extranjero",
  careerGuidance: "Orientación vocacional",
  determineYourAreaOfEducationAndProfession:
    "Determina las áreas de estudio y la profesión más adecuadas para ti",
  myCareer: "Mi carrera",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "¿Cómo combinar los estudios con la recreación cultural y los nuevos amigos?. Características de los programas intensivos y su costo",
  toApply: "Aplicar",
  chooseYear: "Seleccione el año",
  _in: "en",
  universitiesInThisCity: "Universidades en esta ciudad",
  programsInThisCity: "Programas en esta ciudad",
  all: "Todo",
  _inYourCountry: "en tu país",
  _common: "general",
  allReviews: "Todas las opiniones",
  calendar: "Calendario",
  activity: "Eventos",
  addToPersonalCalendar: "Añadir al calendario personal",
  eventAdded: "Evento añadido",
  eventSuccessfullyAddedToYourCalendar:
    "¡El evento se ha añadido a tu calendario!",
  population: "Población",
  onTheMap: "En el mapa",
  generalEducation: "Educación general",
  professionalEducation: "Educación profesional",
  preschoolEducation: "Educación preescolar",
  kindergarten: "Guardería infantil",
  nursery: "Cuna",
  earlyDevelopmentCenters: "Centros para el desarrollo temprano",
  primaryAndSecondaryEducation: "Educación primaria, básica y secundaria",
  schools: "Escuelas",
  lyceums: "Liceos",
  gymnasium: "Gimnasios",
  secondaryVocationalEducation: "Educación vocacional secundaria",
  colleges: "Colegios",
  technicalSchools: "Escuelas técnicas",
  secondarySchool: "Academias",
  higherEducation: "Educación superior",
  baccalaureate: "Bachillerato",
  specialistDegree: "Especialidad",
  magistracy: "Maestría",
  postgraduateStudies: "Postgrado",
  preparatoryFaculty: "Facultad preparatoria",
  summerSchools: "Escuelas de verano",
  additionalGeneralAndPreVocationalPrograms:
    "Programas adicionales de desarrollo general y preprofesional",
  continuingEducationAndRetrainingPrograms:
    "Programas de capacitación y recapacitación profesional",
  whatAreTheLevelsOfRussianEducation:
    "¿De qué niveles consta la educación rusa?",
  enterYourName: "Ingresa tu nombre",
  name: "Nombre",
  selectTheSubjectOfTheMessage: "Selecciona el tema de tu mensaje",
  messageSubject: "Tema del mensaje",
  chooseAMessage: "Selecciona el mensaje",
  message: "Mensaje",
  submit: "Enviar",
  frequentlyAskedQuestions: "Preguntas más frecuentes",
  didNotFindTheAnswerToYourQuestion:
    "No hemos encontrado respuesta a tu pregunta",
  contactSupportAt:
    "Contacta con el servicio de atención al cliente en la direccivn:",
  searchInTheSection: "Búsqueda en la sección",
  sort: "Clasificar",
  searchingResults: "Resultados de la búsqueda",
  _answers: "respuestas",
  _views: "visitas",
  byCreationDate: "Por fecha de creación",
  byTheNumberOfResponses: "Por cantidad de respuestas",
  byViews: "Por cantidad de visitas",
  toAnswer: "Contestar",
  toQuote: "Citar",
  complain: "Quejarse",
  addNewMessage: "Añadir un nuevo mensaje",
  selectComplaintType: "Selecciona el tipo de queja",
  typeOfComplaint: "Tipo de queja",
  enterComplaintText: "Escribe el texto de la queja",
  complaintText: "Texto de la queja",
  complaintSentSuccessfully: "Queja enviada correctamente",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Tu queja ha sido enviada, analizaremos tu queja lo antes posible",
  close: "Cerrar",
  myMessages: "Mis mensajes",
  myTopicsAndPosts: "Mis temas y mensajes",
  searchInMyPosts: "Buscar en mis mensajes",
  _posts: "mensajes",
  feedbackOnEducationalOrganizations: "Opiniones sobre las universidades",
  posted: "Publicado",
  goToMessage: "Ir al mensaje",
  _themes: "temas",
  themeTitle: "Nombre del tema",
  enterAThemeName: "Escribe el nombre del tema",
  cancel: "Cancelar",
  banUser: "¿Bloquear al usuario?",
  areYouSure: "¿Estás seguro?",
  toBan: "Bloquear",
  _logIn: "autenticarse",
  _or: "o",
  _signUp: "registrarse",
  eventCalendar: "Calendario de eventos",
  trainingApplicationsFiled: "Solicitudes de educación presentadas",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    " Las universidades más populares elegidas por los solicitantes.",
  _of: "de",
  _whenApplying: "al presentar la solicitud",
  tOP10EducationalOrganizations: "Las 10 universidades más destacadas",
  collapse: "Minimizar",
  showAll: "Mostrar todo",
  conditionsForForeigners: "Condiciones para los extranjeros",
  supportForInternationalStudents: "Apoyo a los estudiantes extranjeros",
  lifeOfForeignStudents: "La vida de los estudiantes extranjeros",
  leisureAndSportingEvents: "Actividades de ocio y deportivas",
  dormitories: "Albergues estudiantiles",
  dormitoryCost: "Costo del albergue estudiantil",
  theNumberOfPeopleInTheRoom: "Cantidad de personas en la habitación",
  from: "Desde",
  _rubles: "rublos",
  _to: "hasta",
  _perMonth: "al mes",
  "2-3People": "Entre 2 y 3 personas",
  theInternationalCooperation: "Cooperación internacional",
  photoAndVideo: "Fotos y videos",
  eventTitle: "Nombre de la actividad",
  location: "Lugar de realización",
  date: "Fecha",
  enterYourRealMail: "Indica tu dirección de correo real",
  requiredField: "Campo obligatorio",
  minimumLengthCharacters: "Longitud mínima{length} de caracteres",
  maximumLengthCharacters: "Longitud máxima {length}de caracteres",
  theFieldMustBeANumber: "El campo debe ser un número",
  onlyLettersAreAllowedInTheField: "El campo solo acepta letras",
  checkKeyboardLayout: "Comprueba el idioma de tu teclado",
  checkTheNumberYouEnteredIsCorrect:
    "Comprueba si el número que ingresaste es correcto",
  olympiadName: "Nombre de la olimpiada",
  description: "Descripción",
  contactInformation: "Información de contacto",
  majorForeignPartnerUniversities:
    "Principales universidades extranjeras asociadas",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Posibilidades para el aprendizaje del idioma ruso a tiempo completo y a distancia en Rusia y en todo el mundo",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "¿Eres profesor de idiomas? Envía tu formulario para publicar tu perfil en el sitio web",
  submitApplication: "Enviar formulario",
  chooseATeachingExperience: "Selecciona tu experiencia en enseñanza",
  teachingExperience: "Experiencia en enseñanza",
  chooseLanguages: "Seleccionar idiomas",
  knowledgeOfLanguages: "Conocimiento de idiomas",
  workExperience: "Experiencia laboral",
  trainingLevel: "Nivel de preparación",
  durationOfOneLesson: "Duración de una clase",
  possibleTrainingTime: "Posible horario para las clases",
  theCosOfOneLesson: "Precio de una clase",
  writeAMessage: "Escribir un mensaje",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Puedes escribir al profesor de idiomas después de registrarse o autenticarse en el sitio.",
  educationDocuments: "Documentos de educación",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Ya has seleccionado otro programa, si seleccionas este programa, el programa y las instituciones seleccionados anteriormente se perderán.",
  limitIsExceeded: "Límite superado",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Puedes elegir no más de 6 universidades.",
  _fieldsOfEducation: "áreas de educación",
  _trainingAreas: "áreas de formación",
  _cities: "ciudades",
  _languagesOfInstruction: "Idiomas de enseñanza",
  _typesOfAdditionalPrograms: "tipos de programas complementarios",
  _semesters: "semestres",
  chooseDurationOfStudy: "Selecciona la duración de los estudios",
  _durationOfStudy: "duración de los estudios",
  enterTheNameOfTheEducationalProgram: "Ingresa el nombre del programa",
  instruction: "Instrucciones",
  profile: "Formulario",
  back: "Volver",
  next: "Continuar",
  testReport: "Informe de los resultados de la prueba",
  testDate: "Fecha de la prueba",
  duration: "Duración",
  memberName: "Nombre del participante",
  age: "Edad",
  gender: "Sexo",
  currentLevelOfEducation: "Nivel de educación actual",
  areasOfInterestHobbies: "Áreas de interés, aficiones",
  sportsAchievements: "Logros deportivos",
  testScaleResults: "Resultados según la escala de la prueba",
  scaleDescription: "Descripción de los resultados por escalas",
  recommendedEducationAreas: "Áreas de educación recomendadas",
  enterYourAge: "Indica tu edad",
  enterYourGender: "Indica tu sexo",
  chooseYourCurrentLevelOfEducation: "Selecciona tu nivel de educación actual",
  chooseYourAreasOfInterestHobbies: "Elige tus áreas de interés, aficiones",
  chooseYourSportingAchievements: "Selecciona tus logros deportivos",
  legalizationOfDocuments: "Legalización de documentos",
  consularLegalization: "Legalización consular",
  edit: "Editar",
  mobilePhoneNumber: "Número de teléfono móvil",
  iWantToReceiveNotificationsByEmail:
    "Quiero recibir notificaciones por correo electrónico",
  authorizationCheck: "Verificación de la autenticación",
  createANewApplication: "Crear una nueva solicitud",
  selectionStage: "etapa de selección",
  underRevision: "En revisión",
  fillingOutAnApplication: "Llenado de la solicitud",
  verificationAndRevision: "Verificación y revisión",
  dossierFormation: "Formación del expediente",
  fillingInTheQuestionnaire: "Llenado del formulario",
  attachingDocuments: "Adjuntar documentos",
  sendingApplicationForVerification:
    "Envío de la solicitud para su verificación",
  personalData: "Datos personales",
  surnameInLatinLettersAccordingToPassport:
    "Apellido, en letras latinas (como en el pasaporte)",
  nameInLatinLettersAccordingToPassport:
    "Nombre (s), en letras latinas (como en el pasaporte)",
  middleNameIAnyCyrillicInRussianTranscription:
    "Patronímico (si lo hay), en letras cirílicas en la transcripción rusa",
  placeOfBirthAccordingToPassport: "Lugar de nacimiento (como en el pasaporte)",
  dateOfBirth: "Fecha de nacimiento",
  compatriotStatus: "Estatus de ciudadano ruso",
  citizenshipPlaceOfPermanentResidence:
    "Ciudadanía (lugar de residencia permanente)",
  online: "En línea",
  profile2: "Perfil",
  goToTheApplicationForm: "Ir al llenado de la solicitud",
  areYouHere: "Estás aquí",
  russianTextDes1:
    "¿Para qué son necesarias las facultades preparatorias, qué se estudia en ellas, cuánto dura y cuánto cuesta la enseñanza allí?",
  russianTextDes4:
    "¿Dónde y cómo encontrar un profesor para clases individuales de ruso?",
  russianTextDes5:
    "¿Cómo y para qué confirmar tu nivel de ruso para la admisión a una universidad rusa?. Conoce todo sobre cómo pasar la prueba de certificación",
  typeOfProgram: "Tipo de programa",
  chooseTheTypeOfAdditionalProgram: "Selecciona el tipo de programa",
  durationOfStudy: "Duración de los estudios",
  common: "General",
  inYourCountry: "En tu país",
  courseName: "Nombre del curso",
  courseDuration: "Duración del curso",
  classDuration: "Duración de las clases",
  courseCost: "Precio del curso",
  courseReviews: "Opiniones sobre el curso",
  male: "Masculino",
  female: "Femenino",
  feedbackLink: "Enlace a las opiniones",
  russianLanguageCoursesInYourCountry: "Cursos de idioma ruso en tu país",
  evaluation: "Calificación",
  giveFeedback: "Escribir una opinion",
  newFeedback: "Nueva opinion",
  _hour: "hora",
  _hours: "horas",
  __hours: "horas",
  _minute: "minuto",
  _minutes: "minutos",
  __minutes: "minutos",
  create: "Crear",
  feedbackSuccess:
    "Tu opinión se ha enviado correctamente y está pendiente de moderación.",
  _y: "año",
  _from: "c",
  error: "Error",
  direction: "Orientación",
  _tutor: "profesor",
  _tutors: "profesores",
  __tutors: "profesores",
  filters: "Filtros",
  sendMessage: "Enviar mensaje",
  sendMessageSuccess: "Mensaje enviado correctamente",
  _year: "año",
  _years: "años",
  __years: "años",
  _back: "volver",
  _next: "seguir",
  showing: "Mostrar",
  textRussianLanguageCourses:
    "Selecciona tu país de residencia en el menú y fíjate en los cursos y escuelas de idiomas disponibles. Obtener un nivel básico de ruso te facilitará enormemente la adaptación durante tu estancia.",
  tOP10Programs: "Los 10 programas más destacados",
  tOP10City: "Las 10 ciudades rusas más destacadas",
  createANewTopic: "Crear un nuevo tema",
  topics: "Temas",
  posts: "Mensajes",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "rchivos, en formato .pdf, .jpg, .png, de hasta 10 MB",
  noFileSelected: "Archivo no seleccionado",
  attachFile: "Adjuntar archivo",
  eMail: "E-mail",
  fullTime: "Presencial",
  toFind: "Encontrar",
  searchInTopic: "Buscar en el tema",
  lessThan1Month: "Menos de 1 mes",
  "1To3Months": "De 1 a 3 meses",
  "3To6Months": "De 3 a 6 meses",
  "6To12Months": "De 6 a 12 meses",
  moreThan1Year: "Más de 1 año",
  theDateOfThe: "Fecha de realización",
  toAdminPanel: "Ir al panel de administración",
  support: "Soporte técnico",
faq: "FAQ",
  userGuide: "Manual del usuario",
  textUserGuide: "Instrucciones de uso del portal y presentación de la solicitud de estudios",
  textRegisterGuide: "Inscripción en el sitio web Educación en Rusia para extranjeros",
  textTrainingGuide: "Solicitud de estudios en el sitio web Educación en Rusia para extranjeros",
  textApplication: "Completar el cuestionario",
  instructionForWork: "Instrucciones sobre cómo rellenar la solicitud",
  appeals: "Apelaciones",
  handling: "Consultas",
  __students: "del estudiante",
  _student: "estudiante",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Identifica tu grupo de perfil de formación y averigua qué opciones tienes para tu futura profesión",
  delTheme: "Eliminar tema",
  competitionForTheFirstPlace: "Concurso para 1 plaza dentro de los cupos de la Federación de Rusia (personas)",
  ruble: "rublo",
  __rubles: "rublos",
  dateOfEvent: "Fecha del evento",
  durationUnit: "mes",
  numberPhone: "Número de teléfono",
  offline: "Fuera de línea",
  to_begin: "Ir al inicio",
  theDateOfThe: "Fecha de inicio",
  chat: "Bot conversacional",
  yourMessageChat: "Tu mensaje",
  searchChat: "Buscar por diálogo",
  firstMsgChat: "Escribe el texto del mensaje",
  categoryBtnChat_1: "Pregunta sobre la presentación de solicitudes",
  categoryBtnChat_2: "Pregunta sobre los estudios",
  categoryBtnChat_3: "Pregunta sobre la selección por concurso",
  categoryBtnChat_4: "Sugerencias o comentarios",
  categoryBtnChat_5: "Comentarios sobre las actividades de la universidad",
  categoryBtnChat_6: "Quejas sobre la calidad de los servicios",
  categoryBtnChat_7: "Consultas al soporte técnico",
  notQuestionResolvedChat: "Mi problema no se ha resuelto",
  questionResolvedChat: "Mi problema se ha resuelto",
  countrySelectTrueChat: "País indicado correctamente",
  salutationChat: "¡Hola!",
  categoryAppealChat: 'Para obtener información, selecciona la categoría de tu pregunta pulsando el botón. Una vez seleccionada la categoría, escribe la pregunta en el campo de mensaje y pulsa el botón "Enviar".',
  langAppealChat: "Selecciona el idioma de tu consulta. Las consultas enviadas enen ruso e inglés se procesan más rápido.",
  youCountryChatPath1: "Tu país",
  youCountryChatPath2: "Si no es correcto, indica el nombre del país en el mensaje de respuesta.",
  youCountryChat: "¿Es tu país {country}? Si no es correcto, indica el nombre del país en el mensaje de respuesta.",
  notFoundCountryChat: "No se ha encontrado ningún país con el nombre especificado. Comprueba que los datos introducidos son correctos y vuelve a enviar el mensaje.",
  additionalInfoChat: "Proporciona información adicional sobre tu consulta en tu mensaje de respuesta.",
  yourEmailChat: "Indica tu dirección de correo electrónico. Se enviará una respuesta a tu consulta por correo electrónico.",
  notValidEmailChat: "La dirección de correo electrónico que has indicado no se corresponde con el formato de aaa@bbb.ccc. Comprueba que los datos introducidos son correctos y vuelve a enviar el mensaje.",
  acceptRequestChat: "Tu consulta ha sido remitida al operador. El plazo medio de consideración es de 7 días calendario.",
  notFoundEmployeeChat: "El país indicado no está incluido en la lista de países en los que está disponible la educación según el programa de Rossotrudnichestvo. Tu consulta no puede ser remitida para su consideración. Indica otro país. La lista completa de programas educativos se puede encontrar en la página <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
  langBtnChat_1: "Inglés",
  langBtnChat_2: "Ruso",
  langBtnChat_3: "Otro",
  addAppBtn: "Presentar solicitud",
  listAppBtn: "Lista de solicitudes",
  privacyPolicy: "Política de privacidad",
  ___programs: "programa",
  ___educationalPrograms: "programas educativos",
  __educationalPrograms: "programa educativo",
  editTopic: "Editar el tema",
  editMessage: "Editar el mensaje",
  deleteMessage: "Eliminar el mensaje",
  blockingUser: "Bloqueo de usuario",
  blockEndDate: "Fecha de finalización del bloqueo",
  blockEndTime: "Hora de finalización del bloqueo",
  blockForever: "Bloquear permanentemente",
  block: "Bloquear",
  unlock: "Desbloquear",
  edited: "Redactado",
  founder: "Fundador",
  abbreviation: "Abreviatura",
  studentsByCountry: "Cantidad de estudiantes por país",
  studentsByCountryCount: "Cantidad de estudiantes matriculados",
  contactsInternationalDepartment: "Datos de contacto del departamento internacional",
  site: "Sitio web",
  address: "Dirección",
  format: "Formato",
  typeEvent: "Tipo de evento",
  quotaLearning: "Educación por cupos",
  kcp: "Cupos de admisión",
  countSeatsKCP: "Número de сupos de admisión",
  countSeatsCostTraining: "Número de cupos para la educación de pago",
  distanceLearning: "Educación a distancia",
  notificationAboutDistanceLearning: "El programa educativo puede llevarse a cabo utilizando tecnologías de aprendizaje electrónico y a distancia. Consulta con la universidad para obtener información detallada",
  additionalTests: "Fechas de los exámenes de admisión adicionales.",
  pageNotFound: "Página no encontrada",
  goToMainPage: "Ir a la página de inicio",
  faqInformation: "En relación con todas las preguntas relacionadas con el estado de verificación de la solicitud, la lista de documentos requeridos, los plazos de los exámenes de admisión y otras cuestiones relacionadas con el proceso de admisión, te recomendamos ponerte en contacto con las oficinas de Rossotrudnichestvo o con las embajadas de la Federación de Rusia autorizadas para realizar la selección en tu país. Los datos para contactar con el personal de Rossotrudnichestvo se encuentran en tu cuenta personal.",
  allEvents: "Todos los eventos",
  eventsAndNews: "Eventos y noticias",
  titleNews: "Títular de noticia",
  numberOfBudgetPlaces: "Número de plazas financiadas por el Estado",
  russianGovernmentQuota: "Presupuesto( números de referencia de admisión)",
  legalAddress: "Domicilio legal",
  actualAddress: "Domicilio real",
  goToTheRegister: "Ir al registro de programas adicionales",
  searchForSuitablePrograms: "Buscar programas adecuados",
  captcha: "en",
  theMostPopularEducationalProgramsChosenByApplicants: "Programas de estudio más elegidos por los solicitantes",
  theMostPopularCitiesOfRussiaChosenByApplicants: "Ciudades de Rusia más elegidas por los solicitantes",
  ofTheCountry: "del país",
  passed: "Completado",
  question: "Pregunta",
  instructionForProctoring: "Instructions for passing testing with proctoring",
  banner_1: "El portal funciona en modo de prueba!",
  banner_2: "Estimados usuarios del portal, actualmente pueden surgir dificultades técnicas al usar las direcciones de correo de Google. Por favor, utilicen otros servicios de correo al trabajar con el portal.",
  banner_3: "La convocatoria para las becas se abre a partir del 24 de diciembre de 2022.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
