<template>
  <div class="container">
    <div class="universities__info">
      <h2 class="title-2">
        {{ $t('contactInformation') }}
      </h2>

      <dl class="contact-list mb32">
        <dt class="light" v-if="university.address">{{ $t('actualAddress') }}</dt>
        <dd v-if="university.address">{{ university.address | lang('formatted_address') }}</dd>

        <dt class="light" v-if="university.legal_address">{{ $t('legalAddress') }}</dt>
        <dd v-if="university.legal_address">{{ university.legal_address | lang('formatted_address') }}</dd>

        <dt class="light">{{ $t('site')}}</dt>
        <dd>
          <a 
            class="link" 
            :href="$options.filters.validatorWebSite(university.website)" 
            target="_blank">
            {{ university.website }}
          </a>
        </dd>

        <dt class="light">{{ $t('numberPhone') }}</dt>
        <dd v-if="university.phone_university">{{ university.phone_university }}</dd>

        <dt class="light">{{ $t('eMail')}}</dt>
        <dd>{{ university.email_university }}</dd>
      </dl>

      <h2 class="title-4">
        {{ $t('contactsInternationalDepartment') }}
      </h2>
      <dl class="contact-list">
        <dt class="light">{{ $t('numberPhone') }}</dt>
        <dd v-if="university.phone_university_international_departament">
          {{ university.phone_university_international_departament }}
        </dd>
        
        <dt class="light">{{ $t('eMail')}}</dt>
        <dd>{{ university.email_university_international_departament }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default ({
  name: 'contacts-university',
  props: {
    university: Object,
  },
})
</script>

<style lang="scss">
  .University {
    .swiper-slide {
      img {
        height: 100%;
        width: auto !important;
      }
      width: auto !important;
      .video-player {
        & > * {
          &:first-child {
            width: 533px;
          }
          height: 100% !important;
        }
      }
    }
    .university-media-slide {
      height: 300px !important;
    }
  }
</style>
