<template>
  <div>
    <div class="help" data-help="help" v-if="!(user || {}).id">
      <div class="help-icon">
        <img src="@/assets/images/i-icon.png" alt="">
      </div>

      <div class="help-content">
        <p>
          Добро пожаловать на форум. Здесь вы можете задавать вопросы, общаться, делиться своими впечатлениями об обучении
          и жизни в России. Для того, чтобы оставлять сообщения необходимо
          <a :href="`${avanpost}/user/self/register?lang=${getLocale}`">{{ $t('_signUp') }}</a>
          {{ $t('_or') }}
          <router-link to="/account/login">{{ $t('_logIn') }}</router-link>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'help-forum-generic-alert-is-not-auth',
    data: () => ({
      avanpost: process.env.VUE_APP_LINK_AVANPOST,
      domain: window.location.host,
    }),
    computed: {
      ...mapGetters({
        user: 'users/getUser',
        getLocale: 'getLocale',
      }),
    },
  }
</script>
