import {api} from '@/_services';
import _ from 'lodash';

export default {
  namespaced: true,

  actions: {
    getReviews(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/reviews/',
        params,
      });
    },

    getReview(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: `/ps/api/reviews/${params.id}`,
      });
    },

    getReviewsCountByFilter(store, params = {}) {
      store.state.curLanguage = this.getters.getLocale;

      return api.$getMethod(store, {
        url: '/ps/api/reviews/count-by-filter',
        params,
      });
    },
  },

  getters: {
    getParams: state => {
      return state.params;
    },

    getReviewsList: state => {
      return state.params.reviews;
    },
  },

  mutations: {
    updateParam(state, payload) {
      if (!_.has(state.params, payload.model)) {
        _.set(state.params, payload.model, payload.value || null);
        state.params = _.clone(state.params);
      }

      _.set(state.params, payload.model, payload.value);
      state.params = _.clone(state.params);
    },
  },

  state: {
    params: {
      reviews: {
        total: 0,
        data: [],
      },
    },
  },
};
