<template>
  <div>
    <div class="def-container program-container">
      <div class="program-container-popup" v-show="isShowFilter || !isResponsiveSm">
        <div class="program-container-popup-header">
          <h3 class="title-3">{{ $t('filters') }}</h3>
          <div class="program-container-popup-header-close" @click="isShowFilter = !isShowFilter">
            <img src="@/assets/images/close-black.png" alt="" />
          </div>
        </div>
        <div class="nav-tabs-sub-wrap">
          <ul class="nav-tabs-sub">
            <li class="nav-item-sub" @click="getTypeFilter('main')">
              <router-link :class="{ active: typeFilter === 'main' }" class="nav-link-sub"
                :to="{ query: { typeFilter: 'main' } }">{{ $t('mainPrograms') }}
              </router-link>
            </li>

            <li class="nav-item-sub" @click="getTypeFilter('additional')">
              <router-link :class="{ active: typeFilter === 'additional' }" class="nav-link-sub"
                :to="{ query: { typeFilter: 'additional' } }">{{ $t('additionalPrograms') }}</router-link>
            </li>
          </ul>
        </div>

        <div class="tab-content">
          <div :class="{ 'active show': typeFilter === 'main' }" class="tab-pane" id="item-sub5">
            <form class="program-content">
              <MultiSelect :options="levelEducation" :placeholder="$t('chooseYourLevelOfEducation')"
                store-path="filter.programs.main.levelEducation" :label="$t('theLevelOfEducation')" :callback-on-change="
                  () => {
                    onChangeSelect('levelEducation')
                    updateFilters('levelEducation')
                  }
                " allow-empty />

              <!-- область научных знаний -->
              <MultiSelect :options="scientificAreas" :placeholder="$t('chooseYourAreaOfScientificKnowledge')"
                store-path="filter.programs.main.scientificAreas" :label="$t('areaOfScientificKnowledge')"
                v-if="isLevelEducation" :callback-on-change="
                  () => {
                    updateFilters('scientificAreas')
                    onChangeSelect('scientificAreas')
                  }" allow-empty />
              <!-- профиль научной специальности -->
              <AsyncSelectPaginate :code="true" :placeholder="$t('chooseYourScientificSpecialtyProfile')"
                :searchable="true" store-path="filter.programs.main.scientificSpecialties"
                :label="$t('scientificSpecialtyProfile')" v-if="isLevelEducation"
                :callback-on-change="() => { onChangeSelect('scientificSpecialties') }"
                :load-options="getScientificSpecialties" :filters="filtersScientificSpecialties" allow-empty />
              <MultiSelect :options="groupDirections" :placeholder="$t('chooseAGroupOfTrainingAreas')"
                store-path="filter.programs.main.groupDirection" :label="$t('trainingDirectionGroup')"
                v-if="!isLevelEducation" :callback-on-change="
                  () => {
                    // onChangeSelect('groupDirection');
                    updateFilters('groupDirection')
                  }
                " allow-empty />
              <MultiSelect :options="educationalPrograms" :placeholder="$t('chooseATrainingDirection')"
                store-path="filter.programs.main.educationalProgram" v-if="!isLevelEducation"
                :label="$t('trainingDirection')" :callback-on-change="
                  () => {
                    // onChangeSelect('educationalPrograms');
                  }
                " allow-empty />

              <MultiSelect v-if="!this.isUniversityPrograms" :options="universitiesGetCities"
                :placeholder="$t('chooseACity')" store-path="filter.programs.main.city" :label="$t('city')"
                :callback-on-change="
                  () => {
                    // onChangeSelect('universitiesGetCities');
                  }
                " allow-empty />

              <MultiSelect :options="languagesInstruction" :placeholder="$t('chooseYourLanguageOfInstruction')"
                store-path="filter.programs.main.languageInstruction" :label="$t('languageOfInstruction')"
                :callback-on-change="
                  () => {
                    // onChangeSelect('universitiesGetCities');
                  }
                " allow-empty />
              <!-- вид образования -->
              <MultiSelect :options="typeOfEducations" :placeholder="$t('chooseTheTypeOfEducation')"
                store-path="filter.programs.main.typeOfEducation" :label="$t('typeOfEducation')" :callback-on-change="
                  () => {
                    // onChangeSelect('universitiesGetCities');
                  }
                " allow-empty />
            </form>

            <div class="program-content-btn">
              <a class="btn" href="javascript:void(0)" @click="() => getData('main', true, true)">
                {{ $t('show') }}
                {{
                  totalMainPrograms
                  | declOfNum([
                    $t('___programs'),
                    $t('_programs'),
                    $t('__programs'),
                  ])
                }}
              </a>

              <a class="btn btn-outline" href="javascript:void(0)" @click="resetFilter">{{ $t('clearAllFilters') }}</a>
            </div>
          </div>
          <div :class="{ 'active show': typeFilter === 'additional' }" class="tab-pane" id="item-sub6">
            <form class="program-content">
              <MultiSelect :options="semesters" :placeholder="$t('chooseSemester')"
                store-path="filter.programs.additional.semester" :label="$t('semester')" />

              <MultiSelect :options="semestersDurationAdditional" :placeholder="$t('chooseDurationOfStudy')"
                store-path="filter.programs.additional.semesterDurationAdditional" :label="$t('durationOfStudy')"
                custom-label="name" custom-value="id" allow-empty />

              <MultiSelect v-if="!isUniversityPrograms" :options="universitiesGetCitiesAdditional"
                :placeholder="$t('chooseACity')" store-path="filter.programs.additional.city" :label="$t('city')"
                allow-empty />
              <!-- вид образования -->
              <MultiSelect :options="typeOfEducations" :placeholder="$t('chooseTheTypeOfEducation')"
                store-path="filter.programs.additional.typeOfEducation" :label="$t('typeOfEducation')"
                :callback-on-change="
                  () => {
                    // onChangeSelect('universitiesGetCities');
                  }
                " allow-empty />
            </form>

            <div class="program-content-btn">
              <a class="btn" href="javascript:void(0)" @click="() => getData('additional', true, true)">
                {{ $t('show') }}
                {{
                  totalAdditionalPrograms
                  | declOfNum([
                    $t('___programs'),
                    $t('_programs'),
                    $t('__programs'),
                  ])
                }}
              </a>

              <a class="btn btn-outline" href="javascript:void(0)" @click="resetFilter">{{ $t('clearAllFilters') }}</a>
            </div>
          </div>
        </div>
      </div>

      <a href="#" class="btn btn-outline d-none sm-show" @click="isShowFilter = !isShowFilter">{{ $t('filters') }}</a>
    </div>

    <div class="search-row">
      <Input element-class="txt" :placeholder="$t('enterTheNameOfTheEducationalProgram')"
        store-path="filter.programs.search" :value="((forms.filter || {}).programs || {}).search || ''" type="text"
        :keyup-enter-callback="() => getData(typeFilter, true, true)" />

      <Button type="button" element-class="btn btn-search" :callback="() => getData(typeFilter, true, true)"></Button>
    </div>

    <div class="form-row program-select price-search">
      <h3 class="title-3 light mb16">
        {{
          (typeFilter === 'main' ? programsMainList : programsAdditionalList)
            .total
          | declOfNum([$t('_program'), $t('_programs'), $t('__programs')])
        }}
      </h3>
      <multiselect class="form-row simple-select" :options="sortOptions" label="label" @select="onSelect"
        :value="sortOptions.find(item => item.value === sorting)" placeholder="Параметр сортировки">
      </multiselect>
    </div>

    <div class="card__vertical" v-if="typeFilter === 'main'">

      <ProgramsSearchItem :data="program" :currency="currency" :all-data="programsMainList.data" v-for="(program, key) in programsMainList.data" v-bind:key="key"/>

      <Pagination :count="programsMainList.last_page" :callback-on-change="() => getData('main', true)"
        pagination-model="pagination.programs.main" :last-data-count="programsMainList.data.length" />
    </div>

    <div class="card__vertical" v-if="typeFilter === 'additional'">
      <ProgramsSearchItem :data="program" v-for="(program, key) in programsAdditionalList.data" v-bind:key="key"
        type="additional" />

      <Pagination :count="programsAdditionalList.last_page" :callback-on-change="() => getData('additional', true)"
        pagination-model="pagination.programs.additional" :last-data-count="programsAdditionalList.data.length" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {helpers} from '@/_services'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '@/components/generic/pagination/Pagination'
import MultiSelect from '@/components/generic/form/field/MultiSelect'
import ProgramsSearchItem from '@/components/site/pages/educationInRussia/programs/generic/ProgramsSearchItem'
import Input from '@/components/generic/form/field/Input'
import Button from '@/components/generic/form/Button'
import AsyncSelectPaginate from '@/components/generic/asyncSelectPaginate/AsyncSelectPaginate.vue'

export default {
  name: 'programs-search',

  components: {
    Pagination,
    MultiSelect,
    ProgramsSearchItem,
    Input,
    Button,
    AsyncSelectPaginate,
  },

  data() {
    return {
      typeFilter: 'main',
      levelEducation: [],
      areaEducation: [],
      groupDirections: [],
      educationalPrograms: [],
      semestersDurationAdditional: [],
      universitiesGetCities: [],
      languagesInstruction: [],
      typeOfEducations: [],
      semesters: [],
      universitiesGetCitiesAdditional: [],
      totalMainPrograms: 0,
      totalAdditionalPrograms: 0,
      isShowFilter: '',
      allFilters: {},
      isLevelEducation: false,
      postgraduateNumber: 1,
      areaScientificKnowledge: [],
      scientificSpecialtyProfile: [],
      scientificAreas: [],
      scientificSpecialties: [],
      isLoading: true,
      filtersScientificSpecialties: {},
      preloader: false,
      currency:null,
      sorting: 'asc',
      priceSelector: {label: 'Стоимость по возрастанию', value: 'asc'},
      sortOptions: [
        {label: 'Стоимость по убыванию', value: 'desc'},
        {label: 'Стоимость по возрастанию', value: 'asc'},
      ],
    }
  },

  computed: {
    ...mapGetters({
      pagination: 'getPagination',
      programsMainList: 'educationInRussia/getProgramsMainList',
      programsAdditionalList: 'educationInRussia/getProgramsAdditionalList',
      forms: 'form/forms',
    }),
  },

  methods: {
    ...mapActions({
      getTypeOfEducations: 'getTypeOfEducations',
      getLevelEducation: 'getLevelEducation',
      getUniversitiesGetCities: 'educationInRussia/getUniversitiesGetCities',
      getAreaEducation: 'getAreaEducation',
      getSemesters: 'getSemesters',
      getGroupDirections: 'getGroupDirections',
      getEducationFilter: 'getEducationFilter',
      getEducationalPrograms: 'getEducationalPrograms',
      getProgramsCountByFilter: 'educationInRussia/getProgramsCountByFilter',
      getLanguagesInstruction: 'getLanguagesInstruction',
      getPrograms: 'educationInRussia/getPrograms',
      universityPrograms: 'educationInRussia/universityPrograms',
      getScientificAreas: 'getScientificAreas',
      getScientificSpecialties: 'getScientificSpecialties',
      getCurrency: 'getCurrency',
    }),

    getTypeFilter(type) {
      this.typeFilter = type
    },

    getFilter() {
      let currentFilter

      if (this.typeFilter === 'main') {
        currentFilter = this.forms.filter.programs.main
      } else {
        currentFilter = this.forms.filter.programs.additional
      }

      return currentFilter
    },

    onChangeSelect(filter) {
      let {levelEducation} = this.getFilter()

      const selectedOption = this.levelEducation.filter(
        level => level.value === levelEducation,
      )
      const selectedOptionValue = selectedOption[0].value
      if (filter !== 'levelEducation') return

      this.isLevelEducation =
        Number(selectedOptionValue) === this.postgraduateNumber ? true : false
    },

    onSelect(value) {
      this.sorting = value.value
      this.getData(this.typeFilter, true, true)
    },


    updateFilters(filter) {
      let currentFilter = this.getFilter(),
        _groupDirection = _.clone(currentFilter.groupDirection)

      this.getEducationFilter({
        hideAdditionalProgs: this.typeFilter === 'main' ? 1 : null,
        showOnlyAdditionalProgs: this.typeFilter === 'main' ? null : 1,
        areaId: this.isLevelEducation ? null : currentFilter.areaEducation,
        levelId: currentFilter.levelEducation,
        sciAreaId: this.isLevelEducation ? currentFilter.scientificAreas : null,
        sciSpecialtyId: this.isLevelEducation
          ? currentFilter.scientificSpecialties
          : null,
        directionId:
          filter === 'levelEducation' ||
            filter === 'areaEducation' ||
            filter === 'scientificAreas'
            ? null
            : currentFilter.groupDirection,
      }).then(res => {
        if (
          filter === 'levelEducation' ||
          filter === 'areaEducation' ||
          filter === 'scientificAreas'
        ) {
          if (this.typeFilter === 'main') {
            this.groupDirections = _.map(
              res.data.education_direction,
              value => ({value: value.id, name: value.name}),
            )

            if (_.find(this.groupDirections, {value: _groupDirection})) {
              this.$store.commit('form/updateField', {
                model: 'filter.programs.main.groupDirection',
                value: _groupDirection,
              })
            } else {
              this.$store.commit('form/updateField', {
                model: 'filter.programs.main.groupDirection',
                value: null,
              })
            }
          }
        }

        if (
          filter === 'groupDirection' ||
          filter === 'levelEducation' ||
          filter === 'areaEducation' ||
          filter === 'scientificAreas'
        ) {
          if (this.typeFilter === 'main') {
            this.educationalPrograms = _.map(
              res.data.education_program,
              value => ({value: value.id, name: value.name}),
            )

            if (
              !_.find(this.educationalPrograms, {
                value: currentFilter.groupDirection,
              })
            ) {
              this.$store.commit('form/updateField', {
                model: 'filter.programs.main.educationalProgram',
                value: currentFilter.educationalProgram,
              })
            }
          }
        }
      })

      if (filter === 'scientificAreas') {
        this.getFiltersScientificSpecialties(currentFilter.scientificAreas)
      }
    },

    getCites() {
      this.getUniversitiesGetCities({
        district: this.forms.filter.programs.main.district,
      }).then(res => {
        this.universitiesGetCities = helpers.options(res)

        if (!this.universitiesGetCitiesAdditional.length) {
          this.universitiesGetCitiesAdditional = helpers.options(res)
        }
      })
    },

    getFiltersScientificSpecialties(areasId) {
      this.filtersScientificSpecialties = {scientific_areas_id: areasId}
    },

    resetFilter() {
      this.$store.commit('form/updateField', {
        model: 'filter.programs.search',
        value: '',
      })
      this.$store.commit('updateParam', {
        model: 'pagination.programs.main.page',
        value: 1,
      })
      this.$store.commit('updateParam', {
        model: 'pagination.programs.additional.page',
        value: 1,
      })
      this.$store.commit('updateParam', {
        model: 'pagination.programs.main.length',
        value: 10,
      })
      this.$store.commit('updateParam', {
        model: 'pagination.programs.additional.length',
        value: 10,
      })

      if (this.typeFilter === 'main') {
        this.$store.commit('form/updateField', {
          model: 'filter.programs.main',
          value: {},
        })
      } else {
        this.$store.commit('form/updateField', {
          model: 'filter.programs.additional',
          value: {},
        })
      }

      this.getFilterData()
      this.getData(this.typeFilter, true, true)
      this.filtersScientificSpecialties = {}
    },

    getData(typeFilter, updateFilter, resetPagination) {
      this.$store.commit('preloader/isLoading', true)
      this.isShowFilter = !this.isShowFilter
      let currentFilter = this.getFilter()

      if (updateFilter || _.isEmpty(this.allFilters)) {
        this.allFilters = {
          areaId:
            this.isLevelEducation && currentFilter
              ? null
              : currentFilter.areaEducation,
          city: currentFilter && currentFilter.city,
          directionId: currentFilter && currentFilter.groupDirection,
          programId: currentFilter && currentFilter.educationalProgram,
          langInstruction: currentFilter && currentFilter.languageInstruction,
          levelId: currentFilter.levelEducation,
          sciAreaId:
            this.isLevelEducation && currentFilter
              ? currentFilter.scientificAreas
              : null,
          sciSpecialtyId:
            this.isLevelEducation && currentFilter
              ? currentFilter.scientificSpecialties
              : null,
          additionalProgs: typeFilter === 'main' ? null : 1,
          semester: currentFilter.semester,
          semesterDuration:
            typeFilter === 'additional'
              ? currentFilter.semesterDurationAdditional
              : null,
          q: this.forms.filter.programs.search,
          slug: this.universitySlug,
          typeFinancing: currentFilter.typeOfEducation,
          cost_sortable: this.sorting,
        }
      }

      if (!resetPagination) {
        this.allFilters.perPage =
          typeFilter === 'additional'
            ? _.get(this.pagination, 'programs.additional.length', 10)
            : _.get(this.pagination, 'programs.main.length', 10)

        this.allFilters.page =
          typeFilter === 'additional'
            ? _.get(this.pagination, 'programs.additional.page', null)
            : _.get(this.pagination, 'programs.main.page', null)
      } else {
        this.allFilters.page = 1

        if (typeFilter === 'additional') {
          this.$store.commit('updateParam', {
            model: 'pagination.programs.additional.page',
            value: 1,
          })
          this.$store.commit('updateParam', {
            model: 'pagination.programs.additional.length',
            value: 10,
          })
        } else {
          this.$store.commit('updateParam', {
            model: 'pagination.programs.main.page',
            value: 1,
          })
          this.$store.commit('updateParam', {
            model: 'pagination.programs.main.length',
            value: 10,
          })
        }
      }

      (this.isUniversityPrograms ? this.universityPrograms : this.getPrograms)(
        this.allFilters,
      ).then(res => {
        if (res.status === 'success') {
          if (typeFilter === 'main') {
            this.$store.commit('educationInRussia/updateParam', {
              model: 'programs.main',
              value: res.data,
            })
          } else {
            this.$store.commit('educationInRussia/updateParam', {
              model: 'programs.additional',
              value: res.data,
            })
          }
        }
      }).finally(() => this.$store.commit('preloader/isLoading', false))
    },
    getFilterData() {
      this.$store.commit('preloader/isLoading', true)

      this.getEducationalPrograms({
        hideAdditionalProgs: 1,
      }).then(res => {
        this.educationalPrograms = helpers.options(res)
      }).finally(() => this.$store.commit('preloader/isLoading', false))
    },

    getCountProgram(typeFilter) {  
      let currentFilter = this.getFilter()
      this.getProgramsCountByFilter({
        areaId:
          !this.isLevelEducation && currentFilter
            ? currentFilter.areaEducation
            : null,
        langInstruction: currentFilter && currentFilter.languageInstruction,
        city: currentFilter.city,
        directionId: currentFilter.groupDirection,
        programId: currentFilter.educationalProgram,
        levelId: currentFilter.levelEducation,
        semester: currentFilter.semester,
        sciAreaId:
          this.isLevelEducation && currentFilter
            ? currentFilter.scientificAreas
            : null,
        sciSpecialtyId:
          this.isLevelEducation && currentFilter
            ? currentFilter.scientificSpecialties
            : null,
        additionalProgs: typeFilter === 'main' ? null : 1,
        universityId: this.universityId,
        semesterDuration:
          typeFilter === 'additional'
            ? currentFilter.semesterDurationAdditional
            : null,
        typeFinancing: currentFilter.typeOfEducation,
      }).then(res => {
        if (typeFilter === 'main') {
          this.totalMainPrograms = res.data
        } else {
          this.totalAdditionalPrograms = res.data
        }
      })
    },
  },

  mounted() {
    window.scroll(0, 0)

    if (this.$route.query.typeFilter === 'additional') {
      this.typeFilter = 'additional'
      this.getData('additional', true, true)
      this.getCountProgram('additional') 
    } else {
      this.getData('main', true, true)
      this.getCountProgram('main')
    }

    if (!this.isUniversityPrograms) {
      this.getCites()
    }

    this.getLevelEducation().then(res => {
      this.levelEducation = helpers.options(res)
    })

    this.getSemesters().then(res => {
      this.semesters = helpers.options(res)
    })

    this.getScientificAreas().then(res => {
      this.scientificAreas = helpers.options(res.data)
    })

    this.getGroupDirections().then(res => {
      this.groupDirections = helpers.options(res)
    })

    this.getLanguagesInstruction().then(res => {
      this.languagesInstruction = helpers.options(res)
    })

    this.getAreaEducation().then(res => {
      this.areaEducation = helpers.options(res)
    })

    this.getTypeOfEducations().then(res => {
      this.typeOfEducations = helpers.optionsObjectKeys(res.data)
    })

    this.getFilterData()

    this.getCurrency().then(res => {
      this.currency = res.data
    })

    this.semestersDurationAdditional = [
      {id: '0-0,99', name: this.$t('lessThan1Month')},
      {id: '1-2,99', name: this.$t('1To3Months')},
      {id: '3-5,99', name: this.$t('3To6Months')},
      {id: '6-11,99', name: this.$t('6To12Months')},
      {id: '12+', name: this.$t('moreThan1Year')},
    ]
  },

  props: {
    isUniversityPrograms: Boolean,
    universitySlug: String,
    universityId: Number,
  },

  watch: {
    'forms.filter.programs.main.typeOfEducation'() {
      this.getCountProgram(this.typeFilter) 
    },
    'forms.filter.programs.additional.typeOfEducation'() {
      this.getCountProgram(this.typeFilter) 
    },
    typeFilter() {
      if (this.typeFilter === 'additional') {
        this.getData(this.typeFilter, true, true)
      }
      if (this.typeFilter === 'main') {
        if (!this.programsMainList.data.length) {
          this.getData('main')
        }
      } else {
        if (!this.programsAdditionalList.data.length) {
          this.getData('additional')

          this.getCountProgram(this.typeFilter)
        }
      }
    },
    'forms.filter.programs.search'(valNext) {
      if (valNext === '') {
        this.getData(this.typeFilter, true, true)
      }
    },
    'pagination.programs.main.page'(currentPage) {
      if (currentPage !== 1) {
        this.isShowFilter = !this.isShowFilter
      }
    },
     'pagination.programs.additional.page'(currentPage) {
      if (currentPage !== 1) {
        this.isShowFilter = !this.isShowFilter
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.price-search {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-row .simple-select {

  background-color: white;
  border-radius: 10px;
  border: 1px solid gray;

}

.price-search.form-row .multiselect.simple-select {
  min-width: 320px;
  background-color: inherit;
  border:none;
}
</style>

