<template>
  <div :class="{row: !hideGrid}" class="news__wrapper">
    <div
      :class="{'col-2': !hideGrid}"
      v-for="(news, key) in data"
      v-bind:key="key"
      v-if="news.is_active"
    >
      <router-link :to="`/news/${news.slug}`">
        <figure class="info__card news">
          <div class="info__card-img" style="height: 180px">
            <img :src="news.cover" alt="" />
          </div>
          <figcaption class="info__card-content">
            <h5 class="title-sub">
              {{ moment(news.created_at).format('DD.MM.YY') }}
            </h5>
            <p>
              {{ substringNews(news) | lang }}
            </p>
          </figcaption>
        </figure>
      </router-link>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'items-news',

    data: () => ({
      moment: moment,
    }),

    props: {
      data: Array,
      hideGrid: Boolean,
    },

    methods: {
      substringNews(news) {
        const name = news.name || ''
        return news ? {
          ...news,
          name: name.length >= 90
            ? name.substring(0, 87) + '...'
            : name,
        } : {}
      },
    },
  }
</script>
