<template>
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <footer class="footer">
        <div class="container">
            <div class="footer__inner">
                <div
                  class="footer__list-wrap"
                  v-for="(m, k) in menu"
                  v-bind:key="k"
                >
                    <h3
                      class="title-sub"
                      @click="isShow = isShow === m.id ? '' : m.id"
                      :class="{show: isShow === m.id}"
                    >{{ m | lang('name') }}</h3>

                    <ul class="footer__list" v-show="isShow === m.id || !isResponsive">
                        <li
                          v-for="(child, k1) in m.children"
                          v-bind:key="k1"
                        ><router-link :to="child.url">{{ child | lang('name') }}</router-link></li>
                    </ul>
                </div>
            </div>

            <hr />
        </div>

        <div class="footer__copyright">
            <div class="footer__copyright-label">
            <div class="footer__copyright-icon">
                <img src="@/assets/images/footer-logo.png" alt="">
            </div>
               <p> {{ $t('officialSiteForTheSelectionOfForeignCitizensToStudyInRussia', {year: year}) }}</p>

            </div>
                <div class="footer__copyright-link">
                    <router-link to="/pages/pws">{{$t('privacyPolicy')}}</router-link>
                </div>
        </div>
    </footer>
</template>

<script>
    var _paq = window._paq = window._paq || [];
    _paq.push(['setDocumentTitle', document.domain + '/' + document.title]);
    _paq.push(['setCookieDomain', '*.education-in-russia.com']);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u=`${document.location.origin}/mtm/`;
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', process.env.VUE_APP_MATOMO_SITE_ID]);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();

    import {mapGetters} from 'vuex';
    import moment from 'moment';

    export default {
        name: 'footer-blocks',
        props: {},

        computed: {
            ...mapGetters({
                menu: 'getMenu',
            }),
            year: () => moment().format('YYYY'),
        },

        data: () => ({
            isShow: '',
        }),
    };
</script>
