<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <!--  eslint linebreak-style: ["error", "windows"]-->
  <div>
    <div class="base-container" v-if="theme.category ">
      <div class="container">
        <Breadcrumbs
          :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('help'), url: '/help'},
              { title: $t('forum'), url: '/help/forum' },
              { title: lang(theme.category, 'name'), url: `/help/forum/themes/${theme.category.slug}` },
              { title: theme.title },
            ]"
        />

        <h1 class="title-1 mb32 forum-title">{{ theme.title }}</h1>
        <AlertIsNotAuth />

        <div class="search-row mb10">
          <Input
            element-class="txt"
            :placeholder="$t('searchInTopic')"
            store-path="filter.forum.themeMessages.search"
            type="text"
            :keyup-enter-callback="getSearchData"
          />

          <Button
            type="button"
            element-class="btn btn-search"
            :callback="getSearchData"
          ></Button>
        </div>

        <h3 class="title-3 forum__title-result" v-if="isSearch">{{ $t('searchingResults') }}</h3>

        <div class="forum__message">
          <div
            v-for="(messages, key) in themesMessagesList.data"
            v-bind:key="key"
          >
            <ForumPost 
              :data="messages" 
              :is-search="isSearch" 
              :update-posts="getData"
            >
              <template slot="bottom-actions">
                <div class="forum__message-item-content-navigation" v-if="!isSearch && user.id">
                  <a
                    v-if="permissions.post_edit"
                    class="red"
                    href="javascript:void(0)"
                    @click="() => deletePost(messages)"
                  >{{ $t('deleteMessage') }}</a>
                  <a 
                    v-if="permissions.post_delete && messages.user.user_id === user.id"
                    href="javascript:void(0)"
                    @click="() => newMessage('edit', messages)"
                  >{{ $t('editMessage') }}</a>
                  <a
                    href="javascript:void(0)"
                    @click="() => newMessage('response', messages)"
                  >{{ $t('toAnswer') }}</a>

                  <a
                    class="forum__message-link"
                    href="javascript:void(0)"
                    @click="() => newMessage('quote', messages)"
                  >{{ $t('toQuote') }}</a>

                  <a
                    class="forum__message-link"
                    href="javascript:void(0)"
                    @click="() => targetComplain(messages.id)"
                  >{{ $t('complain') }}</a>
                  <div class="forum__message-responsive-menu">
                    <div class="forum__message-responsive-menu-btn" @click="isShowFilter = !isShowFilter"></div>
                    <div class="forum__message-responsive-menu-wrap" v-show="isShowFilter || !isResponsive">
                      <a
                        class="forum__message-link"
                        href="javascript:void(0)"
                        @click="() => newMessage('quote', messages)"
                      >{{ $t('toQuote') }}</a>

                      <a
                        class="forum__message-link"
                        href="javascript:void(0)"
                        @click="() => targetComplain(messages.id)"
                      >{{ $t('complain') }}</a>
                    </div>
                  </div>
                </div>
              </template>
            </ForumPost>
          </div>
        </div>

        <div class="pagination" v-if="shownButtonNewMessage && !isSearch && user.id">
          <Pagination
            :count="themesMessagesList.meta.last_page"
            :callback-on-change="getData"
            pagination-model="pagination.programs.additional"
            :last-data-count="themesMessagesList.data.length"
            :show-list-showing="false"
          />

          <a
            v-if="!permissions.is_banned"
            href="javascript:void(0)"
            class="btn btn-big"
            @click="() => newMessage('message')"
          >{{ $t('addNewMessage') }}</a>
        </div>

        <NewMessage
          ref="newMessageElement"
          :data="currentMessageData"
          :type="currentTypeMessage"
          :paren-id="{id: themeId}"
          :send-callback="getData"
          v-if="!isSearch"
          :edit="editMsg.id"
        />
      </div>
    </div>

    <Modal
      :unique-id="`${modalComplainUniqueId}`"
      ref="modal"
      :callback-close="() => this.createComplainSuccess = false"
    >
      <div slot="body">
        <Form :form-callback="createComplain">
          <template slot="form" v-if="!createComplainSuccess">
            <h2 class="title-2">{{ $t('complain') }}</h2>

            <p>
              <MultiSelect
                :options="complainList"
                :placeholder="$t('selectComplaintType') "
                store-path="forum.complain.complain_id"
                :label="$t('typeOfComplaint')"
              />

              <Textarea
                v-if="forms.forum && forms.forum.complain && forms.forum.complain.complain_id === 3"
                element-class="txt-area"
                rules="required|max:1000|min:10"
                :placeholder="$t('enterComplaintText')"
                :label="$t('complaintText')"
                store-path="forum.complain.message"
              />
            </p>

            <Button
              element-class="btn"
              type="submit"
              :text="$t('submit')"
            />
          </template>

          <template slot="form" v-if="createComplainSuccess">
            <h2 class="title-2">{{ $t('complaintSentSuccessfully') }}</h2>

            <p>
              {{ $t('yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly') }}!
            </p>

            <a
              href="javascript:void(0)"
              class="btn"
              @click="() => { targetModal(modalComplainUniqueId); this.createComplainSuccess = false }"
            >{{ $t('close') }}</a>
          </template>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  
  import {helpers} from '@/_services';
  import Vue from 'vue';
  import AlertIsNotAuth from '@/components/site/pages/help/forum/generic/AlertIsNotAuth';
  import NewMessage from '@/components/site/pages/help/forum/generic/NewMessage';
  import Pagination from '@/components/generic/pagination/Pagination';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Input from '@/components/generic/form/field/Input';
  import Button from '@/components/generic/form/Button';
  import Modal from '@/components/generic/modal/Modal';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';
  import Textarea from '@/components/generic/form/field/Textarea';
  import Form from '@/components/generic/form/Form';
  import ForumPost from '@/components/site/pages/help/forum/generic/ForumPost';

  export default {
    name: 'Forumthemes',
    components: {
      Textarea,
      AlertIsNotAuth,
      NewMessage,
      Pagination,
      Breadcrumbs,
      Input,
      Button,
      Modal,
      MultiSelect,
      Form,
      ForumPost,
    },

    computed: {
      ...mapGetters({
        forms: 'form/forms',
        shownButtonNewMessage: 'help/getShownButtonNewMessage',
        isModalShown: 'modal/isModalShown',
        user: 'users/getUser',
        pagination: 'getPagination',
        permissions: 'users/getPermissionsForum',
      }),
    },

    data:() => ({
      themesMessagesList: {
        last_page: 0,
        data: [],
      },
      editMsg: {},
      lang: Vue.filter('lang'),
      theme: {},
      complainList: [],
      themeId: false,
      currentMessageData: null,
      currentTypeMessage: null,
      createComplainSuccess: false,
      currentComplainMessageId: null,
      isSearch: false,
      modalComplainUniqueId: 'modal-complain',
      isShowFilter: '',
    }),

    methods: {
      ...mapActions({
        getForumPost: 'help/getForumPost',
        getForumPostSearchById: 'help/getForumPostSearchById',
        getForumDiscussionSlug: 'help/getForumDiscussionSlug',
        getForumComplainList: 'help/getForumComplainList',
        setForumComplains: 'help/setForumComplains',
        deleteForumPostById: 'help/deleteForumPostById',
      }),

      targetComplain(id) {
        this.targetModal(this.modalComplainUniqueId);
        this.currentComplainMessageId = id;
        this.$store.commit('form/updateField', {model: 'forum.complain', value: {}})
      },

      getData() {
        this.getForumPost({
          discussion: this.$route.params.slug,
          page: this.$route.query.page || _.get(this.pagination, 'programs.additional.page', 1),
        }).then(res => {
          if (this.$route.query.page) {
            this.$router.replace({...this.$router.currentRoute, query: {page: undefined}})
          }

          this.isSearch = false;

          if (res.data !== 401) {
            this.themesMessagesList = res;
          }
        })
      },

      getSearchData() {
        this.getForumPostSearchById({
          id: this.themeId,
          params: {q: _.get(this.forms, 'filter.forum.themeMessages.search', null)},
        }).then(res => {
          if (_.get(this.forms, 'filter.forum.themeMessages.search', null)) {
            this.isSearch = true;
          } else {
            this.isSearch = false;
          }

          if (res.data !== 401) {
            this.themesMessagesList = res;
          }
        })
      },
      
      checkTypeMessage(body) {
        const element = document.createElement('div')
        element.innerHTML = body
        return element.firstElementChild ? element.firstElementChild.className : ''
      },
      
      newMessage(type, message) {
        this.currentTypeMessage = type;
        if (type === 'edit') {
          this.editPost(message)
          this.currentTypeMessage = this.checkTypeMessage(message.body)
        } else {
          this.editPost({})
        }
        this.currentMessageData = message;
        if (this.shownButtonNewMessage) {
          this.$store.commit('help/setShownButtonNewMessage', !this.shownButtonNewMessage)
        }
        this.$nextTick(() => {
          const newMessageElement = this.$refs.newMessageElement.$el;
          newMessageElement.scrollIntoView();
          const textarea = newMessageElement.querySelector('textarea');
          textarea.focus();
        });
      },

      deletePost(id) {
        this.deleteForumPostById(id).then(() => this.getData())
      },

      editPost(post) {
        this.editMsg = post;
      },
      
      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },

      createComplain() {
        let data = {
          post_id: this.currentComplainMessageId,
          complain_id: this.forms.forum.complain.complain_id,
        };

        if (this.forms.forum.complain.message) {
          data['message'] = this.forms.forum.complain.message;
          data['custom_reason'] = this.forms.forum.complain.message;
        }

        this.setForumComplains(data).then(() => {
          this.createComplainSuccess = true;
        })
      },
    },

    mounted() {
      this.getData();
      this.getForumDiscussionSlug(this.$route.params.slug).then(res => {
        this.theme = res.data;
        this.themeId = res.data.id;
      });

      //запрос /ps/api/forum/complain/list для блокирования сообщений
      this.getForumComplainList().then(res => {
        this.complainList = helpers.options(res);
      });
    },

    watch: {
      editMsg() {
        this.$store.commit('help/setShownButtonNewMessage', false);
      },

      'forms.filter.forum.themeMessages.search'(nextVal, prevVal) {
        if (nextVal !== prevVal && !nextVal) {
          this.getData();
        }
      },
      permissions() {
        this.getData()
      },
    },
  };
</script>
