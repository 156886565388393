<template>
  <div class="forum__message-item mb16">
    <figure class="forum__message-item-user">
      <div>
        <h5 class="title-5">{{ data.user.username }}</h5>
        <div class="forum__message-item-user-data title-sub light">{{ $t('posted')}} {{ data.time_ago }}</div>
      </div>

      <figcaption class="forum__message-item-user-info">
        <div class="forum__message-item-user-avatar">
          <img v-if="data.user.avatar" :src="data.user.avatar" :alt="data.user.username" />
          <img v-if="!data.user.avatar" src="@/assets/images/icon/account_circle-24px.svg" :alt="data.user.username" />
        </div>

        <div class="country" v-if="data.user.country">
          <span class="country-flag">
            <img 
              v-if="data.user.country.country_logo"
              :src="data.user.country.country_logo" 
              alt=""
              class="country-image"
            >
          </span>

          <span class="country-text">{{ data.user.country.name || '' }}</span>
        </div>
        <a href="/help/forum/all-messages">
          <span class="title-sub light one-line">{{data.user.post_count }} {{ $t('_posts') }}</span>
        </a>

        <div v-if="!!permissions.user_block || !!permissions.user_unblock">
          <a
            href="javascript:void(0)"
            class="forum__banned"
            @click="actionForUser()"
          >{{ !isUserBanned() ? $t('block') : $t('unlock') }}</a>
        </div>
      </figcaption>
    </figure>

    <div class="forum__message-item-content">
      <div class="title-sub light">{{ $t('posted') }} {{ data.time_ago }}{{data.time_edited && `. ${$t('edited')} ${data.time_edited}` }}</div>
      <div v-html="data.body" />
      <a
        v-for="file in data.attached_files"
        :key="file.id"
        class="link"
        :href="`/static_forum/${file.path}`"
        target="_blank"
      >{{ file.original_name }}</a>
      <slot name="bottom-actions" />

      <div
        class="forum__message-item-content-navigation"
        v-if="isSearch"
      >
        <a
          href="javascript:void(0)"
          @click="() => goToMessage(data)"
        >{{ $t('goToMessage') }}</a>
      </div>
    </div>

    <Modal
      :unique-id="`${modalBannedUniqueId}`"
      ref="modal"
    >
      <h2 slot="header" class="title-2">{{ $t('blockingUser') }}</h2>
      <div slot="body">
        <InputDate 
          element-class="txt"
          type="date"
          :label="$t('blockEndDate')"
          :placeholder="'ДД.ММ.ГГГГ'"
          store-path="forum.dateEndBlocking"
        />
        <Input 
          element-class="txt"
          type="time"
          :label="$t('blockEndTime')"
          :placeholder="'ЧЧ:ММ'"
          store-path="forum.timeEndBlocking"
        />
        <div class="checkbox">
          <label for="isBlockForever">
            <InputSimple
              store-path="forum.isBlockForever"
              element-id="isBlockForever"
              type="checkbox"
              :timeout="0"
            />
            <span>{{ $t('blockForever') }}</span>
          </label>       
        </div> 
        <a
          href="javascript:void(0)"
          class="btn-danger"
          @click="bannedUser"
        >{{ $t('block') }}</a>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/generic/modal/Modal';
  import Input from '@/components/generic/form/field/Input';
  import InputDate from '@/components/generic/form/field/InputDate';
  import InputSimple from '@/components/generic/form/field/InputSimple';
  import {mapActions, mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: 'help-forum-generic-forum-post',

    components: {
      Modal,
      Input,
      InputDate,
      InputSimple,
    },
    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
        user: 'users/getUser',
        forms: 'form/forms',
        updateBannedList: 'help/updateBannedList',
        permissions: 'users/getPermissionsForum',
      }),
    },

    props: {
      data: Object,
      isSearch: Boolean,
      updatePosts: Function,
    },

    data: () => ({
      modalBannedUniqueId: '',
    }),

    methods: {
      ...mapActions({
        setForumComplainBan: 'help/setForumComplainBan',
        getForumGoToMessage: 'help/getForumGoToMessage',
        setForumComplainUnBan: 'help/setForumComplainUnBan',
      }),

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },
      
      actionForUser() {
        const {user_id} = this.data.user
        if (this.isUserBanned()) return this.unbannedUser(`${user_id}`) 
        this.targetModal(this.modalBannedUniqueId); 
      },

      isUserBanned() {
        const {is_ban_user} = this.data
        return is_ban_user
      },

      bannedUser() {
        const params = {
          id: this.data.user.user_id,
          always: this.forms.forum.isBlockForever,
        }
        let 
          date = moment().format('YYYY-MM-DD'), 
          time = moment().format('HH:mm:ss');
        if (this.forms.forum.dateEndBlocking) date = moment(this.forms.forum.dateEndBlocking).format('YYYY-MM-DD')
        if (this.forms.forum.timeEndBlocking) time = moment(this.forms.forum.timeEndBlocking, 'HH:mm:ss').format('HH:mm:ss')
        if (
          this.forms.forum.dateEndBlocking || 
          this.forms.forum.timeEndBlocking
        ) params.dateBlocking = `${date} ${time}`
        if (
          !this.forms.forum.dateEndBlocking && 
          !this.forms.forum.timeEndBlocking
        ) params.dateBlocking = moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
     
        this.setForumComplainBan(params).then(() => {
          this.targetModal(this.modalBannedUniqueId);
          this.$store.commit('help/setUpdateBannedList', {model: 'updateBannedList', value: !this.updateBannedList})
          this.updatePosts()
        })
      },

      unbannedUser(userId) {
        this.setForumComplainUnBan({userId, data: {}}).then(() => {
            this.$store.commit('help/setUpdateBannedList', {model: 'updateBannedList', value: !this.updateBannedList})
            this.updatePosts()
          }
        )
      },

      makeid(length = 5) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },

      goToMessage(message) {
        this.getForumGoToMessage({
          postId: message.id,
          discussionId: message.discussion_id,
        }).then(res => {
          if (res.data.page && res.data.slug) {
            this.$router.push(`/help/forum/themes/messages/${res.data.slug}?page=${res.data.page}`);
          }
        })
      },
    },

    mounted() {
      this.modalBannedUniqueId = 'modal-banned' + this.makeid();
    },
  }
</script>
<style>
.forum__message-item-content ul li{
  list-style: inside;
}

.forum__message-item-content ol li {
  list-style: decimal;
}

.forum__message-item-content i {
  font-family: auto;
}
</style>
