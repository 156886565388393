import Vue from 'vue';
import moment from 'moment';

Vue.mixin({
  methods: {
    dateEvent: function(start_date, end_date) {
      const monthStart = moment(start_date,'YYYY-MM-DD').format('MM');
      const dayStart = moment(start_date,'YYYY-MM-DD').format('DD');
      const yearStart = moment(start_date,'YYYY-MM-DD').format('yyyy');
      const monthEnd = moment(end_date,'YYYY-MM-DD').format('mm');
      const dayEnd = moment(end_date,'YYYY-MM-DD').format('dd');
      const yearEnd = moment(end_date,'YYYY-MM-DD').format('yyyy');
      if (
        yearStart === yearEnd && 
        monthStart === monthEnd && 
        dayStart === dayEnd
      ) return moment(start_date,'YYYY-MM-DD').format('DD MMMM')
      if (
        yearStart === yearEnd && 
        monthStart === monthEnd
      ) return `${dayStart} — ${moment(end_date,'YYYY-MM-DD').format('DD MMMM')}`
      if (
        yearStart === yearEnd
      ) return `${moment(start_date,'YYYY-MM-DD').format('DD MMMM')} — ${moment(end_date,'YYYY-MM-DD').format('DD MMMM')}`.trim()
      return `${moment(start_date,'YYYY-MM-DD').format('DD MMMM YYYY')} — ${moment(end_date,'YYYY-MM-DD').format('DD MMMM YYYY' )}`.trim()
    },
    timeEvent: function(start_time, end_time) {
      return `${moment(start_time, 'hh:mm:ss').format('hh:mm')} ${end_time && '- ' + moment(end_time, 'hh:mm:ss').format('hh:mm')}`
    },
    startTimeEvent: function(start_time) {
      return `${moment(start_time, 'hh:mm:ss').format('hh:mm')}`
    },
    endTimeEvent: function(end_time) {
      return `${moment(end_time, 'hh:mm:ss').format('hh:mm')}`
    },
    url_link: function(url) {
      return url ? Vue.filter('validatorWebSite')(url) : '#'
    },
  },
})
