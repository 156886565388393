<template>
  <div>
    <div class="home-page">
      <div class="login__page-container">
        <div class="container" id="container-calendar">
          <Breadcrumbs
            :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('eventCalendar') }
            ]"
          />

          <h1 class="title-1 mb32">{{$t('eventCalendar')}}</h1>

          <div class="login__wrap">
            <div ref="calendar" id="calendar"></div>

            <div v-if="isCurrentEventShown"
                 class="event-popover"
                 :style="`top: ${currentEvent.pageY}px; left: ${currentEvent.pageX}px;`"
            >
              <EventItem :data="currentEvent.event" :eventTypes="eventTypes" />
            </div>

            <div v-if="isCurrentEventShown" class="event-popover-bg" @click="isCurrentEventShown = false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import {Calendar} from '@fullcalendar/core';
  import moment from 'moment';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import momentPlugin from '@fullcalendar/moment';
  import listPlugin from '@fullcalendar/list';
  import ruLocale from '@fullcalendar/core/locales/ru';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Vue from 'vue';
  import '@fullcalendar/core/main.css';
  import '@fullcalendar/daygrid/main.css';
  import '@fullcalendar/timegrid/main.css';
  import '@fullcalendar/list/main.css';
  import EventItem from '@/components/site/pages/events/generic/EventItem';

  export default {
    name: 'calendar-of-events',

    components: {
      Breadcrumbs,
      EventItem,
    },

    data() {
      return {
        currentEvent: {
          pageX: 300,
          pageY: 300,
        },

        isCurrentEventShown: false,
        eventsCalendar: [],
        calendar: {},
        eventItem: undefined,
        directive: 'v-click-outside',
        eventTypes: [],
      }
    },

    computed: {
      ...mapGetters({
        locale: 'getLocale',
        typeList: 'getTypeList',
      }),
    },

    methods: {
      ...mapActions({
        getEventsCalendar: 'events/getEventsCalendar',
        getTypeList: 'events/getTypeList',
      }),

      open() {
        _.map(document.getElementsByClassName('fc-event-active'),fcEvent => {
          if (fcEvent) {
            fcEvent.classList.remove('fc-event-active')
          }
        });
        this.isCurrentEventShown = true;
      },

      hide() {
        this.isCurrentEventShown = false;
        _.map(document.getElementsByClassName('fc-event-active'),fcEvent => {
          if (fcEvent) {
            fcEvent.classList.remove('fc-event-active')
          }
        });
      },

      getContentHeight() {
        return (document.getElementById('container-calendar').clientWidth / 7 * 5) - 18;
      },
    },

  mounted() {
      this.$store.commit('preloader/isLoading', true)
      window.scrollTo(0, 0);

      let calendarEl = document.getElementById('calendar');

      this.calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin ],
        locales: [ ruLocale ],

        contentHeight: this.getContentHeight(),
        fixedWeekCount: false,

        header: {
          left:   'prev,title,next',
          center: '',
          right:  '',
        },

        views: {
          dayGridMonth: {
            titleFormat: 'MMMM, YYYY',
          },
        },

        eventTimeFormat: 'hh:mm',

        eventClick: ev => {
          this.open()
          ev.el.classList.add('fc-event-active')
          this.eventItem = ev.el;
          this.currentEvent.pageX = ev.el.getBoundingClientRect().left
          this.currentEvent.pageY = ev.jsEvent.pageY + 15
          this.currentEvent.title = ev.event.title
          this.currentEvent.time = ev.event.extendedProps.time
          this.currentEvent.location = ev.event.extendedProps.location
          this.currentEvent.event = ev.event.extendedProps.event
        },
      });

      this.calendar.render();
      this.calendar.setOption('locale', this.locale);

      this.getEventsCalendar().then(res => {
        this.eventsCalendar = res.data;

        _.map(this.eventsCalendar, event => {
          this.calendar.addEvent({
            title: Vue.filter('lang')(event, 'name'),
            start: moment(`${event.start_date} ${event.start_time}`).format('YYYY-MM-DD hh:mm'),
            end: moment(`${event.start_date} ${event.end_time}`).format('YYYY-MM-DD'),

            extendedProps: {
              event,
            },

            description: event.description,
          })
        })
      }).finally(() => this.$store.commit('preloader/isLoading', false));

      this.getTypeList().then(res => {
        this.eventTypes = res
      })
    },

    watch: {
      isCurrentEventShown() {
        if (!this.isCurrentEventShown) {
          _.map(document.getElementsByClassName('fc-event-active'),fcEvent => {
            if (fcEvent) {
              fcEvent.classList.remove('fc-event-active')
            }
          });
        }
      },

      locale() {
        this.calendar.setOption('locale', this.locale);
      },
    },
  }
</script>
