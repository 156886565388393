<template>
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
               { title: $t('help'), url: '/help'},
              {title: $t('feedback') },
            ]"
        />

        <div class="container">
          <div class="row">
            <div class="feedback__wrap">
              <FeedbackForm :title="this.$t('feedback')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import FeedbackForm from '@/components/site/pages/help/generic/FeedbackForm';

  export default {
    name: 'Home',

    components: {
      Breadcrumbs,
      FeedbackForm,
    },

    mounted() {
      window.scrollTo(0, 0);
    },
  };
</script>
