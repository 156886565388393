<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">

      <div class="container">
        <div class="career__steps-wrapper">
          <router-link to="/education-in-russia/choice-of-profession" class="close-btn">
            <img alt="" src="@/assets/images/close-icon.png">
          </router-link>

          <div class="career__steps">
            <transition name="fade" mode="out-in">
              <div
                class="career__steps-item"
                :class="{ current: currentStep === 1, chosen: currentStep > 1}"
                id="levelEducation"
              >
                <p class="light">{{ $t('sTEP') }} 1</p>
                <h2 class="title-2">{{ $t('theLevelOfEducation') }}</h2>

                <div class="career__steps-content" v-if="currentStep === 1">
                  <div
                    class="career__steps-content-item"
                    v-for="(level, key) in levelEducationsData"
                    v-bind:key="key"
                  >
                    <h3 class="title-4">{{ level.name | kitCut(careerCut)  }}</h3>
                    <div class="career__steps-content-item-chose">
                      <a
                        href="javascript:void(0)"
                        @click="() => chose('levelEducation', level.id)"
                      >{{ $t('choices') }}</a>

                      <a
                        href="javascript:void(0)"
                        @click="modalDescription = level.description, targetModal(modalUniqueId)"
                      >{{ $t('moreDetails') }}</a>
                    </div>
                  </div>
                </div>

                <div class="career__steps-chosen">
                  {{ levelEducation && levelEducations.find(v =>v.value === levelEducation).name }}
                </div>
              </div>
            </transition>

            <transition name="fade" mode="out-in">
              <div
                class="career__steps-item"
                :class="{ current: currentStep === 2, chosen: currentStep > 2}"
                v-if="currentStep >= 2"
                id="areaEducation"
              >
                <p class="light">{{ $t('sTEP') }} 2</p>
                <h2 class="title-2">{{ $t('areaOfEducation') }}</h2>

                <div class="career__steps-content" v-if="currentStep === 2">
                  <div
                    class="career__steps-content-item"
                    v-for="(level, key) in areaEducations"
                    v-bind:key="key"
                  >
                    <h3 class="title-4">{{ level.name | kitCut(careerCut) }}</h3>
                    <div class="career__steps-content-item-chose">
                      <a
                        href="javascript:void(0)"
                        @click="() => chose('areaEducation', level.id)"
                      >{{ $t('choices') }}</a>

                      <a
                        href="javascript:void(0)"
                        @click="modalDescription = level.description, targetModal(modalUniqueId)"
                      >{{ $t('moreDetails') }}</a>
                    </div>
                  </div>
                </div>

                <div class="career__steps-chosen">
                  {{ areaEducation && areaEducations.find(v =>v.id === areaEducation).name }}
                </div>
              </div>
            </transition>

            <transition name="fade" mode="out-in">
              <div
                class="career__steps-item"
                :class="{ current: currentStep === 3, chosen: currentStep > 3}"
                v-if="currentStep >= 3"
                id="groupDirection"
              >
                <p class="light">{{ $t('sTEP') }} 3</p>
                <h2 class="title-2">{{ $t('trainingDirectionGroup') }}</h2>

                <div class="career__steps-content" v-if="currentStep === 3">
                  <div
                    class="career__steps-content-item"
                    v-for="(level, key) in groupDirections"
                    v-bind:key="key"
                  >
                    <h3 class="title-4">{{ level.name | kitCut(careerCut) }}</h3>
                    <div class="career__steps-content-item-chose">
                      <a
                        href="javascript:void(0)"
                        @click="() => chose('groupDirection', level.id)"
                      >{{ $t('choices') }}</a>

                      <a
                        href="javascript:void(0)"
                        @click="modalDescription = level.description, targetModal(modalUniqueId)"
                      >{{ $t('moreDetails') }}</a>
                    </div>
                  </div>
                </div>

                <div class="career__steps-chosen">
                  {{ groupDirection && groupDirections.find(v =>v.id === groupDirection).name }}
                </div>
              </div>
            </transition>
          </div>

          <transition name="fade" mode="out-in">
            <div class="career__steps-offer-wrapper current" v-if="currentStep === 4" id="educationPrograms">
              <div class="career__steps-offer">
                <div
                  class="career__steps-offer-item"
                  v-for="(educationProgram, key) in educationPrograms"
                  v-bind:key="key"
                >
                  <h5 class="title-5">{{ educationProgram.name | kitCut(careerCutMin) }}</h5>
                  <div class="career__steps-offer-content">
                    <div>
                      <p>
                        {{ $t('afterTrainingForThisProgramYouCanBecome') }}:
                      </p>
                      <ul class="list-disc">
                        <li 
                          v-for="(profession, key) in educationProgram.professions"
                          v-bind:key="key"
                          v-if="profession.profession"
                        >
                          {{ profession.profession.name }}
                        </li>
                      </ul>
                    </div>

                    <div>
                      <span @click="() => setEducationProgram(educationProgram.id, '/education-in-russia/programs')">
                        <a
                                class="link"
                                href="javascript:void(0)"
                        >{{ $t('chooseProgram') }}</a>
                      </span>

                      <span @click="() => setEducationProgram(educationProgram.id, '/education-in-russia/universities')">
                        <a
                                class="link"
                                href="javascript:void(0)"
                        >{{ $t('chooseUniversity') }}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row__btn" v-if="currentStep === 4">
                <a class="btn" href="javascript:void(0)" @click="printDiv">{{ $t('print') }}</a>
              </div>
            </div>
          </transition>
        </div>
      </div>

    </div>

    <Modal
      :unique-id="modalUniqueId"
      ref="modal"
    >
      <div slot="body">
        <p>
          {{ modalDescription }}
        </p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => targetModal(modalUniqueId)"
        >{{ $t('close') }}</a>
      </div>
    </Modal>
  </div>
</template>


<script>
  import {mapActions, mapGetters} from 'vuex';
  import {helpers} from '@/_services';
  import Modal from '@/components/generic/modal/Modal';

  export default {
    name: 'MyCareer',

    props: {
      careerCut: {type: Number, default: function() { return 95; }},
      careerCutMin: {type: Number, default: function() { return 60; }},
    },

    components: {
      Modal,
    },

    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
      }),
    },

    data() {
      return {
        levelEducations: [],
        levelEducationsData: [],
        levelEducation: null,
        areaEducations: [],
        areaEducation: null,
        groupDirections: [],
        groupDirection: null,
        educationPrograms: [],
        currentStep: 1,
        modalUniqueId: 'modal-details',
        modalDescription : '',
      }
    },

    methods: {
      ...mapActions({
        getLevelEducation: 'getLevelEducation',
        getEducationFilter: 'getEducationFilter',
        getEducationPrograms: 'educationInRussia/getEducationPrograms',
        getMyCareersDownload: 'educationInRussia/getMyCareersDownload',
      }),

      chose(type, value) {
        if (type === 'levelEducation') {
          this[type] = value;
          this.currentStep = 2;
        }

        if (type === 'areaEducation') {
          this[type] = value;
          this.currentStep = 3;
        }

        if (type === 'groupDirection') {
          this[type] = value;
          this.currentStep = 4;
        }
      },

      updateFilters(scrollTo) {
        this.$store.commit('preloader/isLoading', true)
        this.getEducationFilter({
          levelId: this.levelEducation,
          areaId: this.areaEducation,
          directionId: this.groupDirection,
        }).then(res => {
          if (this.currentStep === 2) {
            this.areaEducations = res.data.education_area
          }

          if (this.currentStep === 3) {
            this.groupDirections = res.data.education_direction
          }

          this.$scrollTo(`#${scrollTo}`, {duration: 500})
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      educationProgramsData(scrollTo) {
        this.$store.commit('preloader/isLoading', true)
        this.getEducationPrograms({
          eduLvl: this.levelEducation,
          eduArea: this.areaEducation,
          eduDirection: this.groupDirection,
        }).then(res => {
          if (this.currentStep === 4) {
            this.educationPrograms = res.data;

            // programs
            this.$store.commit('form/updateField', {model: 'filter.programs.main.levelEducation', value: this.levelEducation});
            this.$store.commit('form/updateField', {model: 'filter.programs.main.areaEducation', value: this.areaEducation});
            this.$store.commit('form/updateField', {model: 'filter.programs.main.groupDirection', value: this.groupDirection});
            this.$store.commit('form/updateField', {model: 'filter.programs.main.scientificAreas', value: this.scientificAreas});
            this.$store.commit('form/updateField', {model: 'filter.programs.main.scientificSpecialties', value: this.scientificSpecialties});
            this.$store.commit('form/updateField', {model: 'filter.programs.additional.groupDirection', value: this.groupDirection});

            // universities
            this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: this.groupDirection});
            this.$store.commit('form/updateField', {model: 'filter.universities.main.scientificSpecialties', value: this.scientificSpecialties});
            this.$store.commit('form/updateField', {model: 'filter.universities.additional.groupDirection', value: this.groupDirection});
          }

          this.$scrollTo(`#${scrollTo}`, {duration: 500})
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      setEducationProgram(educationProgram, redirectTo) {
        // programs
        this.$store.commit('form/updateField', {model: 'filter.programs.main.educationalProgram', value: educationProgram});
        this.$store.commit('form/updateField', {model: 'filter.programs.main.groupDirection', value: this.groupDirection});
        this.$store.commit('form/updateField', {model: 'filter.programs.main.levelEducation', value: this.levelEducation});
        this.$store.commit('form/updateField', {model: 'filter.programs.main.scientificAreas', value: this.scientificAreas});
        this.$store.commit('form/updateField', {model: 'filter.programs.main.scientificSpecialties', value: this.scientificSpecialties});


        // universities
        this.$store.commit('form/updateField', {model: 'filter.universities.main.educationalProgram', value: educationProgram});
        this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: this.groupDirection});
        this.$store.commit('form/updateField', {model: 'filter.universities.main.levelEducation', value: this.levelEducation});
        this.$store.commit('form/updateField', {model: 'filter.universities.main.scientificSpecialties', value: this.scientificSpecialties});
        this.$store.commit('form/updateField', {model: 'filter.universities.additional.groupDirection', value: this.groupDirection});

        this.$router.push(redirectTo);
      },

      myCareersDownload() {
        this.$store.commit('preloader/isLoading', true)
        this.getMyCareersDownload({
          eduLvl: this.levelEducation,
          eduArea: this.areaEducation,
          eduDirection: this.groupDirection,
        }).then(res => {
          window.open(res.application, '_blank');
        }).finally(() => this.$store.commit('preloader/isLoading', false))
      },

      printDiv() {
        window.print();
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },
    },

  mounted() {
      this.$store.commit('preloader/isLoading', true)
      this.getLevelEducation().then(res => {
        this.levelEducations = helpers.options(res);
        this.levelEducationsData = res;
      }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    watch: {
      levelEducation() {
        this.updateFilters('areaEducation');
      },

      areaEducation() {
        this.updateFilters('groupDirection');
      },

      groupDirection() {
        this.educationProgramsData('educationPrograms');
      },
    },
  };
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
</style>
