<script>
  export default {
    name: 'Meta',

    props: {
      description: String,
      keywords   : String,
      title      : String,
    },

    watch: {
      title: {
        handler() {
          document.title = this.title || 'Образование в России для Иностранцев | Official website for the selection of foreign citizens to study in Russia';
          document.description = this.description || 'Education In Russia - удобный способ подать заявку из любой точки мира и пройти этапы отбора для поступления в учебные заведения России. | This official website allows international students to apply for studies in Russia and check the status of admissions online.';
          document.keywords = this.keywords || `Учеба в России, Государственная информационная система Образование в Российской Федерации для иностранцев, 
ГИС ОРФИ, Суперсервис Образование в Российской Федерации для Иностранцев, Суперсервис, Обучение в России, Хочу учиться в России, Education in Russia, учеба в россии 
студенты, учеба вузы россии, учеба в россии, организация учебы в россии, учеба россия, иностранные студенты учеба в россии, учеба в университете в россии, иностранные 
студенты вернуться в россию на учебу, Study in Russia, Education in the Russian Federation for Foreigners, Superservice, I want to study in Russia, Education in Russia, 
study in Russia for students, study in Russian universities, study in Russia, organization of study in Russia, Russia education, foreign students go to study in Russia, 
study at a university in Russia, foreign students return to Russia to study`;
        },

        immediate: true,
      },
    },

    render() {
      return null;
    },
  };
</script>
