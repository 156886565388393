<template>
  <div class="container">
    <div class="universities__info">
      <div class="row">
        <div class="col-4">
          <h2 class="title-2">{{ $t('generalInformation') }}</h2>
          <div v-html="university.description" />
        </div>
      </div>

      <h2 class="title-2" v-if="university.ratings.length">{{ $t('ratings') }}</h2>

      <div class="row" v-if="university.ratings.length">
        <div class="col-6">
          <div class="nav-tabs-sub-wrap-univers">
            <ul class="nav-tabs-sub">
              <li class="nav-item-sub">
                <a
                  :class="{ active: activeSubTab === 'item-sub3' }"
                  @click.prevent="setActiveSubTab('item-sub3')"
                  class="nav-link-sub"
                  href="#item-sub3"
                  v-if="this.getRatings(false).length"
                >{{ $t('internationalRatings') }}</a>
              </li>

              <li class="nav-item-sub">
                <a
                  :class="{ active: activeSubTab === 'item-sub4' }"
                  @click.prevent="setActiveSubTab('item-sub4')"
                  class="nav-link-sub"
                  href="#item-sub4"
                  v-if="this.getRatings(true).length"
                >{{ $t('nationalRatings') }}</a>
              </li>
            </ul>
          </div>

          <div class="tab-content" v-if="university.ratings.length">
            <div
              :class="{ 'active show': activeSubTab === 'item-sub3' }"
              class="tab-pane" id="item-sub3"
              v-if="getRatings(false).length"
            >
              <table class="table">
                <thead>
                <tr>
                  <th>{{ $t('year') }}</th>
                  <th>{{ $t('ratingName') }}</th>
                  <th>{{ $t('aPlace') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(rating, key) in getRatings(false)"
                  v-bind:key="key"
                  v-show="(key < 5 || showAllRating) && !rating.rating.is_national"
                >
                  <td>{{ rating.year }}</td>
                  <td>{{ rating.rating.name }}</td>
                  <td>{{ rating.position }}</td>
                </tr>
                </tbody>
              </table>

              <a
                class="link"
                href="javascript:void(0)"
                v-if="getRatings(false).length >= 5 && !showAllRating"
                @click="setShowAllRating(true)"
              >{{ $t('showAll') }}</a>

              <a
                class="link"
                href="javascript:void(0)"
                v-if="getRatings(false).length >= 5 && showAllRating"
                @click="setShowAllRating(false)"
              > {{ $t('collapse') }}</a>
            </div>


            <div :class="{ 'active show': activeSubTab === 'item-sub4' }" class="tab-pane" id="item-sub4">
              <table class="table">
                <thead>
                <tr>
                  <th>{{ $t('year') }}</th>
                  <th>{{ $t('ratingName') }}</th>
                  <th>{{ $t('aPlace') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(rating, key) in getRatings(true)"
                  v-bind:key="key"
                  v-show="(key < 5 || showAllRating)"
                >
                  <td>{{ rating.year }}</td>
                  <td>{{ rating.rating.name }}</td>
                  <td>{{ rating.position }}</td>
                </tr>
                </tbody>
              </table>

              <a
                class="link"
                href="javascript:void(0)"
                v-if="getRatings(true).length >= 5 && !showAllRating"
                @click="showAllRating = true"
              >{{ $t('showAll') }}</a>

              <a
                class="link"
                href="javascript:void(0)"
                v-if="getRatings(true).length >= 5 && showAllRating"
                @click="showAllRating = false"
              > {{ $t('collapse') }}</a>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row" v-if="university.for_foreign_students">
        <div class="col-6">
          <h2 class="title-2 mb32">
            {{ $t('studentsByCountry') }}
          </h2>
          <form class="row mb0">
            <MultiSelect
              :options="formattedCountries"
              store-path="university.country"
              :placeholder="$t('country')"
              class="col-2"
            />
          </form>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('country') }}</th>
                <th>{{ $t('studentsByCountryCount') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2">
                  <div data-scroll="y">
                    <table>
                      <tbody>
                        <tr
                          v-for="({country, number_of_students_in_country}, key) in filterTable"
                          :key="key"
                        >
                          <td>{{ country && country.name }}</td>
                          <td>{{ number_of_students_in_country }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
            <div class="row" v-if="university.for_foreign_students">
              <div class="col-6">
                <h2 class="title-2 mb32">
                  {{ $t('studentsByCountry') }}
                </h2>
                <form class="row mb0 form-row">
                  <div class="form-row">
                    <multiselect 
                    :options="formattedCountries" 
                    v-model="selectedCountry" 
                    track-by="name" 
                    label="name"
                    :placeholder="$t('country')" 
                    class="col-6 arrow">
                    </multiselect>
                  </div>
                </form>
                <div class="students__container" data-scroll="y">
                  <table class="students__table">
                    <tbody>
                      <tr class="students__table-head">
                        <th>{{ $t('country') }}</th>
                        <th>{{ $t('studentsByCountryCount') }}</th>
                        <th>Количество выпустившиxся студентов</th>
                        <th>Количество отчисленных студентов</th>
                      </tr>
                      <tr v-for="(
                                {
                                  country,
                                  number_of_students_in_country,
                                  number_graduated_students,
                                  number_expelled_students,
                                },
                                key
                              ) in filterTable" :key="key">
                        <td>{{ country && country.name }}</td>
                        <td>{{ number_of_students_in_country }}</td>
                        <td>{{ number_graduated_students }}</td>
                        <td>{{ number_expelled_students }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


      <div class="row" v-if="university.for_foreign_students">
        <div class="col-4">
          <h2 class="title-2 mb32">
            {{ $t('conditionsForForeigners') }}
          </h2>

          <div v-html="university.for_foreign_students" />
        </div>
      </div>

      <div class="row mb32" v-if="university.dormitory_description">
        <div class="col-6">
          <h2 class="title-2">
            {{ $t('dormitories') }}
          </h2>
        </div>

        <div class="col-4" v-html="university.dormitory_description" />

        <div class="col-2">
          <div class="mb32">
            <h4 class="title-sub light">{{ $t('dormitoryCost') }}</h4>
            <h4 class="title-sub">
              {{ $t('from') }} {{ university.dorm_cost_min | handlerEmpty }} {{ $t('_to') }} 
              {{ university.dorm_cost_max | handlerEmpty | declOfNum([$t('ruble'), $t('__rubles'), $t('_rubles')]) }}
              {{ $t('_perMonth') }}
            </h4>
          </div>
          <div>
            <h4 class="title-sub light">{{ $t('theNumberOfPeopleInTheRoom') }}</h4>
            <h4 class="title-sub">{{ university | lang('roommate_count') }}</h4>
          </div>
        </div>
      </div>

      <swiper :options="swiperOption" class="swiper slider-photo" v-if="university.dormitory_medias.length">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <swiper-slide
          v-for="(dormitory, key) in university.dormitory_medias"
          v-bind:key="`${key}-dormitory`"
          class="university-media-slide"
        >
          <img alt="" v-if="dormitory.file_type === 0" :src="dormitory.path" />
        </swiper-slide>
      </swiper>

      <div class="row" v-if="university.international_relation">
        <div class="col-4">
          <h2 class="title-2">
            {{ $t('theInternationalCooperation') }}
          </h2>

          <div v-html="university.international_relation" />
        </div>
      </div>

      <h2 class="title-2" v-if="university.medias.length">
        {{ $t('photoAndVideo') }}
      </h2>

      <swiper :options="swiperOption" class="swiper slider-photo" v-if="university.medias.length">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>

        <swiper-slide
          v-for="(media, key) in university.medias"
          v-bind:key="`${key}-media`"
          class="university-media-slide"
        >
          <img alt="" v-if="media.file_type === 0" :src="media.path" width="auto" />
          <div id="1111111111111" style="height: 300px !important">
            <video-player
              v-if="media.file_type === 1"
              class="video-player-box"
              ref="videoPlayer"
              :options="{
                muted: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [{
                  type: `video/${media.ext}`,
                  src: media.path,
                }],
              }"
              :playsinline="true"
              customEventName="customstatechangedeventname"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {videoPlayer} from 'vue-video-player';

//import MultiSelect from '@/components/generic/form/field/MultiSelect';
import 'video.js/dist/video-js.css';
import 'swiper/css/swiper.css';
import {mapGetters} from 'vuex';

export default ({  
  name: 'infomartion-university',
  props: {
    university: Object,
    setActiveSubTab: Function,
    setShowAllRating: Function,
    getRatings: Function,
    activeSubTab: String,
    showAllRating : Boolean,
  },
  components: {
    Swiper,
    SwiperSlide,
    videoPlayer,
    
    //MultiSelect,
  },
  data: () => ({
    selectedCountry: {value: 0, name: 'Все'},
    swiperOption: {
      slidesPerView: 2,
      spaceBetween: 32,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 8,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 22,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      getDataCountries: 'getDataCountries',
    }),

    formattedCountries() {
      let array = [{value: 0, name: 'Все'}]
      const {students_countries} = this.university
      students_countries.map(country => {
        array.push({value: country.id, name: country.country.name})
      })
      return array
    },


    filterTable: {
      get: function() {
        const {students_countries} = this.university
        return this.selectedCountry.value === 0
          ? students_countries
          : students_countries.filter(
            country => country.id === this.selectedCountry.value,
          )
      },
    },
  },
})
</script>

<style lang="scss">
.University {
    .swiper-slide {
      img {
        height: 100%;
        width: auto !important;
      }
      width: auto !important;
      .video-player {
        & > * {
          &:first-child {
            width: 533px;
          }
          height: 100% !important;
        }
      }
    }
    .university-media-slide {
      height: 300px !important;
    }
  }
    .arrow .multiselect__select {
      right: 15px;
      top: 2px;
    }
</style>
