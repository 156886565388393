<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
               { title: $t('help'), url: '/help'},
              {title: $t('frequentlyAskedQuestions') },
            ]"
        />

        <h1 class="title-1 mb32">{{ $t('frequentlyAskedQuestions') }}</h1>

        <div class="def-container faq__wrapper" v-if="faqs.length">
          <badger-accordion>
            <badger-accordion-item
              v-for="(faq, key) in faqs"
              v-bind:key="key"
            >
              <template slot="header">{{ faq | lang('name') }}</template>
              <template slot="content">
                <div class="row">
                  <div class="col-6 splited-text">
                    {{ splitText(faq.content) }}
                  </div>
                </div>
              </template>
            </badger-accordion-item>
          </badger-accordion>
        </div>

        <div class="def-container">
          <p>{{ $t('faqInformation') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .splited-text {
    text-indent: 20px;
    white-space: pre-wrap;
  }
</style>

<script>
  import {mapActions} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'help-faq',

    components: {
      Breadcrumbs,
    },

    computed: {},

    data() {
      return {
        faqs: [],
      }
    },

    methods: {
      ...mapActions({
        getFaq: 'help/getFaq',
      }),

      splitText(str) {
        return str.split('\n\n').join('\n\n     ')
      },

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getFaq()
          .then(res => {
            if (res.status === 'success') {
              this.faqs = res.data;
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      window.scrollTo(0, 0);
      this.getData();
    },
  };
</script>
