<template>
  <div class="container">
    <div class="universities__info">
      <div class="row">
        <div class="col-6">
          <h2 class="title-2">
            {{ $t('activity') }}
          </h2>
          <table class="table-grid news-table-full" data-row-select="odd">
            <thead>
              <tr>
                <th>{{ $t('eventTitle') }}</th>
                <th>{{ $t('location') }}</th>
                <th>{{ $t('format') }}</th>
                <th>{{ $t('typeEvent') }}</th>
                <th>{{ $t('dateOfEvent') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, key) in dataEvents"
                v-bind:key="key"
                v-show="eventsShownAll || key < 3"
              >
                <td>{{ event | lang('name') }}</td>
                <td>{{ event | lang('location') }}</td>
                <td>
                  {{
                    event.is_online
                      | handlerBoolean($t('online'), $t('offline'))
                  }}
                </td>
                <td>{{ event.type && event.type.name }}</td>
                <td>
                  <p>
                    {{ event.start_date | date}} {{ event.start_time | time }} {{event.gmt && `(GMT ${event.gmt.name})` }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="news-tables">
            <div
              class="news-table"
              v-for="(event, key) in dataEvents"
              v-bind:key="key"
              v-show="eventsShownAll || key < 3"
            >
              <div class="news-table-title">{{ $t('eventTitle') }}</div>
              <div class="news-table-content">
                {{ event | lang('name') }}
              </div>
              <div class="news-table-title">{{ $t('location') }}</div>
              <div class="news-table-content">
                {{ event | lang('location') }}
              </div>
              <div class="news-table-title">{{ $t('format') }}</div>
              <div class="news-table-content">
                {{
                  event.is_online | handlerBoolean($t('online'), $t('offline'))
                }}
              </div>
              <div class="news-table-title">{{ $t('typeEvent') }}</div>
              <div class="news-table-content">
                {{ event.type && event.type.name }}
              </div>
              <div class="news-table-title">{{ $t('dateOfEvent') }}</div>
              <div class="news-table-content">
                {{ event.start_date | date }} {{ event.start_time | time }}
                {{ event.gmt && `(GMT ${event.gmt.name})` }}
              </div>
            </div>
          </div>
          <a
            class="link"
            href="javascript:void(0)"
            v-if="dataEvents.length > 3 && !eventsShownAll"
            @click="eventsShownAll = true"
            >{{ $t('showAll') }}</a
          >

          <a
            class="link"
            href="javascript:void(0)"
            v-if="dataEvents.length > 3 && eventsShownAll"
            @click="eventsShownAll = false"
            >{{ $t('collapse') }}</a
          >
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h2 class="title-2">
            {{ $t('news') }}
          </h2>

          <table class="table var2 news-table-full" data-row-select="odd">
            <thead>
              <tr>
                <th>{{ $t('titleNews') }}</th>
                <th>{{ $t('description') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(news, key) in dataNews"
                v-bind:key="key"
                v-show="newsShownAll || key < 3"
              >
                <td>
                  <a class="link" :href="news.website">{{
                    news | lang('name')
                  }}</a>
                </td>
                <td v-html="news.description"></td>
              </tr>
            </tbody>
          </table>
          <div class="news-tables">
            <div
              class="news-table"
              v-for="(news, key) in dataNews"
              v-bind:key="key"
              v-show="newsShownAll || key < 3"
            >
              <div class="news-table-title">{{ $t('titleNews') }}</div>
              <div class="news-table-content">
                <a class="link" :href="news.website">{{
                  news | lang('name')
                }}</a>
              </div>
              <div class="news-table-title">{{ $t('description') }}</div>
              <div class="news-table-content" v-html="news.description"></div>
            </div>
          </div>
          <a
            class="link"
            href="javascript:void(0)"
            v-if="dataNews.length > 3 && !newsShownAll"
            @click="newsShownAll = true"
            >{{ $t('showAll') }}</a
          >

          <a
            class="link"
            href="javascript:void(0)"
            v-if="dataNews.length > 3 && newsShownAll"
            @click="newsShownAll = false"
            >{{ $t('collapse') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'events-news-university',
  props: {
    dataNews: Array,
    dataEvents: Array,
  },
  data: () => ({
    eventsShownAll: false,
    newsShownAll: false,
  }),
}
</script>
