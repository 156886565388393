<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('news'), url: '/news' },
              {title: filterLang(news, 'name') || news.title },
            ]"
        />

        <h1 class="title-1 mb20">{{ news | lang('name') }}</h1>

        <!--  Дата новости  -->
        <p class="light mb32">{{ moment(news.created_at).format('DD.MM.YY') }}</p>
        <!--  Дата новости  -->

        <div class="news-container" v-if="news.id">
          <div class="row">
            <div class="col-4">
              <img v-if="news.cover" :src="news.cover" />


              <div class="event-wrapper news-event" v-if="news.news_to_event">
                <EventItem :data="news.news_to_event.event" />

                <div class="news-event-button" v-if="timeDiff >= 0">
                  <a class="btn" @click="() => evensParticipate(news.news_to_event.event.id)">{{ $t('addToPersonalCalendar') }}</a>
                </div>
              </div>
            </div>

            <div class="col-4" v-html="modifyUrl(filterLang(news, 'content'))"/>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :unique-id="modalUniqueId"
      ref="modal"
    >
      <div slot="body">
        <h2 class="title-2">{{ $t('eventAdded') }}</h2>
        <p>{{ $t('eventSuccessfullyAddedToYourCalendar') }}!</p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => targetModal(modalUniqueId)"
        >{{ $t('close') }}</a>
      </div>
    </Modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Vue from 'vue';
  import EventItem from '@/components/site/pages/events/generic/EventItem';
  import Modal from '@/components/generic/modal/Modal';
  import moment from 'moment';

  export default {
    name: 'news-single',

    data() {
      return {
        news: {},
        filterLang: Vue.filter('lang'),
        modalUniqueId  : 'modal-news',
        timeDiff: false,
        moment: moment,
      }
    },

    components: {
      Breadcrumbs,
      EventItem,
      Modal,
    },

    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
      }),
    },

    methods: {
      ...mapActions({
        getNewsSingle: 'news/getNewsSingle',
        setEventsParticipate: 'events/setEventsParticipate',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getNewsSingle({slug: this.$route.params.slug})
          .then(res => {
            if (res.status === 'success') {
              this.news = res.data;

              _.each(this.news.translations, (t, k) => {
                if (t.content) {
                  this.news.translations[k].content = t.content.replace('oembed', 'iframe');
                  this.news.translations[k].content = t.content.replace('url', 'src');
                  this.news.translations[k].content = t.content.replace('watch?v=', 'embed/');
                  this.news.translations[k].content = t.content.replace('oembed', 'iframe');
                }
              })

              if (this.news.news_to_event) {
                this.timeDiff = moment(this.news.news_to_event.event.start_date).diff(moment(), 'day');
              }
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
      modifyUrl(content) {
        if (content.includes('<figure class="media">')) 
          {
            let result = content.split('oembed').join('iframe').split('url').join('src').split('youtu.be').join('youtube.com/embed').split('watch?v=').join('embed/').replace(/(&)[^"]*/g, '$1').replace('&', '');
            return result;
          }  
        else 
          return content;
      },
      evensParticipate(id) {
        this.$store.commit('preloader/isLoading', true)
        this.setEventsParticipate({
          event_id: id,
        }).then(() => {
          this.targetModal(this.modalUniqueId);
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },
    },

    mounted() {
      window.scrollTo(0, 0);
      this.getData();
    },
  }
</script>
