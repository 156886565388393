<template>
  <div class="def-container">
    <ul class="aside__menu">
      <li>
        <a class="notify"
          :href="user.org_id === '00' ? '/settings/profile/notification' : '/settings/notifications'"
        >
          <span class="notify-count" v-if="user.unread_notification_count > 0">
            {{user.unread_notification_count}}
          </span>
          <img alt="" src="@/assets/images/icon/ic-notification.svg">{{ $t('notifications') }}
        </a>
      </li>
      <li>
        <router-link
          to="/account/favorites"
        ><img alt="" src="@/assets/images/icon/ic-favorite.svg">{{ $t('favorites') }}</router-link>
      </li>
      <li>
        <router-link
          to="/account/choices"
        ><img alt="" src="@/assets/images/icon/ic-star.svg">{{ $t('theChoice') }}</router-link>
      </li>
      <li>
        <a 
          href="/settings/profile/calendar"
        ><img alt="" src="@/assets/images/icon/ic-calendar.svg">{{ $t('calendar') }}</a>
      </li>

      <li>
        <a
          href="/settings/profile"
        >{{ $t('profile2') }}</a>
      </li>
      <li>
        <a
          :href="user.org_id === '00' ? '/settings/profile/applications' : '/settings/applications-register'"
        >{{ $t('myApplications') }}</a>
      </li>
      <li>
        <a
          :href="user.org_id === '00' ? '/settings/profile/screening-test' : '/settings/screening-test'"
        >{{ $t('screeningTests') }}</a>
      </li>
      <li>
        <a
          href="/settings/profile/appeals"
        >{{ $t('appeals') }}</a>
      </li>
      <li :hidden="true">
        <a
          :href="user.org_id === '00' ? '/settings/profile/services' : '/settings/services'"
        >{{ $t('servicesAndFacilities') }}</a>
      </li>
      <!-- <li :hidden="user.org_id === '01'">
        <a
          href="/settings/appeals-user"
        >{{ $t('handling') }}</a>
      </li> -->
      <li>
        <a
          @click="logout"
          href="#"
        ><img src="@/assets/images/icon/ic-exit.svg" alt="">{{ $t('logout') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  
  export default {
    name: 'account-profile-menu-left',
    
    computed: {
      ...mapGetters({
        isAuth: 'users/isAuth',
        user: 'users/getUser',
        getLocale: 'getLocale',
      }),
    },

    methods: {
      logout() {
        this.$store.commit('users/logout');
      },
    },
  }
</script>
