export default {
    russian: "Русский",
    english: "English",
    arab: "العربية",
    chinese: "中文, 汉语",
    spanish: "Español",
    french: "Français",
    portuguese: "Português",
    indian: "हिन्दी",
    vietnamese: "Tiếng Việt",
    malay: "Melayu",
    indomelayu: "Indo-Melayu",
    turkish: " Türk",
    persian: "فارسی",
    uzbek: "O‘zbek",
    german: "Deutsch",
    mongolian: "Монгол",
    help: "Assistance",
    personalArea: "Compte personnel",
    myApplications: "Mes candidatures",
    servicesAndFacilities: "Services et fonctionnalités",
    logout: "Quitter",
    favorites: "Favori",
    theChoice: "Sélection",
    notifications: "Notifications",
    logIn: "Se connecter",
    quickAccessThroughSocialNetworks: "Connexion rapide via les réseaux sociaux",
    enterYourEmail: "Entrer votre e-mail",
    password: "Mot de passe",
    enterPassword: "Entrer le mot de passe",
    rememberMe: "Se souvenir de moi",
    forgotYourPassword: "Mot de passe oublié ?",
    noAccount: "Pas de compte ?",
    registration: "Enregistrement",
    countryOfCitizenship: "Pays de citoyenneté",
    chooseYourCountryOfCitizenship: "Sélectionner le pays de votre citoyenneté",
    iHaveReadAndAgree: "J'ai lu et j'accepte les conditions",
    _toTheProcessingOfMyPersonalData: "de traitement de mes données personnelles",
    iAgreeTo: "J'accepte",
    _theUseOfCookies: "l'utilisation de cookies",
    signUp: "Enregistrer",
    haveAnAccount: "Vous avez un compte ?",
    mainPage: "Page d'accueil",
    frequentlyAskedQuestionsAnswers: "Réponses aux questions fréquemment posées",
    goTo: "Aller",
    startTesting: "Commencer les tests",
    buildCareerVector: "Construire un vecteur de carrière",
    forum: "Forum",
    textForumBanner:
        "Sur le forum général, vous pouvez discuter de tous les sujets qui sont importants pour vous : à quoi ressemble la vie dans les villes russes, comment se déroulent les cours dans les universités russes, etc.",
    feedback: "Commentaires",
    technicalSupportFeedbackForm:
        "Formulaire pour rejoindre l’assistance technique",
    allCities: "Toutes les villes",
    citiesOfRussia: "Villes de Russie",
    educationInRussia: "Étudier en Russie",
    educationalOrganizations: "Universités",
    educationInRussiaYourKeyToSuccess:
        "Étudier en Russie : la clé de votre succès",
    applyForStudyInRussia: "Soumettez votre candidature pour étudier en Russie",
    apply: "Soumettre votre candidature",
    logInOrRegisterToAccessCareerGuidanceAndApply:
        "Connectez-vous ou inscrivez-vous pour accéder aux informations d'orientations professionnelles et pour soumettre votre candidature",
    aboutOrganization: "Sur l'organisation",
    eventsAndOlympiads: "Événements et olympiades",
    educationalPrograms: "Programmes",
    contacts: "Contacts",
    inFavorites: "Dans les favoris",
    toFavorites: "Aux favoris",
    fromChoices: "Sélectionné",
    choices: "Sélectionner",
    generalInformation: "Informations générales",
    internationalRatings: "Classements internationaux",
    nationalRatings: "Classements nationaux",
    ratings: "Classements",
    year: "Année",
    ratingName: "Nom du classement",
    aPlace: "Place",
    screeningTests: "Épreuves éliminatoires",
    choiceOfProfession: "Choisir une profession",
    lifeInRussia: "Vivre en Russie",
    russianLanguage: "Langue russe",
    livingConditions: "Conditions de vie",
    cultureAndLeisure: "Loisirs et divertissements",
    safety: "Sécurité",
    benefitsForInternationalStudents: "Privilèges pour les étudiants étrangers",
    workForInternationalStudents: "Emplois pour étudiants étrangers",
    studentVisaAndRegistration: "Visa d'étudiant et enregistrement",
    healthInsurance: "Assurance maladie",
    howToEnter: "Comment être admis",
    educationLevels: "Niveaux d'enseignement",
    admissionProcess: "Procédure d'admission",
    olympics: "Olympiades",
    officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
        "Site officiel de la sélection des citoyens étrangers pour étudier en Russie, {year}",
    whyRussia: "Pourquoi la Russie",
    "10reasonsToStudyInRussia": "10 raisons d'étudier en Russie",
    reviewsOfForeignStudents: "Commentaires d'étudiants étrangers",
    onlineMonitoring: "Surveillance en ligne",
    mainPrograms: "Programmes principaux",
    additionalPrograms: "Programmes complémentaires",
    chooseCounty: "Sélectionnez un district",
    county: "District",
    chooseACity: "Sélectionnez une ville",
    city: "Ville",
    chooseYourLevelOfEducation: "Sélectionnez votre niveau d'études",
    chooseYourAreaOfScientificKnowledge: "Choisissez un domaine de connaissances scientifiques",
    chooseYourScientificSpecialtyProfile: "Choisir un profil d'une spécialité scientifique",
     areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
    theLevelOfEducation: "Niveau d'études",
    chooseAGroupOfTrainingAreas:
        "Sélectionnez un groupe de programmes de formation",
    trainingDirectionGroup: "Groupe de programmes de formation",
    chooseATrainingDirection: "Sélectionnez un programme de formation",
    trainingDirection: "Programme de formation",
    chooseRatings: "Sélectionnez des classements",
    thePresenceOfThePreparatoryFaculty: "Présence d'une faculté préparatoire",
    show: "Afficher",
    _university: "université",
    _universities: "universités",
    __universities: "universités",
    clearAllFilters: "Réinitialiser tous les filtres",
    chooseSemester: "Sélectionnez un semestre",
    semester: "Semestre",
    enterTheNameOfTheEducationalOrganization: "Saisissez le nom de l'université",
    _educationalOrganization: "université",
    _educationalOrganizations: "universités",
    __educationalOrganizations: "universités",
    chooseYourAreaOfEducation: "Sélectionnez un domaine de formation",
    areaOfEducation: "Domaine de formation",
    chooseYourLanguageOfInstruction: "Sélectionnez la langue d'enseignement",
    languageOfInstruction: "Langue d'enseignement",
    _program: "programme",
    _programs: "programmes",
    __programs: "programmes",
    formOfTraining: "Format d'enseignement",
    level: "Niveau",
    typeOfTraining: "Type d'enseignement",
    free: "Gratuit",
    paid: "Payant",
    tuitionFees: "Coût de l'enseignement payant",
    established: "Date de fondation",
    numberOfStudents: "Nombre d'étudiants",
    _students: "d'étudiants",
    numberOfQuotaPlaces: "Nombre de places gratuites (quotas)",
    _for: "à",
    numberOfInternationalStudents: "Étudiants étrangers",
    _educationalPrograms: "programmes de formation",
    moreDetails: "Détails",
    sTEP: "ÉTAPE",
    afterTrainingForThisProgramYouCanBecome:
        "Après avoir terminé ce programme, vous pouvez devenir",
    chooseProgram: "Sélectionner un programme",
    chooseUniversity: "Sélectionner une université",
    print: "Imprimer",
    save: "Sauvegarder",
    programs: "Programmes",
    selectedProgram: "Programme sélectionné",
    noProgramSelected: "Aucun programme sélectionné",
    selectedEducationalOrganizations: "Universités sélectionnées",
    educationalOrganizationNotSelected: "Aucune université sélectionnée",
    representation: "Représentation",
    programDescription: "Description du programme",
    programPageOnTheUniversityWebsite:
        "Page du programme sur le site Web de l'université",
    programCurator: "Responsable du programme",
    disciplines: "Matières",
    theProcessOfEnteringARussianEducationalOrganization:
        "Processus d'admission dans une université russe",
    chooseAProgramAndUpTo6EducationalOrganizations:
        "Choisissez un programme et jusqu'à 6 universités",
    takeTheCompetitiveSelectionInYourCountry:
        "Passez par le processus de sélection dans votre pays",
    fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
        "Remplissez le formulaire de candidature en ligne et joignez-y les documents nécessaires",
    getAnInvitationToStudAnStudyVisa:
        "Obtenez une invitation à étudier et un visa d'études",
    preparatoryFaculties: "Facultés préparatoires",
    summerAndWinterSchools: "Cours d'été et d'hiver",
    russianLanguageCourses: "Cours de langue russe",
    tutorsTheRussianLanguage: "Professeurs de langue russe",
    certificationInRussian: "Attestation de maîtrise du russe",
    news: "Actualités",
    allNews: "Toutes les actualités",
    reviewsOfStudentsAndGraduates: "Commentaires des étudiants et des diplômés",
    areYouSureYouWantToWithdrawTheApplication:
        "Voulez-vous vraiment retirer votre candidature ?",
    withdrawApplication: "Retirer une candidature",
    jan: "Janvier",
    feb: "Février",
    mar: "Mars",
    apr: "Avril",
    may: "Mai",
    jun: "Juin",
    jul: "Juillet",
    aug: "Août",
    sep: "Septembre",
    act: "Octobre",
    nov: "Novembre",
    dec: "Décembre",
    cityFeatures: "Caractéristiques de la ville",
    enterTheNameOfTheCity: "Entrez le nom de la ville",
    organizations: "Organisations",
    livingWage: "Minimum vital",
    "one-roomApartmentForRent": "Location d'un appartement de 1 pièce",
    _month: "mois",
    averageTemperature: "Température moyenne",
    _inWinter: "en hiver",
    _inSummer: "en été",
    averageCostOfDormitories: "Coût moyen d'une place en foyer",
    photos: "Photos",
    interestingPlaces: "Sites d'intérêt",
    readCompletely: "Lire en entier",
    _review: "commentaire",
    _reviews: "commentaires",
    __reviews: "commentaires",
    university: "Université",
    universities: "Universités",
    country: "Pays",
    selectACountry: "Sélectionner le pays",
    reviewOfAForeignStudent: "Commentaire d'étudiant étranger",
    careerGuidance: "Orientation professionnelle",
    determineYourAreaOfEducationAndProfession:
        "Déterminez les domaines de formation et les professions qui vous conviennent",
    myCareer: "Ma carrière",
    defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
        "Comment combiner étude, loisirs culturels et nouvelles connaissances. Caractéristiques des programmes intensifs et leur coût",
    toApply: "Appliquer",
    chooseYear: "Sélectionnez l'année",
    _in: "en",
    universitiesInThisCity: "Universités de cette ville",
    programsInThisCity: "Programmes de cette ville",
    all: "Tous",
    _inYourCountry: "dans votre pays",
    _common: "généraux",
    allReviews: "Tous les commentaires",
    calendar: "Calendrier",
    activity: "Événements",
    addToPersonalCalendar: "Ajouter au calendrier personnel",
    eventAdded: "Événement ajouté",
    eventSuccessfullyAddedToYourCalendar:
        "Événement ajouté avec succès à votre calendrier !",
    population: "Population",
    onTheMap: "Sur la carte",
    generalEducation: "Enseignement général",
    professionalEducation: "Formation professionnelle",
    preschoolEducation: "Enseignement préélémentaire",
    kindergarten: "École maternelle",
    nursery: "Crèche",
    earlyDevelopmentCenters: "Centres d'éveil",
    primaryAndSecondaryEducation:
        "Enseignement primaire, élémentaire et secondaire",
    schools: "Écoles",
    lyceums: "Lycées",
    gymnasium: "Gymnases",
    secondaryVocationalEducation: "Enseignement secondaire professionnel",
    colleges: "Collèges",
    technicalSchools: "Lycée technique",
    secondarySchool: "Écoles de formation",
    higherEducation: "Enseignement supérieur",
    baccalaureate: "Licence",
    specialistDegree: "Spécialité",
    magistracy: "Maîtrise",
    postgraduateStudies: "Troisième cycle",
    preparatoryFaculty: "Faculté préparatoire",
    summerSchools: "Cours d'été",
    additionalGeneralAndPreVocationalPrograms:
        "Programmes de développement général et pré-professionnels complémentaires",
    continuingEducationAndRetrainingPrograms:
        "Programmes de formation permanente et de recyclage professionnel",
    whatAreTheLevelsOfRussianEducation:
        "Quels sont les niveaux d'enseignement en Russie ?",
    enterYourName: "Entrez votre nom",
    name: "Prénom",
    selectTheSubjectOfTheMessage: "Sélectionnez l'objet du message",
    messageSubject: "Objet du message",
    chooseAMessage: "Sélectionnez le message",
    message: "Message",
    submit: "Envoyer",
    frequentlyAskedQuestions: "FAQ",
    didNotFindTheAnswerToYourQuestion: "Pas de réponse à votre question",
    contactSupportAt: "Contactez l'assistance à",
    searchInTheSection: "Rechercher dans la section",
    sort: "Trier",
    searchingResults: "Résultats de la recherche",
    _answers: "des réponses",
    _views: "des vues",
    byCreationDate: "Par date de création",
    byTheNumberOfResponses: "Par nombre de réponses",
    byViews: "Par nombre de vues",
    toAnswer: "Répondre",
    toQuote: "Citer",
    complain: "Signaler",
    addNewMessage: "Ajouter un nouveau message",
    selectComplaintType: "Sélectionnez le type de réclamation",
    typeOfComplaint: "Type de réclamation",
    enterComplaintText: "Saisissez le texte de votre réclamation",
    complaintText: "Texte de la réclamation",
    complaintSentSuccessfully: "La réclamation a bien été envoyée",
    yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
        "Votre réclamation a bien été envoyée et nous l'examinerons dans les plus brefs délais",
    close: "Fermer",
    myMessages: "Mes messages",
    myTopicsAndPosts: "Mes objets et messages",
    searchInMyPosts: "Rechercher parmi mes messages",
    _posts: "messages",
    feedbackOnEducationalOrganizations: "Commentaires sur les universités",
    posted: "Publié",
    goToMessage: "Aller au message",
    _themes: "objets",
    themeTitle: "Nom de l'objet",
    enterAThemeName: "Entrez le nom de l'objet",
    cancel: "Annuler",
    banUser: "Bannir un utilisateur ?",
    areYouSure: "Confirmer ?",
    toBan: "Bannir",
    _logIn: "s'identifier",
    _or: "ou",
    _signUp: "s'enregistrer",
    eventCalendar: "Calendrier des événements",
    trainingApplicationsFiled: "Nombre de candidatures déposées",
    theMostPopularEducationalOrganizationsChosenByApplicants:
        " Universités les plus populaires choisies par les candidats",
    _of: "parmi",
    _whenApplying: "lors du dépôt de candidature",
    tOP10EducationalOrganizations: "TOP-10 des universités",
    collapse: "Réduire",
    showAll: "Tout afficher",
    conditionsForForeigners: "Conditions pour les étrangers",
    supportForInternationalStudents: "Soutien aux étudiants étrangers",
    lifeOfForeignStudents: "Vie quotidienne des étudiants étrangers",
    leisureAndSportingEvents: "Activités de loisirs et sports",
    dormitories: "Foyers",
    dormitoryCost: "Coût des foyers",
    theNumberOfPeopleInTheRoom: "Nombre de personnes dans la chambre",
    from: "De",
    _rubles: "roubles",
    _to: "à",
    _perMonth: "par mois",
    "2-3People": "2 à 3 personnes",
    theInternationalCooperation: "Coopération internationale",
    photoAndVideo: "Photo et vidéo",
    eventTitle: "Titre de l'événement",
    location: "Lieu de l'événement",
    date: "Date",
    enterYourRealMail: "Indiquez votre e-mail actuel",
    requiredField: "Champs requis",
    minimumLengthCharacters: "Longueur minimale de {length}caractères",
    maximumLengthCharacters: "Longueur maximale de {length}caractères",
    theFieldMustBeANumber: "Le champ ne doit contenir que des nombres",
    onlyLettersAreAllowedInTheField: "Le champ ne doit contenir que des lettres",
    checkKeyboardLayout: "Vérifiez la disposition du clavier",
    checkTheNumberYouEnteredIsCorrect: "Vérifiez que le numéro saisi est correct",
    olympiadName: "Nom de l'olympiade",
    description: "Description",
    contactInformation: "Détails de contact",
    majorForeignPartnerUniversities:
        "Principales universités partenaires étrangères",
    learnAboutRussianLanguageFormsProgramsAndTuitionFees:
        "Possibilités d'apprendre la langue russe à temps plein et à distance, en Russie et dans le monde",
    areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
        "Êtes-vous un professeur ? Soumettez un formulaire de candidature pour publier votre profil sur le site",
    submitApplication: "Soumettre un formulaire de candidature",
    chooseATeachingExperience: "Sélectionnez l'expérience d'enseignement",
    teachingExperience: "Expérience d'enseignement",
    chooseLanguages: "Sélectionnez des langues",
    knowledgeOfLanguages: "Compétences linguistiques",
    workExperience: "Années d'expérience",
    trainingLevel: "Niveau de préparation",
    durationOfOneLesson: "Durée d'une leçon",
    possibleTrainingTime: "Horaire possible pour les leçons",
    theCosOfOneLesson: "Coût d'une leçon",
    writeAMessage: "Écrire un message",
    youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
        "Vous pouvez écrire au professeur après inscription ou identification sur le site",
    educationDocuments: "Documents d'études",
    youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
        "Vous avez déjà sélectionné un autre programme : si vous sélectionnez ce programme, la précédente sélection de programme et d'institution sera perdue.",
    limitIsExceeded: "Limite dépassée",
    youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
        "Vous ne pouvez pas choisir plus de 6 universités.",
    _fieldsOfEducation: "domaines de formation",
    _trainingAreas: "programmes de formation",
    _cities: "villes",
    _languagesOfInstruction: "langues d'enseignement",
    _typesOfAdditionalPrograms: "types de programmes complémentaires",
    _semesters: "semestres",
    chooseDurationOfStudy: "Choisissez la durée des études",
    _durationOfStudy: "durée des études",
    enterTheNameOfTheEducationalProgram: "Entrez le nom du programme",
    instruction: "Instructions",
    profile: "Formulaire de candidature",
    back: "Retour",
    next: "Suivant",
    testReport: "Résultat du test",
    testDate: "Date du test",
    duration: "Durée",
    memberName: "Nom du participant",
    age: "Âge",
    gender: "Sexe",
    currentLevelOfEducation: "Niveau actuel d'études",
    areasOfInterestHobbies: "Centres d'intérêt, hobby",
    sportsAchievements: "Réalisations sportives",
    testScaleResults: "Résultats par échelles de test",
    scaleDescription: "Description des résultats par échelles",
    recommendedEducationAreas: "Domaines d'enseignement recommandés",
    enterYourAge: "Saisissez votre âge",
    enterYourGender: "Saisissez votre sexe",
    chooseYourCurrentLevelOfEducation:
        "Sélectionnez votre niveau d'études actuel",
    chooseYourAreasOfInterestHobbies: "Sélectionnez vos centres d'intérêt, hobby",
    chooseYourSportingAchievements: "Sélectionnez vos réalisations sportives",
    legalizationOfDocuments: "Légalisation de documents",
    consularLegalization: "Légalisation consulaire",
    edit: "Modifier",
    mobilePhoneNumber: "Numéro de portable",
    iWantToReceiveNotificationsByEmail:
        "Je souhaite recevoir des notifications par e-mail",
    authorizationCheck: "Contrôle d'identification",
    createANewApplication: "Créer une nouvelle candidature",
    selectionStage: "étape de sélection",
    underRevision: "En cours de finalisation",
    fillingOutAnApplication: "Remplir un formulaire de candidature",
    verificationAndRevision: "Vérification et finalisation",
    dossierFormation: "Constitution du dossier",
    fillingInTheQuestionnaire: "Remplir le formulaire de candidature",
    attachingDocuments: "Joindre des documents",
    sendingApplicationForVerification:
        "Envoi d'une candidature pour vérification",
    personalData: "Données personnelles",
    surnameInLatinLettersAccordingToPassport:
        "Nom, en lettres latines (tel que dans le passeport)",
    nameInLatinLettersAccordingToPassport:
        "Prénom(s), en lettres latines (tel(s) que dans le passeport)",
    middleNameIAnyCyrillicInRussianTranscription:
        "Patronyme (le cas échéant), en lettres cyrilliques en transcription russe",
    placeOfBirthAccordingToPassport:
        "Lieu de naissance (tel que dans le passeport)",
    dateOfBirth: "Date de naissance",
    compatriotStatus: "Statut de compatriote",
    citizenshipPlaceOfPermanentResidence:
        "Citoyenneté (lieu de résidence permanente)",
    online: "En ligne",
    profile2: "Profil",
    goToTheApplicationForm: "Allez à remplir un formulaire de candidature",
    areYouHere: "Vous êtes ici",
    russianTextDes1:
        "Pourquoi fréquenter les facultés préparatoires, de quelles disciplines elles se composent, combien de temps dure la formation et combien elle coûte",
    russianTextDes4:
        "Où et comment trouver un professeur pour des cours particuliers de russe",
    russianTextDes5:
        "Comment et pourquoi confirmer le niveau de langue russe pour l'admission dans une université russe. Pour tout savoir sur le test de certificat",
    typeOfProgram: "Type de programme",
    chooseTheTypeOfAdditionalProgram:
        "Sélectionnez le type de programme complémentaire",
    durationOfStudy: "Durée des études",
    common: "Général",
    inYourCountry: "Dans votre pays",
    courseName: "Nom du cours",
    courseDuration: "Durée du cours",
    classDuration: "Durée des leçons",
    courseCost: "Coût du cours",
    courseReviews: "Commentaires sur le cours",
    male: "Homme",
    female: "Féminin",
    feedbackLink: "Lien vers les commentaires",
    russianLanguageCoursesInYourCountry: "Cours de russe dans votre pays",
    evaluation: "Estimation",
    giveFeedback: "Laisser des commentaires",
    newFeedback: "Nouveau commentaire",
    _hour: "heure",
    _hours: "heures",
    __hours: "heures",
    _minute: "minute",
    _minutes: "minutes",
    __minutes: "minutes",
    create: "Formuler",
    feedbackSuccess:
        "Le commentaire a été soumis avec succès et est en attente de modération",
    _y: "année",
    _from: "c",
    error: "Erreur",
    direction: "Domaine",
    _tutor: "professeur",
    _tutors: "professeurs",
    __tutors: "professeurs",
    filters: "Filtres",
    sendMessage: "Envoyer un message",
    sendMessageSuccess: "Message envoyé avec succès",
    _year: "année",
    _years: "années",
    __years: "années",
    _back: "retour",
    _next: "suivant",
    showing: "Afficher",
    textRussianLanguageCourses:
        "Sélectionnez votre pays de résidence dans le menu et consultez les cours de langue et les écoles disponibles. L'obtention d'un niveau de base de russe dans votre pays de résidence facilitera grandement l'adaptation lors de votre séjour en Russie.",
    tOP10Programs: "TOP-10 des programmes",
    tOP10City: "TOP-10 des villes russes",
    createANewTopic: "Créer un nouvel objet",
    topics: "Objets",
    posts: "Messages",
    filesOfTheFormatPdfJpgPngUpTo10MB:
        "Fichiers au format .pdf, .jpg, .png jusqu'à 10 Mo",
    noFileSelected: "Aucun fichier sélectionné",
    attachFile: "Pièce jointe",
    eMail: "E-mail",
    fullTime: "En présentiel",
    toFind: "Rechercher",
    searchInTopic: "Rechercher par objet",
    lessThan1Month: "Moins d'un mois",
    "1To3Months": "De 1 à 3 mois",
    "3To6Months": "De 3 à 6 mois",
    "6To12Months": "De 6 à 12 mois",
    moreThan1Year: "Plus d'un an",
    theDateOfThe: "Date de l'événement",
    toAdminPanel: "Se rendre au panneau d'administration",
    support: "Assistance technique",
    faq: "FAQ",
    userGuide: "Manuel d'utilisation",
    textUserGuide: "Instructions pour utiliser le portail et déposer une demande pour une formation",
    textRegisterGuide: "Inscription sur le site Éducation en Russie pour les étrangers",
    textTrainingGuide: "Demande de formation sur le site Éducation en Russie pour les étrangers",
    textApplication: "Remplir le questionnaire",
    instructionForWork: "Instructions pour travailler avec la demande",
    appeals: "Appels",
    handling: "Demandes",
    __students: "étudiants",
    _student: "étudiant",
    defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession: "Définissez votre groupe de programmes de formation et découvrez les options possibles pour votre future profession",
    delTheme: "Supprimer l'objet",
    competitionForTheFirstPlace: "Concours pour 1 place dans les limites du quota de la Fédération de Russie (personnes)",
    ruble: "rouble",
    __rubles: "roubles",
    dateOfEvent: "Date",
    durationUnit: "mois",
    numberPhone: "Numéro de téléphone",
    offline: "Hors ligne",
    to_begin: "vers le début",
    theDateOfThe: "Date de début",
    chat: "Chatbot",
    yourMessageChat: "Votre message",
    searchChat: "Recherche dans le dialogue",
    firstMsgChat: "Saisissez le message",
    categoryBtnChat_1: "Question concernant le dépôt de candidature",
    categoryBtnChat_2: "Question concernant la formation",
    categoryBtnChat_3: "Question concernant le concours de sélection",
    categoryBtnChat_4: "Suggestion ou remarque",
    categoryBtnChat_5: "Commentaires concernant les activités de l'université",
    categoryBtnChat_6: "Plainte concernant la qualité des services",
    categoryBtnChat_7: "Contacter l'assistance technique",
    notQuestionResolvedChat: "Mon problème n'est pas résolu",
    questionResolvedChat: "Mon problème est résolu",
    countrySelectTrueChat: "Le non de pays est exact",
    salutationChat: "Bonjour",
    categoryAppealChat: "Pour obtenir des informations, sélectionnez la catégorie correspondant à votre question en cliquant sur le bouton. Après avoir sélectionné une catégorie, saisissez votre question et appuyez sur le bouton Soumettre.",
    langAppealChat: "Sélectionnez la langue dans laquelle vous souhaitez envoyer vos demandes. Les demandes envoyées en russe et en anglais sont traitées plus rapidement.",
    youCountryChatPath1: "Votre pays",
    youCountryChatPath2: "Sinon, veuillez spécifier le nom du pays dans le message de réponse.",
    youCountryChat: "Votre pays {country}? Sinon, veuillez spécifier le nom du pays dans le message de réponse.",
    notFoundCountryChat: "Aucun pays correspondant au nom que vous avez spécifié n'a été trouvé. Vérifiez l'exactitude des données saisies et renvoyez le message.",
    additionalInfoChat: "Veuillez indiquer dans le message de réponse des informations complémentaires concernant votre demande.",
    yourEmailChat: "Veuillez saisir votre adresse e-mail. Une réponse à votre demande vous sera envoyée par e-mail.",
    notValidEmailChat: "L'adresse e-mail que vous avez envoyée ne correspond pas au format aaa@bbb.ccc. Vérifiez l'exactitude des données saisies et renvoyez le message.",
    acceptRequestChat: "Votre demande a été transmise à l'opérateur pour traitement. Le délai moyen de traitement est de 7 jours calendaires.",
    notFoundEmployeeChat: "Le pays que vous avez spécifié n'est pas inclus dans la liste des pays où une formation dans le cadre du programme Rossotroudnitchestvo est offerte. Votre demande ne peut pas être transmise pour examen. Veuillez spécifier un autre pays. Vous pouvez trouver la liste complète des programmes de formation sur la page <a href=/education-in-russia/programs#>{host}/education-in-russia/programs#.</a>' ",
    langBtnChat_1: "Anglais",
    langBtnChat_2: "Russe",
    langBtnChat_3: "Autre",
    addAppBtn: "Soumettre une demande",
    listAppBtn: "Liste de demandes",
    privacyPolicy: "Politique de confidentialité",
    ___programs: "programme",
    ___educationalPrograms: "programmes de formation",
    __educationalPrograms: "programme de formation",
    editTopic: "Modifier l'objet",
    editMessage: "Modifier le message",
    deleteMessage: "Supprimer le message",
    blockingUser: "Blocage de l'utilisateur",
    blockEndDate: "Date de fin du blocage",
    blockEndTime: "Heure de fin du blocage",
    blockForever: "Bloquer pour toujours",
    block: "Bloquer",
    unlock: "Débloquer",
    edited: "Modifié",
    founder: "Créateur",
    abbreviation: "Abréviation",
    studentsByCountry: "Nombre d'étudiants par pays",
    studentsByCountryCount: "Nombre d'étudiants en cours de formation",
    contactsInternationalDepartment: "Contacts du département international",
    site: "Site",
    address: "Adresse",
    format: "Format",
    typeEvent: "Type d'événement",
    quotaLearning: "Études par quota",
    kcp: "Nombre de contrôle d'inscription",
    countSeatsKCP: "Nombre de places en fonction du nombre de contrôle d'inscription",
    countSeatsCostTraining: "Nombre de places pour des études payantes",
    distanceLearning: "Études à distance",
    notificationAboutDistanceLearning: "La mise en œuvre du programme de formation est possible grâce à l'utilisation des technologies d'apprentissage en ligne et d'enseignement à distance. Renseignez-vous auprès de l'université pour plus de détails.",
    additionalTests: "Dates de tenues des épreuves de sélection supplémentaires",
    pageNotFound: "Page non trouvée",
    goToMainPage: "Retour à la page d'accueil",
    faqInformation: "Pour toutes les questions relatives à l'état de la vérification de la demande, la liste des documents requis, le calendrier des épreuves de sélection, ainsi que pour toutes autres questions concernant le processus d'admission, nous vous recommandons de contacter les bureaux de représentation de Rossotroudnitchestvo ou encore l'ambassade de la Fédération de Russie, autorisée à effectuer la sélection dans votre pays. Les contacts pour joindre les employés de Rossotroudnitchestvo sont accessibles dans votre compte personnel.",
    allEvents: "Tous les événements",
    eventsAndNews: "Événements et actualités",
    titleNews: "Titre de l'actualité",
    numberOfBudgetPlaces: "Nombre de places avec financement public",
    russianGovernmentQuota: "Budget (les chiffres d'admissions)",
    legalAddress: "Adresse légale",
    actualAddress: "Adresse réelle",
    goToTheRegister: "Accéder au registre des programmes supplémentaires",
    captcha: "en",
    theMostPopularEducationalProgramsChosenByApplicants: "Les programmes de formations les plus populaires choisis par les candidats",
    theMostPopularCitiesOfRussiaChosenByApplicants: "Les villes de Russie les plus populaires choisies par les candidats",
    ofTheCountry: "du pays",
    passed: "Terminé",
    question: "Question",
    instructionForProctoring: "Instructions for passing testing with proctoring",
    banner_1: "Le portail est en mode d'essai!",
    banner_2: "Chers utilisateurs, Des problèmes techniques avec les adresses de messagerie Google peuvent être rencontrés. Veuillez privilégier d'autres services de courrier électronique lors de l'utilisation du portail.",
    banner_3: "La campagne d'admission commencera  à partir du 24 décembre 2022.",
    typeOfEducation: "Вид образования",
    chooseTheTypeOfEducation: "Выберите вид образования",
};
