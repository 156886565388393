<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page Event">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('eventCalendar'), url: '/calendar-of-events' },
              {title: filterLang(event, 'name') || event.title },
            ]"
        />
        <div class="d-space-between"> 
          <h1 class="title-1 mb20">{{ event | lang('name') }}</h1>
          <button
            v-if="event.type"
            class='event-list-item-button'
            disabled
          >{{ event.type.name }}
          </button>
        </div>

        <div class="event-container" v-if="event.id">
          <div class="row">
            <div class="col-6">
              <div class="event-wrapper event-event">
                <div class="d-space-between">
                  <div class="event_info-header">
                    <div class="event_date-time">
                      <img :src="timeIcon"/> 
                      {{ dateEvent(event.start_date, event.end_date) }} 
                      • 
                      {{ $t('_from') }} {{ startTimeEvent(event.start_time) }}
                      {{ $t('_to') }} {{ endTimeEvent(event.end_time) }}
                    </div>
                    <div class="event_location-link">
                      <span v-if="event.format.code === 'ONLINE' && event.url">
                        <img :src="siteIcon"/>
                        <a 
                          :href="url_link(event.url)"
                          class="link"
                        > 
                          {{ event.url }}
                        </a>
                      </span>
                      <span class="location" v-else><img :src="locationIcon"/> {{ event.location }}</span>
                    </div>
                  </div>
                  <div class="event-event-button" v-if="isAuth" hidden>
                    <a class="btn" @click="() => evensParticipate(event.id)">{{ $t('signUp') }}</a>
                  </div>
                </div>

                <hr class="mb16"/>

                <div class="row">
                  <div class="col-6" v-html="modifyUrl(filterLang(event, 'description'))"/>
                </div>

                <div class="event_organizers">
                  <span class="organizers-title">{{ $t('organizers') }}</span>
                  <span class="organizers-value">{{ event.organizers }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :unique-id="modalUniqueId"
      ref="modal"
    >
      <div slot="body">
        <h2 class="title-2">{{ $t('eventAdded') }}</h2>
        <p>{{ $t('eventSuccessfullyAddedToYourCalendar') }}!</p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => targetModal(modalUniqueId)"
        >{{ $t('close') }}</a>
      </div>
    </Modal>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import Vue from 'vue';
  import Modal from '@/components/generic/modal/Modal';
  import moment from 'moment';
  import timeIcon from '@/assets/images/time-icon.png';
  import locationIcon from '@/assets/images/ic_location.svg';
  import siteIcon from '@/assets/images/site-icon.png';
  import 'moment/locale/ru';

  export default {
    name: 'events-event',

    data() {
      return {
        timeIcon: timeIcon,
        locationIcon: locationIcon,
        siteIcon: siteIcon,
        event: {},
        filterLang: Vue.filter('lang'),
        modalUniqueId  : 'modal-event',
        timeDiff: false,
      }
    },

    components: {
      Breadcrumbs,
      Modal,
    },

    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
        isAuth: 'users/isAuth',
      }),
    },

    methods: {
      ...mapActions({
        getEventById: 'events/getEventById',
        setEventsParticipate: 'events/setEventsParticipate',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getEventById(this.$route.params.id)
          .then(res => {
            if (res.status === 'success') {
              this.event = res.data;

              if (this.event) {
                this.timeDiff = moment(this.event.start_date).diff(moment(), 'day');
              }
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      evensParticipate(id) {
        this.$store.commit('preloader/isLoading', true)
        this.setEventsParticipate({
          event_id: id,
        }).then(() => {
          this.targetModal(this.modalUniqueId);
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },

      modifyUrl(content) {
        if (content.includes('<figure class="media">')) 
          {
            let result = content.split('oembed').join('iframe').split('url').join('src').split('youtu.be').join('youtube.com/embed').split('watch?v=').join('embed/').replace(/(&)[^"]*/g, '$1').replace('&', '');
            return result;
          }  
        else 
          return content;
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },
    },

    mounted() {
      window.scrollTo(0, 0);
      this.getData();
    },
  }
</script>
