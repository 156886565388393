<template>
  <div class="map" :style="`height: ${height}px;`">
      <l-map
        :zoom="zoom"
        :center="center"
        ref="deviceMap"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />

        <l-marker
          :lat-lng="marker.coordinate"
          v-for="(marker, key) in markers"
          v-bind:key="key"
          v-if="marker.coordinate && marker.coordinate[0]"
          @click="() => marker.markerCallback && marker.markerCallback(marker.id)"
        >
          <l-icon
            :icon-size="[30, 40]"
            :icon-anchor="[30, 40]"
            :icon-url="require('@/assets/images/marker.png')"
          />

          <l-popup v-if="marker.title || marker.description">
            <img :src="marker.image" v-if="marker.image" />

            <div class="content-child">
              <div class="title">{{ marker | lang('title') }}</div>

              <div class="text" v-if="marker.link">
                <a target="_blank" :href="marker.link">{{ marker.link }}</a>
              </div>

              <div class="text">{{ marker | lang('description') }}</div>
            </div>
          </l-popup>
        </l-marker>
      </l-map>
  </div>
</template>

<script>
  import _ from 'lodash';
  import L from 'leaflet';
  import {LMap, LTileLayer, LMarker, LIcon, LPopup} from 'vue2-leaflet';
  import 'leaflet/dist/leaflet.css';

  export default {
    name: 'life-in-russia-cities-open-street-map',

    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      LPopup,
    },

    data() {
      return {
        center: [55.751244, 37.618423],
        url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        marker: null,
        data: {
          ratings: [],
        },
        currentTab: 0,
        showAllRatings: false,
      }
    },

    methods: {
      icon() {
        return L.icon({
          iconUrl: require('@/assets/images/marker.png'),
          iconSize: [30, 40],
          iconAnchor: [30, 40],
        });
      },

      setCenter() {
        if (this.markers.length && !this.zoomFixed) {
          let bounds = new L.LatLngBounds(_.map(this.markers, marker => marker.coordinate));

          if (!_.isEmpty(bounds)) {
            this.center = [bounds.getCenter().lat, bounds.getCenter().lng]
            this.$refs.deviceMap.fitBounds(bounds);
          }
        }
      },
    },

    mounted() {
      if (this.isGrey) {
        this.$refs['deviceMap'].$el.getElementsByClassName('leaflet-tile-pane')[0].classList.add('leaflet-tile-pane-grey')
      }

      this.setCenter();
    },

    props: {
      height: Number,
      markers: Array,
      isGrey: Boolean,
      zoom: Number,
      zoomFixed: {type: Boolean, default() { return false }},
    },

    watch: {
      markers() {
        this.setCenter();
      },
    },
  }
</script>

<style lang="scss">
  .leaflet-tile-pane-grey {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .leaflet-popup {
    width: 350px;
    left: 15px!important;

    .leaflet-popup-tip-container, .leaflet-popup-close-button {
      display: none;
    }

    .leaflet-popup-content-wrapper {
      overflow: hidden;
      padding: 0;
      box-shadow: none;
      border-radius: 8px;

      .leaflet-popup-content {
        width: 100% !important;
        padding: 0;
        margin: 0;

        img {
          max-width: 100%;
        }

        .content-child {
          padding: 16px 24px;
          font-size: 14px;

          .title {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .text {
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }
  }
  $screen-md-min: 768px;
  @mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
  }
  @include md{
      .leaflet-popup {
          .text, img {
            display: none;
          }
        }
      }
</style>
