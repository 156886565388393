<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div>
    <div class="container">
      <div class="profile-container">
        <div class="profile-aside">
          <ProfileMenuLeft />

          <div class="aside__banner">
            <div class="aside__banner-top">
              <div class="aside__banner-logo">
                <img alt="" src="@/assets/images/ross-logo.png">
              </div>
              <h4 class="title-4">{{ $t('representation') }}</h4>
              <p>
                Российский информационно-культурный центр в Баку
              </p>
            </div>
            <ul class="aside__banner-links">
              <li>
                <a class="link" href="#"><img alt="" src="@/assets/images/site-icon.png">www.hse.ru</a>
              </li>
              <li>
                <a href="#"><img alt="" src="@/assets/images/icon/ic-mail.svg">azerbaijan_baku@rs.gov.ru</a>
              </li>
              <li>
                <img alt="" src="@/assets/images/icon/ic-phone.svg">+994 12 4964400
              </li>
              <li>
                <img alt="" src="@/assets/images/icon/ic-pin-black-small.svg">Баку, проспект Ходжалы, 20
              </li>
            </ul>
          </div>
        </div>
        <div class="profile-content">
          <ul class="breadcrumbs">
            <li class="breadcrumbs-item">
              <a href="#">{{ $t('servicesAndFacilities') }}</a>
            </li>
            <li class="breadcrumbs-item">
              {{ $t('legalizationOfDocuments') }}
            </li>
          </ul>

          <h1 class="title-1 mb32">
            {{ $t('legalizationOfDocuments') }}
          </h1>
          <div class="def-container">
            <p>
              Согласно законодательству Российской Федерации документы, выданные
              в другом государстве, могут использоваться (приниматься российскими органами и организациями) только при наличии легализации.
            </p>
            <div class="important-info">
              Внимание! Легализация иностранных документов и признание иностранного образования - разные процедуры.
              Легализация документов осуществляется для законного использования иностранного документа в другой стране.
              Признание образования осуществляется для предоставления прав на обучение (или) трудовую деятельность.
            </div>
            <p>
              Легализацией называется совершение ряда определенных формальных процедур для придания документу юридической силы на территории
              другого государства с целью возможности его представления в официальные органы другого государства. Различают два вида
              легализации:
            </p>
            <ul class="list-disc list-sub">
              <li>
                <p>Консульская легализация</p>
                Проводится в 2 этапа: <br>
                1. заверение документа (в зависимости от требований законодательства страны выдачи: как правило, оригинала, в некоторых
                странах – копии,) компетентными органами иностранных дел (МИД) страны выдачи документа – проставляются соответствующие
                штампы и печати с указанием должности и подписью заверяющего лица;
                2. заверение консульскими службами Российской Федерации (консульство или представительство РФ) в стране выдачи документа –
                проставляются соответствующие штампы и печати с указанием должности и подписью заверяющего лица.
              </li>
              <li>
                <p>
                  «Апостилирование» – упрощенный порядок легализации
                </p>
                Проставление штампа «Апостиль» на официальных документах, исходящих от учреждений и организаций стран-участниц Гаагской
                конвенции 1961 года, отменяющей требование консульской легализации иностранных официальных документов:
                <br>
                1. проставляется на оригинале документа или на отдельном листе, скрепляемом с документом, и может быть составлен на
                официальном языке выдающего его органа;
                2. проставление штампа «Апостиль» осуществляется компетентными уполномоченными органами страны выдачи документа (Секретарь
                штата, департамент образования, министерство образования, учебные заведения и др.).
              </li>
            </ul>
            <a class="btn" href="#">{{ $t('apply') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileMenuLeft from '@/components/site/pages/account/generic/ProfileMenuLeft';

  export default {
    name: 'LegalizationDocuments',

    components: {
      ProfileMenuLeft,
    },

    computed: {},
    data() {
      return {

      }
    },
  };
</script>
