<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page" v-if="program.program">
    <div class="university__page-container">

      <div class="main__info-title">
        <div class="main__info-title__img" :style="`background-image: url(${require('@/assets/images/program-bg.png')});`"></div>
        <div class="container">
          <Breadcrumbs
            :items="[
              {title: $t('mainPage'), url: '/' },
              {title: $t('educationInRussia') },
              {title: $t('educationalPrograms'), url: '/education-in-russia/programs' },
              {title: program.additional_name || program.program.name_without_code },
            ]"
          />

          <h1 class="title-1" v-if="program.additional_name">{{ program.additional_name }}</h1>
          <h1 class="title-1" v-if="!program.additional_name">{{ program.program | lang('name_without_code') }}</h1>
        </div>
      </div>

      <div class="container">
        <ProgramsSearchItem
          :data="program"
          :is-header-program="true"
          :type="$route.fullPath.indexOf('-do-') !== -1 ? 'additional' : 'main'"
        />
      </div>

      <div class="container">
        <div class="universities__info">
          <h2 class="title-2">
            {{ $t('programDescription') }}
          </h2>
          <div class="row">
            <div class="col-4" v-html="program.description" />

            <div class="col-2 md-hide">
              <dl class="title-sub mb32" v-if="program.website">
                <dt class="light">{{ $t('programPageOnTheUniversityWebsite') }}</dt>

                <dd>
                  <a class="link"
                    target="_blank"
                    :href="$options.filters.validatorWebSite(program.website)"
                  >{{ program | lang('website') }}</a>
                </dd>
              </dl>

              <dl class="title-sub" v-if="program.curator">
                <dt class="light">{{ $t('programCurator') }}</dt>
                <dd>{{ program | lang('curator') }}</dd>
                <dd>{{ program | lang('curator_contacts') }}</dd>

                <dt class="light">{{ $t('countSeatsCostTraining') }}</dt>
                <dd>{{ program.seats_count | handlerEmpty }}</dd>
                
                <dt class="light">{{ $t('countSeatsKCP') }}</dt>
                <dd>{{ program.kcp_seats_count | handlerEmpty }}</dd>
              </dl>
            </div>
          </div>

          <div class="row" >
            <div class="col-2 d-none md-show">
              <dl class="title-sub mb32" v-if="program.website">
                <dt class="light">{{ $t('programPageOnTheUniversityWebsite') }}</dt>

                <dd>
                  <a class="link"
                    target="_blank"
                    :href="program.website"
                  >{{ program.website }}</a>
                </dd>
              </dl>

              <dl class="title-sub" v-if="program.curator">
                <dt class="light">{{ $t('programCurator') }}</dt>
                <dd>{{ program.program | lang('curator') }}</dd>
                <dd>+7 903 798-54-82</dd>
                <dd><a href="#">igortemkin@yandex.ru</a></dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import ProgramsSearchItem from '@/components/site/pages/educationInRussia/programs/generic/ProgramsSearchItem';
  import {mapActions} from 'vuex';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'Program',

    components: {
      Breadcrumbs,
      ProgramsSearchItem,
    },

    computed: {},

    data() {
      return {
        program: {},
      }
    },

    methods: {
      ...mapActions({
        getProgram: 'educationInRussia/getProgram',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getProgram({idProgram: this.$route.params.idProgram})
          .then(res => {
            if (res.status === 'success') {
              this.program = res.data;
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      this.getData();
    },
  };
</script>
