<template>
  <div v-if="items.length">
    <ul class="breadcrumbs" v-show="!isResponsive">
      <li
        :key="key"
        @click="callback && callback()"
        v-for="(item, key) in items"
        class="breadcrumbs-item"
      >
        <router-link :to="item.url" v-if="item.url && item.url !== '#' && item.url !== fullPath" :prefetch="false">
          {{ typeof item.title === 'function' ? item.title() : item.title }}
        </router-link>

        <span v-if="!item.url ||  item.url === '#' || item.url === fullPath">{{ typeof item.title === 'function' ? item.title() : item.title }}</span>
      </li>
    </ul>

    <router-link
      :to="getBackLink().url || '#'"
      :prefetch="false"
      class="back-link"
      v-show="isResponsive"
    >
      {{ typeof getBackLink().title === 'function' ? getBackLink().title() : getBackLink().title }}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumbs',

    props: {
      callback: Function,
      items: Array,
    },

    data: () => ({
      fullPath: '',
    }),

    methods: {
      getBackLink() {
        let url = this.items[0]

        if (this.items.length > 1) {
          url = this.items[this.items.length - 2]

          if (!url.url && this.items[this.items.length - 3]) {
            url = this.items[this.items.length - 3]
          }
        }

        if (this.items.length === 1) {
          url = this.items[0]
        }

        return url;
      },
    },

    mounted() {
      if (this.$route.fullPath) {
        this.fullPath = this.$route.fullPath;
      }
    },

    watch: {
      '$route.fullPath'(fullPath) {
        this.fullPath = fullPath;
      },
    },
  };
</script>
