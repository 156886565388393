<template>
  <div>
    <a
      :href="`${avanpost}/oauth2/authorize?lang=${getLocale}&client_id=educationinrussia&redirect_uri=${link}/account/login&scope=openid%20profile%20email%20groups%20additional_info&response_type=code`"
      class="login-btn"
      v-if="!isAuth"
    >
      {{ $t('personalArea') }}
    </a>

    <div
      class="header__user"
      v-if="isAuth && user.id"
      @click="isShownMenu = !isShownMenu"
      v-add-tag-recursive="'isDropdown'"
    >
      <figure class="header__user-wrap" :class="{show: isShownMenu}">
        <div class="header__user-avatar">
          <div :style="{background: `url(${user.photo})`}" class="header__user-photo"/>
        </div>
        <figcaption class="header__user-name">
          {{ user.last_name }} {{ user.first_name[0] ? `${user.first_name[0]}.` : '' }}
        </figcaption>
      </figure>
      <div class="header__user-dd" v-show="isShownMenu">
        <ul class="header__user-list">
          <li v-if="roleSatisfying(roles.USER, roles.RZU, roles.ROSS)">
            <a class="notify"
              :href="user.org_id === '00' ? '/settings/profile/notification' : '/settings/notifications'"
            >
              <span class="notify-count" v-if="user.unread_notification_count > 0">
                {{user.unread_notification_count}}
              </span>
              <img src="@/assets/images/icon/ic-notification.svg" alt="">{{ $t('notifications') }}
            </a>
          </li>

          <li v-if="roleSatisfying(roles.USER)">
            <router-link
              to="/account/favorites"
            ><img src="@/assets/images/icon/ic-favorite.svg" alt="">{{ $t('favorites') }}</router-link>
          </li>

          <li v-if="roleSatisfying(roles.USER)">
            <router-link
              to="/account/choices"
            ><img src="@/assets/images/icon/ic-star.svg" alt="">{{ $t('theChoice') }}</router-link>
          </li>
          <li v-if="roleSatisfying(roles.USER)">
            <a
              href="/settings/profile/calendar"
            ><img src="@/assets/images/icon/ic-calendar.svg" alt="">{{ $t('calendar') }}</a>
          </li>
          <!-- <li v-if="roleSatisfying(roles.USER, roles.RZU)">
            <a class="notify"
              :href="roleSatisfying(roles.USER) ? '/settings/appeals-user' : '/settings/chat'"
            >
              <span class="notify-count" v-if="Number.isInteger(this.countUnread)">
                {{countUnread}}
              </span>
              <img src="@/assets/images/icon/ic-main-char.svg" alt="">{{ $t('handling') }}
            </a>
          </li> -->

          <li v-if="roleSatisfying(roles.RZU, roles.ROSS)">
            <a href="/settings/">
              Настройки
            </a>
          </li>

          <li v-if="roleSatisfying(roles.ROSS)">
            <a href="/settings/mailing-creation">
              Создание рассылки
            </a>
          </li>

          <li v-if="roleSatisfying(roles.RZU, roles.ROSS)">
            <a href="/settings/edit-templates">
              Редактирование шаблонов
            </a>
          </li>

          <li v-if="(getPermissionsAdmin && getPermissionsAdmin.admin_panel) || roleSatisfying(roles.REGADMIN)">
            <a
              href="/admin/dashboard"
              class="red"
            >{{ $t('toAdminPanel') }}</a>
          </li>

          <li class="lg-hide">
            <a
              @click="logout"
              href="javascript:void(0)"
            ><img src="@/assets/images/icon/ic-exit.svg" alt="">{{ $t('logout') }}</a>
          </li>
        </ul>

        <hr>

        <ul class="header__user-list second">
          <li v-if="!roleSatisfying(roles.REGADMIN)">
            <a :href="roleSatisfying(roles.USER) ? '/settings/profile' : 'https://auth.education-in-russia.com/lk/'">
              {{ $t('profile2') }}
            </a>
          </li>

          <li v-if="roleSatisfying(roles.USER)">
            <a href="/settings/profile/applications">
              {{ $t('myApplications') }}
            </a>
          </li>

          <li v-if="roleSatisfying(roles.RZU, roles.ROSS)">
            <a href="/settings/applications-register">
              Реестр заявок
            </a>
          </li>

          <li v-if="roleSatisfying(roles.RZU, roles.ROSS)">
            <a href="/settings/events">
              Мероприятия
            </a>
          </li>

          <li v-if="!roleSatisfying(roles.REGADMIN)">
            <a :href="roleSatisfying(roles.USER) ? '/settings/profile/screening-test' : '/settings/screening-test'">
              {{ $t('screeningTests') }}
            </a>
          </li>

          <li v-if="roleSatisfying(roles.RZU, roles.ROSS)">
            <a href="/settings/appeals">
              Апелляции
            </a>
          </li>

          <li v-if="roleSatisfying(roles.USER)">
              <a href="/settings/profile/appeals">
                Апелляции
              </a>
          </li>

          <li :hidden="true">
            <a :href="roleSatisfying(roles.USER) ? '/settings/profile/services' : '/settings/services'">
            {{ $t('servicesAndFacilities') }}
            </a>
          </li>
        </ul>

        <ul class="header__user-list d-none lg-show logout-wrap">
          <li>
            <a
              @click="logout"
              href="javascript:void(0)"
            ><img src="@/assets/images/icon/ic-exit.svg" alt="">{{ $t('logout') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
  import IMG_PROFILE from '@/assets/images/icon/account_circle-24px.svg';

  export default {
    computed: {
      ...mapGetters({
        isAuth: 'users/isAuth',
        user: 'users/getUser',
        roles: 'users/getSetRoles',
        getLocale: 'getLocale',
        getPermissionsAdmin: 'getPermissionsAdmin',
        countUnread: 'chatBot/getCountUnread',
      }),
      ...mapActions({
        getCountUnread: 'chatBot/getCountUnread',
      }),
    },

    name: 'header-user',

    data: () => ({
      isShownMenu: false,
      link: window.location.origin,
      avanpost: process.env.VUE_APP_LINK_AVANPOST,
      domain: window.location.host,
      imgProfile: IMG_PROFILE,
    }),

    methods: {
      logout() {
        this.$store.commit('users/logout');
      },

      closeDropdown(ev) {
        if (this.isShownMenu) {
          this.isShownMenu = ev.target.getAttribute('add-tag-recursive') === 'isDropdown';
        }
      },
    },

    watch: {
      isShownMenu(val) {
        if (val) {
          document.body.addEventListener('click', this.closeDropdown)
        } else {
          document.body.removeEventListener('click', this.closeDropdown)
        }
      },
    },
    mounted() {
      if (this.isAuth) {
        this.getCountUnread.then(res => {
          this.$store.commit('chatBot/updateParam', {model: 'countUnread', value: res});
        })
      }
    },
  }
</script>
