<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <ul class="breadcrumbs">
          <Breadcrumbs :items="[{title: $t('mainPage'), url: '/' }, {title: $t('educationInRussia')}, {title: $t('russianLanguage') }]" />
        </ul>

        <h1 class="title-1 mb32">{{ $t('russianLanguage') }}</h1>

        <div class="card__vertical">
           <Preloader
            :isLoading = "preloader"
            />
          <figure class="card">
            <div class="card-bg" :style="`background-image: url(${require('@/assets/images/img-preparatory-departments2x.jpg')});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('preparatoryFaculties') }}</h3>
              <p>{{ $t('russianTextDes1') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/choice-of-profession/preparatory-faculties">
                {{ $t('goTo') }}
              </router-link>
            </div>
          </figure>

          <figure class="card">
            <div class="card-bg" :style="`background-image: url(${require('@/assets/images/img-summer-and-winter-schools2x.jpg')});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('summerAndWinterSchools') }}</h3>
              <p>{{ $t('defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/choice-of-profession/summer-and-winter-schools">
                {{ $t('goTo') }}
              </router-link>
            </div>
          </figure>

          <figure class="card">
            <div class="card-bg" :style="`background-image: url(${require('@/assets/images/img-russian-language-courses2x.jpg')});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('russianLanguageCourses') }}</h3>
              <p>{{ $t('learnAboutRussianLanguageFormsProgramsAndTuitionFees') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link
                class="btn"
                to="/education-in-russia/choice-of-profession/russian-language-courses"
              >{{ $t('goTo') }}</router-link>
            </div>
          </figure>

          <figure class="card">
            <div class="card-bg" :style="`background-image: url(${require('@/assets/images/img-russian-language-teacher2x.jpg')});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('tutorsTheRussianLanguage') }}</h3>
              <p>{{ $t('russianTextDes4') }}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/russian/tutors">{{ $t('goTo') }}</router-link>
            </div>
          </figure>

          <figure class="card">
            <div class="card-bg" :style="`background-image: url(${require('@/assets/images/img-certification-in-russian2x.jpg')});`">
            </div>
            <figcaption class="card-info">
              <h3 class="title-2">{{ $t('certificationInRussian') }}</h3>
              <p>{{ $t('russianTextDes5' )}}</p>
            </figcaption>

            <div class="card-btn">
              <router-link class="btn" to="/education-in-russia/choice-of-profession/certification-in-russian">
                {{ $t('goTo') }}
              </router-link>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'choice-of-profession',

    data: () => ({
      lang: Vue.filter('lang'),
    }),

    components: {
      Breadcrumbs,
    },

  computed: {},
  created() {
      this.$store.commit('preloader/isLoading', true)
    setTimeout(() => this.$store.commit('preloader/isLoading', false), 1500)
  },
  };
</script>
