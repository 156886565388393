<template>
  <div class="card__universities" :class="{ mb32: isHeaderProgram, [cssVariant]: cssVariant }" v-if="data">
    <div class="card__universities-control" v-if="cssVariant === 'variant-3'">
      <div class="card__universities-control-logo">
        <router-link
          class="link"
          :to="`/education-in-russia/universities/${data.university_slug || data.university && data.university.slug}`"
        >
          <img alt="" :src="data.university.logo">
        </router-link>
      </div>

      <div>
        <a
          class="favorite"
          :class="{ active: data.favorite }"
          href="javascript:void(0)"
          @click="() => addFavorite(data)"
        >{{ data.favorite ? $t('inFavorites') : $t('toFavorites') }}</a>

        <a
          class="btn choose"
          href="javascript:void(0)"
          @click="() => addChoices(data)"
        >{{ data.choice ? $t('fromChoices') : $t('choices') }}</a>
      </div>
    </div>



    <div class="card__universities-content">
      <figure class="favorite-card-top">
        <div class="favorite-card-logo">
          <router-link
            class="link"
            :to="`/education-in-russia/universities/${data.university_slug || data.university && data.university.slug}`"
          >
            <img alt="" :src="data.university.logo" />
          </router-link>
        </div>
      </figure>
      <h3 class="title-3" v-if="!isHeaderProgram">
        <router-link :to="`/education-in-russia/programs/${data.id}`">
          {{ data.additional_name || data.program.name_without_code }}
        </router-link>
      </h3>

      <ul class="card__universities-links">
        <li>
          <router-link
            class="link"
            :to="`/education-in-russia/universities/${data.university_slug || data.university && data.university.slug}`"
          >
            <span>
              <img alt="" src="@/assets/images/ic_university.png" />
              <span v-if="!(data.university || {}).name">{{ data.university }}</span>
              <span v-if="data.university.name">{{ data.university | lang('name') }}</span>
            </span>
          </router-link>
        </li>

        <li>
          <router-link
            class="link"
            :to="`/life-in-russia/cities/${(((data.university || {}).address || {}).city || {}).slug || ''}`"
          >
            <span>
              <img alt="" src="@/assets/images/location-icon.png">
              <span v-if="!data.university.address">{{ data.city }}</span>
              <span v-if="data.university.address.city">{{ data.university.address.city | lang('name')}}</span>
            </span>
          </router-link>
        </li>

        <li v-if="data.website" >
          <a
            class="link"
            :href="$options.filters.validatorWebSite(data.website)"
            target="_blank"
          >
            <span>
              <img alt="" src="@/assets/images/site-icon.png">
              <span>{{ $t('programPageOnTheUniversityWebsite') }}</span>
            </span>
          </a>
        </li>
      </ul>

      <figure class="card__universities-info">
        <figcaption class="card__universities-info-content">
          <p class="programs-sub-date" v-if="type === 'additional'">
            <span class="light">{{ $t('theDateOfThe') }}</span>

            <span class="dark" v-if="true">
              {{ data.planned_start_dates }}
            </span>
            <span v-else>—</span>
          </p>

          <ul class="card__universities-info-list">

            <li v-if="data.additional_tests">
              <span class="light">
                {{$t('additionalTests')}}
              </span>
              <span class="dark">
                {{ data.additional_tests }}
              </span>
            </li>

            <li>
              <span class="light">
                {{ $t('direction') }}
              </span>
              <span class="dark">
                {{ data.program.name }}
              </span>
            </li>

            <li>
              <span class="light">
                {{ $t('formOfTraining') }}
              </span>
              <span class="dark">
                {{ $t('fullTime') }}
              </span>
            </li>

            <li v-if="type !== 'additional'">
              <span class="light">
                {{ $t('level') }}
              </span>
              <span class="dark">
                {{ data.level || data.filter.edu_level.name }}{{ data.duration ? `, ${data.duration}` : '' }}
              </span>
            </li>

            <li v-if="type === 'additional'">
              <span class="light">{{ $t('duration') }}</span>

              <span class="dark" v-if="data.program.duration_program">
                {{ `${data.program.duration_program} ${$t('durationUnit')}.`}}
              </span>
              <span v-else>—</span>
            </li>
            <li v-if="type === 'additional' ">
              <span class="light" >
                {{ $t('semester') }}
              </span>
              <span class="dark" v-if="data.program.semester.name">
                {{ data.program.semester.name }}
              </span>
              <span v-else>—</span>
            </li>

            <li>
              <span class="light">
                {{ $t('typeOfTraining') }}
              </span>
              <span class="dark">
                <span>{{ typeTraining || '—'}}</span>
              </span>
            </li>

            <li>
              <span class="light">
                {{ $t('languageOfInstruction') }}
              </span>
              <span
                class="dark"
                v-for="(lang, key) in data.lang_instruction"
                v-bind:key="key"
              >
                {{ lang.language | lang('name') }}
              </span>
            </li>

            <li>
              <span class="light">
                {{ $t('tuitionFees' )}}
              </span>
              <div
                class="dark cost"
                :class="{open: forms.programsOpenCost && forms.programsOpenCost[data.id] }"
                @click="() => openCost(data.id, data.cost)"
                v-if="data.cost"
              >
                {{ data.cost | number }} ₽
                <template v-if="currency">
                  <div class="cost-wrapp" >
                    <div class="cost-wrapp-item">
                      <span>
                        {{ dollar }} $
                      </span>
                        <span>
                        {{ euro }} €
                      </span>
                    </div>
                  </div>
                </template>
              </div>

              <span v-if="!data.cost">—</span>
            </li>
          </ul>
        </figcaption>
      </figure>
    </div>

    <div class="card__universities-control" v-if="cssVariant === 'variant-2'">
      <div class="card__universities-control-logo">
        <router-link
          class="link"
          :to="`/education-in-russia/universities/${data.university_slug || data.university && data.university.slug}`"
        >
          <img alt="" :src="data.university.logo" />
        </router-link>
      </div>
      <div>
        <a
          class="favorite"
          :class="{ active: data.favorite }"
          href="javascript:void(0)"
          @click="() => addFavorite(data)"
        >{{ data.favorite ? $t('inFavorites') : $t('toFavorites') }}</a>

        <a
          class="btn choose"
          href="javascript:void(0)"
          @click="() => addChoices(data)"
        >{{ data.choice ? $t('fromChoices') : $t('choices') }}</a>
      </div>
    </div>

    <Modal
      :unique-id="`${modalUniqueId}_${data.id}`"
      ref="modal"
    >
      <div slot="body">
        <h2 class="title-2">{{ $t('chooseProgram') }}?</h2>
        <p>
          {{ $t('youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost') }}.
        </p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => { _Choices(data, true); targetModal(`${modalUniqueId}_${data.id}`); }"
        >{{ $t('close') }}</a>
      </div>
    </Modal>

    <Modal
      :unique-id="`${modalErrorUniqueId}_${data.id}`"
      ref="modal"
    >
      <div slot="body">
        <h2 class="title-2">{{ $t('limitIsExceeded') }}</h2>

        <p>{{ $t('youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations') }}.</p>

        <a
          href="javascript:void(0)"
          class="btn"
          @click="() => targetModal(`${modalErrorUniqueId}_${data.id}`)"
        >{{ $t('close') }}</a>
      </div>
    </Modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapActions, mapGetters} from 'vuex';
  import Modal from '@/components/generic/modal/Modal';
  import moment from 'moment';

  export default {
    name: 'programs-university-search-item',

    components: {
      Modal,
    },

    computed: {
      ...mapGetters({
        isModalShown: 'modal/isModalShown',
        forms: 'form/forms',
      }),

      typeTraining() {
      const {cost, kcp, quota_learning} = this.data
      let result = [];
      !!cost && result.push(this.$options.filters.handlerBoolean(this.data.cost, this.$t('paid'), this.$t('free')))
      !!kcp && result.push(this.$options.filters.handlerBoolean(this.data.kcp, this.$t('russianGovernmentQuota')))
      !!quota_learning && result.push(this.$options.filters.handlerBoolean(this.data.quota_learning, this.$t('quotaLearning')))
      return result.join(', ')
    },
     
  },

    data() {
      return {
        modalUniqueId: 'modal-choice',
        modalErrorUniqueId: 'modal-choice-error',
        moment: moment,
        semestersDurationAdditional: {},
        euro: null,
        dollar: null,
      }
    },

    props: {
      data: Object,
      allData: Array,
      isHeaderProgram: Boolean,
      cssVariant: {type: String, default:'variant-2'},
      type: String,
      currency: Object,
    },



    methods: {
      ...mapActions({
        setFavoritesAdd: 'users/setFavoritesAdd',
        unsetFavoritesDelete: 'users/unsetFavoritesDelete',
        setMyChoiceAdd: 'users/setMyChoiceAdd',
        unsetMyChoiceDelete: 'users/unsetMyChoiceDelete',
        getMyChoiceCheck: 'users/getMyChoiceCheck',
      }),

      addFavorite(program) {
        this.$store.commit('preloader/isLoading', true)
        if (program.favorite) {
          this.unsetFavoritesDelete({
            university_program_id: program.id,
          }).then(() => {
            program.favorite = false;
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        } else {
          this.setFavoritesAdd({
            university_id: program.university_id,
            university_program_id: program.id,
          }).then(res => {
            if (res.status === 'success') {
              program.favorite = true;
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        }
      },

      _Choices(program, reset) {
        this.$store.commit('preloader/isLoading', true)
        if (reset) {
          _.map(this.allData, val => {
            val.choice = false;
          });
        }

        this.setMyChoiceAdd({
          program_id: program.program_id,
          university_id: program.university_id,
        }).then(res => {
          if (res.status === 'success') {
            program.choice = true;
          }

          if (res.status === 422) {
            this.targetModal(`${this.modalErrorUniqueId}_${program.id}`);
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false))
      },

      addChoices(program) {
        this.$store.commit('preloader/isLoading', true)
        if (program.choice) {
          this.unsetMyChoiceDelete({
            program_id: program.program_id,
          }).then(() => {
            program.choice = false;
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        } else {
          this.getMyChoiceCheck({
            program_id: program.program_id,
          }).then(r => {
            if (r.status === 'success') {
              this._Choices(program);
            } else {
              this.targetModal(`${this.modalUniqueId}_${program.id}`);
            }
          }).finally(() => this.$store.commit('preloader/isLoading', false))
        }
      },

      targetModal(uniqueId) {
        this.$store.commit(
          'modal/updateShow', {key: uniqueId, value: !this.isModalShown(uniqueId)},
        );
      },

      openCost(id, cost) {
        if (this.forms.programsOpenCost && this.forms.programsOpenCost[id]) {
          this.$store.commit('form/updateField', {model: 'programsOpenCost', value: {}})
        } else {
          this.$store.commit('form/updateField', {model: 'programsOpenCost', value: {}})
          this.$store.commit('form/updateField', {model: `programsOpenCost.${id}`, value: true})
        }

        if (cost && this.currency) {
          this.dollar = Math.ceil(Number(cost) / Number(this.currency.dollar))
          this.euro = Math.ceil(Number(cost) / Number(this.currency.euro))
        }
      },
      parseJSON(json) {
        return JSON.parse(json)
      },
      semestersDuration(duration) {
        let result = '—';

        duration = parseInt(duration)

        if (duration <= 1) {
          result = this.$t('lessThan1Month')
        }

        if (duration > 1 && duration <= 3) {
          result = this.$t('1To3Months')
        }

        if (duration > 3 && duration <= 6) {
          result = this.$t('3To6Months')
        }

        if (duration > 6 && duration <= 12) {
          result = this.$t('6To12Months')
        }

        if (duration > 12) {
          result = this.$t('moreThan1Year')
        }

        return result;
      },
    },

  mounted() {
      this.semestersDurationAdditional = {
        '0-0,99': {id: '0-0,99', name: this.$t('lessThan1Month')},
        '1-2,99': {id: '1-2,99', name: this.$t('1To3Months')},
        '3-5,99': {id: '3-5,99', name: this.$t('3To6Months')},
        '6-11,99': {id: '6-11,99', name: this.$t('6To12Months')},
        '12+': {id: '12+', name: this.$t('moreThan1Year')},
    };
    },
  }
</script>
