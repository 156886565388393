<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div>
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[
              { title: $t('mainPage'), url: '/' },
              { title: $t('help'), url: '/help'},
              { title: $t('forum') },
            ]"
        />

        <div class="forum-title">
          <h1 class="title-1">{{ $t('forum') }}</h1>

          <router-link
            to="/help/forum/my-messages"
            class="btn btn-outline"
            v-if="user.id"
          >{{ $t('myMessages') }}</router-link>
        </div>

        <AlertIsNotAuth />

        <div
          class="forum__item"
          v-for="(category, key) in categories"
          v-bind:key="key"
        >
          <div class="forum__item-title">{{ category | lang('name') }}</div>

          <div
            v-for="(subcat, key) in category.subcat"
            v-bind:key="key"
          > 
            <div 
              class="forum__item-subcat"
              v-if="subcat.name"
            >
              {{subcat.name}}
            </div>
            <div class="forum__item-row"
              v-for="(children, key) in subcat.data"
              :key="key"
            >
              <div class="forum__item-name-massage">
                <router-link
                  :to="`/help/forum/themes/${children.slug}`"
                  class="forum__item-name title-5"
                >{{ children | lang('name') }}</router-link>

                <div class="forum__item-massage">
                  <span class="title-5">{{ children.count }}</span>
                  <span class="light">{{ $t('_themes') }}</span>
                </div>
              </div>

              <figure class="forum__item-user">
                <div class="forum__item-user-avatar" v-if="children.last">
                  <img v-if="children.last.user.avatar" :src="children.last.user.avatar" :alt="children.last.user.username">
                  <img v-if="!children.last.user.avatar" src="@/assets/images/icon/account_circle-24px.svg" :alt="children.last.user.username" />
                </div>

                <figcaption class="forum__item-user-name" v-if="children.last">
                  <span>
                    {{ children.last.user.username }}
                  </span>
                  <span class="light">{{ children.last.time_ago }}</span>
                </figcaption>
              </figure>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import AlertIsNotAuth from '@/components/site/pages/help/forum/generic/AlertIsNotAuth';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';

  export default {
    name: 'help-forum-forum',

    components: {
      AlertIsNotAuth,
      Breadcrumbs,
    },

    data: () => ({
      categories: [],
    }),

    computed: {
      ...mapGetters({
        user: 'users/getUser',
      }),
    },

    methods: {
      ...mapActions({
        getCategories: 'help/getForumCategories',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getCategories().then(res => {
          if (res.status === 'success') {
            this.categories = res.data;
          }
        }).finally(() => this.$store.commit('preloader/isLoading', false))
      },
    },

    mounted() {
      this.getData();
    },
  };
</script>
