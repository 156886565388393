export default {
  russian: "Русский",
  english: "English",
  arab: "العربية",
  chinese: "中文, 汉语",
  spanish: "Español",
  french: "Français",
  portuguese: "Português",
  indian: "हिन्दी",
  vietnamese: "Tiếng Việt",
  malay: "Melayu",
  indomelayu: "Indo-Melayu",
  turkish: " Türk",
  persian: "فارسی",
  uzbek: "O‘zbek",
  german: "Deutsch",
  mongolian: "Монгол",
  help: "Tolong aku",
  support: "Dukungan teknis",
  personalArea: "Ruang pribadi",
  myApplications: "Aplikasi saya",
  servicesAndFacilities: "Layanan dan fasilitas",
  logout: "Keluar",
  favorites: "Lebih suka",
  theChoice: "Pilihan",
  notifications: "Notifikasi",
  logIn: "Memasukkan",
  quickAccessThroughSocialNetworks: "Koneksi cepat melalui jejaring sosial",
  enterYourEmail: "Masukkan email Anda",
  password: "Kata sandi",
  enterPassword: "Masukkan kata kunci",
  rememberMe: "Untuk mengingat saya",
  forgotYourPassword: "lupa kata sandi Anda?",
  noAccount: "Tidak ada akun?",
  registration: "Registrasi",
  countryOfCitizenship: "kewarganegaraan",
  chooseYourCountryOfCitizenship: "Pilih negara kewarganegaraan Anda",
  iHaveReadAndAgree: "Saya membaca dan memberi",
  _toTheProcessingOfMyPersonalData: "menyetujui pemrosesan data pribadi saya",
  iAgreeTo: "Saya setuju",
  _theUseOfCookies: "penggunaan cookie",
  signUp: "Daftar",
  haveAnAccount: "Punya akun?",
  mainPage: "Halaman muka",
  frequentlyAskedQuestionsAnswers:
    "Jawaban atas pertanyaan yang sering diajukan",
  goTo: "Pergi ke",
  forum: "Forum",
  textForumBanner:
    "Diskusikan di forum umum semua topik penting untuk Anda: bagaimana Anda tinggal di kota-kota Rusia, bagaimana Anda belajar di universitas Rusia, dll.",
  feedback: "Umpan balik",
  technicalSupportFeedbackForm: "Formulir Umpan Balik Dukungan Teknis",
  allCities: "semua kota",
  citiesOfRussia: "Kota-kota Rusia",
  educationInRussia: "Pendidikan di Rusia",
  educationalOrganizations: "Organisasi pendidikan",
  educationInRussiaYourKeyToSuccess:
    "Pendidikan di Rusia adalah kunci sukses Anda",
  applyForStudyInRussia: "Daftar untuk belajar di Rusia",
  apply: "Menerapkan",
  logInOrRegisterToAccessCareerGuidanceAndApply:
    "Masuk atau daftar untuk mengakses konseling karier dan melamar",
  aboutOrganization: "Tentang organisasi",
  eventsAndOlympiads: "Acara dan Olimpiade",
  educationalPrograms: "Program edukasi",
  contacts: "Kontak",
  inFavorites: "Di favorit",
  toFavorites: "Ke favorit",
  fromChoices: "Terpilih",
  choices: "Memilih",
  generalInformation: "informasi Umum",
  internationalRatings: "Peluang internasional",
  nationalRatings: "Catatan Nasional",
  ratings: "Ulasan",
  year: "Tahun",
  ratingName: "Nama penilaian",
  aPlace: "Sebuah tempat",
  screeningTests: "Tes skrining",
  choiceOfProfession: "Pilihan profesi",
  lifeInRussia: "Kehidupan di Rusia",
  russianLanguage: "bahasa Rusia",
  livingConditions: "Kondisi hidup",
  cultureAndLeisure: "Budaya dan waktu luang",
  safety: "keamanan",
  benefitsForInternationalStudents: "Manfaat bagi siswa internasional",
  workForInternationalStudents: "Bekerja untuk siswa internasional",
  studentVisaAndRegistration: "Visa pelajar dan registrasi",
  healthInsurance: "Asuransi kesehatan",
  howToEnter: "Cara untuk melanjutkan",
  educationLevels: "Tingkat pendidikan",
  admissionProcess: "Proses penerimaan",
  olympics: "permainan Olimpik",
  officialSiteForTheSelectionOfForeignCitizensToStudyInRussia:
    "Situs web resmi untuk pemilihan warga negara asing untuk belajar di Rusia, {year}",
  whyRussia: "Mengapa Rusia",
  "10reasonsToStudyInRussia": "10 alasan untuk belajar di Rusia",
  reviewsOfForeignStudents: "Review mahasiswa asing",
  onlineMonitoring: "Pemantauan online",
  mainPrograms: "Program utama",
  additionalPrograms: "Program tambahan",
  chooseCounty: "Pilih daerahnya",
  county: "daerah",
  chooseACity: "Pilih kota",
  city: "Kota",
  chooseYourLevelOfEducation: "Pilih tingkat pendidikan Anda",
  chooseYourAreaOfScientificKnowledge: "Pilih bidang pengetahuan ilmiah",
  chooseYourScientificSpecialtyProfile: "Pilih profil spesialisasi ilmiah",
   areaOfScientificKnowledge: "Area of ​​scientific knowledge",
  scientificSpecialtyProfile:"Scientific specialty profile",
  theLevelOfEducation: "Tingkat pendidikan",
  chooseAGroupOfTrainingAreas: "Pilih sekelompok area pelatihan",
  trainingDirectionGroup: "Kelompok Pengarah Pelatihan",
  chooseATrainingDirection: "Pilih arah pelatihan",
  trainingDirection: "Arah pelatihan",
  chooseRatings: "Pilih peringkatnya",
  thePresenceOfThePreparatoryFaculty: "Kehadiran fakultas persiapan",
  show: "Menunjukkan",
  _universities: "Universitas",
  clearAllFilters: "Hapus semua filter",
  chooseSemester: "Pilih semester",
  semester: "Semester",
  enterTheNameOfTheEducationalOrganization:
    "Masukkan nama organisasi pendidikan",
  _educationalOrganizations: "organisasi pendidikan",
  chooseYourAreaOfEducation: "Pilih bidang studi Anda",
  areaOfEducation: "Bidang pendidikan",
  chooseYourLanguageOfInstruction: "Pilih bahasa pengantar Anda",
  languageOfInstruction: "Bahasa pendidikan",
  _programs: "program",
  formOfTraining: "Formulir studi",
  level: "Tingkat",
  typeOfTraining: "Jenis pelatihan",
  free: "Gratis",
  paid: "Dibayar",
  tuitionFees: "Biaya pendidikan",
  established: "Mapan",
  numberOfStudents: "Jumlah siswa",
  _students: "murid",
  numberOfQuotaPlaces: "Jumlah tempat kuota",
  _for: "",
  numberOfInternationalStudents: "Jumlah siswa internasional",
  _educationalPrograms: "Program edukasi",
  moreDetails: "Keterangan lebih lanjut",
  sTEP: "LANGKAH",
  afterTrainingForThisProgramYouCanBecome:
    "Setelah pelatihan untuk program ini, Anda bisa menjadi",
  chooseProgram: "Pilih programnya",
  chooseUniversity: "Pilih universitas",
  print: "Kesan",
  save: "untuk menyelamatkan",
  programs: "Program",
  selectedProgram: "Program yang dipilih",
  noProgramSelected: "Tidak ada program yang dipilih",
  selectedEducationalOrganizations: "Organisasi pendidikan yang dipilih",
  educationalOrganizationNotSelected: "Organisasi pendidikan tidak dipilih",
  representation: "Perwakilan",
  programDescription: "Deskripsi program",
  programPageOnTheUniversityWebsite: "Halaman program di situs web universitas",
  programCurator: "Kurator program",
  disciplines: "Disiplin",
  theProcessOfEnteringARussianEducationalOrganization:
    "Proses memasuki organisasi pendidikan Rusia",
  chooseAProgramAndUpTo6EducationalOrganizations:
    "Pilih program dan maksimal 6 organisasi pendidikan",
  takeTheCompetitiveSelectionInYourCountry:
    "Tentukan pilihan kompetitif di negara Anda",
  fillOutTheApplicationOnlineAndAttachThePackageOfDocuments:
    "Lengkapi aplikasi online dan lampirkan paket dokumen",
  getAnInvitationToStudAnStudyVisa:
    "Dapatkan undangan untuk belajar dan belajar visa",
  preparatoryFaculties: "Fakultas persiapan",
  summerAndWinterSchools: "Sekolah musim panas dan musim dingin",
  russianLanguageCourses: "Pelajaran bahasa Rusia",
  tutorsTheRussianLanguage: "Guru bahasa Rusia",
  certificationInRussian: "Sertifikasi dalam bahasa Rusia",
  news: "berita",
  allNews: "Semua berita",
  reviewsOfStudentsAndGraduates: "Ulasan siswa dan lulusan",
  areYouSureYouWantToWithdrawTheApplication:
    "Anda yakin ingin membatalkan permintaan?",
  withdrawApplication: "Cabut permintaan tersebut",
  jan: "Januari",
  feb: "Februari",
  mar: "Maret",
  apr: "April",
  may: "Mungkin",
  jun: "Juni",
  jul: "Juli",
  aug: "Agustus",
  sep: "September",
  act: "Oktober",
  nov: "November",
  dec: "Desember",
  cityFeatures: "Karakteristik kota",
  enterTheNameOfTheCity: "masukkan nama kota",
  organizations: "Organisasi",
  livingWage: "Gaji yang layak",
  "one-roomApartmentForRent": "Apartemen 1 kamar disewakan",
  _month: "bulan",
  averageTemperature: "suhu rata-rata",
  _inWinter: "di musim dingin",
  _inSummer: "di musim panas",
  averageCostOfDormitories: "Biaya rata-rata asrama",
  photos: "Foto-foto",
  interestingPlaces: "Tempat yang menarik",
  readCompletely: "Baca dengan lengkap",
  university: "Université",
  country: "Negara",
  selectACountry: "Pilih satu negara",
  reviewOfAForeignStudent: "Pemeriksaan siswa asing",
  careerGuidance: "Orientasi profesional",
  determineYourAreaOfEducationAndProfession:
    "Tentukan bidang studi dan profesi Anda",
  myCareer: "Karir saya",
  defineYourGroupOfTrainingAreasAndFindOutPossibleOptionsForYourFutureProfession:
    "Tentukan kelompok area pelatihan Anda dan temukan opsi yang memungkinkan untuk profesi masa depan Anda",
  toApply: "Menerapkan",
  chooseYear: "Pilih tahun",
  _university: "Universitas",
  __universities: "universitas",
  _educationalOrganization: "organisasi pendidikan",
  __educationalOrganizations: "organisasi pendidikan",
  _program: "program",
  __programs: "program",
  _reviews: "Komentar",
  __reviews: "Komentar",
  universities: "Universitas",
  _in: "di",
  universitiesInThisCity: "Universitas di kota ini",
  programsInThisCity: "Program di kota ini",
  all: "Semua",
  _inYourCountry: "di negaramu",
  _common: "umum",
  allReviews: "Semua ulasan",
  calendar: "Kalender",
  activity: "Aktivitas",
  addToPersonalCalendar: "Tambahkan ke kalender pribadi",
  eventAdded: "Événement ajouté",
  eventSuccessfullyAddedToYourCalendar:
    "Acara berhasil ditambahkan ke kalender Anda!",
  population: "Populasi",
  onTheMap: "Di peta",
  generalEducation: "Pendidikan umum",
  professionalEducation: "Pendidikan kejuruan",
  preschoolEducation: "Pendidikan prasekolah",
  kindergarten: "Taman kanak-kanak",
  nursery: "Taman Kanak-kanak",
  earlyDevelopmentCenters: "Pusat pengembangan awal",
  primaryAndSecondaryEducation: "Pendidikan dasar dan menengah",
  schools: "Sekolah",
  lyceums: "SMA",
  gymnasium: "Gym",
  secondaryVocationalEducation: "Pendidikan kejuruan menengah",
  colleges: "Perguruan tinggi",
  technicalSchools: "Sekolah teknik",
  secondarySchool: "Pelajaran kedua",
  higherEducation: "Pendidikan yang lebih tinggi",
  baccalaureate: "sekolah Menengah",
  specialistDegree: "Ijazah spesialis",
  magistracy: "Yudisial",
  postgraduateStudies: "studi pascasarjana",
  preparatoryFaculty: "Fakultas Persiapan",
  summerSchools: "Sekolah musim panas",
  additionalGeneralAndPreVocationalPrograms:
    "Program umum dan pra-profesional tambahan",
  continuingEducationAndRetrainingPrograms:
    "Pendidikan berkelanjutan dan program pelatihan ulang.",
  whatAreTheLevelsOfRussianEducation: "Bagaimana tingkat pendidikan Rusia?",
  enterYourName: "Masukkan nama Anda",
  name: "nama keluarga",
  selectTheSubjectOfTheMessage: "Pilih subjek pesan",
  messageSubject: "Objek pesan",
  chooseAMessage: "Pilih pesan",
  message: "pesan",
  submit: "Kirimkan",
  frequentlyAskedQuestions: "Pertanyaan yang sering diajukan",
  didNotFindTheAnswerToYourQuestion:
    "Anda belum menemukan jawaban atas pertanyaan Anda?",
  contactSupportAt: "Hubungi dukungan di",
  searchInTheSection: "Cari di bagian",
  sort: "Untuk memesan",
  searchingResults: "Hasil Pencarian",
  _answers: "tanggapan",
  _views: "sudut pandang",
  byCreationDate: "Menurut tanggal pembuatan",
  byTheNumberOfResponses: "Berdasarkan jumlah tanggapan",
  byViews: "Menurut tampilan",
  toAnswer: "Balasan",
  toQuote: "Mengutip",
  complain: "Mengeluh",
  addNewMessage: "Tambahkan pesan baru",
  selectComplaintType: "Pilih jenis keluhan",
  typeOfComplaint: "Jenis keluhan",
  enterComplaintText: "Masukkan teks pengaduan",
  complaintText: "Teks pengaduan",
  complaintSentSuccessfully: "Keluhan berhasil dikirim",
  yourComplaintHasBeenSentSuccessfullyWeWillReviewYourComplaintShortly:
    "Keluhan Anda telah berhasil dikirim, kami akan segera memeriksanya!",
  close: "Cari",
  myMessages: "Pesan saya",
  myTopicsAndPosts: "Topik dan publikasi saya",
  searchInMyPosts: "Cari publikasi saya",
  _posts: "publikasi",
  feedbackOnEducationalOrganizations: "Komentar tentang organisasi pendidikan",
  posted: "Sadar",
  goToMessage: "Buka pesan",
  _themes: "tema",
  themeTitle: "judul topik",
  enterAThemeName: "Masukkan nama subjek",
  cancel: "Untuk membatalkan",
  banUser: "Cekal pengguna?",
  areYouSure: "Apakah kamu yakin",
  toBan: "Melarang",
  _logIn: "memulai sesi",
  _or: "atau",
  _signUp: "Daftar",
  eventCalendar: "Kalender acara",
  trainingApplicationsFiled: "Permintaan pelatihan dikirim",
  theMostPopularEducationalOrganizationsChosenByApplicants:
    "Organisasi pendidikan paling populer yang dipilih oleh pelamar",
  _: "dari",
  _whenApplying: "Saat melamar",
  tOP10EducationalOrganizations: "TOP 10 organisasi pendidikan",
  collapse: "Jatuh",
  showAll: "Tunjukkan semua",
  conditionsForForeigners: "Ketentuan untuk orang asing",
  supportForInternationalStudents: "Dukungan untuk siswa internasional",
  lifeOfForeignStudents: "Kehidupan mahasiswa asing",
  leisureAndSportingEvents: "Acara rekreasi dan olahraga",
  dormitories: "Kamar tidur",
  dormitoryCost: "Biaya asrama",
  theNumberOfPeopleInTheRoom: "Jumlah orang di dalam ruangan",
  from: "dari",
  _rubles: "rubel",
  _to: "di",
  _perMonth: "per bulan",
  "2-3People": "2-3 orang",
  theInternationalCooperation: "Kerjasama internasional",
  photoAndVideo: "Foto dan video",
  eventTitle: "Judul acara",
  location: "Lokasi",
  date: "Bertanggal",
  enterYourRealMail: "Masukkan email asli Anda",
  requiredField: "bidang yang harus diisi",
  minimumLengthCharacters: "Karakter panjang minimum {length}",
  maximumLengthCharacters: "Panjang maksimum {length} karakter",
  theFieldMustBeANumber: "Bidang harus berupa angka.",
  onlyLettersAreAllowedInTheField: "Hanya huruf yang diperbolehkan di lapangan",
  checkKeyboardLayout: "Periksa tata letak keyboard",
  checkTheNumberYouEnteredIsCorrect:
    "Periksa apakah nomor yang Anda masukkan benar",
  olympiadName: "Nama Olimpiade",
  description: "Deskripsi",
  contactInformation: "Kontak informasi",
  majorForeignPartnerUniversities: "Universitas mitra asing utama",
  learnAboutRussianLanguageFormsProgramsAndTuitionFees:
    "Pelajari lebih lanjut tentang formulir, program, dan biaya pendaftaran Rusia",
  areYouATutorSubmitAProfileToPublishYourProfileOnTheSite:
    "Apakah Anda seorang tutor? Kirimkan profil untuk mempublikasikan profil Anda di situs",
  submitApplication: "Ajukan lamaran",
  chooseATeachingExperience: "Pilih pengalaman mengajar",
  teachingExperience: "Pengalaman mengajar",
  chooseLanguages: "Pilih bahasa",
  knowledgeOfLanguages: "Pengetahuan tentang bahasa",
  workExperience: "Pengalaman kerja",
  trainingLevel: "Tingkat pendidikan",
  durationOfOneLesson: "durasi pelajaran",
  possibleTrainingTime: "Waktu pelatihan yang memungkinkan",
  theCosOfOneLesson: "Biaya pelajaran",
  writeAMessage: "untuk menulis pesan",
  youCanWriteToATutorAfterRegistrationOrAuthorizationOnTheSite:
    "Anda dapat menulis ke tutor setelah pendaftaran atau otorisasi di situs",
  educationDocuments: "Dokumen pendidikan",
  youHaveAlreadySelectedAnotherProgramIfYouSelectThisProgramTheSelectedProgramAndOrganizationsWillBeLost:
    "Anda telah memilih program lain, jika Anda memilih program ini, program dan organisasi yang dipilih akan hilang.",
  limitIsExceeded:
    "Anda memiliki opsi untuk tidak memilih lebih dari 6 organisasi pendidikan.",
  youHaveHheOpportunityToChooseNoMoreThan6EducationalOrganizations:
    "Anda memiliki opsi untuk tidak memilih lebih dari 6 organisasi pendidikan.",
  _fieldsOfEducation: "area pengajaran",
  _trainingAreas: "area pelatihan",
  _cities: "kota",
  _languagesOfInstruction: "bahasa pengantar",
  _typesOfAdditionalPrograms: "jenis program tambahan",
  _semesters: "semester",
  chooseDurationOfStudy: "Pilih durasi studi",
  _durationOfStudy: "Durasi studi",
  enterTheNameOfTheEducationalProgram: "Masukkan nama program pendidikan",
  instruction: "Petunjuk",
  profile: "Profil",
  back: "Profil",
  next: "Lanjut",
  testReport: "Laporan pengujian",
  testDate: "Tanggal tes",
  duration: "Duración",
  memberName: "Nama anggota",
  age: "Bertahun-tahun",
  gender: "Jenis kelamin",
  currentLevelOfEducation: "Tingkat pendidikan saat ini",
  areasOfInterestHobbies: "Bidang minat, hobi",
  sportsAchievements: "Keberhasilan olahraga",
  testScaleResults: "Hasil skala uji",
  scaleDescription: "Deskripsi skala",
  recommendedEducationAreas: "Area pengajaran yang direkomendasikan",
  enterYourAge: "Masukkan Umur anda",
  enterYourGender: "Masukkan jenis kelamin Anda",
  chooseYourCurrentLevelOfEducation: "Pilih tingkat pendidikan Anda saat ini",
  chooseYourAreasOfInterestHobbies: "Pilih minat Anda, hobi Anda",
  chooseYourSportingAchievements: "Pilih prestasi olahraga Anda",
  legalizationOfDocuments: "Legalisasi dokumen",
  consularLegalization: "Legalisasi konsuler",
  edit: "Sunting",
  mobilePhoneNumber: "nomor telepon",
  iWantToReceiveNotificationsByEmail:
    "Saya ingin menerima pemberitahuan melalui email",
  authorizationCheck: "Pemeriksaan otorisasi",
  createANewApplication: "Buat aplikasi baru",
  selectionStage: "langkah seleksi",
  underRevision: "Di ruang kerja",
  fillingOutAnApplication: "Selesaikan permintaan",
  verificationAndRevision: "Verifikasi dan review",
  dossierFormation: "Pembentukan file",
  fillingInTheQuestionnaire: "Isi kuesioner",
  attachingDocuments: "Lampirkan dokumen",
  sendingApplicationForVerification: "Pengajuan permintaan verifikasi",
  personalData: "Informasi pribadi",
  surnameInLatinLettersAccordingToPassport:
    "Nama belakang, dalam huruf latin (sesuai paspor)",
  nameInLatinLettersAccordingToPassport:
    "Nama, dalam huruf latin (sesuai paspor)",
  placeOfBirthAccordingToPassport: "Tempat lahir (sesuai paspor)",
  dateOfBirth: "Tanggal lahir",
  compatriotStatus: "Negara bagian kompatriot",
  citizenshipPlaceOfPermanentResidence:
    "Kewarganegaraan (tempat tinggal permanen)",
  online: "On line",
  profile2: "Profil",
  goToTheApplicationForm: "Buka formulir permintaan",
  areYouHere: "Kamu adalah",
  russianTextDes1:
    "Bagaimana mempersiapkan untuk belajar di universitas Rusia, mata pelajaran apa yang dipelajari di fakultas persiapan dan berapa lama waktu yang dibutuhkan",
  russianTextDes4:
    "Pelajari lebih lanjut tentang formulir, program, dan biaya sekolah bahasa Rusia",
  russianTextDes5:
    "Bagaimana mempersiapkan untuk belajar di universitas Rusia, mata pelajaran apa yang dipelajari di fakultas persiapan dan berapa lama waktu yang dibutuhkan",
  typeOfProgram: "Jenis program",
  chooseTheTypeOfAdditionalProgram: "Pilih jenis program tambahan",
  durationOfStudy: "Durasi studi",
  common: "Umum",
  inYourCountry: "Di negaramu",
  courseName: "Nama kursus",
  courseDuration: "Durasi kursus",
  classDuration: "Durasi kursus",
  courseCost: "Biaya kursus",
  courseReviews: "Avis sur les cours",
  male: "Pria",
  female: "Istri",
  feedbackLink: "Tautan ke ulasan",
  russianLanguageCoursesInYourCountry: "Pelajaran bahasa Rusia di negara Anda",
  evaluation: "Évaluation",
  giveFeedback: "bagikan pendapat Anda",
  newFeedback: "Umpan balik baru",
  _hour: "jam",
  _hours: "",
  __hours: "heures",
  _minute: "menit",
  _minutes: "menit",
  __minutes: "menit",
  create: "Membuat",
  feedbackSuccess: "Komentar berhasil dikirim dan menunggu moderasi",
  _y: "y",
  _from: "dari",
  error: "Kesalahan",
  direction: "Arah",
  _tutor: "tutor",
  _tutors: "penjaga",
  __tutors: "penjaga",
  sendMessage: "Kirim pesannya",
  sendMessageSuccess: "pesan berhasil dikirim",
  _year: "tahun",
  _years: "tahun",
  __years: "tahun",
  to_begin: "pergi ke awal",
  _next: "lebih lanjut",
  showing: "Menunjukkan",
  textRussianLanguageCourses:
    "Pilih negara tempat tinggal Anda dari menu dan lihat kursus bahasa dan sekolah yang tersedia. Memperoleh dasar-dasar level Rusia di rumah akan sangat memudahkan adaptasi saat bergerak.",
  filters: "Filter",
  middleNameIAnyCyrillicInRussianTranscription:
    "Nama belakang (jika ada), Sirilik dalam transkripsi Rusia",
  tOP10Programs: "Program TOP-10",
  tOP10City: "TOP 10 kota Rusia",
  createANewTopic: "Buat topik baru",
  topics: "Subjek",
  posts: "Posting",
  filesOfTheFormatPdfJpgPngUpTo10MB:
    "File dalam format .pdf, .jpg, .png hingga 10 MB",
  noFileSelected: "Tidak ada file yang dipilih",
  attachFile: "Lampiran",
  eMail: "Email",
  fullTime: "Waktu penuh",
  toFind: "Temukan",
  searchInTopic: "Telusuri subjek",
  lessThan1Month: "kurang dari 1 bulan",
  "1To3Months": "1 sampai 3 bulan",
  "3To6Months": "3-6 bulan",
  "6To12Months": "6 sampai 12 bulan",
  moreThan1Year: "Lebih dari setahun",
  theDateOfThe: "Tanggal",

  chat: "Bot obrolan",
  yourMessageChat: "Pesan Anda",
  searchChat: "Pencarian dialog",
  firstMsgChat: "Masukkan pesan anda",
  categoryBtnChat_1: "Pertanyaan lamaran",
  categoryBtnChat_2: "Pertanyaan pelatihan",
  categoryBtnChat_3: "Pertanyaan tentang seleksi kompetitif",
  categoryBtnChat_4: "Saran atau komentar",
  categoryBtnChat_5: "Umpan balik tentang kegiatan universitas",
  categoryBtnChat_6: "Keluhan tentang kualitas layanan",
  categoryBtnChat_7: "Menghubungi dukungan teknis",
  notQuestionResolvedChat: "Pertanyaan saya tidak terselesaikan",
  questionResolvedChat: "Pertanyaan saya terselesaikan",
  countrySelectTrueChat: "Negara benar",
  salutationChat: "Halo,",
  categoryAppealChat:
    'Untuk informasi, pilih kategori pertanyaan Anda dengan mengklik tombol. Setelah memilih kategori, masukkan pertanyaan Anda di pesan dan pilih tombol "Kirim".',
  langAppealChat:
    "Pilih bahasa yang Anda inginkan untuk mengirim permintaan. Aplikasi yang dikirim dalam bahasa Rusia dan Inggris akan diproses paling cepat.",
  youCountryChatPath1: "Negaramu",
  youCountryChatPath2: "Jika tidak, berikan nama negara dengan pesan balasan.",
  notFoundCountryChat:
    "Negara dengan nama yang Anda tentukan tidak ditemukan. Periksa kebenaran data yang dimasukkan dan kirim ulang pesannya.",
  additionalInfoChat:
    "Harap tunjukkan dalam pesan tanggapan informasi tambahan tentang permintaan Anda.",
  yourEmailChat:
    "Silakan masukkan alamat email Anda. Tanggapan atas permintaan Anda akan dikirim melalui email.",
  notValidEmailChat:
    "Alamat email yang Anda kirim tidak sesuai dengan format aaa@bbb.ccc. Periksa kebenaran data yang dimasukkan dan kirim ulang pesannya.",
  acceptRequestChat:
    "Banding Anda telah dikirim ke operator. Waktu pemrosesan rata-rata adalah 7 hari kalender.",
  langBtnChat_1: "Inggris",
  langBtnChat_2: "Rusia",
  langBtnChat_3: "Lain",
  captcha: "in_ID",
  listAppBtn: "Daftar aplikasi",
  addAppBtn: "Kirim aplikasi",
  startTesting: "Mulai pengujian",
  buildCareerVector: "Bangun vektor karier",
  allEvents:"Semua acara",
  banner_1: "The portal is in trial mode!",
  banner_2: "Dear Users, There may be technical difficulties with using Google mail addresses at this time. Please use other mail services when working with the portal.",
  banner_3: "Admission campaign starts December 24, 2022.",
  typeOfEducation: "Вид образования",
  chooseTheTypeOfEducation: "Выберите вид образования",
};
