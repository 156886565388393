<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page home__page">
    <div class="home__page-container">

      <div class="main__info">
        <div class="container">
          <div class="main__info-container">
            <div class="main__info-item">
              <h1 class="title-1" v-html="$t('educationInRussiaYourKeyToSuccess')" />
              <p>{{ $t('applyForStudyInRussia') }}</p>

              <a v-if="user.org_id"
                :href="user.org_id === '00' ? '/settings/profile/applications' : '/settings/applications-register'"
                class="btn btn-big btn-linear">{{ user.org_id === '00' ? `${$t('addAppBtn')}` : `${$t('listAppBtn')}`
                }}</a>
            </div>

            <div class="main__info-item" v-if="!(user || {}).id">
              {{ $t('logInOrRegisterToAccessCareerGuidanceAndApply') }}
              <a :href="`${avanpost}/oauth2/authorize?lang=${getLocale}&client_id=educationinrussia&redirect_uri=${link}/account/login&scope=openid%20profile%20email%20groups%20additional_info&response_type=code`"
                class="btn">{{ $t('logIn') }}</a>

              <a :href="`${avanpost}/user/self/register?lang=${getLocale}`" class="btn btn-outline">{{ $t('signUp') }}</a>
            </div>
          </div>

          <div class="program lg-hide">
            <ul class="nav-tabs">
              <li class="nav-item">
                <a class="nav-link" @click.prevent="active = 'program'" :class="{ active: active === 'program' }"
                  href="#item">{{ $t('programs') }}</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" @click.prevent="active = 'university'" :class="{ active: active === 'university' }"
                  href="#item2">{{ $t('universities') }}</a>
              </li>
            </ul>
            <!-- -- desktop  -- -->
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" :class="{ 'active show': active === 'program' }" id="item">
                <ul class="nav-tabs-sub">
                  <li class="nav-item-sub">
                    <a class="nav-link-sub" @click.prevent="activeProgram = 'main'"
                      :class="{ active: activeProgram === 'main' }" href="#item-sub3">{{ $t('mainPrograms') }}</a>
                  </li>

                  <li class="nav-item-sub">
                    <a class="nav-link-sub" @click.prevent="activeProgram = 'additional'"
                      :class="{ active: activeProgram === 'additional' }" href="#item-sub4">{{ $t('additionalPrograms')
                      }}</a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div class="tab-pane" :class="{ 'active show': activeProgram === 'main' }" id="item-sub3">
                    <div class="program-content-search">
                      <MultiSelect :options="levelEducation" :placeholder="$t('chooseYourLevelOfEducation')"
                        store-path="filter.programs.main.levelEducation" :label="$t('theLevelOfEducation')"
                        form-row-class="form-row mb0" :callback-on-change="() => {
                          updateFilters('main', 'program')
                          onChangeSelect('levelEducation')
                        }" />

                      <MultiSelect :options="areaEducation" :placeholder="$t('chooseYourAreaOfEducation')"
                        store-path="filter.programs.main.areaEducation" :label="$t('areaOfEducation')"
                        form-row-class="form-row mb0" v-if="!isLevelEducation" />

                      <!-- -- область научных знаний -- -->

                      <MultiSelect :options="scientificAreas" :placeholder="$t('chooseYourAreaOfScientificKnowledge')"
                        store-path="filter.programs.main.scientificAreas" :label="$t('areaOfScientificKnowledge')"
                        form-row-class="form-row mb0" v-if="isLevelEducation" />

                      <a href="javascript:void(0)" class="btn btn-search"
                        @click="() => goToUrl('/education-in-russia/programs', 'main')"></a>
                    </div>
                  </div>

                  <div class="tab-pane" :class="{ 'active show': activeProgram === 'additional' }" id="item-sub4">
                    <div class="program-content-search">
                      <MultiSelect :options="groupDirections" :placeholder="$t('chooseAGroupOfTrainingAreas')"
                        store-path="filter.programs.additional.groupDirection" :label="$t('trainingDirectionGroup')"
                        :callback-on-change="() => updateFilters('additional', 'program')" form-row-class="form-row mb0"
                        hidden />
                      <MultiSelect :options="educationalProgramsProgramAdditional"
                        :placeholder="$t('chooseATrainingDirection')"
                        store-path="filter.programs.additional.educationalProgram" :label="$t('trainingDirection')"
                        form-row-class="form-row mb0" hidden />
                      <MultiSelect :options="semesters" :placeholder="$t('chooseSemester')"
                        store-path="filter.programs.additional.semester" :label="$t('semester')"
                        form-row-class="form-row mb0" />
                      <MultiSelect :options="semestersDurationAdditional" :placeholder="$t('chooseDurationOfStudy')"
                        store-path="filter.programs.additional.semesterDurationAdditional" :label="$t('durationOfStudy')"
                        custom-label="name" custom-value="id" form-row-class="form-row mb0" allow-empty />

                      <a href="javascript:void(0)" class="btn btn-search"
                        @click="() => goToUrl('/education-in-russia/programs', 'additional')"></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" :class="{ 'active show': active === 'university' }" id="item2">
                <ul class="nav-tabs-sub">
                  <li class="nav-item-sub">
                    <a class="nav-link-sub" @click.prevent="activeUniversities = 'main'"
                      :class="{ 'active show': activeUniversities === 'main' }" href="#item-sub5">{{ $t('mainPrograms')
                      }}</a>
                  </li>

                  <li class="nav-item-sub">
                    <a class="nav-link-sub" @click.prevent="activeUniversities = 'additional'"
                      :class="{ 'active show': activeUniversities === 'additional' }" href="#item-sub6">{{
                        $t('additionalPrograms') }}</a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div class="tab-pane" :class="{ 'active show': activeUniversities === 'main' }" id="item-sub5">
                    <div class="program-content-search">
                      <MultiSelect :options="educationalProgramsUniversity" :placeholder="$t('chooseATrainingDirection')"
                        store-path="filter.universities.main.educationalProgram" :label="$t('trainingDirection')"
                        form-row-class="form-row mb0" />

                      <MultiSelect :options="universitiesGetCities" :placeholder="$t('chooseACity')"
                        store-path="filter.universities.main.city" :label="$t('city')" form-row-class="form-row mb0" />

                      <a href="javascript:void(0)" class="btn btn-search"
                        @click="() => goToUrl('/education-in-russia/universities', 'main')"></a>
                    </div>
                  </div>

                  <div class="tab-pane" :class="{ 'active show': activeUniversities === 'additional' }" id="item-sub6">
                    <div class="program-content-search">
                      <MultiSelect :options="semesters" :placeholder="$t('chooseSemester')"
                        store-path="filter.universities.additional.semester" :label="$t('semester')"
                        form-row-class="form-row mb0" />
                      <MultiSelect :options="semestersDurationAdditional" :placeholder="$t('chooseDurationOfStudy')"
                        store-path="filter.universities.additional.semesterDurationAdditional"
                        :label="$t('durationOfStudy')" custom-label="name" custom-value="id" form-row-class="form-row mb0"
                        allow-empty />

                      <a href="javascript:void(0)" class="btn btn-search"
                        @click="() => goToUrl('/education-in-russia/universities', 'additional')"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="program d-none lg-show">
        <div class="nav-tabs-wrap">
          <ul class="nav-tabs">
            <li class="nav-item">
              <a class="nav-link" @click.prevent="active = 'program'" :class="{ active: active === 'program' }"
                href="#item">{{ $t('programs') }}</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" @click.prevent="active = 'university'" :class="{ active: active === 'university' }"
                href="#item2">{{ $t('universities') }}</a>
            </li>
          </ul>
        </div>
        <!-- -- mobile  -- -->
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane" :class="{ 'active show': active === 'program' }" id="item">
            <div class="nav-tabs-sub-wrap">
              <ul class="nav-tabs-sub">
                <li class="nav-item-sub">
                  <a class="nav-link-sub" @click.prevent="activeProgram = 'main'"
                    :class="{ active: activeProgram === 'main' }" href="#item-sub3">{{ $t('mainPrograms') }}</a>
                </li>

                <li class="nav-item-sub">
                  <a class="nav-link-sub" @click.prevent="activeProgram = 'additional'"
                    :class="{ active: activeProgram === 'additional' }" href="#item-sub4">{{ $t('additionalPrograms')
                    }}</a>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div class="tab-pane" :class="{ 'active show': activeProgram === 'main' }" id="item-sub3">
                <div class="program-content-search">
                  <MultiSelect :options="levelEducation" :placeholder="$t('chooseYourLevelOfEducation')"
                    store-path="filter.programs.main.levelEducation" :label="$t('theLevelOfEducation')"
                    form-row-class="form-row mb0" :callback-on-change="() => {
                      updateFilters('main', 'program')
                      onChangeSelect('levelEducation')
                    }"/>

                  <MultiSelect :options="areaEducation" :placeholder="$t('chooseYourAreaOfEducation')"
                    store-path="filter.programs.main.areaEducation" :label="$t('areaOfEducation')"
                    form-row-class="form-row mb0" v-if="!isLevelEducation"/>

                    <!-- -- область научных знаний -- -->
                  <MultiSelect :options="scientificAreas" :placeholder="$t('chooseYourAreaOfScientificKnowledge')"
                    store-path="filter.programs.main.scientificAreas" :label="$t('areaOfScientificKnowledge')"
                    form-row-class="form-row mb0" v-if="isLevelEducation" />

                  <a href="javascript:void(0)" class="btn btn-search"
                    @click="() => goToUrl('/education-in-russia/programs', 'main')">{{ $t('toFind') }}</a>
                </div>
              </div>

              <div class="tab-pane" :class="{ 'active show': activeProgram === 'additional' }" id="item-sub4">
                <div class="program-content-search">
                  <MultiSelect :options="groupDirections" :placeholder="$t('chooseAGroupOfTrainingAreas')"
                    store-path="filter.programs.additional.groupDirection" :label="$t('trainingDirectionGroup')"
                    :callback-on-change="() => updateFilters('additional', 'program')" form-row-class="form-row mb0" hidden />
                  <MultiSelect :options="educationalProgramsProgramAdditional"
                    :placeholder="$t('chooseATrainingDirection')"
                    store-path="filter.programs.additional.educationalProgram" :label="$t('trainingDirection')"
                    form-row-class="form-row mb0" hidden />
                  <MultiSelect :options="semesters" :placeholder="$t('chooseSemester')"
                    store-path="filter.programs.additional.semester" :label="$t('semester')"
                    form-row-class="form-row mb0" />
                  <MultiSelect :options="semestersDurationAdditional" :placeholder="$t('chooseDurationOfStudy')"
                    store-path="filter.programs.additional.semesterDurationAdditional" :label="$t('durationOfStudy')"
                    custom-label="name" custom-value="id" form-row-class="form-row mb0" allow-empty />

                  <a href="javascript:void(0)" class="btn btn-search"
                    @click="() => goToUrl('/education-in-russia/programs', 'additional')">{{ $t('toFind') }}</a>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane" :class="{ 'active show': active === 'university' }" id="item2">
            <div class="nav-tabs-sub-wrap">
              <ul class="nav-tabs-sub">
                <li class="nav-item-sub">
                  <a class="nav-link-sub" @click.prevent="activeUniversities = 'main'"
                    :class="{ 'active show': activeUniversities === 'main' }" href="#item-sub5">{{ $t('mainPrograms')
                    }}</a>
                </li>

                <li class="nav-item-sub">
                  <a class="nav-link-sub" @click.prevent="activeUniversities = 'additional'"
                    :class="{ 'active show': activeUniversities === 'additional' }" href="#item-sub6">{{
                      $t('additionalPrograms') }}</a>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div class="tab-pane" :class="{ 'active show': activeUniversities === 'main' }" id="item-sub5">
                <div class="program-content-search">
                  <MultiSelect :options="educationalProgramsUniversity" :placeholder="$t('chooseATrainingDirection')"
                    store-path="filter.universities.main.educationalProgram" :label="$t('trainingDirection')"
                    form-row-class="form-row mb0" />

                  <MultiSelect :options="universitiesGetCities" :placeholder="$t('chooseACity')"
                    store-path="filter.universities.main.city" :label="$t('city')" form-row-class="form-row mb0" />

                  <a href="javascript:void(0)" class="btn btn-search"
                    @click="() => goToUrl('/education-in-russia/universities', 'main')">{{ $t('toFind') }}</a>
                </div>
              </div>

              <div class="tab-pane" :class="{ 'active show': activeUniversities === 'additional' }" id="item-sub6">
                <div class="program-content-search">
                     <MultiSelect :options="semesters" :placeholder="$t('chooseSemester')"
                        store-path="filter.universities.additional.semester" :label="$t('semester')"
                        form-row-class="form-row mb0" />

                      <MultiSelect :options="semestersDurationAdditional" :placeholder="$t('chooseDurationOfStudy')"
                        store-path="filter.universities.additional.semesterDurationAdditional"
                        :label="$t('durationOfStudy')" custom-label="name" custom-value="id" form-row-class="form-row mb0"
                        allow-empty />

                  <a href="javascript:void(0)" class="btn btn-search"
                    @click="() => goToUrl('/education-in-russia/universities', 'additional')">{{ $t('toFind') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container step__item-wrapper">
        <div class="row">
          <div class="col-4">
            <div class="def-container">
              <h3 class="title-3">
                {{ $t('theProcessOfEnteringARussianEducationalOrganization') }}
              </h3>
              <div class="step">
                <figure class="step__item">
                  <div class="step__item-title">
                    01
                    <div class="step__item-title-icon">
                      <img src="@/assets/images/step-1.png" alt="">
                    </div>
                  </div>
                  <figcaption class="step__item-content">
                    {{ $t('chooseAProgramAndUpTo6EducationalOrganizations') }}
                  </figcaption>
                </figure>
                <figure class="step__item">
                  <div class="step__item-title">
                    03
                    <div class="step__item-title-icon">
                      <img src="@/assets/images/step-3.png" alt="">
                    </div>
                  </div>
                  <figcaption class="step__item-content">
                    {{ $t('takeTheCompetitiveSelectionInYourCountry') }}
                  </figcaption>
                </figure>
                <figure class="step__item">
                  <div class="step__item-title">
                    02
                    <div class="step__item-title-icon">
                      <img src="@/assets/images/step-2.png" alt="">
                    </div>
                  </div>
                  <figcaption class="step__item-content">
                    {{ $t('fillOutTheApplicationOnlineAndAttachThePackageOfDocuments') }}
                  </figcaption>
                </figure>
                <figure class="step__item">
                  <div class="step__item-title">
                    04
                    <div class="step__item-title-icon">
                      <img src="@/assets/images/step-4.png" alt="">
                    </div>
                  </div>
                  <figcaption class="step__item-content">
                    {{ $t('getAnInvitationToStudAnStudyVisa') }}
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
          <div class="col-2">
            <figure class="info__card">
              <div class="info__card-img">
                <img src="@/assets/images/card-1.png" alt="">
              </div>
              <figcaption class="info__card-content">
                <h3 class="title-3">
                  {{ $t('russianLanguage') }}
                </h3>

                <ul class="info__card-list">
                  <li>
                    <router-link to="/education-in-russia/choice-of-profession/preparatory-faculties"
                      class="info__card-link">{{ $t('preparatoryFaculties') }}</router-link>
                  </li>

                  <li>
                    <router-link to="/education-in-russia/choice-of-profession/summer-and-winter-schools"
                      class="info__card-link">{{ $t('summerAndWinterSchools') }}</router-link>
                  </li>

                  <li>
                    <router-link to="/education-in-russia/choice-of-profession/russian-language-courses"
                      class="info__card-link">{{ $t('russianLanguageCourses') }}</router-link>
                  </li>

                  <li>
                    <router-link to="/education-in-russia/russian/tutors" class="info__card-link">{{
                      $t('tutorsTheRussianLanguage') }}</router-link>
                  </li>

                  <li>
                    <router-link to="/education-in-russia/choice-of-profession/certification-in-russian"
                      class="info__card-link">{{ $t('certificationInRussian') }}</router-link>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>
        </div>

        <div class="title-block">
          <div class="title-select">
            <h2 class="title-2">{{ $t('news') }}</h2>
            <MultiSelect v-if="(user || {}).id" :options="selectTitle" :placeholder="$t('_common')"
              form-row-class="form-row mb0" :value="selectTitle[0]" store-path="filter.home.news" />
            <MultiSelect v-else :options="selectTitle.filter(item => item.value !== 'country')"
              :placeholder="$t('_common')" form-row-class="form-row mb0" :value="selectTitle[0]"
              store-path="filter.home.news" />
          </div>
          <router-link to="/news" class="btn btn-outline md-hide">{{ $t('allNews') }}</router-link>
        </div>

        <div class="mb64">
          <swiper class="swiper slider-default news-slider" :options="swiperOptionTree">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

            <swiper-slide v-for="(obj, key) in news" v-bind:key="key">
              <ItemsNews :hide-grid="true" :data="[obj]" />
            </swiper-slide>
          </swiper>
          <div class="resp-title-btn md-show d-none">
            <router-link to="/news" class="btn btn-outline">{{ $t('allNews') }}</router-link>
          </div>
        </div>

        <div class="title-block">
          <div class="title-select">
            <h2 class="title-2">{{ $t('activity') }}</h2>

            <MultiSelect :options="selectTitle" :placeholder="$t('_common')" form-row-class="form-row mb0"
              :value="selectTitle[0]" store-path="filter.home.events" />
          </div>
          <a href="/events/" class="btn btn-outline md-hide">{{ $t('allEvents') }}</a>
        </div>

        <div class="mb64">
          <swiper class="swiper slider-default news-slider" :options="swiperOptionTree">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

            <swiper-slide v-for="(event, key) in events.data" v-bind:key="key">
              <div class="event-wrapper">
                <CardEventHome :event="event" />
              </div>

            </swiper-slide>
          </swiper>

          <div class="resp-title-btn md-show d-none">
            <router-link to="/calendar-of-events" class="btn btn-outline">{{ $t('calendar') }}</router-link>
          </div>
        </div>

        <div class="title-block">
          <h2 class="title-2">{{ $t('citiesOfRussia') }}</h2>
          <router-link to="/life-in-russia/cities" class="btn btn-outline md-hide">{{ $t('allCities') }}</router-link>
        </div>

        <div class="mb64">
          <swiper class="swiper slider-default" :options="swiperOptionDouble">
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>

            <swiper-slide v-for="(city, key) in cities" v-bind:key="key">
              <CitySearchItem :data="city" />
            </swiper-slide>
          </swiper>

          <div class="resp-title-btn md-show d-none">
            <router-link to="/life-in-russia/cities" class="btn btn-outline">{{ $t('allCities') }}</router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <figure class="info__card">
              <div class="info__card-img">
                <img src="@/assets/images/card-7.png" alt="">
              </div>
              <figcaption class="info__card-content">
                <h3 class="title-3">
                  {{ $t('help') }}
                </h3>
                <ul class="info__card-list">
                  <li>
                    <router-link to="/help/faq" class="info__card-link">FAQ</router-link>
                  </li>
                  <li>
                    <router-link to="/help/forum" class="info__card-link">{{ $t('forum') }}</router-link>
                  </li>
                  <li>
                    <router-link to="/help/feedback" class="info__card-link">{{ $t('feedback') }}</router-link>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>

          <div class="col-4">
            <div class="title-block reviews-title-block">
              <h2 class="title-2">{{ $t('reviewsOfStudentsAndGraduates') }}</h2>
              <router-link to="/why-russia/reviews" class="btn btn-outline md-hide">{{ $t('allReviews') }}</router-link>
            </div>
            <div class="reviews">

              <swiper class="swiper slider-default" :options="swiperOption">
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>

                <swiper-slide v-for="(review, key) in reviews" v-bind:key="key">
                  <ItemsReviews :data="[review]" :is-single="true" :review-cut="250" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="resp-title-btn md-show d-none">
              <router-link to="/why-russia/reviews" class="btn btn-outline">{{ $t('allReviews') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {helpers} from '@/_services';
import {mapActions, mapGetters} from 'vuex';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import ItemsNews from '@/components/site/pages/news/generic/ItemsNews';

import CitySearchItem from '@/components/site/pages/lifeInRussia/cities/generic/CitySearchItem';
import ItemsReviews from '@/components/site/pages/whyRussia/generic/ItemsReviews';

import MultiSelect from '@/components/generic/form/field/MultiSelect';

import CardEventHome from '@/components/site/pages/events/CardEventHome';

export default {
  name: 'Home',

  components: {
    Swiper,
    SwiperSlide,
    ItemsNews,
    CitySearchItem,
    ItemsReviews,
    MultiSelect,
    CardEventHome,
  },

  computed: {
    ...mapGetters({
      forms: 'form/forms',
      user: 'users/getUser',
      getLocale: 'getLocale',
    }),
  },

  data() {
    return {
      avanpost: process.env.VUE_APP_LINK_AVANPOST,
      domain: window.location.host,
      link: window.location.origin,
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoHeight: true,
      },
      swiperOptionDouble: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 2,
        spaceBetween: 32,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 32,
          },
        },
      },
      swiperOptionTree: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 3,
        spaceBetween: 32,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          750: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        },
      },
      news: [],
      cities: [],
      reviews: [],
      levelEducation: [],
      areaEducation: [],
      areaScientificKnowledge: [],
      scientificAreas: [],
      selectTitle: [
        {
          name: this.$t('all'),
          value: 'all',
        },

        {
          name: this.$t('_common'),
          value: 'common',
        },

        {
          name: this.$t('_inYourCountry'),
          value: 'country',
        },
      ],
      groupDirections: [],
      semesters: [],
      semestersDurationAdditional: [],
      educationalProgramsProgramAdditional: [],
      educationalProgramsUniversity: [],
      educationalProgramsUniversityAdditional: [],
      universitiesGetCities: [],
      districts: [],
      active: 'program',
      activeProgram: 'main',
      activeUniversities: 'main',
      events: [],
      filterNews: 'all',
      filterEvents: 'all',
      isLevelEducation: false,
      postgraduateNumber: 1,
    };
  },

  methods: {
    ...mapActions({
      getNews: 'news/getNews',
      getCities: 'lifeInRussia/getCities',
      getReviews: 'whyRussia/getReviews',
      getLevelEducation: 'getLevelEducation',
      getAreaEducation: 'getAreaEducation',
      getGroupDirections: 'getGroupDirections',
      getSemesters: 'getSemesters',
      getEducationalPrograms: 'getEducationalPrograms',
      getUniversitiesGetCities: 'educationInRussia/getUniversitiesGetCities',
      getEvents: 'events/getEvents',
      getDistricts: 'getDistricts',
      getEducationFilter: 'getEducationFilter',
      getScientificAreas: 'getScientificAreas',
    }),

    getCites() {
      this.getUniversitiesGetCities({
        district: this.forms.filter.universities.main.district,
      }).then(res => {
        this.universitiesGetCities = helpers.options(res);
      });
    },

    getFilter(filter, tab) {
      let currentFilter;

      if (tab === 'program') {
        if (filter === 'main') {
          currentFilter = this.forms.filter.programs.main;
        } else {
          currentFilter = this.forms.filter.programs.additional;
        }
      } else {
        if (filter === 'main') {
          currentFilter = this.forms.filter.universities.main;
        } else {
          currentFilter = this.forms.filter.universities.additional;
        }
      }
      return currentFilter;
    },

    updateFilters(filter, tab) {
      this.$store.commit('preloader/isLoading', true)

      let currentFilter = this.getFilter(filter, tab);

      if (tab === 'program') {
        this.$store.commit('form/updateField', {model: 'filter.programs.additional.educationalProgram', value: null});
      } else {
        this.$store.commit('form/updateField', {model: 'filter.universities.additional.educationalProgram', value: null});
      }

      this.getEducationFilter({
        hideAdditionalProgs: null,
        showOnlyAdditionalProgs: 1,
        directionId: currentFilter.groupDirection,
        sciAreaId: this.isLevelEducation ? currentFilter.scientificAreas : null,
        areaId: this.isLevelEducation ? null : currentFilter.areaEducation,
      }).then(res => {
        if (tab === 'program') {
          this.educationalProgramsProgramAdditional = _.map(res.data.education_program, value => ({value: value.id, name: value.name}));
        } else {
          this.educationalProgramsUniversity = _.map(res.data.education_program, value => ({value: value.id, name: value.name}));
          this.educationalProgramsUniversityAdditional = _.map(res.data.education_program, value => ({value: value.id, name: value.name}));
        }
      }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    getNewsData() {
      this.$store.commit('preloader/isLoading', true)
      this.getNews({
        perPage: 15,
        showBy: this.filterNews,
      }).then(res => {
        this.news = res.data.data;
      }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    getEventsData() {
      this.$store.commit('preloader/isLoading', true)
      this.getEvents({
        showBy: this.filterEvents,
      }).then(res => {
        this.events = res.data;
      }).finally(() => this.$store.commit('preloader/isLoading', false));
    },

    goToUrl(path, type) {
      this.$store.commit('form/updateField', {model: 'filter.programs.main.groupDirection', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.main.educationalProgram', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.main.city', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.main.languageInstruction', value: null});

      this.$store.commit('form/updateField', {model: 'filter.programs.additional.semesterDurationAdditional', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.additional.languageInstruction', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.additional.city', value: null});

      this.$store.commit('form/updateField', {model: 'filter.universities.main.district', value: null});
      this.$store.commit('form/updateField', {model: 'filter.universities.main.levelEducation', value: null});
      this.$store.commit('form/updateField', {model: 'filter.universities.main.groupDirection', value: null});
      this.$store.commit('form/updateField', {model: 'filter.universities.main.ratings', value: null});

      this.$store.commit('form/updateField', {model: 'filter.universities.additional.district', value: null});
      this.$store.commit('form/updateField', {model: 'filter.universities.additional.levelEducation', value: null});
      this.$store.commit('form/updateField', {model: 'filter.universities.additional.groupDirection', value: null});

      this.$store.commit('form/updateField', {model: 'filter.universities.search', value: null});
      this.$store.commit('form/updateField', {model: 'filter.programs.search', value: null});
      
      this.$router.push({path: path, query: {typeFilter: type}})
    },

    onChangeSelect(filter) {
      if (filter !== 'levelEducation')
        return;

      let {levelEducation} = this.getFilter('main', 'program');
      const selectedOption = this.levelEducation.filter(level => level.value === levelEducation);
      const selectedOptionValue = selectedOption[0].value;

      this.isLevelEducation = Number(selectedOptionValue) === this.postgraduateNumber
        ? true
        : false;
    },
  },

  mounted() {
    this.getNewsData();
    this.getEventsData();
    this.getCites();


    this.getDistricts().then(res => {
      this.districts = helpers.options(res);
    });

    this.getGroupDirections().then(res => {
      this.groupDirections = helpers.options(res);
    });

    this.getSemesters().then(res => {
      this.semesters = helpers.options(res);
    });

    this.getScientificAreas().then(res => {
      this.scientificAreas = helpers.options(res.data);
    });

    this.semestersDurationAdditional = [
      {id: '0-0,99', name: this.$t('lessThan1Month')},
      {id: '1-2,99', name: this.$t('1To3Months')},
      {id: '3-5,99', name: this.$t('3To6Months')},
      {id: '6-11,99', name: this.$t('6To12Months')},
      {id: '12+', name: this.$t('moreThan1Year')},
    ];

    this.getEducationalPrograms({
      hideAdditionalProgs: 1,
    }).then(res => {
      this.educationalProgramsUniversity = helpers.options(res);
    });

    this.getEducationalPrograms({
      showOnlyAdditionalProgs: 1,
    }).then(res => {
      this.educationalProgramsProgramAdditional = helpers.options(res);
      this.educationalProgramsUniversityAdditional = helpers.options(res);
    });

    this.getLevelEducation().then(res => {
      this.levelEducation = helpers.options(res);
    });

    this.getAreaEducation().then(res => {
      this.areaEducation = helpers.options(res);
    });

    this.getCities({
      perPage: 15,
    }).then(res => {
      this.cities = res.data;
    });

    this.getReviews({
      perPage: 15,
    }).then(res => {
      this.reviews = res.data.data;
    });
  },

  watch: {
    forms(nextState) {
      if (this.filterNews !== nextState.filter.home.news) {
        this.filterNews = nextState.filter.home.news
      }

      if (this.filterEvents !== nextState.filter.home.events) {
        this.filterEvents = nextState.filter.home.events
      }
    },

    filterNews() {
      this.getNewsData();
    },

    filterEvents() {
      this.getEventsData();
    },
  },
};
</script>
