<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div class="home-page">
    <div class="base-container">
      <div class="container">
        <Breadcrumbs
          :items="[{title: $t('mainPage'), url: '/' }, {title: $t('news') }]"
        />

        <h1 class="title-1 mb32">{{ $t('news') }}</h1>

        <ul class="nav-tabs-sub">
          <li class="nav-item-sub">
            <a
              href="javascript:void(0)"
              class="nav-link-sub"
              @click.prevent="activeFilter = 'all'"
              :class="{ 'active show': activeFilter === 'all' }"
            >{{ $t('all') }}</a>
          </li>

          <li class="nav-item-sub">
            <a
              href="javascript:void(0)"
              class="nav-link-sub"
              @click.prevent="activeFilter = 'common'"
              :class="{ 'active show': activeFilter === 'common' }"
            >{{ $t('common') }}</a>
          </li>

          <li class="nav-item-sub">
            <a
              href="javascript:void(0)"
              class="nav-link-sub"
              @click.prevent="activeFilter = 'country'"
              :class="{ 'active show': activeFilter === 'country' }"
            >{{ $t('inYourCountry') }}</a>
          </li>
        </ul>

        <ItemsNews :data="newsList.data" />

        <Pagination
          :count="newsList.last_page"
          :callback-on-change="() => getData()"
          pagination-model="pagination.news"
          :show-list-showing="false"
          :last-data-count="newsList.data.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ItemsNews from '@/components/site/pages/news/generic/ItemsNews';
  import Pagination from '@/components/generic/pagination/Pagination';
  import Breadcrumbs from '@/components/generic/Breadcrumbs';
  import {mapActions, mapGetters} from 'vuex';

  export default {
    name: 'news',

    components: {
      Breadcrumbs,
      Pagination,
      ItemsNews,
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        newsList: 'news/getNewsList',
        locale: 'getLocale',
        forms: 'form/forms',
      }),
    },

    data() {
      return {
        activeFilter: 'all',
      }
    },

    methods: {
      ...mapActions({
        getNews: 'news/getNews',
      }),

      getData() {
        this.$store.commit('preloader/isLoading', true)
        this.getNews({
          perPage: (this.pagination.news|| {}).length || 9,
          page: (this.pagination.news|| {}).page || null,
          showBy: this.activeFilter,
        }).then(res => {
          this.$store.commit('news/updateParam', {model: 'news', value: res.data});
        }).finally(() => this.$store.commit('preloader/isLoading', false));
      },
    },

    mounted() {
      window.scrollTo(0, 0);
      this.getData();
    },

    watch: {
      activeFilter() {
        this.$store.commit('updateParam', {model: 'pagination.news.page', value: 1});
        this.getData();
      },
    },
  }
</script>
