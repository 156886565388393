<template>
    <validation-observer v-slot="{handleSubmit}" ref="observer">
        <form @submit.prevent="handleSubmit(onSubmit)">
            <slot name="form" />
        </form>
    </validation-observer>
</template>

<script>
    export default {
        methods: {
            onSubmit() {
                this.$el.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');

                setTimeout(function() {
                    if(this.formCallback)
                        this.formCallback(this.$refs.observer.reset);

                    this.$el.querySelector('[type="submit"]').removeAttribute('disabled');
                }.bind(this), 1000)
            },
        },

        name: 'Form',

        props: {
            formCallback: Function,
        },
    }
</script>
