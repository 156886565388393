<template>
  <div class='event-popover-info'>
    <div :class='{row: isEventInsert}'>
      <div :class="{'col-4': isEventInsert}">
        <div class='event-popover-title' v-if='!isEventInsert'>
          <router-link :to='`/event/${data.id}`'>
            {{ title }}
          </router-link>
          <div class='event-popover-type'>{{ type }}</div>
        </div>
        <div class='event-popover-table'>
          <div class='event-popover-table-row'>
            <div class='event-popover-table-row-title'>{{ $t('organizers') }}</div>
            <div class='event-popover-table-row-value'>{{ organizers }}</div>
          </div>
          <div class='event-popover-table-row'>
            <div class='event-popover-table-row-title'>{{ $t('dateOfEvent') }}</div>
            <div class='event-popover-table-row-value'>{{ date }}</div>
          </div>
          <div class='event-popover-table-row'>
            <div class='event-popover-table-row-title'>{{ $t('timeOfEvent') }}</div>
            <div class='event-popover-table-row-value'>
              {{ data.format.code === 'ONLINE' ? `${time} (GMT ${timeZone})` : time }}
            </div>
          </div>
          <div class='event-popover-table-row'>
            <div class='event-popover-table-row-title'>{{ $t('formatOfEvent') }}</div>
            <div class='event-popover-table-row-value'>{{ format }}</div>
          </div>
          <div class='event-popover-table-row' v-if="data.format.code === 'ONLINE' && data.url">
            <div class='event-popover-table-row-title'>{{ $t('linkOnEvent') }}</div>
            <div class='event-popover-table-row-value'><a :href="url_link(data.url)" class="link">{{ data.url }}</a></div>
          </div>
          <div class='event-popover-table-row' v-else>
            <div class='event-popover-table-row-title'>{{ $t('location') }}</div>
            <div class='event-popover-table-row-value'>{{ location }}</div>
          </div>
        </div>
      </div>

      <div :class="{'col-2 text-right': isEventInsert}">
        <slot name='right' />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import {getTimeZone} from '../../../../../../utils/getTimeZone.ts'

export default {
  name: 'events-event-item',

  data: () => ({
    title: '',
    type: '',
    organizers: '',
    date: '',
    time: '',
    format: '',
    location: '',
    timeZone: '',
  }),

  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
  },

  methods: {
    setData() {
      moment.locale(this.locale)
      this.title = Vue.filter('lang')(this.data, 'name')
      this.organizers = this.data.organizers
      const startDate = moment(this.data.start_date).format('D')
      const endDate = moment(this.data.end_date).locale('ru').format('D MMMM')

      this.date = this.data.start_date === this.data.end_date ? `${endDate}` :`${startDate} - ${endDate}`
      const startTime = moment(`${this.data.start_date} ${this.data.start_time}`).format('HH:mm')
      const endTime = moment(`${this.data.end_date} ${this.data.end_time}`).format('HH:mm')
      this.time = `c ${startTime} до ${endTime}`
      this.format = this.data.format && this.data.format.name
      this.location = this.data.location
      this.type = this.data && this.data.type && this.data.type.name
      if (this.data.format.code === 'ONLINE') {
        this.timeZone = getTimeZone()
      }
    },
  },

  mounted() {
    this.setData()
  },

  updated() {
    this.setData()
  },

  props: {
    data: Object,
    isEventInsert: Boolean,
    eventTypes: Array,
  },

  watch: {
    locale() {
      this.setData()
    },
  },
  destroyed() {
    this.setData({})
  },
}
</script>
