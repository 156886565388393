import _ from 'lodash';

export default {
    namespaced: true,

    getters: {
        isModalShown: state => key => {
            if(!state.modals[key])
                _.assign(state.modals, {[key]: {isModalShown: false}});

            return state.modals[key].isModalShown;
        },

        modals: state => {
            return state.modals;
        },
    },

    mutations: {
        initModal(state, key) {
            state.modals[key] = {
                isModalShown: false,
            };

            state.modals = _.clone(state.modals);
        },

        updateShow(state, payload) {
            if (payload.value) {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            } else {
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
            }

            state.modals[payload.key].isModalShown = payload.value;
        },
    },

    state: {
        modals: {},
    },
};
