<template>
  <Form :form-callback="onSubmit" ref="careerTestsForm">
    <div slot="form" class="feedback">
      <Input
        type="text"
        element-class="txt"
        :placeholder="$t('enterYourName')"
        store-path="careerTests.name"
        :label="`${$t('name')} <span class='red'>*</span>`"
        rules="required|min:2|max:100|onlyText"
      />

      <Input
        type="text"
        element-class="txt"
        :placeholder="$t('enterYourAge')"
        store-path="careerTests.age"
        :label="`${$t('age')} <span class='red'>*</span>`"
        rules="required|numeric|max:2|notnull"
      />

      <MultiSelect
        :options="genders"
        :placeholder="$t('enterYourGender')"
        store-path="careerTests.gender"
        :label="`${$t('gender')} <span class='red'>*</span>`"
        rules="required"
      />

      <MultiSelect
        :options="levelsEducation"
        :placeholder="$t('chooseYourCurrentLevelOfEducation')"
        store-path="careerTests.levelEducation"
        :label="`${$t('currentLevelOfEducation')} <span class='red'>*</span>`"
        rules="required"
      />

      <MultiSelect
        :options="areasInterestHobbies"
        class="multiselect-select"
        :placeholder="$t('chooseYourAreasOfInterestHobbies')"
        store-path="careerTests.areasInterestHobbies"
        :label="`${$t('areasOfInterestHobbies')} <span class='red'>*</span>`"
        rules="required"
        is-multiple
        allow-empty
      />

      <MultiSelect
        :options="sports"
        class="multiselect-select"
        :placeholder="$t('chooseYourSportingAchievements')"
        store-path="careerTests.sportsAchivments"
        :label="$t('sportsAchievements')"
        is-multiple
        allow-empty
      />

      <div class="form-row__btn">
        <Button
          type="submit"
          class="btn"
          :text="$t('next')"
        />
      </div>
    </div>
  </Form>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import Form from '@/components/generic/form/Form';
  import Input from '@/components/generic/form/field/Input';
  import Button from '@/components/generic/form/Button';
  import MultiSelect from '@/components/generic/form/field/MultiSelect';
  import {helpers} from '@/_services/helpers.service';

  export default {
    name: 'education-in-russia-career-generic-career-test',

    components: {
      Form,
      Input,
      Button,
      MultiSelect,
    },

    data: () => ({
      value: null,
      areasInterestHobbies: [],
      genders: [],
      sports: [],
      levelsEducation: [],
    }),

    computed: {
      ...mapGetters({
        forms: 'form/forms',
        params: 'educationInRussia/getParams',
      }),
    },

    methods: {
      ...mapActions({
        getSports: 'educationInRussia/getSports',
        getLevelEducation: 'getLevelEducation',
        getHobby: 'educationInRussia/getHobby',
      }),

      onSubmit() {
        this.$store.commit('form/updateField', {model: 'careerTests.answers', value: null})
        this.$store.commit('educationInRussia/updateParam', {model: 'careerTests.step', value: 'manual'})
      },
    },

    mounted() {
      this.genders = [{name: this.$t('male'), value: 'm'}, {name: this.$t('female'), value: 'w'}];

      this.getSports().then(res => {
        this.sports = helpers.options(res.data)
      })

      this.getHobby().then(res => {
        this.areasInterestHobbies = helpers.options(res.data)
      })

      this.getLevelEducation().then(res => {
        this.levelsEducation = helpers.options(res)
      })
    },
  }
</script>
