<template>
  <div>
    <ul class="pagination__list-page">
      <li
        v-if="getDataPagination().page > 1 && count > 5"
      >
        <a
          href="#"
          @click="() => setCurrentPage(1)"
        >{{ $t('to_begin') }}</a>
      </li>

      <li
        v-for="(page, key) in counter"
        v-bind:key="key"
      >
        <a
          href="#"
          :class="{ active: getDataPagination().page === page.page }"
          @click="() => setCurrentPage(page.page)"
          v-if="page.page"
        >{{ page.title }}</a>

        <span v-if="!page.page" >{{ page.title }}</span>
      </li>

      <li>
        <a
          href="#"
          v-if="count >= (getDataPagination().page + 1) && count > 5"
          @click="() => setCurrentPage(getDataPagination().page + 1)"
        >{{ $t('_next') }}</a>
      </li>
    </ul>

    <ul class="pagination__list-post" v-if="showListShowing">
      <li><span>{{ $t('showing') }}</span></li>
      <li
        v-for="(show, key) in showListVariants"
        v-bind:key="key"
      >
        <a
          href="#"
          :class="{active: getDataPagination().length === show}"
          @click="() => setLength(show)"
        >{{ show }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import _ from 'lodash';
  import {mapGetters} from 'vuex';

  export default {
    name: 'pagination-component',

    data() {
      return {
        counter: [],
      };
    },

    computed: {
      ...mapGetters({
        pagination: 'getPagination',
        locale: 'getLocale',
        forms: 'form/forms',
      }),
    },

    methods: {
      setLength(length) {
        this.$store.commit('updateParam', {model: `${this.paginationModel}.length`, value: length});
        this.$store.commit('updateParam', {model: `${this.paginationModel}.page`, value: 1});

        if (this.callbackOnChange()) {
          this.callbackOnChange();
        }
      },

      setCurrentPage(page) {
        this.$store.commit('updateParam', {model: `${this.paginationModel}.page`, value: page});
        this.updateCounter();

        if (this.callbackOnChange()) {
          this.callbackOnChange();
        }
      },

      getDataPagination() {
        return _.get(this.pagination, `${this.paginationModel}`.replace('pagination.', ''), {page: 1, length: 10});
      },

      updateCounter() {
        if (this.count && this.count > 1) {
          let
            pagination = this.getDataPagination(),
            p = pagination.page,
            s = [-2 + p, -1 + p, p, 1 + p, 2 + p];

          this.counter = [];

          if (s[3] > this.count && p - 4 > 0) {
            this.counter.push({title: p - 3 , page: p - 3})
          }

          if (s[4] > this.count && p - 4 > 0) {
            this.counter.push({title: p - 4 , page: p - 4})
          }

          _.map(s, page => page <= this.count && page > 0 && this.counter.push({title: page , page: page}))

          if (s[0] <= 0 && p + 2 < this.count) {
            this.counter.push({title: p + 3 , page: p + 3})
          }

          if (s[1] <= 0 && p + 3 < this.count) {
            this.counter.push({title: p + 4 , page: p + 4})
          }

          if (this.counter.slice(-1)[0].page < this.count) {
            this.counter.push({title: '...'});
            this.counter.push({title: this.count, page: this.count});
          }
        } else {
          this.counter = [];
        }
      },

      showMore() {
        this.setCurrentPage(this.getDataPagination().page + 1)
      },
    },

    mounted() {
      this.updateCounter();
    },

    props: {
      count: Number,
      callbackOnChange: Function,
      paginationModel: {type: String, default() { return 'pagination' }},
      lastDataCount: {type: Number, default() { return 1 }},
      showListShowing: {type: Boolean, default() { return true }},
      showListVariants: {type: Array, default() { return [10, 25, 75, 100] }},
    },

    watch: {
      count() {
        this.updateCounter();
      },

      'pagination'() {
        this.updateCounter();
      },
    },
  };
</script>
